import PropTypes from 'prop-types';

import { BigLogo, SmallLogo } from '@root/assets/svg';

export const Logo = ({ src, isSmall = false, sx }) => {
  const srcCustom = src ? src : isSmall ? SmallLogo : BigLogo;
  return <img src={srcCustom} alt="Logo" style={{ ...sx }} />;
};

Logo.propTypes = {
  isSmall: PropTypes.bool,
  src: PropTypes.string,
  sx: PropTypes.object,
};
