const getSavedOrders = state => state.savedOrders.savedOrders;
const getIsInfiniteScrollLoading = state =>
  state.savedOrders.isInfiniteScrollLoading;
const getSavedOrdersMeta = state => state.savedOrders.savedOrdersMeta;
const getIsSavedOrdersLoading = state => state.savedOrders.isSavedOrdersLoading;
const getIsSavedOrdersError = state => state.savedOrders.isSavedOrdersError;

const savedOrdersSelectors = {
  getSavedOrders,
  getSavedOrdersMeta,
  getIsSavedOrdersLoading,
  getIsSavedOrdersError,
  getIsInfiniteScrollLoading,
};

export default savedOrdersSelectors;
