import { InviteEmployeesModal } from '@root/components';
import {
  AccountActivity,
  CompanyInfo,
  ListOfEmployees,
  PersonalInfo,
  Roles,
  Security,
  SubsidiaryCompanies,
  WorkflowSettings,
} from '@root/modules';

export const ProfileConf = t => {
  return [
    {
      label: t('profile:conf.personal_info.title'),
      description: t('profile:conf.personal_info.description'),
      icon: 'icon-person',
      value: 'personal_info',
      path: 'personal-info',
      modal: null,
      permission_key: 'exception',
      body: () => <PersonalInfo />,
    },
    {
      label: t('profile:conf.company_info.title'),
      description: t('profile:conf.company_info.description'),
      icon: 'icon-company',
      value: 'company_info',
      path: 'company-info',
      modal: null,
      body: () => <CompanyInfo />,
      permission_key: 'profile_company_info_access',
    },
    {
      label: t('profile:conf.subsidiary_companies.title'),
      description: t('profile:conf.subsidiary_companies.description'),
      icon: 'icon-subsidiary-company',
      value: 'subsidiary_companies',
      path: 'subsidiary-companies',
      modal: null,
      body: () => <SubsidiaryCompanies />,
      permission_key: 'make_commercial_offer_access',
    },
    {
      label: t('profile:conf.list_of_employees.title'),
      description: t('profile:conf.list_of_employees.description'),
      icon: 'icon-people',
      value: 'list_of_employees',
      path: 'list-of-employees',
      permission_key_add: 'profile_workers_add_access',
      buttonText: t('profile:conf.list_of_employees.add_employee_btn'),
      modal: props => <InviteEmployeesModal {...props} />,
      body: () => <ListOfEmployees />,
      permission_key: 'profile_workers_access',
    },
    // {
    //   label: t('profile:conf.list_of_employees.title'),
    //   description: t('profile:conf.list_of_employees.description'),
    //   icon: 'icon-people',
    //   value: 'list_of_employees',
    //   path: 'list-of-employees',
    // },
    // {
    //   label: t('profile:conf.notification_settings.title'),
    //   description: t('profile:conf.notification_settings.description'),
    //   icon: 'icon-notification',
    //   value: 'notification_settings',
    //   path: 'notification-settings',
    // },
    {
      label: t('profile:conf.workflow_settings.title'),
      description: t('profile:conf.workflow_settings.description'),
      icon: 'icon-settings',
      value: 'workflow_settings',
      path: 'workflow-settings',
      modal: null,
      body: () => <WorkflowSettings />,
      permission_key: 'profile_colors_access',
    },
    // {
    //   label: t('profile:conf.social_media.title'),
    //   description: t('profile:conf.social_media.description'),
    //   icon: 'icon-social-media',
    //   value: 'social_media',
    //   path: 'social-media',
    // },
    {
      label: t('profile:conf.roles.title'),
      description: t('profile:conf.roles.description'),
      icon: 'icon_key',
      value: 'roles',
      path: 'roles',
      modal: null,
      body: () => <Roles />,
      permission_key: 'profile_permissions_access',
    },
    {
      label: t('profile:conf.account_activity.title'),
      description: t('profile:conf.account_activity.description'),
      icon: 'icon-account-activity',
      value: 'account_activity',
      path: 'account-activity',
      modal: null,
      body: () => <AccountActivity />,
      permission_key: 'profile_logs_access',
    },
    {
      label: t('profile:conf.security.title'),
      description: t('profile:conf.security.description'),
      icon: 'icon-security',
      value: 'security',
      path: 'security',
      modal: null,
      body: () => <Security />,
      permission_key: 'profile_security_access',
    },
  ];
};

export const AccountActivityTableConf = (t, screenWidth) => [
  {
    label: t(
      'profile:account_activity_page.account_activity_table_conf.browser',
    ),
    isDisplay: true,
    value: 'browser',
    style: {
      width: screenWidth < 769 ? '100%' : '40%',
      display: 'flex',
      flexDirection: 'column',
      gap: '8px',
    },
  },
  {
    label: 'IP',
    value: 'ip',
    isDisplay: true,
    style: { width: '40%' },
  },
  {
    label: t('profile:account_activity_page.account_activity_table_conf.time'),
    value: 'time',
    isDisplay: screenWidth > 768,
    style: { width: '20%' },
  },
];

export const paddingMap = screenWidth => {
  return {
    'personal-info': screenWidth < 769 ? '16px' : '32px',
    'company-info': screenWidth < 769 ? '16px' : '32px',
    'subsidiary-companies': screenWidth < 769 ? '16px' : '32px',
    'list-of-employees': screenWidth < 769 ? '16px' : '32px 32px 9px 32px',
    'workflow-settings': screenWidth < 769 ? '16px' : '32px',
    roles: screenWidth < 769 ? '16px' : '32px',
    'account-activity': screenWidth < 769 ? '16px' : '32px',
    security: screenWidth < 769 ? '16px' : '32px',
  };
};
