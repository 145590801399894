import { Viewer, Worker } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { pdfjs } from 'react-pdf';

import styled from '@emotion/styled';

import { getErrorStack } from '@root/helpers';
import { ButtonWithoutBorder, TextInput } from '@root/ui';

import { Typography, useTheme } from '@mui/material';

import dock_dock from '../../../asdfsdfsdf.pdf';

const AgreementsHTML = ({
  dock,
  assignDock,
  downloadDock,
  isDockUpload,
  upload,
  dockFile,
  targetDock,
  isDock,
}) => {
  const { t } = useTranslation(['profile'], { useSuspense: false });
  const { color } = useTheme();

  const [dockInfo, setDockInfo] = useState({
    title: '',
    number: '',
  });
  const [error, setError] = useState({});
  const [textError, setTextError] = useState('');

  // const [dockForm, setDockForm] = useState(supplyAgreement);
  const [dockForm, setDockForm] = useState({});

  useEffect(() => {
    if (!dock) return;
    setTimeout(setListeners, 0);
  }, [dock]);

  // useEffect(() => {
  //   if (isDockUpload) upload(dockForm, dockInfo);
  // }, [isDockUpload]);

  const setListeners = () => {
    const inputFieldsElements = document.querySelectorAll('[name][id^="inp"]');

    if (!inputFieldsElements.length) return;

    inputFieldsElements.forEach(el => {
      dockForm[el.id] = { name: el.name, value: '' };

      el.addEventListener('input', event => {
        dockForm[el.id].value = event.target.value;
      });
    });
  };

  const validation = () => {
    let flag = false;
    let currentError = {};

    const { title, number } = dockInfo;

    Object.keys({
      title,
      number,
    }).forEach(key => {
      if (!dockInfo[key] || dockInfo[key] === '') {
        currentError = getErrorStack(
          currentError,
          key,
          t('validation:required'),
        );
        flag = true;
      }
    });
    setError(currentError);
    return flag;
  };

  const containerStyle = {
    overflowY: 'scroll',
    maxHeight: window.innerHeight - 200 + 'px',
  };

  const onChange = name => value => {
    setDockInfo(prevState => ({ ...prevState, [name]: value }));
  };

  const iframeHeight = window.innerHeight * 0.72 + 'px';

  return (
    <>
      <Wrapper>
        <Fields>
          <TextInput
            error={error?.email?.init}
            errorText={error?.email?.text}
            sx={{
              width: '100%',
              height: '38px',
              background: 'none',
              color: color.gray_80,
              fontWeight: '400',
              fontSize: '12px',
            }}
            value={dockInfo.title}
            onChange={onChange('title')}
            placeholder={t('agreements.dock_title')}
            type="text"
            withOutSpace={true}
            data-test-id="Title"
            inputProps={{
              maxLength: 64,
            }}
          />
          <Typography
            sx={{
              fontSize: '12px',
              fontWeight: '400',
            }}
            variant="bodyBoldSmall"
            color={color.gray_80}
          >
            №
          </Typography>
          <TextInput
            error={error?.email?.init}
            errorText={error?.email?.text}
            sx={{
              width: '100%',
              height: '38px',
              background: 'none',
              color: color.gray_80,
              fontWeight: '400',
              fontSize: '12px',
            }}
            value={dockInfo.number}
            onChange={onChange('number')}
            placeholder={t('agreements.dock_number')}
            type="text"
            withOutSpace={true}
            data-test-id="Number"
            inputProps={{
              maxLength: 9,
            }}
          />
        </Fields>
        <Fields>
          {isDock && (
            <ButtonWithoutBorder
              onClick={() => upload(dockForm, dockInfo)}
              sx={{
                color: color.white,
                borderRadius: '4px',
                display: 'flex',
                height: '38px',
                padding: '0 12px',
                justifyItems: 'center',
                alignItems: 'center',
                border: 'none',
                backgroundColor: color.main,
                '&:hover': {
                  color: color.gray_90,
                  backgroundColor: color.main,
                },
              }}
            >
              <Typography
                sx={{
                  fontSize: '12px',
                  fontWeight: '700',
                }}
                variant="bodyBoldSmall"
                color={color.white}
              >
                {t('agreements.save')}
              </Typography>
            </ButtonWithoutBorder>
          )}
          {!isDock && (
            <ButtonWithoutBorder
              onClick={assignDock}
              sx={{
                color: color.white,
                borderRadius: '4px',
                display: 'flex',
                height: '38px',
                padding: '0 12px',
                justifyItems: 'center',
                alignItems: 'center',
                border: 'none',
                backgroundColor: color.main,
                '&:hover': {
                  color: color.gray_90,
                  backgroundColor: color.main,
                },
              }}
            >
              <Typography
                sx={{
                  fontSize: '12px',
                  fontWeight: '700',
                }}
                variant="bodyBoldSmall"
                color={color.white}
              >
                {t('agreements.assign_doc')}
              </Typography>
            </ButtonWithoutBorder>
          )}
          <ButtonWithoutBorder
            onClick={assignDock}
            sx={{
              marginLeft: '16px',
              color: color.gray_80,
              borderRadius: '4px',
              display: 'flex',
              height: '38px',
              justifyItems: 'center',
              alignItems: 'center',
              background: 'none',
              border: 'none',
              '&:hover': {
                color: color.main,
              },
            }}
          >
            <Typography
              sx={{
                fontSize: '12px',
                fontWeight: '700',
              }}
              variant="bodyBoldSmall"
              color={color.gray_80}
            >
              {t('agreements.download_dock')}
            </Typography>
          </ButtonWithoutBorder>
        </Fields>
      </Wrapper>
      {isDock && dock && (
        <div
          style={containerStyle}
          dangerouslySetInnerHTML={{ __html: dock }}
        />
      )}
      {!isDock && targetDock && targetDock.file_path && (
        <Worker
          workerUrl={`https://unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`}
        >
          <Viewer fileUrl={targetDock.file_path} />
        </Worker>
        // <div>
        //   <iframe
        //     src={targetDock.file_path}
        //     width="100%"
        //     height={iframeHeight}
        //     sandbox="allow-same-origin allow-scripts allow-popups allow-forms"
        //     allow="autoplay"
        //   />
        //   {/*<iframe src={dock_dock} width="100%" height={iframeHeight} />*/}
        // </div>
      )}
    </>
  );
};

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 17px;
`;

const Fields = styled.div`
  display: flex;
  align-items: center;
`;

export default AgreementsHTML;
