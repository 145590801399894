import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { useQueryParams } from '@root/hooks/useQueryParams';
import adminOperation from '@root/redux/admin/admin-operation';
import adminSelectors from '@root/redux/admin/admin-selectors';
import { BasicAutocompleteSelect } from '@root/ui/Input/AutocompleteSelect/index';

const CompanyFilter = ({ handleChange }) => {
  const { t } = useTranslation(['admin'], { useSuspense: false });
  const dispatch = useDispatch();
  const companys = useSelector(adminSelectors.getSelectCompanys);
  const { getQueryObj } = useQueryParams();
  const [value, setValue] = useState();

  useEffect(() => {
    const companyName = getQueryObj().company_name;
    if (companyName) {
      dispatch(
        adminOperation.getCompanysSelect({
          q: companyName,
          onResolve: items => {
            setValue(items.find(item => item.name === companyName));
          },
        }),
      );
    }
  }, []);

  const onAutocompleteChange = (event, newInputValue, reason, type) => {
    switch (reason) {
      case 'input':
        dispatch(adminOperation.getCompanysSelect({ q: newInputValue }));
        break;
      default:
        break;
    }
  };

  const autocompleteInputChange = (e, v) => {
    setValue(v);
    if (v?.id) {
      handleChange({ id: v.id, name: v.name });
    } else if (v === null) {
      handleChange({ id: null, name: null });
    }
  };

  return (
    <>
      {/* <BasicSelect
        sx={{ height: '36px', p: 0, width: '300px' }}
        height={'36px !important'}
        data={[{ id: 1, name: 'Виберіть бізнес ' }]}
        name={t('admin:business_placeholder')}
      /> */}
      <BasicAutocompleteSelect
        sx={{ height: '36px', p: 0, width: '300px' }}
        height={'36px !important'}
        placeholder={t('admin:business_placeholder')}
        listBoxStyles
        noOptionsText={t('admin:type_business_name_paceholder')}
        options={companys}
        getOptionLabel={option => option.label ?? option.name}
        isOptionEqualToValue={(option, value) => option?.id === value?.id}
        autocompleteInputChange={onAutocompleteChange}
        onAutocompleteChange={autocompleteInputChange}
        onAutocompleteOpen={e => {}}
      />
    </>
  );
};

export default CompanyFilter;
