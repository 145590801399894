import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Sprite } from '@root/assets/svg';
import { SearchInput } from '@root/components';
import { getCurrentDate, getFirstDateOfCurrentMonth } from '@root/helpers';
import { useIsFirstRender, useQueryParams } from '@root/hooks';
import { WidthContext } from '@root/providers/WidthProvider';
import { BasicSelect, RadioCustom, RangeDatePicker, ToggleBtn } from '@root/ui';

import { FormControlLabel, RadioGroup, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

export const Header = ({
  isExpanded,
  isFilterExpanded,
  handleFetchDeliveryHistory,
  handleToggleSearchExpended,
  handleToggleFilterExpended,
  onSearchTermUpdate,
  isTopBorder,
  isBottomBorder = true,
  useFilter = false,
  additionalContent,
  selectData,
  getSelectedValue,
  selectedValue,
  activeRadioBtn,
  setActiveRadioBtn,
  onCloseSelect,
}) => {
  const { logisticsTab } = useParams();
  const { getQueryObj, setSearchParams } = useQueryParams();
  const { date_from, date_to } = getQueryObj();

  const [dates, setDates] = useState([
    date_from || getFirstDateOfCurrentMonth(),
    date_to || getCurrentDate(),
  ]);

  const [searchTerm, setSearchTerm] = useState('');
  const [errorDate, setErrorDate] = useState(false);
  const isFirstRender = useIsFirstRender();
  const { color } = useTheme();
  const { t } = useTranslation(['mutual_settlements', 'common'], {
    useSuspense: false,
  });

  const { screenWidth } = useContext(WidthContext);

  useEffect(() => {
    if (isFirstRender) return;
    handleResetSearch();
  }, [logisticsTab]);

  useEffect(() => {
    onSearchTermUpdate(searchTerm);
  }, [searchTerm]);

  useEffect(() => {
    if (isFirstRender) return;
    const dateFrom = dates[0].length;
    const dateTo = dates[1] && dates[1].length;

    const params = logisticsTab
      ? { date_from, date_to, tab: logisticsTab }
      : { date_from, date_to };
    if (dateFrom && dateTo) {
      handleFetchDeliveryHistory(params);
    }
  }, [dates[0], dates[1], logisticsTab]);

  const onChange = name => value => {
    setSearchTerm(value);
  };

  const handleResetSearch = () => {
    setSearchTerm('');
  };

  const onChangeDate = date => {
    const dateFrom = date[0];
    const dateTo = date[1] && date[1].length ? date[1] : '';
    setDates(date);
    setSearchParams({
      date_from: dateFrom,
      date_to: dateTo,
    });
  };

  const onCalendarClose = () => {
    setErrorDate(dates[0] && !dates[1]);
  };

  return (
    <DeliveryHistoryHeaderWrapper
      color={color.line}
      isTopBorder={isTopBorder}
      isBottomBorder={isBottomBorder}
    >
      <DeliveryHistoryHeaderInner>
        <DatePickerWrapper>
          <RangeDatePicker
            inputSx={{
              height: '36px',
              width: '200px',
              border: errorDate ? `1px solid ${color.red}` : '',
            }}
            date={dates}
            onChangeDate={onChangeDate}
            onCalendarClose={onCalendarClose}
            observer={logisticsTab}
          />
        </DatePickerWrapper>

        <ToggleBtnWrapper>
          <Form>
            <SearchInput
              onChange={onChange}
              searchValue={searchTerm}
              placeholder={t('common:search')}
              handleClearSearch={handleResetSearch}
              sx={{
                '& input': {
                  fontSize: '0.875rem',
                },
              }}
              sxLabel={{
                marginRight: 0,
              }}
            />
          </Form>
          {useFilter && (
            <ToggleBtn
              size={36}
              isActive={isFilterExpanded}
              href={`${Sprite}#${
                isFilterExpanded ? 'icon-cross' : 'icon-filter'
              }`}
              onClick={() => handleToggleFilterExpended()}
            />
          )}
        </ToggleBtnWrapper>
      </DeliveryHistoryHeaderInner>
      {additionalContent && additionalContent()}
      {isFilterExpanded && (
        <FilterWrapper color={color.line}>
          <Typography color={color.gray} variant="uppercaseBoldSmall">
            {t('mutual_settlements:filters.type')}
          </Typography>
          <TypeWrapper>
            <RadioGroup
              onChange={(_, value) => setActiveRadioBtn(value)}
              value={activeRadioBtn}
              sx={{
                marginTop: '5px',
                display: 'flex',
                justifyContent: 'space-between',
                flexDirection: 'row',
                paddingRight: '60px',
              }}
            >
              <FormControlLabel
                disabled={errorDate}
                control={<RadioCustom />}
                sx={{
                  marginLeft: '-9px',
                }}
                label={
                  <Typography
                    variant={'bodyBoldMedium'}
                    component={'span'}
                    sx={{
                      fontWeight: 400,
                      color: color.gray_80,
                    }}
                  >
                    {t('mutual_settlements:filters.radios.general')}
                  </Typography>
                }
                value={3}
              />
              <FormControlLabel
                disabled={errorDate}
                control={<RadioCustom />}
                sx={{
                  marginLeft: '-9px',
                }}
                label={
                  <Typography
                    variant={'bodyBoldMedium'}
                    component={'span'}
                    sx={{
                      fontWeight: 400,
                      color: color.gray_80,
                    }}
                  >
                    {t('mutual_settlements:filters.radios.buyer')}
                  </Typography>
                }
                value={1}
              />
              <FormControlLabel
                disabled={errorDate}
                control={<RadioCustom />}
                sx={{
                  marginLeft: '-8px',
                }}
                label={
                  <Typography
                    variant={'bodyBoldMedium'}
                    component={'span'}
                    sx={{
                      fontWeight: 400,
                      color: color.gray_80,
                    }}
                  >
                    {t('mutual_settlements:filters.radios.supplier')}
                  </Typography>
                }
                value={2}
              />
            </RadioGroup>
          </TypeWrapper>

          <Typography
            color={color.gray}
            variant="uppercaseBoldSmall"
            sx={{
              display: 'inline-block',
              marginTop: '24px',
              marginBottom: '12px',
            }}
          >
            {t('mutual_settlements:filters.document_type')}
          </Typography>
          <BasicSelect
            disabled={errorDate}
            value={selectedValue}
            callBack={getSelectedValue}
            renderValue={selected => {
              if (selected.length === 0) {
                return (
                  <Typography
                    color={`${color.gray_60} !important`}
                    variant="span"
                  >
                    {t('all')}
                  </Typography>
                );
              }
              return selected.join(', ');
            }}
            data={selectData}
            displayEmpty
            multiple
            sx={{
              width: '300px',
              maxWidth: '100%',
            }}
            height="36px"
            onClose={onCloseSelect}
          />
        </FilterWrapper>
      )}
    </DeliveryHistoryHeaderWrapper>
  );
};

Header.propTypes = {
  handleFetchDeliveryHistory: PropTypes.func.isRequired,
  handleToggleSearchExpended: PropTypes.func.isRequired,
  onSearchTermUpdate: PropTypes.func.isRequired,
  isExpanded: PropTypes.bool.isRequired,
  isTopBorder: PropTypes.bool.isRequired,
  isBottomBorder: PropTypes.bool.isRequired,
  additionalContent: PropTypes.func,
  selectData: PropTypes.array,
  getSelectedValue: PropTypes.func.isRequired,
  activeRadioBtn: PropTypes.string,
  setActiveRadioBtn: PropTypes.func.isRequired,
};

const Form = styled.form`
  display: flex;
  width: 100%;
`;

const DeliveryHistoryHeaderWrapper = styled.div`
  border-top: ${({ color, isTopBorder }) =>
    isTopBorder && `1px solid ${color}`};
  border-bottom: ${({ color, isBottomBorder }) =>
    isBottomBorder && `1px solid ${color}`};
`;

const DeliveryHistoryHeaderInner = styled.div`
  display: flex;
  align-items: center;
  gap: 8px 16px;
  padding: 10px;

  @media (max-width: 768px) {
    align-items: stretch;
    flex-direction: column;
  }
`;

const DatePickerWrapper = styled.div``;

const ToggleBtnWrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 16px;
  width: 100%;
`;

const FilterWrapper = styled.div`
  border-radius: 4px;
  border: ${({ color }) => `1px solid ${color}`};
  margin: 21px 10px 21px 10px;
  padding: 20px;
`;

const TypeWrapper = styled.div``;
