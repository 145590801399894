import { useTranslation } from 'react-i18next';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Sprite } from '@root/assets/svg/index';
import { Empty } from '@root/components';
import { ActivityAreas } from '@root/components/ContactField/ActivityAreas';
import { InputField } from '@root/components/ContactField/InputField';
import { Phone } from '@root/components/ContactField/Phone';
import { SwitchField } from '@root/components/ContactField/SwitchField';
import { AvatarUI } from '@root/ui';
import { IconBtn } from '@root/ui/Button/IconBtn/index';

import { Typography } from '@mui/material';
import Divider from '@mui/material/Divider';

import { DatePickerField } from './DatePickerField/index';
import { SelectAsyncField } from './SelectAsyncField/index';

export const ContactField = ({
  lastStep,
  type = 'field',
  variant = 'bodyLarge',
  title,
  value,
  displayValue,
  subValue,
  padding = '12px 0',
  sx,
  onEdit,
  onCancel,
  editValue,
  errors,
  goToStep,
  multiple,
  id,
  data,
  operation,
}) => {
  const { t } = useTranslation(['common'], { useSuspense: false });
  const FIELDS_DESCRIPTION_MAP = {
    phone: (
      <Phone
        id={id}
        errors={errors}
        editValue={editValue}
        value={value}
        variant={variant}
        lastStep={lastStep}
        sx={sx}
        onEdit={onEdit}
        onCancel={onCancel}
        goToStep={goToStep}
      />
    ),
    select: (
      <ActivityAreas
        id={id}
        errors={errors}
        editValue={editValue}
        value={value}
        variant={variant}
        sx={sx}
        onEdit={onEdit}
        onCancel={onCancel}
        goToStep={goToStep}
        multiple={multiple}
        data={data}
        displayValue={displayValue}
      />
    ),
    select_async: (
      <SelectAsyncField
        id={id}
        errors={errors}
        editValue={editValue}
        value={value}
        variant={variant}
        sx={sx}
        onEdit={onEdit}
        onCancel={onCancel}
        goToStep={goToStep}
        multiple={multiple}
        displayValue={displayValue}
        operation={operation}
      />
    ),
    field: (
      <InputField
        id={id}
        errors={errors}
        editValue={editValue}
        sx={sx}
        type={type}
        variant={variant}
        value={value}
        onEdit={onEdit}
        onCancel={onCancel}
        goToStep={goToStep}
      />
    ),
    switch: (
      <SwitchField
        id={id}
        errors={errors}
        editValue={editValue}
        sx={sx}
        type={type}
        variant={variant}
        value={value}
        onEdit={onEdit}
        onCancel={onCancel}
        goToStep={goToStep}
      />
    ),
    date: (
      <DatePickerField
        id={id}
        errors={errors}
        editValue={editValue}
        sx={sx}
        type={type}
        variant={variant}
        value={value}
        onEdit={onEdit}
        onCancel={onCancel}
        goToStep={goToStep}
      />
    ),
  };

  const getHead = type => {
    switch (type) {
      case 'img':
        return (
          <AvatarUiWrap>
            <AvatarUI src={value}>
              <Empty
                type={'EmptyLogo'}
                isCompany={false}
                name={subValue}
                noAvatarSx={{
                  fontSize: '2rem',
                }}
              />
            </AvatarUI>
            {goToStep && (
              <IconBtn
                href={`${Sprite}#icon-pan`}
                onClick={() => goToStep()}
                tooltipText={t('common:tooltip.edit')}
              />
            )}
          </AvatarUiWrap>
        );

      case 'companyLogo':
        return value ? (
          <CompanyLogo src={value} alt="Logo Company" />
        ) : (
          <Empty
            type={'EmptyLogo'}
            isCompany
            tag={'div'}
            wrapperStyles={{
              width: '100%',
              maxWidth: '160px',
              height: '58px',
            }}
            contentStyles={{
              width: '100%',
              height: '100%',
            }}
          />
        );

      default:
        return (
          <Typography
            sx={{ flex: '0 0 130px', marginRight: '40px' }}
            variant={variant}
            component={'p'}
          >
            {title}
          </Typography>
        );
    }
  };

  const fieldDescription = FIELDS_DESCRIPTION_MAP[type];

  return (
    <>
      <Wrapper padding={padding}>
        {getHead(type)}
        {fieldDescription}
      </Wrapper>
      {type === 'area' && (
        <Typography sx={{ mt: '12px' }} variant={variant} component={'p'}>
          {value}
        </Typography>
      )}
      <Divider />
    </>
  );
};

const Wrapper = styled.div`
  display: flex;
  padding: ${({ padding }) => padding};
  align-items: ${({ align }) => align};
`;

const CompanyLogo = styled.img`
  height: 58px;
`;

const AvatarUiWrap = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
`

ContactField.propTypes = {
  lastStep: PropTypes.bool,
  type: PropTypes.string,
  variant: PropTypes.string,
  title: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  subValue: PropTypes.string.isRequired,
  goToStep: PropTypes.func.isRequired,
  padding: PropTypes.string,
  sx: PropTypes.object,
};
