import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { HeaderDefault } from '@root/components/Modal/ModalHeader/Default/index';
import { ModalBase } from '@root/ui/Modal/index';

const TutorialVideoDialog = ({ title, page, videoSrc }) => {
  const { t } = useTranslation(['common']);
  const [show, setShow] = useState(false);

  useEffect(() => {
    if (!window.localStorage.getItem(page)) {
      setShow(true);
    }
  }, []);

  const onMouseDown = e => {
    if (e.target.classList.contains('MuiBackdrop-root')) {
      setShow(false);
    }
  };

  return (
    <ModalBase
      open={show}
      modalHeader={
        <HeaderDefault
          text={title || t('tutorial.video_modal_dialog')}
          onClose={() => {
            setShow(false);
            window.localStorage.setItem(page, true);
          }}
        />
      }
      sx={{ outline: 'none', width: '1000px' }}
      onMouseDown={onMouseDown}
    >
      <iframe
        width="100%"
        height="590"
        src={videoSrc}
        title={title || t('tutorial.video_modal_dialog')}
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowfullscreen
      ></iframe>
    </ModalBase>
  );
};

export default TutorialVideoDialog;
