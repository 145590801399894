import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import { useParams } from 'react-router';
import { NavLink } from 'react-router-dom';

import styled from 'styled-components';

import { SocialsLinksConf } from '@root/conf/SocialsLinksConf/index';
import { getErrorStack, handleDispatchFetch } from '@root/helpers';
import { useNavigation, useQueryParams } from '@root/hooks';
import { CheckSecretKeyModal } from '@root/modules/Forms/LoginForm/CheckSecretKeyModal';
import {
  ButtonBase,
  CheckboxBase,
  MessageError,
  PasswordInput,
  TextInput,
} from '@root/ui';

import operationAuth from '@redux/auth/auth-operation';
import authOperation from '@redux/auth/auth-operation';

import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import authSelectors from "@redux/auth/auth-selectors";
import userSelectors from "@redux/user/user-selectors";
// import ReactGA from "react-ga4";

export const LoginForm = () => {
  const [data, setData] = useState({
    email: '',
    password: '',
    secret: '',
    rememberMe: false,
  });
  const { navigation } = useNavigation();
  const { getQueryObj } = useQueryParams();
  const { token, token_invitation } = getQueryObj();
  const [error, setError] = useState({});
  const { lang } = useParams();
  const { t } = useTranslation(['login', 'validation'], { useSuspense: false });
  const { color } = useTheme();
  const dispatch = useDispatch();
  const [textError, setTextError] = useState('');

  const currentLang = lang ? `/${lang}` : '';

  const [isModalOpen, setIsModalOpen] = useState();

  const handleCloseCheckSecretKeyModal = () => {
    setIsModalOpen(false);
  };

  const onChange = name => value => {
    setData(prevState => ({ ...prevState, [name]: value }));
  };

  const validation = () => {
    let flag = false;
    let currentError = {};

    const { email, password } = data;

    Object.keys({
      email,
      password,
    }).forEach(key => {
      if (!data[key] || data[key] === '') {
        currentError = getErrorStack(
          currentError,
          key,
          t('validation:required'),
        );
        flag = true;
      }
    });

    if (!email) {
      currentError = getErrorStack(
        currentError,
        'email',
        t('validation:required'),
      );
      flag = true;
    }
    setError(currentError);
    return flag;
  };

  useEffect(() => {
    if (token) {
      handleDispatchFetch(
        ({ onResolve, onReject }) =>
          dispatch(
            operationAuth.authByToken({
              tokenUser: token,
              onResolve,
              onReject,
            }),
          ),
        () => {},
        ({ message, data }) => {
          if (data?.id) {
            navigation('/pre-login');
          } else {
            setError({
              email: { init: true, text: '' },
              password: { init: true, text: '' },
            });
            setTextError(message);
            navigation(`/login`);
          }
        },
      );
    }
    if (token_invitation) {
      handleDispatchFetch(
        ({ onResolve, onReject }) =>
          dispatch(
            operationAuth.authNewUserByToken({
              tokenUser: token_invitation,
              onResolve,
              onReject,
            }),
          ),
        ({ message, data }) => {
          handleNavigation(message, data);
        },
        ({ message, data }) => {
          handleNavigation(message, data);
        },
      );
    }
  }, [token, token_invitation]);

  const handleNavigation = (message, data) => {
    if (data?.id || data?.email) {
      navigation('/pre-login');
    } else {
      setError({
        email: { init: true, text: '' },
        password: { init: true, text: '' },
      });
      setTextError(message);
      navigation(`/login`);
    }
  };

  const handelSubmit = async e => {
    e.preventDefault();
    if (validation()) return;
    handleDispatchFetch(
      ({ onResolve, onReject }) =>
        dispatch(
          operationAuth.signIn({
            data,
            onResolve,
            onReject,
          }),
        ),
      ({ ga_is_active }) => {
        setIsModalOpen(ga_is_active);
      },
      message => {
        setData(prevData => ({ ...prevData, secret: '' }));
        setError({
          email: { init: true, text: '' },
          password: { init: true, text: '' },
        });
        setTextError(message);
        handleCloseCheckSecretKeyModal();
      },
    );
  };

  const handleSocialClick = driver => {
    dispatch(
      authOperation.getSocialSignup({
        driver,
        onResolve: url => {
          if (url) {
            window.location.assign(url);
          } else {
            alert('error! url is ', url);
          }
        },
      }),
    );
  };

  const { email, password, rememberMe } = data;
  return (
    <>
      <CheckSecretKeyModal
        handleCloseModal={handleCloseCheckSecretKeyModal}
        open={isModalOpen}
        onSubmit={handelSubmit}
        data={data}
        setData={setData}
        onChange={onChange}
        error={error}
      />
      <Form autoComplete="on" onSubmit={handelSubmit}>
        <Typography variant={'h1'}>{t('login:tittle')}</Typography>
        <Typography sx={{ mt: '28px' }} variant={'bodyLarge'} component={'p'}>
          {t('login:slogan')}
        </Typography>
        <Row>
          <TextInput
            error={error?.email?.init}
            errorText={error?.email?.text}
            sx={{ mt: '28px', maxWidth: '100%', height: '48px' }}
            value={email}
            onChange={onChange('email')}
            placeholder={t('login:field.login_placeholder')}
            type="text"
            withOutSpace={true}
            data-test-id="Email"
            autoComplete={'email'}
            inputProps={{
              maxLength: 255,
            }}
          />
        </Row>
        <Row>
          {' '}
          <PasswordInput
            error={error?.password?.init}
            errorText={error?.password?.text}
            sx={{ mt: '28px', maxWidth: '100%', height: '48px' }}
            value={password}
            onChange={onChange('password')}
            placeholder={t('login:field.password', { req: '*' })}
            data-test-id="Password"
            autoComplete={'new-password'}
            inputProps={{
              maxLength: 255,
            }}
          />
        </Row>

        <WrapperMemo>
          <RememberMe>
            <CheckboxBase
              width={24}
              onChange={onChange('rememberMe')}
              checked={rememberMe}
              error={error?.agreement?.init}
            >
              <Typography
                sx={{ ml: '10px', color: color.gray }}
                variant={'bodyLarge'}
                component={'p'}
              >
                {t('login:rememberMe')}
              </Typography>
            </CheckboxBase>
          </RememberMe>
          <NavLink to={currentLang + '/password-reset'}>
            <Typography
              variant={'bodyLarge'}
              sx={{ textDecorationLine: 'underline', color: color.gray }}
              component={'p'}
            >
              {t('login:forgotPassword')}
            </Typography>
          </NavLink>
        </WrapperMemo>
        {textError && (
          <MessageError
            errorText={textError}
            sx={{
              mt: '16px',
              mr: 'auto',
              position: 'relative',
              top: '16px',
              fontSize: '0.75rem',
              whiteSpace: 'nowrap',
            }}
          />
        )}

        <ButtonBase
          type="submit"
          size={'large'}
          sx={{ mt: '28px', width: '100% ' }}
        >
          <Typography variant={'bodyBoldLarge'}>
            {' '}
            {t('login:loginBtn')}
          </Typography>
        </ButtonBase>

        <AdditionAppsTitle color={color}>
          <Typography color={color.gray_60}>
            {t('login:auth_with_apps')}
          </Typography>
        </AdditionAppsTitle>
        <BtnsGrid color={color}>
          {SocialsLinksConf.map(item => (
            <SocialBtn
              type="button"
              color={color}
              onClick={() => {
                handleSocialClick(item.id);
              }}
            >
              <img src={item.ico} alt="" />
              <Typography color={color.gray} sx={{ minWidth: `100px` }}>
                {item.text}
              </Typography>
            </SocialBtn>
          ))}
        </BtnsGrid>
      </Form>{' '}
      <WrapperSignUp>
        <Typography
          variant={'bodyMedium'}
          sx={{ color: color.gray_80 }}
          component={'p'}
        >
          {t('login:questionAboutAccount')}
        </Typography>
        <NavLink to={currentLang + '/sign-up'}>
          <Typography
            variant={'bodyBoldMedium'}
            sx={{ color: color.main, ml: '4px' }}
            component={'p'}
          >
            {t('login:signUp')}
          </Typography>
        </NavLink>
      </WrapperSignUp>
    </>
  );
};

const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 103px;
`;
const Row = styled.div`
  width: 100%;
`;

const WrapperMemo = styled.div`
  display: flex;
  align-items: center;
  margin-top: 16px;

  justify-content: space-between;
  width: 100%;
`;

const RememberMe = styled.div`
  display: flex;
  align-items: center;
`;

const WrapperSignUp = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  margin-top: auto;

  @media (max-width: 1024px) {
    margin-top: 20px;
  }
`;

const AdditionAppsTitle = styled.div`
  position: relative;
  text-align: center;
  margin-top: 28px;
  width: 100%;

  &::before {
    content: '';
    position: absolute;
    height: 1px;
    width: 25%;
    top: 50%;
    left: 0;
    background-color: ${({ color }) => color.line};
  }
  &::after {
    content: '';
    position: absolute;
    height: 1px;
    width: 25%;
    top: 50%;
    right: 0;
    background-color: ${({ color }) => color.line};
  }
`;

const BtnsGrid = styled.div`
  margin-top: 28px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 16px 28px;

  @media (max-width: 768px) {
    align-self: stretch;
    grid-template-columns: 1fr;
  }
`;

const SocialBtn = styled.button`
  padding: 12px 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid ${({ color }) => color.line};
  border-radius: 4px;
  text-align: center;
  cursor: pointer;
  img {
    margin-right: 10px;
  }
`;

const LoginBadge = styled.div`
  margin-top: 28px;
  display: flex;
  align-items: center;
  width: 100%;
  background-color: #e9eaef;
  border-radius: 4px;
  padding: 10px;
`;
