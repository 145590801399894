import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { NavLink, useParams } from 'react-router-dom';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Cost, ImgPrev, LabelProduct, Managing } from '@root/components';
import { savedOrdersConf } from '@root/conf/SavedOrdersConf';
import { WidthContext } from '@root/providers/WidthProvider';
import { theme } from '@root/styles';
import { CustomScroll } from '@root/ui';

import userSelectors from '@redux/user/user-selectors';

import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from '@mui/material';

export const SavedOrderProducts = ({
  data,
  handleDeleteProduct,
  handleAddProductToCart,
}) => {
  const choosedCurrency = useSelector(
    userSelectors.getChoosedCurrencyWidget,
  ).field_name;
  const preventDefault = e => e.preventDefault();
  const { lang } = useParams();
  const currentLang = lang ? `/${lang}` : '';

  const { t } = useTranslation(['common', 'order_history'], {
    useSuspense: false,
  });

  const { screenWidth } = useContext(WidthContext);

  return (
    <TableContainer component={Paper} sx={{ maxHeight: '327px' }}>
      <CustomScroll vertical={true}>
        <Table size="small" aria-label="a saved orders table">
          <TableBody sx={{ display: 'block' }}>
            {data.map(row => (
              <TableRow
                key={'pe' + row.id}
                sx={{
                  minHeight: '109px',
                  borderBottom: `1px solid ${theme.color.line}`,
                  userSelect: 'auto',
                  boxShadow: 'none',
                  th: {
                    borderBottom: 'none',
                  },
                  display: 'flex',
                  alignItems: 'center',
                  padding: '20px',
                }}
              >
                {savedOrdersConf(screenWidth).map((el, i) => {
                  switch (el?.value) {
                    case 'product':
                      return (
                        el.isDisplay && (
                          <TableCell
                            component="th"
                            scope="row"
                            key={'si' + row.id + i}
                            sx={{ p: 0, ...el.style, cursor: 'pointer' }}
                          >
                            <NavLink to={currentLang + row?.product.url}>
                              <ImgPrev
                                src={row?.product.image}
                                countImg={row?.product.images_quantity}
                              />
                            </NavLink>
                          </TableCell>
                        )
                      );

                    case 'name':
                      return (
                        <TableCell
                          component="th"
                          scope="row"
                          key={'si' + row.id + i}
                          sx={{ p: 0, ...el.style, cursor: 'pointer' }}
                        >
                          <NavLink to={currentLang + row?.product.url}>
                            <MobileWrapper>
                              {screenWidth < 769 && (
                                <NavLink to={currentLang + row?.product.url}>
                                  <ImgPrev
                                    src={row?.product.image}
                                    countImg={row?.product.images_quantity}
                                  />
                                </NavLink>
                              )}
                              <LabelProduct
                                label={row?.product.model}
                                article={row?.product.sku}
                                isAvailable
                                canBuy={row?.product.stock !== 0}
                              />

                              {screenWidth < 769 && (
                                <Typography
                                  variant="bodyNormalSmall"
                                  color={theme.color.gray_80}
                                >
                                  {row?.quantity} {t('order_history:piece')}
                                </Typography>
                              )}

                              {screenWidth < 769 && <Cost data={row.product} />}

                              {screenWidth < 431 && (
                                <ManagingMobileWrapper>
                                  <Managing
                                    canBuy={
                                      row?.product.can_buy[choosedCurrency]
                                    }
                                    onAddToCartProduct={() =>
                                      handleAddProductToCart(
                                        row.product_id,
                                        row.quantity,
                                      )
                                    }
                                    onDeleteProduct={() =>
                                      handleDeleteProduct(row.product_id)
                                    }
                                  />
                                </ManagingMobileWrapper>
                              )}
                            </MobileWrapper>
                          </NavLink>
                        </TableCell>
                      );

                    case 'cost':
                      return (
                        el.isDisplay && (
                          <TableCell
                            component="th"
                            scope="row"
                            key={'si' + row.id + i}
                            sx={{ p: 0, ...el.style, cursor: 'pointer' }}
                          >
                            <Cost data={row.product} />
                          </TableCell>
                        )
                      );
                    case 'quantity':
                      return (
                        el.isDisplay && (
                          <TableCell
                            component="th"
                            scope="row"
                            key={'si' + row.id + i}
                            sx={{
                              p: 0,
                              ...el.style,
                            }}
                          >
                            <Typography
                              variant="bodyNormalSmall"
                              color={theme.color.gray_80}
                            >
                              {row?.quantity} {t('order_history:piece')}
                            </Typography>
                          </TableCell>
                        )
                      );
                    case el.isDisplay && 'managing':
                      return (
                        <TableCell
                          onClick={preventDefault}
                          component="th"
                          scope="row"
                          key={'si' + row.id + i}
                          sx={{
                            p: 0,
                            ...el.style,
                          }}
                        >
                          <Managing
                            canBuy={row?.product.can_buy[choosedCurrency]}
                            onAddToCartProduct={() =>
                              handleAddProductToCart(
                                row.product_id,
                                row.quantity,
                              )
                            }
                            onDeleteProduct={() =>
                              handleDeleteProduct(row.product_id)
                            }
                          />
                        </TableCell>
                      );
                    default:
                      return '';
                  }
                })}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </CustomScroll>
    </TableContainer>
  );
};

const MobileWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

const ManagingMobileWrapper = styled.div`
  margin-top: 15px;
`;

SavedOrderProducts.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.exact({
      amounts_personal: PropTypes.exact({
        price: PropTypes.number,
        price_uah_cash: PropTypes.number,
        price_uah_no_pdv: PropTypes.number,
        price_uah_pdv: PropTypes.number,
      }),
      amounts_product: PropTypes.exact({
        price: PropTypes.number,
        price_uah_cash: PropTypes.number,
        price_uah_no_pdv: PropTypes.number,
        price_uah_pdv: PropTypes.number,
      }),
      id: PropTypes.number,
      product: PropTypes.exact({
        brand: PropTypes.string,
        can_buy: PropTypes.exact({
          price: PropTypes.bool,
          price_uah_cash: PropTypes.bool,
          price_uah_no_pdv: PropTypes.bool,
          price_uah_pdv: PropTypes.bool,
        }),
        cart: PropTypes.exact({
          cart_product_id: PropTypes.number,
          id: PropTypes.number,
          personal_amount: PropTypes.exact({
            price: PropTypes.string,
            price_uah_cash: PropTypes.string,
            price_uah_no_pdv: PropTypes.string,
            price_uah_pdv: PropTypes.string,
          }),
          quantity: PropTypes.number,
        }),
        description: PropTypes.string,
        has_analogs: PropTypes.bool,
        id: PropTypes.number,
        image: PropTypes.string,
        images_quantity: PropTypes.number,
        in_compare: PropTypes.bool,
        is_in_cart: PropTypes.bool,
        is_saved: PropTypes.bool,
        model: PropTypes.string,
        name: PropTypes.string,
        personal_price: PropTypes.exact({
          price: PropTypes.string,
          price_uah_cash: PropTypes.string,
          price_uah_no_pdv: PropTypes.string,
          price_uah_pdv: PropTypes.string,
        }),
        price: PropTypes.exact({
          price: PropTypes.string,
          price_uah_cash: PropTypes.string,
          price_uah_no_pdv: PropTypes.string,
          price_uah_pdv: PropTypes.string,
        }),
        prices: PropTypes.exact({
          price: PropTypes.exact({
            product: PropTypes.string,
            personal: PropTypes.string,
          }),
          price_uah_cash: PropTypes.exact({
            product: PropTypes.string,
            personal: PropTypes.string,
          }),
          price_uah_no_pdv: PropTypes.exact({
            product: PropTypes.string,
            personal: PropTypes.string,
          }),
          price_uah_pdv: PropTypes.exact({
            product: PropTypes.string,
            personal: PropTypes.string,
          }),
        }),
        sku: PropTypes.string,
        slug: PropTypes.string,
        stock: PropTypes.number,
        stock_max: PropTypes.number,
        stock_percent: PropTypes.number,
        url: PropTypes.string,
        warehouses: PropTypes.arrayOf(
          PropTypes.exact({
            city_name: PropTypes.string,
            warehouses: PropTypes.exact({
              name: PropTypes.string,
              stock: PropTypes.number,
              stock_max: PropTypes.number,
              stock_percent: PropTypes.number,
            }),
          }),
        ),
      }),
      product_id: PropTypes.number,
      quantity: PropTypes.number,
    }),
  ),
  handleDeleteProduct: PropTypes.func.isRequired,
  handleAddProductToCart: PropTypes.func.isRequired,
};
