import {useState} from "react";
import "./HamburgerButton.css"

export const HamburgerButton = ({isSidebarVisible, buttonAction}) => {

    const [isOpen, setIsOpen] = useState(false);

    const handleClickButton = () => {
        if (isSidebarVisible && buttonAction) {
            buttonAction(false)
        }
        if (!isSidebarVisible && buttonAction) {
            buttonAction(true)
        }
        // setIsOpen(prevState => !prevState)
    }

    return (
        <svg
            onClick={handleClickButton}
            className={`ham hamRotate ham4 ${isSidebarVisible ? "active" : ""}`}
            viewBox="0 0 100 100" width="55"
        >
            <path
                className="line top"
                d="m 70,33 h -40 c 0,0 -8.5,-0.149796 -8.5,8.5 0,8.649796 8.5,8.5 8.5,8.5 h 20 v -20"/>
            <path
                className="line middle"
                d="m 70,50 h -40"/>
            <path
                className="line bottom"
                d="m 30,67 h 40 c 0,0 8.5,0.149796 8.5,-8.5 0,-8.649796 -8.5,-8.5 -8.5,-8.5 h -20 v 20"/>
        </svg>
    )
}
