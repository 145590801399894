import { useDispatch, useSelector } from 'react-redux';

import PropTypes from 'prop-types';

import { AddProductToListOfCategoryModal } from '@root/components';
import { useSaleData } from '@root/hooks';

import homeOperation from '@redux/home/home-operation';

export const TopSaleModal = ({ handleCloseModal }) => {
  const { data } = useSaleData({ key: 'top_sale' });
  const dispatch = useDispatch();

  const onSubmit = chosenId => {
    dispatch(
      homeOperation.createHomeTopSale({
        productList: chosenId,
      }),
    ).then(() => handleCloseModal());
  };

  return (
    <>
      <AddProductToListOfCategoryModal
        data={data}
        handleCloseModal={handleCloseModal}
        onSubmitCallBack={onSubmit}
        isTopSale
      />
    </>
  );
};
TopSaleModal.propTypes = {
  handleCloseModal: PropTypes.func.isRequired,
};
