import PropTypes from 'prop-types';
import styled from 'styled-components';

import { useTheme } from '@mui/material';
import {forwardRef} from "react";

export const CustomScroll = forwardRef(({
  children,
  height,
  vertical = false,
  horizontally = false,
  padding,
}, ref) => {
  const { color } = useTheme();
  return (
    <Component
      colors={{ gray: color.gray_40, white: color.white, line: color.line }}
      vertical={vertical}
      horizontally={horizontally}
      padding={padding}
      ref={ref}
    >
      {children}
    </Component>
  );
});

const Component = styled.div`
  overflow-x: ${({ horizontally }) => (horizontally ? 'auto' : 'hidden')};
  overflow-y: ${({ vertical }) => (vertical ? 'auto' : 'hidden')};
  height: inherit;
  max-height: inherit;
  width: 100%;
  scrollbar-width: thin;
  padding: ${({ padding }) => padding};

  ::-webkit-scrollbar {
    width: 5px;
  }

  ::-webkit-scrollbar-thumb {
    background: ${props => props.colors.gray};
    border-radius: 4px;
  }

  ::-webkit-scrollbar-track {
    background: ${props => props.colors.white};
    border-left: 1px solid ${props => props.colors.line};
  }
`;

CustomScroll.propTypes = {
  children: PropTypes.node,
  height: PropTypes.number,
  vertical: PropTypes.bool,
  horizontally: PropTypes.bool,
  padding: PropTypes.string,
};
