import { useContext, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Sprite } from '@root/assets/svg';
import { OutlineButtonBase } from '@root/ui';

import orderOperation from '@redux/order/order-operation';

import { Divider, Typography, useTheme } from '@mui/material';

import { CartItem } from '../CartItem';
import { ModalAllItem } from './ModalAllItem';
import { WidthContext } from '@root/providers/WidthProvider';

export const WrapperSection = ({
  children,
  title,
  btnAllListTitle,
  addNewTitle,
  modalDetails,
  renderData,
  available_methods,
  sectionType,
}) => {
  const [openAllList, setOpenAllList] = useState(false);
  const [openNew, setOpenNew] = useState(false);
  const [currentObj, setCurrentObj] = useState(null);
  const [flag, setFlag] = useState('New');
  const dispatch = useDispatch();
  const { color } = useTheme();

  const {screenWidth} = useContext(WidthContext)
  const handelAction = ({ el, type }) => {
    setOpenNew(true);
    setCurrentObj(el);
    setFlag(type);
  };

  const { renderDataInMemo, pinedLength } = useMemo(() => {
    const itemChose = renderData.find(el => el.is_selected);
    const firstTreeItem = renderData.slice(0, 3);
    const pinedList = renderData.filter(el => el.is_pinned);
    if (itemChose) firstTreeItem.push(itemChose);
    return {
      renderDataInMemo: [
        ...new Set([...firstTreeItem].map(obj => JSON.stringify(obj))),
      ].map(str => JSON.parse(str)),
      pinedLength: pinedList.length,
    };
  }, [renderData]);

  return (
    <>
      <Typography
        variant={'h3'}
        sx={{ color: color.gray, p: screenWidth < 769 ? '18px 0 10px 16px' : '26px 0 16px 24px' }}
        component={'h2'}
      >
        {title}
      </Typography>
      <Divider />
      <InfoClient>
        {renderDataInMemo.map((el, idx) => (
          <CartItem
            key={'del' + el.id + idx}
            type={sectionType}
            data={el}
            edit={() => handelAction({ el, type: 'edit' })}
            clone={() => handelAction({ el, type: 'clone' })}
            removeItem={({ entity, id }) => {
              dispatch(
                orderOperation.deleteTemplate({
                  entity,
                  id,
                }),
              );
            }}
            pin={() => {
              dispatch(
                orderOperation.changePinnedTemplate({
                  entity: sectionType,
                  id: el.id,
                  pin: el.is_pinned ? 0 : 1,
                }),
              );
            }}
            hideDellBtn={renderDataInMemo.length === 1}
            hidePin={pinedLength === 3 && idx === 3}
          />
        ))}
        <BtnFooter>
          <button type={'button'} onClick={() => setOpenAllList(true)}>
            <Typography
              variant={'bodyBoldSmall'}
              sx={{ color: color.main, cursor: 'pointer' }}
            >
              {btnAllListTitle}
            </Typography>
          </button>

          <OutlineButtonBase
            type="button"
            sx={{ width: 'fit-content', height: '36px' }}
            onClick={() => {
              setFlag('New');
              setOpenNew(true);
            }}
          >
            <Svg width="16" height="16">
              <use href={`${Sprite}#icon-plus`}></use>
            </Svg>
            <Typography variant={'bodyBoldSmall'} align={'center'}>
              {addNewTitle}
            </Typography>
          </OutlineButtonBase>
        </BtnFooter>
      </InfoClient>
      {openNew &&
        modalDetails({
          open: openNew,
          toggleOpenModal: () => {
            setOpenNew(false);
            setCurrentObj(null);
            setFlag('New');
          },
          currentObj,
          flag,
          openAllList: () => setOpenAllList(true),
        })}

      {openAllList && (
        <ModalAllItem
          open={openAllList}
          toggleOpenModal={() => setOpenAllList(false)}
          available_methods={available_methods}
          data={renderData}
          sectionType={sectionType}
          setOpenCreateModal={setOpenNew}
          handelAction={handelAction}
        />
      )}
    </>
  );
};

const BtnFooter = styled.div`
  display: flex;
  justify-content: space-between;

  @media(max-width: 768px) {
    flex-direction: column;
    align-items: start;
    gap: 10px;
  }

  margin-top: 10px;
`;

const Svg = styled.svg`
  margin-right: 4px;
`;

const InfoClient = styled.div`
  padding: 16px 24px 16px 24px;

  @media(max-width: 768px) {
    padding: 10px 16px 10px 16px;
  }
`;

WrapperSection.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
  btnAllListTitle: PropTypes.string,
  addNewTitle: PropTypes.string,
  modalDetails: PropTypes.func.isRequired,
  renderData: PropTypes.arrayOf(
    PropTypes.exact({
      entity: PropTypes.string,
      id: PropTypes.number,
      info: PropTypes.exact({
        address: PropTypes.string,
        delivery_info: PropTypes.exact({
          city_ref: PropTypes.string,
          city_title: PropTypes.string,
          date: PropTypes.string,
          department_ref: PropTypes.string,
          department_title: PropTypes.string,
          elevator: PropTypes.bool,
          flat_num: PropTypes.string,
          floor_num: PropTypes.string,
          npPhone: PropTypes.string,
          post_office_ref: PropTypes.string,
          post_office_title: PropTypes.string,
          street_number: PropTypes.string,
          street_title: PropTypes.string,
          time: PropTypes.string,
          type: PropTypes.string,
        }),
        is_drop: PropTypes.bool,
        middle_name: PropTypes.string,
        name: PropTypes.string,
        phone: PropTypes.string,
        surname: PropTypes.string,
      }),
      is_pinned: PropTypes.number,
      is_selected: PropTypes.bool,
      key: PropTypes.string,
      subTitle: PropTypes.string,
      title: PropTypes.string,
    }),
  ),
  available_methods: PropTypes.exact({
    delivery_types: PropTypes.arrayOf(
      PropTypes.exact({
        id: PropTypes.number,
        items: PropTypes.arrayOf(
          PropTypes.exact({
            icon: PropTypes.string,
            id: PropTypes.number,
            isDefault: PropTypes.bool,
            key: PropTypes.string,
            subTitle: PropTypes.string,
            title: PropTypes.string,
          }),
        ),
        key: PropTypes.string,
        label: PropTypes.string,
        subTitle: PropTypes.string,
        title: PropTypes.string,
      }),
    ),
    payment_types: PropTypes.arrayOf(
      PropTypes.exact({
        icon: PropTypes.string,
        id: PropTypes.number,
        isDefault: PropTypes.bool,
        key: PropTypes.string,
        subTitle: PropTypes.string,
        title: PropTypes.string,
      }),
    ),
  }),
  sectionType: PropTypes.string,
};
