import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { serialize } from 'object-to-formdata';
import styled from 'styled-components';

import { NoPhoto } from '@root/assets/image';
import { getErrorStack, handleDispatchFetch, validURL } from '@root/helpers';
import {
  ButtonBase,
  CustomScroll,
  FileLoader,
  OutlineButtonBase,
  TextInput,
} from '@root/ui';

import homeOperation from '@redux/home/home-operation';
import homeSelectors from '@redux/home/home-selectors';
import { updateHomeBanner } from '@redux/home/home-slice';

import { useTheme } from '@mui/material/styles';

export const DownloadBannerModal = ({ handleCloseModal }) => {
  const [data, setData] = useState([]);
  const [activeBanner, setActiveBanner] = useState(0);
  const [error, setError] = useState([]);
  const fileRef = useRef();
  const fileLargeRef = useRef();
  const dispatch = useDispatch();

  const { t } = useTranslation(['validation', 'common', 'home'], {
    useSuspense: false,
  });
  const { color } = useTheme();

  const homeBanner = useSelector(homeSelectors.getHomeBanner);

  useEffect(() => {
    setData(homeBanner);
  }, [homeBanner]);

  const handleRemoveImage = (img, type) => {
    if (!img) handleSetBannerInfo(null, type);
  };

  const validation = () => {
    let flag = false;
    const currentError = data.map(({ link, src, src_large }) => {
      let errorStep = {};

      if (!src) {
        errorStep = getErrorStack(errorStep, 'src', t('validation:required'));
        flag = true;
      }

      if (!src_large) {
        errorStep = getErrorStack(
          errorStep,
          'src_large',
          t('validation:required'),
        );
        flag = true;
      }

      if (src && src?.size > 5000000) {
        errorStep = getErrorStack(errorStep, 'src', t('validation:file_size'));
        flag = true;
      }

      if (src_large && src_large?.size > 5000000) {
        errorStep = getErrorStack(
          errorStep,
          'src_large',
          t('validation:file_size'),
        );
        flag = true;
      }

      if (!link || link === '') {
        errorStep = getErrorStack(
          errorStep,
          'link',
          t('validation:incorrect_email'),
        );
        flag = true;
      }

      if (!validURL(link) && link !== '') {
        errorStep = getErrorStack(
          errorStep,
          'link',
          t('validation:enter_valid_url'),
        );
        flag = true;
      }

      return errorStep;
    });
    setError(currentError);
    return flag;
  };

  const handleSetBannerInfo = (value, type) => {
    const copyData = [...data];
    copyData[activeBanner] = { ...data[activeBanner], [type]: value };
    setData(copyData);
  };

  const handleSaveHomeBanner = e => {
    e.preventDefault();
    if (validation()) return;

    handleDispatchFetch(
      ({ onResolve, onReject }) =>
        dispatch(
          homeOperation.updateHomeBanner({
            formData: serialize(
              { slide: data },
              {
                indices: true,
                booleansAsIntegers: true,
              },
            ),
            onResolve,
            onReject,
          }),
        ),
      data => {
        handleCloseModal();
        dispatch(updateHomeBanner(data));
      },
    );
  };

  const handelAddItemBanner = e => {
    setData([
      ...data,
      {
        id: null,
        link: '',
        src: null,
        src_large: null,
      },
    ]);
  };

  const handelDeleteSlide = () => {
    const currentData = data[activeBanner];
    if (currentData?.id) {
      dispatch(homeOperation.deleteHomeBanner({ id: currentData?.id }));
    } else {
      setData([...data].filter((el, idx) => idx !== activeBanner));
    }
    if (activeBanner === 0) {
      setActiveBanner(0);
    } else {
      setActiveBanner(activeBanner - 1);
    }
  };

  return (
    <UploadBannerModalWrapper>
      <Form onSubmit={e => handleSaveHomeBanner(e)} id={'banner'}>
        <Wrapper>
          <NavWrapper>
            <CustomScroll vertical={true}>
              <ListSlider>
                {data.map((el, idx, arr) => {
                  return (
                    <ItemSlider
                      key={idx}
                      isError={error[idx] && Object.keys(error[idx]).length > 0}
                      isActive={idx === activeBanner}
                      color={color}
                      onClick={() => setActiveBanner(idx)}
                    >
                      {el.src ? (
                        <PreviewImageItem
                          src={
                            typeof el?.src === 'string'
                              ? el?.src
                              : URL.createObjectURL(el?.src)
                          }
                          alt="Slider"
                          width="200px"
                        />
                      ) : (
                        <PreviewImageItem
                          src={NoPhoto}
                          alt="No Img"
                          width="36px"
                        />
                      )}
                    </ItemSlider>
                  );
                })}
              </ListSlider>
            </CustomScroll>
            <ButtonWrapper>
              <OutlineButtonBase
                onClick={handelAddItemBanner}
                sx={{ padding: '5px 16px', width: '100%', mt: '5px' }}
              >
                {t('common:buttons.add')}
              </OutlineButtonBase>
              {data.length > 1 && (
                <ButtonBase
                  sx={{
                    mt: '10px',
                    backgroundColor: color.red,
                    '&:hover': {
                      backgroundColor: color.red,
                    },
                  }}
                  onClick={handelDeleteSlide}
                >
                  {t('common:buttons.delete_slide')}
                </ButtonBase>
              )}
            </ButtonWrapper>
          </NavWrapper>
          <WrapperFile>
            <TextInput
              label={t('home:modals.download_banner_modal.add_link_label')}
              required={true}
              error={error[activeBanner]?.link?.init}
              errorText={error[activeBanner]?.link?.text}
              value={data[activeBanner]?.link}
              onChange={value => handleSetBannerInfo(value, 'link')}
              placeholder="https://"
              sx={{
                marginTop: '4px',
                height: '36px',
              }}
            />

            <FileLoader
              showPreview={true}
              renderPreview={({ file }) => (
                <PreviewImage src={file} maxWidth="220" />
              )}
              fileRef={fileRef}
              file={data[activeBanner]?.src}
              setFile={value => handleSetBannerInfo(value, 'src')}
              title={t(
                'home:modals.download_banner_modal.download_banner_loader_title',
              )}
              handleSetImage={img => handleRemoveImage(img, 'src')}
              isLogo={true}
              initError={error[activeBanner]?.src}
              optionalRecommendationSize={t(
                'home:modals.download_banner_modal.optimal_size',
                { size: '441x349' },
              )}
            />

            <FileLoader
              showPreview={true}
              renderPreview={({ file }) => (
                <PreviewImage src={file} maxWidth="582" />
              )}
              fileRef={fileLargeRef}
              file={data[activeBanner]?.src_large}
              setFile={value => handleSetBannerInfo(value, 'src_large')}
              title={t(
                'home:modals.download_banner_modal.download_fullwidth_banner_loader_title',
              )}
              handleSetImage={img => handleRemoveImage(img, 'src_large')}
              isLogo={true}
              initError={error[activeBanner]?.src_large}
              optionalRecommendationSize={t(
                'home:modals.download_banner_modal.optimal_size',
                { size: '893x349' },
              )}
            />
          </WrapperFile>
        </Wrapper>
      </Form>
    </UploadBannerModalWrapper>
  );
};

const UploadBannerModalWrapper = styled.div`
  width: 1100px;
  max-width: 100%;
`;

const PreviewImage = styled.img`
  width: 100%;
  max-width: ${({ maxWidth }) => maxWidth}px;
  height: 175px;
  object-fit: cover;
`;

const Form = styled.form``;

const Wrapper = styled.div`
  display: flex;
  gap: 20px;
`;

const NavWrapper = styled.div`
  margin-top: 10px;
  min-width: 200px;
  height: 520px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const WrapperFile = styled.div`
  flex-grow: 1;
`;

const ListSlider = styled.ul`
  margin-right: 5px;

  li + li {
    margin-top: 10px;
  }
`;

const ItemSlider = styled.li`
  height: 78px;
  width: 200px;

  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;

  border: 2px solid
    ${({ color, isActive, isError }) =>
      isError ? color.red : isActive ? color.main : color.line};
  border-radius: 4px;
  overflow: hidden;
  background-color: ${({ color }) => color.white};
`;

const PreviewImageItem = styled.img``;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
