import React from 'react';
import EllipsisText from 'react-ellipsis-text';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Sprite } from '@root/assets/svg';
import { PricesTooltip, QuantityManager } from '@root/components';
import { useNavigation } from '@root/hooks';
import { ToggleBtn } from '@root/ui';

import userSelectors from '@redux/user/user-selectors';

import { Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

export const CardSlider = ({
  isEdit,
  data,
  addedToCart,
  onClick,
  onDelete,
}) => {
  const currency = useSelector(userSelectors.getChoosedCurrency);
  const choosedCurrency = useSelector(
    userSelectors.getChoosedCurrencyWidget,
  ).field_name;
  const { color } = useTheme();
  const { navigation } = useNavigation();
  const userPermissions = useSelector(userSelectors.getPermissions);

  const { t } = useTranslation(['common'], { useSuspense: false });
  return (
    <Cart color={color.white}>
      {isEdit && (
        <Svg width={16} height={16} stroke={color.gray} onClick={onDelete}>
          <use href={`${Sprite}#icon-delete`} />
        </Svg>
      )}

      <HeaderWrapper onClick={() => navigation(data.url)}>
        <Img src={data?.image} alt="" />
        <PricesWrapper display={'flex'}>
          <Flex>
            {' '}
            <Typography
              variant={'bodyBoldMedium'}
              sx={{
                color: color.gray,
              }}
            >
              <EllipsisText text={data?.model} length={'20'} />
            </Typography>
            <Typography
              variant={'bodyBoldSmall'}
              sx={{
                color: color.gray_80,
              }}
            >
              <EllipsisText text={data?.name} length={'35'} />
            </Typography>
          </Flex>
        </PricesWrapper>
      </HeaderWrapper>
      <HeaderWrapper mt={'12px'}>
        <PricesWrapper>
          {data?.personal_price[choosedCurrency] > 0 ||
          data?.price[choosedCurrency] > 0 ? (
            <PricesTooltip prices={data.prices}>
              <TooltipInner>
                <Typography
                  variant={'bodyBoldLarge'}
                  sx={{
                    color: color.gray,
                  }}
                >
                  {data?.personal_price[choosedCurrency]} {currency}
                </Typography>
                {choosedCurrency === 'price' && (
                  <Typography
                    variant={'bodyBoldExSmall'}
                    sx={{
                      color: color.gray_60,
                      mb: '3px',
                    }}
                  >
                    {data?.personal_price['price_no_cash']} {currency}{' '}
                    {t('common:currencyBg')}
                  </Typography>
                )}
                <Typography
                  variant={'bodyBoldExSmall'}
                  sx={{
                    color: color.gray_60,
                  }}
                >
                  {data?.price[choosedCurrency]} {currency}
                </Typography>
              </TooltipInner>
            </PricesTooltip>
          ) : (
            <Typography
              sx={{
                color: color.gray,
                display: 'flex',
                alignItems: 'baseline',
                width: '90%',
                textAlign: 'left',
              }}
              variant={'bodyBoldMedium'}
              align={'center'}
              component={'p'}
            >
              {t('common:check_price_for_details')}
            </Typography>
          )}
        </PricesWrapper>
        {userPermissions['cart_access'] && (
          <>
            {addedToCart ? (
              <QuantityManager hide_amount={true} data={data} />
            ) : (
              <Box>
                <ToggleBtn
                  onClick={onClick}
                  size={32}
                  disabled={!data?.can_buy[choosedCurrency]}
                  isActive={data?.can_buy[choosedCurrency]}
                  href={`${Sprite}#icon-grocery_cart`}
                />
                {data?.relation_quantity && (
                  <Typography
                    sx={{ ml: '8px' }}
                    variant={'bodyBoldSmall'}
                    component={'p'}
                  >
                    x{data?.relation_quantity}
                  </Typography>
                )}
              </Box>
            )}
          </>
        )}
      </HeaderWrapper>
    </Cart>
  );
};

const Cart = styled.div`
  background: ${props => props.color};
  position: relative;
  z-index: 30;
  box-shadow: 6px 6px 10px rgba(54, 74, 99, 0.06);
  border-radius: 4px;
  padding: 12px;

  cursor: pointer;
  width: 100%;
  max-width: 242px;
  min-width: 242px;
  height: 129px;
`;

const Box = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const Img = styled.img`
  height: 38px;
  margin-right: 12px;
`;

const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: ${({ mt }) => (mt ? mt : 0)};
`;

const PricesWrapper = styled.div`
  margin-right: auto;
`;

const Flex = styled.div`
  display: flex;
  flex-direction: column;
`;

const TooltipInner = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

const Svg = styled.svg`
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
`;

CardSlider.propTypes = {
  data: PropTypes.exact({
    brand: PropTypes.string,
    can_buy: PropTypes.exact({
      price: PropTypes.bool,
      price_uah_cash: PropTypes.bool,
      price_uah_no_pdv: PropTypes.bool,
      price_uah_pdv: PropTypes.bool,
    }),
    cart: PropTypes.shape({
      cart_product_id: PropTypes.number,
      id: PropTypes.number,
      personal_amount: PropTypes.exact({
        price: PropTypes.string,
        price_uah_cash: PropTypes.string,
        price_uah_no_pdv: PropTypes.string,
        price_uah_pdv: PropTypes.string,
      }),
      quantity: PropTypes.number,
    }),
    description: PropTypes.string,
    has_analogs: PropTypes.bool,
    id: PropTypes.number,
    image: PropTypes.string,
    images_quantity: PropTypes.number,
    in_compare: PropTypes.bool,
    is_in_cart: PropTypes.bool,
    is_saved: PropTypes.bool,
    model: PropTypes.string,
    name: PropTypes.string,
    personal_price: PropTypes.exact({
      price: PropTypes.string,
      price_uah_cash: PropTypes.string,
      price_uah_no_pdv: PropTypes.string,
      price_uah_pdv: PropTypes.string,
    }),
    price: PropTypes.exact({
      price: PropTypes.string,
      price_uah_cash: PropTypes.string,
      price_uah_no_pdv: PropTypes.string,
      price_uah_pdv: PropTypes.string,
    }),
    prices: PropTypes.exact({
      price: PropTypes.exact({
        product: PropTypes.string,
        personal: PropTypes.string,
      }),
      price_uah_cash: PropTypes.exact({
        product: PropTypes.string,
        personal: PropTypes.string,
      }),
      price_uah_no_pdv: PropTypes.exact({
        product: PropTypes.string,
        personal: PropTypes.string,
      }),
      price_uah_pdv: PropTypes.exact({
        product: PropTypes.string,
        personal: PropTypes.string,
      }),
    }),
    sku: PropTypes.string,
    slug: PropTypes.string,
    stock: PropTypes.number,
    stock_max: PropTypes.number,
    stock_percent: PropTypes.number,
    url: PropTypes.string,
    warehouses: PropTypes.arrayOf(
      PropTypes.exact({
        city_name: PropTypes.string,
        warehouses: PropTypes.exact({
          name: PropTypes.string,
          stock: PropTypes.number,
          stock_max: PropTypes.number,
          stock_percent: PropTypes.number,
        }),
      }),
    ),
  }),
  addedToCart: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
};
