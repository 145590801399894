import log from 'eslint-plugin-react/lib/util/log';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import styled from '@emotion/styled';

import { Sprite } from '@root/assets/svg';
import { HeaderDefault } from '@root/components';
import AgreementsHTML from '@root/modules/Agreements/AgreementsHTML';
import { ButtonWithoutBorder, ModalBase } from '@root/ui';

import agreementsDockOperations from '@redux/agreements-dock/agreements-dock-operations';
import agreementsDockSelectors from '@redux/agreements-dock/agreements-dock-selectors';

import { Typography, useTheme } from '@mui/material';

const MODAL_TYPES = {
  assign: 'assign',
  dockType: 'dockType',
};

const AgreementsDockLayout = ({ targetDock }) => {
  const { color } = useTheme();
  const dispatch = useDispatch();
  const { t } = useTranslation(['profile'], { useSuspense: false });

  const [isOpenModal, setIsOpenModal] = useState(false);
  const [modalType, setModalType] = useState(null);
  const [isDockUpload, setIsDockUpload] = useState(false);

  const dockTypes = useSelector(agreementsDockSelectors.getDockTypes);
  const dockTemplate = useSelector(agreementsDockSelectors.getDockTemplate);
  const dockFile = useSelector(agreementsDockSelectors.getDockFile);

  const [isDock, setIsDock] = useState(false);

  useEffect(() => {
    if (dockTemplate?.html) setIsDock(true);
  }, [dockTemplate]);

  useEffect(() => {
    if (targetDock?.file_path) setIsDock(false);
  }, [targetDock]);

  useEffect(() => {
    dispatch(agreementsDockOperations.getDockTypes());
  }, []);

  const upload = async (form, info) => {
    setIsDockUpload(false);

    let isValid = info.title && info.number;

    if (!isValid) return;

    Object.values(form).forEach(el => {
      if (!el) isValid = false;
    });

    if (!isValid) return;

    try {
      const body = {
        title: info.title,
        treaty_file_id: dockTemplate?.id,
        number: info.number,
        data: JSON.stringify(form),
      };

      await dispatch(agreementsDockOperations.createDock(body));
    } catch (e) {
      console.log('Error upload dock.');
    }
  };

  const add = async () => {
    setModalType(MODAL_TYPES.dockType);
    setIsOpenModal(true);
  };

  const assignDock = async () => {
    setModalType(MODAL_TYPES.assign);
    setIsOpenModal(true);
    console.log('assignDock');
  };

  const downloadDock = async () => {
    console.log('downloadDock');
  };

  const assignMethod = method => {
    console.log('method: ', method);
  };

  const chooseDock = async id => {
    await dispatch(agreementsDockOperations.getDockById(id));
    setIsOpenModal(false);
  };

  return (
    <>
      <ButtonsWrapper>
        <ButtonWithoutBorder
          onClick={() => setIsDockUpload(true)}
          sx={{
            color: color.gray_60,
            borderRadius: '4px',
            display: 'flex',
            height: '36px',
            padding: '0 12px',
            justifyItems: 'center',
            alignItems: 'center',
            border: `1px solid ${color.gray_60}`,
            '&:hover': {
              color: color.main,
              background: 'none',
              border: `1px solid ${color.main}`,
            },
          }}
        >
          <Typography
            sx={{
              fontSize: '12px',
              fontWeight: '700',
            }}
            variant="bodyBoldSmall"
            color={color.gray}
          >
            {t('agreements.upload_btn')}
          </Typography>
        </ButtonWithoutBorder>
        <ButtonWithoutBorder
          onClick={add}
          sx={{
            marginLeft: '16px',
            color: color.gray_60,
            borderRadius: '4px',
            display: 'flex',
            height: '36px',
            padding: '0 12px',
            justifyItems: 'center',
            alignItems: 'center',
            border: `1px solid ${color.gray_60}`,
            '&:hover': {
              color: color.main,
              background: 'none',
              border: `1px solid ${color.main}`,
            },
          }}
        >
          <svg width={16} height={16} fill={'currentColor'}>
            <use href={`${Sprite}#icon-plus-btn`}></use>
          </svg>
          <Typography
            sx={{
              marginLeft: '6px',
              fontSize: '12px',
              fontWeight: '700',
            }}
            variant="bodyBoldSmall"
            color={color.gray}
          >
            {t('agreements.add_btn')}
          </Typography>
        </ButtonWithoutBorder>
      </ButtonsWrapper>
      <Wrapper color={color}>
        {(dockTemplate?.html || targetDock) && (
          <AgreementsHTML
            dock={dockTemplate?.html}
            assignDock={assignDock}
            downloadDock={downloadDock}
            upload={upload}
            isDockUpload={isDockUpload}
            dockFile={dockFile}
            targetDock={targetDock}
            isDock={isDock}
          />
        )}
      </Wrapper>
      {modalType === MODAL_TYPES.dockType && (
        <ModalBase
          handleClose={() => setIsOpenModal(false)}
          open={isOpenModal}
          modalHeader={
            <HeaderDefault
              onClose={() => setIsOpenModal(false)}
              text={t('agreements.dock_types_modal_header')}
            />
          }
        >
          {dockTypes.length &&
            dockTypes.map(({ id, name }) => (
              <ButtonWithoutBorder
                key={`key-${id}`}
                onClick={() => chooseDock(id)}
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                  borderRadius: '0',
                  width: '558px',
                  fontWeight: '400',
                  fontSize: '14px',
                  color: color.gray,
                  height: '32px',
                  padding: '0',
                  marginTop: '12px',
                  background: 'none',
                  border: 'none',
                  borderBottom: `1px solid ${color.line_light}`,
                  '&:hover': {
                    color: color.main,
                  },
                }}
              >
                <Typography variant="bodyMedium" color={color.gray}>
                  {name}
                </Typography>
              </ButtonWithoutBorder>
            ))}
        </ModalBase>
      )}
      {modalType === MODAL_TYPES.assign && (
        <ModalBase
          handleClose={() => setIsOpenModal(false)}
          open={isOpenModal}
          modalHeader={
            <HeaderDefault
              onClose={() => setIsOpenModal(false)}
              text={t('agreements.assign_modal_header')}
            />
          }
        >
          <ButtonWithoutBorder
            onClick={() => assignMethod('sign')}
            sx={{
              display: 'flex',
              justifyContent: 'flex-start',
              borderRadius: '0',
              width: '558px',
              fontWeight: '400',
              fontSize: '14px',
              color: color.gray,
              height: '32px',
              padding: '0',
              marginTop: '12px',
              background: 'none',
              border: 'none',
              borderBottom: `1px solid ${color.line_light}`,
              '&:hover': {
                color: color.main,
              },
            }}
          >
            <Typography variant="bodyMedium" color={color.gray}>
              {t('agreements.sign')}
            </Typography>
          </ButtonWithoutBorder>
          <ButtonWithoutBorder
            onClick={() => assignMethod('diia')}
            sx={{
              display: 'flex',
              justifyContent: 'flex-start',
              width: '558px',
              borderRadius: '0',
              padding: '0',
              fontWeight: '400',
              fontSize: '14px',
              color: color.gray,
              height: '32px',
              marginTop: '12px',
              background: 'none',
              border: 'none',
              borderBottom: `1px solid ${color.line_light}`,
              '&:hover': {
                color: color.main,
              },
            }}
          >
            <Typography variant="bodyMedium" color={color.gray}>
              {t('agreements.diia_sign')}
            </Typography>
          </ButtonWithoutBorder>
          <ButtonWithoutBorder
            onClick={() => assignMethod('vchasno')}
            sx={{
              display: 'flex',
              justifyContent: 'flex-start',
              width: '558px',
              fontWeight: '400',
              padding: '0',
              fontSize: '14px',
              color: color.gray,
              height: '32px',
              marginTop: '12px',
              background: 'none',
              border: 'none',
              '&:hover': {
                color: color.main,
              },
            }}
          >
            <Typography variant="bodyMedium" color={color.gray}>
              {t('agreements.vchasno_sign')}
            </Typography>
          </ButtonWithoutBorder>
        </ModalBase>
      )}
    </>
  );
};

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  min-height: 76vh;
  padding: 17px 17px 0;
  border-radius: 4px;
  background-color: ${({ color }) => color.line};
  border: 1px solid ${({ color }) => color.line_light};
`;

const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 9px;
`;

export default AgreementsDockLayout;
