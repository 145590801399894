import React from 'react';

import { t } from 'i18next';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { useNavigation } from '@root/hooks';
import { ButtonBase } from '@root/ui';

import { Typography, useTheme } from '@mui/material';

export const EmptyBox = ({
  title = t('home:empty_title'),
  titleSx,
  subTitle = t('home:empty_subtitle'),
  buttonTittle = t('home:buttons.go_to_catalog'),
  boxStyles,
  variants,
  callbackBtn,
  children,
}) => {
  const { navigation } = useNavigation();
  const { color } = useTheme();

  const navigateTo = url => {
    return navigation(url);
  };

  return (
    <Wrapper styles={boxStyles}>
      {children}
      <Typography
        variant={variants ? variants.title : 'h1'}
        component={'p'}
        color={color.gray}
        sx={{ marginBottom: '24px', fontSize: '16px', ...titleSx }}
      >
        {title}
      </Typography>
      {subTitle && (
        <Typography
          variant={'bodyBoldSmall'}
          component={'span'}
          color={color.gray_60}
          sx={{ marginBottom: '24px' }}
        >
          {subTitle}
        </Typography>
      )}
      {buttonTittle && (
        <ButtonBase
          sx={{ minHeight: '38px' }}
          onClick={() => (callbackBtn ? callbackBtn() : navigateTo('/catalog'))}
        >
          <Typography
            component={'span'}
            color={color.white}
            variant={'bodyBoldSmall'}
          >
            {buttonTittle}
          </Typography>
        </ButtonBase>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  align-items: center;
  text-align: center;
  ${({ styles }) => styles};
`;

EmptyBox.propTypes = {
  title: PropTypes.string,
  titleSx: PropTypes.object,
  subTitle: PropTypes.string,
  buttonTittle: PropTypes.string,
  boxStyles: PropTypes.object,
  variants: PropTypes.string,
  callbackBtn: PropTypes.func,
  children: PropTypes.object,
};
