import React, { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';

import styled from 'styled-components';

import { Blank } from '@root/assets/image';
import { StepperWithOutSpace } from '@root/components';
import { CustomScroll } from '@root/ui';

import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';

import { RowItem } from './Item';

export const OrderServiceDetails = forwardRef(
  ({ selectedServiceOrder, width, height }, ref) => {
    const { color } = useTheme();
    const { t } = useTranslation('order_service', { useSuspense: false });

    const getProperHistory = () => {
      return (
        selectedServiceOrder &&
        selectedServiceOrder?.history.map(row => ({
          label: row.status,
          description: row.status_date,
        }))
      );
    };

    return (
      <BoxWrap
        color={color.line}
        bg={color.white}
        width={width || '40%'}
        height={height || 'calc(100vh - 180px)'}
        ref={ref}
      >
        {selectedServiceOrder ? (
          <CustomScroll vertical>
            <ContentWrap>
              <RowItem
                title={t('order_num')}
                value={selectedServiceOrder?.number}
              />
              <RowItem title={t('date')} value={selectedServiceOrder?.date} />
              <RowItem
                title={t('equipment')}
                value={selectedServiceOrder?.model}
              />
              <RowItem
                title={t('serial_num')}
                value={selectedServiceOrder?.serial}
              />
              <RowItem
                title={t('complect')}
                value={selectedServiceOrder?.complect}
              />
              <RowItem
                title={t('status')}
                value={selectedServiceOrder?.status}
              />
              <RowItem
                title={t('warranty_case')}
                value={selectedServiceOrder?.type}
              />
              <RowItem
                title={t('declared_malfunction')}
                value={selectedServiceOrder?.malfunction}
              />
              <RowItem
                title={t('repairs')}
                value={selectedServiceOrder?.repair_comments}
              />
              <StepperWrap bg={color.line}>
                <Typography
                  sx={{ color: color.gray_80, minWidth: '187px' }}
                  variant={'bodySmall'}
                  component={'span'}
                >
                  {t('service_history')}
                </Typography>
                <StepperWithOutSpace
                  steps={getProperHistory()}
                  activeStep={selectedServiceOrder?.history.length - 1}
                />
              </StepperWrap>
            </ContentWrap>
          </CustomScroll>
        ) : (
          <EmptyBox bg={color.white}>
            <img src={Blank} alt="Blank" />
            <Typography
              variant={'bodySmall'}
              component={'p'}
              sx={{
                display: 'flex',
                alignItems: 'center',
                mt: '40px',
                color: color.gray_80,
                textAlign: 'center'
              }}
            >
              {t('order_service:select_service_order_to_see_details')}
            </Typography>
          </EmptyBox>
        )}
      </BoxWrap>
    );
  },
);

OrderServiceDetails.propTypes = {};

const BoxWrap = styled.div`
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  border: 1px solid ${props => props.color};
  border-radius: 4px;
  background: ${props => props.bg};

  @media (max-width: 1400px) {
    width: 100%;
    height: auto;
  }
`;

const ContentWrap = styled.div`
  display: flex;
  flex-direction: column;
`;

const StepperWrap = styled.div`
  padding: 12px 24px;
  display: flex;
  position: relative;

  @media (max-width: 576px) {
    flex-direction: column;
    gap: 10px;
  }
`;

const EmptyBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 50px;
  background: ${props => props.bg};
`;
