import { useMemo } from 'react';
import EllipsisText from 'react-ellipsis-text';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Sprite } from '@root/assets/svg';
import { StatusWithoutSteps, StepperStatusTyne } from '@root/components';

import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';

export const CartHistory = ({ isActive, onClick, data }) => {
  const { color } = useTheme();
  const { t } = useTranslation(['order_history', 'common'], {
    useSuspense: false,
  });
  const { orderTab } = useParams();
  const { status, title } = data;
  const isCommercialOffersTab = orderTab === 'my-commercial-offers';

  const StatusInMemo = useMemo(
    () => () => {
      switch (orderTab) {
        case 'my-orders':
        case 'my-commercial-offers':
          if (status === 'Saved') {
            return (
              <InfoOrderStatus>
                <Svg>
                  <use href={`${Sprite}#icon-heart-status`} />
                </Svg>
                <Typography variant={'bodyBoldSmall'} color={color.gray_80}>
                  {t('order_history:saved')}
                </Typography>
              </InfoOrderStatus>
            );
          } else if (data?.statuses && data?.statuses.length > 0) {
            return (
              <StepperStatusTyne
                steps={data?.statuses}
                withSteps={!isCommercialOffersTab}
              />
            );
          }
          break;
        default:
          return <StatusWithoutSteps data={data} />;
      }
    },
    [status, data?.statuses],
  );

  const calculateAmount = () => {
    return data?.details?.reduce((acc, item) => {
      return acc + +item.amount * item.quantity;
    }, 0);
  };

  return (
    <Components
      colors={{ main: color.main, line: color.line, gray_20: color.gray_20 }}
      onClick={() => onClick(data?.id)}
      color={isActive ? color.main_light : color.white}
      isActive={isActive}
    >
      <FlexContainer style={{ marginBottom: '8px' }}>
        <InfoOrder>
          <Typography
            variant={'bodyBoldMedium'}
            component={'p'}
            sx={{
              display: 'flex',
              alignItems: 'center',
              mb: '4px',
              color: color.gray,
            }}
          >
            {status === 'Saved' || isCommercialOffersTab ? title : `№ ${title}`}
          </Typography>

          <Typography
            variant={'bodySmall'}
            component={'p'}
            sx={{
              display: 'flex',
              alignItems: 'center',
              color: color.gray_60,
            }}
          >
            {data?.note && <EllipsisText text={data?.note} length={200} />}
          </Typography>
        </InfoOrder>
        <StatusInMemo />
      </FlexContainer>
      {data?.detail && (
        <FlexContainer style={{ marginBottom: '10px' }}>
          <Typography color={color.gray_60} variant="bodySmall">
            {data?.detail && <EllipsisText text={data?.detail} length={200} />}
          </Typography>
        </FlexContainer>
      )}
      <FlexContainer>
        <FlexContainer>
          <Typography
            variant={'bodySmall'}
            component={'p'}
            sx={{
              display: 'flex',
              alignItems: 'center',
              color: color.gray_60,
            }}
          >
            {data?.quantity}{' '}
            {t('order_history:items_count.key', { count: +data?.quantity })}
          </Typography>
          <Typography
            variant={'bodyBoldSmall'}
            component={'p'}
            sx={{
              display: 'flex',
              alignItems: 'center',
              ml: '14px',
              color: color.gray,
            }}
          >
            {data?.use_price ? data?.[data?.use_price] : `${data?.amount}`}{' '}
            {data?.currency ? data?.currency : t('common:currency')}
          </Typography>
        </FlexContainer>
        <Typography
          variant={'bodySmall'}
          component={'p'}
          sx={{
            display: 'flex',
            alignItems: 'center',
            ml: 'auto',
            color: color.gray_60,
          }}
        >
          {t('order_history:create')} {data?.created}
        </Typography>
      </FlexContainer>
    </Components>
  );
};

const Components = styled.li`
  padding: 16px;
  cursor: pointer;
  background: ${({ color }) => color};
  border-left: ${({ isActive, colors }) =>
    isActive ? `3px solid ${colors.main}` : ''};
  border-bottom: 1px solid ${props => props.colors.line};

  &:hover {
    background: ${({ colors, isActive }) => !isActive && colors.gray_20};
  }
`;

const FlexContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const InfoOrder = styled.div`
  margin-right: 50px;
`;

const InfoOrderStatus = styled.div`
  display: flex;
  column-gap: 6px;
  margin-top: 1px;
  width: 130px;
`;

const Svg = styled.svg`
  width: 14px;
  height: 14px;
`;

CartHistory.propTypes = {
  isActive: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  data: PropTypes.exact({
    amount: PropTypes.string,
    created: PropTypes.string,
    currency: PropTypes.string,
    id: PropTypes.number,
    note: PropTypes.string,
    quantity: PropTypes.number,
    status: PropTypes.string,
    title: PropTypes.string,
  }),
};
