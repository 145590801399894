import { createSlice } from '@reduxjs/toolkit';

import { updateComparison, updateFavorite } from '@root/helpers';

import comparisonsOperation from '../comparisons/comparisons-operation';
import favoriteOperation from '../favorite/favorite-operation';
import savedOrdersOperation from '../saved-orders/saved-orders-operation';
import operation from './cart-operation';

const initialState = {
  cartData: [],
  spacialPrise: 0,
  totalPrice: 0,
  totalQuantity: 0,
  firstLoading: true,
  loading: false,
  errors: [],
};

const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    updateCartBySaved(state, { payload }) {
      state.cartData = state.cartData.map(el => {
        if (el.id === payload.savedId) {
          return { ...el, is_saved: !el.is_saved };
        } else {
          return el;
        }
      });
    },
    updateCart(state, { payload }) {
      const { products, special_total_price, total_price, total_quantity } =
        payload.data;

      state.cartData = products;
      state.spacialPrise = special_total_price;
      state.totalPrice = total_price;
      state.totalQuantity = total_quantity;
    },
    onClear(state, { payload }) {
      state.cartData = initialState.cartData;
      state.firstLoading = initialState.firstLoading;
    },
    onClearErrors(state, { payload }) {
      state.errors = initialState.errors;
    },
  },
  extraReducers: {
    // ================================Global-Store-Listeners=============================//
    [savedOrdersOperation.addSavedOrderProductToCart.fulfilled]: (
      state,
      { payload },
    ) => {
      const { products, special_total_price, total_price, total_quantity } =
        payload.data;
      state.cartData = products;
      state.spacialPrise = special_total_price;
      state.totalPrice = total_price;
      state.totalQuantity = total_quantity;
    },
    [savedOrdersOperation.addSavedOrderToCart.fulfilled]: (
      state,
      { payload },
    ) => {
      const { products, special_total_price, total_price, total_quantity } =
        payload.data;
      state.cartData = products;
      state.spacialPrise = special_total_price;
      state.totalPrice = total_price;
      state.totalQuantity = total_quantity;
    },
    [savedOrdersOperation.cancelAddSavedOrderToCart.fulfilled]: (
      state,
      { payload },
    ) => {
      const { products, special_total_price, total_price, total_quantity } =
        payload.data;
      state.cartData = products;
      state.spacialPrise = special_total_price;
      state.totalPrice = total_price;
      state.totalQuantity = total_quantity;
    },
    [favoriteOperation.postProductToFavorite.fulfilled]: (
      state,
      { payload },
    ) => {
      const { id } = payload;
      state.cartData = updateFavorite(state.cartData, id);
    },
    [favoriteOperation.deleteProductFromSaved.fulfilled]: (
      state,
      { payload },
    ) => {
      const { id } = payload;
      state.cartData = updateFavorite(state.cartData, id);
    },
    [comparisonsOperation.addToComparisons.fulfilled]: (state, { payload }) => {
      const { id } = payload;
      state.cartData = updateComparison(state.cartData, id);
    },
    [comparisonsOperation.removeFromComparisons.fulfilled]: (
      state,
      { payload },
    ) => {
      const { id } = payload;
      state.cartData = updateComparison(state.cartData, id);
    },
    //====getCartList====//
    [operation.getCartList.fulfilled]: (state, { payload }) => {
      const { products, special_total_price, total_price, total_quantity } =
        payload.data;
      state.cartData = products;
      state.spacialPrise = special_total_price;
      state.totalPrice = total_price;
      state.totalQuantity = total_quantity;
      state.firstLoading = false;
    },
    [operation.getCartList.rejected]: (state, { payload }) => {
      state.firstLoading = false;
    },
    //====addProductToCart====//

    [operation.addProductToCart.fulfilled]: (state, { payload }) => {
      const { data } = payload;
      const { products, special_total_price, total_price, total_quantity } =
        data.data;

      state.cartData = products;
      state.spacialPrise = special_total_price;
      state.totalPrice = total_price;
      state.totalQuantity = total_quantity;
      state.totalQuantity = total_quantity;
    },

    //====putOneToCart====//

    [operation.putOneToCart.fulfilled]: (state, { payload }) => {
      const { data } = payload;
      const { products, special_total_price, total_price, total_quantity } =
        data.data;
      state.cartData = products;
      state.spacialPrise = special_total_price;
      state.totalPrice = total_price;
      state.totalQuantity = total_quantity;
    },

    //====deleteOneFromCart====//

    [operation.deleteOneFromCart.fulfilled]: (state, { payload }) => {
      const { data } = payload;
      const { products, special_total_price, total_price, total_quantity } =
        data.data;
      state.cartData = products;
      state.spacialPrise = special_total_price;
      state.totalPrice = total_price;
      state.totalQuantity = total_quantity;
    },

    //====clearCart====//
    [operation.clearCart.pending]: (state, action) => {
      state.loading = true;
    },
    [operation.clearCart.fulfilled]: (state, { payload }) => {
      state.cartData = initialState.cartData;
      state.spacialPrise = initialState.spacialPrise;
      state.totalPrice = 0;
      state.totalQuantity = initialState.totalQuantity;
      state.loading = false;
    },
    [operation.clearCart.rejected]: (state, { payload }) => {
      state.loading = false;
    },
    //====addToOrder====//
    [operation.addToOrder.pending]: (state, action) => {
      state.loading = true;
    },
    [operation.addToOrder.fulfilled]: (state, { payload }) => {
      state.loading = false;
    },
    [operation.addToOrder.rejected]: (state, { payload }) => {
      state.loading = false;
      state.errors = payload;
    },
    //====postSavedToCart====//
    [operation.postSavedToCart.pending]: (state, action) => {
      state.loading = true;
    },
    [operation.postSavedToCart.fulfilled]: (state, { payload }) => {
      const { products, special_total_price, total_price, total_quantity } =
        payload.data;
      state.cartData = products;
      state.spacialPrise = special_total_price;
      state.totalPrice = total_price;
      state.totalQuantity = total_quantity;
      state.loading = false;
    },
    [operation.postSavedToCart.rejected]: (state, { payload }) => {
      state.loading = false;
    },
    //====deleteFavoriteFromCart====//
    [operation.deleteFavoriteFromCart.fulfilled]: (state, { payload }) => {
      const { products, special_total_price, total_price, total_quantity } =
        payload.data;
      state.cartData = products;
      state.spacialPrise = special_total_price;
      state.totalPrice = total_price;
      state.totalQuantity = total_quantity;
    },
  },
});

export const { updateCart, onClear, onClearErrors } = cartSlice.actions;

export default cartSlice.reducer;
