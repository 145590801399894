import { createSlice } from '@reduxjs/toolkit';

import operationOrder from '../order/order-operation';
import operationProduct from '../product/product-operation';
import operation from './option-operation';

const initialState = {
  cities: [],
  activityAreas: [],
  filialList: [],
  historyFilters: {},
  logisticServices: [],
  brands: [],
  langs: [],
  loading: false,
};

const authSlice = createSlice({
  name: 'option',
  initialState,
  extraReducers: {
    // ================================Global-Store-Listeners=============================//
    //====getArchiveOrders====//
    [operationOrder.getArchiveOrders.fulfilled]: (state, { payload }) => {
      const { amount, filter, period } = payload;
      state.historyFilters = { amount, filter: [filter], period };
    },
    //====setProductByEditId====//
    [operationProduct.getProductByEditId.fulfilled]: (state, { payload }) => {
      const { brands, langs } = payload.data;

      state.brands = brands;
      state.langs = langs;
    },
    // ================================End-Global-Store-Listeners=============================//
    //====getCity====//
    [operation.getCity.pending]: (state, action) => {
      state.loading = true;
    },
    [operation.getCity.fulfilled]: (state, { payload }) => {
      state.cities = payload;
      state.loading = false;
    },
    [operation.getCity.rejected]: (state, { payload }) => {
      state.loading = false;
    },

    //====getActivityAreas====//
    [operation.getActivityAreas.pending]: (state, action) => {
      state.loading = true;
    },
    [operation.getActivityAreas.fulfilled]: (state, { payload }) => {
      state.activityAreas = payload;
      state.loading = false;
    },
    [operation.getActivityAreas.rejected]: (state, { payload }) => {
      state.loading = false;
    },
    //====getFilialList====//
    [operation.getFilialList.pending]: (state, action) => { },
    [operation.getFilialList.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.filialList = payload;
    },
    [operation.getFilialList.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
    //====getHistoryFilters====//
    [operation.getHistoryFilters.pending]: (state, action) => {
      state.loading = true;
    },
    [operation.getHistoryFilters.fulfilled]: (state, { payload }) => {
      state.historyFilters = payload;
      state.loading = false;
    },
    [operation.getHistoryFilters.rejected]: (state, { payload }) => {
      state.loading = false;
    },
    //====getHistoryFilters====//
    [operation.getCoFilters.pending]: (state, action) => {
      state.loading = true;
    },
    [operation.getCoFilters.fulfilled]: (state, { payload }) => {
      state.historyFilters = payload;
      state.loading = false;
    },
    [operation.getCoFilters.rejected]: (state, { payload }) => {
      state.loading = false;
    },
    //====getLogisticServices====//
    [operation.getLogisticServices.pending]: (state, action) => {
      state.loading = true;
    },
    [operation.getLogisticServices.fulfilled]: (state, { payload }) => {
      state.logisticServices = payload;
      state.filialList = payload[2]?.filials;
      state.loading = false;
    },
    [operation.getLogisticServices.rejected]: (state, { payload }) => {
      state.loading = false;
    },

    //====getLangs====//
    [operation.getLangs.pending]: (state, action) => { },
    [operation.getLangs.fulfilled]: (state, { payload }) => {
      state.langs = payload;
    },
    [operation.getLangs.rejected]: (state, { payload }) => { },

    //====getBrands====//
    [operation.getBrands.pending]: (state, action) => { },
    [operation.getBrands.fulfilled]: (state, { payload }) => {
      state.brands = payload;
    },
    [operation.getBrands.rejected]: (state, { payload }) => { },

    //====addBrands====//
    [operation.addBrands.pending]: (state, action) => { },
    [operation.addBrands.fulfilled]: (state, { payload }) => {
      state.brands = payload;
    },
    [operation.addBrands.rejected]: (state, { payload }) => { },
  },
});
export default authSlice.reducer;
