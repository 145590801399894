import React, { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import {
  AutoSizer,
  CellMeasurer,
  CellMeasurerCache,
  List,
} from 'react-virtualized';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Empty, Header, VirtualizedTable } from '@root/components';
import { DeliveryHistoryConf } from '@root/conf';
import { DeliveryHistoryNavigation } from '@root/modules/DeliveryHistory/DeliveryHistoryNavigation';
import { WidthContext } from '@root/providers/WidthProvider';
import { LabelStatus, ProgressLoader } from '@root/ui';

import logisticSelectors from '@redux/logistic/logistic-selectors';
import {
  setSearchValue,
  setSelectedDelivery,
} from '@redux/logistic/logistic-slice';

import { TableBody, TableCell, TableRow, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

export const DeliveryHistory = ({
  handleFetchDeliveryHistory,
  onItemClick,
}) => {
  const { color } = useTheme();
  const { t } = useTranslation(['common'], { useSuspense: false });

  const isHistoryLoading = useSelector(
    logisticSelectors.getIsDeliveryHistoryLoading,
  );

  const [isSearchExpanded, setIsSearchExpanded] = useState(false);
  const [activeRow, setActiveRow] = useState();

  const { logisticsTab } = useParams();

  const { screenWidth } = useContext(WidthContext);

  const dispatch = useDispatch();

  const filteredDeliveryHistory = useSelector(
    logisticSelectors.getFilteredogistic,
  );

  const isDeliveryHistoryLoading = useSelector(
    logisticSelectors.getIsDeliveryHistoryLoading,
  );

  const searchTerm = useSelector(logisticSelectors.getSearchValue);

  const handleToggleSearchExpended = () =>
    setIsSearchExpanded(prevIsSearchExpanded => !prevIsSearchExpanded);

  const cache = useRef(
    new CellMeasurerCache({
      fixedWidth: true,
      defaultHeight: 50,
    }),
  );

  const handleSetActiveRow = selectedRow => {
    onItemClick();
    const {
      number,
      repair: repair_array,
      return: return_array,
      logistic_service,
      ttn_number,
      phone_number,
    } = selectedRow;
    setActiveRow(number);
    dispatch(
      setSelectedDelivery({
        repair_array,
        return_array,
        logistic_service,
        ttn_number,
        phone_number,
      }),
    );
  };

  const handleResetActiveRow = () => {
    setActiveRow(0);
  };

  useEffect(() => {
    if (!cache.current && isHistoryLoading) return;
    cache.current.clearAll();
  }, [isHistoryLoading]);

  return (
    <DeliveryHistoryWrapper background={color.white} border={color.line}>
      <DeliveryHistoryNavigation
        handleFetchDeliveryHistory={handleFetchDeliveryHistory}
      >
        <Header
          isTopBorder={true}
          isBottomBorder={true}
          isExpanded={isSearchExpanded}
          handleFetchDeliveryHistory={handleFetchDeliveryHistory}
          handleToggleSearchExpended={handleToggleSearchExpended}
          onSearchTermUpdate={searchTerm =>
            dispatch(setSearchValue(searchTerm))
          }
        />

        <div
          style={{
            position: 'relative',
            height: screenWidth < 1025 ? 'auto' : 'calc(100vh - 271px)',
          }}
        >
          {isDeliveryHistoryLoading && <ProgressLoader />}
          {filteredDeliveryHistory.length > 0 && (
            <VirtualizedTable
              tabType={logisticsTab}
              isSearchExpanded={isSearchExpanded}
              handleResetActiveRow={handleResetActiveRow}
              tableConf={DeliveryHistoryConf}
              checkIsInclude={true}
            >
              <TableBody
                sx={{
                  height: 'calc(100vh - 305px)',
                  display: 'block !important',
                }}
              >
                <ScrollWrapper
                  colors={{
                    gray: color.gray_40,
                    white: color.white,
                    line: color.line,
                  }}
                >
                  <AutoSizer>
                    {({ width, height }) => (
                      <List
                        width={width}
                        height={height}
                        rowHeight={cache.current.rowHeight}
                        defferedMeasurementCache={cache.current}
                        rowCount={filteredDeliveryHistory.length}
                        rowRenderer={({ key, index, style, parent }) => {
                          const data = filteredDeliveryHistory[index];

                          return (
                            <CellMeasurer
                              key={key}
                              cache={cache.current}
                              parent={parent}
                              columnIndex={0}
                              rowIndex={index}
                            >
                              <TableRow
                                onClick={() => handleSetActiveRow(data)}
                                sx={{
                                  borderBottom: `1px solid ${color.line}`,
                                  '&:last-child, &:last-child td': {
                                    borderBottom: 0,
                                  },
                                  userSelect: 'auto',
                                  boxShadow: 'none',
                                  th: {
                                    borderBottom: 'none',
                                  },
                                  display: 'flex',
                                  alignItems: 'center',
                                  padding: '4px 16px',
                                  cursor: 'pointer',
                                  background:
                                    activeRow === data.number
                                      ? color.main_light
                                      : color.white,
                                  borderLeft:
                                    activeRow === data.number
                                      ? `3px solid ${color.main}`
                                      : '',

                                  '&:hover': {
                                    background:
                                      activeRow !== data.number &&
                                      color.gray_20,
                                  },
                                }}
                                style={style}
                              >
                                {DeliveryHistoryConf(t, screenWidth).map(
                                  delivery => {
                                    switch (delivery.slug) {
                                      case 'date':
                                        return (
                                          delivery.type.includes(
                                            logisticsTab,
                                          ) &&
                                          delivery.isDisplay && (
                                            <TableCell
                                              sx={{
                                                borderBottom: 'none',
                                                ...delivery.style,
                                                p: 0,
                                                display: 'flex',
                                                flexDirection: 'column',
                                                gap: '8px',
                                              }}
                                              key={delivery.slug}
                                            >
                                              {screenWidth < 769 && (
                                                <Typography
                                                  variant="bodyBoldSmall"
                                                  color={color.gray}
                                                >
                                                  #{data.number}
                                                </Typography>
                                              )}

                                              {screenWidth < 569 && (
                                                <MobileWrapper>
                                                  {data.logistic_service ===
                                                    'Нова Пошта' &&
                                                    !data.ttn_number && (
                                                      <Typography
                                                        color={color.gray}
                                                        variant="bodyBoldSmall"
                                                      >
                                                        {data.logistic_service ||
                                                          '-'}
                                                      </Typography>
                                                    )}{' '}
                                                  {data.logistic_service ===
                                                    'Нова Пошта' &&
                                                    data.ttn_number && (
                                                      <Typography
                                                        variant="bodySmall"
                                                        color={color.gray_80}
                                                      >
                                                        TTH №
                                                        {data.ttn_number || '-'}
                                                      </Typography>
                                                    )}
                                                  <Typography
                                                    variant="bodySmall"
                                                    color={color.gray}
                                                  >
                                                    {t(
                                                      'common:table.table_heads.sits',
                                                    )}
                                                    : {data.places}
                                                  </Typography>
                                                  {logisticsTab ===
                                                    'sending-to-client' && (
                                                    <Typography
                                                      variant="bodySmall"
                                                      color={color.gray}
                                                    >
                                                      {t(
                                                        'common:table.table_heads.weight',
                                                      )}
                                                      : {data.weight}
                                                    </Typography>
                                                  )}
                                                </MobileWrapper>
                                              )}

                                              <Typography
                                                variant="bodySmall"
                                                color={color.gray_80}
                                              >
                                                {data.date}
                                              </Typography>
                                            </TableCell>
                                          )
                                        );

                                      case 'document_number':
                                        return (
                                          delivery.type.includes(
                                            logisticsTab,
                                          ) &&
                                          delivery.isDisplay && (
                                            <TableCell
                                              sx={{
                                                borderBottom: 'none',
                                                ...delivery.style,
                                                p: 0,
                                              }}
                                              key={delivery.slug}
                                            >
                                              <Typography
                                                variant="bodyBoldSmall"
                                                color={color.gray}
                                              >
                                                #{data.number}
                                              </Typography>
                                            </TableCell>
                                          )
                                        );

                                      case 'delivery_method':
                                        return (
                                          delivery.type.includes(
                                            logisticsTab,
                                          ) &&
                                          delivery.isDisplay && (
                                            <TableCell
                                              sx={{
                                                borderBottom: 'none',
                                                ...delivery.style,
                                                p: 0,
                                              }}
                                              key={delivery.slug}
                                            >
                                              {data.logistic_service ===
                                                'Нова Пошта' &&
                                              !data.ttn_number ? null : (
                                                <Typography
                                                  color={color.gray}
                                                  variant="bodyBoldSmall"
                                                >
                                                  {data.logistic_service}
                                                </Typography>
                                              )}{' '}
                                              {data.logistic_service ===
                                                'Нова Пошта' &&
                                                data.ttn_number && (
                                                  <Typography
                                                    variant="bodySmall"
                                                    color={color.gray_80}
                                                  >
                                                    TTH №{data.ttn_number}
                                                  </Typography>
                                                )}
                                            </TableCell>
                                          )
                                        );

                                      case 'weight':
                                        return (
                                          delivery.type.includes(
                                            logisticsTab,
                                          ) &&
                                          delivery.isDisplay && (
                                            <TableCell
                                              sx={{
                                                borderBottom: 'none',
                                                ...delivery.style,
                                                p: 0,
                                              }}
                                              key={delivery.slug}
                                            >
                                              <Typography
                                                variant="bodySmall"
                                                color={color.gray}
                                              >
                                                {data.weight}
                                              </Typography>
                                            </TableCell>
                                          )
                                        );

                                      case 'sits':
                                        return (
                                          delivery.type.includes(
                                            logisticsTab,
                                          ) &&
                                          delivery.isDisplay && (
                                            <TableCell
                                              sx={{
                                                borderBottom: 'none',
                                                ...delivery.style,
                                                p: 0,
                                              }}
                                              key={delivery.slug}
                                            >
                                              <Typography
                                                variant="bodySmall"
                                                color={color.gray}
                                              >
                                                {data.places}
                                              </Typography>
                                            </TableCell>
                                          )
                                        );

                                      case 'status':
                                        return (
                                          delivery.type.includes(
                                            logisticsTab,
                                          ) &&
                                          delivery.isDisplay && (
                                            <TableCell
                                              sx={{
                                                borderBottom: 'none',
                                                ...delivery.style,
                                                p: 0,
                                              }}
                                              key={delivery.slug}
                                            >
                                              <LabelStatus>
                                                <Typography
                                                  variant="bodyBoldSmall"
                                                  color={`${color.gray} !important`}
                                                >
                                                  {data.status}
                                                </Typography>
                                              </LabelStatus>
                                            </TableCell>
                                          )
                                        );
                                    }
                                  },
                                )}
                              </TableRow>
                            </CellMeasurer>
                          );
                        }}
                      />
                    )}
                  </AutoSizer>
                </ScrollWrapper>
              </TableBody>
            </VirtualizedTable>
          )}

          {searchTerm === '' && filteredDeliveryHistory.length === 0 && (
            <Empty type={'NoHistory'} />
          )}

          {searchTerm !== '' && filteredDeliveryHistory.length === 0 && (
            <Empty />
          )}
        </div>
      </DeliveryHistoryNavigation>
    </DeliveryHistoryWrapper>
  );
};

DeliveryHistory.propTypes = {
  handleFetchDeliveryHistory: PropTypes.func.isRequired,
};

const DeliveryHistoryWrapper = styled.div`
  border: ${({ border }) => `1px solid ${border}`};
  border-radius: 4px;
  background: ${({ background }) => background};
  flex: 0 0 42%;

  @media (max-width: 1400px) {
    flex: 0 0 50%;
  }

  @media (max-width: 1400px) {
    flex: 0 0 calc(60% - 24px);
  }

  @media (max-width: 1024px) {
    flex: 0 0 100%;
  }
`;

const MobileWrapper = styled.div`
  @media (max-width: 576px) {
    display: flex;
    flex-direction: column;
  }
`;

const ScrollWrapper = styled.div`
  overflow: hidden;
  height: inherit;
  max-height: inherit;

  div {
    ::-webkit-scrollbar {
      width: 5px;
    }

    ::-webkit-scrollbar-thumb {
      background: ${props => props.colors.gray};
      border-radius: 4px;
    }

    ::-webkit-scrollbar-track {
      background: ${props => props.colors.white};
      border-left: 1px solid ${props => props.colors.line};
    }
  }
`;
