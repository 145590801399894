import * as React from 'react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import PropTypes from 'prop-types';
import style from 'styled-components';

import { Sprite } from '@root/assets/svg';
import {
  AccordionBase,
  CreateSavedModal,
  EditCategoryListModal,
  Tabs,
} from '@root/components';
import { CustomScroll, IconBtn, MenuBurger } from '@root/ui';

import authSelectors from '@redux/auth/auth-selectors';
import categoryOperation from '@redux/category/category-operation';
import categorySelectors from '@redux/category/category-selectors';
import favoriteSelectors from '@redux/favorite/favorite-selectors';
import productOperation from '@redux/product/product-operation';

import {
  Divider,
  Drawer,
  List,
  ListItem,
  Typography,
  useTheme,
} from '@mui/material';
import { styled } from '@mui/material/styles';

const drawerWidth = 240;

const Section = styled('section', {
  shouldForwardProp: prop => prop !== 'open',
})(({ theme, open, overflow, mobileCatalogStyles }) => ({
  overflow: overflow,
  position: 'relative',
  height: 'calc(100% - 68px)',
  flexGrow: 1,
  padding: !mobileCatalogStyles ? '20px 32px 0 24px ' : '15px',
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: `-${drawerWidth}px`,
  ...(open && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  }),
  '@media(max-width: 768px)': {
    width: '100%',
    padding: '15px',
  },
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  padding: '8px 16px 8px 24px',
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'space-between',
  alignItems: 'center',

  button: {
    marginLeft: '8px',
  },
}));

const FlexCont = styled('div')(({ theme }) => ({
  display: 'flex',

  justifyContent: 'space-between',
  alignItems: 'center',
}));

export const DrawerCustom = ({ children, overflow, mobileCatalogStyles }) => {
  const { category } = useParams();
  const userRole = useSelector(authSelectors.getRole);
  const [open, setOpen] = useState(true);
  const [modalOpen, setModalOpen] = useState(false);
  const [isRefresh, setIsRefresh] = useState(false);
  const [expanded, setExpanded] = useState('new');
  const [openSavedModal, setOpenSavedModal] = useState(false);
  //should be replaced by logic from the backend
  const dispatch = useDispatch();
  const newFavoriteId = useSelector(favoriteSelectors.getNewFavoriteId);
  const menuData = useSelector(categorySelectors.getCategory);

  const { t } = useTranslation(['catalog', 'common'], { useSuspense: false });
  const { color } = useTheme();

  useEffect(() => {
    if (!!newFavoriteId) dispatch(categoryOperation.getCategory());
  }, [newFavoriteId]);

  useEffect(() => {
    dispatch(categoryOperation.getCategory());
    if (expanded !== category) setExpanded(category);
    const stateDrawer = JSON.parse(localStorage.getItem('drawerState'));
    setIsRefresh(true);
    if (typeof stateDrawer !== 'object') setOpen(stateDrawer);
  }, []);

  const handleDrawerOpen = () => {
    localStorage.setItem('drawerState', true);
    setOpen(true);
  };

  const handleOpenModal = () => {
    setOpenSavedModal(true);
  };

  const handleCloseModal = () => {
    setOpenSavedModal(false);
  };

  const handleDrawerClose = () => {
    localStorage.setItem('drawerState', false);
    setOpen(false);
  };

  const handleChange = panel => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleOpenCategoryEditor = () => {
    dispatch(categoryOperation.getCategoryListToAdmin());
    setModalOpen(true);
  };

  const handleCloseCategoryModal = () => {
    setModalOpen(false);
  };

  const handleById = el => {
    // TODO: refactor navigate logic
    switch (el.slug) {
      case 'watchlist':
        dispatch(
          productOperation.getWatchlist({
            page: 1,
            per_page: 10,
          }),
        );
        break;
      default:
        dispatch(
          productOperation.getProduct({
            name: el.name,
            id: el.id,
            page: 1,
            per_page: 10,
          }),
        );
        break;
    }
  };

  return (
    <>
      {isRefresh && (
        <>
          <MenuBurger
            open={open}
            drawerWidth={drawerWidth}
            handleDrawerOpen={handleDrawerOpen}
          />

          <Drawer
            sx={{
              width: drawerWidth,
              flexShrink: 0,
              '& .MuiDrawer-paper': {
                position: 'inherit',
                width: drawerWidth,
                boxSizing: 'border-box',
              },
            }}
            variant="persistent"
            anchor="left"
            open={open}
          >
            <DrawerHeader sx={{ minHeight: 'fit-content !important' }}>
              <Typography variant={'bodyBoldMedium'} component={'p'}>
                <>{t('catalog:category_title')}</>
              </Typography>
              <FlexCont>
                {userRole === 'admin' ? (
                  <IconBtn
                    aria-label="open drawer"
                    onClick={handleOpenCategoryEditor}
                    href={`${Sprite}#icon-pan`}
                    tooltipText={t('common:tooltip.edit')}
                  />
                ) : (
                  <IconBtn
                    aria-label="open drawer"
                    onClick={() => {}}
                    href={`${Sprite}#icon-settings`}
                    tooltipText={t('common:tooltip.settings')}
                  />
                )}

                <IconBtn
                  aria-label="close drawer"
                  onClick={handleDrawerClose}
                  href={`${Sprite}#icon-close_arrow`}
                />
              </FlexCont>
            </DrawerHeader>
            <Divider />
            <Container>
              <CustomScroll vertical={true}>
                <List sx={{ p: 0 }}>
                  {menuData.map((el, i) => (
                    <>
                      <ListItem
                        key={el.slug}
                        disablePadding
                        sx={{
                          transition: '0.25s',
                          '&:hover': {
                            backgroundColor: color.gray_20,
                          },
                        }}
                      >
                        {el.children.length > 0 ? (
                          <AccordionBase
                            handleOpenModal={handleOpenModal}
                            data={el}
                            expanded={expanded}
                            handleChange={handleChange}
                            type="link"
                            icon={el.icon}
                            handleById={payload => {
                              dispatch(productOperation.getProduct(payload));
                            }}
                          />
                        ) : (
                          <Tabs
                            path={el.slug}
                            handleChange={setExpanded}
                            text={el.name}
                            id={el.id}
                            type="link"
                            icon={el.icon}
                            handleById={() => {
                              handleById(el);
                            }}
                          />
                        )}
                      </ListItem>
                      <Divider />
                    </>
                  ))}
                </List>
              </CustomScroll>
            </Container>
            {openSavedModal && (
              <CreateSavedModal
                open={openSavedModal}
                handleClose={handleCloseModal}
              />
            )}
          </Drawer>

          <EditCategoryListModal
            handleClose={handleCloseCategoryModal}
            open={modalOpen}
          />
          <Section
            overflow={overflow}
            open={open}
            mobileCatalogStyles={mobileCatalogStyles}
          >
            {children}
          </Section>
        </>
      )}
    </>
  );
};

const Container = style.div`
  display: flex;
  flex-direction: column;
  overflow: auto;
  height: calc(100dvh - 100px);
`;

DrawerCustom.propTypes = {
  children: PropTypes.node,
  overflow: PropTypes.string.isRequired,
};
