import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Swiper, SwiperSlide } from 'swiper/react';

import styled from 'styled-components';
import { Autoplay, Pagination } from 'swiper';
import 'swiper/css';
import 'swiper/css/pagination';

import { ProgressLoader } from '@root/ui';

import homeOperation from '@redux/home/home-operation';
import homeSelectors from '@redux/home/home-selectors';

import { useTheme } from '@mui/material/styles';

export const Banner = ({ conf }) => {
  const homeBanner = useSelector(homeSelectors.getHomeBanner);
  const isHomeBannerLoading = useSelector(homeSelectors.getIsHomeBannerLoading);
  const { color } = useTheme();
  const { size } = conf;

  const dispatch = useDispatch();

  const windowWith = useSelector(state => state?.windowWithSlice?.windowWith)

  useEffect(() => {
    dispatch(homeOperation.getHomeBanner());
  }, []);

  return (
    <BannerWrapper>
      {isHomeBannerLoading ? (
        <ProgressLoader />
      ) : (
        <Swiper
          pagination={true}
          style={{
            '--swiper-pagination-color': color.main,
          }}
          modules={[Autoplay, Pagination]}
          className="mySwiper"
          autoplay={{
            delay: 5000,
            disableOnInteraction: false,
          }}
        >
          {homeBanner.map(el => (
            <SwiperSlide key={el.id} style={{ height: '349px' }}>
              <Link target="_blank" href={el.link}>
                {size === '1fr' && <Img rel="preload" src={el.src} alt="" />}
                {size === '2fr' && (
                  <Img rel="preload" src={windowWith > 768 ? el.src_large : el.src} alt="" />
                )}
              </Link>
            </SwiperSlide>
          ))}
        </Swiper>
      )}
    </BannerWrapper>
  );
};

const BannerWrapper = styled.div`
  height: 349px;
  overflow: hidden;

  .swiper-pagination {
    margin-bottom: 10px;
  }
`;

const Img = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const Link = styled.a``;
