import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Sprite } from '@root/assets/svg';
import {
  IconBtn,
  ModalBase,
  NavigationButton,
  OutlineButtonBase,
} from '@root/ui';

import authSelectors from '@redux/auth/auth-selectors';

import { Button, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

export const DashboardManagers = ({
  conf,
  changeConf,
  isEdit,
  confDashboardElement,
}) => {
  const [open, setOpen] = useState(false);
  const { title, tabNames, isHide, key, size, position: positionConf } = conf;
  const userRole = useSelector(authSelectors.getRole);
  const { color } = useTheme();
  const [active, setActive] = useState('');
  const { t } = useTranslation(['common'], { useSuspense: false });

  const windowWith = useSelector(state => state?.windowWithSlice?.windowWith)

  const bannerButtonStyles = key === 'banner' && {
    backgroundColor: color.white,
    borderRadius: '50%',
    fill: color.gray,
    stroke: color.gray,
    padding: '3px',
    width: '21px',
    height: '21px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    opacity: '.7',
  };

  useEffect(() => {
    if (tabNames) {
      setActive(tabNames[0].key_nav);
    }
  }, [tabNames]);

  const handleCloseModal = () => {
    setOpen(false);
  };

  const ActionMarkUp = position =>
    isEdit && (
      <Action position={position}>
        <ActionItem>
          <IconBtn
            onClick={() => {}}
            sx={{ cursor: 'grab', ...bannerButtonStyles }}
            disableBorder
            size={16}
            href={`${Sprite}#icon-dnd-anchor`}
            tooltipText={t('common:tooltip.move')}
          />
        </ActionItem>

        <ActionItem>
          <IconBtn
            onClick={() =>
              changeConf({
                size: size === '1fr' ? '2fr' : '1fr',
                position: { ...positionConf, w: size === '1fr' ? 2 : 1 },
              })
            }
            sx={{ ...bannerButtonStyles }}
            onMouseDown={e => e.stopPropagation()}
            disableBorder
            size={16}
            href={`${Sprite}#icon-resize`}
            tooltipText={
              size === '1fr'
                ? t('common:tooltip.increase')
                : t('common:tooltip.decrease')
            }
          />
        </ActionItem>
        {/* {key !== 'banner' && key !== 'top_sale' && ( */}
        {key !== 'banner' && (
          <ActionItem>
            <IconBtn
              sx={{
                position: 'relative',
                zIndex: 100,
                stroke: isHide && color.main,
                fill: isHide && color.main,
              }}
              onClick={() => changeConf({ isHide: !isHide })}
              disableBorder
              size={16}
              href={!isHide ? `${Sprite}#icon-eye` : `${Sprite}#icon-eye-show`}
              onMouseDown={e => e.stopPropagation()}
              tooltipText={
                isHide ? t('common:tooltip.show') : t('common:tooltip.hide')
              }
            />
          </ActionItem>
        )}
      </Action>
    );

  return (
    <Card color={color} isHide={isHide} size={size} isEdit={isEdit}>
      <Flex color={color}>
        {key !== 'banner' && (
          <Header color={color} onMouseDown={e => e.stopPropagation()}>
            <HeaderTitle>
              {title && (
                <Typography
                  variant={'bodyBoldSmall'}
                  component={'h3'}
                  sx={ windowWith > 430
                    ? { marginRight: '15px', marginLeft: '20px' }
                    : { marginRight: '10px', marginLeft: '10px' }
                  }
                >
                  {title}
                </Typography>
              )}
              {tabNames &&
                tabNames.map(el => (
                  <NavigationButton
                    isActive={active === el.key_nav}
                    onClick={() => setActive(el.key_nav)}
                    key={el.name}
                    disabled={el.disabled}
                    sx={{
                      paddingRight: '20px !important',
                      paddingLeft: '20px !important',
                    }}
                  >
                    {el.name}
                  </NavigationButton>
                ))}
            </HeaderTitle>
          </Header>
        )}
        {ActionMarkUp('absolute')}

        <WidgetWrapper onMouseDown={e => e.stopPropagation()}>
          {confDashboardElement[key].widget({
            activeTab: active,
            isEdit,
            conf,
          })}
        </WidgetWrapper>
      </Flex>
      {userRole === 'admin' &&
        confDashboardElement[key].onChangeEl &&
        isEdit && (
          <OutlineButtonBase
            sx={{
              position: 'absolute',
              bottom: '16px',
              right: '16px',
              zIndex: 10,
            }}
            onClick={() => {
              setOpen(true);
            }}
            onMouseDown={e => e.stopPropagation()}
          >
            {t('home:buttons.change')}
          </OutlineButtonBase>
        )}
      {open && (
        <ModalBase
          open={open}
          handleClose={handleCloseModal}
          onMouseDown={e => e.stopPropagation()}
          modalHeader={confDashboardElement[key].headerModal({
            handleCloseModal,
          })}
          modalBottom={confDashboardElement[key].footer({
            handleCloseModal,
          })}
        >
          {confDashboardElement[key].onChangeEl({ handleCloseModal })}
        </ModalBase>
      )}
    </Card>
  );
};

const Card = styled.div`
  position: relative;
  width: ${({ size }) => size};
  max-height: 349px;
  height: 100%;
  background-color: ${({ color }) => color.white};
  border: 1px solid ${({ color }) => color.line};
  border-radius: 4px;
  overflow: hidden;
  opacity: ${({ isEdit, isHide }) => (isEdit ? '' : isHide ? 0 : '')};
  visibility: ${({ isEdit, isHide }) => (isEdit ? '' : isHide ? 'hidden' : '')};

  &::before {
    display: ${({ isHide }) => (isHide ? 'block' : 'none')};
    position: absolute;
    top: 0;
    left: 0;
    content: '';
    width: 100%;
    height: 100%;

    background-color: ${({ color }) => color.white};
    opacity: 0.4;
    z-index: 50;
  }

  @media(max-width: 1199px) {
    max-height: unset;
    //font-size: 1rem !important;
  }
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${({ color }) => color.line};

  min-height: 42px;
`;

const HeaderTitle = styled.div`
  flex-grow: 1;
  display: flex;
  align-items: center;
`;

const Flex = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;
const Action = styled.div`
  position: ${({ position }) => position};
  top: 13px;
  right: 17px;
  display: flex;
  align-items: center;

  margin-left: auto;
  gap: 8px;
  z-index: 100;
`;

const ActionItem = styled.div`
  flex: 0 0 16px;
`;

const WidgetWrapper = styled.div`
  height: 100%;
`;

NavigationButton.propTypes = {
  conf: PropTypes.object.isRequired,
  changeConf: PropTypes.func.isRequired,
  isEdit: PropTypes.bool.isRequired,
};
