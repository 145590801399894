export const savedOrdersAccordionStyles = {
  accordionStyles: {
    backgroundColor: '#F7F8FA',
    padding: '16px 16px',
    '.MuiButtonBase-root.MuiAccordionSummary-root': {
      alignItems: 'flex-end',
    },
    '& .MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium': {
      width: '20px',
      height: '20px',
      color: '#8094AE',
    },
    '.MuiPaper-root': {
      borderRadius: '0px',
      boxShadow: 'none',
    },
  },
  accordionSummaryStyles: {},
  accordionDetailsStyles: {
    padding: '20px 0 0 0',
  },
};
