import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import styled from 'styled-components';

import { Sprite } from '@root/assets/svg';
import { CustomizedSwitches, IconBtn } from '@root/ui';
import { DatePickerCustom } from '@root/ui/Input/DatePicker/Classic/index';
import { TextInput } from '@root/ui/Input/TextInput/index';

import { Typography, useTheme } from '@mui/material';

export const DatePickerField = ({
  id,
  variant,
  sx,
  type,
  value,
  onEdit,
  goToStep,
  errors,
}) => {
  const [isEdit, setIsEdit] = useState(false);
  const [locValue, setLocValue] = useState(value);
  const { t } = useTranslation(['common'], { useSuspense: false });
  const { color } = useTheme();

  useEffect(() => {
    if (id) setIsEdit(false);
  }, [id]);

  const onEditSuccess = () => {
    setIsEdit(false);
  };
  const onError = str => {
    setIsEdit(true);
  };

  return (
    <>
      <FieldDescription>
        <WrapperText>
          <Typography
            sx={{
              position: 'relative',
              display: 'flex',
              alignItems: 'center',
              width: '190px',
              wordBreak: 'break-all',

              ...sx,
            }}
            variant={variant}
            component={'p'}
          >
            {isEdit ? (
              <DatePickerCustom
                value={value}
                dateFormat="dd.MM.yyyy"
                onChange={e => setLocValue(e)}
                error={errors?.init}
                errorText={errors?.text}
              />
            ) : type === 'area' ? (
              ''
            ) : (
              value
            )}
          </Typography>
        </WrapperText>
      </FieldDescription>

      {(onEdit || goToStep) &&
        (!isEdit ? (
          <IconBtn
            href={`${Sprite}#icon-pan`}
            onClick={() => (goToStep ? goToStep() : setIsEdit(true))}
            tooltipText={t('common:tooltip.edit')}
          />
        ) : (
          <IconsWrapper>
            <IconBtn
              onClick={() => setIsEdit(false)}
              href={`${Sprite}#icon-cross`}
              sx={{ stroke: color.red }}
            />
            <IconBtn
              onClick={() => {
                onEdit && onEdit(locValue, onEditSuccess, onError);
              }}
              href={`${Sprite}#icon-tick`}
              sx={{ stroke: color.green }}
            />
          </IconsWrapper>
        ))}
    </>
  );
};

const FieldDescription = styled.div`
  flex: 1 1 auto;
`;

const IconsWrapper = styled.div`
  display: flex;
  column-gap: 6px;
`;

const WrapperText = styled.div`
  padding-right: 5px;
`;
