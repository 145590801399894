import { useMemo } from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Dagger, GreenChecked } from '@root/assets/svg';

import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';

export const PasswordLights = ({ status, text, windowWith }) => {
  const { color } = useTheme();

  return useMemo(() => {
    return (
      <Card key={text} data-test-item="PasswordLightItem">
        <img src={status ? GreenChecked : Dagger} alt="Status" />
        <Typography
          sx={{
            ml: '8px',
            color: color.gray_80,
            // fontSize: windowWith > 991 ? '1rem' : '0.75rem'
          }}
          variant={windowWith > 991 ? 'bodyLarge' : 'bodySmall'}
          component={'p'}
        >
          {text}
        </Typography>
      </Card>
    );
  }, [status, text]);
};

const Card = styled.div`
  display: flex;

  @media(max-width: 991px) {
    width: 50%;
    align-items: flex-start;

    img {
      width: 14px;
      //margin-top: 4px;
    }
  }
`;

PasswordLights.propTypes = {
  status: PropTypes.bool.isRequired,
  text: PropTypes.string.isRequired,
};
