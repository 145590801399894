import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import userSelectors from '@root/redux/user/user-selectors';
import { theme } from '@root/styles';

import { Typography } from '@mui/material';

export const StockBar = ({ warehouse, index, noCounters = false }) => {
  const userPermissions = useSelector(userSelectors.getPermissions);
  const { t } = useTranslation();

  const subColor =
    warehouse.stock_percent > 70
      ? theme.color.green_light
      : warehouse.stock_percent <= 30
      ? theme.color.red_light
      : theme.color.orange_light;
  const backgroundColor =
    warehouse.stock_percent > 70
      ? theme.color.green
      : warehouse.stock_percent <= 30
      ? theme.color.red
      : theme.color.orange;

  const getDetailsByPermission = () => {
    switch (true) {
      // VIP acces
      case userPermissions.product_stock_all_access === true:
        return warehouse.stock > 999 ? 999 + '+' : warehouse.stock;
      // standart access
      default:
        return getWordByPercent(warehouse.stock_percent);
    }
  };

  const getWordByPercent = percent => {
    switch (true) {
      case percent > 70:
        return t('common:bagato');
      case percent > 30:
        return t('common:dostatno');
      default:
        return t('common:malo');
    }
  };

  return (
    <CityRegionInfo
      style={{
        borderRight: index <= 3 ? `1px dashed ${theme.color.line_light}` : '',
      }}
    >
      {!noCounters && (
        <>
          <Bar style={{ background: subColor }}>
            <BarPercentValue
              style={{
                height: `${warehouse.stock_percent}%`,
                backgroundColor,
              }}
            ></BarPercentValue>
          </Bar>
          <Typography
            variant={'bodySmall'}width
            sx={{
              maxWidth: '39px',
              color: theme.color.gray,
            }}
          >
            {getDetailsByPermission()}
          </Typography>
        </>
      )}
      <Typography
        variant={'bodySmall'}
        sx={{
          width: '110px',
          color: theme.color.gray,
        }}
      >
        {!noCounters && <> - </>} {warehouse.name}
      </Typography>
    </CityRegionInfo>
  );
};

const CityRegionInfo = styled.div`
  display: flex;
  width: 160px;
  flex-direction: row;
  align-items: center;
  padding: 4px 5px 4px 0;
  margin-right: 14px;

  @media(max-width: 768px) {
    margin: 0;
    padding: 4px 5px;
  }
`;

const Bar = styled.div`
  height: 12px;
  width: 4px;
  overflow: hidden;
  border-radius: 2px;
  margin-right: 6px;
  position: relative;
`;

const BarPercentValue = styled.div`
  position: absolute;
  bottom: 0;
  height: 0;
  transition: margin 0.5s;
  width: 4px;
  border-radius: 0 0 2px 2px;
`;

StockBar.propTypes = {
  warehouse: PropTypes.exact({
    name: PropTypes.string.isRequired,
    stock: PropTypes.number,
    stock_max: PropTypes.number,
    stock_percent: PropTypes.number.isRequired,
  }),
  index: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
