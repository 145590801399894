import { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import styled from 'styled-components';

import {
  IconArrowDown,
  IconBold,
  IconColorPicker,
  IconEmoji,
  IconImage,
  IconItalic,
  IconLink,
  IconList,
  IconNumList,
  IconRedo,
  IconUnderline,
  IconUndo,
} from '@root/assets/svg';
import { Sprite } from '@root/assets/svg/index';

import { useTheme } from '@mui/material';

const CreateSizeArray = (from, to, step) => {
  const arr = [];
  for (let index = from; index <= to; index = index + step) {
    arr.push(`${index}px`);
  }
  return arr;
};

const sizeArray = CreateSizeArray(8, 128, 2);

const Clamp = (num, min, max) => Math.min(Math.max(num, min), max);

const BannerImageEditor = ({ currentBanner, currentId, updateBanner }) => {
  const { color } = useTheme();
  const { t } = useTranslation(['settings']);
  const myDivRef = useRef(null);
  const [divHeight, setDivHeight] = useState(0);
  const [anchor, setAnchor] = useState('top-left');
  const [value, setValue] = useState('');
  const [imgContainerWidth, setImgContainerWidth] = useState(0);
  const [displayMoveControlls, setDisplayMoveControlls] = useState(false);

  useEffect(() => {
    if (currentBanner) {
      handleAnchorChange(
        currentBanner.anchor,
        currentBanner.x,
        currentBanner.y,
      );
      setValue(currentBanner.html);
    }
  }, [currentId, currentBanner]);

  const ref = useRef(null);
  const memoizedModules = useMemo(
    () => ({
      toolbar: {
        container: [
          [{ header: [1, 2, 3, 4, 5, 6, false] }],
          [
            {
              size: sizeArray,
            },
          ],
          [
            {
              color: ['red', 'green', 'blue', 'orange', 'violet', 'white', ''],
            },
          ],
          ['bold', 'italic', 'underline'],
          [
            { align: ['', 'center', 'right'] },
            { list: 'ordered' },
            { list: 'bullet' },
          ],
          ['undo', 'redo'],
        ],
        handlers: {
          undo() {
            ref.current.getEditor().history.undo();
          },
          redo() {
            ref.current.getEditor().history.redo();
          },
          verticalAlign(e) {
            alert(e);
          },
        },
      },
    }),
    [],
  );
  const onChange = e => {
    setValue(e);
  };

  // Обновление высоты при изменении размера
  const updateDivHeight = () => {
    if (myDivRef.current) {
      setDivHeight(myDivRef.current.clientHeight);
      setImgContainerWidth((myDivRef.current.clientHeight * 591) / 653);
    }
  };

  const getHeight = () => myDivRef.current.clientHeight;
  const getWidth = () => (myDivRef.current.clientHeight * 591) / 653;

  useEffect(() => {
    updateDivHeight();
    window.addEventListener('resize', updateDivHeight);

    setTimeout(() => {
      handleAnchorChange('top-right');
    }, 100);

    const Size = ReactQuill.Quill.import('attributors/style/size');
    Size.whitelist = sizeArray;
    ReactQuill.Quill.register(Size, true);

    return () => {
      window.removeEventListener('resize', updateDivHeight);
    };
  }, []);

  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [editorX, setEditorX] = useState(0);
  const [editorY, setEditorY] = useState(0);

  const convertXY = (x, y, anch) => {
    let newX, newY;
    switch (anchor) {
      case 'top-left':
        newX = x;
        newY = y;
        break;
      case 'top-right':
        newX = getWidth() - x;
        newY = y;
        break;
      case 'bottom-left':
        newX = x;
        newY = getHeight() - y;
        break;
      case 'bottom-right':
        newX = getWidth() - x;
        newY = getHeight() - y;
        break;
      default:
        newX = 10;
        newY = 10;
        break;
    }
    return { newX, newY };
  };

  const handleMouseDown = e => {
    const offsetX = e.clientX - position.x;
    const offsetY = e.clientY - position.y;

    const handleMouseMove = e => {
      let x = Clamp(e.clientX - offsetX, 0, getWidth());
      let y = Clamp(e.clientY - offsetY, 0, getHeight());
      handlePosition(x, y, anchor);

      const { newX, newY } = convertXY(x, y, anchor);
      emitUpdateBanner(newX, newY, anchor);
    };

    const handleMouseUp = () => {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
    };

    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);
  };
  const handlePosition = (x, y, anchor = 'top-left') => {
    const qleditor = ref?.current
      .getEditor()
      .container.querySelector('.ql-editor');
    switch (anchor) {
      case 'top-left':
        setPosition({
          x,
          y,
        });
        setEditorX(`${x}px`);
        setEditorY(`${y}px`);
        qleditor.style.left = `${x}px`;
        qleditor.style.top = `${y}px`;
        qleditor.style.right = `unset`;
        qleditor.style.bottom = `unset`;
        qleditor.style.maxWidth = `${getWidth() - x}px`;
        qleditor.style.height = `unset`;
        break;
      case 'top-right':
        setPosition({
          x,
          y,
        });
        setEditorX(`${x}px`);
        setEditorY(`${y}px`);
        qleditor.style.left = `unset`;
        qleditor.style.top = `${y}px`;
        qleditor.style.right = `${getWidth() - x}px`;
        qleditor.style.bottom = `unset`;
        qleditor.style.maxWidth = `${x}px`;
        qleditor.style.height = `unset`;
        break;
      case 'bottom-left':
        setPosition({
          x,
          y,
        });
        setEditorX(`${x}px`);
        setEditorY(`${y}px`);
        qleditor.style.left = `${x}px`;
        qleditor.style.top = `unset`;
        qleditor.style.right = `unset`;
        qleditor.style.bottom = `${getHeight() - y}px`;
        qleditor.style.maxWidth = `${getWidth() - x}px`;
        qleditor.style.height = `unset`;
        break;
      case 'bottom-right':
        setPosition({
          x,
          y,
        });
        setEditorX(`${x}px`);
        setEditorY(`${y}px`);
        qleditor.style.left = `unset`;
        qleditor.style.top = `unset`;
        qleditor.style.right = `${getWidth() - x}px`;
        qleditor.style.bottom = `${getHeight() - y}px`;
        qleditor.style.maxWidth = `${x}px`;
        qleditor.style.height = `unset`;
        break;

      default:
        break;
    }
  };
  const handleAnchorChange = (
    newAnchor = 'top-left',
    x = 10,
    y = 10,
    emit = false,
  ) => {
    setAnchor(newAnchor);
    switch (newAnchor) {
      case 'top-left':
        ref.current.getEditor().format('align', '', 'user');
        setPosition({
          x,
          y,
        });
        handlePosition(x, y, newAnchor);
        break;
      case 'top-right':
        ref.current.getEditor().format('align', 'right', 'user');
        setPosition({
          x: getWidth() - x,
          y: y,
        });
        handlePosition(getWidth() - x, y, newAnchor);
        break;
      case 'bottom-left':
        ref.current.getEditor().format('align', '', 'user');
        setPosition({
          x: x,
          y: getHeight() - y,
        });
        handlePosition(x, getHeight() - y, newAnchor);
        break;
      case 'bottom-right':
        ref.current.getEditor().format('align', 'right', 'user');
        setPosition({
          x: getWidth() - x,
          y: getHeight() - y,
        });
        handlePosition(getWidth() - x, getHeight() - y, newAnchor);
        break;

      default:
        break;
    }
    if (emit) emitUpdateBanner(x, y, newAnchor, undefined);
  };
  const emitUpdateBanner = (newX, newY, newAnchor, newHtml) => {
    updateBanner(newX, newY, newAnchor, newHtml);
  };

  return (
    <EditorWrap
      ref={myDivRef}
      color={color}
      editorX={editorX}
      editorY={editorY}
      hovered={displayMoveControlls}
    >
      <ImgWrap
        w={imgContainerWidth}
        h={divHeight}
        show={currentBanner}
        onMouseEnter={() => {
          setDisplayMoveControlls(true);
        }}
        onMouseLeave={() => {
          setDisplayMoveControlls(false);
        }}
      >
        <NoBannerSelected color={color}></NoBannerSelected>
        <img src={currentBanner?.image} alt="" />
        <Grabler
          style={{
            left: position.x,
            top: position.y,
          }}
          onMouseDown={handleMouseDown}
          anchor={anchor}
          color={color}
          hovered={displayMoveControlls}
        >
          <use href={`${Sprite}#icon-replace`} />
        </Grabler>
        <AnchorTopLeft
          onClick={() => {
            handleAnchorChange('top-left', 10, 10, true);
          }}
          color={color}
        />
        <AnchorTopRight
          onClick={() => {
            handleAnchorChange('top-right', 10, 10, true);
          }}
          color={color}
        />
        <AnchorBottomLeft
          onClick={() => {
            handleAnchorChange('bottom-left', 10, 10, true);
          }}
          color={color}
        />
        <AnchorBottomRight
          onClick={() => {
            handleAnchorChange('bottom-right', 10, 10, true);
          }}
          color={color}
        />
        <ReactQuill
          value={value}
          onChange={(val, c, emitter) => {
            // console.log(val);
            if (ref.current) {
              const editor = ref.current?.getEditor();
              editor?.getLength() > 1 ? onChange(val) : onChange('');
              if (emitter === 'user' && currentId) {
                emitUpdateBanner(undefined, undefined, undefined, val);
              }
            }
          }}
          ref={ref}
          theme="snow"
          modules={memoizedModules}
          placeholder={t('banner.text_placeholder')}
        />
      </ImgWrap>
    </EditorWrap>
  );
};

export default BannerImageEditor;

const NoBannerSelected = styled.div`
  background-color: ${({ color }) => color.gray_40};
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 1 !important;
`;

const AnchorTopLeft = styled.div`
  cursor: pointer;
  position: absolute;
  width: 10px;
  height: 10px;
  z-index: 40;
  top: 0;
  left: 0;
  border-radius: 50%;
  border: 2px solid ${({ color }) => color.main};
  transform: translate(-50%, -50%);
`;
const AnchorTopRight = styled.div`
  cursor: pointer;
  position: absolute;
  width: 10px;
  height: 10px;
  z-index: 40;
  top: 0;
  right: 0;
  border-radius: 50%;
  border: 2px solid ${({ color }) => color.main};
  transform: translate(50%, -50%);
`;
const AnchorBottomLeft = styled.div`
  cursor: pointer;
  position: absolute;
  width: 10px;
  height: 10px;
  z-index: 40;
  bottom: 0;
  left: 0;
  border-radius: 50%;
  border: 2px solid ${({ color }) => color.main};
  transform: translate(-50%, 50%);
`;
const AnchorBottomRight = styled.div`
  cursor: pointer;
  position: absolute;
  width: 10px;
  height: 10px;
  z-index: 40;
  bottom: 0;
  right: 0;
  border-radius: 50%;
  border: 2px solid ${({ color }) => color.main};
  transform: translate(50%, 50%);
`;

const ImgWrap = styled.div`
  width: ${({ w }) => w}px;
  height: ${({ h }) => h}px;
  margin: 0 auto;
  position: relative;
  pointer-events: ${({ show }) => (show ? 'unset' : 'none')};
  & > * {
    opacity: ${({ show }) => (show ? '1' : '0')};
    transition: opacity 0.3s ease;
  }
  img {
    width: 100%;
    height: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    object-fit: cover;
    display: block;
  }
`;

const Grabler = styled.svg`
  stroke: ${({ color }) => color.main};
  width: 30px;
  height: 30px;
  position: absolute;
  z-index: 50;
  top: 0;
  left: 0;
  transform: ${({ anchor }) => {
    switch (anchor) {
      case 'top-right':
        return 'translate(0, -100%)';
      case 'bottom-left':
        return 'translate(-100%, 0)';
      case 'bottom-right':
        return 'translate(0, 0)';
      default:
        return 'translate(-100%, -100%)';
    }
  }};
  cursor: grab;
  opacity: ${({ hovered }) => (hovered ? '1' : '0')};
`;

const EditorWrap = styled.div`
  /* height: calc(100% - 93px); */
  height: calc(100% - 133px);
  margin: 40px auto 20px;
  position: relative;
  user-select: none;

  .quill {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .ql-editor.ql-blank {
    &:before {
      /* font-size: 0.875rem; */
      color: ${({ color }) => color.gray_60};
      font-style: revert;
      left: 0;
    }
  }

  .ql-editor {
    padding: 0;
    overflow: hidden auto;
    background-color: unset;
    height: unset;
    background-color: ${({ hovered }) =>
      hovered ? 'rgba(122, 209, 255, 0.5)' : 'transparent'};
    position: absolute;
    font-size: 24px;
    min-width: 200px;

    ::-webkit-scrollbar {
      width: 5px;
    }

    ::-webkit-scrollbar-thumb {
      background: ${({ color }) => color.gray_40};
      border-radius: 4px;
    }

    ::-webkit-scrollbar-track {
      background: ${({ color }) => color.white};
      border-left: 1px solid ${({ color }) => color.line};
    }

    img {
      display: inline-block;
    }
  }

  .ql-container,
  .ql-toolbar {
    border-color: ${({ color, error }) =>
      error ? color.red : color.line} !important;
    padding: 0 !important;
    background-color: ${({ color }) => color.white};
  }

  .ql-formats {
    padding: 8px 4px;
    margin-right: 0 !important;
    border-right: 1px solid ${({ color }) => color.line};
    height: 34px;
  }

  .ql-container {
    border-radius: 4px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    padding: 0 !important;
    border: none;
    background-color: unset;
  }

  .ql-toolbar {
    border-radius: 4px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    display: flex;
    justify-content: space-between;
    position: absolute;
    top: -76px;
    left: 0;
    width: 480px;

    &:after {
      content: none;
    }

    button {
      width: 18px !important;
      height: 18px !important;
      background-size: contain !important;
      margin: 0 4px;

      svg {
        display: none;
      }
    }

    .ql-formats:first-child {
      padding-right: 25px;
      padding-left: 16px;
      min-width: 115px;

      .ql-picker {
        height: 18px;
      }
    }

    .ql-formats:nth-child(2) {
      padding-right: 22px;
      padding-left: 16px;
    }

    .ql-formats:nth-child(5) {
      height: 34px;
    }

    .ql-formats:last-child {
      border-right: none;
    }
  }

  .ql-picker-options {
    max-height: 300px;
    overflow: auto;
  }

  .ql-snow .ql-editing {
    left: 0 !important;
  }

  .ql-snow .ql-editing:lang(uk) {
    &:before {
      content: 'Додати посилання';
    }

    .ql-action {
      &:after {
        content: 'Зберегти';
      }
    }
  }

  .ql-snow .ql-stroke,
  .ql-fill {
    stroke: ${({ color }) => color.gray_60};
  }

  .ql-size {
    .ql-picker-label {
      &:before {
        content: attr(data-value) !important;
      }
    }
  }

  .ql-snow .ql-header,
  .ql-size,
  .ql-align {
    .ql-picker-label,
    .ql-picker-item {
      &:before {
        font-size: 14px !important;
      }

      &:hover {
        color: ${({ color }) => color.main};
      }
    }

    .ql-active {
      color: ${({ color }) => color.main} !important;
    }
  }

  .ql-snow .ql-header:lang(uk) {
    .ql-picker-label,
    .ql-picker-item {
      &:before {
        content: 'Звичайний' !important;
      }
    }

    .ql-picker-label[data-value='1'],
    .ql-picker-item[data-value='1'] {
      &:before {
        content: 'H1' !important;
      }
    }

    .ql-picker-label[data-value='2'],
    .ql-picker-item[data-value='2'] {
      &:before {
        content: 'H2' !important;
      }
    }

    .ql-picker-label[data-value='3'],
    .ql-picker-item[data-value='3'] {
      &:before {
        content: 'H3' !important;
      }
    }

    .ql-picker-label[data-value='4'],
    .ql-picker-item[data-value='4'] {
      &:before {
        content: 'H4' !important;
      }
    }

    .ql-picker-label[data-value='5'],
    .ql-picker-item[data-value='5'] {
      &:before {
        content: 'H5' !important;
      }
    }

    .ql-picker-label[data-value='6'],
    .ql-picker-item[data-value='6'] {
      &:before {
        content: 'H6' !important;
      }
    }
  }

  .ql-snow .ql-header,
  .ql-size,
  .ql-align {
    width: auto !important;

    .ql-picker-label {
      padding-left: 0;
      border: none;

      svg {
        display: none;
      }

      &:after {
        content: '';
        width: 20px;
        height: 20px;
        display: inline-block;
        background: url(${IconArrowDown}) no-repeat;
        background-size: contain;
        position: absolute;
        transform: rotate(0deg);
        transition: 0.2s ease all;
        right: -20px;
      }
    }
  }

  .ql-expanded {
    .ql-picker-label {
      &:after {
        transform: rotate(180deg) !important;
      }
    }
  }

  .ql-snow .ql-align {
    width: 20px !important;
    margin-right: 20px;

    .ql-picker-label {
      border: none;

      &:after {
        right: -15px;
        top: 0;
      }

      svg {
        display: initial;
      }
    }
  }

  .ql-snow .ql-color {
    width: 24px;
    height: 17px;
    text-align: center;

    .ql-picker-label {
      background: url(${IconColorPicker}) no-repeat;
      background-size: contain;
      width: 20px;
      height: 20px;

      svg {
        display: none;
      }
    }

    .ql-active {
      background-color: ${({ color }) => color.gray_40} !important;
    }

    .ql-picker-label {
      border: none;
    }
  }

  .ql-bold.ql-active,
  .ql-italic.ql-active,
  .ql-underline.ql-active,
  .ql-link.ql-active,
  .ql-list.ql-active {
    background-color: ${({ color }) => color.gray_40} !important;
    border-radius: 2px;
    padding: 2px;
  }

  .ql-toolbar {
    border-radius: 4px;
  }

  .ql-snow.ql-toolbar .ql-bold {
    background: url(${IconBold}) no-repeat;
  }

  .ql-snow.ql-toolbar .ql-italic {
    background: url(${IconItalic}) no-repeat;
  }

  .ql-snow.ql-toolbar .ql-underline {
    background: url(${IconUnderline}) no-repeat;
  }

  .ql-snow.ql-toolbar .ql-link {
    background: url(${IconLink}) no-repeat;
  }

  .ql-snow.ql-toolbar .ql-image {
    background: url(${IconImage}) no-repeat;
  }

  .ql-snow.ql-toolbar .ql-emoji {
    background: url(${IconEmoji}) no-repeat;
  }

  .ql-snow.ql-toolbar .ql-list:first-of-type {
    background: url(${IconList}) no-repeat;
  }

  .ql-snow.ql-toolbar .ql-list:last-of-type {
    background: url(${IconNumList}) no-repeat;
  }

  .ql-snow.ql-toolbar .ql-undo {
    background: url(${IconUndo}) no-repeat;
  }

  .ql-snow.ql-toolbar .ql-redo {
    background: url(${IconRedo}) no-repeat;
  }

  .ql-snow {
    .ql-picker {
      &.ql-size {
        .ql-picker-label,
        .ql-picker-item {
          &::before {
            content: attr(data-value) !important;
          }
        }
      }
    }
  }
`;
