import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const getComparisonsCount = createAsyncThunk(
  'comparisons/getComparisonCount',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(`/product/comparison`);
      return response.data.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

const addToComparisons = createAsyncThunk(
  'comparisons/addToComparisons',
  async ({ id, onResolve, onReject }, thunkApi) => {
    try {
      const response = await axios.post(`/product/comparison/${id}`);
      if (onResolve) onResolve(response.data.data);
      return { ...response.data.data, id };
    } catch (error) {
      if (onReject) onReject(error.response.data.message);
      return thunkApi.rejectWithValue(error);
    }
  },
);

const removeFromComparisons = createAsyncThunk(
  'comparisons/removeFromComparisons',
  async ({ id, onResolve, onReject }, thunkApi) => {
    try {
      const response = await axios.delete(`/product/comparison/${id}`);
      if (onResolve) onResolve(response.data.data);
      return { ...response.data.data, id };
    } catch (error) {
      if (onReject) onReject(error.response.data.message);
      return thunkApi.rejectWithValue(error);
    }
  },
);

const getComparisonsCategories = createAsyncThunk(
  'comparisons/getComparisonsCategories',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(`/product/comparison/categories`);
      return response.data.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

const getComparisonsProductsDetails = createAsyncThunk(
  'comparisons/getComparisonsProducts',
  async ({ categoryId }, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `/product/comparison/categories/${categoryId}`,
      );
      return response.data.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

const removeFromComparisonsPage = createAsyncThunk(
  'comparisons/removeFromComparisonsPage',
  async ({ id, onResolve, onReject }, thunkApi) => {
    try {
      const response = await axios.delete(`/product/comparison/${id}`);
      if (onResolve) onResolve(response.data.data);
      return response.data.data;
    } catch (error) {
      if (onReject) onReject(error.response.data.message);
      return thunkApi.rejectWithValue(error);
    }
  },
);

const removeCategoryCompare = createAsyncThunk(
  'comparisons/removeCategoryCompare',
  async ({ id, onResolve, onReject }, thunkApi) => {
    try {
      const response = await axios.delete(
        `/product/comparison/categories/${id}`,
      );
      if (onResolve) onResolve(response.data.data);
      return response.data.data;
    } catch (error) {
      if (onReject) onReject(error.response.data.message);
      return thunkApi.rejectWithValue(error);
    }
  },
);

const createComparisonPdf = createAsyncThunk(
  'comparisons/createComparisonPdf',
  async (data, thunkApi) => {
    try {
      const response = await axios.post('/product/compare/document', data);
      return response.data.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  },
);

const getComparisonPdfLinksList = createAsyncThunk(
  'comparisons/getComparisonPdfLinksList',
  async (_, thunkApi) => {
    try {
      const response = await axios.get('/product/compare/document');
      return response.data.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  },
);

const removePdfListItem = createAsyncThunk(
  'comparisons/removePdfListItem',
  async (id, thunkApi) => {
    try {
      const response = await axios.delete(`/product/compare/document/${id}`);
      return response.data.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  },
);
export default {
  addToComparisons,
  getComparisonsCount,
  removeFromComparisons,
  getComparisonsCategories,
  getComparisonsProductsDetails,
  removeFromComparisonsPage,
  removeCategoryCompare,
  createComparisonPdf,
  getComparisonPdfLinksList,
  removePdfListItem,
};
