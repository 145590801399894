import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import styled from 'styled-components';

import { Sprite } from '@root/assets/svg/index';
import { CartButton, Logo } from '@root/components';
import {
  AISupport,
  BonusWidjet,
  ExchangeRate,
  Locales,
  NotificationsWidget,
  Rate,
  UserMenu,
} from '@root/widgets';
import { Buscet } from '@root/widgets/Buscet';
import { Scales } from '@root/widgets/Scales';
import TextPagesHeaderMenu from '@root/widgets/TextPagesHeaderMenu/index';

import userSelectors from '@redux/user/user-selectors';

import { useTheme } from '@mui/material/styles';

import { Typography } from '../../../node_modules/@mui/material/index';
import {HamburgerButton} from "@root/ui/HamburgerButton/HamburgerButton";
import {useEffect, useState} from "react";
import arrowDown from '../../assets/image/arrow-down.svg'
import {useParams} from "react-router-dom";


export const Header = ({isSidebarVisible, setIsSidebarVisible}) => {
  const { logo } = useSelector(userSelectors.getUserCompanyConf);
  const { color } = useTheme();
  const { t } = useTranslation('header');
  const userPermissions = useSelector(userSelectors.getPermissions);
  const windowWith = useSelector(state => state?.windowWithSlice?.windowWith)
  const [isHeaderOpen, setIsHeaderOpen] = useState(false)

  // close header when user visit another page
  const page= useParams();

  useEffect(() => {
    setIsHeaderOpen(false);
  }, [page]);
  // end close header when user visit another page

  if(windowWith > 1440) {
    return (
      <Component colors={{ white: color.white, line: color.line_light }}>
        <WrapperLogo color={color.line_light}>
          <Logo src={logo} sx={{ height: '34px' }} />
          <BetaWarning colors={{ red: color.red, white: color.white }}>
            Beta
          </BetaWarning>
        </WrapperLogo>
        <WrapperExchangeRate>
          <ExchangeRate />
        </WrapperExchangeRate>

        <Wrapper>
          <TempMainWrap
            href="https://viatec.ua/"
            color={color}
            hoverColor={`rgba(${color.main
              .substr(1)
              .match(/../g)
              .map(x => +`0x${x}`)},${0.08})`}
          >
            <TempLeftSide>
              <TempSvg color={color}>
                <use href={`${Sprite}#icon-logout`}></use>
              </TempSvg>
              <Typography
                color={`${color.gray} !important`}
                variant={'bodyBoldSmall'}
                component={'span'}
              >
                {t('go_to_old_cabinet')}
              </Typography>
            </TempLeftSide>
          </TempMainWrap>
        </Wrapper>

        <Wrapper>
          <BonusWidjet />
        </Wrapper>

        <GroupWrapper>
          <Rate />
          <Locales />
        </GroupWrapper>

        <GroupWrapper>
          <NotificationsWidget />
          {userPermissions['product_compare_access'] && <Scales />}
          {/*<TextPagesHeaderMenu />*/}
           <AISupport />
          <UserMenu />
        </GroupWrapper>

        {userPermissions['cart_access'] && (
          <Wrapper minWidth={180}>
            {/*<Buscet />*/}
            <CartButton/>
          </Wrapper>
        )}
      </Component>
    );
  }

  if(windowWith <= 1440) {
    return (
      <Component colors={{ white: color.white, line: color.line_light }}>
        <MobileHeaderTopInnerWrapper>
          {
            windowWith <= 1199 &&
            <HamburgerButton isSidebarVisible={isSidebarVisible} buttonAction={setIsSidebarVisible} />
          }
          <WrapperLogo color={color.line_light}>
            <Logo src={logo} sx={{ height: '34px' }} />
            <BetaWarning colors={{ red: color.red, white: color.white }}>
              Beta
            </BetaWarning>
          </WrapperLogo>
          {
            windowWith > 768 &&
            <>
              <GroupWrapper>
                <Rate />
                <Locales />
              </GroupWrapper>
              <GroupWrapper>
                <NotificationsWidget />
                {userPermissions['product_compare_access'] && <Scales />}
                {/*<TextPagesHeaderMenu />*/}
                 <AISupport />
                <UserMenu />
              </GroupWrapper>
            </>
          }
          {
            windowWith <= 1440 &&
            <MobileHeaderMenuOpenButton
              isHeaderOpen={isHeaderOpen}
              onClick={() => setIsHeaderOpen(prevState => !prevState) }
            >
              <img src={arrowDown} alt="arrow-down"/>
            </MobileHeaderMenuOpenButton>
          }
          {userPermissions['cart_access'] && (
            <MobileCartButtonWrapper minWidth={150}>
              {/*<Buscet />*/}
              <CartButton windowWith={windowWith}/>
            </MobileCartButtonWrapper>
          )}
        </MobileHeaderTopInnerWrapper>
        <MobileHeaderBottomInnerWrapper isHeaderOpen={isHeaderOpen}>
          {
            windowWith <= 768 &&
            <MobileToolsWrapper>
              <GroupWrapper>
                <Rate />
                <Locales />
              </GroupWrapper>
              <GroupWrapper>
                <NotificationsWidget />
                {windowWith > 768 && userPermissions['product_compare_access'] && <Scales />}
                {/*<TextPagesHeaderMenu />*/}
                 <AISupport />
                <UserMenu />
              </GroupWrapper>
            </MobileToolsWrapper>
          }
          <MobileExchangeRateBonusWidgetWrapper>
            <WrapperExchangeRate>
              <ExchangeRate />
            </WrapperExchangeRate>
            <MobileBonusWidgetWrapper>
              <BonusWidjet />
            </MobileBonusWidgetWrapper>
          </MobileExchangeRateBonusWidgetWrapper>
          <Wrapper>
            <TempMainWrap
              href="https://viatec.ua/"
              color={color}
              hoverColor={`rgba(${color.main
                .substr(1)
                .match(/../g)
                .map(x => +`0x${x}`)},${0.08})`}
            >
              <TempLeftSide>
                <TempSvg color={color}>
                  <use href={`${Sprite}#icon-logout`}></use>
                </TempSvg>
                <Typography
                  color={`${color.gray} !important`}
                  variant={'bodyBoldSmall'}
                  component={'span'}
                >
                  {t('go_to_old_cabinet')}
                </Typography>
              </TempLeftSide>
            </TempMainWrap>
          </Wrapper>
        </MobileHeaderBottomInnerWrapper>
      </Component>
    );
  }

};

const GroupWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  :not(:first-child) {
    margin-left: 24px;
  }
  :nth-child(2) {
    margin-left: auto;
  }
`;

const Component = styled.header`
  display: flex;
  align-items: center;
  width: 100%;
  padding-right: 32px;
  height: 68px;
  background: ${prop => prop.colors.white};
  border-bottom: 1px solid ${prop => prop.colors.line};

  @media(max-width: 1440px) {
    flex-wrap: wrap;
    padding-right: 0;
    position: relative;
  }
`;

const WrapperLogo = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  width: 240px;
  justify-content: center;
  padding: 0 16px 0 16px;
  border-right: 1px solid ${props => props.color};
  column-gap: 23px;

  @media(max-width: 1680px) {
    position: relative;
    padding: 0 8px;
    width: 130px;
    column-gap: 0;
  }
  @media(max-width: 1440px) {
    padding: 0 5px;
    width: 120px;
  }
  @media(max-width: 1199px) {
    width: 70px;
    margin-right: auto;
    img {
      width: 65px;
    }
  }
  @media(max-width: 768px) {
    width: 60px;
    img {
      width: 55px;
    }
  }
`;

const Wrapper = styled.div`
  padding: ${prop => prop.padding};
  margin-left: 34px;
  min-width: ${({ minWidth }) => (minWidth ? `${minWidth}px` : '')};

  @media(max-width: 1680px) {
    margin-left: auto;
  }
  @media(max-width: 1440px) {
    margin-left: 16px;
    margin-right: 16px;
  }
  @media(max-width: 768px) {
    margin-top: 16px;
  }
`;

const WrapperExchangeRate = styled.div`
  margin-left: 34px;
  margin-right: auto;

  @media(max-width: 1680px) {
    margin-left: 24px;
    margin-right: 8px;
  }
  @media(max-width: 1440px) {
    margin-left: 10px;
    margin-right: 8px;
  }
  @media(max-width: 768px) {
    margin-right: 0px;
  }
`;

const BetaWarning = styled.div`
  display: flex;
  background: ${props => props.colors.red};
  padding: 4px 10px;
  border-radius: 44px;
  font-weight: 700;
  font-size: 0.625rem;
  color: ${props => props.colors.white};
  margin-top: 4px;

  @media(max-width: 1680px) {
    position: absolute;
    top: -2px;
    right: 2px;
  }
  @media(max-width: 1440px) {
    top: -10px;
    right: 2px;
  }
  @media(max-width: 1199px) {
    top: -17px;
    right: -2px;
  }
  @media(max-width: 768px) {
    top: -14px;
    right: 1px;
    padding: 1px 4px;
  }
`;

Header.className = Component;

const TempMainWrap = styled.a`
  display: flex;
  align-items: center;
  //TODO create object for static colors, if situation with background repeat
  background: #f5f6fa;
  padding: 8px 12px;
  border-radius: 4px;
  width: 100%;
  gap: 12px;
  border: 1px solid ${({ color }) => color.line};
  transition: 0.3s ease;

  &:hover {
    background: ${({ hoverColor }) => hoverColor};
  }
`;

const TempLeftSide = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  flex: 50%;
`;

const TempSvg = styled.svg`
  width: 16px;
  height: 16px;
  fill: transparent;
  vertical-align: middle;
  position: relative;
  top: -2px;
`;

const MobileHeaderTopInnerWrapper = styled.div`
  width: 100%;
  display: flex;
  padding: 9px 0;
  align-items: center;

  @media(max-width: 1440px) {
    padding: 6px 0;
  }
`

const MobileHeaderBottomInnerWrapper = styled.div`
  width: 100%;
  display: flex;
  padding: 10px 0;
  border-top: 1px solid #DBDEE9;

  @media(max-width: 1440px) {
    align-items: flex-end;
    padding: ${props => (props.isHeaderOpen ? '10px 0' : '0')};
    background-color: #fff;
    transition: 0.1s;
    position: absolute;
    top: 100%;
    bottom: 0;
    left: 0;
    right: 0;
    //transform: translateY(100%);
    z-index: 999;
    opacity: ${props => (props.isHeaderOpen ? '1' : '0')};
    height: ${props => (props.isHeaderOpen ? '69px' : '0')};
    overflow: ${props => (props.isHeaderOpen ? 'visible' : 'hidden')};
    pointer-events: ${props => (props.isHeaderOpen ? 'auto' : 'none')};
    box-shadow: 2px 4px 5px rgba(0, 0, 0, 0.05);
  }
  @media(max-width: 768px) {
    flex-direction: column;
    height: 165px;
  }
`

const MobileCartButtonWrapper = styled.div`
  padding: ${prop => prop.padding};
  min-width: ${({ minWidth }) => (minWidth ? `${minWidth}px` : '')};

  @media(max-width: 1680px) {
    margin-right: 16px;
  }
  @media(max-width: 768px) {
    margin-left: 0;
    margin-right: 8px;
  }
`;

const MobileBonusWidgetWrapper  = styled.div`
  padding: ${prop => prop.padding};
  margin-left: 34px;
  min-width: ${({ minWidth }) => (minWidth ? `${minWidth}px` : '')};

  @media(max-width: 1680px) {
    margin-right: auto;
  }
  @media(max-width: 768px) {
    margin-right: 0;
    margin-left: 0;
  }
`;

const MobileToolsWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 16px 0 3px;
`

const MobileExchangeRateBonusWidgetWrapper = styled.div`
  width: 100%;
  margin-top: 16px;
  display: flex;
  justify-content: space-between;
  padding: 0 16px 0 3px;
`

const MobileHeaderMenuOpenButton = styled.span`
  margin: 0 36px;
  cursor: pointer;
  transition: 0.2s;

  img {
    width: 30px;
    transform: ${props => (props.isHeaderOpen ? 'rotate(180deg)' : 'none')};
  }

  @media(max-width: 450px) {
    margin-right: 26px;
  }
`
