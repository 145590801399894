import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import styled from 'styled-components';

import { Sprite } from '@root/assets/svg';
import { Empty } from '@root/components';
import { useNavigation } from '@root/hooks';
import {
  AnimateText,
  AvatarUI,
  MenuBody,
  MenuButton,
  MenuItemCustoms,
} from '@root/ui';

import authOperation from '@redux/auth/auth-operation';
import userSelectors from '@redux/user/user-selectors';

import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';

export const UserMenu = () => {
  const { color } = useTheme();
  const [anchorEl, setAnchorEl] = useState(null);
  const dispatch = useDispatch();
  const open = Boolean(anchorEl);
  const name = useSelector(userSelectors.getUserName);
  const companyName = useSelector(userSelectors.getUserCompanyName);
  const avatar = useSelector(userSelectors.getAvatar);

  const { t } = useTranslation('header', { useSuspense: false });

  const { navigation } = useNavigation();

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  return (
    <Box>
      <MenuButton
        sx={{
          minWidth: '24px',
          textTransform: 'initial',
          textAlign: 'left',
          color: color.gray_60,
          '&:hover': {
            color: color.main,
          },
        }}
        open={open}
        handleClick={handleClick}
      >
        <svg width={24} height={24} fill={'none'}>
          <use href={`${Sprite}#icon-user-menu`}></use>
        </svg>
      </MenuButton>

      <MenuBody
        anchorEl={anchorEl}
        open={open}
        handleClose={handleClose}
        sx={{ width: '127px' }}
      >
        <MenuItemCustoms
          handelClick={() => {
            handleClose();
            navigation('/profile/personal-info');
          }}
        >
          <Typography color={`${color.gray} !important`} variant={'bodySmall'}>
            {t('header:user_menu_widget.private_data')}
          </Typography>
        </MenuItemCustoms>
        <MenuItemCustoms
          handelClick={() => {
            dispatch(authOperation.logOut());
            handleClose();

              // REMOVE USER DATA FOR GOOGLE ANALYTICS WHEN LOGOUT
              localStorage.removeItem('savedUserForGA')
          }}
        >
          <Typography color={`${color.gray} !important`} variant={'bodySmall'}>
            {t('header:user_menu_widget.logout')}
          </Typography>
        </MenuItemCustoms>
      </MenuBody>
    </Box>
  );
};

const Box = styled.div`
  display: flex;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 8px;
  margin-right: 8px;
`;
