import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import styled from 'styled-components';

import { Cloud, Sprite } from '@root/assets/svg';
import { AvatarUI, IconBtn, MessageError } from '@root/ui';

import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';

const DocumentFileLoader = ({
  fileRef,
  file,
  setFile,
  title,
  subTitle,
  initError,
  optionalRecommendationSize = '',
  styles,
  accept,
  type = ['image/jpeg', 'image/png', 'image/webp'],
}) => {
  const { t } = useTranslation(['registration', 'validation'], {
    useSuspense: false,
  });
  const { color } = useTheme();
  const [dragActive, setDragActive] = useState(false);
  const [error, setError] = useState({ init: false, text: '' });
  useEffect(() => {
    setError({});
  }, [file]);

  const handelClick = e => {
    fileRef.current.click();
  };

  useEffect(() => {
    if (initError) {
      setError(initError);
    }
  }, [initError]);

  // handle drag events
  const handleDrag = e => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDragActive(true);
    } else if (e.type === 'dragleave') {
      setDragActive(false);
    }
  };

  const handleDrop = e => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      const file = e.dataTransfer.files[0];
      validationFile(file);
    }
  };

  const handleFile = e => {
    const file = e.currentTarget.files[0];
    validationFile(file);
    e.currentTarget.value = null;
  };

  const validationFile = file => {
    const req = [...type];

    if (!req.includes(file.type)) {
      setError({ init: true, text: t('validation:invalid_file') });
      return;
    }
    setFile(file);
  };

  const { text, init } = error;

  return (
    <>
      <FileLoaderWrapper style={{ ...styles }}>
        <DropArea
          color={color}
          onClick={handelClick}
          onDragEnter={handleDrag}
          styles={styles}
        >
          <CloudIcon src={Cloud} alt="Imag Cloud" />
          <Typography
            sx={{ mt: '10px', color: color.gray }}
            variant={'bodyBoldSmall'}
            align={'center'}
            component={'p'}
          >
            {title}
          </Typography>
          <Typography
            sx={{ mt: '4px', color: color.gray_80 }}
            variant={'bodySmall'}
            align={'center'}
            component={'p'}
          >
            {subTitle || t('registration:step_two.loader.subTitle')}
          </Typography>

          <Typography
            sx={{ mt: '4px', color: color.gray_60 }}
            variant={'bodyExSmall'}
            align={'center'}
            component={'p'}
          >
            {`PNG, JPG ${optionalRecommendationSize}`}
          </Typography>
          {dragActive && (
            <HideBox
              id="drag-file-element"
              onDragEnter={handleDrag}
              onDragLeave={handleDrag}
              onDragOver={handleDrag}
              onDrop={handleDrop}
            ></HideBox>
          )}
        </DropArea>
        <File type="file" ref={fileRef} onChange={handleFile} accept={accept} />
      </FileLoaderWrapper>
      {init && <MessageError errorText={text} />}
    </>
  );
};

export default DocumentFileLoader;

const FileLoaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 30px;
  margin-top: 28px;
  position: relative;
`;

const DropArea = styled.div`
  width: 100%;

  border: 1px dashed ${props => props.color.gray_40};
  border-radius: 4px;
  padding: 16px;

  cursor: pointer;
  background: ${({ styles }) =>
    styles?.background ? styles?.background : 'transparent'};

  &:hover {
    background: ${({ color }) => color.main_light};
  }
`;

const CloudIcon = styled.img`
  margin-left: auto;
  margin-right: auto;
`;

const File = styled.input`
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  padding: 0;
  white-space: nowrap;
  clip-path: inset(100%);
  clip: rect(0 0 0 0);
  overflow: hidden;
`;

const HideBox = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 1rem;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
`;
