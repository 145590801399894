import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Empty, InfiniteScroll, NewsOrEventCard } from '@root/components';
import { useNavigation } from '@root/hooks';
import { CustomScroll, NavigationButton, ProgressLoader } from '@root/ui';

import newsAndEventsOperation from '@redux/news-and-events/news-and-events-operation';
import newsAndEventsSelectors from '@redux/news-and-events/news-and-events-selectors';
import { clearState } from '@redux/news-and-events/news-and-events-slice';

export const NewsAndEventsWidget = ({ activeTab, isEdit }) => {
  const getArticlesList = useSelector(newsAndEventsSelectors.articlesList);
  const meta = useSelector(newsAndEventsSelectors.articlesListMeta);
  const loadingArticlesList = useSelector(
    newsAndEventsSelectors.loadingArticlesList,
  );
  const dispatch = useDispatch();

  const { navigation } = useNavigation();
  const { t } = useTranslation('news_and_events');

  useEffect(() => {
    switch (activeTab) {
      case 'news':
        dispatch(newsAndEventsOperation.getNewsList({ page: 1 }));
        break;
      case 'events':
        dispatch(newsAndEventsOperation.getEventsList({ page: 1 }));
        break;
      default:
        break;
    }
  }, [activeTab]);

  useEffect(() => {
    return () => {
      dispatch(clearState());
    };
  }, []);

  const handlePagination = () => {
    const currentPage = meta.current_page + 1;

    switch (activeTab) {
      case 'news':
        dispatch(newsAndEventsOperation.getNewsList({ page: currentPage }));
        break;
      case 'events':
        dispatch(newsAndEventsOperation.getEventsList({ page: currentPage }));
        break;
      default:
        break;
    }
  };

  const navigateTo = slug => {
    const path = `/announcements/${activeTab}${slug.length ? `/${slug}` : ''}`;
    navigation(path);
  };

  return (
    <ContentWrap>
      <CustomScroll vertical={true}>
        {!loadingArticlesList && !getArticlesList.length ? (
          <Empty
            type="EmptyBox"
            title={t(
              `news_and_events:${
                activeTab === 'news' ? 'empty_news_title' : 'empty_events_title'
              }`,
            )}
            subTitle=""
            buttonTittle=""
          />
        ) : (
          <InfiniteScroll
            data={getArticlesList}
            onScroll={({ inView }) =>
              inView &&
              meta.current_page !== meta.last_page &&
              handlePagination()
            }
          >
            {({ isInView, item, index }) => (
              <NewsOrEventCard
                navigationBtn={true}
                isActive={false}
                viewType={activeTab}
                item={item}
                handleCardEvent={() => {}}
                handleNavigationBtnEvent={navigateTo}
                cardStyles={{ cursor: 'initial' }}
              />
            )}
          </InfiniteScroll>
        )}
        {loadingArticlesList && <ProgressLoader />}
      </CustomScroll>
      {!isEdit && (
        <NavigationButton
          onClick={() =>
            navigateTo(
              getArticlesList.length > 0 ? getArticlesList[0]?.slug : '',
            )
          }
          sx={{
            position: 'absolute',
            top: 0,
            right: 0,
            padding: '10px 20px !important',
          }}
        >
          {t('buttons.view_all')}
        </NavigationButton>
      )}
    </ContentWrap>
  );
};

const ContentWrap = styled.div`
  height: 307px;
`;

NewsAndEventsWidget.propTypes = {
  activeTab: PropTypes.string,
  isEdit: PropTypes.bool,
};
