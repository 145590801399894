import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { serialize } from 'object-to-formdata';
import styled from 'styled-components';

import {
  Voteface1,
  Voteface2,
  Voteface3,
  Voteface4,
  Voteface5,
  VotefaceUnset1,
  VotefaceUnset2,
  VotefaceUnset3,
  VotefaceUnset4,
  VotefaceUnset5,
} from '@root/assets/image/index';
import { Sprite } from '@root/assets/svg/index';
import { handleDispatchFetch } from '@root/helpers/handleDispatchFetch';
import userOperation from '@root/redux/user/user-operation';
import { ButtonBase } from '@root/ui/Button/ButtonBase/index';
import { ButtonWithoutBorder } from '@root/ui/Button/ButtonWithoutBorder/index';
import { TextArea } from '@root/ui/Input/TextArea/index';

import { useTheme } from '@mui/material/styles';

import { Box, Typography } from '../../../../node_modules/@mui/material/index';
import moment from '../../../../node_modules/moment/moment';

const ReviewForm = () => {
  const { color } = useTheme();
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [display, setDisplay] = useState('form');
  const [selectedVote, setSelectedVote] = useState(null);
  const [textValue, setTextValue] = useState('');

  const [daysInterval, setDaysInterval] = useState(30);

  useEffect(() => {
    // const lastRateDate = new Date(window.localStorage.getItem('lastRateDate'));
    // const lastRateDateMoment = moment(lastRateDate);

    // if (!lastRateDateMoment._isValid) {
    //   window.localStorage.setItem('lastRateDate', Date());
    // } else if (moment().diff(lastRateDateMoment, 'days') >= daysInterval) {
    //   setShow(true);
    // }

    if (window.localStorage.getItem('rateOpen') === null) {
      setShow(true);
    }
  }, []);

  const onSubmit = () => {
    if (selectedVote) {
      window.localStorage.setItem('rateOpen', true);
      handleDispatchFetch(
        ({ onResolve, onReject }) => {
          dispatch(
            userOperation.createReview({
              formData: serialize({ rating: selectedVote, comment: textValue }),
              onReject,
              onResolve,
            }),
          );
        },
        () => {
          setDisplay('success');
          window.localStorage.setItem('lastRateDate', Date());
        },
      );
    }
  };

  return (
    <>
      {show && (
        <PositionAbsoluteWrap>
          <FormWrap>
            <Heading>
              {display === 'form' ? (
                <Typography variant="h2" color={color.gray}>
                  Оцініть користування сервісом
                </Typography>
              ) : (
                <div></div>
              )}

              <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <svg
                  width={24}
                  height={24}
                  stroke={color.gray_60}
                  onClick={() => {
                    setShow(false);
                  }}
                >
                  <use href={`${Sprite}#icon-close`} />
                </svg>
              </Box>
            </Heading>
            {display === 'form' ? (
              <>
                <div>
                  <VoteFaceWrap>
                    <img
                      src={selectedVote === 1 ? Voteface1 : VotefaceUnset1}
                      alt=""
                      onClick={() => {
                        setSelectedVote(1);
                      }}
                    />
                    <img
                      src={selectedVote === 2 ? Voteface2 : VotefaceUnset2}
                      alt=""
                      onClick={() => {
                        setSelectedVote(2);
                      }}
                    />
                    <img
                      src={selectedVote === 3 ? Voteface3 : VotefaceUnset3}
                      alt=""
                      onClick={() => {
                        setSelectedVote(3);
                      }}
                    />
                    <img
                      src={selectedVote === 4 ? Voteface4 : VotefaceUnset4}
                      alt=""
                      onClick={() => {
                        setSelectedVote(4);
                      }}
                    />
                    <img
                      src={selectedVote === 5 ? Voteface5 : VotefaceUnset5}
                      alt=""
                      onClick={() => {
                        setSelectedVote(5);
                      }}
                    />
                  </VoteFaceWrap>
                  <Box
                    sx={{
                      mt: '8px',
                      display: 'flex',
                      justifyContent: 'space-between',
                    }}
                  >
                    <Typography variant="bodyExSmall" color={color.gray_60}>
                      Дуже погано
                    </Typography>
                    <Typography variant="bodyExSmall" color={color.gray_60}>
                      Дуже добре
                    </Typography>
                  </Box>
                </div>
                <Box sx={{ mt: '24px' }}>
                  <TextArea
                    value={textValue}
                    onChange={val => setTextValue(val)}
                    placeholder={'Поділіться з нами, що ми можемо покращити...'}
                  ></TextArea>
                </Box>
                <BtnsWrap>
                  <ButtonWithoutBorder
                    onClick={() => {
                      setShow(false);
                    }}
                  >
                    Нагадати пізніше
                  </ButtonWithoutBorder>
                  <ButtonBase
                    sx={{ ml: '10px' }}
                    disabled={!selectedVote}
                    onClick={onSubmit}
                  >
                    Надіслати
                  </ButtonBase>
                </BtnsWrap>
              </>
            ) : (
              <Success>
                <svg
                  width="70"
                  height="61"
                  viewBox="0 0 70 61"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M69.83 18.8894C69.83 8.8384 61.653 0.660156 51.6043 0.660156C44.2293 0.660156 37.8676 5.06356 35.0004 11.3761C32.1343 5.06356 25.7714 0.660156 18.4 0.660156C8.3466 0.660156 0.171875 8.8384 0.171875 18.8894C0.171875 21.0096 0.535405 23.043 1.20618 24.9381C1.96842 27.0958 3.1235 29.0659 4.58818 30.7651C5.21321 31.4922 5.89102 32.1676 6.62394 32.788L34.4258 60.4033C34.597 60.5746 34.8198 60.6602 35.0449 60.6602C35.2701 60.6602 35.4964 60.5746 35.6688 60.4033L64.5026 31.756L64.5002 31.7525C66.3695 29.8797 67.8341 27.6059 68.7523 25.0694C69.4489 23.1392 69.83 21.0577 69.83 18.8894Z"
                    fill={color.main}
                  />
                </svg>
                <Typography variant="h2" color={color.gray} sx={{ mt: '16px' }}>
                  Дуже дякуємо!
                </Typography>
                <Typography
                  variant="bodyLarge"
                  color={color.gray_60}
                  sx={{ mt: '16px' }}
                >
                  Ми цінуємо ваші коментарі
                </Typography>
              </Success>
            )}
          </FormWrap>
        </PositionAbsoluteWrap>
      )}
    </>
  );
};

export default ReviewForm;

const FormWrap = styled(Box)`
  border-radius: 4px;
  padding: 23px 20px;
  background-color: #fff;
  box-shadow: 0px 0px 30px 0px rgba(54, 74, 99, 0.16);
`;

const PositionAbsoluteWrap = styled.div`
  position: fixed;
  z-index: 100;
  bottom: 20px;
  right: 20px;
  min-width: 360px;
`;

const Heading = styled(Box)`
  display: grid;
  grid-template-columns: 1fr 30px;

  svg {
    cursor: pointer;
  }
`;

const VoteFaceWrap = styled(Box)`
  display: flex;
  justify-content: space-around;
  margin-top: 24px;

  img {
    width: 36px;
    height: 36px;
    cursor: pointer;
  }
`;

const BtnsWrap = styled(Box)`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 16px;
`;

const Success = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
