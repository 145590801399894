import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import { LangManager } from '@root/components';
import { SwitchField } from '@root/components/ContactField/SwitchField';
import optionOperation from '@root/redux/option/option-operation';
import userSelectors from '@root/redux/user/user-selectors';
import { theme } from '@root/styles';
import { ButtonBase, CheckboxBase, OutlineButtonBase } from '@root/ui';

import optionSelectors from '@redux/option/option-selectors';

import { Typography } from '@mui/material';

export const AdminController = ({
  isEdit,
  onChangeMode,
  currentLang,
  handleChangeLang,
  onSubmit,
  isCreate,
  isDraft,
  changeIsDraft,
}) => {
  const { t } = useTranslation(['common', 'catalog', 'product_details'], {
    useSuspense: false,
  });
  const getPermissions = useSelector(userSelectors.getPermissions);
  const dispatch = useDispatch();

  const [isPublicNew, setIsPublicNew] = useState(false);

  const langsConf = useSelector(optionSelectors.getLangs);

  useEffect(() => {
    dispatch(optionOperation.getLangs({}));
  }, []);

  return (
    <>
      <Zoning></Zoning>
      <PosAbsolute>
        {!isEdit ? (
          <>
            {getPermissions['product_card_edit_access'] && (
              <FlexWrapper>
                <SwitchField
                  editValue={!isEdit}
                  onEdit={() => onChangeMode(!isEdit)}
                  width={'36px'}
                />
                <SwitcherLabel>
                  {t('catalog:product_details.edit_mode')}
                </SwitcherLabel>
              </FlexWrapper>
            )}
          </>
        ) : (
          <FlexStart>
            {' '}
            <LangManager
              conf={langsConf}
              currentLang={currentLang}
              handleChangeLang={handleChangeLang}
            />
            <EditContainer>
              {!isCreate && (
                <CheckboxBase
                  width={16}
                  onChange={changeIsDraft}
                  checked={!!!isDraft}
                >
                  <Typography
                    variant={'bodyBoldSmall'}
                    sx={{
                      marginRight: '16px',
                      marginLeft: '8px',
                      display: 'flex',
                      alignItems: 'center',
                      fontWeight: '700',
                      fontSize: '12px',
                      color: theme.color.gray_80,
                    }}
                  >
                    {t('product_details:public_new_checkbox')}
                  </Typography>
                </CheckboxBase>
              )}

              <FlexWrapper>
                <SwitchField
                  editValue={!isEdit}
                  onEdit={() => onChangeMode(!isEdit)}
                  width={'36px'}
                />
                <SwitcherLabel>
                  {t('catalog:product_details.edit_mode')}
                </SwitcherLabel>
              </FlexWrapper>
              <OutlineButtonBase
                onClick={() => {
                  onSubmit(true, isPublicNew);
                }}
                size="medium"
                sx={{ height: '36px', marginLeft: '8px' }}
              >
                <Typography variant={'bodyBoldSmall'}>
                  {t('common:buttons.saveAndClose')}
                </Typography>
              </OutlineButtonBase>
              <ButtonBase
                onClick={() => onSubmit(false, isPublicNew)}
                size="medium"
                sx={{ height: '36px', ml: '16px' }}
              >
                <Typography variant={'bodyBoldSmall'}>
                  {t('common:buttons.save')}
                </Typography>
              </ButtonBase>
            </EditContainer>
          </FlexStart>
        )}
      </PosAbsolute>
    </>
  );
};

const Zoning = styled.div`
  flex: 40px 0 0;
`;

const PosAbsolute = styled.div`
  position: absolute;
  left: 0;
  top: 42px;
  width: 100%;
  z-index: 10;
  padding: 10px 16px 10px;
  background-color: #fff;
`;

const SwitcherLabel = styled.div(() => ({
  marginLeft: '8px',
  display: 'flex',
  alignItems: 'center',
  fontWeight: '700',
  fontSize: '12px',
  color: theme.color.gray_80,
}));

const FlexWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const EditContainer = styled.div`
  display: flex;
  align-items: center;
`;

const FlexStart = styled.div`
  display: flex;
  justify-content: space-between;
`;
AdminController.propTypes = {
  isEdit: PropTypes.bool.isRequired,
  onChangeMode: PropTypes.func.isRequired,
  currentLang: PropTypes.string.isRequired,
  handleChangeLang: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};
