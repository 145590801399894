import { createSlice } from '@reduxjs/toolkit';

import agreementsDockOperations from '@redux/agreements-dock/agreements-dock-operations';
import orderServiceOperation from '@redux/order-service/order-service-operation';

const initialState = {
  isDockTypesLoading: false,
  dockTypes: [],
  dockTemplate: null,
  dockFile: null,
  dockList: [],
};

const agreementsDockSlice = createSlice({
  name: 'dock',
  initialState,
  reducers: {},
  extraReducers: {
    [agreementsDockOperations.getDockTypes.pending]: (state, { payload }) => {
      state.isDockTypesLoading = true;
    },
    [agreementsDockOperations.getDockTypes.fulfilled]: (state, { payload }) => {
      state.dockTypes = [...payload];
      state.isDockTypesLoading = false;
    },
    [agreementsDockOperations.getDockTypes.rejected]: (state, { payload }) => {
      state.isDockTypesLoading = false;
    },
    [agreementsDockOperations.getDockById.fulfilled]: (state, { payload }) => {
      state.dockTemplate = payload;
    },
    [agreementsDockOperations.createDock.fulfilled]: (state, { payload }) => {
      state.dockFile = payload;
    },
    [agreementsDockOperations.getDockList.fulfilled]: (state, { payload }) => {
      state.dockList = [...payload];
    },
  },
});

export default agreementsDockSlice.reducer;
