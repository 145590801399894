import styled from 'styled-components';
import ActivityAreasItem from '../ActivityAreasItem/index';

const ActivityAreasList = ({ list }) => {
  return (
    <ItemsListWrap>
      {list.length
        ? list.map(item => {
            return (
              <ActivityAreasItem key={item.id} id={item.id} name={item.name}>
                {item.name}
              </ActivityAreasItem>
            );
          })
        : ''}
    </ItemsListWrap>
  );
};

export default ActivityAreasList;

const ItemsListWrap = styled.div`
    max-height: calc(100vh - 190px);
    overflow-y: auto;
`
