import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import styled from 'styled-components';

import { handleDispatchFetch } from '@root/helpers';
import { useNotifications } from '@root/hooks';
import { ButtonWithoutBorder, NavigationButton } from '@root/ui';

import notificationsOperation from '@redux/notifications/notifications-operation';
import {
  setAllNotificationsAsViewed,
  setHasNotViewedNotifications,
} from '@redux/notifications/notifications-slice';

import { useTheme } from '@mui/material';

export const TabsNavigation = ({
  children,
  handleGetNotifications,
  tabsNavigation,
}) => {
  const { t } = useTranslation('common', { useSuspense: false });
  const [activeTabId, setActiveTabId] = useState('base');
  const dispatch = useDispatch();
  const { color } = useTheme();

  const handleSetActiveTab = tabId => {
    setActiveTabId(tabId);
    handleGetNotifications({ page: 1, per_page: 10, type: tabId });
  };

  const getSelectedNotifications = useMemo(
    () => () => {
      /*const { general, services } = notifications;
      return activeTabId === 'general' ? general : services;*/
      return [];
    },
    [activeTabId],
  );

  const handleMarkAllAsRead = () => {
    handleDispatchFetch(
      ({ onResolve, onReject }) => {
        dispatch(
          notificationsOperation.markAllAsRead({
            onResolve,
            onReject,
            type: activeTabId,
          }),
        );
      },
      () => {
        dispatch(setHasNotViewedNotifications(false));
        dispatch(setAllNotificationsAsViewed());
      },
    );
  };

  return (
    <>
      {tabsNavigation && (
        <NavigationWrapper borderColor={color.line}>
          <NavigationButton
            isActive={activeTabId === 'base'}
            onClick={() => handleSetActiveTab('base')}
            key={'general'}
          >
            {t('notifications.all')}
          </NavigationButton>
          <NavigationButton
            isActive={activeTabId === 'service'}
            onClick={() => handleSetActiveTab('service')}
            key={'services'}
            disabled
          >
            {t('notifications.service')}
          </NavigationButton>

          <ButtonWithoutBorder
            sx={{
              marginLeft: 'auto',
            }}
            onClick={() => handleMarkAllAsRead()}
          >
            {t('notifications.mark_as_read')}
          </ButtonWithoutBorder>
        </NavigationWrapper>
      )}
      {children({ getSelectedNotifications, type: activeTabId })}
    </>
  );
};

const NavigationWrapper = styled.div`
  display: flex;
  align-items: center;
  padding-right: 12px;
  border-bottom: 1px solid ${({ borderColor }) => borderColor};
`;
