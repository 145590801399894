import React from 'react';
import { useTranslation } from 'react-i18next';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import { HeaderDefault } from '@root/components';
import { ButtonBase, ModalBase, OutlineButtonBase } from '@root/ui';

import { Typography, useTheme } from '@mui/material';

export const AddOrReplaceCartProducts = ({
  open,
  handleClose,
  handleCallback,
}) => {
  const { t } = useTranslation('common', { useSuspense: false });
  const { color } = useTheme();
  return (
    <ModalBase
      open={open}
      handleClose={handleClose}
      modalHeader={
        <HeaderDefault onClose={handleClose} text={t('contains_products')} />
      }
      modalBottom={
        <ButtonsWrap>
          <OutlineButtonBase onClick={() => handleCallback('replace')}>
            {t('buttons.replace')}
          </OutlineButtonBase>
          <ButtonBase onClick={() => handleCallback('add')}>
            {t('buttons.add')}
          </ButtonBase>
        </ButtonsWrap>
      }
    >
      <Box>
        <Typography variant="bodyMedium" color={color.gray}>
          {t('contains_products_title')}
        </Typography>
      </Box>
    </ModalBase>
  );
};

const Box = styled.div`
  width: 400px;
`;
const ButtonsWrap = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 12px;
  height: 38px;
  gap: 12px;
`;

AddOrReplaceCartProducts.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func.isRequired,
  handleCallback: PropTypes.bool.isRequired,
};
