import OutlinedInput from '@mui/material/OutlinedInput';
import { styled } from '@mui/material/styles';

export const OutlinedInputCustom = styled(OutlinedInput)(({ theme }) => ({
  padding: '12px 16px',
  backgroundColor: `${theme.color.white}`,
  height: '36px',
  borderRadius: '4px',
  fieldset: {
    borderWidth: '1px !important',
    borderColor: `${theme.color.line}`,
  },
  input: {
    padding: '0',
    fontSize: '0.875rem',
    lineHeight: 1.5,
    color: theme.color.gray,
    '&::placeholder': {
      fontSize: '0.875rem',
      color: theme.color.gray_60,
      opacity: 1,
    },
    '&:-webkit-autofill': {
      boxShadow: '0 0 0 100px transparent inset',
      WebkitTextFillColor: theme.color.gray,
    },
  },

  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderColor: `${theme.color.line}`,
  },

  '&:hover': {
    background: theme.color.gray_20,

    fieldset: {
      borderColor: `${theme.color.line} !important`,
    },
  },

  'input:focus': {
    '&::placeholder': {
      color: theme.color.gray_40,
    },
  },
}));
