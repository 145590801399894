import styled from 'styled-components';

import { NoDeliveryDetailsImage } from '@root/assets/image';

import { Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

export const NoMutualSettlementDetails = ({ t }) => {
  const { color } = useTheme();
  return (
    <NoMutualSettlementDetailsWrapper color={color}>
      <Image src={NoDeliveryDetailsImage} />
      <Typography
        component="div"
        variant="bodySmall"
        color={color.gray_80}
        marginTop="40px"
        sx={{textAlign: 'center'}}
      >
        {t('mutual_settlements:select_document_to_see_details')}
      </Typography>
    </NoMutualSettlementDetailsWrapper>
  );
};

NoMutualSettlementDetails.propTypes = {};

const NoMutualSettlementDetailsWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${({ color }) => color.white};
  padding: 50px;
`;
const Image = styled.img``;
