import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import styled from 'styled-components';

import { Empty } from '@root/components';
import { getCurrentDate, getPriorDateFromNow } from '@root/helpers';
import { ErrorChecker1C } from '@root/middleware';
import { CustomScroll, ProgressLoader } from '@root/ui';

import orderServiceOperation from '@redux/order-service/order-service-operation';
import orderServiceSelectors from '@redux/order-service/order-service-selectors';

import { FullServiceOrdersWidget } from './FullServiceOrdersWidget';
import { ShrinkServiceOrdersWidget } from './ShrinkServiceOrdersWidget';

export const ServiceOrdersWidget = ({ conf, serviceOrderDetailsModule }) => {
  const isServiceOrdersLoading = useSelector(
    orderServiceSelectors.getIsServiceOrdersLoading,
  );
  const serviceOrders = useSelector(orderServiceSelectors.getServiceOrders);
  const errorOneC = useSelector(orderServiceSelectors.getErrorOneC);
  const dispatch = useDispatch();

  const { t } = useTranslation(['home']);

  useEffect(() => {
    dispatch(
      orderServiceOperation.getServiceOrders({
        date_to: getCurrentDate(),
        date_from: getPriorDateFromNow(30, 'days'),
      }),
    );
  }, []);

  return (
    <ErrorChecker1C
      error={errorOneC}
      errorPageStyles={{
        position: 'absolute',
        top: '55%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '65%',
        height: '100%',
        padding: 0,
      }}
    >
      <ServiceOrdersWidgetWrapper>
        {isServiceOrdersLoading ? (
          <ProgressLoader />
        ) : serviceOrders && serviceOrders.length === 0 ? (
          <Empty
            type={'NoHistory'}
            title={t('home:no_data_for_the_current_month')}
          />
        ) : (
          <CustomScroll vertical>
            {conf.size === '1fr' ? (
              <ShrinkServiceOrdersWidget
                serviceOrderDetailsModule={serviceOrderDetailsModule}
              />
            ) : (
              <FullServiceOrdersWidget />
            )}
          </CustomScroll>
        )}
      </ServiceOrdersWidgetWrapper>
    </ErrorChecker1C>
  );
};

const ServiceOrdersWidgetWrapper = styled.div`
  height: 307px;
`;
