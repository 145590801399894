export const DeliveryFromClientDetailsConf = (t, screenWidth) => [
  {
    label: t('common:table.table_heads.model'),
    slug: 'model',
    isDisplay: screenWidth > 768,
    style: {
      width: screenWidth > 768 ? '20%' : '100%',
    },
  },
  {
    label: t('common:table.table_heads.serial_number'),
    slug: 'serial_number',
    isDisplay: screenWidth > 768,
    style: {
      width: '20%',
    },
  },
  {
    label: t('common:table.table_heads.reported_malfunction'),
    slug: 'reported_malfunction',
    isDisplay: screenWidth > 768,
    style: {
      width: '30%',
    },
  },
  {
    label: t('common:table.table_heads.equipment'),
    slug: 'equipment',
    isDisplay: screenWidth > 768,
    style: {
      width: '30%',
    },
  },
];

export const SendingToClientDetailsConf = (t, screenWidth) => [
  {
    label: '#',
    slug: 'id',
    isDisplay: screenWidth > 768,
    style: {
      width: screenWidth > 768 ? '30px' : '100%',
    },
  },
  {
    label: t('common:table.table_heads.naming'),
    slug: 'name',
    isDisplay: screenWidth > 768,
    style: {
      width: '100%',
    },
  },
  {
    label: t('common:table.table_heads.amount'),
    slug: 'amount',
    isDisplay: screenWidth > 768,
    style: {
      minWidth: '100px',
      textAlign: 'right !important',
    },
  },
  {
    label: t('common:table.table_heads.unit_of_measurement'),
    slug: 'unit_of_measurement',
    isDisplay: screenWidth > 768,
    style: {
      minWidth: '150px',
      textAlign: 'center !important',
    },
  },
];
