import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const getActivityData = createAsyncThunk(
    'activityAreas/getActivityData',
    async ({ onResolve, onReject }, { rejectWithValue }) => {
        try {
            const { data: { data } } = await axios.get('/admin/activity-areas');
            if (onResolve) onResolve();
            return { data };
        } catch (error) {
            if (onReject) onReject();
            return rejectWithValue(error);
        }
    },
);
const addNewActivityArea = createAsyncThunk(
    'activityAreas/addNewActivityArea',
    async ({ name, onResolve, onReject }, { rejectWithValue }) => {
        try {
            const formData = new FormData();
            formData.append("name", name);
            const { data: { data } } = await axios.post('/admin/activity-areas', formData,
                {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                });
            if (onResolve) onResolve();
            return data;
        } catch (error) {
            if (onReject) onReject();
            return rejectWithValue(error);
        }
    },
);
const deleteActivityArea = createAsyncThunk(
    'activityAreas/deleteActivityArea',
    async ({ id, onResolve, onReject }, { rejectWithValue }) => {
        try {
            const { data: { data } } = await axios.delete(`/admin/activity-areas/${id}`);
            if (onResolve) onResolve();
            return id;
        } catch (error) {
            if (onReject) onReject();
            return rejectWithValue(error);
        }
    },
);
const updateActivityArea = createAsyncThunk(
    'activityAreas/updateActivityArea',
    async ({ id, name, onResolve, onReject }, { rejectWithValue }) => {
        try {
            const formData = new FormData();
            formData.append("name", name);
            const { data: { data } } = await axios.post(`/admin/activity-areas/${id}`, formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            });
            if (onResolve) onResolve();
            return data;
        } catch (error) {
            if (onReject) onReject();
            return rejectWithValue(error);
        }
    },
);

export default {
    getActivityData,
    addNewActivityArea,
    deleteActivityArea,
    updateActivityArea,
};
