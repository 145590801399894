import { useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import { CustomizedSwitches } from '@root/ui';
import { BasicSelect } from '@root/ui/Input/Select/index';

import { Typography, useTheme } from '@mui/material';

export const PermissionInput = ({
  handleToggleActivityPermission,
  handleSelect,
  data,
  type,
  value,
  disabled = false,
}) => {
  const { color } = useTheme();

  const [selectValue, setSelectValue] = useState('');
  useEffect(() => {
    setSelectValue(data?.options?.find(item => item.value === data?.value)?.id);
  }, [data]);

  const getInput = () => {
    switch (data?.type) {
      case 'switch':
        return (
          <CustomizedSwitches
            checked={value}
            onChange={handleToggleActivityPermission(
              data?.code,
              data?.child_codes,
            )}
            sx={{
              ml: 0,
              mr: 0,
            }}
            label={
              <Typography
                color={type === 'parent' ? color.gray : color.gray_80}
                component="p"
                variant={type === 'parent' ? 'bodyBoldMedium' : 'bodyMedium'}
                sx={{ mr: 'auto' }}
              >
                {data?.name}
              </Typography>
            }
            disabled={disabled}
            labelPlacement="start"
          />
        );
      case 'select': {
        return (
          <InputWrap>
            <Typography variant="bodyMedium" color={color.gray_80}>
              {data.name}
            </Typography>
            <BasicSelect
              data={data.options}
              value={selectValue}
              callBack={value => {
                handleSelect(data?.code, data?.child_codes, value, data);
                setSelectValue(value);
              }}
              sx={{ width: '300px', maxWidth: '100%' }}
            />
          </InputWrap>
        );
      }

      default:
        return (
          <>
            <Typography
              color={type === 'parent' ? color.gray : color.gray_80}
              component="p"
              variant={type === 'parent' ? 'bodyBoldMedium' : 'bodyMedium'}
              sx={{ mr: 'auto' }}
            >
              {data?.name}
            </Typography>
          </>
        );
    }
  };
  return <Item>{getInput()}</Item>;
};

const Item = styled.div`
  display: block;
  padding: 4px 0;
  width: 100%;
`;

const InputWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 6px;

  @media (max-width: 768px) {
    flex-direction: column;
    justify-content: start;
    align-items: start;
  }
`;

PermissionInput.propsTypes = {
  handleToggleActivityPermission: PropTypes.func,
  data: PropTypes.exact({
    name: PropTypes.string,
    type: PropTypes.string,
    code: PropTypes.string,
    child_codes: PropTypes.arrayOf(PropTypes.string),
    id: PropTypes.number,
    children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    checked: PropTypes.bool,
  }),
  type: PropTypes.string,
  value: PropTypes.bool,
  disabled: PropTypes.bool,
};
