import React, { useEffect, useMemo, useRef } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import axios from 'axios';
import PropTypes from 'prop-types';
import * as Emoji from 'quill-emoji';
import 'quill-emoji/dist/quill-emoji.css';
// import ImageUploader from 'quill-image-uploader';
import styled from 'styled-components';

import {
  IconArrowDown,
  IconBold,
  IconColorPicker,
  IconEmoji,
  IconImage,
  IconItalic,
  IconLink,
  IconList,
  IconNumList,
  IconRedo,
  IconUnderline,
  IconUndo,
  RequiredStar,
} from '@root/assets/svg';
import { MessageError } from '@root/ui';

import { Typography, useTheme } from '@mui/material';

export const RichEditor = ({ value, onChange, label, placeholder, error }) => {
  const ref = useRef(null);

  const { color } = useTheme();

  useEffect(() => {
    const Size = ReactQuill.Quill.import('attributors/style/size');
    Size.whitelist = [
      '9px',
      '10px',
      '11px',
      '12px',
      '14px',
      '16px',
      '18px',
      '20px',
      '22px',
      '24px',
      '26px',
      '28px',
    ];

    ReactQuill.Quill.register('modules/emoji', Emoji);
    // ReactQuill.Quill.register('modules/imageUploader', ImageUploader);
    ReactQuill.Quill.register(Size, true);
  }, []);

  const memoizedModules = useMemo(
    () => ({
      toolbar: {
        container: [
          [{ header: [1, 2, 3, 4, 5, 6, false] }],
          [
            {
              size: [
                '9px',
                '10px',
                '12px',
                '14px',
                '16px',
                '18px',
                '20px',
                '22px',
                '24px',
                '26px',
                '28px',
              ],
            },
          ],
          [
            {
              color: ['red', 'green', 'blue', 'orange', 'violet', ''],
            },
          ],
          ['bold', 'italic', 'underline'],
          ['link', 'image', 'emoji'],
          [
            { align: ['', 'center', 'right', 'justify'] },
            { list: 'ordered' },
            { list: 'bullet' },
          ],
          ['undo', 'redo'],
        ],
        handlers: {
          undo() {
            ref.current.getEditor().history.undo();
          },
          redo() {
            ref.current.getEditor().history.redo();
          },
        },
      },
      //   imageUploader: {
      //     upload: file => {
      //       return new Promise((resolve, reject) => {
      //         const formData = new FormData();
      //         formData.append('image', file);

      //         axios
      //           .post('/upload-image', formData)
      //           .then(response => resolve(response.data.url))
      //           .catch(error => {
      //             console.log(error);
      //             reject('Upload failed');
      //           });
      //       });
      //     },
      //   },
      'emoji-toolbar': true,
    }),
    [],
  );

  return (
    <EditorWrapper color={color} error={error?.init}>
      <InputLabelWrapper>
        <RequiredStarImg
          isRowDirection={false}
          src={RequiredStar}
          alt="Required Star"
        />
        <Typography
          variant="bodySmall"
          sx={{ color: color.gray, ml: '2px' }}
          component={'span'}
        >
          {label}
        </Typography>
      </InputLabelWrapper>
      <ReactQuill
        value={value}
        onChange={val => {
          if (ref.current) {
            const editor = ref.current?.getEditor();
            editor?.getLength() > 1 ? onChange(val) : onChange('');
          }
        }}
        ref={ref}
        theme="snow"
        modules={memoizedModules}
        placeholder={placeholder}
      />
      {error?.init && <MessageError errorText={error?.text} />}
    </EditorWrapper>
  );
};

const EditorWrapper = styled.div`
  .ql-editor.ql-blank {
    &:before {
      font-size: 0.875rem;
      color: ${({ color }) => color.gray_60};
      font-style: revert;
    }
  }

  .ql-editor {
    height: 200px;
    overflow: hidden auto;

    ::-webkit-scrollbar {
      width: 5px;
    }

    ::-webkit-scrollbar-thumb {
      background: ${({ color }) => color.gray_40};
      border-radius: 4px;
    }

    ::-webkit-scrollbar-track {
      background: ${({ color }) => color.white};
      border-left: 1px solid ${({ color }) => color.line};
    }

    img {
      display: inline-block;
    }
  }

  .ql-container,
  .ql-toolbar {
    border-color: ${({ color, error }) =>
      error ? color.red : color.line} !important;
    padding: 0 !important;
    background-color: ${({ color }) => color.white};
  }

  .ql-formats {
    padding: 8px 4px;
    margin-right: 0 !important;
    border-right: 1px solid ${({ color }) => color.line};
    height: 34px;
  }

  .ql-container {
    border-radius: 4px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    padding: 0 !important;
  }

  .ql-toolbar {
    border-radius: 4px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    display: flex;
    justify-content: space-between;

    &:after {
      content: none;
    }

    button {
      width: 18px !important;
      height: 18px !important;
      background-size: contain !important;
      margin: 0 4px;

      svg {
        display: none;
      }
    }

    .ql-formats:first-child {
      padding-right: 25px;
      padding-left: 16px;
      min-width: 115px;

      .ql-picker {
        height: 18px;
      }
    }

    .ql-formats:nth-child(2) {
      padding-right: 22px;
      padding-left: 16px;
    }

    .ql-formats:nth-child(5) {
      height: 34px;
    }

    .ql-formats:last-child {
      border-right: none;
    }
  }

  .ql-snow .ql-editing {
    left: 0 !important;
  }

  .ql-snow .ql-editing:lang(uk) {
    &:before {
      content: 'Додати посилання';
    }

    .ql-action {
      &:after {
        content: 'Зберегти';
      }
    }
  }

  .ql-snow .ql-stroke,
  .ql-fill {
    stroke: ${({ color }) => color.gray_60};
  }

  .ql-size {
    .ql-picker-label {
      &:before {
        content: attr(data-value) !important;
      }
    }
  }

  .ql-snow .ql-header,
  .ql-size,
  .ql-align {
    .ql-picker-label,
    .ql-picker-item {
      &:before {
        font-size: 14px !important;
      }

      &:hover {
        color: ${({ color }) => color.main};
      }
    }

    .ql-active {
      color: ${({ color }) => color.main} !important;
    }
  }

  .ql-snow .ql-header:lang(uk) {
    .ql-picker-label,
    .ql-picker-item {
      &:before {
        content: 'Звичайний' !important;
      }
    }

    .ql-picker-label[data-value='1'],
    .ql-picker-item[data-value='1'] {
      &:before {
        content: 'H1' !important;
      }
    }

    .ql-picker-label[data-value='2'],
    .ql-picker-item[data-value='2'] {
      &:before {
        content: 'H2' !important;
      }
    }

    .ql-picker-label[data-value='3'],
    .ql-picker-item[data-value='3'] {
      &:before {
        content: 'H3' !important;
      }
    }

    .ql-picker-label[data-value='4'],
    .ql-picker-item[data-value='4'] {
      &:before {
        content: 'H4' !important;
      }
    }

    .ql-picker-label[data-value='5'],
    .ql-picker-item[data-value='5'] {
      &:before {
        content: 'H5' !important;
      }
    }

    .ql-picker-label[data-value='6'],
    .ql-picker-item[data-value='6'] {
      &:before {
        content: 'H6' !important;
      }
    }
  }

  .ql-snow .ql-header,
  .ql-size,
  .ql-align {
    width: auto !important;

    .ql-picker-label {
      padding-left: 0;
      border: none;

      svg {
        display: none;
      }

      &:after {
        content: '';
        width: 20px;
        height: 20px;
        display: inline-block;
        background: url(${IconArrowDown}) no-repeat;
        background-size: contain;
        position: absolute;
        transform: rotate(0deg);
        transition: 0.2s ease all;
        right: -20px;
      }
    }
  }

  .ql-expanded {
    .ql-picker-label {
      &:after {
        transform: rotate(180deg) !important;
      }
    }
  }

  .ql-snow .ql-align {
    width: 20px !important;
    margin-right: 20px;

    .ql-picker-label {
      border: none;

      &:after {
        right: -15px;
        top: 0;
      }

      svg {
        display: initial;
      }
    }
  }

  .ql-snow .ql-color {
    width: 24px;
    height: 17px;
    text-align: center;

    .ql-picker-label {
      background: url(${IconColorPicker}) no-repeat;
      background-size: contain;
      width: 20px;
      height: 20px;

      svg {
        display: none;
      }
    }

    .ql-active {
      background-color: ${({ color }) => color.gray_40} !important;
    }

    .ql-picker-label {
      border: none;
    }
  }

  .ql-bold.ql-active,
  .ql-italic.ql-active,
  .ql-underline.ql-active,
  .ql-link.ql-active,
  .ql-list.ql-active {
    background-color: ${({ color }) => color.gray_40} !important;
    border-radius: 2px;
    padding: 2px;
  }

  .ql-snow.ql-toolbar .ql-bold {
    background: url(${IconBold}) no-repeat;
  }

  .ql-snow.ql-toolbar .ql-italic {
    background: url(${IconItalic}) no-repeat;
  }

  .ql-snow.ql-toolbar .ql-underline {
    background: url(${IconUnderline}) no-repeat;
  }

  .ql-snow.ql-toolbar .ql-link {
    background: url(${IconLink}) no-repeat;
  }

  .ql-snow.ql-toolbar .ql-image {
    background: url(${IconImage}) no-repeat;
  }

  .ql-snow.ql-toolbar .ql-emoji {
    background: url(${IconEmoji}) no-repeat;
  }

  .ql-snow.ql-toolbar .ql-list:first-of-type {
    background: url(${IconList}) no-repeat;
  }

  .ql-snow.ql-toolbar .ql-list:last-of-type {
    background: url(${IconNumList}) no-repeat;
  }

  .ql-snow.ql-toolbar .ql-undo {
    background: url(${IconUndo}) no-repeat;
  }

  .ql-snow.ql-toolbar .ql-redo {
    background: url(${IconRedo}) no-repeat;
  }

  .ql-snow .ql-picker.ql-size .ql-picker-item[data-value='9px'] {
    &:before {
      content: '9';
    }
  }

  .ql-snow .ql-size .ql-picker-item[data-value='10px'] {
    &:before {
      content: '10';
    }
  }

  .ql-snow .ql-size .ql-picker-item[data-value='11px'] {
    &:before {
      content: '11';
    }
  }

  .ql-snow .ql-size .ql-picker-item[data-value='12px'] {
    &:before {
      content: '12';
    }
  }

  .ql-snow .ql-size .ql-picker-item[data-value='14px'] {
    &:before {
      content: '14';
    }
  }

  .ql-snow .ql-size .ql-picker-item[data-value='16px'] {
    &:before {
      content: '16';
    }
  }

  .ql-snow .ql-size .ql-picker-item[data-value='18px'] {
    &:before {
      content: '18';
    }
  }

  .ql-snow .ql-size .ql-picker-item[data-value='20px'] {
    &:before {
      content: '20';
    }
  }

  .ql-snow .ql-size .ql-picker-item[data-value='22px'] {
    &:before {
      content: '22';
    }
  }

  .ql-snow .ql-size .ql-picker-item[data-value='24px'] {
    &:before {
      content: '24';
    }
  }

  .ql-snow .ql-size .ql-picker-item[data-value='26px'] {
    &:before {
      content: '26';
    }
  }

  .ql-snow .ql-size .ql-picker-item[data-value='28px'] {
    &:before {
      content: '28';
    }
  }
`;

const InputLabelWrapper = styled.div`
  display: flex;
  align-items: flex-start;
`;

const RequiredStarImg = styled.img`
  margin-top: 3px;
`;

RichEditor.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  error: PropTypes.object,
};
