import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import { StatusWithoutSteps, StepperStatusWithIcon } from '@root/components';
import { correctPhone } from '@root/helpers';
import { WidthContext } from '@root/providers/WidthProvider';

import { Typography, useTheme } from '@mui/material';

export const MyOrdersDetails = ({ data, activeTab }) => {
  const { color } = useTheme();
  const { t } = useTranslation(['order_history'], {
    useSuspense: false,
  });
  const { orderTab } = useParams();

  const { screenWidth } = useContext(WidthContext);

  const getStatus = () => {
    switch (orderTab ? orderTab : activeTab) {
      case 'archive-orders':
        return <StatusWithoutSteps data={data} />;

      default:
        break;
    }
  };

  const isMobile = () => screenWidth < 768;

  const calculateAmount = () => {
    return data?.details.reduce((acc, item) => {
      if (item.use_price) {
        return acc + +item?.prices?.[item?.use_price] * item.quantity;
      }
      return acc + +item.amount * item.quantity;
    }, 0);
  };

  return (
    <Wrapper
      color={color.line}
      padding={'31px 7px 19px 24px'}
      switchDirection={true}
    >
      <DeliveryWrapper>
        <PayInfo>
          <Typography
            variant={'bodyBoldSmall'}
            component={'p'}
            sx={{
              display: 'flex',
              alignItems: 'center',
              mb: isMobile() ? '4px' : '10px',
              color: color.gray_60,
            }}
          >
            {t('order_history:payment')}
          </Typography>
          <Typography
            variant={'h2'}
            component={'p'}
            sx={{
              display: 'flex',
              alignItems: 'center',
              mb: '4px',
              color: color.gray,
            }}
          >
            {calculateAmount().toFixed(2)} {data?.currency}
          </Typography>
          <Typography
            variant={'bodyBoldMedium'}
            component={'p'}
            sx={{
              display: 'flex',
              alignItems: 'center',
              color: color.gray,
            }}
          >
            {data?.payment_title}
          </Typography>
          {getStatus()}
        </PayInfo>
        <DeliveryInfo>
          <Typography
            variant={'bodyBoldSmall'}
            component={'p'}
            sx={{
              display: 'flex',
              alignItems: 'center',
              mb: isMobile() ? '4px' : '10px',
              color: color.gray_60,
            }}
          >
            {t('order_history:delivery_address')}
          </Typography>

          <Typography
            variant={'bodyBoldMedium'}
            component={'p'}
            sx={{
              display: 'flex',
              alignItems: 'center',
              color: color.gray,
              maxWidth: '268px',
            }}
          >
            {data?.last_name} {data?.first_name} {data?.middle_name}
          </Typography>

          <Typography
            variant={'bodyMedium'}
            component={'p'}
            sx={{
              display: 'flex',
              alignItems: 'center',
              color: color.gray_80,
            }}
          >
            {correctPhone(data?.phone)}
          </Typography>

          <Typography
            variant={'bodyBoldMedium'}
            component={'p'}
            sx={{
              display: 'flex',
              alignItems: 'center',
              mt: '10px',
              color: color.gray,
            }}
          >
            {data?.delivery_title}
          </Typography>

          <Typography
            variant={'bodyMedium'}
            component={'p'}
            sx={{
              color: color.gray_80,
              maxWidth: '100%',
            }}
          >
            <EllipsisText title={data?.address}>{data?.address}</EllipsisText>
          </Typography>
        </DeliveryInfo>
        <MoreInfo>
          {data?.payment && (
            <>
              <Typography
                variant={'bodyBoldSmall'}
                component={'p'}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  mb: isMobile() ? '4px' : '10px',
                  color: color.gray_60,
                }}
              >
                {t('order_history:payment_address')}
              </Typography>{' '}
              <Typography
                variant={'bodyMedium'}
                component={'p'}
                dangerouslySetInnerHTML={{
                  __html: data?.payment,
                }}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  color: color.gray,
                  maxWidth: '268px',
                }}
              />
            </>
          )}
        </MoreInfo>
      </DeliveryWrapper>
      <StatusDelivery>
        {data?.statuses && <StepperStatusWithIcon steps={data?.statuses} />}
      </StatusDelivery>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: ${({ switchDirection }) =>
    switchDirection ? 'column' : 'row'};
  justify-content: space-between;

  padding: ${({ padding }) => padding};
  border-bottom: 1px solid ${props => props.color};

  @media (max-width: 768px) {
    padding: 15px;
  }
`;

const DeliveryWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: start;
  margin-bottom: 28px;
  gap: 20px;
`;

const StatusDelivery = styled.div`
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  padding-bottom: 8px;
`;

const PayInfo = styled.div`
  flex: 0 1 165px;
`;

const DeliveryInfo = styled.div`
  flex: 0 1 330px;
`;

const MoreInfo = styled.div`
  flex: 0 1 311px;
`;

const Svg = styled.svg`
  fill: ${({ colorChosen }) => colorChosen};
  stroke: ${({ colorChosen }) => colorChosen};
  margin: 0 4px 0 0;
  flex-shrink: 0;
`;

const EllipsisText = styled.div`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  @supports (-webkit-line-clamp: 2) {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: initial;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
`;

MyOrdersDetails.prototype = {
  data: PropTypes.object,
  activeTab: PropTypes.string,
};
