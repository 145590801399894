import { css } from '@emotion/css';

import { theme } from '@root/styles';

export const listStyles = css`
  margin-top: 4px;

  & ul {
    max-height: 300px;
    border: 1px solid ${theme.color.line};
    border-radius: 4px;
    padding: 0 !important;
    overflow-y: auto;
    scrollbar-width: thin;

    ::-webkit-scrollbar {
      width: 5px;
    }

    ::-webkit-scrollbar-thumb {
      background: ${theme.color.gray_40};
      border-radius: 4px;
    }

    ::-webkit-scrollbar-track {
      background: ${theme.color.white};
      border-left: 1px solid ${theme.color.line};
    }
  }

  & li {
    padding: 4px 5px;
    border-radius: 2px;

    &:hover {
      background-color: ${theme.color.gray_20};
    }

    &:active {
      background-color: ${theme.color.main} !important;
      color: ${theme.color.white};
    }
  }

  & .MuiAutocomplete-option[aria-selected='true'] {
    background-color: ${theme.color.main} !important;
    color: ${theme.color.white} !important;
  }

  & .MuiAutocomplete-option[aria-selected='true'] > p {
    color: ${theme.color.white} !important;
  }
`;

export const inputStyles = {
  '.MuiInputBase-root': {
    height: '34px',
    paddingTop: '5px !important',
    paddingLeft: '16px !important',
    paddingBottom: '5px !important',
    '&:hover': {
      background: theme.color.gray_20,
      fieldset: {
        borderColor: `${theme.color.line} !important`,
      },
    },
  },
  '.MuiInputBase-input': {
    padding: '0 !important',
    fontSize: '0.875rem',
    lineHeight: 1.5,
    color: theme.color.gray,
    border: 'none',
    outline: 'none',
    '&::placeholder': {
      fontSize: '0.875rem',
      color: theme.color.gray_60,
      opacity: 1,
    },
    '&:-webkit-autofill': {
      boxShadow: '0 0 0 100px transparent inset',
      WebkitTextFillColor: theme.color.gray,
    },
  },
};
