import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { NavLink, useParams } from 'react-router-dom';

import styled from '@emotion/styled';
import PropTypes from 'prop-types';

import { Sprite } from '@root/assets/svg';
import { theme } from '@root/styles';
import { ButtonBase, ToggleBtn } from '@root/ui';
import { Buscet } from '@root/widgets/Buscet';

import cartSelectors from '@redux/cart/cart-selectors';
import userSelectors from '@redux/user/user-selectors';

import { Typography, useTheme } from '@mui/material';

export const CartButton = ({ refs, windowWith }) => {
  const { color } = useTheme();
  const totalQuantity = useSelector(cartSelectors.getTotalQuantity);
  const specialPrise = useSelector(cartSelectors.getSpacialPrise);
  const totalPrise = useSelector(cartSelectors.getTotalPrice);
  const currency = useSelector(userSelectors.getChoosedCurrency);
  const choosedCurrency = useSelector(
    userSelectors.getChoosedCurrencyWidget,
  ).field_name;

  const { lang } = useParams();
  const currentLang = lang ? `/${lang}` : '';

  const { t } = useTranslation(['common'], { useSuspense: false });

  return (
    <>
      {totalQuantity ? (
        <PriceWrapper
          ref={refs}
          colors={{
            white: color.white,
            gray: color.gray_80,
            line: color.line,
            gray_20: color.gray_20,
          }}
        >
          <NavLink
            to={currentLang + '/catalog/cart'}
            data-test-id="NoEmptyCart"
          >
            <PriceInner>
              <Wrapper>
                <svg width={16} height={16}>
                  <use href={`${Sprite}#icon-grocery_cart`}></use>
                </svg>
                <ItemsCounter
                  color={color.main}
                  data-test-id="TotalQuantityCart"
                >
                  {totalQuantity}
                </ItemsCounter>
                <PriceValueWrapper>
                  <Typography
                    color={theme.color.gray}
                    variant={'bodyBoldLarge'}
                  >
                    {specialPrise[choosedCurrency]} {''}
                    {currency}
                  </Typography>
                  {
                    windowWith > 500 &&
                    <Typography
                      color={theme.color.gray_60}
                      variant={'bodyBoldExSmall'}
                    >
                      {totalPrise[choosedCurrency]} {currency}
                    </Typography>
                  }

                </PriceValueWrapper>
              </Wrapper>
            </PriceInner>
          </NavLink>
        </PriceWrapper>
      ) : (
        <NavLink to={currentLang + '/catalog/cart'}>
          <Buscet />
        </NavLink>
      )}
    </>
  );
};

const PriceWrapper = styled.div`
  background: ${props => props.colors.white};
  stroke: ${props => props.colors.gray};
  cursor: pointer;
  height: 50px;
  border: 1px solid ${props => props.colors.line};
  border-radius: 4px;
  transition: 0.25s;

  &:hover {
    background: ${props => props.colors.gray_20};
  }
`;

const PriceInner = styled.div`
  padding: 5px 8px;
  display: flex;
  flex-direction: row;
  align-items: center;

  @media(max-width: 1680px) {
    padding: 5px;
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const ItemsCounter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  min-width: 16px;
  color: white;
  font-style: normal;
  font-weight: 700;
  font-size: 0.625rem;
  line-height: 100%;
  margin-bottom: 21px;
  height: 16px;
  border-radius: 50%;
  background-color: ${props => props.color};
  padding: 4px;

  &::before {
    content: '';
    display: block;
    position: absolute;
    width: 0;
    height: 0;
    border-top: 4px solid transparent;
    border-right: 8px solid ${props => props.color};
    border-bottom: 4px solid transparent;
    transform: translateY(85%) translateX(-86%) rotate(-37deg);
  }
`;

const PriceValueWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 8px;
  margin-right: 45px;

  @media(max-width: 1680px) {
    margin-right: 0;
    margin-left: 5px;
  }

  @media(max-width: 1199px) {
    span:first-child {
      font-size: 0.75rem;
    }
  }
`;

CartButton.propTypes = {
  refs: PropTypes.object,
};
