import styled from 'styled-components';

import { ProductNoPhoto } from '@root/assets/image/index';

const EmptyNews = () => {
  return (
    <ContentWrap>
      <img src={ProductNoPhoto} alt="" />
    </ContentWrap>
  );
};

export default EmptyNews;

const ContentWrap = styled.div`
  ${props => props.contentWrapStyles}

  img {
    width: 100%;
    
  }
`;
