export const CounterpartsConf = t => [
  {
    label: '',
    value: 'checkBox',
    type: 'checkBox',
    style: active =>
      active ? { ml: '32px', width: '16px' } : { ml: '32px', width: '16px' },
  },
  {
    label: t('admin:counterparties_table_conf.name'),
    value: 'official_name',
    type: 'name',
    style: active =>
      active ? { ml: '16px', width: '250px' } : { ml: '16px', width: '250px' }, //w:290
  },
  {
    label: t('admin:counterparties_table_conf.type'),
    value: 'type',
    type: 'number',
    style: active =>
      active ? { ml: '10px', width: '92px' } : { ml: '39px', width: '92px' }, //39px
  }, // change
  {
    label: t('admin:counterparties_table_conf.edrpou'),
    value: 'number',
    type: 'number',
    style: active =>
      active ? { ml: '10px', width: '70px' } : { ml: '35px', width: '70px' }, //35px
  }, // change
  {
    label: t('admin:counterparties_table_conf.ipn'),
    value: 'longNumber',
    type: 'longNumber',
    style: active =>
      active ? { ml: '24px', width: '185px' } : { ml: '48px', width: '208px' }, //w:208
  }, // change
  {
    label: t('admin:counterparties_table_conf.business'),
    value: 'businesses',
    type: 'businesses',
    style: active =>
      active ? { ml: '30px', width: '200px' } : { ml: '30px', width: '200px' },
    hide: true,
  },
  {
    label: t('admin:counterparties_table_conf.status'),
    value: 'status',
    type: 'status',
    style: active =>
      active ? { ml: '39px', width: '105px' } : { ml: '39px', width: '105px' },
    hide: true,
  },
  {
    label: '',
    value: 'edit',
    type: 'action',
    style: active => (active ? { ml: 'auto' } : { ml: 'auto' }),
    accordionData: ['Переглянути', 'Редагувати', 'Архівувати'],
  },
];
