export const cartConf = (t, screenWidth) => [
  {
    label: t('cart:cart_conf.all'),
    value: 'selectProduct',
    type: 'img',
    style: { maxWidth: '82px', width: '100%' },
    isDisplayHeader: screenWidth > 768,
    isDisplayBody: screenWidth > 768,
  },
  {
    label: t('cart:cart_conf.name'),
    value: 'name',
    type: 'email',
    style: { marginLeft:  screenWidth < 769 ? '0' : '32px', maxWidth: screenWidth < 769 ? '200px' : '422px', width: '100%' },
    isDisplayHeader: true,
    isDisplayBody: true,
  },
  {
    label: t('cart:cart_conf.price'),
    value: 'cost',
    type: 'cost',
    style: { marginLeft: '30px', maxWidth: '110px', width: '100%' },
    isDisplayHeader: screenWidth > 431,
    isDisplayBody: screenWidth > 431,
  }, // change
  {
    label: t('cart:cart_conf.availability'),
    value: 'availability',
    type: 'availability',
    style: { marginLeft: '139px', maxWidth: '100px', width: '100%' },
    isDisplayHeader: screenWidth > 1024,
    isDisplayBody: screenWidth > 1024,
  }, // change
  {
    label: '',
    value: 'action',
    type: 'action',
    style: { marginLeft: 'auto', maxWidth: '200px', width: '100%' },
    isDisplayHeader: screenWidth > 576,
    isDisplayBody: screenWidth > 576,
  }, // change
];
