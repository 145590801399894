const getCartData = state => state.cart.cartData;
const getSpacialPrise = state => state.cart.spacialPrise;
const getTotalPrice = state => state.cart.totalPrice;
const getTotalQuantity = state => state.cart.totalQuantity;
const getLoading = state => state.cart.loading;
const getError = state => state.cart.errors;
const getFirstLoading = state => state.cart.firstLoading;

const cartSelectors = {
  getCartData,
  getSpacialPrise,
  getTotalPrice,
  getTotalQuantity,
  getLoading,
  getError,
  getFirstLoading,
};
export default cartSelectors;
