import { Typography } from '@mui/material';

export const AccountingDocumentsConf = (t, screenWidth) => [
  {
    label: t('accounting_documents:conf.document'),
    slug: 'document',
    sort: false,
    isDisplay: true,
    style: {
      display: 'flex',
      flexDirection: 'column',
      gap: '8px',
      width: screenWidth > 1700 ? '400px' : '300px',
    },
  },
  {
    label: t('accounting_documents:conf.date'),
    slug: 'date',
    sort: true,
    isDisplay: screenWidth > 1700,
    style: {
      width: '130px',
    },
  },
  {
    label: t('accounting_documents:conf.status'),
    slug: 'status',
    sort: false,
    isDisplay: screenWidth > 1400,
    style: {
      width: '130px',
      textAlign: 'center',
    },
  },
  {
    label: (
      <>
        <Typography variant="bodyBoldSmall" component="p">
          {t('accounting_documents:conf.summ_all')}
        </Typography>
        <Typography variant="bodySmall" component="p">
          {t('accounting_documents:conf.summ_paid')}
        </Typography>
        <Typography variant="bodySmall" component="p">
          {t('accounting_documents:conf.summ_need_to_pay')}
        </Typography>
      </>
    ),
    slug: 'balance',
    sort: false,
    isDisplay: true,
    style: {
      width: '80px',
      textAlign: 'right',
    },
  },
  {
    label: (
      <>
        <Typography variant="bodyBoldSmall" component="p">
          {t('accounting_documents:conf.bill')}
        </Typography>
        <Typography variant="bodyBoldSmall" component="p">
          {t('accounting_documents:conf.expenses')}
        </Typography>
      </>
    ),
    sort: false,
    slug: 'bill',
    isDisplay: screenWidth > 576,
    style: {
      width: '130px',
      textAlign: 'center',
    },
  },
];
