import PropTypes from 'prop-types';
import styled from 'styled-components';

import { BasicTooltip } from '@root/ui';

import { useTheme } from '@mui/material/styles';

export const ToggleBtn = ({
  href,
  onClick,
  size = 16,
  isActive,
  isDisabled,
  refs,
  tooltipText = '',
  ...prop
}) => {
  const { color } = useTheme();
  return (
    <BasicTooltip title={tooltipText} placement="top">
      <Button
        type={'button'}
        onClick={onClick}
        color={color}
        size={size}
        isActive={isActive}
        disabled={isDisabled}
        ref={refs}
        {...prop}
      >
        <Svg width={16} height={16}>
          <use href={href}></use>
        </Svg>
      </Button>
    </BasicTooltip>
  );
};

const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;

  width: ${prop => prop.size}px;
  height: ${prop => prop.size}px;
  fill: ${({ color, isActive }) => (isActive ? color.white : color.gray_60)};
  stroke: ${({ color, isActive }) => (isActive ? color.white : color.gray_60)};
  background-color: ${({ color, isActive }) =>
    isActive ? color.main : 'transparent'};
  cursor: pointer;

  border: ${({ color, isActive, disableBorder }) =>
    disableBorder ? 'none' : isActive ? color.main : `1px solid ${color.line}`};
  border-radius: 4px;
  transition: 0.25s;

  &:active svg {
    fill: ${({ color, isActive }) => !isActive && color.main};
    stroke: ${({ color, isActive }) => !isActive && color.main};
  }

  &:hover {
    fill: ${({ color, isActive }) => !isActive && color.gray_80};
    stroke: ${({ color, isActive }) => !isActive && color.gray_80};
    box-shadow: ${({ isActive }) =>
      isActive && '0px 4px 10px 0px rgba(57, 99, 225, 0.28)'};
    background-color: ${({ color, isActive }) => !isActive && color.gray_20};
  }

  &:active {
    box-shadow: ${({ isActive }) =>
      isActive && '0px 2px 4px 0px rgba(57, 99, 225, 0.34)'};
  }
`;

const Svg = styled.svg``;

ToggleBtn.propTypes = {
  href: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  size: PropTypes.number,
  isActive: PropTypes.bool,
  isDisabled: PropTypes.bool,
  refs: PropTypes.object,
};
