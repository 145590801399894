import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const getTextPages = createAsyncThunk(
  'spec-guide/getTextPages',
  async (
    { onResolve, onReject },
    { rejectWithValue },
  ) => {
    try {
      const { data: { data } } = await axios.get('/page');
      if (onResolve) onResolve(data);
      return data;
    } catch (error) {
      if (onReject) onReject(error.response.data.message);
      return rejectWithValue(error);
    }
  },
);

const getTextPageBySlug = createAsyncThunk(
  'spec-guide/getTextPageBySlug',
  async (
    { slug, onResolve, onReject },
    { rejectWithValue },
  ) => {
    try {
      const { data: { data } } = await axios.get(`/page/${slug}`);
      if (onResolve) onResolve(data);
      return data;
    } catch (error) {
      if (onReject) onReject(error.response.data.message);
      return rejectWithValue(error);
    }
  },
);

const postNewTextPage = createAsyncThunk(
  'spec-guide/getTextPageBySlug',
  async (
    { formData, onResolve, onReject },
    { rejectWithValue },
  ) => {
    try {
      const { data: { data } } = await axios.post(`/page`, formData);
      if (onResolve) onResolve(data);
      return data;
    } catch (error) {
      if (onReject) onReject(error.response.data.message);
      return rejectWithValue(error);
    }
  },
);

const updateTextPage = createAsyncThunk(
  'spec-guide/updateTextPage',
  async (
    { formData, slug, onResolve, onReject },
    { rejectWithValue },
  ) => {
    try {
      const { data: { data } } = await axios.post(`/page/${slug}?_method=put`, formData);
      if (onResolve) onResolve(data);
      return data;
    } catch (error) {
      if (onReject) onReject(error.response.data.message);
      return rejectWithValue(error);
    }
  },
);

const deleteTextPage = createAsyncThunk(
  'spec-guide/deleteTextPage',
  async (
    { slug, onResolve, onReject },
    { rejectWithValue },
  ) => {
    try {
      const { data: { data } } = await axios.delete(`/page/${slug}`);
      if (onResolve) onResolve(data);
      return data;
    } catch (error) {
      if (onReject) onReject(error.response.data.message);
      return rejectWithValue(error);
    }
  },
);

const getHeaderMenuTextPages = createAsyncThunk(
  'spec-guide/getHeaderMenuTextPages',
  async (
    { onResolve, onReject },
    { rejectWithValue },
  ) => {
    try {
      const { data: { data } } = await axios.get(`/page/header`);
      if (onResolve) onResolve(data);
      return data;
    } catch (error) {
      if (onReject) onReject(error.response.data.message);
      return rejectWithValue(error);
    }
  },
);

export default {
  getTextPages,
  getTextPageBySlug,
  postNewTextPage,
  updateTextPage,
  deleteTextPage,
  getHeaderMenuTextPages,
};
