export const ListOfEmployeesConf = (t, screenWidth) => {
  return [
    {
      label: t('profile:list_of_employees_page.conf.user'),
      slug: 'user',
      isDisplay: true,
      style: {
        padding: '5px 10px 5px 20px',
      },
    },
    {
      label: t('profile:list_of_employees_page.conf.role'),
      slug: 'role',
      isDisplay: screenWidth > 768,
      style: {
        padding: '5px 10px',
        width: '28%',
      },
    },
    {
      label: t('profile:list_of_employees_page.conf.phone'),
      slug: 'phone',
      isDisplay: screenWidth > 1024,
      style: {
        width: '18%',
        padding: '5px 10px',
      },
    },
    {
      label: t('profile:list_of_employees_page.conf.city'),
      slug: 'city',
      isDisplay: screenWidth > 1024,
      style: {
        width: '10%',
        padding: '5px 10px',
      },
    },
    {
      label: t('profile:list_of_employees_page.conf.status'),
      slug: 'status',
      isDisplay: screenWidth > 768,
      style: {
        padding: '5px 10px',
      },
    },
    // {
    //   label: t('profile:list_of_employees_page.conf.sing_up_by_message'),
    //   slug: 'sign_in_by_message',
    //   icon: '#icon-info',
    //   style: {
    //     marginLeft: 'auto',
    //     whiteSpace: 'nowrap',
    //     padding: '5px 0 5px 10px',
    //   },
    // },
    {
      label: '',
      slug: 'menu',
      isDisplay: true,
      style: {
        paddingLeft: 0,
      },
    },
  ];
};
