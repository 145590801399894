import {useSelector} from "react-redux";
import {useEffect, useState} from "react";

export const useCatalogMobileStylesFlag = () => {
    const windowWith = useSelector(state => state?.windowWithSlice?.windowWith)

    const [mobileCatalogStyles, setMobileCatalogStyles] = useState(false);
    useEffect(() => {
        if(windowWith <= 991) {
            setMobileCatalogStyles(true)
        }
        if(windowWith > 991) {
            setMobileCatalogStyles(false)
        }
    }, [windowWith]);

    return mobileCatalogStyles
}
