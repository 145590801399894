import * as React from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import styled from 'styled-components';

import { BasicAutocompleteSelect, Chip } from '@root/ui';

import { ListSubheader, Typography, useTheme } from '@mui/material';
import { MenuItem } from '@mui/material';

export const AutocompleteCategorySelect = ({
  value,
  options,
  callBack,
  placeholder,
}) => {
  const [subcategories, setSubcategories] = useState([]);

  const { t } = useTranslation(['product_details'], { useSuspense: false });

  useEffect(() => {
    const subcategoriesArray = correctedOptions.filter(item =>
      value.includes(item.id),
    );

    setSubcategories(subcategoriesArray);
  }, [value]);

  const { color } = useTheme();

  const correctedOptions = useMemo(() => {
    let newOptions = [];
    let i = 0;

    for (i = 0; i < options.length; i++) {
      let currentOption = options[i];

      let newCurrentOption = currentOption.children.map(child => ({
        parentCategoryName: currentOption.name,
        ...child,
      }));

      newOptions = [...newOptions, ...newCurrentOption];
    }

    return newOptions;
  }, [options]);

  const onAutocompleteInputChange = (event, newInputValue, reason, type) => {};

  const onAutocompleteChange = (event, newValue, type) => {
    callBack([...newValue].map(item => item?.id));
    setSubcategories(newValue);
  };

  const handleDeleteSubcategory = subcategoryId => {
    const newSubCategories = [...subcategories].filter(
      subcategory => subcategory.id !== subcategoryId,
    );

    callBack([...newSubCategories].map(item => item?.id));
    setSubcategories(newSubCategories);
  };

  const renderTags = () => {};

  const renderGroup = params => {
    return (
      <>
        <ListSubheader
          sx={{
            padding: '8px 10px 6px 10px !important',
            pointerEvents: 'none',
          }}
        >
          <Typography
            variant="bodyBoldMedium"
            component={'p'}
            sx={{
              color: `${color.gray} !important`,
            }}
          >
            {params.group}
          </Typography>
        </ListSubheader>
        <div>{params.children}</div>
      </>
    );
  };

  const renderOption = (props, option) => {
    return (
      <MenuItem
        value={option.name}
        variant="selectedMenu"
        sx={{
          padding: '4px 24px 4px 24px !important',
        }}
        {...props}
      >
        <Typography
          variant="bodyNormalSmall"
          component={'p'}
          sx={{ fontSize: '0.875rem', color: `${color.gray_80} !important` }}
        >
          {option.name}
        </Typography>
      </MenuItem>
    );
  };

  const renderCustomTags = useMemo(() => {
    return (
      <Tags>
        {subcategories
          .filter(
            (item, index, array) =>
              array.findIndex(
                i => i.parentCategoryName === item.parentCategoryName,
              ) === index,
          )
          .map(item => (
            <Chip
              onClick={() => {}}
              title={item.parentCategoryName}
              isHideDelete
              sx={{
                padding: '3px 3px 3px 8px',
              }}
            >
              <SubChipsWrapper>
                {subcategories
                  .filter(i => i.parentCategoryName === item.parentCategoryName)
                  .map(subItem => (
                    <Chip
                      key={subItem.name}
                      onClick={() => handleDeleteSubcategory(subItem.id)}
                      title={subItem.name}
                      sx={{
                        background: color.white,
                        border: 'none',
                        padding: '3px',
                      }}
                      separator=""
                    />
                  ))}
              </SubChipsWrapper>
            </Chip>
          ))}
      </Tags>
    );
  }, [subcategories]);

  return (
    <>
      <BasicAutocompleteSelect
        value={subcategories}
        paginationCallback={() => {}}
        autocompleteInputChange={onAutocompleteInputChange}
        onAutocompleteChange={onAutocompleteChange}
        groupBy={option => option.parentCategoryName}
        renderGroup={renderGroup}
        renderOption={renderOption}
        options={correctedOptions}
        getOptionLabel={option => option?.name}
        onAutocompleteOpen={() => {}}
        renderTags={renderTags}
        multiple
        noOptionsText={t('product_details:no_category_found')}
        placeholder={placeholder}
      />

      {renderCustomTags}
    </>
  );
};

const Tags = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-top: 8px;
`;

const SubChipsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
  margin-left: 8px;
`;
