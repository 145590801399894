import { useSelector } from 'react-redux';

import styled from 'styled-components';

import { Sprite } from '@root/assets/svg/index';
import authSelectors from '@root/redux/auth/auth-selectors';

import { useTheme } from '@mui/material';

const IconCheck = ({ width, height = 16, sx, display = false }) => {
  const { color } = useTheme();
  const userRole = useSelector(authSelectors.getRole);
  const svgDecrease = 0;
  return (
    <>
      {display === true && userRole === 'admin' && (
        <Wrap width={width || height} height={height} color={color}>
          <Svg
            width={(width || height) - svgDecrease}
            height={height - svgDecrease}
            sx={sx}
            color={color}
          >
            <use href={`${Sprite}#icon-double-check`} />
          </Svg>
        </Wrap>
      )}
    </>
  );
};

export default IconCheck;

const Wrap = styled.div`
  display: inline-block;
  vertical-align: text-top;
  position: relative;
  width: ${({ width, height }) => `${width || height}px`};
  height: ${({ height }) => `${height}px`};
  /* background-color: ${({ color }) => color.green}; */
  border-radius: 3px;
  margin-left: 6px;
`;

const Svg = styled.svg`
  ${({ sx }) => sx}
  stroke: ${({ color }) => color.green} !important;

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;
