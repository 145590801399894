import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import { ContactField } from '@root/components';
import { handleDispatchFetch } from '@root/helpers/handleDispatchFetch';
import { getErrorStack } from '@root/helpers/validation';
import adminOperation from '@root/redux/admin/admin-operation';
import { ProgressLoader } from '@root/ui/Loader/ProgressLoader/index';

import adminSelectors from '@redux/admin/admin-selectors';

import { Typography, useTheme } from '@mui/material';

export const BusinessForm = () => {
  const [loading, setLoading] = useState(true);
  const data = useSelector(adminSelectors.getBusinessDetails);
  const {
    id,
    name,
    contractor_code,
    identification_code,
    company_type,
    company_type_id,
    status,
    official_name,
  } = data;
  const companyTypes = useSelector(adminSelectors.getCompanyTypes);
  const [errors, setErrors] = useState({});
  const { t } = useTranslation(['admin'], { useSuspense: false });
  const { color } = useTheme();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      adminOperation.getCompanyTypes({ onResolve: () => setLoading(false) }),
    );
  }, []);

  const handleResetErrors = name => {
    if (name) {
      const copyErrors = JSON.parse(JSON.stringify(errors));
      delete copyErrors[name];
      setErrors(copyErrors);
    } else {
      setErrors({});
    }
  };

  const handleErrors = errors => {
    let currentError = {};
    for (const [key, value] of Object.entries(errors)) {
      currentError = getErrorStack(
        currentError,
        key.replace(/\.\d+/, ''),
        value?.[0],
      );
    }
    setErrors(currentError);
  };

  const handleUpdateBusinessInfo = ({ id, payload, onEditSuccess }) => {
    handleResetErrors();
    handleDispatchFetch(
      ({ onResolve, onReject }) => {
        dispatch(
          adminOperation.updateAdminCounterparty({
            id,
            payload,
            onResolve,
            onReject,
          }),
        );
      },
      () => onEditSuccess(),
      errors => {
        handleErrors(errors.response.data.errors);
      },
    );
  };

  return (
    <Form>
      <Typography variant={'h2'} sx={{ textTransform: 'uppercase' }}>
        {official_name}
      </Typography>
      {!loading ? (
        <Wrapper>
          <ContactField
            type="switch"
            padding={'28px 0'}
            title={t('admin:business_table_conf.status')}
            editValue={status === 'active'}
            errors={errors.status}
            value={
              status === 'active'
                ? t('admin:active_status')
                : t('admin:inactive_status')
            }
            variant={'bodyMedium'}
            onEdit={(payload, onEditSuccess) =>
              handleUpdateBusinessInfo({
                id,
                payload: { status: payload ? 'active' : 'inactive' },
                onEditSuccess,
              })
            }
            id={id}
          />
          <ContactField
            type={'select_async'}
            operation={adminOperation.getCompanysSelect}
            padding={'28px 0'}
            title={t('admin:counterparties_table_conf.business')}
            variant={'bodyMedium'}
            errors={errors.name}
            value={name}
            onEdit={(payload, onEditSuccess) =>
              handleUpdateBusinessInfo({
                id,
                payload: { company_id: payload },
                onEditSuccess,
              })
            }
            id={id}
          />
          <ContactField
            type={'select'}
            padding={'28px 0'}
            title={t('admin:counterparties_table_conf.type')}
            value={company_type_id}
            onEdit={(payload, onEditSuccess) =>
              handleUpdateBusinessInfo({
                id,
                payload: { company_type_id: payload },
                onEditSuccess,
              })
            }
            variant={'bodyMedium'}
            errors={errors.company_type}
            data={companyTypes}
            displayValue={company_type}
            id={id}
          />
          {contractor_code && (
            <ContactField
              padding={'28px 0'}
              title={t('admin:counterparties_table_conf.edrpou')}
              variant={'bodyMedium'}
              value={contractor_code}
              onEdit={(payload, onEditSuccess) =>
                handleUpdateBusinessInfo({
                  id,
                  payload: { contractor_code: payload },
                  onEditSuccess,
                })
              }
              id={id}
              errors={errors.contractor_code}
            />
          )}
          {identification_code && (
            <ContactField
              padding={'28px 0'}
              title={t('admin:counterparties_table_conf.ipn')}
              variant={'bodyMedium'}
              value={identification_code}
              onEdit={(payload, onEditSuccess) =>
                handleUpdateBusinessInfo({
                  id,
                  payload: { identification_code: payload },
                  onEditSuccess,
                })
              }
              id={id}
              errors={errors.identification_code}
            />
          )}
        </Wrapper>
      ) : (
        <ProgressLoader />
      )}
    </Form>
  );
};

const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 425px;
  padding-bottom: 50px;
  position: relative;
`;
const Wrapper = styled.div`
  margin-top: 28px;
`;

BusinessForm.propTypes = {
  data: PropTypes.exact({
    additional_info: PropTypes.string,
    company_type: PropTypes.string,
    company_type_id: PropTypes.number,
    contractor_code: PropTypes.number,
    id: PropTypes.number,
    identification_code: PropTypes.number,
    name: PropTypes.string,
    official_name: PropTypes.string,
    site: PropTypes.string,
    status: PropTypes.string,
  }),
};
