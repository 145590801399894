import { createGlobalStyle } from 'styled-components';
import { normalize } from 'styled-normalize';

export const GlobalStyle = createGlobalStyle`
  ${normalize}
  html {
    font-size: 16px;

    /* @media screen and (min-width: 2048px) {
     font-size: 20px;
    } */

    overflow: overlay;
    scrollbar-width: thin;


  }
  input {
    @media(max-width: 768px) {
      font-size: 1rem !important;
    }
  }


  .mui-fixed {
    padding-right: 0 !important;
  }

  body {
    font-weight: 400;
    margin: 0;
  }

  * {
    box-sizing: border-box;
    font-family: 'Roboto', sans-serif;

    &::before, &::after {
      box-sizing: border-box;
    }

    ::-webkit-scrollbar {
      width: 5px;
    }

    ::-webkit-scrollbar-thumb {
      background: #c5c8d2;
      border-radius: 4px;
    }

    ::-webkit-scrollbar-track {
      background: #fff;
      border-left: 1px solid #dbdee9;
    }
  }

  html, body, #root, .App, main {
    height: 100%;
  }


  html, body, #root, .App {
    min-height: 450px;

  }

  h1, h2, h3, h4, h5, h6 {
    margin: 0;
    padding: 0;
    color: ${({ color }) =>
    color.text_color.main === '#364A63'
      ? ''
      : color.text_color.main} !important;
  }

  p {
    margin: 0;
    padding: 0;
    color: ${({ color }) =>
    color.text_color.secondary === '#364A63CC'
      ? ''
      : color.text_color.secondary} !important;;
  }

  span {
    color: ${({ color }) =>
    color.text_color.secondary === '#364A63CC'
      ? ''
      : color.text_color.secondary} !important;;
  }

  a {
    text-decoration: none;
    text-transform: none;
    color: inherit;
    transition: .3s linear;
    cursor: pointer;
  }

  img, svg {
    display: block;
  }

  button {
    background: none;
    border: none;
    outline: none;
    padding: 0;

    span {
      color: inherit !important;
    }
  }

  input {
    outline: none;
    margin: 0;
    border: 0;
    /* Chrome, Safari, Edge, Opera */

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */

    &[type=number] {
      -moz-appearance: textfield;
    }

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus {
      -webkit-box-shadow: 0 0 0px 1000px #ffffff inset;
      transition: background-color 5000s ease-in-out 0s;
    }
  }

  table, thead {
    display: block !important;
  }

  ul, ol {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  textarea {
    outline: none;
  }


  .textPageCols{
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  margin: 10px 0;
  }
  .textPageCols .item .capt{
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 1.35714285714;
    text-transform: uppercase;
    color: #717171;
    margin-bottom: 5px;
  }
  .textPageCols .item .text{
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 1.25;
    color: #2e2e2e;
    text-decoration: none;
  }

  .info-block, .info-block-warning{
    padding: 24px 40px;
    margin: 10px 0px;

    background: rgba(47, 136, 179, .1);
    padding: 24px 40px;
    border: 1px solid #2f88b3;
    border-radius: 8px;
  }
  .info-block-warning{
    border-color:  rgb(244, 212, 217);
    background: rgb(255, 248, 249);
  }

  .react-grid-item img {
    pointer-events: none;
    user-select: none;
    object-fit: contain;
  }
`;
