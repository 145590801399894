import { useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import { ColorInput, MenuButton } from '@root/ui';

import Picker from './Picker';

export const ColorPicker = ({ colorNum, onChange }) => {
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [colorInInput, setColorInInput] = useState(colorNum);

  const handleClick = e => {
    setOpen(!open);
    setAnchorEl(!open ? e.currentTarget : '');
  };
  useEffect(() => {
    if (colorInInput !== colorNum) setColorInInput(colorNum);
  }, [colorNum]);

  const handelChangeColor = color => {
    if (color?.hex) {
      setColorInInput(color.hex);
    } else {
      setColorInInput(color);
    }
  };

  const handleSubmit = () => {
    setOpen(false);
    onChange(colorInInput);
  };

  return (
    <Wrapper>
      <MenuButton open={open} handleClick={handleClick}>
        <ColorInput colorNum={colorNum} />
      </MenuButton>

      <Picker
        open={open}
        onClose={handleClick}
        anchorEl={anchorEl}
        color={colorInInput}
        onChangeComplete={handelChangeColor}
        handleSubmit={handleSubmit}
      />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
`;

ColorPicker.propTypes = {
  colorNum: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};
