import { useLocation, useParams } from 'react-router-dom';

export const useLang = () => {
  const localLang = localStorage.getItem('i18nextLng');
  const { lang } = useParams();
  const { pathname, search } = useLocation();
  document.documentElement.lang = localLang;

  const switchLang = langCurrent => {
    localStorage.setItem('i18nextLng', langCurrent);
    let path;
    if (lang) {
      path = pathname.split(`/${lang}`)[1];
    } else {
      path = pathname;
    }

    if (langCurrent !== 'uk') {
      window.location.href = `/${langCurrent}${path}${search}`;
    } else {
      window.location.href = `${path}${search}`;
    }
  };

  function checkLanguageFromURL(url) {
    const regex = /\/([a-z]{2})\//;

    const match = url.match(regex);

    if (match && match.length > 1) {
      return match[1];
    } else {
      return 'uk';
    }
  }

  return {
    switchLang,
    localLang,
    checkLanguageFromURL,
  };
};
