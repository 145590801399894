export const SettingsPagesConf = t => [
    {
        title: t('admin:settings_tab.banner'),
        slug: 'banner'
    },
    {
        title: t('admin:settings_tab.activity_area'),
        slug: 'activityareas'
    },
    {
        title: t('admin:settings_tab.agreements'),
        slug: 'agreements'
    },
    {
        title: t('admin:settings_tab.specifications_guide'),
        slug: 'specifications-guide'
    },
    {
        title: t('admin:settings_tab.text_pages'),
        slug: 'text-pages'
    }
]