import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import styled from 'styled-components';

import { CreateFilterModal } from '@root/components/Modal/CreateFilterModal/index';
import categoryOperation from '@root/redux/category/category-operation';
import productOperation from '@root/redux/product/product-operation';
import productSelectors from '@root/redux/product/product-selectors';
import {
  addFilterSettingsItem,
  updateFilterSettingsFilter,
  updateFilterSettingsProp,
} from '@root/redux/product/product-slice';
import userSelectors from '@root/redux/user/user-selectors';
import { ButtonBase } from '@root/ui/Button/ButtonBase/index';
import { OutlineButtonBase } from '@root/ui/Button/OutlineButton/index';
import { BasicSelect } from '@root/ui/Input/Select/index';

import { Typography, useTheme } from '@mui/material';
import { Sprite } from '@root/assets/svg/index';

const ProductFiltersSettings = ({ isEdit, item }) => {
  const { color } = useTheme();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const getPermissions = useSelector(userSelectors.getPermissions);
  const [show, setShow] = useState(false);
  const getSettingsFilterCurrent = useSelector(
    productSelectors.getSettingsFilterCurrent,
  );
  const getSettingsFilterListArray = useSelector(
    productSelectors.getSettingsFilterListArray,
  );

  useEffect(() => {
    dispatch(productOperation.getProductFilters({ id: item.id }));
  }, []);

  const onAddFilter = () => {
    dispatch(addFilterSettingsItem({}));
  };

  const onFilterSelect = index => v => {
    dispatch(updateFilterSettingsFilter({ index, value: v }));
  };

  const onPropSelect = index => v => {
    dispatch(updateFilterSettingsProp({ index, value: v }));
  };

  return (
    <>
      <ComponentWrap id="markdown_products">
        <HeadWrap>
          <Typography
            variant={'h2'}
            sx={{
              color: color.gray,
            }}
          >
            {t('product_details:product_filters.title')}
          </Typography>
          {getPermissions['product_card_add_filters_access'] && (
            <OutlineButtonBase
              onClick={() => {
                dispatch(categoryOperation.getCategoryListToAdmin()).then(
                  () => {
                    setShow(true);
                  },
                );
              }}
              sx={{
                padding: '10px 8px',
              }}
            >
              <svg width={24} height={24}>
                <use href={`${Sprite}#icon-plus`} />
              </svg>
              {t('product_details:product_filters.create_filter')}
            </OutlineButtonBase>
          )}
        </HeadWrap>

        {getSettingsFilterCurrent?.map((filterEl, index) => (
          <FilterItem>
            <BasicSelect
              data={getSettingsFilterListArray}
              initValue={filterEl.filterValue}
              callBack={onFilterSelect(index)}
              name={t('product_details:product_filters.selectBlock')}
            />
            <BasicSelect
              data={filterEl.propsArray}
              initValue={filterEl.propsArrayValue}
              callBack={onPropSelect(index)}
              name={t('product_details:product_filters.filterValue')}
            />
          </FilterItem>
        ))}
        <ButtonBase onClick={onAddFilter} sx={{ mt: '16px' }}>
          {t('product_details:product_filters.addFilter')}
        </ButtonBase>
      </ComponentWrap>
      <CreateFilterModal
        open={show}
        handleClose={() => {
          setShow(false);
          dispatch(
            productOperation.getProductFilters({
              id: item.id,
              onlyUpdate: true,
            }),
          );
        }}
      />
    </>
  );
};

export default ProductFiltersSettings;

const ComponentWrap = styled.div`
  padding: 25px 103px;
  background-color: #fff;
  margin-left: -20px;
  margin-right: -20px;
`;

const HeadWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const FilterItem = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
  margin-top: 14px;
`;
