import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import styled from 'styled-components';

import {
  ChangePasswordModal,
  ValidateTwoFactorAuthenticationModal,
} from '@root/components';
import { handleDispatchFetch } from '@root/helpers';
import { useNavigation } from '@root/hooks';
import { ButtonBase, CustomizedSwitches } from '@root/ui';

import { setOpenNotification } from '@redux/notification-watcher/notification-watcher-slice';
import userOperation from '@redux/user/user-operation';
import userSelectors from '@redux/user/user-selectors';

import { Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

export const Security = () => {
  const [isActivityLogsChecked, setIsActivityLogsChecked] = useState(true);
  const [isAuthenticationChecked, setIsAuthenticationChecked] = useState(false);
  const [
    isValidateAuthenticationModalOpen,
    setIsValidateAuthenticationModalOpen,
  ] = useState(false);
  const [isChangePasswordModalOpen, setIsChangePasswordModalOpen] =
    useState(false);
  const [error, setError] = useState({});
  const { color } = useTheme();
  const { navigation } = useNavigation();
  const { t } = useTranslation(['profile'], { useSuspense: false });

  document.title = t('profile:conf.security.title');

  const dispatch = useDispatch();

  const userChangePasswordAt = useSelector(
    userSelectors.getUserChangePasswordAt,
  );
  const permission = useSelector(userSelectors.getPermissions);
  const isSaveLoginsActive = useSelector(userSelectors.getIsSaveLoginsActive);
  const isTwoFactorAuthenticationActive = useSelector(
    userSelectors.getIsTwoFactorAuthenticationActive,
  );

  useEffect(() => {
    if (!permission.profile_security_access) {
      navigation('/error-403');
    }
  }, []);

  useEffect(() => {
    setIsActivityLogsChecked(isSaveLoginsActive);
  }, [isSaveLoginsActive]);

  useEffect(() => {
    setIsAuthenticationChecked(isTwoFactorAuthenticationActive);
  }, [isTwoFactorAuthenticationActive]);

  const handleOpenNotification = () => {
    dispatch(
      setOpenNotification({
        open: true,
        message: t(
          'profile:security_page.modals.change_password_modal.change_password_successfully_notifications',
        ),
      }),
    );
  };

  const handleSetIsAuthenticationChecked = () => {
    setIsAuthenticationChecked(isTwoFactorAuthenticationActive);
  };

  const handleCloseActivateAuthenticationModal = () => {
    setIsValidateAuthenticationModalOpen(false);
  };
  const handleOpenCheckVerificationModal = () => {
    setIsValidateAuthenticationModalOpen(true);
  };

  const handleCloseChangePasswordModal = () => {
    setIsChangePasswordModalOpen(false);
  };
  const handleOpenChangePasswordModal = () => {
    setIsChangePasswordModalOpen(true);
  };

  const handleToggleActivityLogs = checked => {
    handleDispatchFetch(
      ({ onResolve, onReject }) =>
        dispatch(
          userOperation.updateSecuritySettings({
            onResolve,
            onReject,
            show_logins: checked ? 1 : 0,
          }),
        ),
      () => {
        handleCloseActivateAuthenticationModal();
      },
      message => {
        setError({
          secret: { init: true, text: message },
        });
      },
    );
    setIsActivityLogsChecked(checked);
  };

  const handleToggleAuthentication = checked => {
    setIsAuthenticationChecked(checked);
    handleOpenCheckVerificationModal();
  };

  return (
    <>
      {isValidateAuthenticationModalOpen && (
        <ValidateTwoFactorAuthenticationModal
          open={isValidateAuthenticationModalOpen}
          handleCloseModal={() => {
            handleCloseActivateAuthenticationModal();
            handleSetIsAuthenticationChecked();
          }}
        />
      )}

      {isChangePasswordModalOpen && (
        <ChangePasswordModal
          open={isChangePasswordModalOpen}
          handleCloseModal={handleCloseChangePasswordModal}
          handleOpenNotification={handleOpenNotification}
        />
      )}

      <SecurityWrapper>
        {permission.profile_security_save_access && (
          <SecurityItem color={color.line}>
            <SecurityItemInfo maxWidth="530px">
              <Typography
                color={color.gray}
                variant="bodyBoldMedium"
                component="p"
              >
                {t('profile:security_page.save_my_activity.title')}
              </Typography>
              <Typography
                color={color.gray_60}
                variant="bodyMedium"
                component="p"
                marginTop="4px"
                maxWidth="529px"
              >
                {t('profile:security_page.save_my_activity.description')}
              </Typography>
            </SecurityItemInfo>

            <CustomizedSwitches
              checked={isActivityLogsChecked}
              onChange={handleToggleActivityLogs}
              sx={{ marginLeft: '0px' }}
            />
          </SecurityItem>
        )}

        <SecurityItem color={color.line}>
          <SecurityItemInfo maxWidth="530px">
            <Typography
              color={color.gray}
              variant="bodyBoldMedium"
              component="p"
              marginTop="4px"
            >
              {t('profile:security_page.change_password.title')}
            </Typography>
            <Typography
              color={color.gray_60}
              variant="bodyMedium"
              component="p"
              marginTop="4px"
            >
              {t('profile:security_page.change_password.description')}
            </Typography>
          </SecurityItemInfo>
          {userChangePasswordAt && (
            <LastChangesWrapper>
              <Typography
                color={color.gray_60}
                variant="bodySmall"
                component="p"
              >
                {t('profile:security_page.last_changed')}:
              </Typography>
              <Typography
                color={color.gray_60}
                variant="bodySmall"
                component="p"
              >
                {userChangePasswordAt}
              </Typography>
            </LastChangesWrapper>
          )}

          <ButtonBase onClick={handleOpenChangePasswordModal}>
            {t('common:buttons.change_password')}
          </ButtonBase>
        </SecurityItem>
        <SecurityItem color={color.line}>
          <SecurityItemInfo maxWidth="530px">
            <Typography
              color={color.gray}
              variant="bodyBoldMedium"
              component="p"
              marginTop="4px"
            >
              {t('profile:security_page.two_factor_authentication.title')}
            </Typography>
            <Typography
              color={color.gray_60}
              variant="bodyMedium"
              component="p"
              maxWidth="593px"
              marginTop="4px"
            >
              {t('profile:security_page.two_factor_authentication.description')}
            </Typography>
          </SecurityItemInfo>
          <CustomizedSwitches
            checked={isAuthenticationChecked}
            onChange={handleToggleAuthentication}
            sx={{ marginLeft: '0px' }}
          />
        </SecurityItem>
      </SecurityWrapper>
    </>
  );
};

const SecurityWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;

  @media (max-width: 768px) {
    gap: 16px;
  }
`;

const SecurityItem = styled.div`
  display: flex;
  align-items: center;
  padding: 32px;
  border: 1px solid ${props => props.color};
  border-radius: 4px;

  @media (max-width: 768px) {
    flex-wrap: wrap;
    gap: 8px;
    padding: 16px;
  }
`;

const SecurityItemInfo = styled.div`
  flex: 1 1 auto;
`;

const LastChangesWrapper = styled.div`
  margin-right: 12px;
`;
