import * as React from 'react';

import Radio from '@mui/material/Radio';
import { styled } from '@mui/material/styles';

const BpIcon = styled('span')(({ theme }) => ({
  borderRadius: '50%',
  width: 16,
  height: 16,
  boxShadow:
    'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
  backgroundColor: theme.color.white,
  backgroundImage:
    'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
  '.Mui-focusVisible &': {
    outline: '2px auto rgba(19,124,189,.6)',
    outlineOffset: 2,
  },
  'input:hover ~ &': {
    backgroundColor: theme.color.line,
  },

  'input:active ~ &': {
    border: `1px solid ${theme.color.main}`,

    position: 'relative',
    '&::after': {
      position: 'absolute',
      top: '50%',
      left: '50%',
      content: '""',
      width: '6px',
      height: '6px',
      background: theme.color.main,
      borderRadius: '40px',
      transform: 'translate(-50%, -50%)',
    },
  },

  'input:disabled ~ &': {
    boxShadow: 'none',
    background: 'rgba(206,217,224,.5)',
  },
}));

const BpCheckedIcon = styled(BpIcon)(({ theme }) => ({
  border: `1px solid ${theme.color.main}`,
  width: 16,
  height: 16,
  backgroundColor: theme.color.white,
  position: 'relative',
  '&:before': {
    display: 'block',
    position: 'absolute',
    top: '50%',
    left: '50%',
    content: '""',
    width: '10px',
    height: '10px',
    background: theme.color.main,
    borderRadius: '40px',
    transform: 'translate(-50%, -50%)',
  },
}));

// Inspired by blueprintjs
export const RadioCustom = props => {
  return (
    <Radio
      disableRipple
      color="default"
      checkedIcon={<BpCheckedIcon />}
      icon={<BpIcon />}
      {...props}
    />
  );
};
