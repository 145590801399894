import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import { theme } from '@root/styles';

import { Typography } from '@mui/material';

export const EllipsisText = ({ text, length, color, variant }) => {
  const [toggleHide, setToggleHide] = useState(false);

  const [textEllipsis, setTextEllipsis] = useState('');

  useEffect(() => {
    setTextEllipsis(text && text.length > 500 ? text.slice(0, length) : text);
  }, [text]);

  const { t } = useTranslation(['common'], { useSuspense: false });

  return (
    <>
      <Typography
        variant={variant || 'bodyMedium'}
        sx={{
          color: color || theme.color.gray_80,
        }}
      >
        <TextContainer
          dangerouslySetInnerHTML={{ __html: toggleHide ? text : textEllipsis }}
        ></TextContainer>
        {!toggleHide && text.length > length && <span>...</span>}
      </Typography>

      {text.length > 500 && (
        <Typography
          variant={variant || 'bodyMedium'}
          onClick={() => setToggleHide(!toggleHide)}
          sx={{
            color: theme.color.main,
            textDecoration: 'underline',
            cursor: 'pointer',
            display: 'block',
          }}
        >
          {toggleHide ? t('common:buttons.hide') : t('common:buttons.read_all')}
        </Typography>
      )}
    </>
  );
};

const TextContainer = styled.div`
  display: inline;
  * {
    display: inline;
  }
`;

EllipsisText.propTypes = {
  text: PropTypes.string.isRequired,
  length: PropTypes.number.isRequired,
};
