import { useSelector } from 'react-redux';
import { Navigate, useParams } from 'react-router-dom';

import PropTypes from 'prop-types';

import authSelectors from '@redux/auth/auth-selectors';

export function PublicRoute({ element }) {
  const token = useSelector(authSelectors.getUserToken);
  const { lang } = useParams();
  return <>{token ? <Navigate to={lang ? `/${lang}/` : '/'} /> : element}</>;
}

PublicRoute.propTypes = {
  element: PropTypes.node,
};
