const getSearchData = state => state.search.searchData;
const getParamSearch = state => state.search.paramSearch;
const getTotalSearch = state => state.search.totalSearch;
const getSearchMeta = state => state.search.searchMeta;
const getSearchPageData = state => state.search.searchPageData;
const getLoading = state => state.search.loading;

const getSearchBySerial = state => state.search.serialSearch;
const getSearchBySerialLoading = state => state.search.serialSearchLoading;
const getSerialSearchError = state => state.search.serialSearchError;

const searchSelectors = {
  getSearchData,
  getSearchPageData,
  getParamSearch,
  getTotalSearch,
  getSearchMeta,
  getLoading,

  getSearchBySerial,
  getSearchBySerialLoading,
  getSerialSearchError,
};
export default searchSelectors;
