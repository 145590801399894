import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import styled from 'styled-components';

import { HeaderDefault } from '@root/components/Modal/ModalHeader/Default/index';
import bannersSelectors from '@root/redux/banners/banners-selectors';
import { ButtonBase } from '@root/ui/Button/ButtonBase/index';
import { OutlineButtonBase } from '@root/ui/Button/OutlineButton/index';
import { ModalBase } from '@root/ui/Modal/index';

import { useTheme } from '@mui/material';

const BannerDeleteDialog = ({ open, onClose, onSubmit, id }) => {
  const { color } = useTheme();
  const banners = useSelector(bannersSelectors.getBanners);
  const { t } = useTranslation(['settings']);
  return (
    <ModalBase
      open={open}
      modalHeader={
        <HeaderDefault onClose={onClose} text={t('banner.delete_banner')} />
      }
    >
      <ImgWrap color={color}>
        <img src={banners?.[id]?.image} alt="" />
      </ImgWrap>
      <BtnsWrap>
        <OutlineButtonBase onClick={onClose}>
          {t('banner.cancel')}
        </OutlineButtonBase>
        <ButtonBase onClick={onSubmit}>{t('banner.delete')}</ButtonBase>
      </BtnsWrap>
    </ModalBase>
  );
};

export default BannerDeleteDialog;

const ImgWrap = styled.div`
  display: flex;

  img {
    width: 100px;
    height: 94px;
    background-color: #fff;
    border: 1px solid ${({ color }) => color.line};
    border-radius: 4px;
  }
`;

const BtnsWrap = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 15px;
  width: 400px;
  button {
    margin-left: 12px;
  }
`;
