import InputMask from 'react-input-mask';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import { RequiredStar } from '@root/assets/svg';
import { MessageError } from '@root/ui';

import { Typography, useTheme } from '@mui/material';

import { OutlinedInputCustom } from '../style/OutlinedInput';

export const PhoneInput = ({
  value = '',
  onChange,
  sx,
  placeholder,
  error = false,
  errorText,
  setPhoneModal,
  label,
  styleWrapper,
  required = false,
  autoCompleteType,
  labelRowDirection = false,
  labelRowGap = '',
  labelTextVariant = 'bodySmall',
  errorPositionLeft = '170px',
  ...prop
}) => {
  const { color } = useTheme();
  const handelChange = e => {
    const value = e.target.value;
    if (value === '' || !value) return;
    let regexp = /\d/g;
    const valueEx = `${value}`.match(regexp).join('');
    if (setPhoneModal) setPhoneModal(value);
    onChange(valueEx);
  };

  const handelBlur = e => {
    if (value.length === 3) {
      if (setPhoneModal) setPhoneModal('');
      onChange(null);
    }
  };

  return (
    <FlexContainer
      style={{
        ...styleWrapper,
        flexDirection: labelRowDirection ? 'row' : 'column',
        gap: labelRowGap ? labelRowGap : 'initial',
        position: labelRowDirection ? 'relative' : 'initial',
        marginBottom: error && errorText && labelRowDirection ? '20px' : '',
      }}
    >
      {label && (
        <LabelWrapper isRowDirection={labelRowDirection}>
          {required && (
            <RequiredStarImg
              isRowDirection={labelRowDirection}
              src={RequiredStar}
              alt="Required Star"
            />
          )}
          <Typography
            variant={labelTextVariant}
            sx={{ color: color.gray, ml: '2px' }}
            component={'span'}
          >
            {label}
          </Typography>
        </LabelWrapper>
      )}
      <InputMask
        mask="+38(099)999 99 99"
        value={value}
        onChange={e => handelChange(e)}
        onBlur={handelBlur}
        {...prop}
      >
        {inputProps => (
          <OutlinedInputCustom
            {...inputProps}
            placeholder={placeholder}
            error={error}
            type="tel"
            autoComplete={autoCompleteType}
            sx={{
              width: labelRowDirection ? '100%' : 'initial',
              ...sx,
            }}
          />
        )}
      </InputMask>
      {error && errorText && (
        <MessageError
          sx={{
            position: labelRowDirection ? 'absolute' : 'initial',
            top: labelRowDirection ? '100%' : '',
            left: labelRowDirection ? errorPositionLeft : '',
          }}
          errorText={errorText}
        />
      )}
    </FlexContainer>
  );
};

const FlexContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const LabelWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  min-width: ${({ isRowDirection }) => (isRowDirection ? '150px' : '')};

  span {
    ${({ isRowDirection }) =>
      isRowDirection
        ? {
            alignSelf: 'center',
            whiteSpace: 'nowrap',
          }
        : {}}
  }
`;

const RequiredStarImg = styled.img`
  margin-top: 3px;
  ${({ isRowDirection }) =>
    isRowDirection
      ? {
          marginTop: 0,
          marginBottom: '3px',
          alignSelf: 'center',
        }
      : {}}
`;

PhoneInput.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  sx: PropTypes.object,
  placeholder: PropTypes.string,
  error: PropTypes.bool,
  errorText: PropTypes.string,
  setPhoneModal: PropTypes.func.isRequired,
  label: PropTypes.string,
  styleWrapper: PropTypes.object,
  required: PropTypes.bool,
};
