import PropTypes from 'prop-types';

import { useTheme } from '@mui/material';
import Avatar from '@mui/material/Avatar';

export const AvatarUI = ({ src, width = 60, height = 60, children, sx }) => {
  const { color } = useTheme();
  return (
    <Avatar
      alt="Avatar"
      src={src}
      sx={{
        width: width,
        height: height,
        bgcolor: src && src.length > 0 ? '' : color.line_light,
        img: {
          objectFit: 'contain',
        },
        ...sx,
      }}
    >
      {children}
    </Avatar>
  );
};

AvatarUI.propTypes = {
  src: PropTypes.string.isRequired,
  width: PropTypes.number,
  height: PropTypes.number,
  children: PropTypes.node,
  sx: PropTypes.object,
};
