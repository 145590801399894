import { useLoadScript } from '@react-google-maps/api';
import { useEffect, useRef, useState } from 'react';

const libraries = ['places'];
const googleMapsApiKey = process.env.REACT_APP_GOOGLE_KEY;

export const GoogleCityInput = ({ children, value, onFound }) => {
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey,
    libraries,
    language: 'uk',
  });

  const autocompleteService = useRef(null);

  useEffect(() => {
    if (isLoaded && !autocompleteService.current) {
      autocompleteService.current =
        new window.google.maps.places.AutocompleteService();
    }
  }, [isLoaded]);

  useEffect(() => {
    if (value && autocompleteService.current) {
      autocompleteService.current.getPlacePredictions(
        {
          input: value,
          types: ['(cities)'],
          componentRestrictions: { country: 'ua' },
        },
        (predictions, status) => {
          if (status === window.google.maps.places.PlacesServiceStatus.OK) {
            onFound(
              predictions.map((item, index) => {
                return { id: index, name: item.description };
              }),
            );
          } else {
            onFound([]);
          }
        },
      );
    } else {
      onFound([]);
    }
  }, [value]);

  if (loadError) return <div>Google component load error</div>;
  if (!isLoaded) return <div>Loading...</div>;

  return <>{children}</>;
};
