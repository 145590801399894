import { useTranslation } from 'react-i18next';

import styled from 'styled-components';

import { Sprite } from '@root/assets/svg/index';

import { Typography, useTheme } from '@mui/material';

const BlockedUserProductCardMessage = () => {
  const { color } = useTheme();
  const { t } = useTranslation(['mutual_settlements', 'common']);

  return (
    <>
      <MsgBlock>
        <Svg width={14} height={14} color={color}>
          <use href={`${Sprite}#icon-alert`} />
        </Svg>
        <Typography variant="bodyExSmall" color={color.red}>
          {t('common:blocked_user_messages.product_card_msg')}{' '}
          <a href="/mutual-settlements">
            <Typography variant="bodyBoldExSmall">
              {t('mutual_settlements:title')}
            </Typography>
          </a>
          .
        </Typography>
      </MsgBlock>
    </>
  );
};

export default BlockedUserProductCardMessage;

const MsgBlock = styled.div`
  display: flex;
`;
const Svg = styled.svg`
  flex: 14px 0 0;
  margin-right: 6px;
  stroke: ${({ color }) => color.red} !important;
`;
