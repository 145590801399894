import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import { getAccountOptionList, getPayerTypeList } from '@root/conf';
import { CheckboxBase, RadioCustom, TextInput } from '@root/ui';

import userSelectors from '@redux/user/user-selectors';

import {
  FormControlLabel,
  RadioGroup,
  Typography,
  useTheme,
} from '@mui/material';

export const BillFrom = ({ onChangeRadio, onChange, payment_info, error }) => {
  const { color } = useTheme();
  const { t } = useTranslation(['placing_order'], { useSuspense: false });
  const currencyRates = useSelector(userSelectors.getCurrencyRates)[0];

  const {
    account_option,
    send_invoice,
    payer_type,
    email,
    name_organization,
    contractor_code,
    identification_code,
    name_fop_organization,
    full_name,
  } = payment_info;

  const getPayerType = key => {
    switch (key) {
      case 'legal_entity':
        return (
          <>
            <TextInput
              error={error?.name_organization?.init}
              errorText={error?.name_organization?.text}
              sx={{ width: '100%', height: '36px' }}
              value={name_organization}
              onChange={onChange('name_organization', 'payment_info')}
              placeholder={t('placing_order:fields.name_organization')}
              type="text"
              label={t('placing_order:fields.name_organization')}
              required={true}
              styleWrapper={{ marginTop: '12px' }}
              inputProps={{
                maxLength: 100,
              }}
            />
            <TextInput
              error={error?.contractor_code?.init}
              errorText={error?.contractor_code?.text}
              sx={{ width: '100%', height: '36px' }}
              value={contractor_code}
              onChange={onChange('contractor_code', 'payment_info')}
              placeholder={t('placing_order:fields.code_organization')}
              type="text"
              withOutSpace={true}
              label={t('placing_order:fields.code_organization')}
              required={true}
              styleWrapper={{ marginTop: '12px' }}
              inputProps={{
                maxLength: 8,
              }}
            />
          </>
        );

      case 'individual':
        return (
          <>
            <TextInput
              error={error?.full_name?.init}
              errorText={error?.full_name?.text}
              sx={{ width: '100%', height: '36px' }}
              value={full_name}
              onChange={onChange('full_name', 'payment_info')}
              placeholder={t('placing_order:fields.full_name')}
              type="text"
              label={t('placing_order:fields.full_name')}
              required={true}
              styleWrapper={{ marginTop: '12px' }}
              inputProps={{
                maxLength: 100,
              }}
            />
          </>
        );
      case 'individual_entrepreneur':
        return (
          <>
            <TextInput
              error={error?.name_fop_organization?.init}
              errorText={error?.name_fop_organization?.text}
              sx={{ width: '100%', height: '36px' }}
              value={name_fop_organization}
              onChange={onChange('name_fop_organization', 'payment_info')}
              placeholder={t('placing_order:fields.name_fop_organization')}
              type="text"
              label={t('placing_order:fields.name_fop_organization')}
              required={true}
              styleWrapper={{ marginTop: '12px' }}
              inputProps={{
                maxLength: 100,
              }}
            />
            <TextInput
              error={error?.identification_code?.init}
              errorText={error?.identification_code?.text}
              sx={{ width: '100%', height: '36px' }}
              value={identification_code}
              onChange={onChange('identification_code', 'payment_info')}
              placeholder={t('placing_order:fields.ipn')}
              type="text"
              withOutSpace={true}
              label={t('placing_order:fields.ipn')}
              required={true}
              styleWrapper={{ marginTop: '12px' }}
              inputProps={{
                maxLength: 10,
              }}
            />
          </>
        );

      default:
        break;
    }
  };

  return (
    <>
      <Box gap={25}>
        {' '}
        <Typography
          variant={'bodyMedium'}
          sx={{ color: color.gray_80 }}
          component={'p'}
        >
          {t('placing_order:title.select_required_account_option')}
        </Typography>
        <RadioGroup
          aria-labelledby="demo-radio-buttons-group-label"
          name="radio-buttons-group"
          value={account_option}
          onChange={onChangeRadio('account_option', 'payment_info')}
        >
          {getAccountOptionList(t, currencyRates).map(el => (
            <FormControlLabel
              control={<RadioCustom />}
              sx={{
                marginLeft: '-9px',
              }}
              label={
                <Typography
                  variant={'bodyBoldMedium'}
                  component={'span'}
                  sx={{
                    fontWeight: 400,
                    color: color.gray_80,
                  }}
                >
                  {el.label}
                </Typography>
              }
              value={el.value}
            />
          ))}
        </RadioGroup>
      </Box>

      <Box gap={25}>
        {' '}
        <Typography
          variant={'bodyMedium'}
          sx={{ color: color.gray_80 }}
          component={'p'}
        >
          {t('placing_order:title.payer_type')}
        </Typography>
        <RadioGroup
          aria-labelledby="demo-radio-buttons-group-label"
          name="radio-buttons-group"
          value={payer_type}
          onChange={onChangeRadio('payer_type', 'payment_info')}
        >
          {getPayerTypeList(t).map(el => (
            <>
              <FormControlLabel
                control={<RadioCustom />}
                sx={{
                  marginLeft: '-9px',
                }}
                label={
                  <Typography
                    variant={'bodyBoldMedium'}
                    component={'span'}
                    sx={{
                      fontWeight: 400,
                      color: color.gray_80,
                    }}
                  >
                    {el.label}
                  </Typography>
                }
                value={el.value}
              />

              {payer_type === el.value && getPayerType(payer_type)}
            </>
          ))}
        </RadioGroup>
      </Box>

      <Box gap={10}>
        <CheckboxBase
          width={16}
          onChange={onChange('send_invoice', 'payment_info')}
          checked={send_invoice}
        >
          <Typography
            sx={{ ml: '10px', color: color.gray }}
            variant={'bodyMedium'}
            component={'p'}
          >
            {t('placing_order:fields.send_invoice_to_email')}
          </Typography>
        </CheckboxBase>
        {send_invoice && (
          <TextInput
            error={error?.email?.init}
            errorText={error?.email?.text}
            sx={{ width: '100%', height: '36px' }}
            value={email}
            onChange={onChange('email', 'payment_info')}
            placeholder={'Email*'}
            type="text"
            withOutSpace={true}
            data-test-id="Email"
            autoComplete={'email'}
            label={t('placing_order:fields.email_for_bill')}
            required={true}
            styleWrapper={{ marginTop: '12px' }}
            inputProps={{
              maxLength: 255,
            }}
          />
        )}
      </Box>
    </>
  );
};

const Box = styled.div`
  margin-bottom: ${({ gap }) => gap}px;
  max-width: 300px;
  width: 100%;
`;

BillFrom.propTypes = {
  onChangeRadio: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  payment_info: PropTypes.exact({
    account_option: PropTypes.string,
    contractor_code: PropTypes.string,
    email: PropTypes.string,
    identification_code: PropTypes.string,
    name_fop_organization: PropTypes.string,
    name_organization: PropTypes.string,
    payer_type: PropTypes.string,
    send_invoice: PropTypes.bool,
  }),
  error: PropTypes.object,
};
