import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { serialize } from 'object-to-formdata';

const updateHomeConfig = createAsyncThunk(
  'home/updateHomeConfig',
  async ({ onResolve, onReject, conf }, { rejectWithValue }) => {
    try {
      const { data } = await axios.post('dashboard', { conf });

      if (onResolve) onResolve(data.data);
      return data.data;
    } catch (error) {
      if (onReject) onReject(error);
      return rejectWithValue(error);
    }
  },
);

const getHomeBanner = createAsyncThunk(
  'home/getHomeBanner',
  async (homeConfig, { rejectWithValue }) => {
    try {
      const { data } = await axios.get('dashboard/banner');

      return data.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

const updateHomeBanner = createAsyncThunk(
  'home/updateHomeBanner',
  async ({ onResolve, onReject, formData }, { rejectWithValue }) => {
    try {
      const { data } = await axios.post('dashboard/admin/banner', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      if (onResolve) onResolve(data.data);
      return data.data;
    } catch (error) {
      if (onReject) onReject(error);
      return rejectWithValue(error);
    }
  },
);

const deleteHomeBanner = createAsyncThunk(
  'home/deleteHomeBanner',
  async ({ id }, { rejectWithValue }) => {
    try {
      const { data } = await axios.delete(`dashboard/admin/banner/${id}`);

      return data.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

const getHomeTopSale = createAsyncThunk(
  'home/getHomeTopSalle',
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await axios.get('dashboard/producthits');
      return data.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

const createHomeTopSale = createAsyncThunk(
  'home/createHomeTopSalle',
  async ({ productList }, { rejectWithValue }) => {
    try {
      const { data } = await axios.post('dashboard/admin/producthits', {
        productList,
      });
      return data.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

const getHomeOrderChart = createAsyncThunk(
  'home/getHomeOrderChart',
  async (productList, { rejectWithValue }) => {
    try {
      const { data } = await axios.get('dashboard/orders');
      return data.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

const getHomeCommercialOffersChart = createAsyncThunk(
  'home/getHomeCommercialOffersChart',
  async (productList, { rejectWithValue }) => {
    try {
      const { data } = await axios.get('dashboard/comercial_orders');
      return data.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

const getHomeBuysMostOften = createAsyncThunk(
  'home/getHomeBuysMostOften',
  async (productList, { rejectWithValue }) => {
    try {
      const { data } = await axios.get('dashboard/productoften');
      return data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  },
);

const getHomeOrders = createAsyncThunk(
  'home/getHomeOrders',
  async ({ page, activeTab }, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(`dashboard/orderslist/${activeTab}`, {
        params: {
          page: page,
        },
      });
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

const getLastMonthsOrders = createAsyncThunk(
  'home/getLastMonthsOrders',
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await axios.get('dashboard/products-month');
      return data.data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  },
);

const getOneC = createAsyncThunk(
  'user/getOneC',
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await axios.get('dashboard/cache1c');
      return data.data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  },
);

export default {
  updateHomeConfig,
  getHomeBanner,
  updateHomeBanner,
  getHomeTopSale,
  createHomeTopSale,
  getHomeOrderChart,
  getHomeCommercialOffersChart,
  getHomeBuysMostOften,
  getHomeOrders,
  deleteHomeBanner,
  getLastMonthsOrders,
  getOneC,
};
