import React, { forwardRef, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import styled from 'styled-components';

import { Blank } from '@root/assets/image';
import { WriteDocumentModal } from '@root/components';
import { formatDate } from '@root/helpers';
import { ButtonBase, Link as LinkDecorator, ProgressLoader } from '@root/ui';

import authSelectors from '@redux/auth/auth-selectors';

import { Typography, useTheme } from '@mui/material';

export const AccountingDocumentsDetails = forwardRef(
  ({ fileUrl, number, date, t }, ref) => {
    const [openModal, setOpenModal] = useState(false);
    const [isBlobLoading, setIsBlobLoading] = useState(false);
    const [fileBlob, setFileBlob] = useState(null);
    const { color } = useTheme();
    const token = useSelector(authSelectors.getUserToken);

    useEffect(() => {
      if (fileUrl) {
        setIsBlobLoading(true);
        downloadPdfFile();
      }
    }, [fileUrl]);
    const downloadPdfFile = () => {
      const accessToken = 'Bearer ' + token;
      const xhr = new XMLHttpRequest();
      xhr.responseType = 'blob';

      xhr.open('GET', `${fileUrl}`);
      xhr.setRequestHeader(
        'Authorization',
        accessToken,
        'Access-Control-Max-Age: 7200',
      );

      xhr.onload = () => {
        setIsBlobLoading(false);
        setFileBlob(xhr.response);
      };

      xhr.send();
    };

    const linkUrl = useMemo(() => {
      return fileBlob ? URL.createObjectURL(fileBlob) : null;
    }, [fileBlob]);

    return (
      <Wrapper color={color} ref={ref}>
        {fileUrl ? (
          <AccountingDocumentsDetailsWrapper>
            {isBlobLoading ? (
              <ProgressLoader />
            ) : (
              <>
                <AccountingDocumentsDetailsHeader>
                  <Typography
                    sx={{
                      flexGrow: 1,
                    }}
                    color={color.gray_60}
                    variant="bodySmall"
                  >
                    {t('accounting_documents:bill_to_pay')} № {number}{' '}
                    {t('accounting_documents:from')} {formatDate(date)}
                  </Typography>
                  <ButtonBase
                    sx={{
                      height: '38px',
                    }}
                    onClick={() => setOpenModal(true)}
                  >
                    <Typography variant={'bodyBoldSmall'} color={color.white}>
                      {t('accounting_documents:sign_documents_btn')}
                    </Typography>
                  </ButtonBase>
                  <Link href={linkUrl} target="_blank" download>
                    <LinkDecorator>
                      <Typography variant={'bodyBoldSmall'}>
                        {t('accounting_documents:download_btn')}
                      </Typography>
                    </LinkDecorator>
                  </Link>
                </AccountingDocumentsDetailsHeader>
                <AccountingDocumentsDetailsMain color={color}>
                  <iframe src={linkUrl} height="100%" width="100%"></iframe>
                </AccountingDocumentsDetailsMain>
              </>
            )}
            {openModal && (
              <WriteDocumentModal
                open={openModal}
                handleClose={() => setOpenModal(false)}
              />
            )}
          </AccountingDocumentsDetailsWrapper>
        ) : (
          <EmptyBox bg={color} color={color}>
            <img src={Blank} alt="Blank" />
            <Typography
              variant={'bodySmall'}
              component={'p'}
              sx={{
                display: 'flex',
                alignItems: 'center',
                mt: '40px',
                color: color.gray_80,
                textAlign: 'center'
              }}
            >
              {t('accounting_documents:select_document_see_details')}
            </Typography>
          </EmptyBox>
        )}
      </Wrapper>
    );
  },
);

const Wrapper = styled.div`
  height: calc(100vh - 165px);
  flex: 0 1 40%;
  border-radius: 4px;
  border: 1px solid ${props => props.color.line};
  background: ${props => props.color.line_light};

  @media (max-width: 1024px) {
    height: auto;
  }
`;

const AccountingDocumentsDetailsWrapper = styled.div`
  height: 100%;
  position: relative;
  padding-left: 17px;
  padding-right: 17px;
  padding-bottom: 17px;
`;

const AccountingDocumentsDetailsHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  padding-top: 17px;
  padding-bottom: 17px;

  @media (max-width: 768px) {
    flex-wrap: wrap;
    gap: 8px;
  }
`;

const AccountingDocumentsDetailsMain = styled.div`
  height: calc(100vh - 245px);
  background: ${props => props.color.white};
`;

const Link = styled.a``;

const EmptyBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: ${props => props.bg.white};
  height: 100%;
  padding: 50px;
`;
