const getListTypesFavorite = state => state.favorite.listTypesFavorite;
const getNewFavoriteId = state => state.favorite.newFavoriteId;
const getListAllFavoriteProduct = state =>
  state.favorite.listAllFavoriteProduct;
const getFavoriteName = state => state.favorite.favoriteName;
const getFavoriteDescription = state => state.favorite.favoriteDescription;
const getLoading = state => state.favorite.loading;
const getLoadingFirst = state => state.favorite.isFirstLoading;
const getMeta = state => state.favorite.meta;

const favoriteSelectors = {
  getListTypesFavorite,
  getNewFavoriteId,
  getFavoriteName,
  getFavoriteDescription,
  getListAllFavoriteProduct,
  getMeta,
  getLoadingFirst,
  getLoading,
};

export default favoriteSelectors;
