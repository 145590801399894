import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import moment from 'moment/moment';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Sprite } from '@root/assets/svg';
import { Empty } from '@root/components';
import { NavigationButton } from '@root/ui';

import { Typography, useTheme } from '@mui/material';

export const NewsOrEventCard = ({
  viewType = 'news',
  navigationBtn = false,
  isActive = false,
  item,
  handleCardEvent,
  handleNavigationBtnEvent,
  cardStyles,
  scrollToBottom
}) => {
  const { color } = useTheme();
  const { t } = useTranslation('news_and_events', { useSuspense: false });
  const [imgError, setImgError] = useState(false);

  return (
    <Card
      style={{ ...cardStyles }}
      color={color}
      isActive={isActive}
      onClick={() =>{
        handleCardEvent(item?.slug)
        scrollToBottom()
      }}
    >
      <FlexWrap>
        <LeftSideWrap>
          {item.preview_image && !imgError ? (
            <img
              src={item.preview_image}
              style={{ width: '100%', height: '58px', objectFit: 'contain' }}
              alt={item.preview_image}
              onError={() => {
                setImgError(true);
              }}
            />
          ) : (
            <Empty
              type="EmptyNews"
              contentStyles={{ height: '58px' }}
            />
          )}
        </LeftSideWrap>
        <RightSideWrap>
          <Typography
            color={color.gray}
            variant="bodyBoldSmall"
            sx={{ marginBottom: '5px', minHeight: '15px' }}
          >
            {item.title}
          </Typography>
          {viewType === 'news' && (
            <Typography
              color={color.gray_80}
              variant="bodySmall"
              sx={{ minHeight: '15px' }}
              dangerouslySetInnerHTML={{ __html: item.description }}
            ></Typography>
          )}
          {viewType !== 'news' && (
            <Div>
              <Typography
                color={color.gray_60}
                variant="bodyBoldExSmall"
                sx={{ display: 'flex', alignItems: 'center', minWidth: '96px' }}
              >
                <Svg>
                  <use href={`${Sprite}#icon-calendar`}></use>
                </Svg>
                {moment(item?.date).format('DD.MM.YYYY')} {item.time}
              </Typography>
              {item?.location && (
                <Typography
                  color={color.gray_60}
                  variant="bodyBoldExSmall"
                  sx={{ display: 'flex', alignItems: 'center' }}
                >
                  <Svg>
                    <use href={`${Sprite}#icon-location`}></use>
                  </Svg>
                  {item?.location}
                </Typography>
              )}
            </Div>
          )}
          {navigationBtn && (
            <NavigationButton
              sx={{
                padding: '3px !important',
                marginTop: '8px !important',
                color: `${color.gray_80} !important`,
                display: 'block',
                textAlign: 'left !important',
                width: 'fit-content',
              }}
              onClick={() => handleNavigationBtnEvent(item?.slug)}
            >
              {t('buttons.reed_more')}
            </NavigationButton>
          )}
        </RightSideWrap>
      </FlexWrap>
    </Card>
  );
};

const Card = styled.div`
  padding: 12px 10px;
  border-bottom: 1px solid ${({ color }) => color.line};
  border-left: ${({ isActive, color }) =>
    isActive && `3px solid ${color.main}`};
  background-color: ${({ isActive, color }) =>
    isActive ? 'rgba(239, 243, 253, 1)' : color.white};
  cursor: pointer;
`;
const FlexWrap = styled.div`
  display: flex;
`;
const LeftSideWrap = styled.div`
  pointer-events: none;
  width: 76px;
  margin-right: 10px;
  flex-shrink: 0;
`;
const RightSideWrap = styled.div`
  display: flex;
  flex-direction: column;
`;

const Div = styled.div`
  display: flex;
  gap: 16px;
`;

const Svg = styled.svg`
  width: 13px;
  height: 13px;
  vertical-align: middle;
  position: relative;
  top: -2px;
  margin-right: 3px;
`;

NewsOrEventCard.propTypes = {
  viewType: PropTypes.string,
  navigationBtn: PropTypes.bool,
  isActive: PropTypes.bool,
  item: PropTypes.object,
  handleCardEvent: PropTypes.func.isRequired,
  handleNavigationBtnEvent: PropTypes.func.isRequired,
  cardStyles: PropTypes.object,
};
