const getOrder = state => state.order.order;
const getOrderId = state => state.order.orderId;
const getError = state => state.order.error;
const getMyOrders = state => state.order.myOrders;
const getCoOrders = state => state.order.commercialOfferOrders;
const getCoOrdersMeta = state => state.order.commercialOfferOrdersMeta;
const getArchiveOrders = state => state.order.archiveOrders;
const getOrderHistoryById = state => state.order.orderHistoryById;
const getMyOrdersMeta = state => state.order.myOrdersMeta;
const getLoading = state => state.order.loading;
const getLoadingHistory = state => state.order.loadingHistory;
const getLoadingHistoryDetails = state => state.order.loadingDetails;
const getOnlineForm = state => state.order.onlineForm;
const getSavedOrderTemplate = state => state.order.savedOrderTemplate;

const orderSelectors = {
  getLoading,
  getLoadingHistory,
  getLoadingHistoryDetails,
  getOrder,
  getOrderId,
  getError,
  getMyOrders,
  getArchiveOrders,
  getOrderHistoryById,
  getMyOrdersMeta,
  getOnlineForm,
  getSavedOrderTemplate,
  getCoOrders,
  getCoOrdersMeta,
};
export default orderSelectors;
