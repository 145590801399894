import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import {
  BasicSelect,
  ButtonBase,
  FileLoader,
  OutlineButtonBase,
  PhoneInput,
  TextInput,
} from '@root/ui';

import { useTheme } from '@mui/material';

import { GoogleCityInput } from '../Input/GoogleCityInput/index';
import { GoogleStreetInput } from '../Input/GoogleStreetInput/index';

export const CompanyForm = ({
  data,
  setData,
  onChange,
  error,
  submitForm,
  labelRowDirection,
  labelRowGap,
  inputHeight,
  showActionBtn = false,
  actionBtnCallback,
  labelTextVariant,
  errorPositionLeft,
}) => {
  const { color } = useTheme();
  const { t } = useTranslation(['commercial_offer', 'common'], {
    useSuspense: false,
  });
  const fileRef = useRef();
  const {
    logo,
    company_name,
    company_type_id,
    contractor_code,
    identification_code,
    phone,
    city,
    address,
    zip_code,
    contact_person,
    site,
    email,
  } = data;
  const handleSetImage = img => {
    if (!img) setData(prevState => ({ ...prevState, is_delete_file: true }));
    setData(prevState => ({ ...prevState, logo: img }));
  };

  const [cityTips, setCityTips] = useState([]);
  const [addresTips, setAddresTips] = useState([]);

  return (
    <Form onSubmit={submitForm}>
      <FileLoader
        fileRef={fileRef}
        file={logo}
        setFile={img => {
          setData(prevstate => ({ ...prevstate, logo: img }));
        }}
        title={t('company_logo')}
        handleSetImage={handleSetImage}
        isLogo={true}
        styles={{ margin: '0', background: color.background }}
        initError={error?.logo}
      />
      <TextInput
        placeholder={t('about_company_fields.company_placeholder')}
        label={t('about_company_fields.company_label')}
        value={company_name}
        onChange={onChange('company_name')}
        required
        sx={{ height: inputHeight }}
        max={255}
        error={error?.company_name?.init}
        errorText={error?.company_name?.text}
        labelRowDirection={labelRowDirection}
        labelRowGap={labelRowGap}
        labelTextVariant={labelTextVariant}
      />
      <BasicSelect
        error={error?.company_type_id?.init}
        helperText={error?.company_type_id?.text}
        currentId={company_type_id}
        data={[
          { id: 1, name: t('about_company_fields.tov') },
          { id: 2, name: t('about_company_fields.fop') },
        ]}
        name={t('about_company_fields.type_of_cooperation')}
        callBack={onChange('company_type_id')}
        height={inputHeight}
        label={t('about_company_fields.type')}
        labelRowDirection={labelRowDirection}
        labelRowGap={labelRowGap}
        labelTextVariant={labelTextVariant}
        errorPositionLeft={errorPositionLeft}
        required
      />
      {company_type_id && (
        <>
          {company_type_id === 1 && (
            <TextInput
              error={error?.contractor_code?.init}
              errorText={error?.contractor_code?.text}
              value={contractor_code}
              onChange={onChange('contractor_code')}
              placeholder={t(
                'about_company_fields.company_registration_number',
              )}
              type="text"
              max={8}
              digitsOnly={true}
              sx={{
                height: inputHeight,
              }}
              label={t('about_company_fields.company_registration_number')}
              labelRowDirection={labelRowDirection}
              labelRowGap={labelRowGap}
              labelTextVariant={labelTextVariant}
              required
            />
          )}

          {company_type_id === 2 && (
            <TextInput
              error={error?.identification_code?.init}
              errorText={error?.identification_code?.text}
              value={identification_code}
              onChange={onChange('identification_code')}
              placeholder={t('about_company_fields.ipn')}
              type="text"
              max={10}
              digitsOnly={true}
              sx={{
                height: inputHeight,
              }}
              label={t('about_company_fields.ipn_company')}
              labelRowDirection={labelRowDirection}
              labelRowGap={labelRowGap}
              labelTextVariant={labelTextVariant}
              required
            />
          )}
        </>
      )}
      <PhoneInput
        label={t('about_company_fields.phone_label')}
        placeholder={t('about_company_fields.phone_placeholder')}
        value={phone}
        onChange={onChange('phone')}
        error={error?.phone?.init}
        errorText={error?.phone?.text}
        required
        sx={{ height: inputHeight }}
        labelRowDirection={labelRowDirection}
        labelRowGap={labelRowGap}
        labelTextVariant={labelTextVariant}
      />
      <GoogleCityInput value={city} onFound={founds => setCityTips(founds)}>
        <TextInput
          placeholder={t('about_company_fields.city_placeholder')}
          label={t('about_company_fields.city_label')}
          value={city}
          onChange={onChange('city')}
          error={error?.city?.init}
          errorText={error?.city?.text}
          max={50}
          required
          sx={{ height: inputHeight }}
          labelRowDirection={labelRowDirection}
          labelRowGap={labelRowGap}
          labelTextVariant={labelTextVariant}
          tips={cityTips}
        />
      </GoogleCityInput>
      <GoogleStreetInput
        value={address}
        city={city}
        onFound={founds => setAddresTips(founds)}
      >
        <TextInput
          placeholder={t('about_company_fields.address_placeholder')}
          label={t('about_company_fields.address_label')}
          value={address}
          onChange={onChange('address')}
          error={error?.address?.init}
          errorText={error?.address?.text}
          max={255}
          required
          sx={{ height: inputHeight }}
          labelRowDirection={labelRowDirection}
          labelRowGap={labelRowGap}
          labelTextVariant={labelTextVariant}
          tips={addresTips}
        />
      </GoogleStreetInput>

      <TextInput
        placeholder={t('about_company_fields.zip_code_placeholder')}
        label={t('about_company_fields.zip_code_label')}
        value={zip_code}
        onChange={onChange('zip_code')}
        max={5}
        sx={{ height: inputHeight }}
        digitsOnly
        labelRowDirection={labelRowDirection}
        labelRowGap={labelRowGap}
        labelTextVariant={labelTextVariant}
      />
      <TextInput
        placeholder={t('about_company_fields.person_placeholder')}
        label={t('about_company_fields.person_label')}
        value={contact_person}
        onChange={onChange('contact_person')}
        max={50}
        sx={{ height: inputHeight }}
        labelRowDirection={labelRowDirection}
        labelRowGap={labelRowGap}
        labelTextVariant={labelTextVariant}
      />
      <TextInput
        placeholder={t('about_company_fields.site_placeholder')}
        label={t('about_company_fields.site_label')}
        value={site}
        onChange={onChange('site')}
        max={255}
        sx={{ height: inputHeight }}
        error={error?.site?.init}
        errorText={error?.site?.text}
        labelRowDirection={labelRowDirection}
        labelRowGap={labelRowGap}
        labelTextVariant={labelTextVariant}
      />
      <TextInput
        placeholder={t('about_company_fields.email_placeholder')}
        label={t('about_company_fields.email_label')}
        value={email}
        onChange={onChange('email')}
        max={255}
        sx={{ height: inputHeight }}
        error={error?.email?.init}
        errorText={error?.email?.text}
        labelRowDirection={labelRowDirection}
        labelRowGap={labelRowGap}
        labelTextVariant={labelTextVariant}
      />
      <div style={{ display: 'flex', justifyContent: 'flex-end', gap: '15px' }}>
        {showActionBtn && (
          <OutlineButtonBase
            onClick={actionBtnCallback}
            sx={{ height: '36px' }}
          >
            {t('common:buttons.delete')}
          </OutlineButtonBase>
        )}
        <ButtonBase
          sx={{
            height: '36px',
            maxWidth: '110px',
            width: '100%',
            alignSelf: 'end',
          }}
          type="submit"
        >
          {t('common:buttons.save')}
        </ButtonBase>
      </div>
    </Form>
  );
};

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 16px;

  @media (max-width: 991px) {
    padding-right: 0;
  }
`;

CompanyForm.propTypes = {
  data: PropTypes.exact({
    logo: PropTypes.object,
    is_delete_file: PropTypes.bool,
    company_name: PropTypes.string,
    edrpou: PropTypes.string,
    phone: PropTypes.string,
    city: PropTypes.string,
    address: PropTypes.string,
    zip_code: PropTypes.string,
    contact_person: PropTypes.string,
    site: PropTypes.string,
    email: PropTypes.string,
  }),
  setData: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  error: PropTypes.object,
  submitForm: PropTypes.func.isRequired,
  showActionBtn: PropTypes.bool,
  actionBtnCallback: PropTypes.func,
  labelRowDirection: PropTypes.bool,
  labelRowGap: PropTypes.string,
  inputHeight: PropTypes.string,
  errorPositionLeft: PropTypes.string,
};
