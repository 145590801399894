import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const createSavedOrder = createAsyncThunk(
  'savedOrders/crateSavedOrders',
  async ({ savedData, onResolve, onReject }, thunkApi) => {
    try {
      const response = await axios.post(`usercarts`, savedData);

      if (onResolve) onResolve(response.data);
      return response.data;
    } catch (error) {
      if (onReject) onReject(error.message.error.text);
      return thunkApi.rejectWithValue(error);
    }
  },
);

const getSavedOrders = createAsyncThunk(
  'savedOrders/getSavedOrders',
  async ({ page, search }, thunkApi) => {
    try {
      const response = await axios.get(`usercarts`, {
        params: { page, q: search },
      });

      return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  },
);

const deleteSavedOrderById = createAsyncThunk(
  'savedOrders/deleteSavedOrderById',
  async ({ id, onResolve, onReject }, thunkApi) => {
    try {
      const response = await axios.delete(`usercarts/${id}`);

      if (onResolve) onResolve(response.data);
      return response.data;
    } catch (error) {
      if (onReject) onReject(error.response.data.message);
      return thunkApi.rejectWithValue(error);
    }
  },
);

const addSavedOrderToCart = createAsyncThunk(
  'savedOrders/addSavedOrderToCart',
  async ({ id, onResolve, onReject }, thunkApi) => {
    try {
      const response = await axios.post(`usercarts/${id}/tocart`);
      if (onResolve) onResolve(response.data);
      return response.data;
    } catch (error) {
      if (onReject) onReject(error.response.data.message);
      return thunkApi.rejectWithValue(error);
    }
  },
);

const deleteSavedOrderProductById = createAsyncThunk(
  'savedOrders/deleteSavedOrderProductById',
  async ({ id, productId, onResolve, onReject }, thunkApi) => {
    try {
      const response = await axios.delete(`usercarts/${id}/${productId}`);

      if (onResolve) onResolve(response.data);
      return response.data;
    } catch (error) {
      if (onReject) onReject(error.response.data.message);
      return thunkApi.rejectWithValue(error);
    }
  },
);

const addSavedOrderProductToCart = createAsyncThunk(
  'savedOrders/addSavedOrderProductToCart',
  async ({ id, productId, onResolve, onReject }, thunkApi) => {
    try {
      const response = await axios.post(`usercarts/${id}/${productId}/tocart`);

      if (onResolve) onResolve(response.data);
      return response.data;
    } catch (error) {
      if (onReject) onReject(error.response.data.message);
      return thunkApi.rejectWithValue(error);
    }
  },
);

const cancelAddSavedOrderToCart = createAsyncThunk(
  'savedOrders/cancelAddSavedOrderToCart',
  async ({ id, onResolve, onReject }, thunkApi) => {
    try {
      const response = await axios.post(`usercarts/${id}/cancel`);

      if (onResolve) onResolve(response.data);
      return response.data;
    } catch (error) {
      if (onReject) onReject(error.response.data.message);
      return thunkApi.rejectWithValue(error);
    }
  },
);

export default {
  createSavedOrder,
  addSavedOrderToCart,
  getSavedOrders,
  deleteSavedOrderById,
  deleteSavedOrderProductById,
  addSavedOrderProductToCart,
  cancelAddSavedOrderToCart,
};
