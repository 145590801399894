import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Sprite } from '@root/assets/svg';
import { IconBtn } from '@root/ui';

import { Typography, useTheme } from '@mui/material';

export const Chip = ({
  title,
  value,
  onClick,
  isHideDelete = false,
  sx,
  children,
  separator = ':',
}) => {
  const { color } = useTheme();

  return (
    <Component color={color} style={{ ...sx }}>
      {title && (
        <Typography
          sx={{
            color: color.gray_80,
          }}
          variant={'bodyExSmall'}
          component={'p'}
        >
          {title}
          {separator}
        </Typography>
      )}

      {children ? (
        children
      ) : (
        <Typography
          sx={{
            color: color.gray_80,
          }}
          variant={'bodyExSmall'}
          component={'p'}
        >
          &nbsp;
          {value}
        </Typography>
      )}

      {!isHideDelete && (
        <IconBtn
          onClick={onClick}
          href={`${Sprite}#icon-cross`}
          size={16}
          sx={{ marginLeft: '10px', display: 'block' }}
        />
      )}
    </Component>
  );
};

const Component = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  background: ${({ color }) => color.gray_20};
  padding: 8px 6px;
  border: 1px solid ${({ color }) => color.line_light};
  border-radius: 4px;
`;

Chip.propTypes = {
  title: PropTypes.string,
  value: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  sx: PropTypes.object,
  isHideDelete: PropTypes.bool,
};
