import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Sprite } from '@root/assets/svg';
import { IconBtn } from '@root/ui';

import { Typography, useTheme } from '@mui/material';

export const HeaderDefault = ({ text, onClose, sx }) => {
  const { color } = useTheme();
  return (
    <Wrapper sx={sx}>
      <Typography variant={'h3'} sx={{ color: color.gray }}>
        {text}
      </Typography>
      <IconBtn size="24px" href={`${Sprite}#icon-cross`} onClick={onClose} />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${({ sx }) => sx}
`;

HeaderDefault.propTypes = {
  text: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  sx: PropTypes.object,
};
