import React, { useEffect, useState } from 'react';
import EllipsisText from 'react-ellipsis-text';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Sprite } from '@root/assets/svg';
import { Empty, InfiniteScroll, StepperStatusTyne } from '@root/components';
import { CustomScroll, IconBtn, ModalBase, ProgressLoader } from '@root/ui';

import homeOperation from '@redux/home/home-operation';
import homeSelectors from '@redux/home/home-selectors';
import orderOperation from '@redux/order/order-operation';
import { clearOrderHistoryDetails } from '@redux/order/order-slice';

import { Divider, Typography, useTheme } from '@mui/material';

export const OrderWidjet = ({ conf, historyDetailsModule, activeTab }) => {
  const [open, setOpen] = useState(false);
  const [page, setPage] = useState(1);
  const isExpanded = conf.size === '2fr';
  const { t } = useTranslation(['order_history', 'common', 'home'], {
    useSuspense: false,
  });
  const { color } = useTheme();
  const ordersList = useSelector(homeSelectors.getHomeOrders);
  const loading = useSelector(homeSelectors.getHomeOrdersLoading);
  const meta = useSelector(homeSelectors.getHomeOrdersMeta);
  const dispatch = useDispatch();

  useEffect(() => {
    if (activeTab) getOrdersList(1);
    setPage(1);
  }, [activeTab]);

  const getOrdersList = page =>
    dispatch(homeOperation.getHomeOrders({ page, activeTab }));

  const loadMoreItems = isInView => {
    if (isInView) {
      setPage(page + 1);
      if (meta.current_page !== meta.last_page) {
        getOrdersList(page + 1);
      }
    }
  };

  const showOrderDetails = orderId => {
    setOpen(true);
    dispatch(
      orderOperation.getHistoryOrderById({ id: orderId, orderTab: activeTab }),
    );
  };

  const onClose = () => {
    setOpen(false);
    dispatch(clearOrderHistoryDetails());
  };

  return (
    <ContentWrap>
      {loading && <ProgressLoader />}
      <List expanded={isExpanded}>
        <CustomScroll vertical={true}>
          {!loading && !ordersList.length && (
            <Empty
              type={'EmptyBox'}
              title={
                activeTab === 'my-commercial-offers'
                  ? t('home:there_is_no_any_commercial_offers')
                  : t('home:empty_title')
              }
            />
          )}
          <InfiniteScroll
            data={ordersList}
            onScroll={({ inView }) => inView && loadMoreItems(inView)}
          >
            {({ isInView, item, index }) => {
              return (
                <ListItem color={color} key={item.id}>
                  <ListItemHeader
                    isExpanded={isExpanded}
                    isCommercialOffer={activeTab === 'my-commercial-offers'}
                  >
                    <Button
                      isCommercialOffer={activeTab === 'my-commercial-offers'}
                      color={color}
                      onClick={() => showOrderDetails(item.id)}
                    >
                      <Typography color={color.gray} variant={'bodyBoldSmall'}>
                        {activeTab === 'my-orders' && '№'}{' '}
                        <EllipsisText
                          text={item?.title?.toString()}
                          length={'20'}
                        />
                      </Typography>
                    </Button>
                    {isExpanded && (
                      <SmallDetailsOrder
                        isCommercialOffer={activeTab === 'my-commercial-offers'}
                      >
                        <Typography
                          variant={'bodyLarge'}
                          color={color.gray_60}
                          sx={{ minWidth: '65px' }}
                        >
                          {item.quantity}{' '}
                          {t('order_history:items_count.key', {
                            count: Number(item.quantity),
                          })}
                        </Typography>
                        <Divider
                          orientation="vertical"
                          flexItem={true}
                          sx={{
                            margin: '0 7px',
                          }}
                        />
                        <Typography
                          variant={'bodyBoldSmall'}
                          color={color.gray}
                        >
                          {item.amount} {item.currency}
                        </Typography>
                      </SmallDetailsOrder>
                    )}
                    {item?.statuses && (
                      <StepperStatusTyne
                        steps={item?.statuses}
                        withSteps={!isExpanded && activeTab === 'my-orders'}
                        boxSx={{
                          minHeight: isExpanded ? 'initial' : '30px !important',
                          width: 130,
                        }}
                      />
                    )}
                  </ListItemHeader>
                  <ListItemBody>
                    <Typography
                      sx={{ whiteSpace: 'nowrap' }}
                      color={color.gray_60}
                      variant={'bodySmall'}
                    >
                      {t('order_history:create')} {item?.created}
                    </Typography>
                  </ListItemBody>
                </ListItem>
              );
            }}
          </InfiniteScroll>
        </CustomScroll>
      </List>
      {open && (
        <ModalBase
          sx={{
            padding: '45px 38px 38px',
          }}
          open={open}
          handleClose={onClose}
        >
          <IconBtn
            sx={{
              position: 'absolute',
              top: '10px',
              right: '38px',
            }}
            size={25}
            href={`${Sprite}#icon-cross`}
            onClick={onClose}
          />
          {historyDetailsModule}
        </ModalBase>
      )}
    </ContentWrap>
  );
};

const ContentWrap = styled.div`
  height: 100%;
`;

const ListItem = styled.div`
  padding: 12px 10px;
  border-bottom: 1px solid ${({ color }) => color.line};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const ListItemHeader = styled.div`
  display: flex;
  align-items: ${({ isExpanded }) => (isExpanded ? 'center' : 'flex-start')};
  justify-content: ${({ isExpanded }) =>
    isExpanded ? 'start' : 'space-between'};

  gap: 85px;

  @media(max-width: 600px) {
    gap: 0;
  }
`;

const ListItemBody = styled.div``;

const Button = styled.button`
  transition: 0.25s;
  cursor: pointer;
  text-decoration: underline;
  color: ${({ color }) => color.text_color.main};
  min-width: 160px;
  text-align: left;

  &:hover {
    color: ${({ color }) => color.main};
  }
`;

const SmallDetailsOrder = styled.div`
  text-transform: lowercase;
  display: flex;
  align-items: center;
  height: 8px;
  // margin: 0 125px;
  min-width: 170px;
`;

const List = styled.div`
  height: 307px;

  & ${ListItem} {
    flex-direction: ${({ expanded }) => (expanded ? 'row' : '')};
  }

  & ${ListItemHeader} {
    justify-content: ${({ expanded }) => (expanded ? 'initial' : '')};
    width: 100%;
  }
`;

OrderWidjet.propTypes = {
  conf: PropTypes.exact({
    id: PropTypes.number,
    isHide: PropTypes.bool,
    isNav: PropTypes.bool,
    key: PropTypes.string,
    name: PropTypes.string,
    position: PropTypes.object,
    size: PropTypes.string,
    sort: PropTypes.number,
  }),
  historyDetailsModule: PropTypes.object,
  activeTab: PropTypes.string,
};
