import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import PropTypes from 'prop-types';

import { Sprite } from '@root/assets/svg';
import { AccordionBase, Tabs } from '@root/components';
import { categoryList } from '@root/conf';
import { CustomScroll, IconBtn, MenuBurger } from '@root/ui';

import categoryOperation from '@redux/category/category-operation';
import categorySelectors from '@redux/category/category-selectors';

import { Divider, Drawer, List, ListItem, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

const drawerWidth = 240;

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  padding: '8px 16px 8px 24px',
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'space-between',
  alignItems: 'center',

  button: {
    marginLeft: '8px',
  },
}));

const FlexCont = styled('div')(({ theme }) => ({
  display: 'flex',

  justifyContent: 'space-between',
  alignItems: 'center',
}));

const Contend = styled('div', {
  shouldForwardProp: prop => prop !== 'open',
})(({ theme, open }) => ({
  //overflow: overflow,
  position: 'relative',
  height: '100%',
  flexGrow: 1,
  borderTop: '1px solid #dbdee9',
  display: 'flex',
  flexDirection: 'row',

  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: `-${drawerWidth}px`,
  ...(open && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  }),
}));

const Container = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  overflow: 'auto',
  height: '640px',
}));

export const DrawerCategory = ({
  activeId,
  setActiveId,
  children,
  expanded,
  setExpanded,
  handleById,
  defaultOpen = false,
}) => {
  const [open, setOpen] = useState(defaultOpen);
  const dispatch = useDispatch();
  const { t } = useTranslation(['catalog', 'home'], { useSuspense: false });
  const menuData = useSelector(categorySelectors.getCategoryListToEdit);

  const data = useMemo(() => {
    if (menuData.length === 0) return;
    return categoryList(menuData);
  }, [menuData]);

  useEffect(() => {
    dispatch(categoryOperation.getCategoryListToAdmin());
  }, []);

  const handleChange = panel => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <>
      <MenuBurger
        open={open}
        drawerWidth={drawerWidth}
        handleDrawerOpen={() => setOpen(true)}
      />

      <Drawer
        sx={{
          borderTop: '1px solid #dbdee9',
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            position: 'inherit',
            width: drawerWidth,
            boxSizing: 'border-box',
            borderRight: 'none',
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <DrawerHeader sx={{ minHeight: 'fit-content !important' }}>
          <Typography variant={'bodyBoldMedium'} component={'p'}>
            <>{t('catalog:category_title')}</>
          </Typography>
          <FlexCont>
            <IconBtn
              aria-label="close drawer"
              onClick={() => setOpen(false)}
              href={`${Sprite}#icon-close_arrow`}
            />
          </FlexCont>
        </DrawerHeader>
        <Divider />
        <Container>
          <CustomScroll vertical={true}>
            <ListItem disablePadding>
              <Tabs
                text={t('home:modals.total_price_modal.selected_products')}
                id={0}
                active={0 === activeId}
                handleChange={handleChange('')}
                handleById={() => {}}
                setActiveId={setActiveId}
                type="static"
              />
            </ListItem>
            {data ? (
              <List sx={{ p: 0 }}>
                {data.map((el, i) => (
                  <>
                    <ListItem key={el?.id} disablePadding>
                      {el?.children?.length > 0 ? (
                        <AccordionBase
                          handleOpenModal={() => {}}
                          data={el}
                          expanded={expanded}
                          handleChange={handleChange}
                          handleById={handleById}
                          setActiveId={setActiveId}
                          activeId={activeId}
                          type="static"
                        />
                      ) : (
                        <Tabs
                          text={el?.name}
                          id={el?.id}
                          active={el.id === activeId}
                          handleChange={handleChange('')}
                          handleById={handleById}
                          setActiveId={setActiveId}
                          type="static"
                        />
                      )}
                    </ListItem>
                    <Divider />
                  </>
                ))}
              </List>
            ) : (
              <></>
            )}
          </CustomScroll>
        </Container>
      </Drawer>

      <Contend open={open}> {children} </Contend>
    </>
  );
};

DrawerCategory.propTypes = {
  activeId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  setActiveId: PropTypes.func.isRequired,
  expanded: PropTypes.bool.isRequired,
  setExpanded: PropTypes.func.isRequired,
  handleById: PropTypes.func.isRequired,
  children: PropTypes.node,
  defaultOpen: PropTypes.bool,
};
