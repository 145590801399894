import { createSlice } from '@reduxjs/toolkit';
import { nanoid } from 'nanoid';

import operation from './auth-operation';

const initialState = {
  token: '',
  isRefreshing: true,
  role: [],
  register: {
    email: '',
    name: null,
    birthday: null,
    phones: [{ id: nanoid(), phone: null, confirm: null }],
    city_id: null,
    status: false,
    avatar: null,
    city: '',
    company: {
      official_name: '',
      company_type_id: '',
      contractor_code: null,
      identification_code: null,
      logo: null,
      name_company: '',
      employees_quantity: '',
      additional_info: '',
      site: '',
      activity_areas: [],
      employees: [{ email: '', role: '' }],
    },
    logins: {},
    security: {
      saveLogs: false,
    },
  },
  dataUpdate: null,
  loading: false,

  passwordResetData: null,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    onClearRegister(state, { payload }) {
      state.register = initialState.register;
    },
    onClearDataUpdate(state, { payload }) {
      state.dataUpdate = initialState.dataUpdate;
    },
    storePasswordResetData(state, { payload }) {
      state.passwordResetData = payload;
    },
    unsetToken(state, {payload}){
        state.token = null
    }
  },
  extraReducers: {
    //====getUser====//

    [operation.getUser.pending]: (state, action) => {
      state.isRefreshing = true;
    },
    [operation.getUser.fulfilled]: (state, { payload }) => {
      const { persistedToken, user } = payload;

      const { role } = user;
      const isGuest = role[0] === 'guest';

      state.token = persistedToken;
      state.isRefreshing = false;
      state.role = role[0];

      if (isGuest) {
        state.register = user;
      }
    },
    [operation.getUser.rejected]: (state, { payload }) => {
      state.isRefreshing = false;
    },

    //====signIn====//
    [operation.signIn.pending]: (state, action) => {
      state.loading = true;
    },
    [operation.signIn.fulfilled]: (state, { payload }) => {
      state.token = payload?.token;
      state.loading = false;
      if (payload?.role) {
        state.role = payload?.role[0];
      }
    },
    [operation.signIn.rejected]: (state, { payload }) => {
      state.loading = false;
    },
    //====signUp====//
    [operation.signUp.pending]: (state, action) => {
      state.loading = true;
    },
    [operation.signUp.fulfilled]: (state, { payload }) => {
      state.register.email = payload.email;
      state.token = payload?.token;
      state.loading = false;
      state.role = payload.role[0];
    },
    [operation.signUp.rejected]: (state, action) => {
      state.loading = false;
    },
    //====signUpStepTwo====//
    [operation.signUpStepTwo.pending]: (state, action) => {
      state.loading = true;
    },
    [operation.signUpStepTwo.fulfilled]: (state, { payload }) => {
      state.register = payload;
      state.loading = false;
    },
    [operation.signUpStepTwo.rejected]: (state, action) => {
      state.loading = false;
    },
    //====signUpStepThierd====//
    [operation.signUpStepThierd.pending]: (state, action) => {
      state.loading = true;
    },
    [operation.signUpStepThierd.fulfilled]: (state, { payload }) => {
      state.register = payload;
      state.loading = false;
    },
    [operation.signUpStepThierd.rejected]: (state, action) => {
      state.loading = false;
    },
    //====signUpStepFours====//
    [operation.signUpStepFours.pending]: (state, action) => {
      state.loading = true;
    },
    [operation.signUpStepFours.fulfilled]: (state, { payload }) => {
      state.register = payload;
      state.loading = false;
    },
    [operation.signUpStepFours.rejected]: (state, action) => {
      state.loading = false;
    },
    //====signUpStepFifth====//
    [operation.signUpStepFifth.pending]: (state, action) => {
      state.loading = true;
    },
    [operation.signUpStepFifth.fulfilled]: (state, { payload }) => {
      state.register = payload;
      // state.register.employees = payload.employees;
      state.loading = false;
    },
    [operation.signUpStepFifth.rejected]: (state, action) => {
      state.loading = false;
    },

    //====signUpStepSixth====//
    [operation.signUpStepSixth.pending]: (state, action) => {
      state.loading = true;
    },
    [operation.signUpStepSixth.fulfilled]: (state, { payload }) => {
      state.role = payload.role[0];
      state.register.status = payload.status;
      state.loading = false;
    },
    [operation.signUpStepSixth.rejected]: (state, action) => {
      state.loading = false;
    },

    //====clearToken====//
    [operation.logOut.fulfilled]: (state, { payload }) => {
      state.token = null;
      state.role = [];
    },

    //====authByToken====//
    [operation.authByToken.pending]: (state, action) => {
      state.loading = true;
    },
    [operation.authByToken.fulfilled]: (state, { payload }) => {
      state.token = payload?.token;
      state.role = payload.role[0];
      state.loading = false;
    },
    [operation.authByToken.rejected]: (state, { payload }) => {
      const { data } = payload;
      state.loading = false;
      state.dataUpdate = data;
    },
    //====authNewUserByToken====//
    [operation.authNewUserByToken.pending]: (state, action) => {
      state.loading = true;
    },
    [operation.authNewUserByToken.fulfilled]: (state, { payload }) => {
      const { data, token } = payload;
      state.dataUpdate = { userInfo: { ...data, token }, type: 'new' };
      state.loading = false;
    },
    [operation.authNewUserByToken.rejected]: (state, { payload }) => {
      state.loading = false;
    },

    //====updateOldUserData====//
    [operation.updateOldUserData.pending]: (state, action) => {
      state.loading = true;
    },
    [operation.updateOldUserData.fulfilled]: (state, { payload }) => {
      state.token = payload?.token;
      state.role = payload.role[0];
      state.loading = false;
    },
    [operation.updateOldUserData.rejected]: (state, { payload }) => {
      state.loading = false;
    },

    //====passwordReset====//
    [operation.passwordReset.pending]: (state, action) => {
      state.loading = true;
    },
    [operation.passwordReset.fulfilled]: (state, { payload }) => {
      state.loading = false;
    },
    [operation.passwordReset.rejected]: (state, { payload }) => {
      state.loading = false;
    },

    //====passwordResetComfirm====//
    [operation.passwordResetComfirm.pending]: (state, action) => {
      state.loading = true;
    },
    [operation.passwordResetComfirm.fulfilled]: (state, { payload }) => {
      state.loading = false;
    },
    [operation.passwordResetComfirm.rejected]: (state, { payload }) => {
      state.loading = false;
    },
  },
});

export const { onClearRegister, onClearDataUpdate, storePasswordResetData, unsetToken } =
  authSlice.actions;

export default authSlice.reducer;
