import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const getDeliveryHistoryFromClient = createAsyncThunk(
  'logistic/getDeliveryHistoryFromClient',
  async ({ date_from, date_to, onResolve, onReject }, { rejectWithValue }) => {
    try {
      const { data } = await axios.get('logistic/returnlist', {
        params: {
          date_from,
          date_to,
        },
      });
      if (onResolve) onResolve(data.data);
      return data.data;
    } catch (error) {
      if (onReject) onReject(error.response.data.message);
      return rejectWithValue(error.response);
    }
  },
);

const getDeliveryHistorySendingToClient = createAsyncThunk(
  'logistic/getDeliveryHistorySendingToClient',
  async ({ date_from, date_to, onResolve, onReject }, { rejectWithValue }) => {
    try {
      const { data } = await axios.get('logistic/receivelist', {
        params: {
          date_from,
          date_to,
        },
      });
      if (onResolve) onResolve(data.data);
      return data.data;
    } catch (error) {
      if (onReject) onReject(error.response.data.message);
      return rejectWithValue(error.response);
    }
  },
);

const getDeliveryDetailsById = createAsyncThunk(
  'logistic/getDeliveryDetailsById',
  async ({ id, onResolve, onReject }, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(`logistic/${id}`);
      if (onResolve) onResolve(data.data);
      return data.data;
    } catch (error) {
      if (onReject) onReject(error.response.data.message);
      return rejectWithValue(error.response);
    }
  },
);

const getDeliveryRequisites = createAsyncThunk(
  'logistic/getDeliveryRequisites',
  async ({ onResolve, onReject }, { rejectWithValue }) => {
    try {
      const { data } = await axios.get('logistic/requisites');
      if (onResolve) onResolve(data.data);
      return data.data;
    } catch (error) {
      if (onReject) onReject(error.response.data.message);
      return rejectWithValue(error.response);
    }
  },
);

const createNewDelivery = createAsyncThunk(
  'logistic/createNewDelivery',
  async (paylod, { rejectWithValue }) => {
    try {
      const { data } = await axios.post('logistic/newsend', { ...paylod });

      return data.data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  },
);

export default {
  getDeliveryHistoryFromClient,
  getDeliveryHistorySendingToClient,
  getDeliveryDetailsById,
  getDeliveryRequisites,
  createNewDelivery,
};
