import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { PieChart } from 'react-minimal-pie-chart';
import { useSelector } from 'react-redux';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import userSelectors from '@root/redux/user/user-selectors';

import { Typography, useTheme } from '@mui/material';

export const Availability = ({
  inStock,
  notAvailable,
  present,
  warehouseId,
  warehouses,
}) => {
  const { color } = useTheme();
  const { t } = useTranslation(['common'], { useSuspense: false });
  const userPermissions = useSelector(userSelectors.getPermissions);
  const subColorMenage = stockValue =>
    stockValue > 70
      ? color.green_light
      : stockValue <= 30
      ? color.red_light
      : color.orange_light;

  const colorMenage = stockValue =>
    stockValue > 70 ? color.green : stockValue <= 30 ? color.red : color.orange;

  const checkAvailableQuantity = (stock, notAvailable) => {
    const stockRes = stock > 999 ? 999 + '+' : stock;
    const notAvailableRes = notAvailable > 999 ? 999 + '+' : notAvailable;
    return `${stockRes}/${notAvailableRes}`;
  };

  // TODO: Доделать вывод слов относительно 100% товаров
  const checkAvailableQuantityWords = (stock, notAvailable) => {
    return `${getWordByPercent(calculatePercent)}/${getWordByPercent(
      calculatePercent,
    )}`;
  };

  const checkAvailableQuantityWordStandart = () => {
    return `${getWordByPercent(calculatePercent)}`;
  };

  const getWordByPercent = percent => {
    switch (true) {
      case percent > 70:
        return t('common:bagato');
      case percent > 30:
        return t('common:dostatno');
      default:
        return t('common:malo');
    }
  };

  const calculatePercent = useMemo(() => {
    if (warehouseId) {
      const wh = warehouses.find(item => item.id === warehouseId);
      if (wh) {
        const result = (wh.stock * 100) / wh.stock_max;
        return Number.isInteger(result) ? result : 0;
      } else return present;
    } else return present;
  }, [warehouseId, warehouses, present]);

  const displayStock = useMemo(() => {
    if (warehouseId) {
      const wh = warehouses.find(item => item.id === warehouseId);
      if (wh) {
        return wh.stock;
      } else return notAvailable;
    } else return notAvailable;
  }, [warehouseId, warehouses, notAvailable]);

  const displayStockMax = useMemo(() => {
    if (warehouseId) {
      const wh = warehouses.find(item => item.id === warehouseId);
      if (wh) {
        return wh.stock_max;
      } else return inStock;
    } else return inStock;
  }, [warehouseId, warehouses, inStock]);

  const getDetailsByPermission = () => {
    switch (true) {
      // VIP acces
      case userPermissions.product_stock_all_access === true:
        return (
          <Typography
            variant={'bodySmall'}
            sx={{ color: color.gray, ml: '4px' }}
          >
            {checkAvailableQuantity(displayStockMax, displayStock)}
          </Typography>
        );
      // advanced access
      case userPermissions.product_stock_advanced_access === true:
        return (
          <Typography
            variant={'bodySmall'}
            sx={{ color: color.gray, ml: '4px' }}
          >
            {checkAvailableQuantityWords(displayStockMax, displayStock)}
          </Typography>
        );
      // standart access
      case userPermissions.product_stock_standard_access === true:
        return (
          <Typography
            variant={'bodySmall'}
            sx={{ color: color.gray, ml: '4px' }}
          >
            {checkAvailableQuantityWordStandart()}
          </Typography>
        );
      default:

        return ('undefined')
    }
  };

  return (
    <>
      <Component>
        <AvailabilityBox>
          <PieChart
            style={{
              width: '16px',
              height: '16px',
            }}
            data={[
              {
                title: '',
                value: calculatePercent,
                color: colorMenage(calculatePercent),
              },
              {
                title: '',
                value: 100 - calculatePercent,
                color: subColorMenage(calculatePercent),
              },
            ]}
            animate={false}
            lineWidth={40}
            startAngle={-90}
          />
          {calculatePercent === 0 ? (
            <Typography
              variant={'bodySmall'}
              sx={{ color: color.gray, ml: '4px' }}
            >
              {t('common:not_available')}
            </Typography>
          ) : (
            getDetailsByPermission()
          )}
        </AvailabilityBox>
      </Component>
      {warehouseId &&
        warehouses.map(
          item =>
            item.id === warehouseId && (
              <StorageBox>
                <Typography variant={'bodyExSmall'} sx={{ color: color.gray }}>
                  {item.name} :
                  <Typography
                    variant={'bodyBoldExSmall'}
                    sx={{ color: colorMenage(item.stock_percent), ml: '2px' }}
                  >
                    {item.stock}
                  </Typography>
                </Typography>
              </StorageBox>
            ),
        )}
    </>
  );
};

const Component = styled.div``;

const AvailabilityBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
const StorageBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 4px;
`;

Availability.propTypes = {
  inStock: PropTypes.number,
  notAvailable: PropTypes.number,
  present: PropTypes.number,
  warehouseId: PropTypes.number,
  warehouses: PropTypes.arrayOf(
    PropTypes.exact({
      city_name: PropTypes.string,
      warehouses: PropTypes.exact({
        name: PropTypes.string,
        stock: PropTypes.number,
        stock_max: PropTypes.number,
        stock_percent: PropTypes.number,
      }),
    }),
  ),
};
