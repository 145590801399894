import { memo } from 'react';

import styled from 'styled-components';

import { PDFFile } from '@root/assets/image';

import { Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

export const Documents = memo(
  ({
    t,
    handleSelectDocument,
    docInvoiceUrl,
    docInvoiceImplUrl,
    selectedDocument,
    verticalAlign = 'center',
  }) => {
    const { color } = useTheme();
    const { type, url } = selectedDocument;
    return (
      <DocumentsWrapper verticalAlign={verticalAlign}>
        {docInvoiceUrl && (
          <Document
            onClick={() => handleSelectDocument('bill', docInvoiceUrl)}
            isSelected={url === docInvoiceUrl}
            color={color.main}
          >
            <Image src={PDFFile} />
            <Typography
              color={url === docInvoiceUrl ? color.main : color.gray_60}
              variant="bodyExSmall"
            >
              {t('accounting_documents:bill_short')}
            </Typography>
          </Document>
        )}

        {docInvoiceImplUrl && (
          <Document
            onClick={() => handleSelectDocument('expense', docInvoiceImplUrl)}
            isSelected={url === docInvoiceImplUrl}
            color={color.main}
          >
            <Image src={PDFFile} />
            <Typography
              color={url === docInvoiceImplUrl ? color.main : color.gray_60}
              variant="bodyExSmall"
            >
              {t('accounting_documents:expenses_short')}
            </Typography>
          </Document>
        )}
      </DocumentsWrapper>
    );
  },
);

const DocumentsWrapper = styled.div`
  display: flex;
  column-gap: 4px;
  justify-content: ${({ verticalAlign }) => verticalAlign};
`;

const Document = styled.div`
  padding: 4px 6px;
  border: ${({ isSelected, color }) =>
    isSelected ? `1px solid ${color}` : '1px solid transparent'};
  border-radius: 4px;
  cursor: pointer;
  position: relative;
  z-index: 1000;
`;
const Image = styled.img`
  width: 32px;
  height: 32px;
`;
