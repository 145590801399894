import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import styled from 'styled-components';

import { Sprite } from '@root/assets/svg/index';
import authSelectors from '@root/redux/auth/auth-selectors';
import knowledgeSelectors from '@root/redux/knowledge/knowledge-selectors';
import { TextInput } from '@root/ui/Input/TextInput/index';

import { Typography, useTheme } from '@mui/material';

const KnowledgeTitleRename = () => {
  const { t } = useTranslation(['knowledge']);
  const role = useSelector(authSelectors.getRole);
  const meta = useSelector(knowledgeSelectors.getKnowledgeMeta);
  const dispatch = useDispatch();
  const { color } = useTheme();
  const [isEdit, setIsEdit] = useState(false);
  const [name, setName] = useState('');

  const toEdit = () => {
    // setName(meta?.brand);
    // setIsEdit(true);
  };
  const onSubmit = () => {
    // dispatch
  };

  return (
    <>
      <ListTitle color={color}>
        {isEdit ? (
          <>
            <TextInput />
            <EditTitleSvg
              onClick={() => {
                setIsEdit(false);
              }}
            >
              <use href={`${Sprite}#icon-cross`}></use>
            </EditTitleSvg>
            <EditTitleSvg onClick={onSubmit}>
              <use href={`${Sprite}#icon-done`}></use>
            </EditTitleSvg>
          </>
        ) : (
          <>
            <Typography variant="h2" color={color.gray_80}>
              {meta?.brand}
              {role === 'admin' && (
                <EditTitleSvg onClick={toEdit}>
                  <use href={`${Sprite}#icon-edit`}></use>
                </EditTitleSvg>
              )}
            </Typography>
            <Typography variant="bodyMedium" color={color.gray_60}>
              {meta?.articles_count}
            </Typography>
          </>
        )}
      </ListTitle>
    </>
  );
};

export default KnowledgeTitleRename;

const ListTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 20px 20px 34px;
  border-bottom: 1px solid ${({ color }) => color.line};

  & label {
    flex-grow: 1;
  }

  @media(max-width: 1024px) {
    padding: 10px 16px;
  }
`;

const EditTitleSvg = styled.svg`
  width: 16px;
  height: 16px;
  display: inline-block;
  margin-left: 8px;
  cursor: pointer;
`;
