const getLoading = state => state.banners.loading;
const getUploadLoading = state => state.banners.uploadLoading;
const getBanners = state => state.banners.banners;

const bannersSelectors = {
    getLoading,
    getUploadLoading,
    getBanners,
};
export default bannersSelectors;
