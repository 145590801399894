const getData = state => state.admin.data;
const getLinks = state => state.admin.links;
const getMeta = state => state.admin.meta;
const getLoadingBusiness = state => state.admin.loadingBusiness;
const getLoadingCounterparty = state => state.admin.loadingCounterparty;
const getLoadingPeople = state => state.admin.loadingPeople;
const getLoadingAgreement = state => state.admin.loadingAgreement;
const getAdminSearch = state => state.admin.search;
const getBusinessDetails = state => state.admin.businessDetails;
const getIsUpdateBusinessDetailsLoading = state =>
    state.admin.isUpdateBusinessDetailsLoading;
const getCompanyTypes = state =>
    state.admin.companyTypes;
const getSelectCompanys = state =>
    state.admin.selectCompanys;

const adminSelectors = {
    getData,
    getLinks,
    getMeta,
    getLoadingBusiness,
    getLoadingCounterparty,
    getLoadingPeople,
    getAdminSearch,
    getBusinessDetails,
    getIsUpdateBusinessDetailsLoading,
    getLoadingAgreement,
    getCompanyTypes,
    getSelectCompanys
};

export default adminSelectors;
