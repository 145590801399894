import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const getCompanyList = createAsyncThunk(
  'commercialOffer/getCompanyList',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get('/commercials/company');
      return response.data.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

const createCompany = createAsyncThunk(
  'commercialOffer/createCompany',
  async ({ formData, onResolve, onReject }, { rejectWithValue }) => {
    try {
      const { data } = await axios.post('/commercials/company', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      if (onResolve) onResolve();
      return data.data;
    } catch (error) {
      if (onReject) onReject(error.response.data.message);
      return rejectWithValue(error);
    }
  },
);

const updateCompany = createAsyncThunk(
  'commercialOffer/updateCompany',
  async ({ formData, id, onResolve, onReject }, { rejectWithValue }) => {
    try {
      const { data } = await axios.post(
        `/commercials/company/${id}`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        },
      );
      if (onResolve) onResolve();
      return data.data;
    } catch (error) {
      if (onReject) onReject(error.response.data.message);
      return rejectWithValue(error);
    }
  },
);

const deleteCompany = createAsyncThunk(
  'commercialOffer/deleteCompany',
  async ({ id }, { rejectWithValue }) => {
    try {
      const response = await axios.delete(`/commercials/company/${id}`);
      return response.data.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

const createCommercialOfferTemplate = createAsyncThunk(
  'commercialOffer/createCommercialOfferTemplate',
  async ({ ids }, { rejectWithValue }) => {
    try {
      const { data } = await axios.post('commercials/make', { ids: ids });
      return data.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

const getCommercialOfferProducts = createAsyncThunk(
  'commercialOffer/getCommercialOfferProducts',
  async ({ ids }, { rejectWithValue }) => {
    try {
      const { data } = await axios.get('commercials/products', {
        params: {
          ids: ids,
        },
      });
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

const getCommercialOfferCustomsProducts = createAsyncThunk(
  'commercialOffer/getCommercialOfferCustomsProducts',
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await axios.get('commercials/customs');
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

const createCustomProduct = createAsyncThunk(
  'commercialOffer/createCustomProduct',
  async ({ formData, onResolve, onReject }, { rejectWithValue }) => {
    try {
      const { data } = await axios.post('commercials/product', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      if (onResolve) onResolve();
      return data;
    } catch (error) {
      if (onReject) onReject(error.response.data.message);
      return rejectWithValue(error);
    }
  },
);

const removeCustomProduct = createAsyncThunk(
  'commercialOffer/removeCustomProduct',
  async ({ id }, { rejectWithValue }) => {
    try {
      const { data } = await axios.delete(`commercials/product/${id}`);
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

const updateCustomProduct = createAsyncThunk(
  'commercialOffer/updateCustomProduct',
  async ({ formData, id, onResolve, onReject }, { rejectWithValue }) => {
    try {
      const { data } = await axios.post(
        `/commercials/product/${id}`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        },
      );
      if (onResolve) onResolve();
      return data;
    } catch (error) {
      if (onReject) onReject(error.response.data.message);
      return rejectWithValue(error);
    }
  },
);

const resetAllChanges = createAsyncThunk(
  'commercialOffer/resetAllChanges',
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await axios.post('commercials/reset');
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

const createCommercialOffer = createAsyncThunk(
  'commercialOffer/createCommercialOffer',
  async ({ commercialData }, { rejectWithValue }) => {
    try {
      const { data } = await axios.post('commercials/store', commercialData);
      return data.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

const cloneCommercialOffer = createAsyncThunk(
  'commercialOffer/cloneCommercialOffer',
  async ({ id }, { rejectWithValue }) => {
    try {
      const { data } = await axios.post(`commercials/list/${id}/clone`);
      return data.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

const editCommercialOffer = createAsyncThunk(
  'commercialOffer/editCommercialOffer',
  async ({ id }, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(`commercials/list/${id}/edit`);
      return data.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

const updateEditCommercialOffer = createAsyncThunk(
  'commercialOffer/updateEditCommercialOffer',
  async ({ id, commercialData }, { rejectWithValue }) => {
    try {
      const { data } = await axios.post(
        `commercials/list/${id}/edit`,
        commercialData,
      );
      return data.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

const buyCommercialOfferProducts = createAsyncThunk(
  'commercialOffer/cloneCommercialOffer',
  async ({ id, operation, onResolve, onReject }, { rejectWithValue }) => {
    try {
      const { data } = await axios.post(`commercials/list/${id}/buy`, {
        operation: operation,
      });
      if (onResolve) onResolve(data.data);
      return data.data;
    } catch (error) {
      if (onReject) onReject(error.response.data.message);
      return rejectWithValue(error);
    }
  },
);

export default {
  getCompanyList,
  createCompany,
  updateCompany,
  deleteCompany,
  createCommercialOfferTemplate,
  getCommercialOfferProducts,
  createCustomProduct,
  removeCustomProduct,
  updateCustomProduct,
  resetAllChanges,
  createCommercialOffer,
  getCommercialOfferCustomsProducts,
  editCommercialOffer,
  updateEditCommercialOffer,
  cloneCommercialOffer,
  buyCommercialOfferProducts,
};
