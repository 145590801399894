import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import styled from 'styled-components';

import { Sprite } from '@root/assets/svg';
import { MenuBody, MenuButton, MenuItemCustoms } from '@root/ui';

import userOperation from '@redux/user/user-operation';
import userSelectors from '@redux/user/user-selectors';

import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';

export const Rate = () => {
  const { color } = useTheme();
  const currency = useSelector(userSelectors.getPrices);
  const choosedCurrency = useSelector(
    userSelectors.getChoosedCurrencyWidget,
  ).title;
  const [btnText, setBtnText] = useState(choosedCurrency);
  const [anchorEl, setAnchorEl] = useState(null);
  const [hovered, setHovered] = useState(false);
  const open = Boolean(anchorEl);
  const dispatch = useDispatch();

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChangeRate = rateChose => {
    setBtnText(currency[rateChose].title);

    dispatch(
      userOperation.putCurrencyValue({
        price: rateChose,
      }),
    );
  };

  return (
    <div>
      {' '}
      <MenuButton open={open} handleClick={handleClick}>
        <ButtonContentWrap onMouseEnter={() => {
          setHovered(true);
        }} onMouseLeave={() => {
          setHovered(false);
        }}>
          <Svg width={16} height={16} hoverColor={color.main} stroke={open || hovered ? color.main : color.gray}>
            <use href={`${Sprite}#icon-rate-coin`}></use>
          </Svg>

          <Typography
            variant={'bodyBoldSmall'}
            sx={{
              letterSpacing: '0.02em',
              lineHeight: 1,
              padding: '2px 0',
              marginLeft: '4px',
            }}
          >
            {btnText}
          </Typography>

        </ButtonContentWrap>
      </MenuButton>
      <MenuBody
        anchorEl={anchorEl}
        open={open}
        handleClose={handleClose}
        sx={{ width: '98px' }}
      >
        {Object.keys(currency).length > 0 &&
          Object.keys(currency).filter(item => currency[item].display_in_header).map((item, idx) => (
            <MenuItemCustoms
              isActive={btnText === currency[item].title}
              key={idx}
              handelClick={e => {
                handleClose();
                handleChangeRate(currency[item].field_name);
              }}
            >
              <Typography
                className={btnText === currency[item].title && 'active'}
                variant={'bodySmall'}
                color={`${color.gray} !important`}
              >
                {currency[item].title}
              </Typography>
            </MenuItemCustoms>
          ))}
      </MenuBody>
    </div>
  );
};

const ButtonContentWrap = styled.div`
    display: flex;
    align-items: center;
`;

const Svg = styled.svg`
    //margin-right: 4px;
`;
