import styled from 'styled-components';

import { useTheme } from '@mui/material/styles';

export const Container = ({
  children,
  width,
  padding,
  hidden = false,
  measure = 'px',
}) => {
  const { color } = useTheme();
  return (
    <Component
      color={color.background}
      width={width}
      padding={padding}
      isHidden={hidden}
      measure={measure}
    >
      {children}
    </Component>
  );
};

const Component = styled.div`
  margin: 0 auto;
  min-width: ${({ width, measure }) => width + measure};
  background-color: ${props => props.color};
  padding: ${({ padding }) => (padding ? padding : 0)};
  overflow: ${({ isHidden }) => (isHidden ? 'hidden' : 'visible')};
  height: 100%;

  @media (max-width: 1199px) {
    min-width: 100%;
  }
`;

Container.className = Component;
