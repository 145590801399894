import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import styled from 'styled-components';

import AgreementsDockLayout from '@root/modules/Agreements/AgreementsDockLayout';
import AgreementsTable from '@root/modules/Agreements/AgreementsTable';

import agreementsDockOperations from '@redux/agreements-dock/agreements-dock-operations';
import agreementsDockSelectors from '@redux/agreements-dock/agreements-dock-selectors';

import { Typography, useTheme } from '@mui/material';

const AgreementsLayout = () => {
  const { color } = useTheme();
  const { t } = useTranslation(['profile'], { useSuspense: false });

  const dispatch = useDispatch();

  const [targetDock, setTargetDock] = useState(null);

  useEffect(() => {
    dispatch(agreementsDockOperations.getDockList());
  }, []);

  const dockList = useSelector(agreementsDockSelectors.getDockList);

  const testFc = id => {
    setTargetDock(dockList.find(el => +el.id === +id));
  };

  return (
    <Wrapper>
      <AgreementsWrapper sx={{ marginRight: '12.5px' }}>
        <Typography
          variant="bodyBoldMedium"
          color={color.gray}
          sx={{ fontSize: '20px', fontWeight: '700' }}
        >
          {t('agreements.title')}
        </Typography>
        <AgreementsTable dockList={dockList} setTargetDock={testFc} />
      </AgreementsWrapper>

      <AgreementsWrapper sx={{ marginLeft: '12.5px' }}>
        <AgreementsDockLayout targetDock={targetDock} />
      </AgreementsWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 16px 12.5px;
`;

const AgreementsWrapper = styled.div`
  width: 50%;
  margin: 0 12.5px;
`;

export default AgreementsLayout;
