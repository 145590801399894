import { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { InView } from 'react-intersection-observer';
import { useDispatch } from 'react-redux';

import { serialize } from 'object-to-formdata';
import styled from 'styled-components';

import { PDFFile } from '@root/assets/image/index';
import { Sprite } from '@root/assets/svg/index';
import { handleDispatchFetch } from '@root/helpers/handleDispatchFetch';
import productOperation from '@root/redux/product/product-operation';
import { NavigationButton } from '@root/ui/Button/NavigationButton/index';
import DocumentFileLoader from '@root/ui/Input/DocumentFileLoader/index';
import { FileLoader } from '@root/ui/Input/LogoFileLoader/index';

import { Typography, useTheme } from '@mui/material';

const DocumentsSection = ({ setNavSection, isEdit, item, windowWith }) => {
  const { color } = useTheme();
  const dispatch = useDispatch();
  const { t } = useTranslation(['product_details']);
  const [currentTab, setCurrentTab] = useState(0);
  const [tabs, setTabs] = useState([
    {
      label: t('documents.tabs.instructions'),
      key: 'instruction',
    },
//    {
//      label: t('documents.tabs.passports'),
//      key: 'passport',
//    },
//    {
//      label: t('documents.tabs.marketing'),
//      key: 'marketing',
//    },
  ]);
  const fileRef = useRef();
  const [file, setFile] = useState(null);

  const uploadDocument = val => {
    handleDispatchFetch(
      ({ onResolve, onReject }) =>
        dispatch(
          productOperation.uploadDocument({
            formData: serialize({
              document_type: tabs[currentTab].key,
              document: val,
            }),
            id: item.id,
            type: tabs[currentTab].key,
            onResolve,
            onReject,
          }),
        ),
      () => {},
      () => {},
    );
  };

  const onDocDelete = id => {
    handleDispatchFetch(
      ({ onResolve, onReject }) =>
        dispatch(
          productOperation.deleteDocument({
            documentId: id,
            type: tabs[currentTab].key,
            onResolve,
            onReject,
          }),
        ),
      () => {},
      () => {},
    );
  };

  return (
    <>
      {(isEdit ||
        item.documents?.instruction?.length ||
        item.documents?.passport?.length ||
        item.documents?.marketing?.length) && (
        <>
          <InView
            onChange={(inView, entry) => inView && setNavSection('documents')}
          />
          <ComponentWrap id="documents">
            <HeadingWrap color={color}>
              <Typography
                variant={'h2'}
                sx={{
                  color: color.gray,
                }}
              >
                {t('documents.alt_title')}
              </Typography>
              {/*
              <TabsWrap color={color}>
                {tabs.map((tab, index) => (
                  <NavigationButton
                    isActive={currentTab === index}
                    onClick={() => {
                      setCurrentTab(index);
                    }}
                    key={tab.key}
                  >
                    {tab.label}
                  </NavigationButton>
                ))}
              </TabsWrap>*/} <br />
            </HeadingWrap>

            {isEdit && (
              <FileLoaderWrap>
                <DocumentFileLoader
                  fileRef={fileRef}
                  setFile={val => {
                    setFile(val);
                    uploadDocument(val);
                  }}
                  file={file}
                  accept={'.pdf'}
                  type={['application/pdf']}
                />
              </FileLoaderWrap>
            )}

            {item.documents?.[tabs[currentTab].key]?.map(item => (
              <ListItem color={color} key={item}>
                <Typography
                  variant="bodyMedium"
                  color={color.gray}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <Image src={PDFFile} />
                  {item.original_name}
                  {isEdit && (
                    <DeleteSvg
                      stroke={color.gray}
                      onClick={() => {
                        onDocDelete(item.id);
                      }}
                    >
                      <use href={`${Sprite}#icon-cross`} />
                    </DeleteSvg>
                  )}
                </Typography>
                <a
                  href={item.filepath}
                  download
                  target="_blank"
                  rel="noreferrer"
                >
                  <svg width={16} height={16}>
                    <use href={`${Sprite}#icon_download`} />
                  </svg>
                  <Typography variant="bodyBoldSmall" color={color.gray_80}>
                    {t('documents.download')} 
                  </Typography>
                </a>
              </ListItem>
            ))}
          </ComponentWrap>
        </>
      )}
    </>
  );
};

export default DocumentsSection;
const ComponentWrap = styled.div`
  padding: 25px 0;
  background-color: #fff;
  margin-left: -20px;
  margin-right: -20px;
  width: 100%;

  @media(max-width: 1199px) {
    margin: 0;
  }
`;

const HeadingWrap = styled.div`
  padding: 0 103px;
  border-bottom: 1px solid ${({ color }) => color.line};

  @media(max-width: 1199px) {
    width: 100%;
    max-width: 84%;
    margin: 0 auto;
    padding: 0;
  }
  @media(max-width: 500px) {
    max-width: 95%;
  }
`;

const TabsWrap = styled.div`
  margin-top: 24px;
`;

const ListItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 103px;

  @media(max-width: 1199px) {
    width: 100%;
    max-width: 84%;
    margin: 0 auto;
    padding: 20px 0;

    span {
      overflow-wrap: anywhere;
    }
  }
  @media(max-width: 500px) {
    max-width: 95%;
  }

  & + & {
    border-top: 1px solid ${({ color }) => color.line};
  }

  a {
    display: flex;
    align-items: center;
  }
  svg {
    margin-right: 4px;
  }
`;

const DeleteSvg = styled.svg`
  display: inline;
  width: 24px;
  height: 24px;
  vertical-align: middle;
  cursor: pointer;
`;

const FileLoaderWrap = styled.div`
  padding: 0 103px 0;
`;

const Image = styled.img`
  width: 32px;
  height: 32px;
  display: inline;
  margin-right: 5px;
`;
