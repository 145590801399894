import { createSlice } from '@reduxjs/toolkit';
import textPagesOperations from './text-pages-operations';

const initialState = {
  loading: false,

  pagesList: [],

  currentTextPageLoading: false,
  currentTextPage: null,

  headerMenuPages: [],

  error: null
};

const textPages = createSlice({
  name: 'text-pages',
  initialState,
  reducers: {
    clearArticleData: state => {
      state.currentTextPage = initialState.currentTextPage;
    },
  },
  extraReducers: {
    //=====getTextPages=====//
    [textPagesOperations.getTextPages.pending]: (state, action) => {
      state.loading = true;
    },
    [textPagesOperations.getTextPages.fulfilled]: (state, { payload }) => {
      state.pagesList = payload;
      state.loading = false;
    },
    [textPagesOperations.getTextPages.rejected]: (state, { payload }) => {
      state.error = payload;
      state.loading = false;
    },

    //=====getTextPageBySlug=====//
    [textPagesOperations.getTextPageBySlug.pending]: (state, action) => {
      state.currentTextPageLoading = true;
      // state.currentTextPage = initialState.currentTextPage;
    },
    [textPagesOperations.getTextPageBySlug.fulfilled]: (state, { payload }) => {
      state.currentTextPage = payload;
      state.currentTextPageLoading = false;
    },
    [textPagesOperations.getTextPageBySlug.rejected]: (state, { payload }) => {
      state.error = payload;
      state.currentTextPageLoading = false;
    },

    //=====updateTextPage=====//
    [textPagesOperations.updateTextPage.pending]: (state, action) => {
      state.currentTextPageLoading = true;
    },
    [textPagesOperations.updateTextPage.fulfilled]: (state, { payload }) => {
      state.currentTextPage = payload;
      state.currentTextPageLoading = false;
    },
    [textPagesOperations.updateTextPage.rejected]: (state, { payload }) => {
      state.error = payload;
      state.currentTextPageLoading = false;
    },

    //=====getHeaderMenuTextPages=====//
    [textPagesOperations.getHeaderMenuTextPages.pending]: (state, action) => {
      // state.currentTextPageLoading = true;
      state.loading = true;
    },
    [textPagesOperations.getHeaderMenuTextPages.fulfilled]: (state, { payload }) => {
      state.headerMenuPages = payload;
      // state.currentTextPageLoading = false;
      state.loading = false;
    },
    [textPagesOperations.getHeaderMenuTextPages.rejected]: (state, { payload }) => {
      state.error = payload;
      // state.currentTextPageLoading = false;
      state.loading = false;
    },

  },
});

export const { clearArticleData } = textPages.actions

export default textPages.reducer;
