import {
  NavLink,
  useParams,
} from '../../../../node_modules/react-router-dom/dist/index';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import styled from 'styled-components';

import { SettingsPagesConf } from '@root/conf/SettingsPagesConf/index';

import { Typography } from '@mui/material/index';
import { useTheme } from '@mui/material/styles';

const SettingsLeftMenu = () => {
  const { lang } = useParams();
  const currentLang = lang ? `/${lang}` : '';
  const { slug } = useParams();
  const { color } = useTheme();
  const { t } = useTranslation(['admin']);

  return (
    <ComponentWrap colors={{ white: color.white, line: color.line_light }}>
      <GroupTitle colors={{ white: color.white, line: color.line_light }}>
        <Typography
          variant="bodyBoldMedium"
          component="p"
          color={color.text_color.main}
        >
          {t('admin:settings_tab.main_title')}
        </Typography>
      </GroupTitle>
      <GroupItems colors={{ white: color.white, line: color.line_light }}>
        {SettingsPagesConf(t).map(item => {
          return (
            <NavLink to={`${currentLang}/settings/${item.slug}`}>
              <LinkTitle
                colors={{ white: color.white, line: color.line_light }}
                active={slug === item.slug}
              >
                <Typography
                  variant={slug === item.slug ? 'bodyMedium' : 'bodyBoldMedium'}
                  component="p"
                  color={
                    slug === item.slug ? color.main : color.text_color.main
                  }
                >
                  {item.title}
                </Typography>
              </LinkTitle>
            </NavLink>
          );
        })}
      </GroupItems>
    </ComponentWrap>
  );
};

export default SettingsLeftMenu;

const ComponentWrap = styled.div`
  width: 240px;
  height: calc(100vh - 68px);

  background: ${prop => prop.colors.white};
  border-bottom: 1px solid ${prop => prop.colors.line};
`;

const GroupTitle = styled.div`
  padding: 8px 18px;
  border-bottom: 1px solid ${prop => prop.colors.line};
`;
const GroupItems = styled.div``;

const LinkTitle = styled.div`
  padding: 8px 24px;
  border-bottom: 1px solid ${prop => prop.colors.line};
`;
