import { useTranslation } from 'react-i18next';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import { NoSavedOrdersImage } from '@root/assets/image';
import { theme } from '@root/styles';
import { ButtonBase } from '@root/ui';

import { Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

export const NoSavedOrders = ({ goToCart }) => {
  const { t } = useTranslation(['cart'], { useSuspense: false });
  const { color } = useTheme();

  return (
    <NoSavedOrdersWrapper>
      <Image src={NoSavedOrdersImage} />
      <Typography
        variant="bodyBoldLarge"
        color={theme.color.gray}
        marginBottom="24px"
        marginTop="24px"
      >
        {t('cart:no_saved_orders')}
      </Typography>
      <Typography
        variant="bodyMedium"
        marginBottom="24px"
        color={color.gray_40}
        sx={{
          textAlign: 'center'
        }}
      >
        {t('cart:add_order_to_saved_templates')}
      </Typography>
      <ButtonBase onClick={() => goToCart()}>
        {t('cart:buttons.get_back_to_cart')}
      </ButtonBase>
    </NoSavedOrdersWrapper>
  );
};

const NoSavedOrdersWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 30px;
`;

const Image = styled.img`
  @media(max-width: 430px) {
    width: 100%;
  }
`;

NoSavedOrders.propTypes = {
  goToCart: PropTypes.func.isRequired,
};
