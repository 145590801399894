import { WidthContext } from '@root/providers/WidthProvider';

const hasWindow = typeof window !== 'undefined';

export const WidthChecker = ({children}) => {
  const getWindowDimensions = () => {
    const screenWidth = hasWindow ? window.innerWidth : null;
    const screenHeight = hasWindow ? window.innerHeight : null;
    return {
      screenWidth,
      screenHeight,
    };
  };

  return <WidthContext.Provider value={getWindowDimensions()}>{children}</WidthContext.Provider>
}
