import React from 'react';

import PropTypes from 'prop-types';

import { ButtonBase } from '@root/ui';

import { Typography, useTheme } from '@mui/material';

export const ListSectionsItem = ({ title, counter, onClick, active }) => {
  const { color } = useTheme();

  return (
    <ButtonBase
      sx={{
        height: 32,
        background: active ? color.main : color.white,
        '&:hover': {
          background: active ? color.main : 'transparent',
        },
        lineHeight: 1.2,
        color: active
          ? color.white
          : color.text_color.main === '#364A63'
          ? color.gray_80
          : color.text_color.secondary,
      }}
      onClick={onClick}
    >
      <Typography variant={active ? 'bodyBoldSmall' : 'bodySmall'}>
        {title} ({counter})
      </Typography>
    </ButtonBase>
  );
};

ListSectionsItem.propTypes = {
  title: PropTypes.string.isRequired,
  counter: PropTypes.number,
  onClick: PropTypes.func.isRequired,
  active: PropTypes.bool.isRequired,
};
