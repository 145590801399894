import PropTypes from 'prop-types';
import styled from 'styled-components';

import { useTheme } from '@mui/material/styles';

export const Link = ({ children, onClick, sx }) => {
  const { color } = useTheme();
  return <Component color={color}>{children}</Component>;
};

const Component = styled.span`
  display: flex;
  align-items: center;
  width: fit-content;

  color: ${prop => prop.color.gray_80};
  fill: ${prop => prop.color.gray_80};
  stroke: ${prop => prop.color.gray_80};
  cursor: pointer;

  &:hover {
    fill: ${prop => prop.color.main};
    stroke: ${prop => prop.color.main};
    color: ${prop => prop.color.main} !important;
  }

  &:active {
    fill: ${prop => prop.color.main_dark};
    stroke: ${prop => prop.color.main_dark};
    color: ${prop => prop.color.main_dark} !important;
  }
`;

Link.propTypes = {
  children: PropTypes.node,
  onClick: PropTypes.func.isRequired,
  sx: PropTypes.object,
};
