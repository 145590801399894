import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import styled from 'styled-components';

import ActivityAreaTableTop from '@root/components/ActivityArea/ActivityAreaTableTop/index';
import activityAreasOperation from '@root/redux/activity-areas/activity-areas-operation';
import activityAreasSelectors from '@root/redux/activity-areas/activity-areas-selectors';
import { ProgressLoader } from '@root/ui/Loader/ProgressLoader/index';

import { useTheme } from '@mui/material';

import ActivityAreasList from '../../../components/ActivityArea/ActivityAreasList/index';

const ActivityAreasTable = () => {
  const { color } = useTheme();
  const dispatch = useDispatch();
  const activityList = useSelector(activityAreasSelectors.getActivityList);
  const loading = useSelector(activityAreasSelectors.getActivityLoading);

  useEffect(() => {
    dispatch(activityAreasOperation.getActivityData({}));
  }, []);

  return (
    <MainWrap>
      <ActivityAreasTableWrap color={color}>
        <ActivityAreaTableTop />
        <ActivityAreasList list={activityList} />
        {loading && <ProgressLoader />}
      </ActivityAreasTableWrap>
    </MainWrap>
  );
};

export default ActivityAreasTable;

const MainWrap = styled.div`
  height: calc(100vh - 68px);
`;
const ActivityAreasTableWrap = styled.div`
  position: relative;
  width: 100%;
  max-width: 450px;
  background-color: #fff;
  border: 1px solid ${({ color }) => color.line};
  border-radius: 8px;
  margin: 20px auto 0;
  overflow: hidden;
`;
