import { createTheme } from '@mui/material/styles';

export const theme = createTheme({
  palette: {
    primary: {
      main: '#8094AE',
      dark: '#1996AA',
      light: '#1996AA',
    },
    secondary: {
      light: '#0066ff',
      main: '#1996AA',
      contrastText: '#ffcc00',
    },
    // Provide every color token (light, main, dark, and contrastText) when using
    // custom colors for props in Material UI's components.
    // Then you will be able to use it like this: `<Button color="custom">`
    // (For TypeScript, you need to add module augmentation for the `custom` value)
    outlineBtn: {
      light: '#ffa726',
      main: '#f57c00',
      dark: '#DBDEE9',
      contrastText: '#8094AE',
    },
    error: {
      main: '#BD2A2A',
      light: '#F0C9C9',
    },
    // Used by `getContrastText()` to maximize the contrast between
    // the background and the text.
    contrastThreshold: 3,
    // Used by the functions below to shift a color's luminance by approximately
    // two indexes within its tonal palette.
    // E.g., shift from Red Page500 to Red 300 or Red 700.
    tonalOffset: 0.2,
  },

  color: {
    white: '#ffffff',
    black: '#000000',
    gray: '#364A63',
    gray_90: '#bfbfbf',
    gray_80: '#526484',
    gray_70: '#d8d8d8',
    gray_60: '#8094AE',
    gray_40: '#C5C8D2',
    gray_30: '#E8EEF3',
    gray_20: '#F7F8FA',
    text_color: {
      main: '#364A63',
      secondary: '#364A63CC',
    },
    menu: 'linear-gradient(180deg, #38B0A7 0%, #36A0B0 27.32%, #368CB6 52.61%, #3677AD 76.87%, #3268B2 100%);',
    menu_icon_color: '#8094AE',
    bg_opacity: 'rgba(0,0,0,0.1)',
    menu_overlay: '#2B304250', //50 === 80%
    line: '#DBDEE9',
    line_light: '#EBEDF1',
    background: '#F5F6FA',
    green: '#6BC266',
    green_light: '#E6FCF6',
    orange: '#E39144',
    orange_light: '#F3D6AB',
    red: '#BD2A2A',
    dark_red: '#e31616',
    red_light: '#F0C9C9',
    main: '#1996AA',
    main_dark: `linear-gradient(0deg, rgba(0, 0, 0, 0.28) 0%, rgba(0, 0, 0, 0.28) 100%), #1996AA`,
    main_light: `linear-gradient(0deg, rgba(255, 255, 255, 0.92) 0%, rgba(255, 255, 255, 0.92) 100%), #1996AA`,
    main_extralight: `#eff3fd`,
  },

  borders: {
    tooltipGrayBorder: '1px solid #D9D9D9',
  },

  height: {
    large: '56px',
    medium: '38px',
    small: '28px',
  },
  padding: {
    baseButton: {
      large: '16px',
      medium: '11px 8px',
      small: '6px 12px',
    },
    outlineButton: '10px 8px',
  },

  gradient: {
    main: 'rgba(0, 0, 0, 0.08)',
  },

  typography: {
    //Title
    h1: {
      fontSize: '1.75rem',
      fontWeight: 700,
      lineHeight: 1.4,
    },
    h2: {
      fontSize: '1.25rem',
      fontWeight: 700,
      lineHeight: 1.1,
    },
    h3: {
      fontSize: '1rem',
      fontWeight: 700,
      lineHeight: 1.1,
    },
    //Body
    //bold
    bodyBoldLarge: {
      fontSize: '1rem',
      fontWeight: 700,
      lineHeight: 1.5,
      letterSpacing: 0,
    },
    bodyBoldMedium: {
      fontSize: '0.875rem',
      fontWeight: 700,
      lineHeight: 1.4,
      letterSpacing: 0,
    },
    bodyBoldSmall: {
      fontSize: '0.75rem',
      fontWeight: 700,
      lineHeight: 1.3,
      letterSpacing: 0,
    },
    bodyBoldExSmall: {
      fontSize: '0.625rem',
      fontWeight: 700,
      lineHeight: 1,
      letterSpacing: 0,
    },

    //normal
    bodyLarge: {
      fontSize: '1rem',
      lineHeight: 1.5,
      letterSpacing: 0,
      fontWeight: 400,
    },
    bodyMedium: {
      fontSize: '0.875rem',
      lineHeight: 1.4,
      letterSpacing: 0,
      fontWeight: 400,
    },
    bodySmall: {
      fontSize: '0.75rem',
      lineHeight: 1.3,
      letterSpacing: 0,
      fontWeight: 400,
    },
    bodyExSmall: {
      fontSize: '0.625rem',
      lineHeight: 1,
      letterSpacing: 0,
      fontWeight: 400,
    },

    //Uppercase
    uppercaseBoldSmall: {
      fontSize: '0.75rem',
      fontWeight: 700,
      lineHeight: 1.5,
      textTransform: 'uppercase',
    },
    uppercaseBoldExSmall: {
      fontSize: '0.625rem',
      fontWeight: 700,
      lineHeight: 1,
      textTransform: 'uppercase',
    },
    uppercaseNormalSmall: {
      fontSize: '0.75rem',
      lineHeight: 1.3,
      letterSpacing: 0,
      textTransform: 'uppercase',
    },
  },
});
