import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const getDockTypes = createAsyncThunk(
  'agreementsDock/getDockTypes',
  async thunkApi => {
    try {
      const res = await axios.get('treaty-files');

      return res.data.data;
    } catch (e) {
      return thunkApi.rejectWithValue(e.response);
    }
  },
);

const getDockById = createAsyncThunk(
  'agreementsDock/getDockById',
  async (id, thunkApi) => {
    try {
      const res = await axios.get(`treaty-files/${id}`);

      return res.data.data;
    } catch (e) {
      return thunkApi.rejectWithValue(e.response);
    }
  },
);

const createDock = createAsyncThunk(
  'agreementsDock/createDock',
  async (payload, thunkApi) => {
    try {
      const { data } = await axios.post(`treaty`, {
        ...payload,
      });

      return data.data;
    } catch (e) {
      return thunkApi.rejectWithValue(e.response);
    }
  },
);

const getDockList = createAsyncThunk(
  'agreementsDock/getDockList',
  async thunkApi => {
    try {
      const res = await axios.get('treaty');

      return res.data.data;
    } catch (e) {
      return thunkApi.rejectWithValue(e.response);
    }
  },
);

export default {
  getDockTypes,
  getDockById,
  createDock,
  getDockList,
};
