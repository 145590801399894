import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const getBusinessData = createAsyncThunk(
  'admin/getBusinessData',
  async ({ page, per_page, status }, thunkApi) => {
    try {
      const response = await axios.get(`admin/company`, {
        params: { page, per_page, status },
      });

      return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  },
);

const getCounterpartyData = createAsyncThunk(
  'admin/getCounterpartyData',
  async ({ page, per_page, params }, thunkApi) => {
    try {
      const response = await axios.get(`admin/counterparty`, {
        params: { page, per_page, ...params },
      });
      return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  },
);

const getPeopleData = createAsyncThunk(
  'admin/getPeople',
  async ({ page, per_page, q, status }, thunkApi) => {
    try {
      const response = await axios.get(`admin/user`, {
        params: { page, per_page, q, status },
        headers: {
          Accept: 'application/json',
        },
      });
      return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  },
);

const getAgreementsData = createAsyncThunk(
  'admin/getAgreements',
  async ({ page, per_page, q, signature }, thunkApi) => {
    try {
      const response = await axios.get(`user/documents/bills`, {
        params: {
          page,
          per_page,
          q,
          ...(signature != null ? { signature } : {}),
        },
        headers: {
          Accept: 'application/json',
        },
      });
      return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  },
);

const deleteAgreement = createAsyncThunk(
  'admin/deleteAgreement',
  async ({ page, per_page, q, id }, thunkApi) => {
    try {
      await axios.delete(`user/documents/bills/${id}`, {
        headers: {
          Accept: 'application/json',
        },
      });
      const querry = q ? { q } : {};
      const response = await axios.get(`user/documents/bills`, {
        params: { page, per_page, ...querry },
        headers: {
          Accept: 'application/json',
        },
      });
      return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  },
);

const getBusinessesSearch = createAsyncThunk(
  'admin/getBusinessesSearch',
  async ({ params, role, page, per_page }, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${role}/company`, {
        params: { page, per_page, ...params },
      });

      return { ...response.data, search: params.q };
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

const getCounterpartsSearch = createAsyncThunk(
  'admin/getCounterpartsSearch',
  async ({ search, role, page, per_page }, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${role}/counterparty`, {
        params: { q: search, page, per_page },
      });

      return { ...response.data, search };
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

const getPeopleSearch = createAsyncThunk(
  'admin/getPeopleSearch',
  async ({ params, role, page, per_page }, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${role}/user`, {
        params: { page, per_page, ...params },
      });

      return { ...response.data, search: params.q };
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

const getAgreementsSearch = createAsyncThunk(
  'admin/getAgreementsSearch',
  async ({ params, role, page, per_page }, { rejectWithValue }) => {
    try {
      const response = await axios.get(`user/documents/bills`, {
        params: { page, per_page, ...params },
      });

      return { ...response.data, search: params.q };
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

const updateAdminBusiness = createAsyncThunk(
  'admin/updateAdminCompanyStatus',
  async ({ id, payload, onResolve, onReject }, { rejectWithValue }) => {
    try {
      const { data } = await axios.post(`admin/company/${id}`, {
        ...payload,
      });

      if (onResolve) onResolve();

      return data.data;
    } catch (error) {
      if (onReject) onReject(error);
      return rejectWithValue(error);
    }
  },
);

const updateAdminCounterparty = createAsyncThunk(
  'admin/updateAdminCounterpartyStatus',
  async ({ id, payload, onResolve, onReject }, { rejectWithValue }) => {
    try {
      const { data } = await axios.post(`admin/counterparty/${id}`, {
        ...payload,
      });

      if (onResolve) onResolve();

      return data.data;
    } catch (error) {
      if (onReject) onReject(error);
      return rejectWithValue(error);
    }
  },
);

const updateAdminUser = createAsyncThunk(
  'admin/updateAdminUser',
  async ({ id, payload, onResolve, onReject }, { rejectWithValue }) => {
    try {
      const { data } = await axios.post(`admin/user/${id}`, {
        ...payload,
      });

      if (onResolve) onResolve();

      return data.data;
    } catch (error) {
      if (onReject) onReject(error);
      return rejectWithValue(error);
    }
  },
);

const getCompanyTypes = createAsyncThunk(
  'admin/getCompanyTypes',
  async ({ onResolve, onReject }, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(`company/company-types`, {});

      if (onResolve) onResolve();

      return data.data;
    } catch (error) {
      if (onReject) onReject(error);
      return rejectWithValue(error);
    }
  },
);

const getCompanysSelect = createAsyncThunk(
  'admin/getCompanysSelect',
  async ({ q, onResolve, onReject }, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(`admin/company/select`, {
        params: { q, },
      });

      if (onResolve) onResolve(data.data);

      return data.data;
    } catch (error) {
      if (onReject) onReject(error);
      return rejectWithValue(error);
    }
  },
);

export default {
  getBusinessData,
  getCounterpartyData,
  getPeopleData,
  getBusinessesSearch,
  getCounterpartsSearch,
  getPeopleSearch,
  updateAdminBusiness,
  updateAdminCounterparty
  ,
  updateAdminUser,
  getAgreementsData,
  getAgreementsSearch,
  deleteAgreement,
  getCompanyTypes,
  getCompanysSelect,
};
