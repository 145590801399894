import { createSlice } from '@reduxjs/toolkit';

import notificationsOperation from '@redux/notifications/notifications-operation';

const NOTIFICATIONS_TYPE_MAP = {
  base: 'baseNotifications',
  service: 'serviceNotifications',
};

const initialState = {
  notificationsMeta: {},
  hasNewNotifications: false,
  isBaseNotificationsLoading: false,
  isServiceNotificationsLoading: false,
  baseNotifications: [],
  serviceNotifications: [],
};

const notificationsSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    setHasNotViewedNotifications: (state, { payload }) => {
      state.hasNewNotifications = payload;
    },
    setAllNotificationsAsViewed: (state, { payload }) => {
      state.baseNotifications = [...state.baseNotifications].map(
        notification => ({ ...notification, readed: true }),
      );
    },
  },
  extraReducers: {
    //getNotifications//
    [notificationsOperation.getNotifications.pending]: (state, { payload }) => {
      state.isBaseNotificationsLoading = true;
    },
    [notificationsOperation.getNotifications.fulfilled]: (
      state,
      { payload },
    ) => {
      const { type, response } = payload;

      state.isBaseNotificationsLoading = false;
      if (response.meta.current_page === 1) {
        state[NOTIFICATIONS_TYPE_MAP[type]] = response.data;
      } else {
        state[NOTIFICATIONS_TYPE_MAP[type]] = [
          ...state[NOTIFICATIONS_TYPE_MAP[type]],
          ...response.data,
        ];
      }

      state.hasNewNotifications = response.has_new;
      state.notificationsMeta = response.meta;
    },
    [notificationsOperation.getNotifications.rejected]: (
      state,
      { payload },
    ) => {
      state.isBaseNotificationsLoading = false;
    },

    [notificationsOperation.markOneAsRead.pending]: (state, { payload }) => {},
    [notificationsOperation.markOneAsRead.fulfilled]: (state, { payload }) => {
      const { type, id, response } = payload;

      state[NOTIFICATIONS_TYPE_MAP[type]] = [
        ...state[NOTIFICATIONS_TYPE_MAP[type]],
      ].map(notification =>
        notification.id === id
          ? {
              ...notification,
              readed: true,
            }
          : notification,
      );
      state.hasNewNotifications = response.has_new;
    },
    [notificationsOperation.markOneAsRead.rejected]: (state, { payload }) => {},
  },
});

export const { setHasNotViewedNotifications, setAllNotificationsAsViewed } =
  notificationsSlice.actions;

export default notificationsSlice.reducer;
