import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Typography, useTheme } from '@mui/material';

export const StatusBase = ({ currentStatus, statuses = {} }) => {
  const { color } = useTheme();
  return (
    <StatusWrapper color={statuses[currentStatus]?.color}>
      <Typography color={color.gray} variant="bodySmall">
        {statuses[currentStatus]?.name}
      </Typography>
    </StatusWrapper>
  );
};

const StatusWrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 4px;

  &:before {
    content: '';
    display: inline-block;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: ${({ color }) => color};
  }
`;

StatusBase.propTypes = {
  currentStatus: PropTypes.string,
  statuses: PropTypes.object,
};
