import { Fragment, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';

import styled from 'styled-components';

import { Sprite } from '@root/assets/svg';
import { ProfileConf, paddingMap } from '@root/conf';
import { WidthContext } from '@root/providers/WidthProvider';
import { CustomScroll, OutlineButtonBase, ProgressLoader } from '@root/ui';

import userSelectors from '@redux/user/user-selectors';

import { Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

export const ProfileSettingsDetails = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { profileTab } = useParams();
  const { t } = useTranslation(['profile'], { useSuspense: false });
  const { screenWidth } = useContext(WidthContext);
  const { color } = useTheme();
  const permission = useSelector(userSelectors.getPermissions);
  const loginsLoading = useSelector(userSelectors.getIsUserLoginsLoading);

  const handleToggleModal = open => {
    setIsModalOpen(open);
  };

  return (
    <>
      <ProfileSettingsDetailsWrapper
        colors={{ white: color.white, line: color.line }}
      >
        {loginsLoading && <ProgressLoader />}
        <CustomScroll vertical={profileTab !== 'account-activity'}>
          <ProfileSettingsDetailsInner
            padding={paddingMap(screenWidth)[profileTab]}
          >
            <ProfileSettingsDetailsHeader>
              {ProfileConf(t).map(
                profile =>
                  profile.path === profileTab && (
                    <Fragment key={profile.path}>
                      {isModalOpen &&
                        profile.modal &&
                        profile.modal({
                          open: isModalOpen,
                          handleClose: () => handleToggleModal(false),
                        })}
                      <HeaderText>
                        <Typography color={color.gray} variant="h1">
                          {profile?.label}
                        </Typography>
                        <Typography
                          color={color.gray_80}
                          variant="bodyMedium"
                          component="p"
                          marginTop="8px"
                        >
                          {profile?.description}
                        </Typography>
                      </HeaderText>
                      {profile?.buttonText &&
                        permission[profile?.permission_key_add] && (
                          <OutlineButtonBase
                            onClick={() => handleToggleModal(true)}
                            sx={{
                              height: '36px',
                              width: '220px',
                              whiteSpace: 'nowrap',
                            }}
                          >
                            <Svg width="16" height="16">
                              <use href={`${Sprite}#icon-plus`}></use>
                            </Svg>

                            {profile?.buttonText}
                          </OutlineButtonBase>
                        )}
                    </Fragment>
                  ),
              )}
            </ProfileSettingsDetailsHeader>

            {ProfileConf(t).map(profile => (
              <Fragment key={profile.path + profile.value}>
                {profileTab === profile.path && profile.body()}
              </Fragment>
            ))}
          </ProfileSettingsDetailsInner>
        </CustomScroll>
      </ProfileSettingsDetailsWrapper>
    </>
  );
};

const ProfileSettingsDetailsWrapper = styled.div`
  position: relative;
  flex: 0 0 70%;
  background: ${props => props.colors.white};
  border: 1px solid ${props => props.colors.line};
  border-radius: 4px;
  height: calc(100vh - 171px);

  @media (max-width: 1024px) {
    flex: 0 0 100%;
    height: auto;
  }
`;

const ProfileSettingsDetailsInner = styled.div`
  padding: ${({ padding }) => padding};
`;

const ProfileSettingsDetailsHeader = styled.div`
  margin-bottom: 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 30px;

  @media (max-width: 768px) {
    flex-direction: column;
    justify-content: stretch;
    align-items: stretch;
    gap: 8px;
  }
`;

const HeaderText = styled.div``;

const Svg = styled.svg``;
