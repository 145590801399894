import { createSlice } from '@reduxjs/toolkit';

import {
  updateComparison,
  updateFavorite,
  updateNestedProductById,
  updateProducts,
} from '@root/helpers';

import cartOperation from '../cart/cart-operation';
import comparisonsOperation from '../comparisons/comparisons-operation';
import favoriteOperation from '../favorite/favorite-operation';
import homeOperation from '../home/home-operation';
import optionOperation from '../option/option-operation';
import productOperation from './product-operation';

const createFilterValuesData = filter => {
  return Object.keys(filter.values).map(key => {
    return { name: filter.values[key].name, value: filter.values[key].id };
  });
};

const initialState = {
  loading: false,
  sync1cLoaing: false,
  loadingItemPage: true,
  loadingEditPage: false,
  product: [],
  meta: {},
  categoryName: '',
  productById: {},
  productByEditIdData: null,
  tags: [],

  productFilterSettings: {
    active: [],
    list: {},
    listArray: [],
    dirty: false,
    currentList: [],
  },

  productPropsToEdit: [],
  productPropsDirty: false,
  productProps: {
    categorys: [],
    blocks: [],
  }
};

const productSlice = createSlice({
  name: 'product',
  initialState,
  reducers: {
    onClear(state, { payload }) {
      state.product = initialState.product;
      state.meta = initialState.meta;
      state.productById = initialState.productById;
      state.categoryName = initialState.categoryName;
      state.loadingItemPage = initialState.loadingItemPage;
      state.productFilterSettings = initialState.productFilterSettings;
    },
    onClearByEditIdData(state, { payload }) {
      state.productByEditIdData = initialState.productByEditIdData;
    },
    onClearProduct(state, { payload }) {
      state.product = initialState.product;
      state.meta = initialState.meta;
      state.categoryName = initialState.categoryName;
    },

    updateProductById(state, { payload }) {
      const { productId, addedToCardProducts } = payload;
      const currentCartProduct = addedToCardProducts.find(
        favoriteProduct => favoriteProduct?.id === productId,
      );

      const checkIsProductInCart = (key, defaultValue) =>
        currentCartProduct ? currentCartProduct[key] : defaultValue;

      state.productById = {
        ...state.productById,
        cart: checkIsProductInCart('cart', { amount: 0, quantity: 0 }),
        is_in_cart: checkIsProductInCart('is_in_cart', false),
      };
    },

    updateIsProductSavedById(state, { payload }) {
      state.productById = {
        ...state.productById,
        is_saved: !payload.is_saved,
      };
    },
    updateIsProductCompareById(state, { payload }) {
      state.productById = {
        ...state.productById,
        in_compare: !payload.in_compare,
      };
    },

    prepareCreateProduct(state, { payload }) {
      state.productById = {
        id: null,
        name: '',
        slug: '',
        model: '',
        brand: '',
        brand_id: '',
        description: '',
        image: '',
        images_quantity: '',
        sku: '',
        price: {
          price_uah_pdv: '',
          price_uah_no_pdv: '',
          price_uah_cash: '',
          price: '',
          price_no_cash: '',
        },
        personal_price: {
          price_uah_pdv: '',
          price_uah_no_pdv: '',
          price_uah_cash: '',
          price: '',
          price_no_cash: '',
        },
        prices: {
          price_uah_pdv: {
            product: '',
            personal: '',
          },
          price_uah_no_pdv: {
            product: '',
            personal: '',
          },
          price_uah_cash: {
            product: '',
            personal: '',
          },
          price: {
            product: '',
            personal: '',
          },
          price_no_cash: {
            product: '',
            personal: '',
          },
        },
        in_compare: false,
        is_in_cart: false,
        cart: {},
        is_saved: false,
        can_buy: {
          price_uah_pdv: true,
          price_uah_no_pdv: true,
          price_uah_cash: true,
          price: true,
          price_no_cash: true,
        },
        stock: 1,
        stock_max: 1,
        stock_percent: 100,
        url: '',
        warrany: null,
        warranty: null,
        warehouses: [],
        has_analogs: false,
        has_markdowns: false,
        is_hits: false,
        in_watchlist: false,
        has_guid: false,
        is_marketing: false,
        images: [],
        property_categories: [],
        related_products: [],
        analogue_products: [],
        markdown_products: [],
        markdown_parent: [],
        documents: {},
      };
    },

    updateFilterSettingsFilter(state, { payload }) {
      const filter = state.productFilterSettings.list[payload.value.value];
      state.productFilterSettings.currentList =
        state.productFilterSettings.currentList.map((item, index) =>
          index === payload.index
            ? {
              ...item,
              filterValue: payload.value,
              filter,
              propsArray: createFilterValuesData(filter),
              propsArrayValue: null,
            }
            : item,
        );
      state.productFilterSettings = {
        ...state.productFilterSettings,
        dirty: true,
      };
    },
    updateFilterSettingsProp(state, { payload }) {
      state.productFilterSettings.currentList =
        state.productFilterSettings.currentList.map((item, index) =>
          index === payload.index
            ? { ...item, propsArrayValue: payload.value }
            : item,
        );
      state.productFilterSettings = {
        ...state.productFilterSettings,
        dirty: true,
      };
    },
    addFilterSettingsItem(state, { payload }) {
      state.productFilterSettings.currentList = [
        ...state.productFilterSettings.currentList,
        {},
      ];
    },

    setProductPropsToEdit(state, { payload }) {
      state.productPropsToEdit = payload;
      state.productPropsDirty = true;
    },
    addPropsToEdit(state, { payload }) {
      state.productPropsToEdit = [...state.productPropsToEdit, { ...payload }];
      state.productPropsDirty = true;
    },
    editPropValue(state, { payload: { blockIdx, propIdx, value } }) {
      const newProps = [...state.productPropsToEdit];
      newProps[blockIdx].properties[propIdx].value = value;
      state.productPropsToEdit = newProps
      state.productPropsDirty = true;
    },
    deleteProp(state, { payload: { blockIdx, propIdx } }) {
      let newProps = [...state.productPropsToEdit];
      const block = newProps[blockIdx];
      if (block.properties.length > 1) {
        block.properties = block.properties.filter((_, index) => index !== propIdx);
        newProps[blockIdx] = block;
      } else {
        newProps = newProps.filter((_, index) => index !== blockIdx);
      }
      state.productPropsToEdit = newProps
      state.productPropsDirty = true;
    },
    togglePin(state, { payload: { blockIdx, propIdx } }) {
      const newProps = [...state.productPropsToEdit];
      const block = newProps[blockIdx];
      block.properties = block.properties.map((prop, index) => index === propIdx ? { ...prop, is_pinned: prop.is_pinned === true ? false : true } : prop);
      state.productPropsDirty = true;
    },
    clearBlockArray(state, { payload }) {
      state.productProps.blocks = [];
    },
    deleteProductPropsBlock(state, { payload: { blockIdx } }) {
      state.productPropsToEdit = state.productPropsToEdit.filter((_, index) => index !== blockIdx);
      state.productPropsDirty = true;
    },
    editProductBlockName(state, { payload: { blockIdx, value } }) {
      const newProps = [...state.productPropsToEdit];
      newProps[blockIdx].name = value;
      state.productPropsToEdit = newProps;
      state.productPropsDirty = true;
    }
  },
  extraReducers: {
    // ================================Global-Store-Listeners=============================//

    //====addBrands====//
    [optionOperation.addBrands.fulfilled]: (state, { payload }) => {
      const obj = payload.find(el => el.selected);

      state.productByEditIdData = {
        ...state.productByEditIdData,
        brand_id: obj.id,
      };
    },
    //====createHomeTopSale====//
    [homeOperation.createHomeTopSale.pending]: (state, { payload }) => {
      state.loading = true;
    },
    [homeOperation.createHomeTopSale.fulfilled]: (state, { payload }) => {
      state.loading = false;
    },
    [homeOperation.createHomeTopSale.rejected]: (state, { payload }) => {
      state.loading = false;
    },
    //====postProductToFavorite====//
    [favoriteOperation.postProductToFavorite.fulfilled]: (
      state,
      { payload },
    ) => {
      const { id } = payload;
      state.product = updateFavorite(state.product, id);
    },
    //====deleteProductFromSaved====//
    [favoriteOperation.deleteProductFromSaved.fulfilled]: (
      state,
      { payload },
    ) => {
      const { id } = payload;
      state.product = updateFavorite(state.product, id);
    },
    //====addToComparisons====//
    [comparisonsOperation.addToComparisons.fulfilled]: (state, { payload }) => {
      const { id } = payload;
      state.product = updateComparison(state.product, id);
    },
    //====removeFromComparisons====//
    [comparisonsOperation.removeFromComparisons.fulfilled]: (
      state,
      { payload },
    ) => {
      const { id } = payload;
      state.product = updateComparison(state.product, id);
    },
    //====addProductToCart====//
    [cartOperation.addProductToCart.fulfilled]: (state, { payload }) => {
      const { data, id } = payload;

      state.product = updateProducts(state.product, data.data);
      state.productById = updateNestedProductById(state?.productById, {
        products: data.data.products,
        productId: id,
        key: 'related_products',
      });
      state.productById = updateNestedProductById(state?.productById, {
        products: data.data.products,
        productId: id,
        key: 'analogue_products',
      });
      state.productById = updateNestedProductById(state?.productById, {
        products: data.data.products,
        productId: id,
        key: 'markdown_products',
      });
    },
    //====putOneToCart====//
    [cartOperation.putOneToCart.fulfilled]: (state, { payload }) => {
      const { data, id } = payload;

      state.product = updateProducts(state.product, data.data);
      state.productById = updateNestedProductById(state?.productById, {
        products: data.data.products,
        productId: id,
        key: 'related_products',
      });
      state.productById = updateNestedProductById(state?.productById, {
        products: data.data.products,
        productId: id,
        key: 'analogue_products',
      });
      state.productById = updateNestedProductById(state?.productById, {
        products: data.data.products,
        productId: id,
        key: 'markdown_products',
      });
    },
    //====deleteOneFromCart====//
    [cartOperation.deleteOneFromCart.fulfilled]: (state, { payload }) => {
      const { data, id } = payload;

      state.product = updateProducts(state.product, data.data);
      state.productById = updateNestedProductById(state?.productById, {
        products: data.data.products,
        productId: id,
        key: 'related_products',
      });
      state.productById = updateNestedProductById(state?.productById, {
        products: data.data.products,
        productId: id,
        key: 'analogue_products',
      });
      state.productById = updateNestedProductById(state?.productById, {
        products: data.data.products,
        productId: id,
        key: 'markdown_products',
      });
    },
    // ================================End-Global-Store-Listeners=============================//
    //====getProduct====//
    [productOperation.getProduct.pending]: (state, action) => {
      state.loading = true;
    },
    [productOperation.getProduct.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.product = payload.data;
      state.meta = payload.meta;
      state.categoryName = payload.category;
    },
    [productOperation.getProduct.rejected]: (state, { payload }) => {
      state.loading = false;
    },
    //====getProductById====//
    [productOperation.getProductById.pending]: (state, action) => { },
    [productOperation.getProductById.fulfilled]: (state, { payload }) => {
      state.loadingItemPage = false;
      state.productById = payload.data;
    },
    [productOperation.getProductById.rejected]: (state, { payload }) => {
      state.loadingItemPage = false;
    },

    //====getProductByEditId====//
    [productOperation.getProductByEditId.pending]: (state, action) => {
      state.loadingEditPage = true;
    },
    [productOperation.getProductByEditId.fulfilled]: (state, { payload }) => {
      state.productByEditIdData = payload.data.product;
      state.loadingEditPage = false;
      state.loading = false;
    },
    [productOperation.getProductByEditId.rejected]: (state, { payload }) => {
      state.loadingEditPage = false;
      state.loading = false;
    },

    //====editProductByEditId====//
    [productOperation.editProductByEditId.pending]: (state, action) => {
      state.loading = true;
    },
    [productOperation.editProductByEditId.fulfilled]: (state, { payload }) => {
      state.productById = payload;
      state.loading = false;
    },
    [productOperation.editProductByEditId.rejected]: (state, { payload }) => {
      state.loading = false;
    },

    // ---- createProduct ----- //
    [productOperation.createProduct.pending]: (state, { payload }) => {
      state.loading = true;
    },
    [productOperation.createProduct.fulfilled]: (state, { payload }) => {
      state.loading = false;
    },
    [productOperation.createProduct.rejected]: (state, { payload }) => {
      state.loading = false;
    },

    // ---- getProductsBySearch ----- //
    [productOperation.getProductsBySearch.pending]: (state, { payload }) => {
      state.loading = true;
    },
    [productOperation.getProductsBySearch.fulfilled]: (state, { payload }) => {
      state.product = payload.data;
      state.meta = payload.meta;
      state.loading = false;
    },
    [productOperation.getProductsBySearch.rejected]: (state, { payload }) => {
      state.loading = false;
    },

    // ---- addAnalogueProductsById ----- //
    [productOperation.addAnalogueProductsById.pending]: (
      state,
      { payload },
    ) => {
      state.loading = true;
    },
    [productOperation.addAnalogueProductsById.fulfilled]: (
      state,
      { payload },
    ) => {
      state.productById = payload.data;
      state.loading = false;
    },
    [productOperation.addAnalogueProductsById.rejected]: (
      state,
      { payload },
    ) => {
      state.loading = false;
    },

    // ---- addRelatedProductsById ----- //
    [productOperation.addRelatedProductsById.pending]: (state, { payload }) => {
      state.loading = true;
    },
    [productOperation.addRelatedProductsById.fulfilled]: (
      state,
      { payload },
    ) => {
      state.productById = payload.data;
      state.loading = false;
    },
    [productOperation.addRelatedProductsById.rejected]: (
      state,
      { payload },
    ) => {
      state.loading = false;
    },

    // ---- toggleWatchlist ----- //
    [productOperation.toggleWatchlist.pending]: (state, { payload }) => {
      state.loading = true;
    },
    [productOperation.toggleWatchlist.fulfilled]: (state, { payload }) => {
      if (state.productById.id === payload.currentId) {
        state.productById = {
          ...state.productById,
          in_watchlist: !state.productById.in_watchlist,
        };
      }
      state.product = state.product.map(item =>
        item.id === payload.currentId
          ? { ...item, in_watchlist: !item.in_watchlist }
          : { ...item },
      );
      state.loading = false;
    },
    [productOperation.toggleWatchlist.rejected]: (state, { payload }) => {
      state.loading = false;
    },

    //====getWatchlist====//
    [productOperation.getWatchlist.pending]: (state, action) => {
      state.loading = true;
    },
    [productOperation.getWatchlist.fulfilled]: (state, { payload }) => {
      state.product = payload.data;
      state.meta = payload.meta;
      state.categoryName = 'watchlist';
      state.loading = false;
    },
    [productOperation.getWatchlist.rejected]: (state, { payload }) => {
      state.loading = false;
    },
    //====uploadDocument====//
    [productOperation.uploadDocument.pending]: (state, action) => {
      state.loading = true;
    },
    [productOperation.uploadDocument.fulfilled]: (state, { payload }) => {
      let newDocs = { ...state.productById.documents };
      newDocs[payload.type] = newDocs[payload.type]
        ? [...newDocs[payload.type], { ...payload.data }]
        : [{ ...payload.data }];
      state.productById = { ...state.productById, documents: newDocs };
    },
    [productOperation.uploadDocument.rejected]: (state, { payload }) => {
      state.loading = false;
    },
    //====deleteDocument====//
    [productOperation.deleteDocument.pending]: (state, action) => {
      state.loading = true;
    },
    [productOperation.deleteDocument.fulfilled]: (state, { payload }) => {
      let newDocs = { ...state.productById.documents };
      newDocs[payload.type] = newDocs[payload.type].filter(item => item.id !== payload.documentId)
      state.productById = { ...state.productById, documents: newDocs };
    },
    [productOperation.deleteDocument.rejected]: (state, { payload }) => {
      state.loading = false;
    },

    //====getProductFilters====//
    [productOperation.getProductFilters.pending]: (state, action) => {
      state.loading = true;
    },
    [productOperation.getProductFilters.fulfilled]: (
      state,
      { payload: { data, onlyUpdate } },
    ) => {
      state.productFilterSettings.active = data.active;
      state.productFilterSettings.list = data.list;
      state.productFilterSettings.listArray = Object.keys(data.list).map(
        key => {
          return {
            name: data.list[key].name,
            value: data.list[key].id,
          };
        },
      );
      if (!onlyUpdate) {
        if (data.active.length) {
          state.productFilterSettings.currentList = data.active.filter(item => data.list[item.filter_id]).map(
            activeItem => {
              const propsArray = createFilterValuesData(
                data.list[activeItem.filter_id],
              );
              return {
                ...activeItem,
                filter: data.list[activeItem.filter_id],
                filterValue: state.productFilterSettings.listArray.find(
                  item => item.value == activeItem.filter_id,
                ),
                propsArray,
                propsArrayValue: propsArray.find(
                  item => item.value === activeItem.filter_value_id,
                ),
              };
            },
          );
        } else {
          state.productFilterSettings.currentList = [{}]
        }
      }
      state.loading = true;
    },
    [productOperation.getProductFilters.rejected]: (state, { payload }) => {
      state.loading = false;
    },
    //====addProductImage====//
    [productOperation.addProductImage.pending]: (state, action) => {
      state.loading = true;
    },
    [productOperation.addProductImage.fulfilled]: (state, { payload }) => {
      state.productById = { ...state.productById, images: payload };
    },
    [productOperation.addProductImage.rejected]: (state, { payload }) => {
      state.loading = false;
    },
    [productOperation.getTags.fulfilled]: (state, { payload }) => {
      state.tags = [...payload];
    },
    //====deleteProductImage====//
    [productOperation.deleteProductImage.pending]: (state, action) => {
      state.loading = true;
    },
    [productOperation.deleteProductImage.fulfilled]: (state, { payload }) => {
      state.productById = { ...state.productById, images: payload };
    },
    [productOperation.deleteProductImage.rejected]: (state, { payload }) => {
      state.loading = false;
    },

    //====getProductPropsToEdit====//
    [productOperation.getProductPropsToEdit.pending]: (state, action) => {
      state.loading = true;
    },
    [productOperation.getProductPropsToEdit.fulfilled]: (state, { payload }) => {
      state.productPropsToEdit = payload;
      state.productPropsDirty = false;
      state.loading = false;
    },
    [productOperation.getProductPropsToEdit.rejected]: (state, { payload }) => {
      state.loading = false;
    },
    //====getCategorysToEdit====//
    [productOperation.getCategorysToEdit.pending]: (state, action) => {
      state.loading = true;
    },
    [productOperation.getCategorysToEdit.fulfilled]: (state, { payload }) => {
      const cats = payload;
      const newCats = [];
      cats.forEach(cat => {
        cat.children.forEach(child => {
          newCats.push(child);
        });
      });
      state.productProps.categorys = newCats;
      state.loading = false;
    },
    [productOperation.getCategorysToEdit.rejected]: (state, { payload }) => {
      state.loading = false;
    },
    //====getBlocksByCategoryToEdit====//
    [productOperation.getBlocksByCategoryToEdit.pending]: (state, action) => {
      state.loading = true;
    },
    [productOperation.getBlocksByCategoryToEdit.fulfilled]: (state, { payload }) => {

      state.productProps.blocks = payload;
      state.loading = false;
    },
    [productOperation.getBlocksByCategoryToEdit.rejected]: (state, { payload }) => {
      state.loading = false;
    },
    //====storePropducProps====//
    [productOperation.storePropducProps.pending]: (state, action) => {
      state.loading = true;
    },
    [productOperation.storePropducProps.fulfilled]: (state, { payload }) => {
      // state.productById = {
      //   ...state.productById,
      //   property_categories: payload
      // };
      state.loading = false;
      state.productPropsDirty = false;
    },
    [productOperation.storePropducProps.rejected]: (state, { payload }) => {
      state.loading = false;
    },

    //====syncProductWith1C====//
    [productOperation.syncProductWith1C.pending]: (state, action) => {
      state.sync1cLoaing = true;
    },
    [productOperation.syncProductWith1C.fulfilled]: (state, { payload }) => {
      state.productById = { ...payload.data };
      state.sync1cLoaing = false;
    },
    [productOperation.syncProductWith1C.rejected]: (state, { payload }) => {
      state.sync1cLoaing = false;
    },
  },
});

export const {
  updateProductById,
  updateIsProductSavedById,
  updateIsProductCompareById,
  onClear,
  onClearProduct,
  onClearByEditIdData,
  prepareCreateProduct,
  updateFilterSettingsFilter,
  updateFilterSettingsProp,
  addFilterSettingsItem,
  addPropsToEdit,
  editPropValue,
  deleteProp,
  togglePin,
  setProductPropsToEdit,
  clearBlockArray,
  deleteProductPropsBlock,
  editProductBlockName,
} = productSlice.actions;

export default productSlice.reducer;
