import styled from 'styled-components';

import { useTheme } from '@mui/material';

const TextPageHtmlWrap = ({ content }) => {
  const { color } = useTheme();
  return (
    <HtmlContent
      color={color}
      dangerouslySetInnerHTML={{
        __html: content,
      }}
    />
  );
};

export default TextPageHtmlWrap;

const HtmlContent = styled.div`
  * {
    color: ${({ color }) => color.gray_80} !important;
    font-family: Roboto, sans-serif !important;
  }
  *:nth-child(1) {
    margin-top: 0;
  }
  div,
  p {
    font-style: normal;
    line-height: 150%;

    color: var(--text-gray-80, #526484);
    font-size: 16px;
    font-weight: 400;
  }
  li {
    font-style: normal;
    line-height: 150%;

    color: var(--text-gray-80, #526484);
    font-size: 16px;
    font-weight: 400;
  }

  a {
    color: ${({ color }) => color.main} !important;
    text-decoration: underline;
  }

  img {
    display: inline-block;
    width: auto;
    height: auto;
    max-width: 100%;
  }

  ul,
  ol {
    list-style: revert;
    list-style-position: inside;

    padding-left: 0;
  }

  strong {
    font-family: Roboto, sans-serif !important;
    font-weight: revert !important;
  }

  em {
    font-family: Roboto, sans-serif !important;
    font-weight: revert !important;
  }

  ins {
    font-family: Roboto, sans-serif !important;
    font-weight: revert !important;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    /* font-family: Roboto, sans-serif !important;
    font-weight: revert !important; */
    color: var(--text-gray-80, #526484);
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 110%; /* 22px */
  }

  img {
    width: 100%;
    display: block;
    margin: 24px 0;
  }

  .textPageLinkBase {
    background-color: ${({ color }) => color.main};
    color: ${({ color }) => color.white} !important;
    padding: 9px 12px;
    border-radius: 4px;
    margin: 10px 0;
    transition: 0.3s ease;
    display: inline-block;
    text-decoration: none;
    &:hover {
      box-shadow: 0px 4px 10px 0px rgba(57, 99, 225, 0.28);
    }
  }
  .textPageLinkOutline {
    background-color: ${({ color }) => color.white};
    color: ${({ color }) => color.gray_60};
    border: 1px solid ${({ color }) => color.line};
    padding: 9px 12px;
    border-radius: 4px;
    margin: 10px 0;
    transition: 0.3s ease;
    display: inline-block;
    text-decoration: none;
    &:hover {
      box-shadow: 0px 4px 10px 0px rgba(57, 99, 225, 0.28);
      color: ${({ color }) => color.gray_80};
    }
  }

  table {
    border-collapse: collapse;
    width: 100%;
    display: table !important;
    tbody {
      tr {
      }
      tr:first-child {
        background: #eaeaea;
        font-weight: 700;
      }
      tr:not(:first-child):hover {
        background: #f4f4f4;
      }
      td,
      th {
        padding: 12px 22px;
      }
    }
  }
`;
