import { useTranslation } from 'react-i18next';

import styled from 'styled-components';

import { EmptySearch } from '@root/assets/image';

import { Typography, useTheme } from '@mui/material';

export const EmptySearchLogo = ({ text }) => {
  const { color } = useTheme();
  const { t } = useTranslation(['search'], { useSuspense: false });
  return (
    <>
      <EmptySearchWrapper>
        <EmptySearchImage src={EmptySearch} alt={'Magnifying glass'} />
        <Typography
          sx={{ marginTop: '18px', textAlign: 'center' }}
          variant={'bodyBoldSmall'}
          color={color.gray_60}
        >
          {text || t('search:empty_search_res')}
        </Typography>
      </EmptySearchWrapper>
    </>
  );
};

const EmptySearchImage = styled.img`
  width: 97px;
`;

const EmptySearchWrapper = styled.div`
  display: flex;
  height: 100%;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  border-radius: 0px 0px 4px 4px;
  padding: 50px;
`;
