import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import styled from 'styled-components';

import { EditModeInput } from '@root/components/EditModeInput/index';
import { categoryList } from '@root/conf/FilterConf/index';
import categoryOperation from '@root/redux/category/category-operation';
import categorySelectors from '@root/redux/category/category-selectors';
import productSelectors from '@root/redux/product/product-selectors';

import { Typography, useTheme } from '@mui/material';

const CategorySettings = ({ isEdit, editData, handleLangData }) => {
  const { t } = useTranslation(['product_details']);
  const { color } = useTheme();
  const dispatch = useDispatch();
  const category = useSelector(categorySelectors.getCategoryListToEdit);
  const loadingEdit = useSelector(productSelectors.getLoadingEditPage);

  useEffect(() => {
    dispatch(categoryOperation.getCategoryListToAdmin({}));
  }, []);

  return (
    <>
      {isEdit && (
        <ComponentWrap>
          <Typography variant="h2" color={color.gray}>
            {t('category.title')}
          </Typography>
          <InputWrap>
            <EditModeInput
              sx={{ maxWidth: 'unset' }}
              isLoading={loadingEdit}
              isEdit={isEdit}
              type="multipleSelect"
              conf={{
                onChange: handleLangData,
                value: editData?.categories,
                error: {},
                key: 'categories',
                placeholder: t(
                  'product_details:placeholder.other_category_of_product',
                ),
                options: categoryList(category),
              }}
            />
          </InputWrap>
        </ComponentWrap>
      )}
    </>
  );
};

export default CategorySettings;

const ComponentWrap = styled.div`
  padding: 34px 103px;
  background-color: #fff;
  margin-left: -20px;
  margin-right: -20px;
`;

const InputWrap = styled.div`
  margin-top: 8px;
`;
