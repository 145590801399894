export const ServiceOrderWidgetConf = t => [
  {
    label: t('order_service:conf.date'),
    slug: 'date',
    style: { width: '20%' },
  },
  {
    label: t('order_service:conf.order_number'),
    slug: 'order_number',
    style: { width: '15%' },
  },
  {
    label: t('order_service:conf.device'),
    slug: 'device',
    style: { width: '25%' },
  },
  {
    label: t('order_service:conf.serial_number'),
    slug: 'serial_number',
    style: { width: '20%' },
  },
  {
    label: t('order_service:conf.warranty_payments'),
    slug: 'warranty_payments',
    style: { width: '20%' },
  },
  {
    label: t('order_service:conf.status'),
    slug: 'status',
    style: { width: '20%' },
  },
];
