import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

import operationAuth from '@redux/auth/auth-operation';

axios.defaults.baseURL = process.env.REACT_APP_API_URL_DEV;
const token = {
    set(token) {
        axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    },
    unset() {
        axios.defaults.headers.common['Authorization'] = ``;
    },
};

const signIn = createAsyncThunk(
    'auth/signIn',
    async ({ data, onResolve, onReject }, thunkApi) => {
        try {
            const response = await axios.post('/auth/sign-in', data);
            if (!response.data.data?.ga_is_active) {
                token.set(response.data.data.token);
                thunkApi.dispatch(
                    operationAuth.getUser({ signInToken: response.data.data.token }),
                );
            }
            if (onResolve) onResolve(response.data.data);

            return response.data.data;
        } catch (error) {
            if (onReject) onReject(error.response.data.message);
            return thunkApi.rejectWithValue(error);
        }
    },
);

const authByToken = createAsyncThunk(
    'auth/authByToken',
    async ({ tokenUser, onResolve, onReject }, thunkApi) => {
        try {
            const { data } = await axios.post(`/auth/by-token?token=${tokenUser}`);
            token.set(data.data.token);
            if (onResolve) onResolve();
            thunkApi.dispatch(
                operationAuth.getUser({ signInToken: data.data.token }),
            );
            return data.data;
        } catch (error) {
            if (onReject) onReject(error.response.data);
            return thunkApi.rejectWithValue(error?.response?.data);
        }
    },
);

const authNewUserByToken = createAsyncThunk(
    'auth/authNewUserByToken',
    async ({ tokenUser, onResolve, onReject }, thunkApi) => {
        try {
            const { data } = await axios.get(`/employees/token?token=${tokenUser}`);
            if (onResolve) onResolve(data);
            return { data: data.data, token: tokenUser };
        } catch (error) {
            if (onReject) onReject(error.response.data);
            return thunkApi.rejectWithValue(error?.response?.data);
        }
    },
);

const signUp = createAsyncThunk(
    'auth/signUp',
    async ({ email, password, onResolve, onReject }, { rejectWithValue }) => {
        try {
            const { data } = await axios.post('/auth/sign-up-first-step', {
                email,
                password,
            });
            token.set(data.data.token);
            return data.data;
        } catch (error) {
            if (onReject) onReject(error.response.data.message);
            return rejectWithValue(error);
        }
    },
);

const signUpStepTwo = createAsyncThunk(
    'auth/signUpStepTwo',
    async ({ formData, onResolve, onReject }, { rejectWithValue }) => {
        try {
            const { data } = await axios.post('/auth/sign-up-second-step', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            if (onResolve) onResolve();
            return data.data;
        } catch (error) {
            if (onReject) onReject(error.response.data.message);
            return rejectWithValue(error);
        }
    },
);

const signUpStepThierd = createAsyncThunk(
    'auth/signUpStepThierd',
    async ({ formData, onResolve, onReject }, { rejectWithValue }) => {
        try {
            const { data } = await axios.post('/auth/sign-up-third-step', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            if (onResolve) onResolve();
            return data.data;
        } catch (error) {
            if (onReject) onReject(error.response.data.message);
            return rejectWithValue(error);
        }
    },
);

const signUpStepFours = createAsyncThunk(
    'auth/signUpStepFours',
    async ({ formData, onResolve, onReject }, { rejectWithValue }) => {
        try {
            const { data } = await axios.post('/auth/sign-up-fourth-step', formData);
            if (onResolve) onResolve();
            return data.data;
        } catch (error) {
            if (onReject) onReject(error.response.data.message);
            return rejectWithValue(error);
        }
    },
);

const signUpStepFifth = createAsyncThunk(
    'auth/signUpStepFifth',
    async ({ formData, onResolve, onReject }, { rejectWithValue }) => {
        try {
            const { data } = await axios.post('/auth/sign-up-fifth-step', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            if (onResolve) onResolve();
            return data.data;
        } catch (error) {
            if (onReject) onReject(error.response.data.message);
            return rejectWithValue(error);
        }
    },
);

const signUpStepSixth = createAsyncThunk(
    'auth/signUpStepSixth',
    async ({ navigate }, { rejectWithValue }) => {
        try {
            const { data } = await axios.post('/auth/sign-up-sixth-step', {
                confirm: true,
            });
            navigate();
            return data.data;
        } catch (error) {
            return rejectWithValue(error);
        }
    },
);

const getUser = createAsyncThunk(
    'auth/getUser',
    async ({ signInToken }, thunkApi) => {
        const state = thunkApi.getState();
        const persistedToken = state.auth.token || signInToken;
        if (persistedToken === null) {
            token.unset();
            return thunkApi.rejectWithValue(state);
        } else {
            try {
                const { data } = await axios.get('/user', {
                    headers: { Authorization: `Bearer ${persistedToken}` },
                });
                token.set(persistedToken);
                return { persistedToken, user: data.data };
            } catch (error) {
                if (error.response.status !== 503) {
                    token.unset();
                }
                return thunkApi.rejectWithValue(state);
            }
        }
    },
);

const logOut = createAsyncThunk('auth/logOut', async (_, thunkApi) => {
    try {
        const state = thunkApi.getState();
        const persistedToken = state.auth.token;
        const { data } = await axios.post('/auth/logout', {
            token: persistedToken,
        });

        return data.data;
    } catch (error) {
        return thunkApi.rejectWithValue(error);
    }
});

const updateOldUserData = createAsyncThunk(
    'auth/updateOldUserData',
    async ({ data }, thunkApi) => {
        try {
            const response = await axios.post('/auth/update', data);
            token.set(response.data.data.token);
            thunkApi.dispatch(
                operationAuth.getUser({ signInToken: response.data.data.token }),
            );
            return response.data.data;
        } catch (error) {
            return thunkApi.rejectWithValue(error);
        }
    },
);

const passwordReset = createAsyncThunk(
    'auth/passwordReset',
    async ({ data, onResolve, onReject }, thunkApi) => {
        try {
            const response = await axios.post('/user/reset-password', data);
            if (onResolve) onResolve(response.data);
            return {};
        } catch (error) {
            if (onReject) onReject(error.response.data);
            return thunkApi.rejectWithValue(error);
        }
    },
);

const passwordResetComfirm = createAsyncThunk(
    'auth/passwordResetComfirm',
    async ({ data, onResolve, onReject }, thunkApi) => {
        try {
            const response = await axios.post('/user/reset-password/confirm', data, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': null,
                },
            });
            if (onResolve) onResolve(response.data);
            return {};
        } catch (error) {
            if (onReject) onReject(error.response.data);
            return thunkApi.rejectWithValue(error);
        }
    },
);

const getSocialSignup = createAsyncThunk(
    'auth/getSocialSignup',
    async ({ driver, onResolve, onReject }, thunkApi) => {
        try {
            const response = await axios.get(`/auth/social/sign-in/${driver}`);

            if (onResolve) onResolve(response.data?.data?.url);

            return {};
        } catch (error) {
            if (onReject) onReject(error.response.data);
            return thunkApi.rejectWithValue(error);
        }
    },
);

const getSocialCallback = createAsyncThunk(
    'auth/getSocialCallback',
    async ({ driver, search, onResolve, onReject }, thunkApi) => {
        try {
            const response = await axios.get(`/auth/social/callback/${driver}${search}`);

            if (onResolve) onResolve(response.data);

            return {};
        } catch (error) {
            if (onReject) onReject(error.response.data);
            return thunkApi.rejectWithValue(error);
        }
    },
);

const getServerStatus = createAsyncThunk(
    'auth/getServerStatus',
    async ({ onResolve, onReject }, thunkApi) => {
        try {
            const response = await axios.get('/errors/200');
            if (onResolve) onResolve(response);

            return {};
        } catch (error) {
            if (onReject) onReject(error.response.status);
            return thunkApi.rejectWithValue(error);
        }
    },
);

export default {
    signIn,
    getUser,
    logOut,
    signUp,
    signUpStepTwo,
    signUpStepThierd,
    signUpStepFours,
    signUpStepFifth,
    signUpStepSixth,
    authByToken,
    updateOldUserData,
    passwordReset,
    passwordResetComfirm,
    authNewUserByToken,
    getSocialSignup,
    getSocialCallback,
    getServerStatus,
};
