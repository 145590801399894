import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import styled from 'styled-components';

import { ConfirmSelection, HeaderDefault, SavedGoods } from '@root/components';
import { ModalBase } from '@root/ui';

import modalWatcherSelectors from '@redux/modal-watcher/modal-watcher-selectors';
import { clearModalState } from '@redux/modal-watcher/modal-watcher-slice';
import notificationWatcherSelectors from '@redux/notification-watcher/notification-watcher-selectors';
import { setOpenNotification } from '@redux/notification-watcher/notification-watcher-slice';

import { Typography, useTheme } from '@mui/material';

export const ModalWatcher = () => {
  const [favoritesChosedArr, setFavoritesChosedArr] = useState([]);
  const { color } = useTheme();
  const { t } = useTranslation(['common'], { useSuspense: false });
  const notificationConfig = useSelector(
    notificationWatcherSelectors.configuration,
  );
  const conf = useSelector(modalWatcherSelectors.modalConfiguration);
  const dispatch = useDispatch();

  const closeModal = () => {
    dispatch(clearModalState());
  };

  const callbackAfterAddToFavoriteList = data => {
    dispatch(
      setOpenNotification({
        ...notificationConfig,
        open: conf.notification_open,
        data: {
          favoriteList: favoritesChosedArr,
          favoriteNewListItem: data ? data : {},
        },
      }),
    );

    closeModal();

    if (typeof conf.reqAfterClose === 'function') {
      conf.reqAfterClose();
    }
  };
  const renderModalBody = () => {
    switch (conf.key) {
      case 'favorite':
        return (
          <SavedGoods
            productId={conf.id}
            callbackAfterAddToFavoriteList={callbackAfterAddToFavoriteList}
            favoritesChosedArr={favoritesChosedArr}
            setFavoritesChosedArr={setFavoritesChosedArr}
          />
        );
      case 'confirm':
        return (
          <ConfirmSelection
            discardChanges={closeModal}
            callbackAfterApplyChanges={() => conf.callback(conf.dataForConfirm)}
          />
        );
      default:
        return <div></div>;
    }
  };

  return (
    conf.open && (
      <ModalBase
        open={conf.open}
        handleClose={!conf.inactiveBackdrop && closeModal}
        modalHeader={
          !conf.isHiddenHeader ? (
            <HeaderDefault
              text={t('common:buttons.save')}
              onClose={closeModal}
            />
          ) : (
            ''
          )
        }
      >
        {renderModalBody()}
      </ModalBase>
    )
  );
};

const HeaderWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  padding-bottom: 15px;
`;
