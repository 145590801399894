import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import on from '../../../node_modules/socket.io-client/lib/on';

const getMutualSettlements = createAsyncThunk(
  'mutualSettlements/getMutualSettlements',
  async ({ date_from, date_to, type, document_type }, { rejectWithValue }) => {
    try {
      const { data } = await axios.get('user/documents/mutuals', {
        params: {
          date_from,
          date_to,
          type,
          document_type,
        },
      });
      return data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  },
);

const getMutualSettlementsTypes = createAsyncThunk(
  'mutualSettlements/getMutualSettlementsTypes',
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await axios.get('user/documents/types');
      return data.data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  },
);

const confirmBonus = createAsyncThunk(
  'mutualSettlements/confirmBonus',
  async ({ flag, onReject, onResolve }, { rejectWithValue }) => {
    try {
      const { data } = await axios.post('company/confirm-bonus', { confirmed: flag });
      if (onResolve) onResolve();
      return data.data;
    } catch (error) {
      if (onReject) onReject();
      return rejectWithValue(error.response);
    }
  },
);

export default {
  getMutualSettlements,
  getMutualSettlementsTypes,
  confirmBonus,
};
