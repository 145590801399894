import {useEffect, useMemo, useState} from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Sprite } from '@root/assets/svg';
import { QuantityManager } from '@root/components/Managers/QuantityManager';
import { BasicTooltip, IconBtn, ToggleBtn } from '@root/ui';
import { OutlineButtonBase } from '@root/ui/Button/OutlineButton/index';

import userSelectors from '@redux/user/user-selectors';

import { Typography, useTheme } from '@mui/material';
import categorySelectors from "@redux/category/category-selectors";
import {findCategoryObjectById} from "@root/helpers/findCategoryObjectById";

export const Action = ({
  onClick,
  handelDetailCard,
  addedToCart,
  isOpenDetail,
  chooseProduct,
  addAnimation,
  handelOpenSavedModal,
  isSaved,
  inCompare,
  relation_quantity,
  errorBorder,
  data,
  addOrRemoveFromComparisons,
  isHideArrowCard = false,
  isWatchlist = false,
  handleWatchlistToggle,
  showControllers = true,
  mobileCatalogStyles
}) => {
  const { color } = useTheme();
  const { t } = useTranslation(['common'], { useSuspense: false });
  const userPermissions = useSelector(userSelectors.getPermissions);

  // GOOGLE ANALYTICS
  let category = useSelector(categorySelectors.getCategory);
  let itemCategoryId = data.categories[0]
  let [googleAnalyticsInfo, setGoogleAnalyticsInfo] = useState({})

  useEffect(() => {
    if (category && itemCategoryId) {
      let categoryObject = findCategoryObjectById(category, itemCategoryId);
      let googleAnalyticsInfoObject = {}

      if (categoryObject.parentObject && categoryObject.childObject) {
        googleAnalyticsInfoObject = {
          item_name: `${data.name} ${data.model}`,
          item_id: `${data.sku}`,
          price: `${data.prices.price.product}`,
          item_brand: `${data.brand}`,
          item_category: "Viatec Cabinet",
          item_category2: `${categoryObject.parentObject.name}`,
          item_category3: `${categoryObject.childObject.name}`,
        }
        if(data.labels.new) {
          googleAnalyticsInfoObject.item_list_name = "Новинки"
        }
        if(data.labels.sale) {
          googleAnalyticsInfoObject.item_list_name2 = "Розпродаж"
        }
      }

      if (categoryObject.parentObjectWithoutChildren) {
        googleAnalyticsInfoObject = {
          item_name: `${data.name} ${data.model}`,
          item_id: `${data.sku}`,
          price: `${data.prices.price.product}`,
          item_brand: `${data.brand}`,
          item_category: "Viatec Cabinet",
          item_list_name: `${categoryObject.parentObjectWithoutChildren.name}`,
        }
      }

      setGoogleAnalyticsInfo(googleAnalyticsInfoObject)
    }
  }, []);

  const googleAnalyticsAddProductToCart = () => {
    window.dataLayer.push({ ecommerce: null });
    window.dataLayer.push({
      event: "add_to_cart",
      ecommerce: {
        items: [{...googleAnalyticsInfo, quantity: 1}]
      }
    });
  }
  // END GOOGLE ANALYTICS

  const QuantityMemo = useMemo(
    (mobileCatalogStyles) => (mobileCatalogStyles) =>
      (
        <QuantityManager
          errorBorder={errorBorder}
          addAnimation={addAnimation}
          data={data}
          mobileCatalogStyles={mobileCatalogStyles}
          googleAnalyticsInfo={googleAnalyticsInfo}
        />
      ),
    [chooseProduct, errorBorder, data, addAnimation, googleAnalyticsInfo],
  );

  const choosedCurrency = useSelector(
    userSelectors.getChoosedCurrencyWidget,
  ).field_name;

  const tooltipText = () => {
    return (
      <TooltipTitle>
        <Typography color={color.white} variant="bodyBoldSmall" component="p">
          {t('common:tooltip.current_product_need')} {relation_quantity}
        </Typography>
        <Typography color={color.white} variant="bodyBoldSmall" component="p">
          {data?.model}
        </Typography>
        <Typography
          color={color.white}
          variant="bodySmall"
          marginTop="3px"
          component="p"
        >
          {t('common:tooltip.can_change_after_adding_product_to_cart')}
        </Typography>
      </TooltipTitle>
    );
  };

  return (
    <FlexContainer>
      <Box>
        {userPermissions['cart_access'] && (
          <>
            {addedToCart ? (
              QuantityMemo(mobileCatalogStyles)
            ) : isWatchlist ? (
              <OutlineButtonBase
                borderColor={!data.in_watchlist ? color.main : color.line}
                // hoverBackground={'blue'}
                hoverBackground={
                  !data.in_watchlist ? color.main_extralight : color.line
                }
                sx={{
                  p: mobileCatalogStyles ? '5px' : '10px 8px',
                  display: 'flex',
                  alignItems: 'center',
                  maxWidth: '100px',
                }}
                onClick={() => {
                  handleWatchlistToggle(data.id);
                }}
              >
                {
                  !mobileCatalogStyles &&
                  <Svg
                    width={16}
                    height={16}
                    color={!data.in_watchlist ? color.main : color.gray_80}
                  >
                    <use href={`${Sprite}#icon-bell`} />
                  </Svg>
                }
                <Typography
                  variant="BodyBoldExSmall"
                  component="p"
                  color={!data.in_watchlist ? color.main : color.gray_80}
                  sx={{ lineHeight: '100%', fontSize: '10px' }}
                >
                  {!data.in_watchlist
                    ? t('watchlist:notify_btn.activated')
                    : t('watchlist:notify_btn.base')}
                </Typography>
              </OutlineButtonBase>
            ) : (
              <>
                <ToggleBtnWrapper onClick={e => e.preventDefault()}>
                  <ToggleBtn
                    onClick={
                      (e) => {
                        onClick(e)
                        googleAnalyticsAddProductToCart()
                      }}
                    size={32}
                    disabled={!data.can_buy[choosedCurrency]}
                    isActive={data.can_buy[choosedCurrency]}
                    href={`${Sprite}#icon-grocery_cart`}
                    data-test-id="ButtonAddToCart"
                  />
                </ToggleBtnWrapper>
                {relation_quantity && (
                  <BasicTooltip title={tooltipText()} placement="top">
                    <Typography
                      sx={{ ml: '8px', cursor: 'pointer' }}
                      variant={'bodyBoldSmall'}
                      component={'p'}
                    >
                      x{relation_quantity}
                    </Typography>
                  </BasicTooltip>
                )}
              </>
            )}
          </>
        )}
      </Box>
      {
        showControllers && (
        <Controllers onClick={e => e.preventDefault()}>
          <IconBtn
            href={`${Sprite}#icon-hart`}
            onClick={handelOpenSavedModal}
            size={16}
            saved={isSaved}
            tooltipText={
              isSaved
                ? t('common:tooltip.remove_from_saved')
                : t('common:tooltip.save')
            }
          />

          {userPermissions['product_compare_access'] && (
            <IconBtn
              href={`${Sprite}#icon-counter`}
              onClick={addOrRemoveFromComparisons}
              size={16}
              sx={{ marginTop: '10px' }}
              compare={inCompare}
              tooltipText={t('common:tooltip.compare')}
            />
          )}

          {!isHideArrowCard && (
            <IconBtn
              href={`${Sprite}#icon-arrow_card`}
              onClick={handelDetailCard}
              size={16}
              sx={{ marginTop: '10px' }}
              rotateSvg={isOpenDetail ? '180deg' : '0'}
              tooltipText={
                isOpenDetail
                  ? t('common:tooltip.hide_details')
                  : t('common:tooltip.show_more')
              }
            />
          )}
        </Controllers>
      )}
    </FlexContainer>
  );
};

const ToggleBtnWrapper = styled.div`
  display: block;
`;

const FlexContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const Box = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 115px;
  height: 100%;
`;

const Controllers = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const Svg = styled.svg`
  stroke: ${({ color }) => color} !important;
  flex: 0 0 16px;
`;

const TooltipTitle = styled.div``;

Action.propTypes = {
  isHideArrowCard: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  handelDetailCard: PropTypes.func.isRequired,
  addedToCart: PropTypes.bool,
  isOpenDetail: PropTypes.bool,
  chooseProduct: PropTypes.shape({
    amount: PropTypes.array,
    quantity: PropTypes.number,
    cart_product_id: PropTypes.number,
    id: PropTypes.number,
    personal_amount: PropTypes.exact({
      price: PropTypes.string,
      price_uah_cash: PropTypes.string,
      price_uah_no_pdv: PropTypes.string,
      price_uah_pd: PropTypes.string,
    }),
  }),
  addAnimation: PropTypes.func,
  handelOpenSavedModal: PropTypes.func.isRequired,
  isSaved: PropTypes.bool,
  inCompare: PropTypes.bool,
  relation_quantity: PropTypes.number,
  errorBorder: PropTypes.bool,
  data: PropTypes.exact({
    brand: PropTypes.string,
    can_buy: PropTypes.exact({
      price: PropTypes.bool,
      price_uah_cash: PropTypes.bool,
      price_uah_no_pdv: PropTypes.bool,
      price_uah_pdv: PropTypes.bool,
    }),
    cart: PropTypes.shape({
      cart_product_id: PropTypes.number,
      id: PropTypes.number,
      personal_amount: PropTypes.exact({
        price: PropTypes.string,
        price_uah_cash: PropTypes.string,
        price_uah_no_pdv: PropTypes.string,
        price_uah_pdv: PropTypes.string,
      }),
      quantity: PropTypes.number,
    }),
    description: PropTypes.string,
    has_analogs: PropTypes.bool,
    id: PropTypes.number,
    image: PropTypes.string,
    images_quantity: PropTypes.number,
    in_compare: PropTypes.bool,
    is_in_cart: PropTypes.bool,
    is_saved: PropTypes.bool,
    model: PropTypes.string,
    name: PropTypes.string,
    personal_price: PropTypes.exact({
      price: PropTypes.string,
      price_uah_cash: PropTypes.string,
      price_uah_no_pdv: PropTypes.string,
      price_uah_pdv: PropTypes.string,
    }),
    price: PropTypes.exact({
      price: PropTypes.string,
      price_uah_cash: PropTypes.string,
      price_uah_no_pdv: PropTypes.string,
      price_uah_pdv: PropTypes.string,
    }),
    prices: PropTypes.exact({
      price: PropTypes.exact({
        product: PropTypes.string,
        personal: PropTypes.string,
      }),
      price_uah_cash: PropTypes.exact({
        product: PropTypes.string,
        personal: PropTypes.string,
      }),
      price_uah_no_pdv: PropTypes.exact({
        product: PropTypes.string,
        personal: PropTypes.string,
      }),
      price_uah_pdv: PropTypes.exact({
        product: PropTypes.string,
        personal: PropTypes.string,
      }),
    }),
    sku: PropTypes.string,
    slug: PropTypes.string,
    stock: PropTypes.number,
    stock_max: PropTypes.number,
    stock_percent: PropTypes.number,
    url: PropTypes.string,
    warehouses: PropTypes.arrayOf(
      PropTypes.exact({
        city_name: PropTypes.string,
        warehouses: PropTypes.exact({
          name: PropTypes.string,
          stock: PropTypes.number,
          stock_max: PropTypes.number,
          stock_percent: PropTypes.number,
        }),
      }),
    ),
  }),
  addOrRemoveFromComparisons: PropTypes.func.isRequired,
};
