import * as React from 'react';

import PropTypes from 'prop-types';

import Slider from '@mui/material/Slider';
import { styled } from '@mui/material/styles';

const AirbnbSlider = styled(Slider)(({ theme }) => ({
  color: theme.color.main,
  height: 3,
  padding: '13px 0',
  '& .MuiSlider-thumb': {
    height: 27,
    width: 27,
    backgroundColor: theme.color.white,
    border: '1px solid currentColor',
    '&:hover': {
      boxShadow: '0 0 0 8px rgba(58, 133, 137, 0.16)',
    },
    '& .airbnb-bar': {
      height: 9,
      width: 1,
      backgroundColor: 'currentColor',
      marginLeft: 1,
      marginRight: 1,
    },
  },
  '& .MuiSlider-track': {
    height: 3,
  },
  '& .MuiSlider-rail': {
    color:
      theme.palette.mode === 'dark' ? theme.color.gray_90 : theme.color.gray_70,
    opacity: theme.palette.mode === 'dark' ? undefined : 1,
    height: 3,
  },
}));

export const RangeInput = ({ value, onChange, min, max }) => {
  return (
    <AirbnbSlider
      getAriaLabel={index => (index === 0 ? 'Minimum price' : 'Maximum price')}
      defaultValue={[min, max]}
      value={value}
      onChange={onChange}
      min={min}
      max={max}
    />
  );
};

RangeInput.propTypes = {
  value: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  min: PropTypes.number,
  max: PropTypes.number,
};
