import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import { PasswordLights } from '@root/components';
import { PasswordConf } from '@root/conf';
import { getErrorStack, validPassword } from '@root/helpers';
import { useNavigation } from '@root/hooks';
import { ButtonBase, PasswordInput, PhoneInput } from '@root/ui';

import authOperation from '@redux/auth/auth-operation';

import Typography from '@mui/material/Typography';

export const UpdateUserDataForm = ({ initData }) => {
  const [data, setData] = useState({
    phone: '',
    password: '',
    confirmPassword: '',
  });
  const [error, setError] = useState({});
  const { t } = useTranslation(['login', 'validation'], { useSuspense: false });
  const [isVisibleValid, setIsVisibleValid] = useState(false);
  const [steps, setSteps] = useState([]);
  const dispatch = useDispatch();
  const { navigation } = useNavigation();

  document.title = t('login:title_meta_update');

  useEffect(() => {
    if (initData) {
      setData({ ...data, ...initData });
    } else {
      navigation('/login');
    }
  }, [initData]);

  const onChange = name => value => {
    setData(prevState => ({ ...prevState, [name]: value }));
  };

  useEffect(() => {
    setSteps(PasswordConf(t));
  }, [t]);

  const validation = () => {
    let flag = false;
    let currentError = {};
    const { phone, password, confirmPassword } = data;

    Object.keys({
      phone,
      password,
      confirmPassword,
    }).forEach(key => {
      if (!data[key] || data[key] === '') {
        currentError = getErrorStack(
          currentError,
          key,
          t('validation:required'),
        );
        flag = true;
      }
    });

    if (
      !validPassword(password, {
        minLowercase: 1,
        minUppercase: 1,
        minNumbers: 1,
        minLength: 8,
      }) &&
      password !== ''
    ) {
      currentError = getErrorStack(
        currentError,
        'password',
        t('validation:password_not_secure'),
      );
      flag = true;
    }

    if (confirmPassword !== password && confirmPassword !== '') {
      currentError = getErrorStack(
        currentError,
        'confirmPassword',
        t('validation:password_not_match'),
      );
      flag = true;
    }

    if (phone.length !== 12 && phone.length !== 0) {
      currentError = getErrorStack(
        currentError,
        'phone',
        t('validation:not_full_fill'),
      );

      flag = true;
    }
    setError(currentError);
    return flag;
  };

  const handelSubmit = e => {
    e.preventDefault();
    if (validation()) return;
    dispatch(authOperation.updateOldUserData({ data }));
  };

  const hideValidation = e => {
    setIsVisibleValid(false);
  };

  useEffect(() => {
    const { password } = data;
    if (data.password === '' || !data.password) return;
    const stepsCopy = [...steps];

    if (!isVisibleValid) setIsVisibleValid(true);
    if (validPassword(password, { minUppercase: 1 })) {
      stepsCopy[0].status = true;
    } else {
      stepsCopy[0].status = false;
    }
    if (validPassword(password, { minLowercase: 1 })) {
      stepsCopy[1].status = true;
    } else {
      stepsCopy[1].status = false;
    }

    if (validPassword(password, { minNumbers: 1 })) {
      stepsCopy[2].status = true;
    } else {
      stepsCopy[2].status = false;
    }

    if (password.length >= 8) {
      stepsCopy[3].status = true;
    } else {
      stepsCopy[3].status = false;
    }

    setSteps(stepsCopy);
  }, [data.password]);

  const { password, confirmPassword, phone } = data;
  return (
    <Wrapper>
      <Form autoComplete="on" onSubmit={handelSubmit}>
        <Typography variant={'h1'} align={'center'}>
          {t('login:update_old_user_data.tittle')}
        </Typography>
        <Typography
          sx={{ mt: '28px' }}
          variant={'bodyLarge'}
          align={'center'}
          component={'p'}
        >
          {t('login:update_old_user_data.slogan')}
        </Typography>

        <PasswordInput
          error={error?.password?.init}
          errorText={error?.password?.text}
          sx={{ mt: '28px', width: '100%', height: '48px' }}
          value={password}
          onChange={onChange('password')}
          placeholder={t('login:field.password', { req: '*' })}
          data-test-id="Password"
          autoComplete={'new-password'}
          inputProps={{
            maxLength: 255,
          }}
          onBlur={hideValidation}
        />
        <PasswordInput
          error={error?.confirmPassword?.init}
          errorText={error?.confirmPassword?.text}
          sx={{ mt: '28px', width: '100%', height: '48px' }}
          onChange={onChange('confirmPassword')}
          value={confirmPassword}
          placeholder={t('registration:field.duplicate_password', {
            req: '*',
          })}
          data-test-id="Password"
          autoComplete={'current-password'}
          withOutSpace={true}
          inputProps={{
            maxLength: 255,
          }}
        />

        <PhoneInput
          sx={{ mt: '28px', height: '48px' }}
          placeholder={t('login:field.phone', {
            req: '*',
          })}
          onChange={onChange('phone')}
          setPhoneModal={() => {}}
          value={phone}
          error={error?.phone?.init}
          errorText={error?.phone?.text}
          data-test-id="Phone"
          autoCompleteType={'tel'}
        />
        <ButtonBase
          type="submit"
          size={'large'}
          sx={{ mt: '28px' }}
          data-test-id="SignIn"
        >
          <Typography variant={'bodyBoldLarge'}>
            {' '}
            {t('common:buttons.sent')}
          </Typography>
        </ButtonBase>
      </Form>
      <WrapperLights>
        {isVisibleValid &&
          steps.map(({ status, text }) => (
            <PasswordLights status={status} text={text} key={text} />
          ))}
      </WrapperLights>
    </Wrapper>
  );
};

const WrapperLights = styled.div`
  margin-top: 216px;
  margin-left: 24px;
  display: flex;
  flex-direction: column;

  width: 210px;

  div + div {
    margin-top: 4px;
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 430px;
`;

UpdateUserDataForm.propTypes = {
  initData: PropTypes.exact({
    phone: PropTypes.string,
    password: PropTypes.string,
    confirmPassword: PropTypes.string,
  }),
};
