import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const getNotifications = createAsyncThunk(
  'notifications/getNotifications',
  async ({ page, per_page, type = 'base' }, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(`notifications/${type}`, {
        params: {
          page,
          per_page,
        },
      });
      return { type, response: data };
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

const markAllAsRead = createAsyncThunk(
  'notifications/markAllAsRead',
  async ({ type, onResolve, onReject }, { rejectWithValue }) => {
    try {
      const { data } = await axios.post(`notifications/${type}/readall`);
      if (onResolve) onResolve();
      return data.data;
    } catch (error) {
      if (onReject) onReject();
      return rejectWithValue(error);
    }
  },
);

const markOneAsRead = createAsyncThunk(
  'notifications/markAllAsRead',
  async ({ type, id, onResolve, onReject }, { rejectWithValue }) => {
    try {
      const { data } = await axios.post(`notifications/${type}/${id}`);
      if (onResolve) onResolve();
      return { type, id, response: data.data };
    } catch (error) {
      if (onReject) onReject();
      return rejectWithValue(error);
    }
  },
);

export default {
  getNotifications,
  markAllAsRead,
  markOneAsRead,
};
