import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import { SearchInput } from '@root/components';
import CompanyFilter from '@root/components/AdminPanel/CompanyFilter/index';
import { useIsFirstRender } from '@root/hooks';
import { BasicSelect } from '@root/ui';

export const AdminSearch = ({ accordionActive, fetchAdminData }) => {
  const [searchValue, setSearchValue] = useState('');
  const [focus, setFocus] = useState(false);

  const { adminTab } = useParams();

  const { t } = useTranslation(['admin'], { useSuspense: false });

  const isFirstRender = useIsFirstRender();

  const onBlur = e => setFocus(false);

  useEffect(() => {
    if (isFirstRender) return;

    setSearchValue('');
  }, [adminTab]);

  const onSearchChange = name => value => {
    setSearchValue(value);
    if (value) {
      setFocus(true);
    } else {
      setFocus(false);
    }
  };

  const handelSubmit = e => {
    if (e.code === 'Enter' || e.code === 'NumpadEnter') {
      e.preventDefault();
      fetchAdminData({ q: searchValue });
    }
  };

  const handleClearSearch = () => {
    setSearchValue('');
    fetchAdminData({ q: null });
  };

  const handleCompanyFilterChange = ({ id, name }) => {
    fetchAdminData({ company_id: id, company_name: name });
  };

  return (
    <Component>
      <Form>
        <Label onKeyDown={handelSubmit}>
          <SearchInput
            focus={focus}
            onBlur={onBlur}
            onChange={onSearchChange}
            placeholder={t('admin:search')}
            searchValue={searchValue}
            handleClearSearch={handleClearSearch}
            t={t}
          />
        </Label>
        <AdminButtons>
          {adminTab === 'people' && (
            <BasicSelect
              sx={{
                height: '36px',
                p: 0,
                width: accordionActive ? '196px' : '300px',
              }}
              height={'36px !important'}
              data={[{ id: 1, name: 'Виберіть бізнес ' }]}
              name={t('admin:business_placeholder')}
            />
          )}
          {adminTab === 'people' && (
            <BasicSelect
              sx={{
                height: '36px',
                p: 0,
                width: accordionActive ? '196px' : '300px',
              }}
              height={'36px !important'}
              data={[{ id: 1, name: 'Виберіть контрагента' }]}
              name={t('admin:counterparty_placeholder')}
            />
          )}
          {adminTab === 'counterparts' && (
            <CompanyFilter handleChange={handleCompanyFilterChange} />
          )}

          {/* {adminTab !== 'agreements' && (
            <>
              <OutlineButtonBase
                sx={{
                  height: '36px',
                  width: '100px',
                  alignItems: 'center',
                  gap: ' 4px',
                }}
                onClick={() => {}}
              >
                <svg height={16} width={16}>
                  <use href={`${Sprite}#icon-appearance`}></use>
                </svg>
                {t('admin:appearance')}
              </OutlineButtonBase>
              <ToggleBtn
                size={36}
                onClick={() => {}}
                href={`${Sprite}#icon-archive`}
                tooltipText={t('common:tooltip.archive')}
              />
            </>
          )} */}
          {/* <ToggleBtn
            size={36}
            onClick={() => {}}
            href={`${Sprite}#icon-filter`}
          /> */}
        </AdminButtons>
      </Form>
    </Component>
  );
};

const AdminButtons = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
`;

const Component = styled.div`
  position: relative;
  width: 100%;
`;

const Form = styled.div`
  padding: 21px 0;
  display: flex;
  align-items: center;
`;

const Svg = styled.svg``;

const Label = styled.label`
  margin-right: 16px;
  display: block;
  width: 100%;
  position: relative;
`;

const Placeholder = styled.div`
  position: absolute;
  top: 0;
  right: 16px;
  transform: translate(0, 50%);

  display: flex;
`;

AdminSearch.propTypes = {
  accordionActive: PropTypes.bool,
};
