import React from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import { useTheme } from '@mui/material';

export const EditorContentWrapper = ({ editorContent }) => {
  const { color } = useTheme();
  return (
    <div className="ql-editor">
      <HtmlContent
        dangerouslySetInnerHTML={{ __html: editorContent }}
        color={color}
      />
    </div>
  );
};

const HtmlContent = styled.div`
  p {
    font-family: Roboto, sans-serif !important;
    font-weight: revert !important;
    color: ${({color}) => color.gray} !important;
  }

  img {
    display: inline-block;
    max-width: 100%;
  }

  ul,
  ol {
    list-style: revert;
    list-style-position: inside;
  }

  strong {
    font-family: Roboto, sans-serif !important;
    font-weight: revert !important;
  }

  em {
    font-family: Roboto, sans-serif !important;
    font-weight: revert !important;
  }

  ins {
    font-family: Roboto, sans-serif !important;
    font-weight: revert !important;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: Roboto, sans-serif !important;
    font-weight: revert !important;
    color: ${({color}) => color.gray} !important;
  }

  .textPageLinkBase {
    background-color: ${({ color }) => color.main};
    color: ${({ color }) => color.white} !important;
    padding: 9px 12px;
    border-radius: 4px;
    margin: 10px 0;
    transition: 0.3s ease;
    display: inline-block;
    text-decoration: none;
    &:hover {
      box-shadow: 0px 4px 10px 0px rgba(57, 99, 225, 0.28);
    }
  }
  .textPageLinkOutline {
    background-color: ${({ color }) => color.white};
    color: ${({ color }) => color.gray_60};
    border: 1px solid ${({ color }) => color.line};
    padding: 9px 12px;
    border-radius: 4px;
    margin: 10px 0;
    transition: 0.3s ease;
    display: inline-block;
    text-decoration: none;
    &:hover {
      box-shadow: 0px 4px 10px 0px rgba(57, 99, 225, 0.28);
      color: ${({ color }) => color.gray_80};
    }
  }

  span {
    font-family: Roboto, sans-serif !important;
    font-weight: revert !important;
  }

  code {
    font-family: Roboto, sans-serif !important;
    font-weight: revert !important;
  }

  div {
    font-family: Roboto, sans-serif !important;
    font-weight: revert !important;
  }

  a {
    font-family: Roboto, sans-serif !important;
    font-weight: revert !important;
    text-decoration: revert !important;
    color: ${({color}) => color.main} !important;

    span {
      color: inherit !important;
    }
  }

  div[style*='text-align:none'] {
    text-align: center !important;
  }
`;

EditorContentWrapper.propTypes = {
  editorContent: PropTypes.string.isRequired,
};
