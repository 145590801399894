import {createSlice} from "@reduxjs/toolkit";

const windowWithSlice = createSlice({
  name: "windowWithSlice",
  initialState: {
    windowWith: '',
  },

  reducers: {
    setWindowWithToStore(state, action) {
      state.windowWith = action?.payload
    },
  }
})

export default windowWithSlice.reducer
export const {setWindowWithToStore} = windowWithSlice.actions
