import * as React from 'react';
import { useEffect, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { nanoid } from 'nanoid';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Sprite } from '@root/assets/svg';
import { BaseModalHeader } from '@root/components';
import { handleDispatchFetch } from '@root/helpers';
import { MainCategoryElement } from '@root/modules/MainCategoryElement';
import { IconBtn, ModalBase, OutlineButtonBase, TextInput } from '@root/ui';

import categoryOperation from '@redux/category/category-operation';
import categorySelectors from '@redux/category/category-selectors';
import {
  clearModalState,
  setOpenModal,
} from '@redux/modal-watcher/modal-watcher-slice';

import { useTheme } from '@mui/material/styles';

export const EditCategoryListModal = ({ open, handleClose }) => {
  const listCategory = useSelector(categorySelectors.getCategoryListToEdit);
  const [items, setItems] = useState([]);
  const [subCategoryName, setSubCategoryName] = useState('');
  const [openCreateCategory, setOpenCreateCategory] = useState(null);
  const [newCategoryName, setNewCategoryName] = useState('');
  const [deletedArr, setDeletedArr] = useState([]);
  const dispatch = useDispatch();

  const { color } = useTheme();
  const { t } = useTranslation(['catalog', 'common'], { useSuspense: false });

  useEffect(() => {
    setItems(listCategory);
  }, [listCategory]);

  const handleParentVisible = id => {
    const hiddenList = items.map(item => {
      if (item.id === id) {
        return { ...item, active: !item.active };
      } else {
        return item;
      }
    });
    setItems(hiddenList);
  };

  const handleChildrenVisible = (parentId, childrenIndex, active) => {
    const hiddenList = [...items].map((item, idx, arr) => {
      if (item.id === parentId) {
        const copy = JSON.parse(JSON.stringify(item));
        copy.children[childrenIndex].active = !active;
        return copy;
      }

      return item;
    });
    setItems(hiddenList);
  };

  const handleSetCategoryName = () => (value, index) => {
    const newList = items.map((item, parentIndex) => {
      if (index === parentIndex) {
        return {
          ...item,
          name: value,
        };
      } else {
        return item;
      }
    });
    setItems(newList);
  };

  const handleSetSubCategoryName = parentId => (value, index) => {
    const newList = JSON.parse(JSON.stringify(items)).map(item => {
      const childrenCopy = [...item.children];
      if (item.id === parentId) {
        childrenCopy[index].name = value;
      }
      return item;
    });
    setItems(newList);
  };

  const handleDeleteCategory = () => (index, id) => {
    dispatch(
      setOpenModal({
        open: true,
        key: 'confirm',
        isHiddenHeader: true,
        inactiveBackdrop: true,
        dataForConfirm: {
          id: id,
          index: index,
        },
        callback: data => removeCategoryOrSubcategory(data),
      }),
    );
  };

  const handleSubCategoryDelete = parentId => (index, id) => {
    dispatch(
      setOpenModal({
        open: true,
        key: 'confirm',
        isHiddenHeader: true,
        inactiveBackdrop: true,
        dataForConfirm: {
          id: id,
          index: index,
          parentId: parentId,
        },
        callback: data => removeCategoryOrSubcategory(data),
      }),
    );
  };

  const handleAddNewSubCategory = (subCategory, index) => {
    const newList = JSON.parse(JSON.stringify(items));
    newList[index].children.push(subCategory);
    setItems(newList);
  };

  const handleSetCategoryList = () => {
    handleDispatchFetch(
      ({ onResolve, onReject }) =>
        dispatch(
          categoryOperation.postCategoryList({
            categories: items,
            deleted: deletedArr,
            onResolve,
            onReject,
          }),
        ),
      () => {
        dispatch(categoryOperation.getCategory());
        handleClose();
      },
    );
  };

  const handleNewSubCategoryName = () => value => {
    setSubCategoryName(value);
  };

  const handleNewCategoryName = () => value => {
    setNewCategoryName(value);
  };

  const handleConfirmAddCategory = () => {
    const category = {
      id: nanoid(),
      active: true,
      can_delete: true,
      children: [],
      name: newCategoryName,
      is_new: true,
    };
    const newList = [...items];
    setOpenCreateCategory(false);
    setNewCategoryName('');
    newList.push(category);
    setItems(newList);
  };

  const removeCategoryOrSubcategory = ({ id, index, parentId }) => {
    if (!parentId) {
      const newList = [...items];
      newList.splice(index, 1);
      setItems(newList);
      setDeletedArr(previousState => [id, ...previousState]);
    } else {
      const newList = items.map(item => {
        const childrenCopy = [...item.children];
        if (item.id === parentId) {
          childrenCopy.splice(index, 1);
          return {
            ...item,
            children: childrenCopy,
          };
        } else {
          return item;
        }
      });
      setItems(newList);
      setDeletedArr(previousState => [id, ...previousState]);
    }
    dispatch(clearModalState());
  };

  const handleDragEnd = result => {
    const justStr = value => value.toString();
    const justNum = (categoryType, value) => {
      let res = value.replace(/\D/g, '');
      if (typeof categoryType === 'string') {
        res = value.split('drop-')[1];
      }
      return res;
    };

    const { type, source, destination } = result;

    if (!destination) return;
    const sourceCategoryId = source.droppableId;
    const destinationCategoryId = destination.droppableId;

    if (type === 'droppable-item') {
      if (sourceCategoryId === destinationCategoryId) {
        const updOrder = reorder(
          items.find(
            category =>
              justStr(category.id) === justNum(category.id, sourceCategoryId),
          ).children,
          source.index,
          destination.index,
        );
        const updCategories = items.map(category => {
          return justStr(category.id) !== justNum(category.id, sourceCategoryId)
            ? category
            : { ...category, children: updOrder };
        });
        setItems(updCategories);
      } else {
        const deepCopyItems = JSON.parse(JSON.stringify(items));
        const sourceOrder = deepCopyItems.find(
          category =>
            justStr(category.id) === justNum(category.id, sourceCategoryId),
        ).children;

        const destinationOrder = deepCopyItems.find(
          category =>
            justStr(category.id) ===
            justNum(category.id, destinationCategoryId),
        ).children;

        const [removed] = sourceOrder.splice(source.index, 1);
        destinationOrder.splice(destination.index, 0, removed);

        destinationOrder[removed] = sourceOrder[removed];
        delete sourceOrder[removed];

        const updCategoriesList = items.map(category => {
          if (justStr(category.id) === justNum(category.id, sourceCategoryId)) {
            return { ...category, children: sourceOrder };
          } else if (
            justStr(category.id) === justNum(category.id, destinationCategoryId)
          ) {
            return { ...category, children: destinationOrder };
          } else {
            return category;
          }
        });
        setItems(updCategoriesList);
      }
    }

    if (type === 'droppable-category') {
      setItems(reorder(items, source.index, destination.index));
    }
  };

  const reorder = (list, startIndex, endIndex) => {
    const result = JSON.parse(JSON.stringify(list));
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  return (
    <ModalBase
      sx={{
        paddingLeft: 0,
        paddingRight: 0,
        top: 'initial',
        left: 'initial',
        transform: 'initial',
      }}
      modalSx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
      open={open}
      modalHeader={
        <BaseModalHeader
          mainTitle={t(
            'catalog:modals.settings_category_modal.settings_category',
          )}
          isActionButtons={true}
          isSearch={false}
          toolbarSx={{
            width: 'auto',
            padding: '0 20px 0 20px !important',
            minHeight: 'initial !important',
          }}
          defPadding={'17px 20px 0 20px'}
          closeModalCallback={handleClose}
          saveOrUpdate={handleSetCategoryList}
        />
      }
      handleClose={handleClose}
    >
      {' '}
      <ModalWrapper>
        <ListCategoryWrapper
          colors={{ gray: color.gray_40, white: color.white, line: color.line }}
        >
          <DragDropContext onDragEnd={handleDragEnd}>
            <div>
              <Droppable droppableId="droppable" type="droppable-category">
                {(provided, snapshot) => (
                  <div ref={provided.innerRef} {...provided.droppableProps}>
                    {items.map((parent, index) => (
                      <Draggable
                        key={'p-' + parent.id}
                        draggableId={'p' + parent.id}
                        index={index}
                      >
                        {(provided, snapshot) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={{
                              ...provided.draggableProps.style,
                              border: snapshot.isDragging
                                ? `1px solid ${color.main}`
                                : '1px solid transparent',
                            }}
                          >
                            <MainCategoryElement
                              parent={true}
                              item={parent}
                              index={index}
                              handleDeleteCategory={handleDeleteCategory}
                              handleSetCategoryName={handleSetCategoryName}
                              handleCategoryVisible={() =>
                                handleParentVisible(parent.id)
                              }
                              handleNewSubCategoryName={
                                handleNewSubCategoryName
                              }
                              handleAddNewSubCategory={handleAddNewSubCategory}
                              subCategoryName={subCategoryName}
                            >
                              <Droppable
                                droppableId={'drop-' + parent.id}
                                type="droppable-item"
                              >
                                {(provided, snapshot) => (
                                  <div
                                    ref={provided.innerRef}
                                    {...provided.droppableProps}
                                    style={{
                                      paddingBottom: snapshot.isDraggingOver
                                        ? '35px'
                                        : '0',
                                    }}
                                  >
                                    {parent.children.map(
                                      (child, childrenIndex) => (
                                        <Draggable
                                          key={'c-' + child.id}
                                          draggableId={'c-' + child.id}
                                          index={childrenIndex}
                                        >
                                          {(provided, dragSnapshot) => (
                                            <div
                                              ref={provided.innerRef}
                                              {...provided.draggableProps}
                                              {...provided.dragHandleProps}
                                            >
                                              <MainCategoryElement
                                                index={childrenIndex}
                                                item={child}
                                                customDragStyles={
                                                  dragSnapshot.isDragging
                                                    ? {
                                                        background: 'aliceblue',
                                                        border: `1px solid ${color.main}`,
                                                      }
                                                    : ''
                                                }
                                                handleDeleteCategory={() =>
                                                  handleSubCategoryDelete(
                                                    parent.id,
                                                  )
                                                }
                                                handleSetCategoryName={() =>
                                                  handleSetSubCategoryName(
                                                    parent.id,
                                                  )
                                                }
                                                handleCategoryVisible={() =>
                                                  handleChildrenVisible(
                                                    parent.id,
                                                    childrenIndex,
                                                    child.active,
                                                  )
                                                }
                                              />
                                            </div>
                                          )}
                                        </Draggable>
                                      ),
                                    )}
                                  </div>
                                )}
                              </Droppable>
                            </MainCategoryElement>
                          </div>
                        )}
                      </Draggable>
                    ))}
                  </div>
                )}
              </Droppable>
            </div>
          </DragDropContext>

          {!!items.length && openCreateCategory ? (
            <TextInput
              id="input-with-icon-adornment"
              sx={{
                width: '443px',
                height: '36px',
                input: { pl: '10px' },
              }}
              styleWrapper={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                margin: '8px 0',
              }}
              placeholder={t(
                'catalog:modals.settings_category_modal.fields.category_name',
              )}
              max={100}
              endInputIcons={
                <>
                  <IconBtn
                    sx={{ marginLeft: '10px' }}
                    href={`${Sprite}#icon-abort`}
                    onClick={() => setOpenCreateCategory(false)}
                    size={16}
                  />
                  <IconBtn
                    disabled={!newCategoryName.length}
                    sx={{ marginLeft: '15px' }}
                    href={`${Sprite}#icon-confirm`}
                    onClick={() => handleConfirmAddCategory(newCategoryName)}
                    size={16}
                  />
                </>
              }
              onChange={handleNewCategoryName()}
              value={newCategoryName}
            />
          ) : (
            <OutlineButtonBase
              onClick={() => setOpenCreateCategory(true)}
              sx={{
                height: '36px',
                margin: '8px 4px 8px 4px',
              }}
            >
              <Svg
                stroke={color.gray_60}
                width={16}
                height={16}
                rotateSvg={'180deg'}
              >
                <use href={`${Sprite}#icon-plus`}></use>
              </Svg>
              {t('catalog:modals.settings_category_modal.buttons.add_category')}{' '}
            </OutlineButtonBase>
          )}
        </ListCategoryWrapper>
      </ModalWrapper>
    </ModalBase>
  );
};

const Svg = styled.svg`
  stroke: ${props => props.stroke};
  margin-right: 4px;
`;

const ListCategoryWrapper = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  min-height: 752px;
`;

const ModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 450px;
`;

EditCategoryListModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};
