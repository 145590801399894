import * as React from 'react';

import PropTypes from 'prop-types';

import { TableCell, Typography, useTheme } from '@mui/material';

export const TextMap = ({ textArr, style }) => {
  const { color } = useTheme();
  return (
    <TableCell component="th" scope="row" sx={{ p: 0, ...style }}>
      {textArr &&
        textArr.map(({ name }) => (
          <Typography
            sx={{ color: color.gray }}
            variant={'bodySmall'}
            component={'p'}
          >
            {name}
          </Typography>
        ))}
    </TableCell>
  );
};

TextMap.propTypes = {
  textArr: PropTypes.arrayOf(
    PropTypes.exact({
      created_at: PropTypes.string,
      guard_name: PropTypes.string,
      id: PropTypes.number,
      name: PropTypes.string,
      pivot: PropTypes.exact({
        model_id: PropTypes.number,
        model_type: PropTypes.string,
        role_id: PropTypes.number,
      }),
      updated_at: PropTypes.string,
    }),
  ),
  style: PropTypes.object,
};
