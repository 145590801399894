import { useTranslation } from 'react-i18next';

import { Error } from '@root/components';
import { ErrorSprite } from '@root/assets/svg/index';

const OnMaintenance = ({ withLogo, height }) => {
  const { t } = useTranslation(['error']);
  return (
    <Error
      themed={true}
      errorImage={`${ErrorSprite}#maintenance`}
      errorTitle={t('error:on_maintenance_page.title')}
      errorDescription={t('error:on_maintenance_page.description')}
      withLogo={withLogo}
      height={height}
    />
  );
};

export default OnMaintenance;
