import PropTypes from 'prop-types';
import styled from 'styled-components';

import { RequiredStar } from '@root/assets/svg';
import { MessageError } from '@root/ui';

import { Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

export const TextArea = ({
  sx,
  value,
  onChange,
  placeholder,
  max,
  error,
  errorText,
  required = false,
  styleWrapper,
  label,
  labelRowGap = '',
  labelTextVariant = 'bodySmall',
  labelRowDirection = false,
  errorPositionLeft = '170px',
  ...prop
}) => {
  const { color } = useTheme();

  const handelChange = e => {
    const value = e.target.value;

    onChange(value);
  };

  return (
    <label
      style={{
        display: labelRowDirection ? 'flex' : 'block',
        position: labelRowDirection ? 'relative' : 'initial',
        gap: labelRowGap ? labelRowGap : 'initial',
        marginBottom: error && errorText && labelRowDirection ? '20px' : '',
        ...styleWrapper,
      }}
    >
      {label && (
        <LabelWrapper isRowDirection={labelRowDirection}>
          {required && (
            <RequiredStarImg
              src={RequiredStar}
              alt="Required Star"
              isRowDirection={labelRowDirection}
            />
          )}
          <Typography
            variant={labelTextVariant}
            sx={{ color: color.gray, ml: '2px' }}
            component={'span'}
          >
            {label}
          </Typography>
        </LabelWrapper>
      )}
      <Component
        color={color}
        onChange={handelChange}
        style={{ ...sx }}
        value={value}
        placeholder={placeholder}
        maxLength={max}
        error={error}
        {...prop}
      />
      {error && errorText && (
        <MessageError
          errorText={errorText}
          sx={{
            position: labelRowDirection ? 'absolute' : 'initial',
            top: labelRowDirection ? '100%' : '',
            left: labelRowDirection ? errorPositionLeft : '',
          }}
        />
      )}
    </label>
  );
};

const Component = styled.textarea`
  padding: 12px 16px;
  border: 1px solid ${({ color }) => color.line};
  background-color: ${({ color }) => color.white};
  border-radius: 4px;
  height: 116px;
  font-size: 1rem;
  line-height: 1.5;
  color: ${({ color }) => color.gray};
  resize: none;
  border-color: ${prop =>
    prop.error ? prop.color.red : ({ color }) => color.line};

  overflow-y: auto;
  scrollbar-width: thin;
  width: 100%;

  ::-webkit-scrollbar {
    width: 5px;
  }

  ::-webkit-scrollbar-thumb {
    background: ${props => props.color.gray_40};
    border-radius: 4px;
  }

  ::-webkit-scrollbar-track {
    background: ${props => props.color.white};
    border-left: 1px solid ${props => props.color.line};
  }

  &::placeholder {
    color: ${({ color }) => color.gray_60};
    opacity: 1;
    font-size: 0.875rem;
  }

  &:hover {
    background: ${({ color }) => color.gray_20};
    color: ${({ color }) => color.gray_80};
  }

  &:focus {
    &::placeholder {
      color: ${({ color }) => color.gray_40};
    }
  }
`;

const LabelWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  min-width: ${({ isRowDirection }) => (isRowDirection ? '150px' : '')};

  span {
    ${({ isRowDirection }) =>
      isRowDirection
        ? {
            alignSelf: 'center',
            whiteSpace: 'nowrap',
          }
        : {}}
  }
`;

const RequiredStarImg = styled.img`
  margin-top: 3px;
  ${({ isRowDirection }) =>
    isRowDirection
      ? {
          marginTop: 0,
          marginBottom: '3px',
          alignSelf: 'center',
        }
      : {}}
`;

TextArea.propTypes = {
  sx: PropTypes.object,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  max: PropTypes.number,
  error: PropTypes.bool,
  errorText: PropTypes.string,
  required: PropTypes.bool,
  label: PropTypes.string,
  styleWrapper: PropTypes.object,
  labelRowGap: PropTypes.string,
  labelTextVariant: PropTypes.string,
  labelRowDirection: PropTypes.bool,
  errorPositionLeft: PropTypes.string,
};
