import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import moment from 'moment/moment';
import styled from 'styled-components';

import userSelectors from '@root/redux/user/user-selectors';
import { ButtonBase } from '@root/ui/Button/ButtonBase/index';
import { OutlineButtonBase } from '@root/ui/Button/OutlineButton/index';
import { Link } from '@root/ui/Link/index';
import { ModalBase } from '@root/ui/Modal/index';

import { useTheme } from '@mui/material/styles';

import { Typography } from '../../../node_modules/@mui/material/index';
import { HeaderDefault } from '../Modal/ModalHeader/Default/index';

const BonusWarningModal2 = () => {
  const { color } = useTheme();
  const [open, setOpen] = useState(false);
  const userBonuses = useSelector(userSelectors.getUserProfile)?.bonuses;
  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    if (userBonuses.status === 'warning') {
      if (window.localStorage.getItem('bonusWarningLastOpened2') === null) {
        setOpen(true);
      } else {
        const lastOpened = new Date(
          window.localStorage.getItem('bonusWarningLastOpened2'),
        );
        moment(lastOpened);
        const currentDate = moment();
        if (currentDate.diff(lastOpened, 'hours') > 24) {
          setOpen(true);
        } else {
          setOpen(false);
        }
      }
    } else {
      setOpen(false);
    }
  }, [userBonuses]);

  const handleClose = () => {
    setOpen(false);
  };

  const onSubmit = () => {
    setOpen(false);
    window.localStorage.setItem('bonusWarningLastOpened2', new Date());
    navigate('/mutual-settlements');
  };

  // const onDecline = () => {
  //   setOpen(false);
  //   window.localStorage.setItem('bonusWarningLastOpened2', new Date());
  // };

  return (
    <>
      <ModalBase
        open={open}
        handleClose={handleClose}
        modalHeader={
          <HeaderDefault
            text={t('common:bonus_warning.title')}
            onClose={handleClose}
          />
        }
      >
        <Wrap>
          <Typography variant="bodyBoldMediun" color={color.red}>
            {t('common:bonus_warning.warningText')}
          </Typography>
          <BtnsWrap>
            {/* <OutlineButtonBase onClick={onDecline}>
              {t('common:bonus_warning.decline')}
            </OutlineButtonBase> */}
            <ButtonBase onClick={onSubmit}>
              {t('common:bonus_warning.submit')}
            </ButtonBase>
          </BtnsWrap>
        </Wrap>
      </ModalBase>
    </>
  );
};

export default BonusWarningModal2;

const Wrap = styled.div`
  width: 100vh;
  max-width: 442px;

  @media(max-width: 600px) {
    max-width: 80vw;
  }
`;

const BtnsWrap = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 12px;

  button {
    margin-left: 8px;
  }
`;
