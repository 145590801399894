import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Sprite } from '@root/assets/svg';
import { MenuSearch, SearchInput } from '@root/components';
import { handleDispatchFetch } from '@root/helpers';
import { useDebounce, useNavigation } from '@root/hooks';
import { useQueryParams } from '@root/hooks/useQueryParams';
import productOperation from '@root/redux/product/product-operation';
import userSelectors from '@root/redux/user/user-selectors';
import { whiteTooltipStyles } from '@root/styles';
import { BasicTooltip, IconBtn, OutlineButtonBase, ToggleBtn } from '@root/ui';

import authSelectors from '@redux/auth/auth-selectors';
import cartOperation from '@redux/cart/cart-operation';
import cartSelectors from '@redux/cart/cart-selectors';
import comparisonsSelectors from '@redux/comparisons/comparisons-selectors';
import searchOperation from '@redux/search/search-operation';
import searchSelectors from '@redux/search/search-selectors';
import { clearSearch } from '@redux/search/search-slice';

import { Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

export const Search = ({
  isOpenFilterMenu,
  onClick,
  hideFilterBtn = false,
  hideAddProductBtn = false,
  comparisonsActionButtons = false,
  indentFactorContainer,
  openCreateModal,
  OpenPdfLinksModal,
  searchInputSx,
  includeClearButton,
  isWaitlist = false,
}) => {
  const [data, setData] = useState({
    searchValue: '',
  });
  const [searchBy, setSearchBy] = useState({
    name: true,
    model: true,
    description: true,
    sku: true,
    property: true,
  });
  const { getQueryObj, setSearchParams } = useQueryParams();
  const { category, categoryChildren } = useParams();
  const { color } = useTheme();
  const [focus, setFocus] = useState(false);
  const [openSearchList, setOpenSearchList] = useState(false);
  const searchData = useSelector(searchSelectors.getSearchData);
  const totalSearch = useSelector(searchSelectors.getTotalSearch);
  const cartData = useSelector(cartSelectors.getCartData);
  const userRole = useSelector(authSelectors.getRole);
  const comparisonDetails = useSelector(
    comparisonsSelectors.getProductsDetails,
  );
  const getPermissions = useSelector(userSelectors.getPermissions);
  const dispatch = useDispatch();
  const { navigation } = useNavigation();

  const { t } = useTranslation(['catalog', 'comparisons'], {
    useSuspense: false,
  });
  const debounceSearchValue = useDebounce(data.searchValue, 400);

  useEffect(() => {
    handelClearSearch();
  }, [categoryChildren]);

  useEffect(() => {
    if (isWaitlist && data?.searchValue === '' && getQueryObj().q !== '') {
      handleClearSearch();
    }
  }, [data]);

  useEffect(() => {
    return () => {
      dispatch(clearSearch());
    };
  }, []);

  useEffect(() => {
    const { searchValue } = data;
    if (searchValue) handleSearchData(searchBy);
  }, [cartData]);

  const handelSubmit = e => {
    if (data.searchValue.length > 0) {
      handleSearchData(searchBy);
    }
  };

  const onChange = name => value => {
    setData(prevState => ({ ...prevState, [name]: value }));
  };

  useEffect(() => {
    handelSubmit();
  }, [debounceSearchValue]);

  const onChangeSearchBy = name => value => {
    setSearchBy(prevState => ({ ...prevState, [name]: value }));
    handleSearchData({ ...searchBy, [name]: value });
  };

  const onBlur = e => setFocus(false);

  const handelClearSearch = () => {
    dispatch(clearSearch());
    setData(prevState => ({ ...prevState, searchValue: '' }));
    setSearchBy({
      name: true,
      model: true,
      description: true,
      sku: true,
      property: true,
    });
    setOpenSearchList(false);
  };

  const handleSearchData = searchTypes => {

    const fields = Object.entries(searchTypes).reduce(
      (previousValue, currentValue, a, array) => {
        return currentValue[1]
          ? previousValue + `${currentValue[0]},`
          : previousValue;
      },
      '',
    );

    !fields.length
      ? dispatch(clearSearch())
      : handleDispatchFetch(
          ({ onResolve, onReject }) => {
            if (isWaitlist) {
              dispatch(
                productOperation.getWatchlist({
                  ...getQueryObj(),
                  q: data.searchValue,
                }),
              );

              setSearchParams({
                ...getQueryObj(),
                q: data.searchValue,
              });
            } else {
              dispatch(
                searchOperation.getProductsBySearch({
                  search: data.searchValue,
                  fields,
                  per_page: 5,
                  onResolve,
                  onReject,
                }),
              );
            }
          },
          data => setOpenSearchList(true),
          error => {},
        );
  };

  const handleClearSearch = () => {
    data.searchValue = '';
    handleSearchData({
      description: true,
      model: true,
      name: true,
      property: true,
      sku: true,
    });
  };

  const handleMoveToItemPage = slug => {
    navigation(`/catalog/product/${slug}`);
    setOpenSearchList(false);
    setData(prevState => ({ ...prevState, searchValue: '' }));
  };

  const handleMoveToSearchPage = () => {
    dispatch(clearSearch());
    setData(prevState => ({ ...prevState, searchValue: '' }));
    const fields = Object.entries(searchBy).reduce(
      (previousValue, currentValue, a, array) => {
        return currentValue[1]
          ? previousValue + `${currentValue[0]},`
          : previousValue;
      },
      '',
    );

    navigation(
      `/catalog/search-results/${category}?search=${searchValue}&fields=${fields
        .slice(0, -1)
        .replace(/,/g, '_')}&page=1&per_page=10`,
    );
    setOpenSearchList(false);
  };

  const handleAddToCart = (e, param) => {
    dispatch(
      cartOperation.addProductToCart({
        id: param.id,
        quantity: 1,
      }),
    );
  };

  const addAnimation = () => {};
  const { searchValue } = data;
  return (
    <Component color={color.white}>
      <Form>
        <SearchInput
          focus={focus}
          onBlur={onBlur}
          onChange={onChange}
          searchValue={searchValue}
          placeholder={t('catalog:fields.search_by_name_or_product')}
          sxLabel={{
            ...searchInputSx,
            ...(isWaitlist ? { marginRight: '0px' } : {}),
          }}
          includeClearButton={includeClearButton}
          handleClearSearch={handleClearSearch}
          on
        />

        {hideFilterBtn ||
          ((categoryChildren ||
            (category !== 'novinki' &&
              category !== 'rozprodaz' &&
              typeof category !== 'undefined')) && (
            <ToggleBtn
              size={36}
              onClick={() => {
                onClick(!isOpenFilterMenu);
              }}
              isActive={isOpenFilterMenu}
              href={`${Sprite}#icon-filter`}
            />
          ))}

        {userRole === 'admin' &&
          !isWaitlist &&
          getPermissions['product_card_add_access'] && (
            <OutlineButtonBase
              sx={{
                width: '200px',
                height: '36px',
                marginLeft: '16px',
                display: hideAddProductBtn ? 'none' : '',
              }}
              onClick={() => {
                navigation('/catalog/product/product-create');
              }}
            >
              <IconBtn
                sx={{ marginRight: '4px' }}
                disableBorder
                size={16}
                href={`${Sprite}#icon-plus`}
              />
              {t('catalog:buttons.add_product')}
            </OutlineButtonBase>
          )}
        {comparisonsActionButtons && (
          <ActionButtonsWrap>
            <OutlineButtonBase
              onClick={OpenPdfLinksModal}
              sx={{ flex: '50%', height: 36 }}
            >
              <Typography variant={'bodyBoldSmall'}>
                {t('comparisons:my_pdf')}
              </Typography>
            </OutlineButtonBase>
            <BasicTooltip
              title={
                comparisonDetails?.category?.count > 3 && (
                  <Typography color={color.gray} variant={'bodyBoldSmall'}>
                    {t('comparisons:hint_text')}
                  </Typography>
                )
              }
              placement="top-start"
              tooltipStyles={whiteTooltipStyles}
            >
              <span style={{ flex: '50%' }}>
                <OutlineButtonBase
                  onClick={openCreateModal}
                  sx={{ height: 36 }}
                  disabled={comparisonDetails?.category?.count > 3}
                >
                  <Typography variant={'bodyBoldSmall'}>
                    {t('comparisons:create_pdf')}
                  </Typography>
                </OutlineButtonBase>
              </span>
            </BasicTooltip>
          </ActionButtonsWrap>
        )}
      </Form>
      {openSearchList && (
        <MenuSearch
          dataCard={searchData}
          total={totalSearch}
          category={category}
          onChange={onChangeSearchBy}
          data={searchBy}
          handleMoveToSearchPage={handleMoveToSearchPage}
          handelClearSearch={handelClearSearch}
          handleMoveToItemPage={handleMoveToItemPage}
          addAnimation={addAnimation}
          handleAddToCart={handleAddToCart}
          indentFactorContainer={indentFactorContainer}
        />
      )}
    </Component>
  );
};

const Component = styled.div`
  background: ${props => props.color};
`;

const Form = styled.form`
  padding: 21px 20px;
  display: flex;
  align-items: center;

  @media(max-width: 1199px) {
    padding: 8px 16px;
  }
`;

const ActionButtonsWrap = styled.div`
  display: flex;
  align-items: center;
  max-width: 220px;
  width: 100%;
  gap: 16px;
`;

Search.propTypes = {
  isOpenFilterMenu: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  hideFilterBtn: PropTypes.bool,
  hideAddProductBtn: PropTypes.bool,
  comparisonsActionButtons: PropTypes.bool,
  indentFactorContainer: PropTypes.string,
  openCreateModal: PropTypes.bool,
  OpenPdfLinksModal: PropTypes.bool,
  searchInputSx: PropTypes.object,
  includeClearButton: PropTypes.bool,
};
