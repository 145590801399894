import { useParams } from '../../../node_modules/react-router-dom/dist/index';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { InView } from 'react-intersection-observer';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';

import axios from 'axios';
import moment from 'moment/moment';
import { serialize } from 'object-to-formdata';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Navigation } from 'swiper';
import 'swiper/css';
import 'swiper/css/pagination';

import { ProductNoPhoto } from '@root/assets/image/index';
import { Sprite } from '@root/assets/svg';
import {
  AdvertisingSlider,
  Availability,
  CharacteristicsInfo,
  CostDetails,
  ListWarehouses,
  QuantityManagerLarge,
} from '@root/components';
import BlockedUserProductCardMessage from '@root/components/BlockedUserMessages/BlockedUserProductCardMessage/index';
import DiscountParentProductLink from '@root/components/DiscountParentProductLink';
import ProductLabel from '@root/components/ProductLabel/index';
import { handleDispatchFetch } from '@root/helpers';
import { getErrorStack } from '@root/helpers/validation';
import { useQueryParams } from '@root/hooks/useQueryParams';
import { Characteristics } from '@root/modules/ProductInfo/Characteristics';
import { Tags } from '@root/modules/ProductInfo/Tags';
import { theme } from '@root/styles';
import {
  ButtonBase,
  ButtonWithoutBorder,
  EllipsisText,
  IconBtn,
  StockBar,
  TextArea,
  TextInput,
} from '@root/ui';
import { ProgressLoader } from '@root/ui/Loader/ProgressLoader/index';

import authSelectors from '@redux/auth/auth-selectors';
import cartOperation from '@redux/cart/cart-operation';
import comparisonsOperation from '@redux/comparisons/comparisons-operation';
import favoriteOperation from '@redux/favorite/favorite-operation';
import { setOpenModal } from '@redux/modal-watcher/modal-watcher-slice';
import { setOpenNotification } from '@redux/notification-watcher/notification-watcher-slice';
import productOperation from '@redux/product/product-operation';
import productSelectors from '@redux/product/product-selectors';
import {
  onClearByEditIdData,
  updateProductById,
} from '@redux/product/product-slice';
import userSelectors from '@redux/user/user-selectors';

import { Typography, useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';

import { AdminController } from './AdminController';
import CategorySettings from './CategorySettings/index';
import DescriptionOperations from './DescriptionOperations/index';
import DiscountedGoods from './DiscountedGoods/index';
import DocumentsSection from './DocumentsSection/index';
import ProductFiltersSettings from './ProductFiltersSettings/index';
import ProductImageEditor from './ProductImageEditor/index';
import { ProductTitle } from './ProductTitle';
import { RelatedProducts } from './RelatedProducts';
import { SimilarProducts } from './SimilarProducts';
import categorySelectors from "@redux/category/category-selectors";
import {findCategoryObjectById} from "@root/helpers/findCategoryObjectById";

const MOCK_TAGS = {
  top: true,
  new: false,
  hot: true,
  buyByBonus: false,
  sale: false,
};

const INIT_EDIT_DATA = {
  brand_id: 'none',
  categories: [],
  category_id: 'none',
  description: { uk: '', en: '', ru: '' },
  introText: { uk: '', en: '', ru: '' },
  id: null,
  model: '',
  name: { uk: '', en: '', ru: '' },
  property_categories: [],
  tags: MOCK_TAGS,
  is_draft: true,
};

export const ProductInfo = ({
  handelMoveToCart,
  item,
  setNavSection,
  handelOpenSavedModal,
  handelMoveToFavorite,
  addOrRemoveFromComparisons,
  isCreate = false,
}) => {
  const [tags, setTags] = useState(null);
  const { getQueryObj } = useQueryParams();
  const getPermissions = useSelector(userSelectors.getPermissions);
  const loading1c = useSelector(productSelectors.get1cLoading);
  const productItemLoading = useSelector(productSelectors.getLoadingItemPage);
  const productEditLoading = useSelector(productSelectors.getLoadingEditPage);
  const [mainImage, setMainImage] = useState();
  const navigationNextRef = useRef(null);
  const navigationPrevRef = useRef(null);
  const [error, setError] = useState({});
  const [cropImageModal, setCropImageModal] = useState(false);
  const [relatedScoop, setRelatedScoop] = useState(false);
  const [openCard, setOpenCard] = useState(false);
  const [choseIdCard, setChoseIdCard] = useState(null);
  const currency = useSelector(userSelectors.getChoosedCurrency);
  const editProduct = useSelector(productSelectors.getProductByEditId);
  const deliveryDay = moment().add(3, 'days').format('DD/MM/YYYY');
  const userRole = useSelector(authSelectors.getRole);
  const choosedCurrency = useSelector(
    userSelectors.getChoosedCurrencyWidget,
  ).field_name;
  const [isEdit, setIsEdit] = useState(false);
  const [lang, setLang] = useState('uk');
  const [editData, setEditData] = useState(INIT_EDIT_DATA);

  const getProductPropsDirty = useSelector(
    productSelectors.getProductPropsDirty,
  );
  const getProductProps = useSelector(productSelectors.getProductProps);

  const filtersSettingChanged = useSelector(
    productSelectors.getSettingsFilterDirty,
  );
  const getNewFilterSettings = useSelector(
    productSelectors.getNewFilterSettings,
  );
  const labelsList = useSelector(productSelectors.getTags);

  const userBonus = useSelector(userSelectors.getUserProfile).bonuses;
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const windowWith = useSelector(state => state?.windowWithSlice?.windowWith)

  // data for GOOGLE ANALYTICS
  let [googleAnalyticsInfo, setGoogleAnalyticsInfo] = useState({})
  let [isGoogleAnalyticsInfoSend, setIsGoogleAnalyticsInfoSend] = useState(false)
  let category = useSelector(categorySelectors.getCategory);
  let itemCategoryId = item && item?.categories && item?.categories[0]

  const fetchTags = async () => {
    try {
      const { data } = await axios.get('/product/labels');

      if (data?.data) {
        let tagsToSet;

        if (item.labels) {
          tagsToSet = [
            ...Object.values(data.data).map(el => ({
              field: el.field_name,
              title: el.title,
              isActive: !!item.labels[el.field_name],
            })),
          ];
        } else {
          tagsToSet = [
            ...Object.values(data.data).map(el => ({
              field: el.field_name,
              title: el.title,
              isActive: false,
            })),
          ];
        }

        setTags(tagsToSet);
      }
    } catch (e) {
      throw new Error(e);
    }
  };

  useEffect(() => {
    if (isEdit && !editData?.id) {
      if (!isCreate)
        dispatch(productOperation.getProductByEditId({ id: item.id }));
    }
  }, [isEdit]);

  useEffect(() => {
    fetchTags();
    setIsEdit(isCreate);
    const edit =
      !!getQueryObj().edit && getPermissions['product_card_edit_access'];
    // if (edit) {
    //   setTimeout(() => {
    //     setIsEdit(edit);
    //   }, 1000);
    // }
    return () => {
      dispatch(onClearByEditIdData());
    };
  }, []);

  useEffect(() => {
    if (editProduct) {
      const obj = { ...editProduct };
      obj.property_categories = item.property_categories;
      obj.tags = tags;
      setEditData(obj);
    }
  }, [editProduct]);

  const handleLangData = key => value => {
    const copyData = JSON.parse(JSON.stringify(editData));
    if (key === 'name') {
      copyData[key][lang] = value;
    } else {
      copyData[key] = value;
    }

    setEditData(copyData);
  };

  const { t } = useTranslation(['product_details', 'common'], {
    useSuspense: false,
  });
  const { color } = useTheme();

  const handelDetailCard = id => {
    if (choseIdCard !== id) {
      setOpenCard(true);
      setChoseIdCard(id);
    } else {
      setOpenCard(!openCard);
      setChoseIdCard(openCard ? null : id);
    }
  };

  const handleChoseItem = param => {
    dispatch(
      setOpenNotification({
        open: true,
        message: t('common:notifications.added_to_cart'),
        action: (
          <ButtonBase onClick={handelMoveToCart} sx={{ height: '25px' }}>
            {t('common:buttons.view')}
          </ButtonBase>
        ),
      }),
    );

    handleDispatchFetch(
      ({ onResolve, onReject }) =>
        dispatch(
          cartOperation.addProductToCart({
            id: param.id,
            quantity: param?.relation_quantity ? param?.relation_quantity : 1,
            onResolve,
            onReject,
          }),
        ),
      data => handleUpdateProductDetails(data, param.id),
      error => {},
    );
  };

  const handleUpdateProductDetails = (data, productId) => {
    const addedToCardProducts = data.data.products;
    dispatch(updateProductById({ addedToCardProducts, productId }));
  };

  useEffect(() => {
    fetchTags();
    if (item) {
      setMainImage(item.images[0]?.big || ProductNoPhoto);
    }

    // if (item && item.labels) {
    //   const newTags = { ...tags };
    //
    //   Object.values(item.labels).forEach(el => {
    //     newTags[el.field] = el.isActive;
    //   });
    //
    //   setTags(newTags);
    // }
  }, [item]);

  const choseImage = img => {
    setMainImage(img || ProductNoPhoto);
  };

  const handleSaveAnalogueProduct = (e, saved, id) => {
    if (saved) {
      handleDispatchFetch(
        ({ onResolve, onReject }) =>
          dispatch(
            favoriteOperation.deleteProductFromSaved({
              id,
              onResolve,
              onReject,
            }),
          ),
        () => dispatch(productOperation.getProductById({ id: item.id })),
        error => {},
      );
    } else {
      dispatch(
        setOpenModal({
          open: true,
          key: 'favorite',
          id: id,
          notification_open: true,
          reqAfterClose: () =>
            dispatch(productOperation.getProductById({ id: item.id })),
        }),
      );
      dispatch(
        setOpenNotification({
          width: 'initial',
          message: t('common:notifications.product_saved'),
          key: 'favorite-notification',
        }),
      );
    }
  };

  const addOrRemoveComparisons = (id, isCompare) => {
    if (isCompare) {
      handleDispatchFetch(
        ({ onResolve, onReject }) =>
          dispatch(
            comparisonsOperation.removeFromComparisons({
              id,
              onResolve,
              onReject,
            }),
          ),
        () => dispatch(productOperation.getProductById({ id: item.id })),
        error => {},
      );
    } else {
      handleDispatchFetch(
        ({ onResolve, onReject }) =>
          dispatch(
            comparisonsOperation.addToComparisons({
              id,
              onResolve,
              onReject,
            }),
          ),
        () => dispatch(productOperation.getProductById({ id: item.id })),
        error => {},
      );
    }
  };

  const validation = () => {
    let currentError = {};
    let flag = false;
    if (!editData.name.uk || !editData.name.en) {
      currentError = getErrorStack(
        currentError,
        'name',
        t('validation:required_name_product_all_langs'),
      );
      flag = true;
    }
    if (!editData.model) {
      currentError = getErrorStack(
        currentError,
        'model',
        t('validation:required'),
      );
      flag = true;
    }
    if (!editData.brand_id || editData.brand_id === 'none') {
      currentError = getErrorStack(
        currentError,
        'brand_id',
        t('validation:required'),
      );
      flag = true;
    }
    if (!editData.sku) {
      currentError = getErrorStack(
        currentError,
        'sku',
        t('validation:required'),
      );
      flag = true;
    }
    setError(currentError);
    return flag;
  };

  const onChange = name => value => {
    const newObj = { ...JSON.parse(JSON.stringify(editData)) };

    if (name === 'description' || name === 'introText') {
      newObj[name][lang] = value;
      setEditData(newObj);
    } else {
      setEditData(prevState => ({ ...prevState, [name]: value }));
    }
  };

  // UNCOMENT WHEN GET TAGS FROM API
  // const onSave = (key, value) => {
  //   editData.tags[key] = value;
  //   setEditData(prevState => ({ ...prevState, tags: editData.tags }));
  // };

  const onSave = (key, value) => {
    const tagsArr = JSON.parse(JSON.stringify(tags));
    const tagToSet = tagsArr.find(el => el.field === key);
    tagToSet.isActive = value;
    setTags(tagsArr);
  };

  const handleSubmitEditData = async (isClose, isPublicNew = false) => {
    if (validation()) return;

    const form = {
      ...editData,
      labels: tags.reduce((obj, el) => {
        obj[el.field] = el.isActive;
        return obj;
      }, {}),
      description: {
        uk: editData.description?.uk || '',
        en: editData.description.en || '',
        ru: editData.description.ru || '',
      },
      introText: {
        uk: editData.introText.uk || '',
        en: editData.introText.en || '',
        ru: editData.introText.ru || '',
      },
    };

    try {
      if (!isCreate && filtersSettingChanged) {
        await dispatch(
          productOperation.storeProductFilters({
            id: editData.id,
            formData: serialize(getNewFilterSettings),
          }),
        );
      }

      if (!isCreate && getProductPropsDirty) {
        await dispatch(
          productOperation.storePropducProps({
            id: editData.id,
            propsData: getProductProps,
          }),
        );
      }

      const { error, payload } = await dispatch(
        isCreate
          ? productOperation.createProduct({ editData: form })
          : productOperation.editProductByEditId({
              id: editData.id,
              editData: form,
            }),
      );

      if (error) {
        const apiErrorsCallback = payload.response.data.errors;
        let currentError = {};
        Object.keys(apiErrorsCallback).forEach(key => {
          currentError = getErrorStack(
            currentError,
            key,
            apiErrorsCallback[key][0],
          );
        });
        setError(currentError);
        return;
      }

      if (isClose) {
        setIsEdit(!isEdit);
        setEditData(INIT_EDIT_DATA);
        dispatch(onClearByEditIdData());
      }

      const getParamToEdit = isCreate ? '?edit=true' : '';
      navigate(payload.url + getParamToEdit, { replace: true });

      await dispatch(
        setOpenNotification({
          open: true,
          width: 'initial',
          message: t('common:notifications.data_successfully_saved'),
        }),
      );
    } catch (error) {
      alert('error, see console');
      console.error('save error: ', error);
    }
  };

  const handleImageSubmit = async file => {
    if (!isCreate) {
      const formData = serialize({ image: file });
      await dispatch(
        productOperation.addProductImage({ id: item.id, formData }),
      );
    } else {
      if (validation()) return;

      const form = {
        ...editData,
        labels: tags.reduce((obj, el) => {
          obj[el.field] = el.isActive;
          return obj;
        }, {}),
        description: {
          uk: editData.description.uk || '',
          en: editData.description.en || '',
        },
        introText: {
          uk: editData.introText.uk || '',
          en: editData.introText.en || '',
        },
      };

      try {
        const { error, payload } = await dispatch(
          productOperation.createProduct({ editData: form }),
        );

        if (error) {
          const apiErrorsCallback = payload.response.data.errors;
          let currentError = {};
          Object.keys(apiErrorsCallback).forEach(key => {
            currentError = getErrorStack(
              currentError,
              key,
              apiErrorsCallback[key][0],
            );
          });
          setError(currentError);
          return;
        }

        const formData = serialize({ image: file });
        await dispatch(
          productOperation.addProductImage({ id: payload.id, formData }),
        );

        navigate(payload.url, { replace: true });
      } catch (error) {
        console.error('error, see console: ', error);
      }
    }
  };

  const changeIsDraft = () => {
    setEditData(prevState => ({ ...prevState, is_draft: !prevState.is_draft }));
  };

  // GOOGLE ANALYTICS
  useEffect(() => {
    if (category && itemCategoryId) {
      let categoryObject = findCategoryObjectById(category, itemCategoryId);
      let googleAnalyticsInfoObject = {}

      if (categoryObject.parentObject && categoryObject.childObject) {
        googleAnalyticsInfoObject = {
          item_name: `${item.name} ${item.model}`,
          item_id: `${item.sku}`,
          price: `${item.prices.price.product}`,
          item_brand: `${item.brand}`,
          item_category: "Viatec Cabinet",
          item_category2: `${categoryObject.parentObject.name}`,
          item_category3: `${categoryObject.childObject.name}`,
        }
        if(item.labels.new) {
          googleAnalyticsInfoObject.item_list_name = "Новинки"
        }
        if(item.labels.sale) {
          googleAnalyticsInfoObject.item_list_name2 = "Розпродаж"
        }
      }

      if (categoryObject.parentObjectWithoutChildren) {
        googleAnalyticsInfoObject = {
          item_name: `${item.name} ${item.model}`,
          item_id: `${item.sku}`,
          price: `${item.prices.price.product}`,
          item_brand: `${item.brand}`,
          item_category: "Viatec Cabinet",
          item_list_name: `${categoryObject.parentObjectWithoutChildren.name}`,
        }
      }

      setGoogleAnalyticsInfo(googleAnalyticsInfoObject)

      if(!isGoogleAnalyticsInfoSend) {
        window.dataLayer.push({ ecommerce: null });  //  Очищаем предыдущий объект eccomerce
        window.dataLayer.push({
          event: "view_item",
          ecommerce: {
            items: [googleAnalyticsInfoObject]
          }
        });

        setIsGoogleAnalyticsInfoSend(true)
      }
    }
  }, [item, category, itemCategoryId]);
  // END GOOGLE ANALYTICS


  return (
    <ProductBody id="product_details_inner">
      {(loading1c ||
        productEditLoading ||
        (!isCreate && productItemLoading)) && <ProgressLoader position="100" />}

      {userRole === 'admin' && (
        <AdminController
          isCreate={isCreate}
          isEdit={isEdit}
          onChangeMode={event => setIsEdit(event)}
          currentLang={lang}
          handleChangeLang={(event, newValue) => {
            setLang(newValue);
          }}
          onSubmit={handleSubmitEditData}
          isDraft={editData.is_draft}
          changeIsDraft={changeIsDraft}
        />
      )}
      {/*<Tags isEdit={isEdit} tagsList={editData.tags} onSave={onSave} />*/}
      <WrapperForMainContent id="about_product">
        <InView
          onChange={(inView, entry) => inView && setNavSection('about_product')}
        />
        <WrapperForPhotos>
          <Tags isEdit={isEdit} tagsList={tags} onSave={onSave} windowWith={windowWith}/>
          <Box
            style={{
              border: `1px solid ${color.gray_30}`,
              width: windowWith > 550 ? '489px' : '280px',
              height: windowWith > 550 ? '503px' : '300px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              position: 'relative',
                margin: '0 auto'
            }}
          >
            <ProductLabel
              isDiscounted={item.has_markdowns}
              sx={{ position: 'absolute', top: '30px', left: '11px' }}
              itemSx={{ mr: '3px' }}
            />
            {mainImage && (
              <img
                height={420}
                width={windowWith > 550 ? 388 : '100%'}
                src={mainImage}
                alt={item.name}
                style={{ objectFit: 'contain' }}
              />
            )}
          </Box>
          <WrapperForSmallImage>
            <NavigationWrap>
              <NavigationSwiperNext ref={navigationNextRef} color={color}>
                <svg stroke={color.gray_60} width={18} height={18}>
                  <use href={`${Sprite}#icon-arrow-right`}></use>
                </svg>
              </NavigationSwiperNext>
            </NavigationWrap>
            <NavigationWrap>
              <NavigationSwiperPrev ref={navigationPrevRef} color={color}>
                <svg stroke={color.gray_60} width={18} height={18}>
                  <use href={`${Sprite}#icon-arrow-left`}></use>
                </svg>
              </NavigationSwiperPrev>
            </NavigationWrap>
            <Swiper
              modules={[Navigation]}
              style={{
                '--swiper-pagination-color': color.white,
                width: windowWith > 550 ? '480px' : '300px',
              }}
              className="mySwiper"
              speed={600}
              slidesPerView="auto"
              spaceBetween="16"
              navigation={{
                prevEl: navigationPrevRef.current,
                nextEl: navigationNextRef.current,
              }}
            >
              {isEdit && (
                <SwiperSlideStyled style={{ height: '103px' }}>
                  <ProductImageEditor onImgSubmit={handleImageSubmit} />
                </SwiperSlideStyled>
              )}
              {item.images &&
                item.images.map((element, index) => (
                  <SwiperSlideStyled>
                    <Box
                      onClick={() => choseImage(element.big)}
                      key={`product-images-${element.id}`}
                      style={{
                        padding: 11.5,
                        border:
                          mainImage === element.big
                            ? `1px solid ${theme.color.main}`
                            : `1px solid ${theme.color.line}`,
                        borderRadius: '4px',
                        cursor: 'pointer',
                        display: 'inline-block',
                        position: 'relative',
                        overflow: 'hidden',
                      }}
                      sx={{
                        '&:hover': {
                          '.EditHover': {
                            display: 'flex',
                          },
                        },
                      }}
                    >
                      <img
                        width={85}
                        height={78}
                        src={element.thumbnail || ProductNoPhoto}
                        alt={item.name}
                      />
                      {index === 0 && (
                        <MainImgLabel color={color}>
                          {t('product_details:main_img')}
                        </MainImgLabel>
                      )}
                      {isEdit && (
                        <EditHover className="EditHover">
                          {/* <IconBtn
                            href={`${Sprite}#icon-edit`}
                            onClick={() => {}}
                            size={16}
                            sx={{
                              width: '32px',
                              height: '32px',
                              borderRadius: '50%',
                              background: '#fff',
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                            }}
                          /> */}
                          <IconBtn
                            href={`${Sprite}#icon-delete`}
                            onClick={() => {
                              dispatch(
                                productOperation.deleteProductImage({
                                  id: item.id,
                                  imgId: element.id,
                                }),
                              );
                            }}
                            size={16}
                            sx={{
                              width: '32px',
                              height: '32px',
                              borderRadius: '50%',
                              background: '#fff',
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                            }}
                          />
                        </EditHover>
                      )}
                    </Box>
                  </SwiperSlideStyled>
                ))}
            </Swiper>
          </WrapperForSmallImage>
        </WrapperForPhotos>
        <InfoBody>
          <ProductTitle
            isCreate={isCreate}
            data={item}
            isEdit={isEdit}
            editData={editData}
            handleLangData={handleLangData}
            lang={lang}
            error={error}
          />
          <DiscountParentProductLink data={item.markdown_parent?.[0]} />

          {!isCreate && (
            <>
              <HistoryPriceWrapper>
                <PriceInfoWrapper>
                  <Box
                    sx={{
                      display: 'flex',
                      width: '100%',
                      flexDirection: 'row',
                    }}
                  >
                    <CostDetails item={item} />
                    {/* <Typography
                  variant={'bodySmall'}
                  sx={{
                    marginLeft: 'auto',
                    color: theme.color.gray,
                  }}
                >
                  {t('product_details:price_history')}
                </Typography> */}
                  </Box>
                  {item.price[choosedCurrency] > 0 &&
                    userBonus.status !== 'blocked' && (
                      <Typography variant={'bodySmall'} color={color.gray_60}>
                        {t('product_details:recommended_selling_price')}
                        {': '}
                        <Typography variant={'bodyBoldSmall'}>
                          {item.price[choosedCurrency]} {currency}
                        </Typography>
                      </Typography>
                    )}

                  <ActionWrapper>
                    {getPermissions['cart_access'] && (
                      <CartButtonWrapper>
                        {item.stock === 0 &&
                        item.stock_max === 0 &&
                        item.delivery_date ? (
                          <ButtonBase sx={{ padding: '16px 24px' }}>
                            <svg
                              height={24}
                              width={24}
                              style={{ marginRight: '10px' }}
                            >
                              <use href={`${Sprite}#icon-rocket`} />
                            </svg>
                            {t('product_details:make_pre_order')}
                          </ButtonBase>
                        ) : (
                          <>
                            {userBonus.status !== 'blocked' && (
                              <QuantityManagerLarge
                                productCartData={item.cart}
                                itemInCart={item.is_in_cart}
                                isActive={item.can_buy[choosedCurrency]}
                                handleChoseItem={() => handleChoseItem(item)}
                                handleUpdateProductDetails={
                                  handleUpdateProductDetails
                                }
                                availableNumber={item.stock}
                                itemPrice={item.price[choosedCurrency]}
                                productId={item.id}
                                inWatchlist={item.in_watchlist}

                                // item={item}
                                // category={category}
                                // itemCategoryId={itemCategoryId}
                                googleAnalyticsInfo={googleAnalyticsInfo}
                              />
                            )}
                          </>
                        )}
                      </CartButtonWrapper>
                    )}

                    <IconBtn
                      href={`${Sprite}#icon-hart`}
                      onClick={handelOpenSavedModal}
                      size={24}
                      saved={item.is_saved}
                      tooltipText={t('common:tooltip.save')}
                    />
                    {windowWith > 768 && getPermissions['product_compare_access'] && (
                      <IconBtn
                        href={`${Sprite}#icon-counter`}
                        onClick={addOrRemoveFromComparisons}
                        size={24}
                        compare={item.in_compare}
                        tooltipText={t('common:tooltip.compare')}
                      />
                    )}

                    <IconBtn
                      href={`${Sprite}#icon-bell-dollar`}
                      onClick={() => {}}
                      size={24}
                      tooltipText={t('common:tooltip.alert_changing_price')}
                    />
                    {item.related_products.length > 0 && (
                      <Typography
                        variant={'bodyBoldMedium'}
                        sx={{
                          color: theme.color.gray_80,
                        }}
                      >
                        {t('product_details:related_products')}
                      </Typography>
                    )}
                  </ActionWrapper>

                  {userBonus.status === 'blocked' && (
                    <BlockedUserProductCardMessage />
                  )}

                  {item.is_in_cart && (
                    <PriceWrapper>
                      <Typography
                        variant={'bodySmall'}
                        sx={{
                          color: color.gray_80,
                        }}
                      >
                        {t('product_details:products_in_cart_for_amount')}
                      </Typography>
                      <Typography
                        variant={'bodyBoldSmall'}
                        sx={{
                          color: color.gray,
                          marginLeft: '2px',
                        }}
                      >
                        {item.cart.personal_amount[choosedCurrency]} {currency}
                      </Typography>
                    </PriceWrapper>
                  )}

                  {item.stock === 0 &&
                    item.stock_max === 0 &&
                    item.delivery_date && (
                      <PriceWrapper>
                        <Typography
                          variant={'bodySmall'}
                          sx={{
                            color: color.gray_80,
                          }}
                        >
                          {t('product_details:delivery_date')}{' '}
                          {moment(item.delivery_date).format('DD.MM.YYYY')}
                        </Typography>
                      </PriceWrapper>
                    )}

                  {item.related_products.length > 0 && (
                    <OpportunityWrapper>
                      <SliderOpportunityWrapper>
                        <AdvertisingSlider
                          data={item.related_products}
                          handleChoseItem={handleChoseItem}
                          styledContainer={
                            windowWith > 500
                            ? { padding: '16px 0 25px 90px' }
                            : { padding: '16px 15px 25px 15px' }
                          }
                          windowWith={windowWith}
                        />
                      </SliderOpportunityWrapper>
                    </OpportunityWrapper>
                  )}
                </PriceInfoWrapper>
                <HistoryBlock>
                  <Typography
                    variant={'bodyMedium'}
                    sx={{
                      color: theme.color.gray,
                    }}
                  >
                    {t('product_details:already_buy_before')}
                  </Typography>
                  {/* <Typography
                variant={'bodyBoldMedium'}
                sx={{
                  color: theme.color.gray,
                }}
              >
                {t('product_details:view_purchase_history')}
              </Typography> */}
                </HistoryBlock>
              </HistoryPriceWrapper>
              <InStockWrapper>
                <Typography
                  variant={'bodySmall'}
                  sx={{
                    color: theme.color.gray,
                  }}
                >
                  {t('common:in_stock')}:
                </Typography>
                <Availability
                  present={item.stock_percent}
                  inStock={item.stock_max}
                  notAvailable={item.stock}
                />
              </InStockWrapper>
              <DeliveryBlock>
                <DeliveryWrapper>
                  <Typography
                    variant={'bodyBoldMedium'}
                    sx={{
                      color: theme.color.gray,
                    }}
                  >
                    {t('common:delivery')}
                  </Typography>

                  {/* <Svg stroke={color.gray_60} style={{ marginLeft: 'auto' }}>
                <use href={`${Sprite}#icon-plus`}></use>
              </Svg>
              <Typography
                variant={'bodySmall'}
                sx={{
                  color: theme.color.gray,
                  marginLeft: '4px',
                }}
              >
                {t('common:buttons.add_city')}
              </Typography> */}
                </DeliveryWrapper>
                <SelfDeliveryWrapper>
                  <SelfDWInfo>
                    <Typography
                      variant={'bodyMedium'}
                      sx={{
                        color: theme.color.gray_80,
                      }}
                    >
                      {t('common:product_delivery.pickup_and_delivery')}
                    </Typography>
                    <Typography
                      variant={'bodySmall'}
                      sx={{
                        color: theme.color.gray_60,
                      }}
                    >
                      {t(
                        'common:product_delivery.from_point_of_issuing_orders',
                      )}
                    </Typography>
                  </SelfDWInfo>
                  <TextBox>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '5px',
                        flexBasis: '145px',
                      }}
                    >
                      <Typography
                        variant={'bodySmall'}
                        sx={{
                          color: theme.color.gray_60,
                        }}
                      >
                        {t('common:product_delivery.pick_up')}{' '}
                      </Typography>
                      <Typography
                        variant={'bodySmall'}
                        sx={{
                          color: theme.color.main,
                        }}
                      >
                        {t('common:today')}
                      </Typography>
                    </div>
                  </TextBox>
                  <div style={{ flex: '33.33%', textAlign: 'right' }}>
                    <Typography
                      variant={'bodyBoldExSmall'}
                      sx={{
                        background: theme.color.green,
                        borderRadius: '2px',
                        padding: ' 2px 4px',
                        color: theme.color.white,
                        width: windowWith > 1199 ? '122px' : 'auto',
                        display: 'inline-block',
                        textAlign: 'left',
                      }}
                    >
                      {t('common:free')}
                    </Typography>
                  </div>
                </SelfDeliveryWrapper>
                <ListWarehouses data={item} />
                <Divider style={{ width: '100%' }} />
                <DeliveryInfoWrapper>
                  <DeliveryInfo>
                    <Typography
                      variant={'bodyMedium'}
                      sx={{
                        color: theme.color.gray_80,
                      }}
                    >
                      {t(
                        'common:product_delivery.delivery_to_specified_address',
                      )}
                    </Typography>
                    <Typography
                      variant={'bodySmall'}
                      sx={{
                        color: theme.color.gray_60,
                      }}
                    >
                      {t(
                        'common:product_delivery.by_courier_to_specified_address',
                      )}
                    </Typography>
                  </DeliveryInfo>
                  <Typography
                    variant={'bodySmall'}
                    sx={{
                      color: theme.color.gray_60,
                      flex: '33.33%',
                      paddingLeft: '10%',
                    }}
                  >
                    {t('common:product_delivery.deliver')} {deliveryDay}
                  </Typography>

                  <Typography
                    variant={'bodyBoldExSmall'}
                    sx={{
                      width: '122px',
                      color: theme.color.main,
                      textAlign: 'end',
                      flex: '33.33%',
                    }}
                  >
                    {t('common:from')} 120 {t('common:currency')}
                  </Typography>
                </DeliveryInfoWrapper>
                <Divider style={{ width: '100%' }} />
                <DeliveryInfoWrapper>
                  <DeliveryInfo>
                    <Typography
                      variant={'bodyMedium'}
                      sx={{
                        color: theme.color.gray_80,
                      }}
                    >
                      {t('common:product_delivery.pickup_from_branch')}
                    </Typography>
                    <Typography
                      variant={'bodySmall'}
                      sx={{
                        color: theme.color.gray_60,
                      }}
                    >
                      {t('common:product_delivery.meest_nova_poshta_ukrposhta')}
                    </Typography>
                  </DeliveryInfo>
                  <Typography
                    variant={'bodySmall'}
                    sx={{
                      color: theme.color.gray_60,
                      flex: '33.33%',
                      paddingLeft: '10%',
                    }}
                  >
                    {t('common:product_delivery.deliver')} {deliveryDay}
                  </Typography>

                  <Typography
                    variant={'bodyBoldExSmall'}
                    sx={{
                      color: theme.color.main,
                      flex: '33.33%',
                      textAlign: 'right',
                    }}
                  >
                    {t('common:product_delivery.at_carrier_rates')}
                  </Typography>
                </DeliveryInfoWrapper>
                <Divider style={{ width: '100%' }} />
                <DeliveryInfoWrapper>
                  <DeliveryInfo>
                    <Typography
                      variant={'bodyMedium'}
                      sx={{
                        color: theme.color.gray_80,
                      }}
                    >
                      {t('common:product_delivery.by_our_courier')}
                    </Typography>
                    <Typography
                      variant={'bodySmall'}
                      sx={{
                        color: theme.color.gray_60,
                      }}
                    >
                      {t('common:product_delivery.by_adress')}
                    </Typography>
                  </DeliveryInfo>
                  <Typography
                    variant={'bodySmall'}
                    sx={{
                      color: theme.color.gray_60,
                      flex: '33.33%',
                      paddingLeft: '10%',
                    }}
                  >
                    {t('common:product_delivery.deliver')} {deliveryDay}
                  </Typography>

                  <Typography
                    variant={'bodyBoldExSmall'}
                    sx={{
                      color: theme.color.main,
                      flex: '33.33%',
                      textAlign: 'right',
                    }}
                  >
                    {t('common:free')}
                  </Typography>
                </DeliveryInfoWrapper>
                <ListWarehouses data={item} noCounters={true} />
              </DeliveryBlock>
              {item.description && !isEdit && (
                <ProductDetails>
                  <EllipsisText text={item.description || ''} length={500} />
                </ProductDetails>
              )}
            </>
          )}
          <DescriptionWrapper>
            <DescriptionOperations
              isCreate={isCreate}
              isEdit={isEdit}
              description={
                typeof editData.description[lang] === 'string'
                  ? editData.description[lang]
                  : ''
              }
              introText={
                typeof editData?.introText[lang] === 'string'
                  ? editData?.introText[lang]
                  : ''
              }
              onChangeDescription={onChange('description')}
              onChangeIntroText={onChange('introText')}
            />
          </DescriptionWrapper>
        </InfoBody>
      </WrapperForMainContent>

      {!isCreate && (
        <RelatedProducts
          isEdit={isEdit}
          setNavSection={setNavSection}
          item={item}
          handleChoseItem={handleChoseItem}
          windowWith={windowWith}
        />
      )}

      {!isCreate && (
        <>
          <InView
            onChange={(inView, entry) =>
              inView && setNavSection('property_categories')
            }
          >
            <Characteristics
              itemId={item.id}
              isEdit={isEdit}
              characteristics={item?.property_categories}
            />
          </InView>
        </>
      )}

      {!isCreate && (
        <SimilarProducts
          isEdit={isEdit}
          setNavSection={setNavSection}
          handleSaveAnalogueProduct={handleSaveAnalogueProduct}
          addOrRemoveComparisons={addOrRemoveComparisons}
          item={item}
          relatedScoop={relatedScoop}
          openCard={openCard}
          choseIdCard={choseIdCard}
          handleChoseItem={handleChoseItem}
          handelDetailCard={handelDetailCard}
          setRelatedScoop={setRelatedScoop}

          windowWith={windowWith}
          isHideArrowCard={windowWith > 1199 ? false : true}
          mobileCatalogStyles={true}
        />
      )}

      {!isCreate && (
        <DocumentsSection
          isEdit={isEdit}
          setNavSection={setNavSection}
          item={item}
          windowWith={windowWith}
        />
      )}

      <DiscountedGoods
        setNavSection={setNavSection}
        item={item}
        handleChoseItem={handleChoseItem}
      />

      <CategorySettings
        isEdit={isEdit}
        editData={editData}
        handleLangData={handleLangData}
      />

      {!isCreate && isEdit && (
        <ProductFiltersSettings isEdit={isEdit} item={item} />
      )}
    </ProductBody>
  );
};

const DescriptionWrapper = styled.div`
  width: 100%;
`;

const Description = styled.div`
  width: 100%;
`;

const Svg = styled.svg`
  stroke: ${props => props.stroke};
  width: 16px;
  height: 16px;
`;

const SliderOpportunityWrapper = styled.div`
  min-width: calc(100vw - 970px);
  max-width: calc(100vw - 970px);

  @media(max-width: 1199px) {
    //min-width: unset;
    //max-width: unset;
    //width: 100%;
    max-width: 100%;
  }
`;

const SliderWrapper = styled.div`
  padding-top: 25px;
  margin-left: -20px;
  margin-right: -20px;
  background: ${props => props.color};
`;

const PriceWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
`;

const CharacteristicsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 34px 103px;
`;

const ProductDetails = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
`;

const DeliveryInfo = styled.div`
  display: flex;
  flex: 33.33%;
  flex-direction: column;
  align-items: flex-start;
  padding: 6px 0px;
`;

const DeliveryInfoWrapper = styled.div`
  display: flex;
  width: inherit;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
`;

const DeliveryBlock = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
`;

const TextBox = styled.div`
  display: flex;
  flex: 33.33%;
  flex-direction: row;
  gap: 4px;
  padding-left: 10.1%;
`;

const SelfDWInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 33.33%;
`;

const SelfDeliveryWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const DeliveryWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const InStockWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
`;

const HistoryPriceWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const HistoryBlock = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  padding: 8px 16px;
  gap: 10px;
  border: 1px solid ${theme.color.line_light};
  border-radius: 4px;
  background: ${theme.color.line_light};
`;

const OpportunityWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 10px;

  @media(max-width: 1199px) {
    width: 100%;
  }
`;

const ActionWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 34px;

  @media(max-width: 600px) {
    flex-wrap: wrap;
  }
`;

const CartButtonWrapper = styled.div`

  @media(max-width: 600px) {
    width: 100%;
  }
`;

const PriceInfoWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 20px;
  gap: 10px;
  border: 1px solid ${theme.color.line_light};
  border-radius: 4px;
  background: ${theme.color.background};

  @media(max-width: 1199px) {
    padding: 15px;
  }
`;

const InfoBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  flex: 1;
  overflow: hidden;
  max-width: 600px;

  @media(max-width: 1199px) {
    width: 100%;
    max-width: 100%;
  }
`;

const WrapperForSmallImage = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 16px;
  position: relative;
`;

const WrapperForPhotos = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: flex-start;
  position: sticky;
  top: 0;
  gap: 11px;

  @media(max-width: 1199px) {
    width: 100%;
    position: unset;
    align-items: center;
  }
`;

const WrapperForMainContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 24px;

  @media(max-width: 1199px) {
    flex-direction: column;
  }
`;

const ProductBody = styled.div(() => ({
  // position: 'relative',
  display: 'flex',
  height: 'calc(100% - 122px)',
  flexDirection: ' column',
  overflow: 'auto',
  padding: '16px 19px',
  gap: '34px',
  scrollbarWidth: 'thin',
  scrollBehavior: 'smooth',
  '::-webkit-scrollbar-thumb': {
    width: '5px',
    height: '257.27px',
    background: theme.color.gray_40,
    borderRadius: '4px',
  },
  '::-webkit-scrollbar': {
    width: '7px',
    background: theme.color.background,
    border: `1px solid ${theme.color.line}`,
  },
  '@media(max-width: 1199px)': {
    padding: '16px 5px',
  },
}));

const SwiperSlideStyled = styled(SwiperSlide)`
  width: 110px;
`;

const MainImgLabel = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 2px 4px;
  border-top-right-radius: 4px;
  background-color: ${({ color }) => color.background};

  color: ${({ color }) => color.gray_80};
  font-size: 10px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%;
  text-transform: uppercase;
`;

const EditHover = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(43, 48, 66, 0.4);

  display: none;
  justify-content: space-around;
  align-items: center;
  svg {
    pointer-events: none;
  }
`;

const NavigationWrap = styled.div`
  width: 100%;
  height: 1px;
  top: 50%;
  position: absolute;
  z-index: 2;
  user-select: none;
  pointer-events: none;

  .swiper-button-disabled {
    /* display: none; */
    opacity: 0;
    pointer-events: none;
  }
`;

const NavigationSwiperNext = styled.div`
  position: absolute;
  right: 0;
  z-index: 1;
  cursor: pointer;
  transform: translate(0, -50%);
  width: 44px;
  height: 44px;
  border-radius: 2px;
  border: 1px solid ${({ color }) => color.line};
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: auto;
  transition: 0.3s ease;
`;

const NavigationSwiperPrev = styled.div`
  position: absolute;
  left: 0;
  z-index: 3;
  transform: translate(0, -50%);
  width: 44px;
  height: 44px;
  border-radius: 2px;
  border: 1px solid ${({ color }) => color.line};
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: auto;
  transition: 0.3s ease;
`;

ProductInfo.propTypes = {
  handelMoveToCart: PropTypes.func.isRequired,
  item: PropTypes.exact({
    analogue_products: PropTypes.arrayOf(
      PropTypes.exact({
        brand: PropTypes.string,
        can_buy: PropTypes.exact({
          price: PropTypes.bool,
          price_uah_cash: PropTypes.bool,
          price_uah_no_pdv: PropTypes.bool,
          price_uah_pdv: PropTypes.bool,
        }),
        cart: PropTypes.exact({
          cart_product_id: PropTypes.number,
          id: PropTypes.number,
          personal_amount: PropTypes.exact({
            price: PropTypes.string,
            price_uah_cash: PropTypes.string,
            price_uah_no_pdv: PropTypes.string,
            price_uah_pdv: PropTypes.string,
          }),
          quantity: PropTypes.number,
        }),
        description: PropTypes.string,
        has_analogs: PropTypes.bool,
        id: PropTypes.number,
        image: PropTypes.string,
        images_quantity: PropTypes.number,
        in_compare: PropTypes.bool,
        is_in_cart: PropTypes.bool,
        is_saved: PropTypes.bool,
        model: PropTypes.string,
        name: PropTypes.string,
        personal_price: PropTypes.exact({
          price: PropTypes.string,
          price_uah_cash: PropTypes.string,
          price_uah_no_pdv: PropTypes.string,
          price_uah_pdv: PropTypes.string,
        }),
        price: PropTypes.exact({
          price: PropTypes.string,
          price_uah_cash: PropTypes.string,
          price_uah_no_pdv: PropTypes.string,
          price_uah_pdv: PropTypes.string,
        }),
        prices: PropTypes.exact({
          price: PropTypes.exact({
            product: PropTypes.string,
            personal: PropTypes.string,
          }),
          price_uah_cash: PropTypes.exact({
            product: PropTypes.string,
            personal: PropTypes.string,
          }),
          price_uah_no_pdv: PropTypes.exact({
            product: PropTypes.string,
            personal: PropTypes.string,
          }),
          price_uah_pdv: PropTypes.exact({
            product: PropTypes.string,
            personal: PropTypes.string,
          }),
        }),
        sku: PropTypes.string,
        slug: PropTypes.string,
        stock: PropTypes.number,
        stock_max: PropTypes.number,
        stock_percent: PropTypes.number,
        url: PropTypes.string,
        warehouses: PropTypes.arrayOf(
          PropTypes.exact({
            city_name: PropTypes.string,
            warehouses: PropTypes.exact({
              name: PropTypes.string,
              stock: PropTypes.number,
              stock_max: PropTypes.number,
              stock_percent: PropTypes.number,
            }),
          }),
        ),
      }),
    ),
    brand: PropTypes.string,
    can_buy: PropTypes.exact({
      price: PropTypes.bool,
      price_uah_cash: PropTypes.bool,
      price_uah_no_pdv: PropTypes.bool,
      price_uah_pdv: PropTypes.bool,
    }),
    cart: PropTypes.exact({
      cart_product_id: PropTypes.number,
      id: PropTypes.number,
      personal_amount: PropTypes.exact({
        price: PropTypes.string,
        price_uah_cash: PropTypes.string,
        price_uah_no_pdv: PropTypes.string,
        price_uah_pdv: PropTypes.string,
      }),
      quantity: PropTypes.number,
    }),
    description: PropTypes.string,
    documents: PropTypes.array,
    has_analogs: PropTypes.bool,
    id: PropTypes.number,
    image: PropTypes.string,
    images: PropTypes.arrayOf(
      PropTypes.exact({
        big: PropTypes.string,
        full: PropTypes.string,
        thumbnail: PropTypes.string,
        thumbnail_second: PropTypes.string,
      }),
    ),
    images_quantity: PropTypes.number,
    in_compare: PropTypes.bool,
    is_in_cart: PropTypes.bool,
    is_saved: PropTypes.bool,
    model: PropTypes.string,
    name: PropTypes.string,
    personal_price: PropTypes.exact({
      price: PropTypes.string,
      price_uah_cash: PropTypes.string,
      price_uah_no_pdv: PropTypes.string,
      price_uah_pdv: PropTypes.string,
    }),
    price: PropTypes.exact({
      price: PropTypes.string,
      price_uah_cash: PropTypes.string,
      price_uah_no_pdv: PropTypes.string,
      price_uah_pdv: PropTypes.string,
    }),
    prices: PropTypes.exact({
      price: PropTypes.exact({
        product: PropTypes.string,
        personal: PropTypes.string,
      }),
      price_uah_cash: PropTypes.exact({
        product: PropTypes.string,
        personal: PropTypes.string,
      }),
      price_uah_no_pdv: PropTypes.exact({
        product: PropTypes.string,
        personal: PropTypes.string,
      }),
      price_uah_pdv: PropTypes.exact({
        product: PropTypes.string,
        personal: PropTypes.string,
      }),
    }),
    property_categories: PropTypes.arrayOf(
      PropTypes.exact({
        id: PropTypes.number,
        name: PropTypes.string,
        properties: PropTypes.arrayOf(
          PropTypes.exact({
            id: PropTypes.number,
            is_pinned: PropTypes.bool,
            name: PropTypes.string,
            value: PropTypes.string,
          }),
        ),
      }),
    ),
    related_products: PropTypes.arrayOf(
      PropTypes.exact({
        brand: PropTypes.string,
        can_buy: PropTypes.exact({
          price: PropTypes.bool,
          price_uah_cash: PropTypes.bool,
          price_uah_no_pdv: PropTypes.bool,
          price_uah_pdv: PropTypes.bool,
        }),
        cart: PropTypes.exact({
          cart_product_id: PropTypes.number,
          id: PropTypes.number,
          personal_amount: PropTypes.exact({
            price: PropTypes.string,
            price_uah_cash: PropTypes.string,
            price_uah_no_pdv: PropTypes.string,
            price_uah_pdv: PropTypes.string,
          }),
          quantity: PropTypes.number,
        }),
        description: PropTypes.string,
        has_analogs: PropTypes.bool,
        id: PropTypes.number,
        image: PropTypes.string,
        images_quantity: PropTypes.number,
        in_compare: PropTypes.bool,
        is_in_cart: PropTypes.bool,
        is_saved: PropTypes.bool,
        model: PropTypes.string,
        name: PropTypes.string,
        personal_price: PropTypes.exact({
          price: PropTypes.string,
          price_uah_cash: PropTypes.string,
          price_uah_no_pdv: PropTypes.string,
          price_uah_pdv: PropTypes.string,
        }),
        price: PropTypes.exact({
          price: PropTypes.string,
          price_uah_cash: PropTypes.string,
          price_uah_no_pdv: PropTypes.string,
          price_uah_pdv: PropTypes.string,
        }),
        prices: PropTypes.exact({
          price: PropTypes.exact({
            product: PropTypes.string,
            personal: PropTypes.string,
          }),
          price_uah_cash: PropTypes.exact({
            product: PropTypes.string,
            personal: PropTypes.string,
          }),
          price_uah_no_pdv: PropTypes.exact({
            product: PropTypes.string,
            personal: PropTypes.string,
          }),
          price_uah_pdv: PropTypes.exact({
            product: PropTypes.string,
            personal: PropTypes.string,
          }),
        }),
        relation_quantity: PropTypes.number,
        sku: PropTypes.string,
        slug: PropTypes.string,
        stock: PropTypes.number,
        stock_max: PropTypes.number,
        stock_percent: PropTypes.number,
        url: PropTypes.string,
        warehouses: PropTypes.arrayOf(
          PropTypes.exact({
            city_name: PropTypes.string,
            warehouses: PropTypes.exact({
              name: PropTypes.string,
              stock: PropTypes.number,
              stock_max: PropTypes.number,
              stock_percent: PropTypes.number,
            }),
          }),
        ),
      }),
    ),
  }),
  setNavSection: PropTypes.func.isRequired,
  handelOpenSavedModal: PropTypes.func.isRequired,
  handelMoveToFavorite: PropTypes.func.isRequired,
  addOrRemoveFromComparisons: PropTypes.func.isRequired,
};
