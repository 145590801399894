import * as React from 'react';
import { useTranslation } from 'react-i18next';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Typography, useTheme } from '@mui/material';
import TableCell from '@mui/material/TableCell';

export const Status = ({ style, status, invited }) => {
  const { t } = useTranslation(['admin'], { useSuspense: false });
  const { color } = useTheme();

  const getStatusText = status => {
    switch (status) {
      case 'active':
        return t('admin:active_status');
      case 'inactive':
        if (invited) {
          return t('admin:invited_status');
        } else {
          return t('admin:inactive_status');
        }
      case 'invited':
        return t('admin:invited_status');
      default:
        return 'unknown status';
    }
  };
  const getStatusColorStyle = status => {
    switch (status) {
      case 'active':
        return `${color.gray} !important`;
      case 'inactive':
        if (invited) {
          return `${color.orange} !important`;
        } else {
          return `${color.dark_red} !important`;
        }
      case 'invited':
        return `${color.orange} !important`;
      default:
        return 'unknown status';
    }
  };
  const getStatusColor = status => {
    switch (status) {
      case 'active':
        return color.green;
      case 'inactive':
        if (invited) {
          return color.orange;
        } else {
          return color.dark_red;
        }
      case 'invited':
        return color.orange;
      default:
        return 'unknown status';
    }
  };

  return (
    <TableCell
      component="th"
      scope="row"
      sx={{
        p: 0,
        ...style,
        display: 'flex',
        flexDirection: 'row',
        gap: '4px',
        alignItems: 'center',
      }}
    >
      <StatusCircle
        color={getStatusColor(status)}
      />
      <Typography
        sx={{
          color: getStatusColorStyle(status),
        }}
        variant={'bodySmall'}
        component={'p'}
      >
        {getStatusText(status)}
      </Typography>
    </TableCell>
  );
};

const StatusCircle = styled.div`
  background: ${props => props.color};
  height: 8px;
  width: 8px;
  border-radius: 50%;
`;

Status.propTypes = {
  style: PropTypes.object,
  status: PropTypes.string,
};
