import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const getEmployees = createAsyncThunk(
  'employees/getEmployees',
  async ({ page, per_page, onResolve, onReject }, { rejectWithValue }) => {
    try {
      const response = await axios.get(`employees`, {
        params: { page, per_page },
      });
      if (onResolve) onResolve();
      return response.data;
    } catch (error) {
      if (onReject) onReject();
      return rejectWithValue(error);
    }
  },
);

const inviteEmployee = createAsyncThunk(
  'employees/inviteEmployee',
  async ({ onResolve, onReject, ...restPayload }, { rejectWithValue }) => {
    try {
      const response = await axios.post(`employees`, restPayload);
      if (onResolve) onResolve();
      return response.data;
    } catch (error) {
      if (onReject) onReject(error.response.data);
      return rejectWithValue(error);
    }
  },
);

const reinviteEmployeeById = createAsyncThunk(
  'employees/reinviteEmployeeById',
  async ({ id, onResolve, onReject }, { rejectWithValue }) => {
    try {
      await axios.post(`employees/${id}/reinvite`);
      if (onResolve) onResolve();
      return id;
    } catch (error) {
      if (onReject) onReject();
      return rejectWithValue(error);
    }
  },
);

const deleteEmployeeById = createAsyncThunk(
  'employees/deleteEmployeeById',
  async ({ id, onResolve, onReject, payload }, { rejectWithValue }) => {
    const { page, per_page } = payload;
    try {
      const { data } = await axios.delete(`employees/${id}`, {
        params: { page, per_page },
      });
      if (onResolve) onResolve();
      return data;
    } catch (error) {
      if (onReject) onReject();
      return rejectWithValue(error);
    }
  },
);

const updateEmployeeById = createAsyncThunk(
  'employees/updateEmployeeById',
  async ({ id, payload, onResolve, onReject }, { rejectWithValue }) => {
    try {
      const { data } = await axios.post(`employees/${id}`, payload);
      if (onResolve) onResolve();
      return { id, response: data.data };
    } catch (error) {
      if (onReject) onReject();
      return rejectWithValue(error);
    }
  },
);

const toggleSignInByMessageById = createAsyncThunk(
  'employees/toggleSingInByMessage',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(``);
      return response.data.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export default {
  getEmployees,
  inviteEmployee,
  updateEmployeeById,
  toggleSignInByMessageById,
  deleteEmployeeById,
  reinviteEmployeeById,
};
