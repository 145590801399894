import { getMonth, getYear } from 'date-fns';
import range from 'lodash/range';

import { Arrow_Back } from '@root/assets/svg';
import { theme } from '@root/styles';

import { FormControl, Select } from '@mui/material';

const handleSelectYear = (value, actionType) => {
  actionType(value);
};
const handleSelectMonth = (value, actionType, options) => {
  actionType(options.indexOf(value));
};
const customSelect = (options, val, onChange, action) => {
  return (
    <Select
      native
      value={val}
      onChange={e => onChange(e.target.value, action, options)}
      sx={{
        color: theme.color.gray,
        fontWeight: 700,
        fontSize: '0.875rem',
      }}
    >
      {options.map((option, i) => (
        <option key={option + i} value={option}>
          {option}
        </option>
      ))}
    </Select>
  );
};

export const customDatePickerHeader = (
  date,
  changeYear,
  changeMonth,
  decreaseMonth,
  increaseMonth,
  prevMonthButtonDisabled,
  nextMonthButtonDisabled,
  monthsList,
  minDate,
) => {
  const years = range(1950, getYear(new Date()) + 1, 1);

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        padding: '5px',
      }}
    >
      <button
        type="button"
        onClick={decreaseMonth}
        disabled={prevMonthButtonDisabled}
        style={{
          cursor: 'pointer',
        }}
      >
        <img src={Arrow_Back} alt="Icon Placeholder" />
      </button>
      <FormControl
        disabled={minDate ? minDate : false}
        sx={{ minWidth: 70 }}
        size="small"
      >
        {customSelect(years, getYear(date), handleSelectYear, changeYear)}
      </FormControl>
      <FormControl
        sx={{
          minWidth: 70,
          select: {
            textTransform: 'capitalize',
            option: { textTransform: 'capitalize' },
          },
        }}
        size="small"
      >
        {customSelect(
          monthsList,
          monthsList[getMonth(date)],
          handleSelectMonth,
          changeMonth,
        )}
      </FormControl>
      <button
        type="button"
        onClick={increaseMonth}
        disabled={nextMonthButtonDisabled}
        style={{
          cursor: 'pointer',
        }}
      >
        <img
          src={Arrow_Back}
          alt="arrow"
          style={{
            transform: 'rotate(180deg)',
          }}
        />
      </button>
    </div>
  );
};
