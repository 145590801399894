import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Sprite } from '@root/assets/svg';
import { BasicSelect, IconBtn, OutlineButtonBase, TextInput } from '@root/ui';

import permissionOperation from '@redux/permission/permission-operation';
import permissionSelectors from '@redux/permission/permission-selectors';

import { Typography, useTheme } from '@mui/material';

export const InviteEmployees = ({
  employees,
  handelAddEmployees,
  deleteEmployees,
  onChange,
  error,
}) => {
  const { t } = useTranslation();
  const { color } = useTheme();
  const permissionList = useSelector(permissionSelectors.getPermission);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(permissionOperation.getPermission({}));
  }, []);

  return (
    <InviteEmployeesWrapper>
      <EmployeesWrapper>
        {employees &&
          employees.map(({ email, role, id }, i) => (
            <WrapperRole color={color.line_light} key={i}>
              <WrapperFlex>
                <Typography variant={'bodyBoldMedium'} component={'p'}>
                  {t('registration:step_five.employee')} {i + 1}
                </Typography>
                <IconBtn
                  href={`${Sprite}#icon-bin`}
                  onClick={() => {
                    deleteEmployees(id);
                  }}
                />
              </WrapperFlex>
              <TextInput
                error={error[i]?.email?.init}
                errorText={error[i]?.email?.text}
                sx={{ mt: '20px', width: '100%', height: '48px' }}
                value={email}
                onChange={onChange('employees', i, 'email')}
                placeholder={'Email'}
                type="text"
                withOutSpace={true}
                autoComplete={'email'}
                inputProps={{
                  maxLength: 255,
                }}
                data-test-id="EmployeesEmail"
              />
              <BasicSelect
                currentId={role}
                data={permissionList}
                name={t('registration:field.role')}
                callBack={onChange('employees', i, 'role')}
                sx={{ mt: '20px' }}
                helperText={error[i]?.role?.text}
                error={error[i]?.role?.init}
                height="48px"
                data-test-id="EmployeesRolesSelect"
                dataTestItem="EmployeesRolesItem"
              />
            </WrapperRole>
          ))}
      </EmployeesWrapper>

      {employees && employees.length < 10 && (
        <OutlineButtonBase
          type="button"
          sx={{ mt: '10px', width: '100%' }}
          onClick={handelAddEmployees}
        >
          <Svg width="16" height="16">
            <use href={`${Sprite}#icon-plus`}></use>
          </Svg>
          <Typography variant={'bodySmall'} align={'center'}>
            {t('registration:step_five.add_employee_btn')}
          </Typography>
        </OutlineButtonBase>
      )}
    </InviteEmployeesWrapper>
  );
};

const InviteEmployeesWrapper = styled.div``;
const Svg = styled.svg``;

const WrapperRole = styled.div`
  padding: 20px;
  background: ${props => props.color};
  border-radius: 4px;
`;

const EmployeesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 28px;
`;

const WrapperFlex = styled.div`
  display: flex;
  justify-content: space-between;
`;

InviteEmployees.propTypes = {
  employees: PropTypes.array,
  handelAddEmployees: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  error: PropTypes.array,
};
