import React, { useContext, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  AutoSizer,
  CellMeasurer,
  CellMeasurerCache,
  List,
} from 'react-virtualized';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Empty, Header, VirtualizedTable } from '@root/components';
import { MutualSettlementsConf } from '@root/conf';
import { handleDispatchFetch } from '@root/helpers/handleDispatchFetch';
import { WidthContext } from '@root/providers/WidthProvider';
import mutualSettlementsOperation from '@root/redux/mutual-settlements/mutual-settlements-operation';
import { setOpenNotification } from '@root/redux/notification-watcher/notification-watcher-slice';
import userSelectors from '@root/redux/user/user-selectors';
import { LabelStatus, ProgressLoader } from '@root/ui';
import { ButtonBase } from '@root/ui/Button/ButtonBase/index';
import { OutlineButtonBase } from '@root/ui/Button/OutlineButton/index';

import mutualSettlementsSelectors from '@redux/mutual-settlements/mutual-settlements-selectors';
import {
  setSearchValue,
  setSelectMutualSettlement,
} from '@redux/mutual-settlements/mutual-settlements-slice';

import { TableBody, TableCell, TableRow, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

export const MutualSettlementsHistory = ({
  selectedValue,
  onCloseSelect,
  activeRadioBtn,
  setActiveRadioBtn,
  handleFetchMutualSettlementsHistory,
  getSelectedValue,
  onItemClick,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [isFilterExpanded, setIsFilterExpanded] = useState(false);
  const [activeRow, setActiveRow] = useState();
  const userBonus = useSelector(userSelectors.getUserProfile)?.bonuses;
  const [confirmBonusModal, setConfirmBonusModal] = useState(
    userBonus.status !== 'ok',
  );

  const { screenWidth } = useContext(WidthContext);

  const { t } = useTranslation(['mutual_settlements']);
  const { color } = useTheme();

  const mutualSettlements = useSelector(
    mutualSettlementsSelectors.getMutualSettlements,
  );
  const mutualSettlementsTypes = useSelector(
    mutualSettlementsSelectors.getMutualSettlementsTypes,
  );
  const loading = useSelector(
    mutualSettlementsSelectors.getIsMutualSettlementsLoading,
  );

  const filteredMutualSettlements = useSelector(
    mutualSettlementsSelectors.getFilteredMutualSettlements,
  );

  const searchTerm = useSelector(mutualSettlementsSelectors.getSearchValue);

  const dispatch = useDispatch();

  const cache = useRef(
    new CellMeasurerCache({
      fixedWidth: false,
      defaultHeight: 34,
    }),
  );

  const tableBodyHeight = `calc(100vh - 285px - ${isExpanded ? 56 : 0}px - ${
    isFilterExpanded ? 232.5 : 0
  }px)`;

  const noHistoryHeight =
    screenWidth < 1400
      ? 'auto'
      : `calc(100vh - 251px - ${isExpanded ? 56 : 0}px - ${
          isFilterExpanded ? 232.5 : 0
        }px)`;

  const handleToggleSearchExpended = () => {
    setIsExpanded(prevIsExpanded => !prevIsExpanded);
  };

  const handleToggleFilterExpended = () => {
    setIsFilterExpanded(prevIsFilterExpanded => !prevIsFilterExpanded);
  };

  const onSearchTermUpdate = searchValue => {
    dispatch(setSearchValue(searchValue));
  };

  const handleSetActiveRow = data => {
    onItemClick();
    const { number } = data;
    setActiveRow(number);
    dispatch(setSelectMutualSettlement(data));
  };

  const handleFetchMutualSettlements = ({ date_from, date_to }) => {
    handleFetchMutualSettlementsHistory({
      date_from,
      date_to,
      type: activeRadioBtn,
      document_type: selectedValue,
    });
  };

  const solveCompanyBonus = flag => {
    handleDispatchFetch(
      ({ onResolve, onReject }) =>
        dispatch(
          mutualSettlementsOperation.confirmBonus({
            flag,
            onResolve,
            onReject,
          }),
        ),
      () => {
        setConfirmBonusModal(false);
        dispatch(
          setOpenNotification({
            open: true,
            width: 'initial',
            message: t('common:buttons.ready'),
          }),
        );
      },
      () => {},
    );
  };

  const MutualSettlementsTable = () => {
    return (
      <VirtualizedTable
        isLoading={false}
        tableConf={MutualSettlementsConf}
        checkIsInclude={false}
        isTopBorder={false}
      >
        <TableBody
          sx={{
            height: tableBodyHeight,
            display: 'block',
          }}
        >
          <ScrollWrapper
            colors={{
              gray: color.gray_40,
              white: color.white,
              line: color.line,
            }}
          >
            <AutoSizer>
              {({ width, height }) => (
                <List
                  width={width}
                  height={height}
                  rowHeight={cache.current.rowHeight}
                  defferedMeasurementCache={cache.current}
                  rowCount={filteredMutualSettlements.length}
                  rowRenderer={({ key, index, style, parent }) => {
                    const data = filteredMutualSettlements[index];

                    return (
                      <CellMeasurer
                        key={key}
                        cache={cache.current}
                        parent={parent}
                        columnIndex={0}
                        rowIndex={index}
                      >
                        <TableRow
                          onClick={() => handleSetActiveRow(data)}
                          sx={{
                            minHeight: '34px',
                            paddingTop: '5px',
                            paddingBottom: '5px',
                            borderBottom: `1px solid ${color.line}`,
                            '&:last-child, &:last-child td': {
                              borderBottom: 0,
                            },
                            userSelect: 'auto',
                            boxShadow: 'none',
                            th: {
                              borderBottom: 'none',
                            },
                            display: 'flex',
                            alignItems: 'center',
                            padding: '4px 16px',
                            cursor: 'pointer',
                            background:
                              activeRow === data.number
                                ? color.main_light
                                : color.white,
                            borderLeft:
                              activeRow === data.number
                                ? `3px solid ${color.main}`
                                : 'none',

                            '&:hover': {
                              background:
                                activeRow !== data.number && color.gray_20,
                            },
                          }}
                          style={style}
                        >
                          {MutualSettlementsConf(t, screenWidth).map(
                            delivery => {
                              switch (delivery.slug) {
                                case 'date':
                                  return (
                                    delivery.isDisplay && (
                                      <TableCell
                                        sx={{
                                          borderBottom: 'none',
                                          ...delivery.style,
                                          p: 0,
                                        }}
                                        key={delivery.slug}
                                      >
                                        <MobileWrapper gap="8px">
                                          {screenWidth < 769 && (
                                            <Typography
                                              variant="bodyBoldSmall"
                                              color={color.gray}
                                            >
                                              #{data.number}
                                            </Typography>
                                          )}

                                          <MobileWrapper gap="3px">
                                            {screenWidth < 769 && (
                                              <Typography
                                                variant="bodySmall"
                                                color={color.red}
                                              >
                                                <Typography
                                                  variant="bodyBoldSmall"
                                                  component="span"
                                                  color={color.gray_60}
                                                >
                                                  {t(
                                                    'mutual_settlements:conf.expenses',
                                                  )}
                                                  :
                                                </Typography>
                                                &nbsp;{' '}
                                                {data.money_minus !== 0
                                                  ? data.money_minus
                                                  : ''}
                                              </Typography>
                                            )}

                                            {screenWidth < 769 && (
                                              <Typography
                                                variant="bodySmall"
                                                color={color.green}
                                              >
                                                <Typography
                                                  variant="bodyBoldSmall"
                                                  component="span"
                                                  color={color.gray_60}
                                                >
                                                  {t(
                                                    'mutual_settlements:conf.income',
                                                  )}
                                                  :
                                                </Typography>
                                                &nbsp;{' '}
                                                {data.money_plus > 0
                                                  ? data.money_plus
                                                  : ''}
                                              </Typography>
                                            )}
                                          </MobileWrapper>

                                          {screenWidth < 769 && (
                                            <LabelStatus
                                              styles={{ alignSelf: 'start' }}
                                            >
                                              <Typography
                                                color={`${color.gray_80} !important`}
                                                variant="bodyBoldExSmall"
                                              >
                                                {data.document_type}
                                              </Typography>
                                            </LabelStatus>
                                          )}

                                          <Typography
                                            variant="bodySmall"
                                            color={color.gray_80}
                                          >
                                            {data.date}
                                          </Typography>
                                        </MobileWrapper>
                                      </TableCell>
                                    )
                                  );

                                case 'number':
                                  return (
                                    delivery.isDisplay && (
                                      <TableCell
                                        sx={{
                                          borderBottom: 'none',
                                          ...delivery.style,
                                          p: 0,
                                        }}
                                        key={delivery.slug}
                                      >
                                        <Typography
                                          variant="bodyBoldSmall"
                                          color={color.gray}
                                        >
                                          {data.number}
                                        </Typography>
                                      </TableCell>
                                    )
                                  );

                                case 'document_type':
                                  return (
                                    delivery.isDisplay && (
                                      <TableCell
                                        sx={{
                                          borderBottom: 'none',
                                          ...delivery.style,
                                          p: 0,
                                        }}
                                        key={delivery.slug}
                                      >
                                        <LabelStatus>
                                          <Typography
                                            color={`${color.gray_80} !important`}
                                            variant="bodyBoldExSmall"
                                          >
                                            {data.document_type}
                                          </Typography>
                                        </LabelStatus>
                                      </TableCell>
                                    )
                                  );

                                case 'expenses':
                                  return (
                                    delivery.isDisplay && (
                                      <TableCell
                                        sx={{
                                          borderBottom: 'none',
                                          ...delivery.style,
                                          p: 0,
                                        }}
                                        key={delivery.slug}
                                      >
                                        <Typography
                                          variant="bodySmall"
                                          color={color.red}
                                        >
                                          {data.money_minus !== 0
                                            ? data.money_minus
                                            : '0'}{' '}
                                        </Typography>
                                      </TableCell>
                                    )
                                  );

                                case 'income':
                                  return (
                                    delivery.isDisplay && (
                                      <TableCell
                                        sx={{
                                          borderBottom: 'none',
                                          ...delivery.style,
                                          p: 0,
                                        }}
                                        key={delivery.slug}
                                      >
                                        <Typography
                                          variant="bodySmall"
                                          color={color.green}
                                        >
                                          {data.money_plus > 0
                                            ? data.money_plus
                                            : '0'}
                                        </Typography>
                                      </TableCell>
                                    )
                                  );
                                default:
                                  return '';
                              }
                            },
                          )}
                        </TableRow>
                      </CellMeasurer>
                    );
                  }}
                />
              )}
            </AutoSizer>
          </ScrollWrapper>
        </TableBody>
      </VirtualizedTable>
    );
  };

  const additionalContent = () => {
    return (
      <Balance>
        <BalanceItem>
          <Typography color={color.gray_60} variant="bodyExSmall">
            {t('mutual_settlements:initial_balance')}
          </Typography>
          <Typography color={color.gray} variant="bodyBoldExSmall">
            {mutualSettlements.length && mutualSettlements[0].start_balance}
          </Typography>
        </BalanceItem>
        <BalanceItem>
          <Typography color={color.gray_60} variant="bodyExSmall">
            {t('mutual_settlements:ending_balance')}
          </Typography>
          <Typography color={color.gray} variant="bodyBoldExSmall">
            {mutualSettlements.length &&
              mutualSettlements[mutualSettlements.length - 1].end_balance}
          </Typography>
        </BalanceItem>
      </Balance>
    );
  };

  return (
    <MutualSettlementsHistoryWrapper
      colorBorder={color.line}
      colorBg={color.white}
    >
      {confirmBonusModal && (
        <BallanceWarning color={color}>
          <div>
            <Typography
              variant="bodySmall"
              color={color.gray_80}
              component="div"
            >
              Ваш баланс на 1{' '}
              {
                [
                  'січня',
                  'лютого',
                  'березня',
                  'квітня',
                  'травня',
                  'червня',
                  'липня',
                  'серпня',
                  'вересня',
                  'жовтня',
                  'листопада',
                  'грудня',
                ][new Date().getMonth()]
              }{' '}
              {new Date().getFullYear()} року складає:
            </Typography>
            <Typography
              variant="bodyBoldSmall"
              color={color.main}
              component="div"
              sx={{ mt: '4px' }}
            >
              {userBonus.last_confirmed_bonus}$
            </Typography>
          </div>
          <WarningBtnWrapper>
            <OutlineButtonBase
              onClick={() => {
                solveCompanyBonus(false);
              }}
            >
              Не підтверджую
            </OutlineButtonBase>
            <ButtonBase
              onClick={() => {
                solveCompanyBonus(true);
              }}
            >
              Підтверджую
            </ButtonBase>
          </WarningBtnWrapper>
        </BallanceWarning>
      )}
      <Header
        useFilter={true}
        isFilterExpanded={isFilterExpanded}
        isExpanded={isExpanded}
        handleFetchDeliveryHistory={handleFetchMutualSettlements}
        handleToggleSearchExpended={handleToggleSearchExpended}
        handleToggleFilterExpended={handleToggleFilterExpended}
        isTopBorder={false}
        isBottomBorder={true}
        onSearchTermUpdate={onSearchTermUpdate}
        additionalContent={additionalContent}
        selectData={mutualSettlementsTypes}
        getSelectedValue={val => getSelectedValue(val)}
        selectedValue={selectedValue}
        activeRadioBtn={activeRadioBtn}
        setActiveRadioBtn={setActiveRadioBtn}
        onCloseSelect={onCloseSelect}
      />
      {loading && <ProgressLoader />}
      {filteredMutualSettlements.length > 0 && MutualSettlementsTable()}

      {searchTerm === '' && filteredMutualSettlements.length === 0 && (
        <NoHistoryWrapper height={noHistoryHeight}>
          <Empty type={'NoHistory'} sx={{ height: 'auto' }} />
        </NoHistoryWrapper>
      )}

      {searchTerm !== '' && filteredMutualSettlements.length === 0 && (
        <NoHistoryWrapper height={noHistoryHeight}>
          <Empty type={'EmptySearchLogo'} />
        </NoHistoryWrapper>
      )}
    </MutualSettlementsHistoryWrapper>
  );
};

const MutualSettlementsHistoryWrapper = styled.div`
  flex: 0 0 50%;
  border-radius: 4px;
  border: ${({ colorBorder }) => `1px solid ${colorBorder}`};
  background: ${({ colorBg }) => colorBg};
  position: relative;
  height: 100%;
`;

const NoHistoryWrapper = styled.div`
  padding: 100px 20px;
  height: ${({ height }) => height};
`;

const MobileWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ gap }) => gap};
`;

const ScrollWrapper = styled.div`
  overflow: hidden;
  height: inherit;
  max-height: inherit;

  div {
    ::-webkit-scrollbar {
      width: 5px;
    }

    ::-webkit-scrollbar-thumb {
      background: ${props => props.colors.gray};
      border-radius: 4px;
    }

    ::-webkit-scrollbar-track {
      background: ${props => props.colors.white};
      border-left: 1px solid ${props => props.colors.line};
    }
  }
`;

const Balance = styled.div`
  display: flex;
  gap: 10px;
  padding-left: 11px;
  padding-bottom: 11px;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const BalanceItem = styled.div`
  width: 120px;
  display: flex;
  column-gap: 10px;
  padding-left: 12px;
`;

const BallanceWarning = styled.div`
  margin: 11px;
  background-color: #fff2dc;
  padding: 8px 16px 10px;
  display: flex;
  gap: 10px;

  @media (max-width: 768px) {
    flex-direction: column;
  }

  & > div:nth-child(1) {
    flex-grow: 1;
  }
  & > div:nth-child(2) {
    button {
      height: 38px;
    }
  }
`;

const WarningBtnWrapper = styled.div`
  display: flex;
  gap: 8px;

  @media (max-width: 576px) {
    flex-direction: column;
  }
`;

MutualSettlementsHistory.propTypes = {
  selectedValue: PropTypes.string,
  activeRadioBtn: PropTypes.string,
  setActiveRadioBtn: PropTypes.func.isRequired,
  handleFetchMutualSettlementsHistory: PropTypes.func.isRequired,
};
