import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import styled from 'styled-components';

import { Double_Check, Sprite } from '@root/assets/svg';
import { correctPhone } from '@root/helpers';
import { BasicTooltip, IconBtn } from '@root/ui';
import { ButtonBase } from '@root/ui/Button/ButtonBase/index';
import { PhoneInput } from '@root/ui/Input/PhoneInput/index';

import { Typography, useTheme } from '@mui/material';

export const Phone = ({
  id,
  variant,
  lastStep,
  value,
  sx,
  onEdit,
  goToStep,
  errors,
}) => {
  const [isEdit, setIsEdit] = useState(false);
  const { t } = useTranslation(['common'], { useSuspense: false });
  const { color } = useTheme();
  const [locValue, setLocValue] = useState(value.map(item => item.phone || ''));

  useEffect(() => {
    if (id) {
      setIsEdit(false);
      setLocValue(value.map(item => item.phone || ''));
    }
  }, [id, value]);

  useEffect(() => {}, []);

  const onPhoneChange = (val, index) => {
    setLocValue(prev => prev.map((phone, i) => (i === index ? val : phone)));
  };

  const onPhoneDelete = index => {
    setLocValue(prev =>
      prev.filter((phone, i) => (index === i ? false : true)),
    );
  };

  const onCancel = () => {
    setIsEdit(false);
    setLocValue(value.map(item => item.phone || ''));
  };

  const addNewPhone = () => {
    setLocValue(prev => [...prev, '']);
  };

  const onEditSuccess = () => {
    setIsEdit(false);
  };
  const onError = () => {};

  return (
    <>
      <FieldDescription>
        <WrapperBox>
          {!isEdit ? (
            value &&
            value.map(({ phone, confirm }, i) => (
              <PhoneWrapper key={i + 200}>
                <Typography
                  sx={{ flexShrink: 0, mr: '8px', ...sx }}
                  variant={variant}
                  component={'p'}
                >
                  {lastStep
                    ? phone
                      ? correctPhone(phone)
                      : '-'
                    : correctPhone(phone)}
                </Typography>
                {confirm && (
                  <BasicTooltip
                    title={t('common:tooltip.confirm')}
                    placement="top"
                  >
                    <img src={Double_Check} alt="Double Check" />
                  </BasicTooltip>
                )}
              </PhoneWrapper>
            ))
          ) : (
            <>
              {locValue.map((phone, i) => (
                <PhoneInputWrap>
                  <PhoneInput
                    key={i}
                    value={phone}
                    onChange={val => {
                      onPhoneChange(val, i);
                    }}
                    error={errors?.init}
                    errorText={errors?.text}
                    onCopy={e => {
                      e.preventDefault();
                    }}
                  />
                  <IconBtn
                    onClick={() => {
                      onPhoneDelete(i);
                    }}
                    href={`${Sprite}#icon-cross`}
                    sx={{ stroke: color.gray }}
                  />
                </PhoneInputWrap>
              ))}
              <ButtonBase onClick={addNewPhone} sx={{ marginRight: '16px' }}>
                {t('common:buttons.add')}
              </ButtonBase>
            </>
          )}
        </WrapperBox>
      </FieldDescription>

      {(onEdit || goToStep) &&
        (!isEdit ? (
          <IconBtn
            href={`${Sprite}#icon-pan`}
            onClick={() => (goToStep ? goToStep() : setIsEdit(true))}
            tooltipText={t('common:tooltip.edit')}
          />
        ) : (
          <IconsWrapper>
            <IconBtn
              onClick={onCancel}
              href={`${Sprite}#icon-cross`}
              sx={{ stroke: color.red }}
            />
            <IconBtn
              onClick={() => {
                onEdit && onEdit(locValue, onEditSuccess, onError);
              }}
              href={`${Sprite}#icon-tick`}
              sx={{ stroke: color.green }}
            />
          </IconsWrapper>
        ))}
    </>
  );
};

const FieldDescription = styled.div`
  flex: 1 1 auto;
`;

const IconsWrapper = styled.div`
  display: flex;
  column-gap: 6px;
`;

const WrapperBox = styled.div`
  display: flex;
  flex-direction: column;
`;

const PhoneWrapper = styled.div`
  display: flex;
`;

const PhoneInputWrap = styled.div`
  display: flex;
  margin-bottom: 5px;
`;
