import { createSlice } from '@reduxjs/toolkit';

import accountingDocumentsOperation from '@redux/accounting-documents/accounting-documents-operation';

const initialState = {
  accountingDocuments: null,
  isAccountingDocumentsLoading: false,
  searchValue: '',
  errorOneC: false,
};

const accountingDocumentsSlice = createSlice({
  name: 'admin',
  initialState,
  reducers: {
    sortAccountingDocumentsByDate: (state, { payload }) => {
      state.accountingDocuments = [...state.accountingDocuments].reverse();
    },
    onClearAccountingDocuments: state => {
      state.accountingDocuments = initialState.accountingDocuments;
      state.errorOneC = initialState.errorOneC;
    },
    setSearchValue: (state, { payload }) => {
      state.searchValue = payload;
    },
  },
  extraReducers: {
    [accountingDocumentsOperation.getAccountingDocuments.pending]: (
      state,
      { payload },
    ) => {
      state.isAccountingDocumentsLoading = true;
    },
    [accountingDocumentsOperation.getAccountingDocuments.fulfilled]: (
      state,
      { payload },
    ) => {
      state.accountingDocuments = payload;
      state.isAccountingDocumentsLoading = false;
    },
    [accountingDocumentsOperation.getAccountingDocuments.rejected]: (
      state,
      { payload },
    ) => {
      const { data } = payload;
      state.errorOneC = data.error_key === '1C_OUT';
    },
  },
});
export const {
  onClearAccountingDocuments,
  setSearchValue,
  sortAccountingDocumentsByDate,
} = accountingDocumentsSlice.actions;
export default accountingDocumentsSlice.reducer;
