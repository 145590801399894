import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import styled from 'styled-components';

import { Sprite } from '@root/assets/svg/index';
import activityAreasOperation from '@root/redux/activity-areas/activity-areas-operation';
import { TextInput } from '@root/ui/Input/TextInput/index';

import { useTheme } from '@mui/material';

import { Typography } from '../../../../node_modules/@mui/material/index';

const ActivityAreasItem = ({ children, id, name }) => {
  const { color } = useTheme();
  const dispatch = useDispatch();
  const [focus, setFocus] = useState(false);
  const [value, setValue] = useState('');
  const [oldValue, setOldValue] = useState('');

  useEffect(() => {
    setOldValue(value);
  }, [focus]);

  const onDelete = id => {
    dispatch(activityAreasOperation.deleteActivityArea({ id }));
  };

  const onChange = name => value => {
    setValue(value);
  };

  const inputRef = ref => {
    ref?.focus();
  };

  const onSubmit = () => {
    setFocus(false);
    if (oldValue !== value) {
      dispatch(activityAreasOperation.updateActivityArea({ id, name: value }));
    }
  };

  return (
    <ListItem>
      {!focus && (
        <Typography
          variant="bodySmall"
          sx={{ flexGrow: 1 }}
          color={color.gray_80}
        >
          {children}
        </Typography>
      )}
      {focus && (
        <TextInput
          value={value}
          onChange={onChange(value)}
          onBlur={() => {}}
          styleWrapper={{ flexGrow: 1, marginRight: '50px' }}
          refers={inputRef}
        />
      )}

      <BtnsWrp>
        {focus ? (
          <>
            <Svg2
              onClick={() => {
                onSubmit();
              }}
            >
              <use href={`${Sprite}#icon-done`} />
            </Svg2>
            <Svg2
              onClick={() => {
                setFocus(false);
              }}
            >
              <use href={`${Sprite}#icon-cross`} />
            </Svg2>
          </>
        ) : (
          <>
            <Svg
              onClick={() => {
                setFocus(true);
                setValue(name);
              }}
            >
              <use href={`${Sprite}#icon-edit`} />
            </Svg>
            <SvgClose
              onClick={() => {
                onDelete(id);
              }}
              sx={{ marginRight: '3px' }}
            >
              <use href={`${Sprite}#icon-delete`} />
            </SvgClose>
          </>
        )}
      </BtnsWrp>
    </ListItem>
  );
};

export default ActivityAreasItem;

const ListItem = styled.div`
  padding: 0px 20px;
  height: 48px;
  display: flex;
  align-items: center;
`;

const Svg = styled.svg`
  width: 16px;
  height: 16px;
  cursor: pointer;
  stroke: #8094ae;
`;

const SvgClose = styled.svg`
  width: 16px;
  height: 16px;
  cursor: pointer;
  stroke: #8094ae;
  margin-right: 3px;
  margin-left: 19px;

  & + svg {
    margin-left: 16px;
    /* width: 20px;
    height: 20px; */
  }
`;

const Svg2 = styled.svg`
  width: 20px;
  height: 20px;
  cursor: pointer;
  stroke: #8094ae;
  fill: #8094ae;

  & + svg {
    margin-left: 16px;
    /* width: 20px;
    height: 20px; */
  }
`;

const BtnsWrp = styled.div`
  display: flex;
  align-items: center;
`;
