import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Cloud, Sprite } from '@root/assets/svg';
import { AvatarUI, IconBtn, MessageError } from '@root/ui';

import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';

const TYPE = ['image/jpeg', 'image/png', 'image/webp'];

export const FileLoader = ({
  fileRef,
  file,
  setFile,
  handleSetImage,
  title,
  initError,
  isLogo,
  renderPreview,
  showPreview = false,
  optionalRecommendationSize = '',
  styles,
}) => {
  const { t } = useTranslation(['registration', 'validation'], {
    useSuspense: false,
  });
  const { color } = useTheme();
  const [dragActive, setDragActive] = useState(false);
  const [error, setError] = useState({ init: false, text: '' });
  useEffect(() => {
    setError({});
  }, [file]);

  const handelClick = e => {
    fileRef.current.click();
  };

  useEffect(() => {
    if (initError) {
      setError(initError);
    }
  }, [initError]);

  // handle drag events
  const handleDrag = e => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDragActive(true);
    } else if (e.type === 'dragleave') {
      setDragActive(false);
    }
  };

  const handleDrop = e => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      const file = e.dataTransfer.files[0];
      validationFile(file);
    }
  };

  const handleFile = e => {
    const file = e.currentTarget.files[0];
    validationFile(file);
    e.currentTarget.value = null;
  };

  const validationFile = file => {
    const req = [...TYPE];

    if (!req.includes(file.type)) {
      setError({ init: true, text: t('validation:invalid_file') });
      return;
    }
    const tempURL = URL.createObjectURL(file);
    const image = new Image();
    image.src = tempURL;
    image.onload = function () {
      setFile && setFile(file);
    };
  };

  const handleEdit = () => {
    fileRef.current.value = '';
    fileRef.current.click();
  };

  const handleDeleted = () => {
    handleSetImage('');
    fileRef.current.value = '';
  };

  const memoizedFile = useMemo(() => {
    if (file && typeof file !== 'string') {
      return URL.createObjectURL(file);
    } else {
      return file ? file : '';
    }
  }, [file]);

  const { text, init } = error;

  return (
    <>
      <FileLoaderWrapper styles={styles}>
        {file && showPreview && (
          <PreviewWrapper>
            {renderPreview({ file: memoizedFile })}
          </PreviewWrapper>
        )}
        {file ? (
          <AvatarWrapper color={color} hasBorder={showPreview}>
            {isLogo ? (
              <CompanyLogo src={memoizedFile} alt="Logo Company" />
            ) : (
              <AvatarUI src={memoizedFile} />
            )}

            <ActionWrapper>
              <IconBtn
                href={`${Sprite}#icon-pan`}
                onClick={handleEdit}
                tooltipText={t('common:tooltip.edit')}
              />
              <IconBtn
                href={`${Sprite}#icon-bin`}
                onClick={handleDeleted}
                tooltipText={t('common:tooltip.remove')}
              />
            </ActionWrapper>
          </AvatarWrapper>
        ) : (
          <>
            <DropArea
              color={color}
              onClick={handelClick}
              onDragEnter={handleDrag}
              styles={styles}
            >
              <CloudIcon src={Cloud} alt="Imag Cloud" />
              <Typography
                sx={{ mt: '10px', color: color.gray }}
                variant={'bodyBoldSmall'}
                align={'center'}
                component={'p'}
              >
                {title}
              </Typography>
              <Typography
                sx={{ mt: '4px', color: color.gray_80 }}
                variant={'bodySmall'}
                align={'center'}
                component={'p'}
              >
                {t('registration:step_two.loader.subTitle')}
              </Typography>

              {/* {optionalRecommendationSize && (
                <Typography
                  sx={{ mt: '4px', color: color.gray_80 }}
                  variant={'bodySmall'}
                  align={'center'}
                  component={'p'}
                >
                  {optionalRecommendationSize}
                </Typography>
              )} */}

              <Typography
                sx={{ mt: '4px', color: color.gray_60 }}
                variant={'bodyExSmall'}
                align={'center'}
                component={'p'}
              >
                {`PNG, JPG ${optionalRecommendationSize}`}
              </Typography>
              {dragActive && (
                <HideBox
                  id="drag-file-element"
                  onDragEnter={handleDrag}
                  onDragLeave={handleDrag}
                  onDragOver={handleDrag}
                  onDrop={handleDrop}
                ></HideBox>
              )}
            </DropArea>
          </>
        )}
        <File
          type="file"
          ref={fileRef}
          onChange={handleFile}
          accept={`.jpg,.png,.jpeg,.webp`}
        />
      </FileLoaderWrapper>
      {init && <MessageError errorText={text} />}
    </>
  );
};
const CompanyLogo = styled.img`
  height: 60px;
`;

const FileLoaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 30px;
  margin-top: ${({ styles }) => styles?.margin || '28px'};
  position: relative;
`;

const AvatarWrapper = styled.div`
  display: flex;
  align-self: stretch;
  align-items: center;
  position: relative;
  justify-content: ${({ hasBorder }) =>
    hasBorder ? 'flex-end' : 'flex-start'};
  flex: 0 0 300px;

  &:before {
    content: '';
    display: ${({ hasBorder }) => (hasBorder ? 'block' : 'none')};
    width: 2px;
    height: 100%;
    background-color: ${({ color }) => color.line};
    position: absolute;
    top: 0;
    left: 0;
    margin-right: 20px;
  }
`;

const ActionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  row-gap: 12px;

  margin-left: 20px;
`;

const DropArea = styled.div`
  width: 100%;

  border: 1px dashed ${props => props.color.gray_40};
  border-radius: 4px;
  padding: 16px;

  cursor: pointer;
  background: ${({ styles }) =>
    styles?.background ? styles?.background : 'transparent'};

  &:hover {
    background: ${({ color }) => color.main_light};
  }
`;

const CloudIcon = styled.img`
  margin-left: auto;
  margin-right: auto;
`;

const File = styled.input`
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  padding: 0;
  white-space: nowrap;
  clip-path: inset(100%);
  clip: rect(0 0 0 0);
  overflow: hidden;
`;

const HideBox = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 1rem;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
`;

const PreviewWrapper = styled.div``;

FileLoader.propTypes = {
  fileRef: PropTypes.object,
  file: PropTypes.string.isRequired,
  setFile: PropTypes.func.isRequired,
  handleSetImage: PropTypes.func.isRequired,
  title: PropTypes.string,
  isLogo: PropTypes.bool,
  initError: PropTypes.shape({
    init: PropTypes.bool,
    text: PropTypes.string,
  }),
  renderPreview: PropTypes.func,
  showPreview: PropTypes.bool,
  optionalRecommendationSize: PropTypes.string,
};
