import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const getProduct = createAsyncThunk(
  'product/getProduct',
  async ({ id, page, per_page, order, sort, filter, stock }, thunkApi) => {
    try {
      const response = await axios.get(`product/category/${id}`, {
        params: { page, per_page, sort, order, filter, stock },
      });
      return { ...response.data, id };
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  },
);

const getProductById = createAsyncThunk(
  'product/getProductById',
  async ({ id }, thunkApi) => {
    try {
      const response = await axios.get(`product/${id}`);
      return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  },
);

const getProductByEditId = createAsyncThunk(
  'product/getProductByEditId',
  async ({ id }, thunkApi) => {
    try {
      const response = await axios.get(`product/edit/${id}`);
      return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  },
);

const editProductByEditId = createAsyncThunk(
  'product/editProductByEditId',
  async ({ id, editData }, thunkApi) => {
    try {
      const { data } = await axios.post(`product/edit/${id}`, { ...editData });
      return data?.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  },
);

const createProduct = createAsyncThunk(
  'product/createProduct',
  async ({ editData, onResolve, onReject }, thunkApi) => {
    try {
      const { data } = await axios.post('product', { ...editData });

    if (onResolve) onResolve(data?.data);
      return data?.data;
    } catch (e) {
      if (onReject) onReject();
      return thunkApi.rejectWithValue(e);
    }
  },
);

const getProductsBySearch = createAsyncThunk(
  'product/getProductsBySearch',
  async ({ q, per_page, page }, { rejectWithValue }) => {
    try {
      const { data } = await axios.get('/product/search', {
        params: {
          q,
          page,
          per_page,
          fields: 'name,model,description,sku,property,',
        },
      });
      return { ...data };
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

const addAnalogueProductsById = createAsyncThunk(
  'product/addAnalogueProductsById',
  async ({ id, products }, { rejectWithValue }) => {
    try {
      const { data } = await axios.post(`/product/${id}/analogs`, { products });
      return { ...data };
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

const addRelatedProductsById = createAsyncThunk(
  'product/addRelatedProductsById',
  async ({ id, related_products }, { rejectWithValue }) => {
    try {
      const { data } = await axios.post(`/product/${id}/related-products`, {
        related_products,
      });
      return { ...data };
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

const getWatchlist = createAsyncThunk(
  'product/getWatchlist',
  async (
    { q, page, per_page, order, sort, filter, stock, onResolve, onReject },
    { rejectWithValue },
  ) => {
    try {
      const {
        data: { data, meta },
      } = await axios.get(`/watchlist`, {
        params: { q, page, per_page, sort, order, filter, stock },
      });
      if (onResolve) onResolve();
      return { data, meta };
    } catch (error) {
      if (onReject) onReject();
      return rejectWithValue(error);
    }
  },
);

const toggleWatchlist = createAsyncThunk(
  'product/toggleWatchlist',
  async ({ id, onResolve, onReject }, { rejectWithValue }) => {
    try {
      const {
        data: { data },
      } = await axios.get(`/watchlist/${id}`);
      if (onResolve) onResolve();
      return { product: data, currentId: id };
    } catch (error) {
      if (onReject) onReject();
      return rejectWithValue(error);
    }
  },
);

const uploadDocument = createAsyncThunk(
  'product/uploadDocument',
  async ({ formData, id, type, onResolve, onReject }, { rejectWithValue }) => {
    try {
      const {
        data: { data },
      } = await axios.post(`/product/${id}/document`, formData);
      if (onResolve) onResolve(data);
      return { data, type };
    } catch (error) {
      if (onReject) onReject();
      return rejectWithValue(error);
    }
  },
);

const deleteDocument = createAsyncThunk(
  'product/deleteDocument',
  async ({ documentId, type, onResolve, onReject }, { rejectWithValue }) => {
    try {
      const {
        data: { data },
      } = await axios.delete(`/product/document/${documentId}`);
      if (onResolve) onResolve(data);
      return { data, type, documentId };
    } catch (error) {
      if (onReject) onReject();
      return rejectWithValue(error);
    }
  },
);

const getCharacteristicsList = createAsyncThunk(
  'product/getCharacteristicsList',
  async ({ rejectWithValue }) => {
    try {
      console.log('HERE');
      const { data } = await axios.get('/properties/category/5');

      return data;
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);

const getProductFilters = createAsyncThunk(
  'product/getProductFilters',
  async ({ id, onlyUpdate = false }, { rejectWithValue }) => {
    try {
      const {
        data: { data },
      } = await axios.get(`/product/${id}/filters`);
      return { data, onlyUpdate };
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);

const storeProductFilters = createAsyncThunk(
  'product/storeProductFilters',
  async ({ id, formData }, { rejectWithValue }) => {
    try {
      const {
        data: { data },
      } = await axios.post(`/product/${id}/filters`, formData);
      return {};
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);

const addProductImage = createAsyncThunk(
  'product/addProductImage',
  async ({ id, formData, onResolve, onReject }, { rejectWithValue }) => {
    try {
      const {
        data: { data },
      } = await axios.post(`/product/${id}/image`, formData);
      if (onResolve) onResolve(data);
      return data;
    } catch (e) {
      if (onReject) onReject();
      return rejectWithValue(e);
    }
  },
);

const deleteProductImage = createAsyncThunk(
  'product/deleteProductImage',
  async ({ id, imgId, onResolve, onReject }, { rejectWithValue }) => {
    try {
      const {
        data: { data },
      } = await axios.delete(`/product/image/${id}/${imgId}`);
      if (onResolve) onResolve(data);
      return data;
    } catch (e) {
      if (onReject) onReject();
      return rejectWithValue(e);
    }
  },
);

const getTags = createAsyncThunk(
  'product/getTags',
  async ({ rejectWithValue }) => {
    try {
      const { data } = await axios.get('/product/labels');

      return data;
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);

const getProductPropsToEdit = createAsyncThunk(
  'product/getProductPropsToEdit',
  async ({ id }, { rejectWithValue }) => {
    try {
      const { data: { data } } = await axios.get(`/product/get_properties/${id}`);

      return data;
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);
const getCategorysToEdit = createAsyncThunk(
  'product/getCategorysToEdit',
  async (_, { rejectWithValue }) => {
    try {
      const { data: { data } } = await axios.get(`/properties/categories`);

      return data;
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);
const getBlocksByCategoryToEdit = createAsyncThunk(
  'product/getBlocksByCategoryToEdit',
  async ({ id }, { rejectWithValue }) => {
    try {
      const { data: { data } } = await axios.get(`/properties/get_groups_by_category/${id}`);
      return data;
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);
const storePropducProps = createAsyncThunk(
  'product/storePropducProps',
  async ({ id, propsData }, { rejectWithValue }) => {
    try {
      const { data: { data } } = await axios.post(`/product/store_properties/${id}`, {
        data: propsData,
      });
      return data;
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);

const syncProductWith1C = createAsyncThunk(
  'product/syncProductWith1C',
  async ({ productId, onReject, onResolve }, { rejectWithValue }) => {
    try {
      const response = await axios.get(`/product/sync/${productId}`);
      if (onResolve) onResolve(response.data);
      return response.data;
    } catch (e) {
      if (onReject) onReject();
      return rejectWithValue(e);
    }
  },
);

export default {
  getProduct,
  getProductById,
  getProductByEditId,
  editProductByEditId,
  createProduct,
  getProductsBySearch,
  addAnalogueProductsById,
  addRelatedProductsById,
  getWatchlist,
  toggleWatchlist,
  uploadDocument,
  deleteDocument,
  getProductFilters,
  storeProductFilters,
  addProductImage,
  deleteProductImage,
  getTags,
  getProductPropsToEdit,
  getCategorysToEdit,
  getBlocksByCategoryToEdit,
  storePropducProps,
  syncProductWith1C,
};
