import React, { useEffect, useRef, useState } from 'react';

import { css } from '@emotion/css';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { RequiredStar, Select_Icon } from '@root/assets/svg';
import { MessageError } from '@root/ui';
import { OutlinedInputCustom } from '@root/ui';

import { ListSubheader, MenuItem, Select, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import { useTheme } from '@mui/material/styles';

const styles = colors => css`
  margin-top: 4px;

  & ul {
    max-height: 300px;
    border: 1px solid ${colors.line};
    border-radius: 4px;
    padding: 0 !important;
  }

  & li {
    padding: 4px 5px;
    border-radius: 2px;

    &:hover {
      background-color: ${colors.gray_20};
    }

    &:active {
      background-color: ${colors.main};
      color: white;
    }
  }

  & .Mui-selected {
    background-color: ${colors.main} !important;
    color: white;
  }

  & .MuiMenu-list {
    overflow-y: auto;
    scrollbar-width: thin;

    ::-webkit-scrollbar {
      width: 5px;
    }

    ::-webkit-scrollbar-thumb {
      background: ${colors.gray_40};
      border-radius: 4px;
    }

    ::-webkit-scrollbar-track {
      background: ${colors.white};
      border-left: 1px solid ${colors.line};
    }
  }
`;

export const BasicSelect = ({
  name,
  data = [],
  currentId = 'none',
  helperText,
  error,
  callBack,
  sx,
  height,
  label,
  labelSx,
  group = false,
  dataTestItem,
  required = false,
  initValue = 'none',
  selectSx = {},
  customItem,
  labelRowGap = '',
  labelTextVariant = 'bodySmall',
  labelRowDirection = false,
  errorPositionLeft = '170px',
  multiple = false,
  ...prop
}) => {
  const [value, setValue] = useState(initValue);
  const refInput = useRef();

  const { color } = useTheme();

  useEffect(() => {
    if (currentId !== 'none' && !multiple) {
      setValue(currentId);
    }
  }, [currentId]);

  const handleChange = event => {
    const {
      target: { value },
    } = event;
    setValue(value);
    callBack(event.target.value);
  };

  const renderMenuItem = (data, sx, typographySx, typographyVariant) => {
    return (
      <MenuItem
        key={data.id}
        value={data.id}
        variant={'selectedMenu'}
        sx={{
          ...sx,
          '&.Mui-selected > p': {
            color: `${color.white} !important`,
          },
          '&:active > p': {
            color: `${color.white} !important`,
          },
        }}
      >
        <Typography
          variant={typographyVariant}
          component={'p'}
          sx={typographySx}
        >
          {data.name}
        </Typography>
      </MenuItem>
    );
  };
  const renderOptions = data => {
    const items = [];
    for (const parent of data) {
      if (parent.children.length) {
        items.push(
          <ListSubheader
            key={parent.id}
            sx={{
              padding: '8px 10px 6px 10px !important',
              pointerEvents: 'none',
            }}
          >
            <Typography
              variant={'bodyBoldMedium'}
              component={'p'}
              sx={{
                color: `${color.gray} !important`,
              }}
            >
              {parent.name}
            </Typography>
          </ListSubheader>,
        );
      } else {
        items.push(
          renderMenuItem(
            parent,
            { padding: '8px 10px 6px 10px !important' },
            { color: `${color.gray} !important` },
            'bodyBoldMedium',
          ),
        );
      }
      for (const child of parent.children) {
        items.push(
          renderMenuItem(
            child,
            { paddingLeft: '24px !important' },
            { fontSize: '0.875rem', color: `${color.gray_80} !important` },
            'bodyNormalSmall',
          ),
        );
      }
    }
    return items;
  };

  return (
    <Box
      sx={{
        minWidth: 120,
        gap: labelRowGap ? labelRowGap : 'initial',
        display: labelRowDirection ? 'flex' : 'block',
        position: labelRowDirection ? 'relative' : 'initial',
        marginBottom: error && helperText && labelRowDirection ? '20px' : '',
        ...sx,
      }}
    >
      {label && (
        <LabelWrapper isRowDirection={labelRowDirection}>
          {required && (
            <RequiredStarImg
              src={RequiredStar}
              alt="Required Star"
              isRowDirection={labelRowDirection}
            />
          )}
          <Typography
            variant={labelTextVariant}
            sx={{ color: color.gray, ml: '2px', ...labelSx }}
            component={'span'}
          >
            {label}
          </Typography>
        </LabelWrapper>
      )}
      <FormControl error={error} fullWidth sx={{ color: color.white }}>
        <Select
          ref={refInput}
          id="demo-simple-select"
          value={value}
          defaultValue="none"
          onChange={handleChange}
          input={<OutlinedInputCustom />}
          sx={{
            p: 0,

            color:
              typeof value === 'string'
                ? `${color.gray_60} !important`
                : `${color.gray} !important`,

            fontSize: '0.875rem',
            lineHeight: 1.5,
            scrollbarWidth: 'thin',
            scrollbarColor: `${color.gray_40} ${color.line}`,
            height: height,
            '& .MuiInputBase-input': {
              p: '12px 16px',
            },
            ...selectSx,
          }}
          MenuProps={{
            classes: {
              paper: styles({
                line: color.line,
                gray_20: color.gray_20,
                main: color.main,
                gray_40: color.gray_40,
                white: color.white,
              }),
            },
          }}
          IconComponent={props => <SelectImg src={Select_Icon} {...props} />}
          multiple={multiple}
          {...prop}
        >
          <MenuItem
            sx={{
              position: 'absolute',
              width: '1px',
              height: '1px',
              margin: '-1px',
              border: 0,
              padding: 0,
              whiteSpace: 'nowrap',
              clipPath: 'inset(100%)',
              clip: 'rect(0 0 0 0)',
              overflow: 'hidden',
            }}
            key="0"
            disabled
            value="none"
          >
            {name}
          </MenuItem>
          {group
            ? renderOptions(data)
            : data.map((option, idx) => (
                <MenuItem
                  key={option.id ? option.id : idx}
                  value={option.id ? option.id : option}
                  variant={'selectedMenu'}
                  data-test-item={dataTestItem}
                >
                  {option.name ? option.name : option}
                </MenuItem>
              ))}
          {customItem && customItem}
        </Select>
        {helperText && (
          <MessageError
            errorText={helperText}
            sx={{
              ml: 0,
              position: labelRowDirection ? 'absolute' : 'initial',
              top: labelRowDirection ? '100%' : '',
              left: labelRowDirection ? errorPositionLeft : '',
            }}
          />
        )}
      </FormControl>
    </Box>
  );
};

const LabelWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  min-width: ${({ isRowDirection }) => (isRowDirection ? '150px' : '')};

  span {
    ${({ isRowDirection }) =>
      isRowDirection
        ? {
            alignSelf: 'center',
            whiteSpace: 'nowrap',
          }
        : {}}
  }
`;

const RequiredStarImg = styled.img`
  margin-top: 3px;
  ${({ isRowDirection }) =>
    isRowDirection
      ? {
          marginTop: 0,
          marginBottom: '3px',
          alignSelf: 'center',
        }
      : {}}
`;

const SelectImg = styled.img`
  margin-top: -5px;
  margin-right: 2px;
`;

BasicSelect.propTypes = {
  name: PropTypes.string,
  data: PropTypes.arrayOf(
    PropTypes.exact({
      id: PropTypes.number,
      name: PropTypes.string,
      children: PropTypes.arrayOf(
        PropTypes.exact({
          id: PropTypes.number,
          name: PropTypes.string,
          slug: PropTypes.string,
          active: PropTypes.bool,
          can_delete: PropTypes.bool,
          children: PropTypes.array,
        }),
      ),
    }),
  ),
  currentId: PropTypes.number,
  helperText: PropTypes.string,
  error: PropTypes.bool,
  callBack: PropTypes.func.isRequired,
  sx: PropTypes.object,
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  label: PropTypes.string,
  labelSx: PropTypes.object,
  group: PropTypes.bool,
  required: PropTypes.bool,
  selectSx: PropTypes.object,
  customItem: PropTypes.node,
  dataTestItem: PropTypes.string,
  initValue: PropTypes.string,
  labelRowGap: PropTypes.string,
  labelTextVariant: PropTypes.string,
  labelRowDirection: PropTypes.bool,
  errorPositionLeft: PropTypes.string,
};
