import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import styled from 'styled-components';

import { Sprite } from '@root/assets/svg';
import { IconBtn } from '@root/ui';
import { BasicSelect } from '@root/ui/Input/Select/index';

import { Typography, useTheme } from '@mui/material';

export const ActivityAreas = ({
  id,
  value,
  displayValue,
  sx,
  variant,
  onEdit,
  goToStep,
  errors,
  multiple = false,
  data = [],
}) => {
  const [isEdit, setIsEdit] = useState(false);
  const [locValue, setLocValue] = useState(multiple ? [] : '');
  const { t } = useTranslation(['common'], { useSuspense: false });
  const { color } = useTheme();

  useEffect(() => {
    if (id) setIsEdit(false);
  }, [id]);

  useEffect(() => {
    if (multiple === true) {
      setLocValue(value.map(item => item.id));
    } else {
      setLocValue(value);
    }
  }, []);
  useEffect(() => {
    if (multiple === true) {
      setLocValue(value.map(item => item.id));
    } else {
      setLocValue(value);
    }
  }, [value]);

  const onEditSuccess = () => {
    setIsEdit(false);
  };

  const onError = () => {};

  return (
    <>
      <FieldDescription>
        {Array.isArray}
        <WrapperBox>
          {isEdit ? (
            <BasicSelect
              error={errors?.init}
              helperText={errors?.text}
              data={data}
              initValue={locValue}
              callBack={e => {
                setLocValue(e);
              }}
              multiple={multiple}
              value={locValue}
              sx={{ maxWidth: '215px' }}
            />
          ) : Array.isArray(value) ? (
            value.map(({ name }) => (
              <ActivityAreasWrapper>
                <Typography
                  sx={{ mr: '8px', ...sx }}
                  variant={variant}
                  component={'p'}
                >
                  {name}
                </Typography>
              </ActivityAreasWrapper>
            ))
          ) : (
            <>{displayValue}</>
          )}
        </WrapperBox>
      </FieldDescription>

      {(onEdit || goToStep) &&
        (!isEdit ? (
          <IconBtn
            href={`${Sprite}#icon-pan`}
            onClick={() => (goToStep ? goToStep() : setIsEdit(true))}
            tooltipText={t('common:tooltip.edit')}
          />
        ) : (
          <IconsWrapper>
            <IconBtn
              onClick={() => setIsEdit(false)}
              href={`${Sprite}#icon-cross`}
              sx={{ stroke: color.red }}
            />
            <IconBtn
              onClick={() => {
                onEdit && onEdit(locValue, onEditSuccess, onError);
              }}
              href={`${Sprite}#icon-tick`}
              sx={{ stroke: color.green }}
            />
          </IconsWrapper>
        ))}
    </>
  );
};

const FieldDescription = styled.div`
  flex: 1 1 auto;
`;

const IconsWrapper = styled.div`
  display: flex;
  column-gap: 6px;
`;

const WrapperBox = styled.div`
  display: flex;
  flex-direction: column;
`;

const ActivityAreasWrapper = styled.div`
  display: flex;
`;
