import {
  NavLink,
  useParams,
} from '../../../../node_modules/react-router-dom/dist/index';
import {forwardRef, useMemo} from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import styled from 'styled-components';

import { Sprite } from '@root/assets/svg/index';
import { EditorContentWrapper } from '@root/components/Knowledge/EditorContentWrapper/index';
import { formatDate } from '@root/helpers/dates';
import authSelectors from '@root/redux/auth/auth-selectors';
import knowledgeSelectors from '@root/redux/knowledge/knowledge-selectors';
import { OutlineButtonBase } from '@root/ui/Button/OutlineButton/index';
import { ProgressLoader } from '@root/ui/Loader/ProgressLoader/index';

import { useTheme } from '@mui/material';

import { Card, Typography } from '../../../../node_modules/@mui/material/index';

export const KnowledgeDetails = forwardRef((_, ref) => {
  const { t } = useTranslation(['knowledge']);
  const { brandId, slug } = useParams();
  const { color } = useTheme();
  const role = useSelector(authSelectors.getRole);
  const articleLoading = useSelector(knowledgeSelectors.getArticleLoading);
  const articleData = useSelector(knowledgeSelectors.getArticleData);

  console.log('articleData in KnowledgeDetails  ', articleData)

  return articleData ? (
    <>
      <StyledCardWrap ref={ref}>
        {articleLoading ? (
          <ProgressLoader />
        ) : (
          <>
            {role === 'admin' && (
              <EditBtnWrp>
                <NavLink to={`/knowledge/${brandId}/edit/${slug}`}>
                  <Typography
                    variant="bodyBoldSmall"
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      cursor: 'pointer',
                    }}
                    color={color.main}
                  >
                    <EditSvgIco color={color.main}>
                      <use href={`${Sprite}#icon-pen`}></use>
                    </EditSvgIco>
                    {t('edit_post')}
                  </Typography>
                </NavLink>
              </EditBtnWrp>
            )}

            <Typography variant="h2" component="h1" color={color.gray}>
              {articleData.title}
            </Typography>

            <AuthodDetailWrap>
              <AuthorDetail>
                <AuthorImg src={articleData.author.avatar} />
                <AuthorTextWrap>
                  <div>
                    <Typography variant="bodyMedium" color={color.gray}>
                      {articleData.author.name}
                    </Typography>
                  </div>
                  <div>
                    <Typography variant="bodyMedium" color={color.gray_60}>
                      {formatDate(
                        new Date(articleData.created_at),
                        'DD MMMM YYYY [р.] HH:mm',
                      )}
                      {articleData.updated ? ` · ${t('edited')}` : ''}
                    </Typography>
                  </div>
                </AuthorTextWrap>
              </AuthorDetail>

              <div></div>
              {/* <OutlineButtonBase sx={{ padding: '9px 12px' }}>
            <Typography variant="bodyBoldSmall" color={color.gray_60}>
              {t('subscribe')}
            </Typography>
          </OutlineButtonBase> */}
            </AuthodDetailWrap>

            <EditorContentWrapper editorContent={articleData.content} />
          </>
        )}
      </StyledCardWrap>
    </>
  ) : (
    <>
      <StyledCardWrap></StyledCardWrap>
    </>
  );
});


const StyledCardWrap = styled(Card)`
  padding: 32px;
  height: 100%;
  max-height: 100%;
  overflow-y: auto;
  position: relative;

  @media(max-width: 1024px) {
    height: auto;
    max-height: unset;
    min-height: 300px;
    padding: 16px 8px;
  }
`;
const EditBtnWrp = styled.div`
  margin-bottom: 16px;
`;
const EditSvgIco = styled.svg`
  width: 16px;
  height: 16px;
  display: inline-block;
  margin-right: 4px;
  stroke: #1996aa;
`;
const EditActions = styled.div`
  display: flex;
  justify-content: space-between;
`;
const AuthodDetailWrap = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 27px 0;
`;
const AuthorDetail = styled.div`
  display: flex;
`;
const AuthorImg = styled.img`
  width: 48px;
  height: 48px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 10px;
`;
const AuthorTextWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
