import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Chip } from '@root/ui';

import optionSelectors from '@redux/option/option-selectors';

export const ChipBox = ({
  filterActive,
  setChipHeight,
  setFilterActive,
  handelSubmitFilter,
}) => {
  const refWrapperChip = useRef();
  const filters = useSelector(optionSelectors.getHistoryFilters);
  const { filter, amount, period } = filters;
  const { t } = useTranslation(['common'], {
    useSuspense: false,
  });

  const handelDelete = (name, childrenName) => {
    let newFilterActive = {};

    switch (name) {
      case 'amount':
        newFilterActive = {
          ...filterActive,
          [name]: [],
        };
        break;

      case 'period':
        newFilterActive = {
          ...filterActive,
          [name]: [],
        };
        break;

      default:
        newFilterActive = {
          ...filterActive,
          [name]: filterActive[name].filter(el => el !== childrenName),
        };
        break;
    }

    setFilterActive(newFilterActive);
    handelSubmitFilter(newFilterActive);
  };

  const getChips = key => {
    if (filterActive[key].length === 0) return <></>;
    const allArr = JSON.parse(JSON.stringify([amount, period, ...filter]));
    const currentObj = allArr.find(el => el.key === key);

    switch (key) {
      case 'amount':
        return (
          <Chip
            title={currentObj.title}
            value={`${filterActive[key][0]} - ${filterActive[key][1]} ${t(
              'common:currency',
            )}`}
            sx={{ marginTop: '10px', marginLeft: '5px' }}
            onClick={() => handelDelete(key)}
          />
        );
      case 'period':
        return (
          <Chip
            title={currentObj.title}
            value={`${filterActive[key][0]} - ${filterActive[key][1]}`}
            sx={{ marginTop: '10px', marginLeft: '5px' }}
            onClick={() => handelDelete(key)}
          />
        );

      default:
        return filterActive[key].map(el => (
          <Chip
            title={currentObj.title.split(' ')[0]}
            value={currentObj.options.find(option => option.key === el).title}
            sx={{ marginTop: '10px', marginLeft: '5px' }}
            onClick={() => handelDelete(key, el)}
          />
        ));
    }
  };

  useEffect(() => {
    getHeight();
  }, [refWrapperChip.current?.offsetHeight]);

  const getHeight = () => {
    setChipHeight(refWrapperChip.current?.offsetHeight);
  };

  return (
    <>
      <FlexWrapper ref={refWrapperChip}>
        {Object.keys(filterActive).map(key => getChips(key))}
      </FlexWrapper>
    </>
  );
};

const FlexWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-left: -5px;
`;

ChipBox.propTypes = {
  filterActive: PropTypes.exact({
    amount: PropTypes.arrayOf(PropTypes.number),
    period: PropTypes.arrayOf(PropTypes.string),
  }),
  setChipHeight: PropTypes.func.isRequired,
  setFilterActive: PropTypes.func.isRequired,
  handelSubmitFilter: PropTypes.func.isRequired,
};
