import { useRef, useState } from 'react';
import { CircleStencil, StencilGrid } from 'react-advanced-cropper';
import { useTranslation } from 'react-i18next';

import { CropperModal } from '@root/components/Modal/CropperModal/index';
import DocumentFileLoader from '@root/ui/Input/DocumentFileLoader/index';
import { FileLoader } from '@root/ui/Input/LogoFileLoader/index';

const ProductImageEditor = ({ onImgSubmit }) => {
  const [file, setFile] = useState(null);
  const { t } = useTranslation();
  const ref = useRef();
  const [isOpen, setIsOpen] = useState(false);

  const handleSetImage = img => {
    urltoFile(img, file.name, file.type).then(function (file) {
      onImgSubmit(file);
    });
  };

  function urltoFile(url, filename, mimeType) {
    if (url.startsWith('data:')) {
      var arr = url.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[arr.length - 1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      var file = new File([u8arr], filename, { type: mime || mimeType });
      return Promise.resolve(file);
    }
    return fetch(url)
      .then(res => res.arrayBuffer())
      .then(buf => new File([buf], filename, { type: mimeType }));
  }

  return (
    <>
      <DocumentFileLoader
        fileRef={ref}
        file={file}
        setFile={file => {
          setFile(file);
          setIsOpen(true);
        }}
        type={['image/jpeg', 'image/png', 'image/webp']}
        accept={`.jpg,.png,.jpeg,.webp`}
        styles={{ margin: '0', height: '103px' }}
        subTitle={t('common:buttons.add')}
      />
      <CropperModal
        handleSetImage={handleSetImage}
        file={file}
        open={isOpen}
        setIsOpen={setIsOpen}
      />
    </>
  );
};

export default ProductImageEditor;
