import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import { CreateOrEditCompany } from '@root/components/Modal/CreateOrEditCompany';
import { getErrorStack } from '@root/helpers';
import { useQueryParams } from '@root/hooks';
import { ButtonBase, CustomScroll } from '@root/ui';

import commercialOfferOperation from '@redux/commercial-offer/commercial-offer-operation';
import commercialOfferSelectors from '@redux/commercial-offer/commercial-offer-selectors';
import { deleteProduct } from '@redux/commercial-offer/commercial-offer-slice';
import {
  clearModalState,
  setOpenModal,
} from '@redux/modal-watcher/modal-watcher-slice';

import { Divider, useTheme } from '@mui/material';

import { Details } from './Details';
import { AboutCompany } from './Forms/AboutCompany';
import { Calculation } from './Forms/Calculation';
import { OfferDetails } from './Forms/OfferDetails';
import { AddOrEditProduct } from './Modals/AddOrEditProduct';

export const CommercialOfferForm = ({
  commercialData,
  setCommercialData,
  setOpenSuccessModal,
  mobileCatalogStyles
}) => {
  const [productDetails, setProductDetails] = useState({
    type: '',
    id: null,
  });
  const [companyDetails, setCompanyDetails] = useState({
    type: '',
    id: null,
  });
  const [openCompanyModal, setOpenCompanyModal] = useState(false);
  const [openAddOrEditPositionModal, setOpenAddOrEditPositionModal] =
    useState(false);
  const [error, setError] = useState({});
  const { getQueryObj } = useQueryParams();
  const { color } = useTheme();
  const { t } = useTranslation(['commercial_offer', 'validation'], {
    useSuspense: false,
  });
  const products = useSelector(commercialOfferSelectors.getProducts);
  const dispatch = useDispatch();

  const { id } = getQueryObj();

  const closeModal = () => {
    setOpenCompanyModal(false);
    setOpenAddOrEditPositionModal(false);
  };
  const handleCreate = (val, type) => {
    setOpenAddOrEditPositionModal(val);
    setProductDetails({
      type: type,
      id: null,
    });
  };
  const handleEdit = (positionType, id) => {
    setOpenAddOrEditPositionModal(true);
    setProductDetails({
      type: positionType,
      id: id,
    });
  };
  const handleClickMenu = (menuType, id, positionType) => {
    if (menuType !== 'edit') {
      dispatch(
        setOpenModal({
          open: true,
          key: 'confirm',
          isHiddenHeader: true,
          inactiveBackdrop: true,
          dataForConfirm: { id: id },
          callback: ({ id }) => {
            dispatch(deleteProduct({ id: id }));
            dispatch(clearModalState());
          },
        }),
      );
    } else {
      setOpenAddOrEditPositionModal(true);
      setProductDetails({
        type: positionType,
        id: id,
      });
    }
  };

  const validation = () => {
    let flag = false;
    let currentError = {};
    const { company_id, offer_name } = commercialData;

    Object.keys({
      company_id,
      offer_name,
    }).forEach(key => {
      if (!commercialData[key] || commercialData[key] === '') {
        currentError = getErrorStack(
          currentError,
          key,
          key === 'company_id'
            ? t(`commercial_offer:validation.${key}`)
            : t(`validation:required`),
        );
        flag = true;
      }
    });

    setError(currentError);
    return flag;
  };

  const onSubmit = e => {
    e.preventDefault();

    if (validation()) return;

    const id = getQueryObj().id;

    const rebuildProductsArr = {
      products: [...products].map(el => ({
        id: el.id,
        type: el.type,
        price: el.price,
        quantity: el.quantity,
        warranty: el.warranty,
      })),
    };
    const result = Object.assign(commercialData, rebuildProductsArr);

    if (id) {
      dispatch(
        commercialOfferOperation.updateEditCommercialOffer({
          id: id,
          commercialData: result,
        }),
      ).then(() => {
        setOpenSuccessModal(true);
      });
    } else {
      dispatch(
        commercialOfferOperation.createCommercialOffer({
          commercialData: result,
        }),
      ).then(() => {
        setOpenSuccessModal(true);
      });
    }
  };


  return (
    <ContentWrap>
      <>
        <Form onSubmit={onSubmit} color={color}>
          <CustomScroll vertical={true}>
            <AboutCompany
              handleOpenModal={(open, type) => {
                setOpenCompanyModal(open);
                setCompanyDetails({
                  type: type,
                  id: commercialData.company_id,
                });
              }}
              companyId={commercialData.company_id}
              setCommercialData={setCommercialData}
              error={error}
              mobileCatalogStyles={mobileCatalogStyles}
            />
            <Calculation
              setCommercialData={setCommercialData}
              commercialData={commercialData}
              mobileCatalogStyles={mobileCatalogStyles}
            />
            <OfferDetails
              setCommercialData={setCommercialData}
              commercialData={commercialData}
              error={error}
              mobileCatalogStyles={mobileCatalogStyles}
            />
            <Divider sx={{ marginBottom: '18px' }} />
            <ButtonBase
              sx={{ margin: '0 0 18px 24px', height: '56px' }}
              type="submit"
            >
              {t(`commercial_offer:buttons.${id ? 'edit_co' : 'create_co'}`)}
            </ButtonBase>
          </CustomScroll>
        </Form>
        <DetailsWrap color={color}>
          <Details
            commercialData={commercialData}
            handleCreate={handleCreate}
            handleEdit={handleEdit}
            handleClickMenu={handleClickMenu}
          />
        </DetailsWrap>
      </>
      {openCompanyModal && (
        <CreateOrEditCompany
          open={openCompanyModal}
          handleClose={closeModal}
          companyDetails={companyDetails}
        />
      )}
      {openAddOrEditPositionModal && (
        <AddOrEditProduct
          productDetails={productDetails}
          open={openAddOrEditPositionModal}
          handleClose={closeModal}
        />
      )}
    </ContentWrap>
  );
};
const ContentWrap = styled.div`
  height: calc(100% - 68px);
  position: relative;
  display: flex;
  gap: 24px;

  @media(max-width: 991px) {
    gap: 10px
  }

  @media(max-width: 768px) {
    flex-direction: column-reverse;
    overflow-y: scroll;
  }
`;
const Form = styled.form`
  background: ${({ color }) => color.white};
  flex: 60%;
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  border: 1px solid ${({ color }) => color.line};

  @media(max-width: 991px) {
    flex: unset;
    width: 50%;
  }

  @media(max-width: 768px) {
    width: 100%;
  }
`;
const DetailsWrap = styled.div`
  flex: 40%;
  background: ${({ color }) => color.white};
  border: 1px solid ${({ color }) => color.line};
  border-radius: 4px;

  @media(max-width: 991px) {
    flex: unset;
    width: 50%;
  }

  @media(max-width: 768px) {
    width: 100%;
  }
`;

CommercialOfferForm.propTypes = {
  commercialData: PropTypes.arrayOf(
    PropTypes.exact({
      company_id: PropTypes.string,
      order_calculation: PropTypes.string,
      proposition_count: PropTypes.string,
      proposition_time: PropTypes.number,
      give_discount: PropTypes.bool,
      discount: PropTypes.string,
      discount_option: PropTypes.string,
      offer_name: PropTypes.string,
      offer_text: PropTypes.string,
      additional_info: PropTypes.string,
      hide_model: PropTypes.bool,
      hide_product_description: PropTypes.bool,
      offer_accepted: PropTypes.bool,
      offer_declined: PropTypes.bool,
      offer_open: PropTypes.bool,
      offer_expired: PropTypes.bool,
    }),
  ),
  setCommercialData: PropTypes.func.isRequired,
  setOpenSuccessModal: PropTypes.func.isRequired,
};
