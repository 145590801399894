import { useTranslation } from 'react-i18next';

import styled from 'styled-components';

import { Sprite } from '@root/assets/svg/index';

import { Typography, useTheme } from '@mui/material';

const BlockedUserWidgetProductMessage = () => {
  const { color } = useTheme();
  const { t } = useTranslation(['mutual_settlements', 'common']);

  return (
    <>
      <MsgBlock>
        <Svg width={16} height={16} color={color.gray_60}>
          <use href={`${Sprite}#icon-info`} />
        </Svg>
        <Typography variant="bodyExSmall" color={color.gray}>
          {t('common:blocked_user_messages.widget_msg')}{' '}
          <a href="/mutual-settlements">
            <Typography variant="bodyBoldExSmall" color={color.main}>
              {t('mutual_settlements:title')}
            </Typography>
          </a>
          .
        </Typography>
      </MsgBlock>
    </>
  );
};

export default BlockedUserWidgetProductMessage;

const MsgBlock = styled.div`
  display: flex;
  margin-top: 6px;
`;
const Svg = styled.svg`
  flex: 16px 0 0;
  margin-right: 6px;
  stroke: ${({ color }) => color} !important;
`;
