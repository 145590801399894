import * as React from 'react';
import { useTranslation } from 'react-i18next';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Sprite } from '@root/assets/svg';
import { Empty, InfiniteScroll } from '@root/components';
import { CustomScroll, IconBtn } from '@root/ui';
import { CheckboxBase } from '@root/ui/Checkbox/index';

import {
  Paper,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useTheme,
} from '@mui/material';

const TableBody = styled.tbody`
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  max-height: calc(100vh - 190px);
`;

export const ManageFiltersTable = ({
  data,
  callback,
  remove,
  edit,
  conf,
  tabType,
}) => {
  const { t } = useTranslation(['catalog', 'common'], { useSuspense: false });
  const { color } = useTheme();
  return (
    <TableContainer
      component={Paper}
      sx={{
        boxShadow: 'initial',
      }}
    >
      <Table size="small" aria-label="a dense table" sx={{ height: '100%' }}>
        <TableHead sx={{ borderBottom: `1px solid ${color.line}` }}>
          <TableRow sx={{ display: 'flex', p: '12px 20px' }}>
            {conf.map(el => {
              return (
                <TableCell
                  key={el.label}
                  sx={{
                    borderBottom: 'none',
                    display: 'flex',
                    alignItems: 'center',
                    ...el.style,
                    p: 0,
                    flexDirection: 'row',
                  }}
                >
                  <Typography
                    variant={'bodyBoldSmall'}
                    component={'p'}
                    sx={{ color: color.gray_60 }}
                  >
                    {t(`common:table.table_heads.${el.value}`)}
                  </Typography>
                </TableCell>
              );
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          <CustomScroll vertical={true}>
            {data && data.length !== 0 ? (
              <InfiniteScroll
                data={data}
                onScroll={({ inView }) =>
                  tabType === 'filters' && inView && callback(inView)
                }
              >
                {({ isInView, item, index }) => (
                  <TableRow
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      p: '16px 20px',
                      height: 'auto',
                      th: {
                        borderBottom: 'none',
                      },
                      '&:last-child, &:last-child th': { borderBottom: 0 },
                    }}
                    key={item.id}
                    id={item.id}
                  >
                    {conf.map((el, idx) => {
                      switch (el?.value) {
                        case 'name':
                          return (
                            <TableCell
                              component="th"
                              scope="row"
                              key={'name-' + item.id}
                              sx={{
                                p: 0,
                                ...el.style,
                                color: color.text_color.secondary,
                                fontSize: '0.75rem',
                              }}
                            >
                              {item?.name}
                            </TableCell>
                          );
                        case 'city':
                          return (
                            <TableCell
                              component="th"
                              scope="row"
                              key={'city-' + item.id}
                              sx={{
                                p: 0,
                                ...el.style,
                                color: color.text_color.secondary,
                                fontSize: '0.75rem',
                              }}
                            >
                              {item?.city || '-'}
                            </TableCell>
                          );
                        case 'category':
                          return (
                            <TableCell
                              component="th"
                              scope="row"
                              key={'category-' + item.id}
                              sx={{
                                p: 0,
                                ...el.style,
                                color: color.text_color.secondary,
                                fontSize: '0.75rem',
                              }}
                            >
                              {item?.category_name}
                            </TableCell>
                          );
                        case 'public_name':
                          return (
                            <TableCell
                              component="th"
                              scope="row"
                              key={'public_name-' + item.id}
                              sx={{
                                p: 0,
                                ...el.style,
                                color: color.text_color.secondary,
                                fontSize: '0.75rem',
                              }}
                            >
                              {item?.public_name || '-'}
                            </TableCell>
                          );
                        case 'guid':
                          return (
                            <TableCell
                              component="th"
                              scope="row"
                              key={'guid-' + item.id}
                              sx={{
                                p: 0,
                                ...el.style,
                                color: color.text_color.secondary,
                                fontSize: '0.75rem',
                              }}
                            >
                              {item?.guid || '-'}
                            </TableCell>
                          );
                        case 'value':
                          return (
                            <TableCell
                              component="th"
                              scope="row"
                              key={'value-' + item.id}
                              sx={{
                                p: 0,
                                ...el.style,
                                color: color.gray_80,
                              }}
                            >
                              {item.values &&
                                item.values.map(({ name, id }, index) => (
                                  <Typography
                                    variant={'bodyBoldSmall'}
                                    component={'span'}
                                    sx={{
                                      fontWeight: 400,
                                      marginRight: '5px',
                                    }}
                                    key={id}
                                  >
                                    {index + 1 !== item.values.length
                                      ? `${name},`
                                      : name}
                                  </Typography>
                                ))}
                            </TableCell>
                          );
                        case 'order-sort':
                          return (
                            <TableCell
                              component="th"
                              scope="row"
                              key={'order-' + item.id}
                              sx={{
                                p: 0,
                                ...el.style,
                                color: color.text_color.secondary,
                                fontSize: '0.75rem',
                              }}
                            >
                              {item?.sort}
                            </TableCell>
                          );
                        case 'edit':
                          return (
                            <TableCell
                              component="th"
                              scope="row"
                              key={'edit-' + item.id}
                              sx={{
                                p: 0,
                                ...el.style,
                                color: color.text_color.secondary,
                                fontSize: '0.75rem',
                              }}
                            >
                              <IconBtn
                                onClick={() => edit(item)}
                                href={`${Sprite}#icon-pan`}
                                sx={{
                                  margin: '0 auto',
                                }}
                              />
                            </TableCell>
                          );
                        case 'remove':
                          return (
                            <TableCell
                              component="th"
                              scope="row"
                              key={'remove-' + item.id}
                              sx={{
                                p: 0,
                                ...el.style,
                                color: color.gray_80,
                                fontSize: '0.75rem',
                              }}
                            >
                              <IconBtn
                                onClick={() => remove(item.id)}
                                href={`${Sprite}#icon-bin`}
                                sx={{
                                  margin: '0 auto',
                                }}
                              />
                            </TableCell>
                          );
                        case 'status':
                          return (
                            <TableCell
                              component="th"
                              scope="row"
                              key={'status-' + item.id}
                              sx={{
                                p: 0,
                                ...el.style,
                                color: color.gray_80,
                                fontSize: '0.75rem',
                              }}
                            >
                              <CheckboxBase
                                width={24}
                                checked={item.active}
                                onChange={() => {}}
                              ></CheckboxBase>
                            </TableCell>
                          );
                        default:
                          break;
                      }
                    })}
                  </TableRow>
                )}
              </InfiniteScroll>
            ) : (
              <TableRow
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  p: '16px 20px',
                  height: '100%',
                }}
              >
                <Empty type={'EmptySearchLogo'} />
              </TableRow>
            )}
          </CustomScroll>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

ManageFiltersTable.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.exact({
      city: PropTypes.string,
      guid: PropTypes.string,
      id: PropTypes.number,
      name: PropTypes.string,
      public_name: PropTypes.string,
      sort: PropTypes.number,
    }),
  ),
  callback: PropTypes.func.isRequired,
  remove: PropTypes.func.isRequired,
  edit: PropTypes.func.isRequired,
  conf: PropTypes.arrayOf(
    PropTypes.exact({
      label: PropTypes.string,
      style: PropTypes.object,
      value: PropTypes.string,
    }),
  ),
  tabType: PropTypes.string.isRequired,
};
