import { createSlice } from '@reduxjs/toolkit';

import operation from './commercial-offer-operation';

const initialState = {
  initialProductsState: [],
  products: [],
  companyList: [],
  companyTemplatesDetails: [],
  customProducts: [],
  customProductsList: [],
  customServicesList: [],
  commercialOfferEditData: {},
  links: {},
  commercialOfferId: null,
  companyListLoading: false,
  productsLoading: false,
  loading: false,
  listLoading: false,
  customProductsLoading: false,
  productsTotalSum: null,
};

const commercialOfferSlice = createSlice({
  name: 'commercialOffer',
  initialState,
  reducers: {
    clearCommercialOfferState: state => {
      state.companyList = initialState.companyList;
      state.companyTemplatesDetails = initialState.companyTemplatesDetails;
      state.products = initialState.products;
      state.initialProductsState = initialState.initialProductsState;
      state.productsTotalSum = initialState.productsTotalSum;
      state.customProducts = initialState.customProducts;
      state.customProductsList = initialState.customProductsList;
      state.customServicesList = initialState.customServicesList;
      state.commercialOfferEditData = initialState.commercialOfferEditData;
    },
    updateProduct(state, { payload }) {
      const copyData = [...state.products];
      const { id } = payload;
      const targetIdx = copyData.findIndex(i => i.id === id);
      state.products = copyData.map((obj, index) => {
        return index === targetIdx ? payload : obj;
      });
    },
    deleteProduct(state, { payload }) {
      state.products = [...state.products].filter(
        item => item.id !== payload.id,
      );
    },
    resetAllProducts(state, { payload }) {
      state.products = state.initialProductsState;
    },
  },
  extraReducers: {
    ///////////////////getCompanyList///////////////////
    [operation.getCompanyList.pending]: (state, { payload }) => {
      state.companyListLoading = true;
    },
    [operation.getCompanyList.fulfilled]: (state, { payload }) => {
      const data = [...payload];
      state.companyList = data.map(item => ({
        id: item.id,
        name: item.company_name,
      }));
      state.companyTemplatesDetails = data;
      state.companyListLoading = false;
    },
    [operation.getCompanyList.rejected]: (state, { payload }) => {
      state.companyListLoading = false;
    },
    ///////////////////createCompany///////////////////
    [operation.createCompany.pending]: (state, { payload }) => {
      state.loading = true;
    },
    [operation.createCompany.fulfilled]: (state, { payload }) => {
      const data = [...payload];
      state.companyList = data.map((item, idx) => ({
        id: item.id,
        name: item.company_name,
        selected: idx === 0,
      }));
      state.companyTemplatesDetails = data;
      state.loading = false;
    },
    [operation.createCompany.rejected]: (state, { payload }) => {
      state.loading = false;
    },
    ///////////////////deleteCompany///////////////////
    [operation.deleteCompany.pending]: (state, { payload }) => {
      state.loading = true;
    },
    [operation.deleteCompany.fulfilled]: (state, { payload }) => {
      const data = [...payload];
      state.companyList = data.map((item, idx) => ({
        id: item.id,
        name: item.company_name,
        selected: idx === 0,
      }));
      state.companyTemplatesDetails = data;
      state.loading = false;
    },
    [operation.deleteCompany.rejected]: (state, { payload }) => {
      state.loading = false;
    },
    ///////////////////updateCompany///////////////////
    [operation.updateCompany.pending]: (state, { payload }) => {
      state.loading = true;
    },
    [operation.updateCompany.fulfilled]: (state, { payload }) => {
      const data = [...payload];
      state.companyList = data.map(item => ({
        id: item.id,
        name: item.company_name,
      }));
      state.companyTemplatesDetails = data;
      state.loading = false;
    },
    [operation.updateCompany.rejected]: (state, { payload }) => {
      state.loading = false;
    },
    //===============createCommercialOffer=====================//
    [operation.createCommercialOffer.pending]: (state, { payload }) => {},
    [operation.createCommercialOffer.fulfilled]: (state, { payload }) => {
      const { links, id } = payload;
      state.commercialOfferId = id;
      state.links = links;
    },
    [operation.createCommercialOffer.rejected]: (state, { payload }) => {},

    ///////////////////createCommercialOfferTemplate///////////////////
    [operation.createCommercialOfferTemplate.pending]: (
      state,
      { payload },
    ) => {},
    [operation.createCommercialOfferTemplate.fulfilled]: (
      state,
      { payload },
    ) => {},
    [operation.createCommercialOfferTemplate.rejected]: (
      state,
      { payload },
    ) => {},
    ///////////////////getCommercialOfferProducts///////////////////
    [operation.getCommercialOfferProducts.pending]: (state, { payload }) => {
      state.productsLoading = true;
    },
    [operation.getCommercialOfferProducts.fulfilled]: (state, { payload }) => {
      state.productsLoading = false;
      state.initialProductsState = [...payload.data];
      state.products = [...payload.data];
      state.productsTotalSum = payload.total;
    },
    [operation.getCommercialOfferProducts.rejected]: (state, { payload }) => {
      state.productsLoading = false;
    },
    ///////////////////getCommercialOfferCustomsProducts///////////////////
    [operation.getCommercialOfferCustomsProducts.pending]: (
      state,
      { payload },
    ) => {
      state.customProductsLoading = true;
    },
    [operation.getCommercialOfferCustomsProducts.fulfilled]: (
      state,
      { payload },
    ) => {
      state.customProducts = payload.data;
      state.customProductsLoading = false;

      const cloneData = JSON.parse(JSON.stringify(payload.data));
      for (const key in cloneData) {
        cloneData[key].unshift({ id: -1, name: 'Створити нову позицію' });
      }
      state.customProductsList = [...cloneData.products].map(el => ({
        id: el.id,
        name: el.name,
      }));
      state.customServicesList = [...cloneData.services].map(el => ({
        id: el.id,
        name: el.name,
      }));
    },
    [operation.getCommercialOfferCustomsProducts.rejected]: (
      state,
      { payload },
    ) => {
      state.customProductsLoading = false;
    },
    ///////////////////createCustomProduct///////////////////
    [operation.createCustomProduct.pending]: (state, { payload }) => {
      state.listLoading = true;
    },
    [operation.createCustomProduct.fulfilled]: (state, { payload }) => {
      state.listLoading = false;
      state.products = [...state.products, payload.data];
      state.productsTotalSum = payload.total;
    },
    [operation.createCustomProduct.rejected]: (state, { payload }) => {
      state.listLoading = false;
    },
    ///////////////////removeCustomProduct///////////////////
    [operation.removeCustomProduct.pending]: (state, { payload }) => {
      state.listLoading = true;
    },
    [operation.removeCustomProduct.fulfilled]: (state, { payload }) => {
      state.listLoading = false;
      state.products = payload.data;
      state.productsTotalSum = payload.total;
    },
    [operation.removeCustomProduct.rejected]: (state, { payload }) => {
      state.listLoading = false;
    },
    ///////////////////updateCustomProduct///////////////////
    [operation.updateCustomProduct.pending]: (state, { payload }) => {
      state.listLoading = true;
    },
    [operation.updateCustomProduct.fulfilled]: (state, { payload }) => {
      const find = [...state.products].find(
        item => item.id === payload.data.id,
      );
      if (!find) {
        state.products = [...state.products, payload.data];
      } else {
        Object.assign(find, payload.data);
      }
      state.listLoading = false;
      state.productsTotalSum = payload.total;
    },
    [operation.updateCustomProduct.rejected]: (state, { payload }) => {
      state.listLoading = false;
    },
    ///////////////////resetAllChanges///////////////////
    [operation.resetAllChanges.pending]: (state, { payload }) => {
      state.listLoading = true;
    },
    [operation.resetAllChanges.fulfilled]: (state, { payload }) => {
      state.products = payload.data;
      state.productsTotalSum = payload.total;
      state.listLoading = false;
    },
    [operation.resetAllChanges.rejected]: (state, { payload }) => {
      state.listLoading = false;
    },
    ///////////////////editCommercialOffer///////////////////
    [operation.editCommercialOffer.pending]: (state, { payload }) => {
      state.productsLoading = true;
    },
    [operation.editCommercialOffer.fulfilled]: (state, { payload }) => {
      const cloneData = JSON.parse(JSON.stringify(payload));
      state.initialProductsState = payload.products;
      state.products = payload.products;

      delete cloneData.products;

      state.commercialOfferEditData = cloneData;
      state.productsLoading = false;
    },
    [operation.editCommercialOffer.rejected]: (state, { payload }) => {
      state.productsLoading = false;
    },
    ///////////////////updateEditCommercialOffer///////////////////
    [operation.updateEditCommercialOffer.pending]: (state, { payload }) => {},
    [operation.updateEditCommercialOffer.fulfilled]: (state, { payload }) => {
      const { id, links } = payload;
      state.commercialOfferId = id;
      state.links = links;
    },
    [operation.updateEditCommercialOffer.rejected]: (state, { payload }) => {},
  },
});
export const {
  updateProduct,
  resetAllProducts,
  deleteProduct,
  clearCommercialOfferState,
} = commercialOfferSlice.actions;
export default commercialOfferSlice.reducer;
