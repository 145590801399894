import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import moment from 'moment';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Box } from '@root/assets/image';
import { Sprite } from '@root/assets/svg';
import {
  Empty,
  InfiniteScroll,
  ModalStatusOrder,
  SearchInput,
} from '@root/components';
import { getOrderTabList } from '@root/conf';
import { getCurrentDate, getFirstDateOfCurrentMonth } from '@root/helpers';
import { useNavigation, useQueryParams } from '@root/hooks';
import { ErrorChecker1C } from '@root/middleware';
import { WidthContext } from '@root/providers/WidthProvider';
import {
  ButtonBase,
  CustomScroll,
  NavigationButton,
  ProgressLoader,
  RangeDatePicker,
  ToggleBtn,
} from '@root/ui';

import optionSelectors from '@redux/option/option-selectors';
import orderOperation from '@redux/order/order-operation';
import orderSelectors from '@redux/order/order-selectors';

import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';

import { ChipBox } from './ChipBox';
import { FilterList } from './FilterList';
import { CartHistory } from './Item';

export const HistoryList = ({
  data,
  setData,
  filterActive,
  setFilterActive,
  handleFetchOrders,
  initialParams,
  clearOnNavigation,
  debounceSearchValue,
  onOrderClick,
}) => {
  const {
    getQueryObj,
    handleUpdateString,
    handleHasQuery,
    appendParam,
    setSearchParams,
  } = useQueryParams();
  const { date_from, date_to } = getQueryObj();

  const [choiceId, setChoiceId] = useState();
  const [chipHeight, setChipHeight] = useState(0);
  const [filterOpen, setFilterOpen] = useState(false);
  const [datesRange, setDatesRange] = useState([
    date_from || getFirstDateOfCurrentMonth(),
    date_to || getCurrentDate(),
  ]);

  const isFirstRender = useRef(true);
  const { navigation } = useNavigation();
  const { orderTab } = useParams();
  const { t } = useTranslation(['order_history', 'search'], {
    useSuspense: false,
  });
  const { color } = useTheme();

  const myOrders = useSelector(orderSelectors.getMyOrders);
  const archiveErrorOneC = useSelector(
    orderSelectors.getError,
  ).archiveErrorOneC;
  const archiveOrders = useSelector(orderSelectors.getArchiveOrders);
  const filters = useSelector(optionSelectors.getHistoryFilters);
  const meta = useSelector(orderSelectors.getMyOrdersMeta);
  const loading = useSelector(orderSelectors.getLoadingHistory);
  const orderHistoryById = useSelector(orderSelectors.getOrderHistoryById);
  const dispatch = useDispatch();
  const { searchValue } = data;

  const orderId = Number(getQueryObj().order);

  const { screenWidth } = useContext(WidthContext);

  const isArchive = orderTab === 'archive-orders';
  const isCommercialOffers = orderTab === 'my-commercial-offers';

  const archiveOrdersForFilter = useMemo(() => {
    if (isArchive) {
      return archiveOrders.filter(el => {
        const { amount, period, orders } = filterActive;
        const unixDate = moment(el?.date).valueOf();

        if (el.title?.toLowerCase().includes(searchValue.toLowerCase())) {
          const isData =
            period?.length > 0
              ? moment(period[0]).valueOf() <= unixDate &&
                moment(period[1]).valueOf() >= unixDate
              : true;

          const isAmount =
            amount?.length > 0
              ? Number(amount[0]) <= Number(el.amount) &&
                Number(amount[1]) >= Number(el.amount)
              : true;

          const isStatus = orders
            ? orders?.length > 0
              ? orders.includes(el.status_key)
              : true
            : true;

          if (isAmount && isData && isStatus) return el;
        }
      });
    } else {
      return archiveOrders;
    }
  }, [archiveOrders, filterActive, orderTab, searchValue]);

  const selectedOrders = useMemo(() => {
    switch (orderTab) {
      case 'my-orders':
        return myOrders;
      case 'archive-orders':
        return archiveOrdersForFilter;
      case 'my-commercial-offers':
        return myOrders;
    }
  }, [orderTab, myOrders, archiveOrdersForFilter]);

  useEffect(() => {
    if (selectedOrders?.length > 0 && orderId) {
      handelClick(orderId);
      if (isCommercialOffers) {
        if (orderId) {
          navigation('/orders/my-commercial-offers');
        }
      }
    }
  }, [selectedOrders, orderId]);

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }

    handleFetchOrders({ orderTab, date_from, date_to });
  }, [date_from, date_to]);

  useEffect(() => {
    setDatesRange([getFirstDateOfCurrentMonth(), getCurrentDate()]);
  }, [orderTab]);

  const onChange = name => value => {
    setData(prevState => ({ ...prevState, [name]: value }));
  };

  const handelClick = id => {
    onOrderClick();
    setChoiceId(id);

    dispatch(orderOperation.getHistoryOrderById({ id, orderTab }));
  };

  const handelClear = e => {
    setData({
      searchValue: '',
    });

    switch (orderTab) {
      case 'my-orders':
        dispatch(
          orderOperation.getMyOrdersBySearch({
            page: 1,
            search: '',
            filterActive,
          }),
        );
        break;

      default:
        break;
    }
  };

  const handelSubmitFilter = valueRes => {
    handleHasQuery('filter');

    for (const key in valueRes) {
      const valueParam = `${key}:` + valueRes[key].join(',');
      if (valueRes[key]?.length > 0) appendParam('filter', valueParam);
    }
    handleUpdateString();

    switch (orderTab) {
      case 'my-orders':
        dispatch(
          orderOperation.getMyOrdersBySearch({
            page: 1,
            search: searchValue,
            filterActive: valueRes,
          }),
        );
        break;
      case 'my-commercial-offers':
        dispatch(
          orderOperation.getCoOrdersBySearch({
            page: 1,
            search: searchValue,
            filterActive: valueRes,
          }),
        );
        break;
      default:
        break;
    }
  };
  const currentUrl = (orderTab, params) => {
    const { date_from, date_to } = params;
    switch (orderTab) {
      case 'my-orders':
        return `/orders/${orderTab}`;
      case 'archive-orders':
        return `/orders/${orderTab}?date_from=${date_from}&date_to=${date_to}`;
      case 'my-commercial-offers':
        return `/orders/${orderTab}`;
      default:
        break;
    }
  };

  const handleNavigation = orderTab => {
    const { date_from, date_to } = initialParams;
    navigation(currentUrl(orderTab, initialParams));
    handleFetchOrders({
      search: '',
      filterConf: [],
      orderTab,
      date_from,
      date_to,
    });
    setFilterOpen(false);
    clearOnNavigation();
    isFirstRender.current = true;
  };

  const onChangeDate = date => {
    const dateFrom = date[0];
    const dateTo = date[1] && date[1]?.length ? date[1] : '';

    if (!dateFrom || !dateTo) return;

    setDatesRange(date);
    setFilterActive({
      amount: [],
      period: [],
    });
    setSearchParams({
      date_from: dateFrom,
      date_to: dateTo,
    });
  };

  const handleInfinityScroll = inView => {
    if (inView && !isArchive) {
      switch (orderTab) {
        case 'my-orders':
          dispatch(
            orderOperation.getMyOrders({
              page: meta.current_page + 1,
              search: data.searchValue,
              filterActive,
            }),
          );
          break;
        case 'my-commercial-offers':
          dispatch(
            orderOperation.getCoOrders({
              page: meta.current_page + 1,
              search: data.searchValue,
              filterActive,
            }),
          );
          break;
        default:
          break;
      }
    }
  };

  return (
    <Components color={color}>
      <NavigationWrapper>
        {getOrderTabList(t).map(({ key, isDisabled, label }) => (
          <NavigationButton
            key={key}
            isActive={orderTab === key}
            sx={{ padding: '8px 20px !important' }}
            disabled={isDisabled}
            onClick={() => handleNavigation(key)}
          >
            {label}
          </NavigationButton>
        ))}
      </NavigationWrapper>

      <Divider color={color} />

      <Search color={color}>
        <FlexWrapper>
          {orderTab === 'archive-orders' && (
            <RangeDatePicker
              inputSx={{
                height: '36px',
                width: screenWidth < 577 ? '100px' : '200px',
              }}
              date={datesRange}
              onChangeDate={onChangeDate}
              onCalendarClose={() => {}}
            />
          )}
          <SearchInput
            focus={false}
            onChange={onChange}
            searchValue={searchValue}
            handleClearSearch={handelClear}
            placeholder={t(
              `search:search_input_${
                !isCommercialOffers ? 'placeholder' : 'co_placeholder'
              }`,
            )}
            sx={{
              '& input': {
                fontSize: '0.875rem',
              },
            }}
            sxLabel={{
              marginRight: 0,
            }}
          />
          <ToggleBtn
            size={36}
            onClick={() => {
              setFilterOpen(!filterOpen);
            }}
            isActive={true}
            href={filterOpen ? `${Sprite}#icon-cross` : `${Sprite}#icon-filter`}
          />
        </FlexWrapper>
        {Object.keys(filters)?.length > 0 && (
          <ChipBox
            filterActive={filterActive}
            setChipHeight={setChipHeight}
            setFilterActive={setFilterActive}
            handelSubmitFilter={handelSubmitFilter}
          />
        )}
      </Search>
      <HistoryListBody chipHeight={chipHeight}>
        <>{loading && <ProgressLoader />}</>{' '}
        <ErrorChecker1C
          error={archiveErrorOneC}
          errorPageStyles={{
            position: 'absolute',
            top: '55%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '65%',
            height: 'fit-content',
            padding: 0,
          }}
        >
          {selectedOrders?.length > 0 ? (
            <ListOfHistory>
              <div style={{ height: '100%' }}>
                <CustomScroll vertical={true}>
                  <InfiniteScroll
                    data={selectedOrders}
                    onScroll={({ inView }) =>
                      inView &&
                      meta?.current_page !== meta?.last_page &&
                      handleInfinityScroll(inView)
                    }
                  >
                    {({ isInView, item, index }) => {
                      return (
                        <CartHistory
                          data={item}
                          isActive={choiceId === item.id}
                          onClick={handelClick}
                        />
                      );
                    }}
                  </InfiniteScroll>
                </CustomScroll>{' '}
              </div>
            </ListOfHistory>
          ) : debounceSearchValue?.length > 0 &&
            selectedOrders?.length === 0 ? (
            <Empty type={'EmptySearchLogo'} />
          ) : isArchive ? (
            <Empty
              type={'NoHistory'}
              title={t('order_history:not_found_order_in_time_range')}
            />
          ) : (
            !loading && (
              <EmptyBox>
                <img src={Box} alt="Box" />
                <Typography
                  variant={'bodyBoldSmall'}
                  component={'p'}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    mt: '40px',
                    mb: '20px',
                    color: color.gray,
                  }}
                >
                  {t(
                    `order_history:${
                      !isCommercialOffers
                        ? 'add_first_order'
                        : 'add_first_co_order'
                    }`,
                  )}
                </Typography>
                <ButtonBase
                  onClick={() => {
                    navigation('/catalog');
                  }}
                  sx={{
                    height: '38px',
                  }}
                >
                  {t('order_history:buttons.nav')}
                </ButtonBase>
              </EmptyBox>
            )
          )}
        </ErrorChecker1C>
      </HistoryListBody>

      {!isNaN(orderId) && orderHistoryById?.status && !isCommercialOffers && (
        <ModalStatusOrder
          open={orderId}
          orderId={orderId}
          isFulfilled={orderHistoryById?.payed}
          hideControl
        />
      )}
      {filterOpen && (
        <FilterList
          filterActive={filterActive}
          setFilterActive={setFilterActive}
          handelSubmitFilter={handelSubmitFilter}
          filterClose={() => setFilterOpen(false)}
        />
      )}
    </Components>
  );
};

const Components = styled.div`
  width: 35%;
  border: 1px solid ${({ color }) => color.line};
  background: ${({ color }) => color.white};
  border-radius: 4px;
  height: 100%;
  position: relative;
  overflow: hidden;

  @media (max-width: 1400px) {
    width: 50%;
  }

  @media (max-width: 1024px) {
    width: 100%;
  }
`;

const ListOfHistory = styled.ul`
  position: relative;
  min-height: 100%;
  height: 100%;
`;

const EmptyBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 200px 0;
`;

const Search = styled.form`
  position: relative;
  padding: 10px 11px;

  border-bottom: 1px solid ${({ color }) => color.line};
  z-index: 200;

  background-color: ${props => props.color.white};
`;

const FlexWrapper = styled.div`
  margin-top: ${({ gap }) => gap}px;
  display: flex;
  gap: 10px;
`;

const Divider = styled.div`
  border-bottom: 1px solid ${({ color }) => color.line};
`;

const NavigationWrapper = styled.div``;

const HistoryListBody = styled.div`
  position: relative;
  min-height: calc(100vh - 280px - ${({ chipHeight }) => chipHeight}px);
  height: calc(100vh - 280px - ${({ chipHeight }) => chipHeight}px);

  ul {
    height: 100%;
  }
`;

HistoryList.className = Components;

HistoryList.propTypes = {
  data: PropTypes.exact({
    searchValue: PropTypes.string,
  }),
  setData: PropTypes.func.isRequired,
  filterActive: PropTypes.exact({
    amount: PropTypes.arrayOf(PropTypes.number),
    period: PropTypes.arrayOf(PropTypes.string),
  }),
  debounceSearchValue: PropTypes.string,
  setFilterActive: PropTypes.func.isRequired,
};
