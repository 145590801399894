import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import { CopyToClipboard, HeaderDefault } from '@root/components';
import { getErrorStack, handleDispatchFetch } from '@root/helpers';
import { ButtonBase, ModalBase, ProgressLoader, TextInput } from '@root/ui';

import userOperation from '@redux/user/user-operation';
import userSelectors from '@redux/user/user-selectors';

import { Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import { getTwoFactorAuthenticationInfo } from './API/getTwoFactorAuthenticationInfo';

export const ValidateTwoFactorAuthenticationModal = ({
  open,
  handleCloseModal,
}) => {
  const isTwoFactorAuthenticationActive = useSelector(
    userSelectors.getIsTwoFactorAuthenticationActive,
  );

  const activateTwoFactorAuthenticationLoading = useSelector(
    userSelectors.getActivateTwoFactorAuthenticationLoading,
  );

  const { color } = useTheme();
  const { t } = useTranslation(['validation', 'common', 'profile'], {
    useSuspense: false,
  });
  const [secretKey, setSecretKey] = useState('');
  const [twoFactorAuthenticationInfo, setTwoFactorAuthenticationInfo] =
    useState({
      qrcode: '',
      secret: '',
    });
  const [
    isTwoFactorAuthenticationInfoLoading,
    setIsTwoFactorAuthenticationInfoLoading,
  ] = useState(false);
  const [error, setError] = useState({});

  const dispatch = useDispatch();

  useEffect(() => {
    if (isTwoFactorAuthenticationActive) return;
    (async () => {
      try {
        setIsTwoFactorAuthenticationInfoLoading(true);
        const { data } = await getTwoFactorAuthenticationInfo();
        setIsTwoFactorAuthenticationInfoLoading(false);
        setTwoFactorAuthenticationInfo(data.data);
      } catch (error) {
        console.log(error);
      }
    })();
  }, []);

  const onChange = () => value => {
    setSecretKey(value);
  };

  const validation = () => {
    let currentError = {};
    let flag = false;
    if (!secretKey || secretKey === '') {
      currentError = getErrorStack(
        currentError,
        'secret',
        t('validation:required'),
      );
      flag = true;
    }

    setError(currentError);
    return flag;
  };

  const handleActivateSecretKey = () => {
    if (validation()) return;
    handleDispatchFetch(
      ({ onResolve, onReject }) => {
        if (!isTwoFactorAuthenticationActive) {
          dispatch(
            userOperation.activateTwoFactorAuthentication({
              onResolve,
              onReject,
              secret: secretKey,
            }),
          );
        } else {
          dispatch(
            userOperation.deactivateTwoFactorAuthentication({
              onResolve,
              onReject,
              secret: secretKey,
            }),
          );
        }
      },
      () => {
        handleCloseModal();
      },
      message => {
        setError({
          secret: { init: true, text: message },
        });
      },
    );
  };

  return (
    <ModalBase
      open={open}
      handleClose={handleCloseModal}
      sx={{
        width: '100%',
        maxWidth: '542px',
      }}
      modalHeader={
        <HeaderDefault
          text={t(
            'profile:security_page.modals.check_authentication_modal.title',
          )}
          onClose={handleCloseModal}
        />
      }
      modalBottom={
        <ButtonWrapper>
          <ButtonBase onClick={() => handleActivateSecretKey()}>
            {t('common:buttons.save')}
          </ButtonBase>
        </ButtonWrapper>
      }
      scrollPadding="0px 5px 0px 0px"
    >
      <AuthenticationVerificationModalWrapper
        minHeight={!isTwoFactorAuthenticationActive ? '422px' : 'auto'}
      >
        {isTwoFactorAuthenticationInfoLoading ? (
          <ProgressLoader />
        ) : (
          <>
            {activateTwoFactorAuthenticationLoading && <ProgressLoader />}
            {!isTwoFactorAuthenticationActive && (
              <ImgWrapper>
                {twoFactorAuthenticationInfo?.qrcode && (
                  <Img src={twoFactorAuthenticationInfo?.qrcode} />
                )}
              </ImgWrapper>
            )}

            {!isTwoFactorAuthenticationActive ? (
              <>
                <Typography
                  variant="bodyBoldLarge"
                  color={color.gray}
                  component="p"
                  textAlign="center"
                  marginTop="20px"
                >
                  {t(
                    'profile:security_page.modals.check_authentication_modal.scan_qr_code_or_request_key',
                  )}
                </Typography>
                <Typography
                  variant="bodyMedium"
                  color={color.gray_60}
                  component="p"
                  textAlign="center"
                  marginTop="4px"
                  marginBottom="20px"
                >
                  {t(
                    'profile:security_page.modals.check_authentication_modal.complete_settings',
                  )}
                </Typography>
              </>
            ) : (
              ''
            )}

            {twoFactorAuthenticationInfo?.secret &&
              !isTwoFactorAuthenticationActive && (
                <CopyToClipboard
                  copyText={twoFactorAuthenticationInfo?.secret}
                  onCopySuccess={() => {}}
                  styles={{ justifyContent: 'center' }}
                >
                  <Typography color={color.gray_60} variant="bodyBoldLarge">
                    {twoFactorAuthenticationInfo?.secret}
                  </Typography>
                </CopyToClipboard>
              )}

            <TextInput
              sx={{
                alignSelf: 'stretch',
                height: '36px',
                marginTop: '20px',
              }}
              placeholder={t(
                'profile:security_page.modals.check_authentication_modal.type_code_placeholder',
              )}
              onChange={onChange('secret_key')}
              value={secretKey}
              error={error?.secret?.init}
              errorText={error?.secret?.text}
            />
            <Typography
              variant="bodyMedium"
              color={color.gray_60}
              textAlign="center"
              component="p"
              marginTop="20px"
            >
              {t(
                'profile:security_page.modals.check_authentication_modal.you_can',
              )}
              <Typography variant="bodyBoldMedium" color={color.main}>
                <a
                  href="https://play.google.com/store/search?q=google+authenticator&c=apps&hl=en&gl=US"
                  target="_blank"
                >
                  {t(
                    'profile:security_page.modals.check_authentication_modal.download_authenticator',
                  )}
                </a>
              </Typography>

              <Typography
                variant="bodyMedium"
                color={color.gray_60}
                textAlign="center"
              >
                {t(
                  'profile:security_page.modals.check_authentication_modal.on_phone',
                )}
              </Typography>
            </Typography>
          </>
        )}
      </AuthenticationVerificationModalWrapper>
    </ModalBase>
  );
};

const AuthenticationVerificationModalWrapper = styled.div`
  min-height: ${({ minHeight }) => minHeight};
`;

const Img = styled.img`
  width: 160px;
  height: 160px;
  margin-top: 15px;
`;

const ImgWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
`;

ValidateTwoFactorAuthenticationModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleCloseModal: PropTypes.func.isRequired,
};
