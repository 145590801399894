import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import { HeaderDefault } from '@root/components';
import { getErrorStack } from '@root/helpers/validation';
import authOperation from '@root/redux/auth/auth-operation';
import userOperation from '@root/redux/user/user-operation';
import { ButtonBase, ModalBase, RadioCustom, TextInput } from '@root/ui';

import FormControlLabel from '@mui/material/FormControlLabel';
import RadioGroup from '@mui/material/RadioGroup';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';

export const ConfirmModal = ({ phone, handleClose, changeConfirm, open }) => {
  const [chose, setChose] = useState('');
  const [code, setCode] = useState('');
  const { t } = useTranslation(['registration'], { useSuspense: false });
  const { color } = useTheme();
  const dispatch = useDispatch();
  const [error, setError] = useState({});
  const codeLength = 10;

  const validation = () => {
    let flag = false;
    let currentError = {};

    if (!code) {
      currentError = getErrorStack(
        currentError,
        'code',
        t('validation:required'),
      );
      flag = true;
    }

    if (code.toString().length !== codeLength) {
      currentError = getErrorStack(
        currentError,
        'code',
        t('validation:otp_length_not_match', {
          length: codeLength,
        }),
      );
      flag = true;
    }

    setError(currentError);
    return flag;
  };

  const handleErrors = msg => {
    let currentError = {};
    currentError = getErrorStack(currentError, 'code', msg);
    setError(currentError);
  };

  const SubmitForm = () => {
    if (validation()) return;

    let regexp = /\d/g;
    const valueEx = `${phone}`.match(regexp).join('');
    dispatch(
      userOperation.validatePhoneConfirmation({
        formData: {
          phone: valueEx,
          code: code,
        },
        onResolve: () => {
          changeConfirm(valueEx);
          handleClose();
        },
        onReject: message => {
          handleErrors(message);
        },
      }),
    );
  };

  const onChangeInput = name => value => setCode(value);
  const onChangeRadio = event => {
    setChose(event.target.value);

    let regexp = /\d/g;
    const valueEx = `${phone}`.match(regexp).join('');

    dispatch(
      userOperation.sendPhoneConfirmation({
        formData: {
          phone: valueEx,
          driver: event.target.value,
        },
      }),
    );
  };

  const massage = () => {
    const phoneArray = phone.split('');
    const phoneMurkUp = phoneArray.reduce((acc, el, i) => {
      if (i === 0 || i === 2 || i === 7) {
        acc = acc + `${el} `;
      } else if (i === 10 || i === 12 || i === 13) {
        acc = acc + `X`;
      } else {
        acc = acc + `${el}`;
      }

      return acc;
    }, '');

    return (
      <WrapperForm>
        {' '}
        <Typography
          variant={'bodyMedium'}
          sx={{ color: color.gray_80, mt: '18px' }}
          component={'p'}
        >
          {t('registration:step_two.modal.label_phone_conf', { phoneMurkUp })}
        </Typography>
        <FlexContainer>
          <TextInput
            sx={{ mt: '28px', width: '100%', height: '48px' }}
            value={code}
            onChange={onChangeInput('code')}
            placeholder={t('registration:field.sms')}
            type="text"
            max={10}
            withOutSpace={true}
            error={error?.code?.init}
            errorText={error?.code?.text}
            digitsOnly={true}
          />
          <ButtonBase
            type="button"
            size={'large'}
            sx={{ mt: '28px', p: '16px 34px', ml: '12px' }}
            onClick={SubmitForm}
          >
            <Typography variant={'bodyBoldSmall'}>
              {' '}
              {t('registration:step_two.modal.confirm_btn')}
            </Typography>
          </ButtonBase>
        </FlexContainer>
      </WrapperForm>
    );
  };

  return (
    <ModalBase
      open={open}
      handleClose={handleClose}
      sx={{ width: '442px' }}
      modalHeader={
        <HeaderDefault
          text={t('registration:step_two.modal.title')}
          onClose={handleClose}
        />
      }
    >
      <Typography
        variant={'bodyMedium'}
        sx={{ color: color.gray_80, mt: '12px' }}
        component={'p'}
      >
        {t('registration:step_two.modal.slogan')}
      </Typography>

      <RadioGroup
        aria-labelledby="demo-radio-buttons-group-label"
        name="radio-buttons-group"
        value={chose}
        onChange={onChangeRadio}
        sx={{
          marginLeft: '2px',
        }}
      >
        <FormControlLabel
          value="viber"
          control={<RadioCustom />}
          label={
            <Typography
              variant={'bodyMedium'}
              sx={{ color: color.gray_80 }}
              component={'p'}
            >
              Viber
            </Typography>
          }
        />
        {chose === 'viber' && massage()}
        <FormControlLabel
          value="sms"
          control={<RadioCustom />}
          label={
            <Typography
              variant={'bodyMedium'}
              sx={{ color: color.gray_80 }}
              component={'p'}
            >
              SMS
            </Typography>
          }
        />
        {chose === 'sms' && massage()}
      </RadioGroup>
    </ModalBase>
  );
};

const WrapperForm = styled.form``;

const FlexContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 16px;
  align-items: flex-start;
`;
ConfirmModal.propTypes = {
  phone: PropTypes.string,
  handleClose: PropTypes.func.isRequired,
  changeConfirm: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};
