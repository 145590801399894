import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const getCategorys = createAsyncThunk(
  'spec-guide/getCategorys',
  async (
    { onResolve, onReject },
    { rejectWithValue },
  ) => {
    try {
      const { data: { data } } = await axios.get('/category');
      if (onResolve) onResolve(data);
      return data;
    } catch (error) {
      if (onReject) onReject(error.response.data.message);
      return rejectWithValue(error);
    }
  },
);

const getSubCategorys = createAsyncThunk(
  'spec-guide/getSubCategorys',
  async (
    { categoryId, onResolve, onReject },
    { rejectWithValue },
  ) => {
    try {
      const { data: { data } } = await axios.get('/category', {
        params: { category_id: categoryId, show_all: true }
      });
      if (onResolve) onResolve(data);
      return data;
    } catch (error) {
      if (onReject) onReject(error.response.data.message);
      return rejectWithValue(error);
    }
  },
);

const getAutoComplete = createAsyncThunk(
  'spec-guide/getAutoComplete',
  async (
    { categoryId, q, onResolve, onReject },
    { rejectWithValue },
  ) => {
    try {
      const { data: { data } } = await axios.get(`/properties/autocomplete/${categoryId}`, {
        params: { search: q }
      });
      if (onResolve) onResolve(data);
      return { data };
    } catch (error) {
      if (onReject) onReject(error.response.data.message);
      return rejectWithValue(error);
    }
  },
);

const storeSpecs = createAsyncThunk(
  'spec-guide/storeSpecs',
  async (
    { categoryId, items, onResolve, onReject },
    { rejectWithValue },
  ) => {
    try {
      const { data: { data } } = await axios.post(`/properties/category/${categoryId}`, { data: items });
      if (onResolve) onResolve(data);
      return data;
    } catch (error) {
      if (onReject) onReject(error.response.data.message);
      return rejectWithValue(error);
    }
  },
);

const getSpescByCategoryId = createAsyncThunk(
  'spec-guide/getSpescByCategoryId',
  async (
    { categoryId, onResolve, onReject },
    { rejectWithValue },
  ) => {
    try {
      const { data: { data } } = await axios.get(`/properties/category/${categoryId}`);
      if (onResolve) onResolve(data);
      return data;
    } catch (error) {
      if (onReject) onReject(error.response.data.message);
      return rejectWithValue(error);
    }
  },
);


export default {
  getCategorys,
  getSubCategorys,
  getAutoComplete,
  storeSpecs,
  getSpescByCategoryId,
};
