import React from 'react';
import { useTranslation } from 'react-i18next';

import styled from 'styled-components';

import { HeaderDefault } from '@root/components';
import { Link as LinkDecorator, ModalBase } from '@root/ui';

import { Typography, useTheme } from '@mui/material';

export const WriteDocumentModal = ({ open, handleClose }) => {
  const { color } = useTheme();
  const { t } = useTranslation(['accounting_documents'], {
    useSuspense: false,
  });
  return (
    <ModalBase
      open={open}
      handleClose={handleClose}
      sx={{
        width: '598px',
      }}
      modalHeader={
        <HeaderDefault
          text={t('accounting_documents:sign_document_by')}
          onClose={handleClose}
        />
      }
    >
      <ModalWrapper>
        <ContentWrapper>
          <Link
            color={color}
            href="https://diia.gov.ua/services/pidpisannya-dokumentiv"
            target="_blank"
          >
            <LinkDecorator>
              <Typography variant={'bodyMedium'}>
                {t('accounting_documents:action_company')}
              </Typography>
            </LinkDecorator>
          </Link>

          <Link href="https://vchasno.ua/" target="_blank">
            <LinkDecorator>
              <Typography variant={'bodyMedium'}>
                {t('accounting_documents:on_time_company')}
              </Typography>
            </LinkDecorator>
          </Link>
        </ContentWrapper>
      </ModalWrapper>
    </ModalBase>
  );
};

const ModalWrapper = styled.div``;
const ContentWrapper = styled.div`
  margin-top: 12px;
  display: flex;
  flex-direction: column;
`;
const Link = styled.a`
  display: inline-block;
  padding: 12px 0;
  border-bottom: 1px solid
    ${props => (props.color ? props.color.line_light : 'transparent')};

  &:first-child {
    padding-top: 0;
  }

  &:last-child {
    padding-bottom: 0;
  }
`;
