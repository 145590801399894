import PropTypes from 'prop-types';

import MenuItem from '@mui/material/MenuItem';
import { useTheme } from '@mui/material/styles';

export const MenuItemCustoms = ({ handelClick, children, isActive }) => {
  const { color } = useTheme();

  return (
    <MenuItem
      className={isActive && 'active'}
      color={'secondary'}
      sx={{
        padding: '4px 5px',
        borderRadius: '2px',
        '&:hover': {
          backgroundColor: `${color.gray_20} `,
          color: `${color.gray_80} `,
        },
        '&:active': {
          backgroundColor: `${color.main}!important`,
          color: `${color.white}!important`,
        },
      }}
      onClick={handelClick}
    >
      {children}
    </MenuItem>
  );
};

MenuItemCustoms.propTypes = {
  handelClick: PropTypes.func.isRequired,
  children: PropTypes.node,
};
