import React from 'react';
import { useTranslation } from 'react-i18next';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import { staticDiscountSelectData, staticTimeSelectData } from '@root/conf';
import { BasicSelect, CustomizedSwitches, TextArea, TextInput } from '@root/ui';

import { Divider, Typography, useTheme } from '@mui/material';

export const Calculation = ({ commercialData, setCommercialData, mobileCatalogStyles }) => {
  const { color } = useTheme();
  const { t } = useTranslation('commercial_offer', { useSuspense: false });

  const {
    order_calculation,
    proposition_count,
    proposition_time,
    give_discount,
    discount,
    discount_option,
  } = commercialData;

  const onChange = name => value => {
    setCommercialData(prevstate => ({ ...prevstate, [name]: value }));
  };


  return (
    <Wrapper>
      <Typography
        sx={{
          marginBottom: '16px',
        }}
        component="p"
        variant="bodyBoldLarge"
        color={color.gray}
      >
        {t('calculation')}
      </Typography>
      <Divider
        sx={{
          margin: '0 -23px 30px',
        }}
      />
      <div style={{ width: !mobileCatalogStyles ? '70%' : '100%'  }}>
        <TextArea
          label={t('calculation_fields.calculation_procedure_label')}
          placeholder={t(
            'calculation_fields.calculation_procedure_placeholder',
          )}
          max={500}
          value={order_calculation}
          onChange={onChange('order_calculation')}
        />
        <FiledWrap>
          <TextInput
            label={t('calculation_fields.proposition_label')}
            sx={{
              flex: '50%',
            }}
            digitsOnly
            value={proposition_count}
            onChange={onChange('proposition_count')}
          />
          <BasicSelect
            sx={{
              flex: '50%',
              alignSelf: 'end',
            }}
            currentId={proposition_time}
            callBack={onChange('proposition_time')}
            data={staticTimeSelectData(t)}
          />
        </FiledWrap>
        <FiledWrap flexDirection={'column'}>
          <CustomizedSwitches
            sx={{ margin: 0 }}
            label={t('calculation_fields.give_discount')}
            checked={give_discount}
            onChange={onChange('give_discount', give_discount)}
          />
          <FiledWrap margin={'0'}>
            <TextInput
              sx={{
                flex: '50%',
              }}
              digitsOnly
              removeLeadingZero
              value={discount}
              onChange={onChange('discount')}
              disabled={!give_discount}
            />
            <BasicSelect
              disabled={!give_discount}
              sx={{
                flex: '50%',
                alignSelf: 'end',
              }}
              currentId={discount_option}
              callBack={onChange('discount_option')}
              data={staticDiscountSelectData(t)}
            />
          </FiledWrap>
        </FiledWrap>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  padding: 25px 23px 34px 23px;

  @media(max-width: 991px) {
    padding: 15px;
  }
`;

const FiledWrap = styled.div`
  display: flex;
  flex-direction: ${({ flexDirection }) =>
    flexDirection ? flexDirection : 'row'};
  align-items: ${({ flexDirection }) =>
    flexDirection ? 'flex-start' : 'center'};
  gap: 8px;
  margin-top: ${({ margin }) => (margin ? margin : '12px')};
  width: 100%;
`;

Calculation.propTypes = {
  commercialData: PropTypes.arrayOf(
    PropTypes.exact({
      company_id: PropTypes.string,
      order_calculation: PropTypes.string,
      proposition_count: PropTypes.string,
      proposition_time: PropTypes.number,
      give_discount: PropTypes.bool,
      discount: PropTypes.string,
      discount_option: PropTypes.string,
      offer_name: PropTypes.string,
      offer_text: PropTypes.string,
      additional_info: PropTypes.string,
      hide_model: PropTypes.bool,
      hide_product_description: PropTypes.bool,
      offer_accepted: PropTypes.bool,
      offer_declined: PropTypes.bool,
      offer_open: PropTypes.bool,
      offer_expired: PropTypes.bool,
    }),
  ),
  setCommercialData: PropTypes.func.isRequired,
};
