import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import styled from 'styled-components';

import { Sprite } from '@root/assets/svg';
import adminOperation from '@root/redux/admin/admin-operation';
import adminSelectors from '@root/redux/admin/admin-selectors';
import { IconBtn } from '@root/ui';
import { BasicAutocompleteSelect } from '@root/ui/Input/AutocompleteSelect/index';

import { Typography, useTheme } from '@mui/material';


// TODO: modify the component for general use
export const SelectAsyncField = ({
  id,
  value,
  displayValue,
  sx,
  variant,
  onEdit,
  goToStep,
  errors,
  multiple = false,
  operation,
}) => {
  const [isEdit, setIsEdit] = useState(false);
  const [data, setData] = useState([]);
  const [locValue, setLocValue] = useState(multiple ? [] : '');
  const { t } = useTranslation(['common'], { useSuspense: false });
  const { color } = useTheme();
  const dispatch = useDispatch();
  const companys = useSelector(adminSelectors.getSelectCompanys);
  const [autocompleteValue, setAutocompleteValue] = useState('');

  useEffect(() => {
    if (id) setIsEdit(false);
  }, [id]);

  useEffect(() => {}, []);
  useEffect(() => {}, [value]);

  const onEditSuccess = () => {
    setIsEdit(false);
  };

  const onError = () => {};

  const onAutocompleteChange = (event, newInputValue, reason, type) => {
    switch (reason) {
      case 'input':
        dispatch(adminOperation.getCompanysSelect({ q: event.target.value }));
        break;
      default:
        break;
    }
  };

  const autocompleteInputChange = (e, v) => {
    setLocValue(v.id);
    setAutocompleteValue(v);
  };

  return (
    <>
      <FieldDescription>
        {Array.isArray}
        <WrapperBox>
          {isEdit ? (
            <BasicAutocompleteSelect
              error={errors?.init}
              helperText={errors?.text}
              placeholder="placeholder"
              listBoxStyles
              sx={{
                maxWidth: '300px',
                width: '100%',
              }}
              noOptionsText="noOptionsText"
              options={companys}
              getOptionLabel={option => option.label ?? option.name}
              isOptionEqualToValue={(option, value) => option?.id === value?.id}
              autocompleteInputChange={onAutocompleteChange}
              onAutocompleteChange={autocompleteInputChange}
              //   value={autocompleteValue}
              onAutocompleteOpen={e => {}}
            />
          ) : Array.isArray(value) ? (
            value.map(({ name }) => (
              <ActivityAreasWrapper>
                <Typography
                  sx={{ mr: '8px', ...sx }}
                  variant={variant}
                  component={'p'}
                >
                  {name}
                </Typography>
              </ActivityAreasWrapper>
            ))
          ) : (
            <>{displayValue}</>
          )}
        </WrapperBox>
      </FieldDescription>

      {(onEdit || goToStep) &&
        (!isEdit ? (
          <IconBtn
            href={`${Sprite}#icon-pan`}
            onClick={() => (goToStep ? goToStep() : setIsEdit(true))}
            tooltipText={t('common:tooltip.edit')}
          />
        ) : (
          <IconsWrapper>
            <IconBtn
              onClick={() => setIsEdit(false)}
              href={`${Sprite}#icon-cross`}
              sx={{ stroke: color.red }}
            />
            <IconBtn
              onClick={() => {
                onEdit && onEdit(locValue, onEditSuccess, onError);
              }}
              href={`${Sprite}#icon-tick`}
              sx={{ stroke: color.green }}
            />
          </IconsWrapper>
        ))}
    </>
  );
};

const FieldDescription = styled.div`
  flex: 1 1 auto;
`;

const IconsWrapper = styled.div`
  display: flex;
  column-gap: 6px;
`;

const WrapperBox = styled.div`
  display: flex;
  flex-direction: column;
`;

const ActivityAreasWrapper = styled.div`
  display: flex;
`;
