import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import styled from 'styled-components';

import { Sprite } from '@root/assets/svg';
import { CustomizedSwitches, IconBtn } from '@root/ui';

import { Typography, useTheme } from '@mui/material';

export const SwitchField = ({
  id,
  variant,
  sx,
  type,
  value,
  onEdit,
  onCancel,
  editValue,
  errors,
  goToStep,
  width,
}) => {
  const [isChecked, setIsChecked] = useState();
  const [isEdit, setIsEdit] = useState(false);
  const { t } = useTranslation(['common'], { useSuspense: false });

  const { color } = useTheme();

  const handleToggle = value => {
    setIsChecked(value);
    onEdit(value, onEditSuccess);
  };

  useEffect(() => {
    setIsChecked(editValue);
  }, [editValue]);

  useEffect(() => {
    if (id) setIsEdit(false);
  }, [id]);

  const onEditSuccess = () => {
    setIsEdit(false);
  };

  useEffect(() => {
    setIsChecked(editValue);
  }, [isEdit]);

  return (
    <>
      <FieldDescription>
        <WrapperText>
          <Typography
            sx={{
              position: 'relative',
              display: 'flex',
              alignItems: 'center',
              width: width || '190px',
              wordBreak: 'break-all',
              color: `${color.gray} !important`,
              '& .MuiFormControlLabel-root': {
                marginLeft: '0 !important',
              },

              '&::before': {
                // display: isEdit ? 'none' : 'block',
                display: 'none',
                content: '""',
                width: '8px',
                height: '8px',
                background: editValue ? color.green : color.red,
                borderRadius: '40px',
                mr: '5px',
              },
              ...sx,
            }}
            variant={variant}
            component={'p'}
          >
            <CustomizedSwitches
              checked={isChecked}
              onChange={handleToggle}
              errors={errors}
            />
            {/* {isEdit ? (

            ) : type === 'area' ? (
              ''
            ) : (
              value
            )} */}
          </Typography>
        </WrapperText>
      </FieldDescription>

      {/* {(onEdit || goToStep) &&
        (!isEdit ? (
          <IconBtn
            href={`${Sprite}#icon-pan`}
            onClick={() => (goToStep ? goToStep() : setIsEdit(true))}
            tooltipText={t('common:tooltip.edit')}
          />
        ) : (
          <IconsWrapper>
            <IconBtn
              onClick={() => {
                setIsEdit(false);
                onCancel && onCancel();
              }}
              href={`${Sprite}#icon-cross`}
              sx={{ stroke: color.red }}
            />
            <IconBtn
              onClick={() => onEdit && onEdit(isChecked, onEditSuccess)}
              href={`${Sprite}#icon-tick`}
              sx={{ stroke: color.green }}
            />
          </IconsWrapper>
        ))} */}
    </>
  );
};

const FieldDescription = styled.div`
  //flex: 1 1 auto;
`;

const IconsWrapper = styled.div`
  display: flex;
  column-gap: 6px;
`;

const WrapperText = styled.div`
  padding-right: 5px;
`;
