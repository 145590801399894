import { createSlice } from '@reduxjs/toolkit';

import { memoSelectedItem } from '@root/helpers';

import operation from './order-operation';

const initialState = {
  loading: true,
  loadingHistory: false,
  loadingDetails: false,
  order: null,
  orderId: null,
  onlineForm: null,
  error: {
    order: [],
    archiveErrorOneC: false,
  },
  myOrders: [],
  archiveOrders: [],
  commercialOfferOrders: [],
  myOrdersMeta: { current_page: 1 },
  archiveOrdersMeta: { current_page: 1 },
  commercialOfferOrdersMeta: { current_page: 1 },
  orderHistoryById: {},
  savedOrderTemplate: { delivery: [], payment: [] },
};

const orderSlice = createSlice({
  name: 'order',
  initialState,
  reducers: {
    clearOrder: state => {
      state.order = initialState.order;
      state.orderId = initialState.orderId;
      state.onlineForm = initialState.onlineForm;
      state.error = initialState.error;
      state.savedOrderTemplate = initialState.savedOrderTemplate;
      state.loading = initialState.loading;
    },

    clearOrderHistory: state => {
      state.orderHistory = initialState.orderHistory;
      state.orderHistoryMeta = initialState.orderHistoryMeta;
      state.orderHistoryById = initialState.orderHistoryById;
      state.onlineForm = initialState.onlineForm;
      state.loading = initialState.loading;
      state.archiveOrders = initialState.archiveOrders;
      state.error = initialState.error;
    },

    clearOrderHistoryDetails: state => {
      state.loadingDetails = initialState.loadingDetails;
      state.orderHistoryById = initialState.orderHistoryById;
    },

    choseTemplate(state, { payload }) {
      const { id, type } = payload;

      const copyObj = { ...state.savedOrderTemplate };

      copyObj[type] = copyObj[type].map(el => {
        if (el.id === id) {
          el.is_selected = true;
        } else {
          el.is_selected = false;
        }
        return el;
      });

      state.savedOrderTemplate = copyObj;
    },
  },
  extraReducers: {
    //====getOrder====//
    [operation.getOrder.pending]: (state, action) => {
      state.loading = true;
    },
    [operation.getOrder.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.order = payload;
    },
    [operation.getOrder.rejected]: (state, { payload }) => {
      state.loading = false;
    },
    //====getSaveOrderTemplate====//
    [operation.getSaveOrderTemplate.pending]: (state, action) => {
      state.loading = true;
    },
    [operation.getSaveOrderTemplate.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.savedOrderTemplate = payload;
    },
    [operation.getSaveOrderTemplate.rejected]: (state, { payload }) => {
      state.loading = false;
    },
    //====confirmOrder====//
    [operation.confirmOrder.pending]: (state, action) => {
      state.loading = true;
    },
    [operation.confirmOrder.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.orderId = payload?.data.id;
      state.onlineForm = payload.form ? payload.form : null;
    },
    [operation.confirmOrder.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error.order = payload.data;
    },

    //====getMyOrders====//
    [operation.getMyOrders.pending]: (state, action) => {
      state.loadingHistory = true;
    },
    [operation.getMyOrders.fulfilled]: (state, { payload }) => {
      state.loadingHistory = false;
      state.loading = false;
      state.myOrders =
        payload.meta.current_page === 1
          ? payload.data
          : [...state.myOrders, ...payload.data];
      state.myOrdersMeta = payload.meta;
    },
    [operation.getMyOrders.rejected]: (state, { payload }) => {
      state.loadingHistory = false;
      state.loading = false;
    },
    //====getArchiveOrders====//
    [operation.getArchiveOrders.pending]: (state, action) => {
      state.loadingHistory = true;
      state.error.archiveErrorOneC = false;
    },
    [operation.getArchiveOrders.fulfilled]: (state, { payload }) => {
      state.loadingHistory = false;
      state.loading = false;
      state.archiveOrders = payload.data;
      state.error.archiveErrorOneC = false;
    },
    [operation.getArchiveOrders.rejected]: (state, { payload }) => {
      const { data } = payload;
      state.loadingHistory = false;
      state.loading = false;
      state.error.archiveErrorOneC = data.error_key === '1C_OUT';
    },
    //====getCoOrders====//
    [operation.getCoOrders.pending]: (state, action) => {
      state.loadingHistory = true;
    },
    [operation.getCoOrders.fulfilled]: (state, { payload }) => {
      state.loadingHistory = false;
      state.loading = false;
      state.myOrders =
        payload.meta.current_page === 1
          ? payload.data
          : [...state.myOrders, ...payload.data];
      state.myOrdersMeta = payload.meta;
    },
    [operation.getCoOrders.rejected]: (state, { payload }) => {
      state.loadingHistory = false;
      state.loading = false;
    },
    //====getHistoryOrderBySearch====//
    [operation.getMyOrdersBySearch.pending]: (state, action) => {
      state.loadingHistory = true;
    },
    [operation.getMyOrdersBySearch.fulfilled]: (state, { payload }) => {
      state.loadingHistory = false;
      state.myOrders = [...payload.data];
      state.myOrdersMeta = payload.meta;
    },
    [operation.getMyOrdersBySearch.rejected]: (state, { payload }) => {
      state.loadingHistory = false;
    },
    //====getCoOrdersBySearch====//
    [operation.getCoOrdersBySearch.pending]: (state, action) => {
      state.loadingHistory = true;
    },
    [operation.getCoOrdersBySearch.fulfilled]: (state, { payload }) => {
      state.loadingHistory = false;
      state.myOrders = [...payload.data];
      state.myOrdersMeta = payload.meta;
    },
    [operation.getCoOrdersBySearch.rejected]: (state, { payload }) => {
      state.loadingHistory = false;
    },
    //====getHistoryOrderById====//
    [operation.getHistoryOrderById.pending]: (state, action) => {
      state.loadingDetails = true;
    },
    [operation.getHistoryOrderById.fulfilled]: (state, { payload }) => {
      state.loadingDetails = false;
      state.orderHistoryById = payload.data;
      state.onlineForm = payload.form ? payload.form : null;
    },
    [operation.getHistoryOrderById.rejected]: (state, { payload }) => {
      state.loadingDetails = false;
    },

    //====cloneHistoryOrderById====//
    [operation.cloneHistoryOrderById.pending]: (state, action) => {},
    [operation.cloneHistoryOrderById.fulfilled]: (state, { payload }) => {},
    [operation.cloneHistoryOrderById.rejected]: (state, { payload }) => {},

    //====addSaveOrderTemplate====//
    [operation.addSaveOrderTemplate.pending]: (state, action) => {},
    [operation.addSaveOrderTemplate.fulfilled]: (state, { payload }) => {
      state.savedOrderTemplate = payload;
    },
    [operation.addSaveOrderTemplate.rejected]: (state, { payload }) => {},

    //====editSaveOrderTemplate====//
    [operation.editSaveOrderTemplate.pending]: (state, action) => {},
    [operation.editSaveOrderTemplate.fulfilled]: (state, { payload }) => {
      state.savedOrderTemplate = memoSelectedItem(
        payload,
        state.savedOrderTemplate,
      );
    },
    [operation.editSaveOrderTemplate.rejected]: (state, { payload }) => {},

    //====changePinnedTemplate====//
    [operation.changePinnedTemplate.pending]: (state, action) => {},
    [operation.changePinnedTemplate.fulfilled]: (state, { payload }) => {
      state.savedOrderTemplate = memoSelectedItem(
        payload,
        state.savedOrderTemplate,
      );
    },
    [operation.changePinnedTemplate.rejected]: (state, { payload }) => {},

    //====deleteTemplate====//
    [operation.deleteTemplate.pending]: (state, action) => {},
    [operation.deleteTemplate.fulfilled]: (state, { payload }) => {
      state.savedOrderTemplate = memoSelectedItem(
        payload,
        state.savedOrderTemplate,
      );
    },
    [operation.deleteTemplate.rejected]: (state, { payload }) => {},
  },
});

export const {
  clearOrder,
  clearOrderHistory,
  choseTemplate,
  clearOrderHistoryDetails,
} = orderSlice.actions;

export default orderSlice.reducer;
