import { useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

import styled from 'styled-components';

import { Sprite } from '@root/assets/svg';
import { settingsExchangeRatesConf } from '@root/conf';
import { arrayReorder } from '@root/helpers';
import { CheckboxBase, TextInput } from '@root/ui';

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useTheme,
} from '@mui/material';

export const SettingsExchangeRateTable = ({ data }) => {
  const [exchangeRates, setExchangeRates] = useState([
    { id: '1', text: '10.23', rate_name: 'UAH' },
    { id: '2', text: '54.50', rate_name: 'USD' },
    { id: '3', text: '39,9421', rate_name: 'EUR' },
    { id: '4', text: '8,5647', rate_name: 'PLN' },
    { id: '5', text: '42,79', rate_name: 'UAH' },
    { id: '6', text: '5.7689', rate_name: 'UAH' },
  ]);

  const { color } = useTheme();

  const onDragEnd = result => {
    if (!result.destination) {
      return;
    }
    const reorderedExchangeRates = arrayReorder(
      exchangeRates,
      result.source.index,
      result.destination.index,
    );
    setExchangeRates(reorderedExchangeRates);
  };

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow
            sx={{
              display: 'flex',
              padding: '12px 0',
              borderBottom: `1px solid ${color.line}`,
            }}
          >
            {settingsExchangeRatesConf.map(el => {
              return (
                <TableCell
                  key={el.label}
                  sx={{
                    p: 0,
                    ...el.style,
                    borderBottom: 'none',
                  }}
                >
                  <Typography
                    variant="bodyBoldSmall"
                    component="p"
                    color={color.gray_60}
                  >
                    {el.label}
                  </Typography>
                </TableCell>
              );
            })}
          </TableRow>
        </TableHead>

        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable">
            {(provided, snapshot) => (
              <TableBody ref={provided.innerRef} {...provided.droppableProps}>
                {exchangeRates.map((row, index) => (
                  <Draggable
                    key={row.id}
                    draggableId={'q-' + row.id}
                    index={index}
                  >
                    {provided => (
                      <TableRow
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          borderBottom: 'none',
                          userSelect: 'none',
                          padding: '16px 0',
                          th: {
                            borderBottom: 'none',
                          },
                        }}
                      >
                        {settingsExchangeRatesConf.map((el, i) => {
                          switch (el?.value) {
                            case 'name':
                              return (
                                <TableCell
                                  component="th"
                                  scope="row"
                                  key={'si' + row.id + i}
                                  sx={{
                                    p: 0,
                                    ...el.style,
                                  }}
                                >
                                  <Svg>
                                    <use
                                      href={`${Sprite}#icon-drag-and-drop`}
                                    />
                                  </Svg>
                                  <Typography
                                    variant="bodyLarge"
                                    textTransform="uppercase"
                                    color={color.gray_80}
                                  >
                                    {row.rate_name}
                                  </Typography>
                                </TableCell>
                              );
                            case 'ua':
                              return (
                                <TableCell
                                  component="th"
                                  scope="row"
                                  key={'si' + row.id + i}
                                  sx={{ p: 0, ...el.style }}
                                >
                                  <TextInput
                                    sx={{ height: '36px', border: 'none' }}
                                  />
                                </TableCell>
                              );
                            case 'en':
                              return (
                                <TableCell
                                  component="th"
                                  scope="row"
                                  key={'si' + row.id + i}
                                  sx={{ p: 0, ...el.style }}
                                >
                                  <TextInput
                                    sx={{ height: '36px', border: 'none' }}
                                  />
                                </TableCell>
                              );

                            case 'ru':
                              return (
                                <TableCell
                                  component="th"
                                  scope="row"
                                  key={'si' + row.id + i}
                                  sx={{ p: 0, ...el.style }}
                                >
                                  <TextInput
                                    sx={{ height: '36px', border: 'none' }}
                                  />
                                </TableCell>
                              );

                            case 'quantity':
                              return (
                                <TableCell
                                  component="th"
                                  scope="row"
                                  key={'si' + row.id + i}
                                  sx={{ p: 0, ...el.style }}
                                >
                                  <Typography
                                    variant="bodyLarge"
                                    textTransform="uppercase"
                                    color={color.gray_80}
                                  >
                                    {row.text}
                                  </Typography>
                                </TableCell>
                              );
                            case 'visibility':
                              return (
                                <TableCell
                                  component="th"
                                  scope="row"
                                  key={'si' + row.id + i}
                                  sx={{
                                    p: 0,
                                    ...el.style,
                                  }}
                                >
                                  <CheckboxBase width="16" />
                                </TableCell>
                              );
                          }
                        })}
                      </TableRow>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </TableBody>
            )}
          </Droppable>
        </DragDropContext>
      </Table>
    </TableContainer>
  );
};

const Svg = styled.svg`
  width: 16px;
  height: 16px;
`;
