import * as React from 'react';

import PropTypes from 'prop-types';

import Dialog from '@mui/material/Dialog';

export const DialogUi = ({ open, onClose, fullScreen, children, sx }) => {
  return (
    <Dialog open={open} onClose={onClose} fullScreen={fullScreen}>
      {children}
    </Dialog>
  );
};
DialogUi.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  fullScreen: PropTypes.bool,
  children: PropTypes.node,
  sx: PropTypes.object,
};
