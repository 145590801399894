import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const getServiceOrders = createAsyncThunk(
  'orderService/getOrders',
  async ({ date_from, date_to }, { rejectWithValue }) => {
    try {
      const response = await axios.get(`logistic/serviceorders`, {
        params: {
          date_from,
          date_to,
        },
      });

      return response.data.data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  },
);

const createNewServiceLogistic = createAsyncThunk(
  'orderService/createNewServiceLogistic',
  async (paylod, { rejectWithValue }) => {
    try {
      const { data } = await axios.post(`logistic/newservice`, { ...paylod });

      return data.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export default { getServiceOrders, createNewServiceLogistic };
