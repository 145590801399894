import React from 'react';
import { Bar } from 'react-chartjs-2';

import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  LinearScale,
  Tooltip,
} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import PropTypes from 'prop-types';

import { useTheme } from '@mui/material';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Tooltip,
  ChartDataLabels,
);

export const Chart = ({
  verticalMode = false,
  defaultSize = true,
  tooltip = false,
  customLabels = false,
  responsive = true,
  hover = null,
  data,
  maxValGraph,
}) => {
  const { color } = useTheme();

  return (
    data && (
      <Bar
        height={'100%'}
        options={{
          devicePixelRatio: 2,
          indexAxis: verticalMode ? 'x' : 'y',
          maintainAspectRatio: defaultSize,
          plugins: {
            tooltip: {
              enabled: tooltip,
            },
            datalabels: {
              display: customLabels,
              clip: false,
            },
          },
          hover: {
            mode: hover,
          },
          scales: {
            x: {
              grid: {
                color: color.line,
                drawOnChartArea: !verticalMode,
                drawTicks: !verticalMode,
              },
              ticks: {
                padding: 10,
                color: color.gray,
                precision: 0,
                font: {
                  size: 10,
                },
              },
              border: {
                display: verticalMode,
              },
              suggestedMax: maxValGraph,
            },
            y: {
              grid: {
                color: color.line,
                drawOnChartArea: verticalMode,
                drawTicks: true,
                offset: false,
              },
              ticks: {
                color: color.gray,
                padding: 13,
                precision: 0,
                font: {
                  size: 10,
                },
              },
              border: {
                display: !verticalMode,
              },
              suggestedMax: maxValGraph,
            },
          },
          responsive: responsive,
        }}
        data={data}
      />
    )
  );
};

Chart.propTypes = {
  verticalMode: PropTypes.bool,
  defaultSize: PropTypes.bool,
  tooltip: PropTypes.bool,
  customLabels: PropTypes.bool,
  responsive: PropTypes.bool,
  hover: PropTypes.string,
  data: PropTypes.exact({
    datasets: PropTypes.array,
    labels: PropTypes.array,
  }),
};
