import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const getPermission = createAsyncThunk(
  'permission/getPermission',
  async ( { companyId, rejectWithValue }) => {
    try {
      const response = await axios.get('clientroles/roles',{
        params: {...(companyId ? {company_id: companyId} : {})}
      });
      return { ...response.data };
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

const getPermissionById = createAsyncThunk(
  'permission/getPermissionById',
  async ({ id }, { rejectWithValue }) => {
    try {
      const response = await axios.get(`clientroles/roles/${id}`);

      return { ...response.data };
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

const createNewRole = createAsyncThunk(
  'permission/createNewRole',
  async ({ name, onResolve, onReject }, { rejectWithValue }) => {
    try {
      const response = await axios.post(`clientroles/roles`, { name });
      if (onResolve) onResolve(response.data.data);
      return { ...response.data };
    } catch (error) {
      if (onReject) onReject(error.response.data.message);
      return rejectWithValue(error);
    }
  },
);

const saveRoleById = createAsyncThunk(
  'permission/saveRoleById',
  async ({ id, data, onResolve, onReject }, { rejectWithValue }) => {
    try {
      const response = await axios.put(`clientroles/roles/${id}`, { data });
      if (onResolve) onResolve();
      return { ...response.data };
    } catch (error) {
      if (onReject) onReject(error.response.data.message);
      return rejectWithValue(error);
    }
  },
);

const cloneRoleById = createAsyncThunk(
  'permission/cloneRoleById',
  async ({ id, name, onResolve, onReject }, { rejectWithValue }) => {
    try {
      const response = await axios.post(`clientroles/roles/${id}/clone`, {
        name,
      });
      if (onResolve) onResolve(response.data.data);
      return { ...response.data };
    } catch (error) {
      if (onReject) onReject(error.response.data.message);
      return rejectWithValue(error);
    }
  },
);

const deleteRoleById = createAsyncThunk(
  'permission/deleteRoleById',
  async ({ id, onResolve, onReject }, { rejectWithValue }) => {
    try {
      const response = await axios.delete(`clientroles/roles/${id}`);
      if (onResolve) onResolve(response.data.data[0].id);
      return response.data;
    } catch (error) {
      if (onReject) onReject(error.response.data.data);
      return rejectWithValue(error);
    }
  },
);

const changeRoleByEmployees = createAsyncThunk(
  'permission/changeRoleByEmployees',
  async ({ users }, { rejectWithValue }) => {
    try {
      const response = await axios.post(`employees/role`, {
        users,
      });
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export default {
  getPermission,
  getPermissionById,
  createNewRole,
  saveRoleById,
  cloneRoleById,
  deleteRoleById,
  changeRoleByEmployees,
};
