import { createSlice } from '@reduxjs/toolkit';

import mutualSettlementsOperation from '@redux/mutual-settlements/mutual-settlements-operation';

const initialState = {
  mutualSettlements: [],
  mutualSettlementsTypes: [],
  exportBaseUrl: '',
  selectedMutualSettlement: null,
  isMutualSettlementsLoading: false,
  searchValue: '',
  errorOneC: false,
};

const mutualSettlementsSlice = createSlice({
  name: 'mutualSettlements',
  initialState,
  reducers: {
    setSelectMutualSettlement: (state, { payload }) => {
      state.selectedMutualSettlement = payload;
    },
    setSearchValue: (state, { payload }) => {
      state.searchValue = payload;
    },
    clear: (state, { payload }) => {
      state.mutualSettlements = initialState.mutualSettlements;
      state.mutualSettlementsTypes = initialState.mutualSettlementsTypes;
      state.selectedMutualSettlement = initialState.selectedMutualSettlement;
      state.searchValue = initialState.searchValue;
      state.errorOneC = false;
    },
  },
  extraReducers: {
    //getMutualSettlements//
    [mutualSettlementsOperation.getMutualSettlements.pending]: (
      state,
      { payload },
    ) => {
      state.isMutualSettlementsLoading = true;
    },
    [mutualSettlementsOperation.getMutualSettlements.fulfilled]: (
      state,
      { payload },
    ) => {
      state.isMutualSettlementsLoading = false;
      state.mutualSettlements = payload.data;
      state.exportBaseUrl = payload.export_base_url;
    },
    [mutualSettlementsOperation.getMutualSettlements.rejected]: (
      state,
      { payload },
    ) => {
      const { data } = payload;
      state.isMutualSettlementsLoading = false;
      state.errorOneC = data.error_key === '1C_OUT';
    },
    //getMutualSettlementsTypes//
    [mutualSettlementsOperation.getMutualSettlementsTypes.pending]: (
      state,
      { payload },
    ) => {},
    [mutualSettlementsOperation.getMutualSettlementsTypes.fulfilled]: (
      state,
      { payload },
    ) => {
      state.mutualSettlementsTypes = payload;
    },
    [mutualSettlementsOperation.getMutualSettlementsTypes.rejected]: (
      state,
      { payload },
    ) => {
      const { data } = payload;
      state.errorOneC = data.error_key === '1C_OUT';
    },
  },
});

export const { setSelectMutualSettlement, setSearchValue, clear } =
  mutualSettlementsSlice.actions;

export default mutualSettlementsSlice.reducer;
