import { createSelector } from '@reduxjs/toolkit';

const getSearchDeliveryHistory = state => state.logistic.searchLogisticHistory;
const getDeliveryHistory = state => state.logistic.logisticHistory;
const getSearchValue = state => state.logistic.searchValue;
const getIsDeliveryHistoryLoading = state =>
  state.logistic.isLogisticHistoryLoading;
const getSelectedDelivery = state => state.logistic.selectedDelivery;
const getDeliveryDetailsById = state => state.logistic.isLogisticDetailsLoading;
const getDeliveryHistoryMeta = state => state.logistic.deliveryHistoryMeta;
const getErrorOneC = state => state.logistic.errorOneC;

const getFilteredogistic = createSelector(
  [getDeliveryHistory, getSearchValue],
  (items, searchValue) => {
    const value = searchValue.toLowerCase();
    return (
      items &&
      items.filter(
        deliveryItem =>
          deliveryItem.number.includes(value.toLowerCase().trim()) ||
          deliveryItem.logistic_service
            .toLowerCase()
            .includes(value.toLowerCase().trim()),
      )
    );
  },
);

const logisticSelectors = {
  getFilteredogistic,
  getSearchValue,
  getDeliveryHistory,
  getSearchDeliveryHistory,
  getDeliveryDetailsById,
  getDeliveryHistoryMeta,
  getIsDeliveryHistoryLoading,
  getSelectedDelivery,
  getErrorOneC,
};

export default logisticSelectors;
