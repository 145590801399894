import { Tab } from '@mui/material';
import Tabs from '@mui/material/Tabs';
import { styled } from '@mui/material/styles';

export const InlineTabs = styled(props => (
  <Tabs
    {...props}
    TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
  />
))({
  '& .MuiTabs-indicator': {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
  },
  '& .MuiTabs-indicatorSpan': {
    maxWidth: 40,
    width: '100%',
    backgroundColor: 'transparent',
  },
});

export const InlineTab = styled(props => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    textTransform: 'none',
    minWidth: 0,
    [theme.breakpoints.up('sm')]: {
      minWidth: 0,
    },
    fontSize: '0.75em',
    marginRight: theme.spacing(1),
    color: '#526484',
    borderRadius: '4px',
    minHeight: '36px',
    padding: '10px 8px',

    // '&:hover': {
    // },
    '&.Mui-selected': {
      color: theme.color.white,
      backgroundColor: theme.color.main,
    },
    // '&.Mui-focusVisible': {
    // },
  }),
);
