import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { serialize } from 'object-to-formdata';
import styled from 'styled-components';

import BannerDeleteDialog from '@root/components/BannerSetings/BannerDeleteDialog/index';
import BannerImageEditor from '@root/components/BannerSetings/BannerImageEditor/index';
import BannerImgsSelect from '@root/components/BannerSetings/BannerImgsSelect/index';
import { getErrorStack } from '@root/helpers/validation';
import bannersOperation from '@root/redux/banners/banners-operation';
import bannersSelectors from '@root/redux/banners/banners-selectors';
import { setOpenNotification } from '@root/redux/notification-watcher/notification-watcher-slice';
import { ButtonBase } from '@root/ui/Button/ButtonBase/index';
import { TextInput } from '@root/ui/Input/TextInput/index';
import { ProgressLoader } from '@root/ui/Loader/ProgressLoader/index';

const BannerSettings = () => {
  const { t } = useTranslation(['settings']);
  const dispatch = useDispatch();
  const [urlValue, setUrlValue] = useState('');
  const [selectedItemId, setSelectedItemId] = useState(null);
  const banners = useSelector(bannersSelectors.getBanners);
  const bannersLoading = useSelector(bannersSelectors.getLoading);
  const [deleteId, setDeleteId] = useState(null);
  const [deleteShow, setDeleteShow] = useState(false);
  const [editedBannerData, setEditedBannerData] = useState(null);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    dispatch(
      bannersOperation.getBanners({
        onResolve: () => {},
      }),
    );
    return () => {
      setSelectedItemId(null);
    };
  }, []);

  const isDirty = useMemo(() => {
    return editedBannerData?.dirty || false;
  }, [editedBannerData]);

  const currentBanner = useMemo(() => {
    if (banners?.[selectedItemId]) {
      setEditedBannerData({ ...banners?.[selectedItemId], dirty: false });
      return banners?.[selectedItemId];
    } else {
      setEditedBannerData(null);
      return null;
    }
  }, [selectedItemId, banners]);
  const currentId = useMemo(() => {
    return banners?.[selectedItemId]?.id;
  }, [selectedItemId, banners]);

  useEffect(() => {
    if (currentId) setUrlValue(currentBanner?.link || '');
    else setUrlValue('');
  }, [currentBanner]);

  const handleUpload = () => {
    setSelectedItemId(null);
  };

  const handleSelect = index => {
    setSelectedItemId(index);
  };

  const onDeleteSubmit = () => {
    setDeleteShow(false);
    const id = banners[deleteId].id;
    dispatch(
      bannersOperation.deleteBanner({
        id,
        onResolve: () => {
          setDeleteId(null);
        },
      }),
    );
  };

  const onUpdateBanner = () => {
    setErrors({});
    const updatedBanner = { ...JSON.parse(JSON.stringify(editedBannerData)) };
    delete updatedBanner.image;
    delete updatedBanner.dirty;
    const tempSelectedItemId = selectedItemId;
    dispatch(
      bannersOperation.updateBanner({
        formData: serialize(updatedBanner),
        id: currentId,
        onResolve: () => {
          handleOpenNotification();
          setSelectedItemId(tempSelectedItemId);
        },
        onReject: errors => {
          let currentError = {};
          for (const [key, value] of Object.entries(errors)) {
            currentError = getErrorStack(
              currentError,
              key.replace(/\.\d+/, ''),
              value?.[0],
            );
          }
          setErrors(currentError);
        },
      }),
    );
  };

  const handleOpenNotification = () => {
    dispatch(
      setOpenNotification({
        open: true,
        message: t('banner.update_success'),
      }),
    );
  };

  const onImageEditorUpdate = (newX, newY, newAnchor, newHtml) => {
    setEditedBannerData(prev => {
      return {
        ...prev,
        dirty: true,
        x: newX || prev.x,
        y: newY || prev.y,
        anchor: newAnchor || prev.anchor,
        html: newHtml || prev.html,
      };
    });
  };

  const moveElement = (array, fromIndex, toIndex) => {
    if (
      fromIndex < 0 ||
      fromIndex >= array.length ||
      toIndex < 0 ||
      toIndex >= array.length
    ) {
      console.error('Invalid indices');
      return array;
    }
    const newArray = [...array];
    const [movedElement] = newArray.splice(fromIndex, 1);
    newArray.splice(toIndex, 0, movedElement);
    return newArray;
  };

  const onDragDrop = (e, toIndex, fromIndex) => {
    e.preventDefault();
    if (toIndex !== Number(fromIndex)) {
      const sortedBanners = moveElement([...banners], fromIndex, toIndex);

      const sorted = {};
      sortedBanners.forEach((item, index) => {
        sorted[item.id] = index;
      });

      dispatch(bannersOperation.updateBannerSort({ sorted }));
    }
  };

  return (
    <MainWrap>
      <ImagesPick>
        <BannerImgsSelect
          banners={banners}
          selectedItemId={selectedItemId}
          onSelect={handleSelect}
          onfileUpload={handleUpload}
          onDelete={id => {
            setDeleteId(id);
            setDeleteShow(true);
          }}
          onDragDrop={onDragDrop}
        />
      </ImagesPick>
      <ImgEditorWrp>
        <TopBtnsWrap>
          <div></div>
          <ButtonBase disabled={!isDirty} onClick={onUpdateBanner}>
            {t('banner.submit')}
          </ButtonBase>
        </TopBtnsWrap>
        <BannerImageEditor
          currentBanner={currentBanner}
          currentId={currentId}
          updateBanner={onImageEditorUpdate}
        />
        <TextInput
          value={urlValue}
          onChange={val => {
            setUrlValue(val);
            setEditedBannerData(prev => {
              return { ...prev, link: val, dirty: true };
            });
          }}
          placeholder={t('banner.addUrl')}
          disabled={!currentId}
          error={errors?.link?.init}
          errorText={errors?.link?.text}
        />
      </ImgEditorWrp>
      {bannersLoading && <ProgressLoader />}
      <BannerDeleteDialog
        open={deleteShow}
        id={deleteId}
        onClose={() => {
          setDeleteShow(false);
        }}
        onSubmit={onDeleteSubmit}
      />
    </MainWrap>
  );
};

export default BannerSettings;

const MainWrap = styled.div`
  height: calc(100vh - 68px);
  display: flex;
  position: relative;
`;

const ImagesPick = styled.div``;

const ImgEditorWrp = styled.div`
  flex-grow: 1;
  padding: 17px 32px 46px;
`;
const TopBtnsWrap = styled.div`
  display: flex;
  justify-content: space-between;
`;
