import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Empty } from '@root/components';
import { AnimateText, AvatarUI } from '@root/ui';

import { Typography, useTheme } from '@mui/material';

export const UserShortInfo = ({ avatar, title, subtitle, isSmall = true }) => {
  const { color } = useTheme();

  return (
    <UserShortInfoWrapper>
      <AvatarUI
        src={avatar}
        width={isSmall ? 29 : 40}
        height={isSmall ? 29 : 40}
      >
        <Empty
          type={'EmptyLogo'}
          isCompany={false}
          name={title}
          noAvatarSx={{
            fontSize: isSmall ? '1rem' : '1.375rem',
          }}
        />
      </AvatarUI>

      <InfoWrapper>
        <Typography
          color={color.gray}
          variant={isSmall ? 'bodyBoldSmall' : 'bodyBoldMedium'}
          component="p"
        >
          {title && (title.length > 20 ? <AnimateText text={title} /> : title)}
        </Typography>
        <Typography
          color={color.gray_80}
          variant={isSmall ? 'bodyExSmall' : 'bodySmall'}
          component="p"
        >
          {subtitle &&
            (subtitle.length > 20 ? <AnimateText text={subtitle} /> : subtitle)}
        </Typography>
      </InfoWrapper>
    </UserShortInfoWrapper>
  );
};

const UserShortInfoWrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 16px;
`;

const InfoWrapper = styled.div``;

UserShortInfo.propsTypes = {
  avatar: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  isSmall: PropTypes.bool,
};
