import React from 'react';
import { useTranslation } from 'react-i18next';

import { Sprite } from '@root/assets/svg';
import { useNavigation } from '@root/hooks';
import { ButtonWithoutBorder } from '@root/ui';

import { Typography, useTheme } from '@mui/material';

export const Buscet = () => {
  const { color } = useTheme();
  const { navigation } = useNavigation();
  const { t } = useTranslation(['cart']);

  const click = () => navigation('/catalog/cart');
  return (
    <ButtonWithoutBorder
      onClick={click}
      sx={{
        color: color.gray_60,
        borderRadius: '4px',
        display: 'flex',
        width: '158px',
        height: '44px',
        justifyItems: 'center',
        alignItems: 'center',
        border: `1px solid ${color.gray_60}`,
        '&:hover': {
          color: color.main,
          background: 'none',
          border: `1px solid ${color.main}`,
        },
      }}
    >
      <svg width={20} height={20} fill={'none'}>
        <use href={`${Sprite}#icon-buscet`}></use>
      </svg>

      <Typography
        sx={{
          marginLeft: '10px',
          fontSize: '14px',
          fontWeight: '700',
        }}
        variant="bodyBoldSmall"
        color={color.gray}
      >
        {t('title')}
      </Typography>
    </ButtonWithoutBorder>
  );
};
