import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { serialize } from 'object-to-formdata';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Sprite } from '@root/assets/svg';
import { handleDispatchFetch, validEmail } from '@root/helpers';
import { useNavigation } from '@root/hooks';
import {
  BasicSelect,
  ButtonBase,
  OutlineButtonBase,
  TextInput,
} from '@root/ui';

import authOperation from '@redux/auth/auth-operation';

import { Typography, useTheme } from '@mui/material';

export const InviteEmployeesForm = ({ initData, pathParrent }) => {
  const [data, setData] = useState({
    employees: [{ email: null, role: null }],
  });
  const [error, setError] = useState({});
  const { t } = useTranslation(['registration', 'validation', 'common'], {
    useSuspense: false,
  });
  const { color } = useTheme();
  const dispatch = useDispatch();
  const { navigation } = useNavigation();

  useEffect(() => {
    const { employees } = initData.company;
    setData({
      employees:
        employees.length === 0 ? [{ email: null, role: null }] : employees,
    });
  }, [initData]);

  const onChange = (name, i, subName) => value => {
    const dataCopy = JSON.parse(JSON.stringify(data));
    dataCopy[name][i][subName] = value ? value : null;
    setData(dataCopy);
  };

  const handelSubmit = e => {
    e.preventDefault();
    const { employees } = data;

    if (validation()) {
      return;
    }

    if (employees[0].email === '') {
      navigation(`${pathParrent}/5`);
    }

    handleDispatchFetch(
      ({ onResolve, onReject }) =>
        dispatch(
          authOperation.signUpStepFifth({
            formData: serialize(
              {
                ...data,
              },

              {
                indices: true,
                nullsAsUndefineds: true,
                booleansAsIntegers: true,
              },
            ),
            onResolve,
            onReject,
          }),
        ),
      () => navigation(`${pathParrent}/5`),
    );
  };

  const validation = () => {
    let flag = false;
    const { employees } = data;
    let arrError = [];

    if (
      employees.length > 1 ||
      (employees.length === 1 && employees[0].email !== null)
    ) {
      arrError = employees.reduce((acc, { email, role }, i) => {
        if (email == null || validEmail(email)) {
          acc = [
            ...acc,
            {
              email: {
                init: true,
                text: t('validation:incorrect_email'),
              },
            },
          ];
          flag = true;
        } else {
          acc = [
            ...acc,
            {
              email: {
                init: false,
                text: '',
              },
            },
          ];
        }
        return acc;
      }, []);
    }
    setError(arrError);
    return flag;
  };

  const handelAddEmployees = () => {
    const dataCopy = JSON.parse(JSON.stringify(data));
    dataCopy.employees = [...dataCopy.employees, { email: null, role: null }];
    setData(dataCopy);
  };

  const handleDeleteEmployees = ind => {
    setData(prev => {
      return {
        ...prev,
        employees: prev.employees.filter((_, index) => ind !== index),
      };
    });
  };

  const { employees } = data;
  return (
    <Wrapper>
      <Form onSubmit={handelSubmit}>
        <Typography variant={'h1'} align={'center'} color={color.gray}>
          {t('registration:step_five.tittle')}
        </Typography>
        <Typography
          sx={{ mt: '28px' }}
          variant={'bodyLarge'}
          align={'center'}
          component={'p'}
          color={color.gray_80}
        >
          {t('registration:step_five.slogan')}
        </Typography>
        {employees &&
          employees.map(({ email, role }, i) => (
            <WrapperRole color={color.line_light}>
              <Typography
                variant={'bodyBoldMedium'}
                component={'p'}
                color={color.gray_80}
              >
                {t('registration:step_five.employee')} {i + 1}
              </Typography>
              {i !== 0 && (
                <CloseSvg
                  width="16"
                  height="16"
                  color={color}
                  onClick={() => {
                    handleDeleteEmployees(i);
                  }}
                >
                  <use href={`${Sprite}#icon-cross`} />
                </CloseSvg>
              )}
              <TextInput
                error={error[i]?.email?.init}
                errorText={error[i]?.email?.text}
                sx={{ mt: '20px', width: '100%', height: '48px' }}
                value={email}
                onChange={onChange('employees', i, 'email')}
                placeholder={'Email'}
                type="text"
                withOutSpace={true}
                autoComplete={'email'}
                inputProps={{
                  maxLength: 255,
                }}
              />
              <BasicSelect
                currentId={role}
                data={[
                  { id: 1, name: t('registration:option.employee') },
                  { id: 2, name: t('registration:option.manager') },
                ]}
                name={t('registration:field.role')}
                callBack={onChange('employees', i, 'role')}
                sx={{ mt: '20px' }}
                helperText={error[i]?.role?.text}
                error={error[i]?.role?.init}
                height="48px"
              />
            </WrapperRole>
          ))}
        {employees.length < 10 && (
          <OutlineButtonBase
            type="button"
            sx={{ mt: '28px', width: '100%' }}
            onClick={handelAddEmployees}
          >
            <Svg width="16" height="16">
              <use href={`${Sprite}#icon-plus`}></use>
            </Svg>
            <Typography variant={'bodySmall'} align={'center'}>
              {t('registration:step_five.add_employee_btn')}
            </Typography>
          </OutlineButtonBase>
        )}

        <WrapperRow>
          <Wrapper>
            <OutlineButtonBase
              type="button"
              sx={{ flex: 1, maxWidth: '208px' }}
              onClick={() => {
                navigation(`${pathParrent}/3`);
              }}
            >
              <Typography variant={'bodyBoldLarge'} align={'center'}>
                {t('common:buttons.back')}
              </Typography>
            </OutlineButtonBase>

            <ButtonBase
              type="submit"
              size={'large'}
              sx={{ flex: 1, ml: '12px', maxWidth: '208px' }}
            >
              <Typography variant={'bodyBoldLarge'}>
                {t('common:buttons.continue')}
              </Typography>
            </ButtonBase>
          </Wrapper>
        </WrapperRow>
      </Form>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 60px;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 430px;
`;

const WrapperRow = styled.div`
  margin-top: 28px;
`;

const WrapperRole = styled.div`
  padding: 20px;
  background: ${props => props.color};
  border-radius: 4px;
  margin-top: 28px;
  position: relative;
`;

const Svg = styled.svg`
  margin-right: 4px;
`;
const CloseSvg = styled.svg`
  stroke: ${({ color }) => color?.gray};
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
`;

InviteEmployeesForm.propTypes = {
  initData: PropTypes.exact({
    avatar: PropTypes.string,
    birthday: PropTypes.string,
    city: PropTypes.string,
    city_id: PropTypes.number,
    company: PropTypes.exact({
      activity_areas: PropTypes.arrayOf(
        PropTypes.exact({ id: PropTypes.number, name: PropTypes.string }),
      ),
      additional_info: PropTypes.string,
      company_type: PropTypes.string,
      company_type_id: PropTypes.number,
      contractor_code: PropTypes.number,
      employees: PropTypes.array,
      employees_quantity: PropTypes.number,
      identification_code: PropTypes.number,
      logo: PropTypes.string,
      name: PropTypes.string,
      official_name: PropTypes.string,
      site: PropTypes.string,
    }),
    currency_rates: PropTypes.arrayOf(
      PropTypes.exact({
        public_name: PropTypes.string,
        rate: PropTypes.string,
      }),
    ),
    email: PropTypes.string,
    ga_is_active: PropTypes.bool,
    id: PropTypes.number,
    name: PropTypes.string,
    np_api_key: PropTypes.string,
    password_updated_at: PropTypes.string,
    phones: PropTypes.arrayOf(
      PropTypes.exact({ confirm: PropTypes.bool, phone: PropTypes.string }),
    ),
    prices: PropTypes.exact({
      price: PropTypes.exact({
        field_name: PropTypes.string,
        selected: PropTypes.bool,
        title: PropTypes.string,
      }),
      price_uah_cash: PropTypes.exact({
        field_name: PropTypes.string,
        selected: PropTypes.bool,
        title: PropTypes.string,
      }),
      price_uah_no_pdv: PropTypes.exact({
        field_name: PropTypes.string,
        selected: PropTypes.bool,
        title: PropTypes.string,
      }),
      price_uah_pdv: PropTypes.exact({
        field_name: PropTypes.string,
        selected: PropTypes.bool,
        title: PropTypes.string,
      }),
    }),
    role: PropTypes.arrayOf(PropTypes.string),
    show_logins: PropTypes.bool,
    status: PropTypes.string,
  }),
  pathParrent: PropTypes.string.isRequired,
};
