import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import {
  AddProductToListOfCategoryModal,
  CounterInput,
} from '@root/components';

import productOperation from '@redux/product/product-operation';

export const RelatedProductsModal = ({ data, handleCloseModal, id }) => {
  const dispatch = useDispatch();
  const [chosenData, setChosenData] = useState(data);

  const onSubmit = () => {
    const related_products = [...chosenData].map(el => {
      return { related_product_id: el.id, quantity: el.relation_quantity };
    });

    dispatch(
      productOperation.addRelatedProductsById({ id, related_products }),
    ).then(() => handleCloseModal());
  };

  const handleChoseProduct = newChosenData => setChosenData(newChosenData);

  const handleChange = (type, item, e) => {
    setChosenData(
      [...chosenData].map(el => {
        if (el === item) {
          switch (type) {
            case 'decrement':
              if (el.relation_quantity <= 1)
                return { ...el, relation_quantity: 1 };
              return { ...el, relation_quantity: el.relation_quantity - 1 };
            case 'increment':
              return { ...el, relation_quantity: el.relation_quantity + 1 };
            case 'manual':
              if (e.target.value <= 0) return { ...el, relation_quantity: 1 };
              return { ...el, relation_quantity: e.target.value };
            default:
              break;
          }
        }
        return el;
      }),
    );
  };

  const checkIsFirstLetterZero = event => {
    const textLength = event.target.value.length;
    return textLength === 0 && event.keyCode === 48;
  };

  const handleEnter = event => {
    if (checkIsFirstLetterZero(event)) {
      event.preventDefault();
    }

    if (event.code === 'Enter' || event.code === 'NumpadEnter') {
      event.preventDefault();
    }
  };

  const customAction = item => {
    const quantity = item?.relation_quantity;
    return (
      <Row>
        x
        <CounterInput
          productQuantity={quantity}
          inputWidth={50}
          handleDecrement={() => handleChange('decrement', item)}
          handleAddProduct={() => handleChange('increment', item)}
          handleSetNumberAddToCart={e => handleChange('manual', item, e)}
          handleEnter={handleEnter}
        />
      </Row>
    );
  };

  return (
    <>
      <AddProductToListOfCategoryModal
        data={chosenData}
        handleCloseModal={handleCloseModal}
        onSubmitCallBack={onSubmit}
        customElAction={customAction}
        modalWidth={1000}
        callBackChoseProduct={handleChoseProduct}
      />
    </>
  );
};

const Row = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 5px;

  align-items: baseline;
`;

RelatedProductsModal.propTypes = {
  data: PropTypes.array.isRequired,
  handleCloseModal: PropTypes.func.isRequired,
  id: PropTypes.number.isRequired,
};
