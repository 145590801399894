import { createSlice } from '@reduxjs/toolkit';

import operations from './knowledge-operation'

const initialState = {
    loading: false,
    authorLoading: false,
    authorList: [],
    list: [],
    links: null,
    meta: null,
    articleLoading: false,
    articleData: null,
};

const knowledgeSlice = createSlice({
    name: 'knowledge',
    initialState,
    reducers: {
        clearArticleData: state => {
            state.articleData = initialState.articleData;
        },
    },
    extraReducers: {
        //====getKnowledgeData====//
        [operations.getKnowledgeData.pending]: (state, action) => {
            state.loading = true
        },
        [operations.getKnowledgeData.fulfilled]: (state, { payload: { data, links, meta } }) => {
            state.list = data
            state.links = links
            state.meta = meta
            state.loading = false
        },
        [operations.getKnowledgeData.rejected]: (state, action) => {
            state.loading = false
        },
        //====getKnowledgeArticle====//
        [operations.getKnowledgeArticle.pending]: (state, action) => {
            state.articleLoading = true
        },
        [operations.getKnowledgeArticle.fulfilled]: (state, { payload }) => {
            state.articleData = payload
            state.articleLoading = false
        },
        [operations.getKnowledgeArticle.rejected]: (state, action) => {
            state.articleLoading = false
        },
        //====editKnowledgeArticle====//
        [operations.editKnowledgeArticle.pending]: (state, action) => {
            state.articleLoading = true
        },
        [operations.editKnowledgeArticle.fulfilled]: (state, { payload }) => {
            state.articleLoading = false
        },
        [operations.editKnowledgeArticle.rejected]: (state, action) => {
            state.articleLoading = false
        },
        //====createKnowledgePost====//
        [operations.createKnowledgePost.pending]: (state, action) => {
            state.articleLoading = true
        },
        [operations.createKnowledgePost.fulfilled]: (state, { payload }) => {
            state.articleLoading = false
        },
        [operations.createKnowledgePost.rejected]: (state, action) => {
            state.articleLoading = false
        },
        //====getKnowledgeAuthors====//
        [operations.getKnowledgeAuthors.pending]: (state, action) => {
            state.authorLoading = true
        },
        [operations.getKnowledgeAuthors.fulfilled]: (state, { payload }) => {
            state.authorList = payload
            state.authorLoading = false
        },
        [operations.getKnowledgeAuthors.rejected]: (state, action) => {
            state.authorLoading = false
        },
        //====addKnowledgeAuthor====//
        [operations.addKnowledgeAuthor.pending]: (state, action) => {
            state.authorLoading = true
        },
        [operations.addKnowledgeAuthor.fulfilled]: (state, { payload }) => {
            state.authorLoading = false
        },
        [operations.addKnowledgeAuthor.rejected]: (state, action) => {
            state.authorLoading = false
        },
    },
});

export const { clearArticleData } = knowledgeSlice.actions
export default knowledgeSlice.reducer;
