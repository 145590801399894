import { useLoadScript } from '@react-google-maps/api';
import { useEffect, useRef, useState } from 'react';

const libraries = ['places'];
const googleMapsApiKey = process.env.REACT_APP_GOOGLE_KEY;

export const GoogleStreetInput = ({ children, city, value, onFound }) => {
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey,
    libraries,
    language: 'uk',
  });

  const autocompleteService = useRef(null);
  const placesService = useRef(null);

  useEffect(() => {
    if (isLoaded) {
      if (!autocompleteService.current) {
        autocompleteService.current =
          new window.google.maps.places.AutocompleteService();
      }
      if (!placesService.current) {
        placesService.current = new window.google.maps.places.PlacesService(
          document.createElement('div'),
        );
      }
    }
  }, [isLoaded]);

  useEffect(() => {
    if (value && autocompleteService.current && city && placesService.current) {
      autocompleteService.current.getPlacePredictions(
        {
          input: city,
          types: ['(cities)'],
          componentRestrictions: { country: 'ua' },
        },
        (predictions, status) => {
          if (
            status === window.google.maps.places.PlacesServiceStatus.OK &&
            predictions[0]
          ) {
            const prediction = predictions[0];
            placesService.current.getDetails(
              { placeId: prediction.place_id },
              (place, status) => {
                if (
                  status === window.google.maps.places.PlacesServiceStatus.OK
                ) {
                  const { lat, lng } = place.geometry.location;
                  getPlacePredictions(value, { lat: lat(), lng: lng() });
                }
              },
            );
          }
        },
      );
    }
  }, [value]);

  const getPlacePredictions = (value, location) => {
    autocompleteService.current.getPlacePredictions(
      {
        input: value,
        types: ['address'],
        location: new window.google.maps.LatLng(location),
        radius: 50000,
        componentRestrictions: { country: 'ua' },
      },
      (predictions, status) => {
        if (status === window.google.maps.places.PlacesServiceStatus.OK) {
          onFound(
            predictions.map((pred, index) => {
              return { id: index, name: pred.description };
            }),
          );
        } else {
          onFound([]);
        }
      },
    );
  };

  if (loadError) return <div>Google component load error</div>;
  if (!isLoaded) return <div>Loading...</div>;

  return <>{children}</>;
};
