export const imageCropperActions = t => [
  {
    iconName: 'icon-crop',
    actionName: 'crop',
    typeName: 'escape-crop',
    tooltipText: t('common:tooltip.crop'),
  },
  {
    iconName: 'icon-horizontal-mirroring',
    actionName: 'horizontal-mirroring',
    typeName: 'rotate-flip',
    tooltipText: t('common:tooltip.flip_horizontal'),
  },
  {
    iconName: 'icon-vertical-mirroring',
    actionName: 'vertical-mirroring',
    typeName: 'rotate-flip',
    tooltipText: t('common:tooltip.flip_vertical'),
  },
  {
    iconName: 'icon-rotate-right',
    actionName: 'rotate-right',
    typeName: 'rotate-flip',
    tooltipText: t('common:tooltip.rotate_right'),
  },
  {
    iconName: 'icon-rotate-left',
    actionName: 'rotate-left',
    typeName: 'rotate-flip',
    tooltipText: t('common:tooltip.rotate_left'),
  },
  {
    iconName: 'icon-remove-bg',
    actionName: 'remove-bg',
    typeName: 'remove-bg',
    tooltipText: t('common:tooltip.remove_background'),
  },
];

export const cropActions = {
  crop: 'crop',
  horizontal_mirroring: 'horizontal-mirroring',
  vertical_mirroring: 'vertical-mirroring',
  rotate_right: 'rotate-right',
  rotate_left: 'rotate-left',
  remove_bg: 'remove-bg',
};
