import { useState } from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Sprite } from '@root/assets/svg';
import { IconBtn } from '@root/ui';

import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';

export const ProductSlider = ({ images = [], handelModal }) => {
  const [count, setCount] = useState(0);

  const { color } = useTheme();

  return (
    <>
      <ImgWrapper>
        <Img
          color={color.line}
          src={images[count]?.thumbnail_second}
          alt="Product Photo"
        />
        <BtnWrapper>
          <IconBtn
            size="16px"
            href={`${Sprite}#icon-zoom`}
            onClick={handelModal}
          />
        </BtnWrapper>
      </ImgWrapper>
      <ControllerWrapper>
        <IconBtn
          size="16px"
          href={`${Sprite}#icon-arrow-left`}
          onClick={() => count !== 0 && setCount(count - 1)}
        />
        <Typography
          variant={'bodySmall'}
          sx={{ color: color.gray_60, mr: '37px', ml: '37px' }}
          component={'p'}
        >
          {count + 1}
          <Typography
            variant={'bodyBoldSmall'}
            sx={{ color: color.gray }}
          ></Typography>
          /{images.length}
        </Typography>

        <IconBtn
          size="16px"
          href={`${Sprite}#icon-arrow-right`}
          onClick={() => count < images.length - 1 && setCount(count + 1)}
        />
      </ControllerWrapper>
    </>
  );
};

const ImgWrapper = styled.div`
  position: relative;
`;

const BtnWrapper = styled.div`
  position: absolute;
  bottom: 10px;
  right: 10px;
`;

const Img = styled.img`
  display: block;
  width: 160px;
  border-radius: 4px;
  border: 1px solid ${props => props.color};
  padding: 5px;
`;

const ControllerWrapper = styled.div`
  display: flex;
  padding: 0 14px;
  margin-top: 12px;
`;

ProductSlider.propTypes = {
  images: PropTypes.array,
  handelModal: PropTypes.func.isRequired,
};
