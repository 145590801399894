const getIsDockTypesLoading = state => state.agreementsDock.isDockTypesLoading;

const getDockTypes = state => state.agreementsDock.dockTypes;

const getDockTemplate = state => state.agreementsDock.dockTemplate;

const getDockFile = state => state.agreementsDock.dockFile;

const getDockList = state => state.agreementsDock.dockList;

export default {
  getIsDockTypesLoading,
  getDockTypes,
  getDockTemplate,
  getDockFile,
  getDockList,
};
