import * as React from 'react';

import PropTypes from 'prop-types';

import TableCell from '@mui/material/TableCell';

export const CompanyLogo = ({ style, img }) => {
  return (
    <TableCell component="th" scope="row" sx={{ p: 0, ...style }}>
      <img
        src={img}
        alt=""
        width={100}
        height={28}
        style={{ objectFit: 'contain' }}
      />
    </TableCell>
  );
};

CompanyLogo.propTypes = {
  style: PropTypes.object,
  img: PropTypes.string.isRequired,
};
