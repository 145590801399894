import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import styled from 'styled-components';

import { PricesTooltip } from '@root/components';
import { theme } from '@root/styles';

import userSelectors from '@redux/user/user-selectors';

import { Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

export const CostDetails = ({ item, sx }) => {
  const choosedCurrency = useSelector(
    userSelectors.getChoosedCurrencyWidget,
  ).field_name;
  const currency = useSelector(userSelectors.getChoosedCurrency);
  const userBonus = useSelector(userSelectors.getUserProfile).bonuses;
  const { t } = useTranslation([]);
  const { color } = useTheme();

  const displayMainCurrency = () => {
    switch (true) {
      case item?.is_marketing === true:
        return t('common:currencyPoints');
      default:
        return currency;
    }
  };

  return (
    <CostDetailsWrapper style={sx}>
      {item?.personal_price[choosedCurrency] > 0 ||
      item?.price[choosedCurrency] > 0 ||
      item?.is_marketing ? (
        <PricesTooltip
          prices={item?.prices}
          isHiddenTooltip={userBonus.status === 'blocked'}
        >
          {userBonus.status === 'blocked' && (
            <Typography
              variant={'bodySmall'}
              color={color.gray_60}
              component="div"
            >
              {t('product_details:recommended_selling_price')}
              {': '}
              <Typography variant={'bodyBoldSmall'} color={color.gray_80}>
                {item?.is_marketing
                  ? item?.price_marketing
                  : +item?.personal_price[choosedCurrency] ||
                    +item?.price[choosedCurrency]}{' '}
                {displayMainCurrency()}
              </Typography>
            </Typography>
          )}
          {userBonus.status !== 'blocked' && (
            <Box>
              {item.is_hits && +item?.prices[choosedCurrency].personal !== 0 ? (
                <>
                  <Typography
                    component={'span'}
                    variant={'h1'}
                    sx={{
                      color: theme.color.gray_60,
                      cursor: 'pointer',
                      fontSize: '16px',
                      mr: '5px',
                      textDecoration: 'line-through',
                    }}
                  >
                    {item?.prices[choosedCurrency].product}
                  </Typography>
                  <Typography
                    component={'span'}
                    variant={'h1'}
                    sx={{
                      color: theme.color.red,
                      cursor: 'pointer',
                    }}
                  >
                    {item?.prices[choosedCurrency].personal}
                  </Typography>
                  <Typography
                    component={'span'}
                    variant={'h3'}
                    sx={{
                      color: theme.color.red,
                      cursor: 'pointer',

                      ml: '4px',
                    }}
                  >
                    {currency}
                  </Typography>
                </>
              ) : (
                <>
                  <Typography
                    component={'span'}
                    variant={'h1'}
                    sx={{
                      color: theme.color.gray,
                      cursor: 'pointer',
                    }}
                  >
                    {item?.is_marketing
                      ? item?.price_marketing
                      : +item?.personal_price[choosedCurrency] ||
                        +item?.price[choosedCurrency]}
                  </Typography>
                  <Typography
                    component={'span'}
                    variant={'h3'}
                    sx={{
                      color: theme.color.gray,
                      cursor: 'pointer',

                      ml: '4px',
                    }}
                  >
                    {displayMainCurrency()}
                  </Typography>
                </>
              )}
            </Box>
          )}

          {choosedCurrency === 'price' &&
            +item?.personal_price['price_no_cash'] > 0 &&
            !item?.is_marketing && (
              <Typography
                variant="bodyBoldSmall"
                sx={{
                  color: color.gray,
                  mb: '3px',
                }}
              >
                {item?.personal_price['price_no_cash']} {currency}{' '}
                {t('common:currencyBg')}
              </Typography>
            )}
        </PricesTooltip>
      ) : (
        <Typography
          variant={'h1'}
          component={'span'}
          sx={{
            color: theme.color.gray,
          }}
        >
          {t('common:check_price_for_details')}
        </Typography>
      )}
    </CostDetailsWrapper>
  );
};

const Box = styled.div`
  /* display: flex; */
  align-items: center;
`;

const CostDetailsWrapper = styled.div``;
