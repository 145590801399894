import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import moment from 'moment';
import styled from 'styled-components';

import { Sprite } from '@root/assets/svg/index';
import { handleDispatchFetch } from '@root/helpers/handleDispatchFetch';
import textPagesOperations from '@root/redux/text-pages/text-pages-operations';
import textPagesSelectors from '@root/redux/text-pages/text-pages-selectors';
import { ButtonWithoutBorder } from '@root/ui/Button/ButtonWithoutBorder/index';

import { Typography, useTheme } from '@mui/material';

import { Popover } from '../../../node_modules/@mui/material/index';

const TextPagesHeaderMenu = () => {
  const { t } = useTranslation(['settings']);
  const { color } = useTheme();
  const dispatch = useDispatch();
  const getHeaderMenuTextPages = useSelector(
    textPagesSelectors.getHeaderMenuPages,
  );

  useEffect(() => {
    handleDispatchFetch(
      ({ onResolve, onReject }) =>
        dispatch(
          textPagesOperations.getHeaderMenuTextPages({ onResolve, onReject }),
        ),
      () => {},
      () => {},
    );
  }, []);

  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const getSortedTextPages = useMemo(() => {
    const servicePages = getHeaderMenuTextPages.filter(
      item => item.service_page === 1,
    );
    const otherPages = getHeaderMenuTextPages.filter(
      item => item.service_page === 0,
    );
    return [
      ...otherPages,
      ...(servicePages.length
        ? [{ title: t('text_pages.service'), type: 'delim' }, ...servicePages]
        : []),
    ];
  }, [getHeaderMenuTextPages]);
  return (
    <>
      <ButtonWithoutBorder
        onClick={handleClick}
        sx={{
          color: color.gray_60,
          background: 'none',
          '&:hover': {
            color: color.main,
            background: 'none',
          },
          minWidth: 'unset',
          marginRight: '18px',
        }}
      >
        <svg width={24} height={24} fill={'none'}>
          <use href={`${Sprite}#icon-support`}></use>
        </svg>
      </ButtonWithoutBorder>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        style={{ marginTop: '4px' }}
      >
        {getSortedTextPages.map((item, index) =>
          item.type === 'delim' ? (
            <StyledDelim key={index} color={color} delim={true}>
              <TextPageItem>
                <Typography
                  variant="bodyBoldSmall"
                  component="div"
                  color={color.gray}
                >
                  {item.title}
                </Typography>
              </TextPageItem>
            </StyledDelim>
          ) : (
            <StyledLink to={`/page/${item.slug}`} key={index} color={color}>
              <TextPageItem>
                <Typography
                  variant="bodyBoldSmall"
                  component="div"
                  color={color.gray}
                >
                  {item.title}
                </Typography>
                {/* <Typography
                  variant="bodyExSmall"
                  sx={{ mt: '9px' }}
                  component="div"
                  color={color.gray}
                >
                  {moment(item.updatedAt).format('DD.MM.YYYY HH:mm')}
                </Typography> */}
              </TextPageItem>
            </StyledLink>
          ),
        )}
      </Popover>
    </>
  );
};

export default TextPagesHeaderMenu;

const StyledLink = styled(Link)`
  padding: 16px 20px;
  display: block;
  text-decoration: none;
  background-color: ${({ delim, color }) => (delim ? color.gray_20 : 'unset')};
  cursor: ${({ delim }) => (delim ? 'default' : 'pointer')};
  & + a {
    border-top: 1px solid ${({ color }) => color.line};
  }
  &:hover {
    background-color: ${({ color }) => color.gray_20};
  }
`;
const StyledDelim = styled(Link)`
  padding: 8px 20px;
  display: block;
  text-decoration: none;
  background-color: ${({ delim, color }) => (delim ? color.gray_20 : 'unset')};
  text-transform: uppercase;
  cursor: ${({ delim }) => (delim ? 'default' : 'pointer')};
  & + a {
    border-top: 1px solid ${({ color }) => color.line};
  }
`;

const TextPageItem = styled.div`
  max-width: 400px;
  min-width: 200px;
`;
