import { useTranslation } from 'react-i18next';

import styled from 'styled-components';

import { Sprite } from '@root/assets/svg/index';

import { Box, Typography, useTheme } from '@mui/material';

const ProductLabel = ({
  isTop = false,
  isNew = false,
  isHit = false,
  isDiscounted = false,
  customLabels = [],
  sx,
  itemSx,
}) => {
  const { color } = useTheme();
  const { t } = useTranslation('product_details');

  return (
    <Box sx={{ display: 'flex', ...sx }}>
      {isTop && (
        <LableItem
          bgc={color.red}
          sx={{ ...itemSx, mt: '2px' }}
          variant="bodyBoldSmall"
          color={color.white}
        >
          <svg stroke={'#fff'}>
            <use href={`${Sprite}#icon-fire`} />
          </svg>
          ТОП
        </LableItem>
      )}
      {isNew && (
        <LableItem
          bgc={color.main}
          sx={{ ...itemSx, mt: '2px' }}
          variant="bodyBoldSmall"
          color={color.white}
        >
          <svg>
            <use href={`${Sprite}#icon-fire`} />
          </svg>
          НОВИНКА
        </LableItem>
      )}
      {isHit && (
        <LableItem
          bgc={color.green}
          sx={{ ...itemSx, mt: '2px' }}
          variant="bodyBoldSmall"
          color={color.white}
        >
          <svg>
            <use href={`${Sprite}#icon-fire`} />
          </svg>
          ХІТ
        </LableItem>
      )}
      {isDiscounted && (
        <LableItem
          bgc={color.orange}
          sx={{ ...itemSx, mt: '2px' }}
          variant="bodyBoldSmall"
          color={color.white}
        >
          {t('discounted_product')}
        </LableItem>
      )}
      {customLabels.length
        ? customLabels.map(item => {
            return (
              <LableItem
                bgc={item.color}
                sx={{ ...itemSx, mt: '2px' }}
                color={item.textColor ? item.textColor : color.white}
                variant="bodyBoldSmall"
              >
                {item.icon && (
                  <svg stroke={item.textColor ? item.textColor : color.white}>
                    <use href={`${Sprite}#icon-fire`} />
                  </svg>
                )}
                {item.text.toUpperCase()}
              </LableItem>
            );
          })
        : ''}
    </Box>
  );
};

export default ProductLabel;

const LableItem = styled(Typography)`
  display: flex;
  align-items: center;
  padding: 2px 4px;
  border-radius: 2px;
  background-color: ${({ bgc }) => bgc};

  svg {
    width: 14px;
    height: 14px;
    margin-right: 3px;
  }
`;
