import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import { HeaderDefault } from '@root/components';
import { getErrorStack, validEmail } from '@root/helpers';
import userSelectors from '@root/redux/user/user-selectors';
import { ButtonBase, ModalBase, PhoneInput, TextInput } from '@root/ui';

import newsAndEventsOperation from '@redux/news-and-events/news-and-events-operation';
import { setOpenNotification } from '@redux/notification-watcher/notification-watcher-slice';

export const SignUpToEventModal = ({
  isOpen,
  articleId,
  handleCloseModal,
  updateArticleDetailsAfterSendRegForm,
}) => {
  const userProfile = useSelector(userSelectors.getUserProfile);
  const [data, setData] = useState({
    first_name: userProfile.userName || '',
    last_name: '',
    email: userProfile.userEmail || '',
    phone: userProfile?.phones?.[0]?.phone || '',
    company_name: userProfile.company.name || '',
    position: '',
  });
  const [errors, setErrors] = useState();
  const dispatch = useDispatch();

  const { t } = useTranslation(['validation', 'news_and_events'], {
    useSuspense: false,
  });

  const handleUpdateEventInfo = (name, value) => {
    setData(prevData => ({ ...prevData, [name]: value }));
  };

  const validation = () => {
    let flag = false;
    let currentError = {};

    const { first_name, last_name, email, company_name, phone, position } =
      data;

    Object.keys({
      first_name,
      last_name,
      email,
      company_name,
      phone,
      position,
    }).forEach(key => {
      if (!data[key] || data[key] === '') {
        currentError = getErrorStack(
          currentError,
          key,
          t('validation:required'),
        );
        flag = true;
      }
    });

    if (phone.length < 12 && phone.length > 2) {
      currentError = getErrorStack(
        currentError,
        'phone',
        t('validation:not_full_fill'),
      );
      flag = true;
    }

    if (validEmail(email) && email !== '') {
      currentError = getErrorStack(
        currentError,
        'email',
        t('validation:incorrect_email'),
      );
      flag = true;
    }

    setErrors(currentError);

    return flag;
  };

  const handleSubmit = e => {
    e.preventDefault();

    if (validation()) return;

    dispatch(
      newsAndEventsOperation.eventRegistration({ info: data, id: articleId }),
    ).then(() => {
      dispatch(
        setOpenNotification({
          open: true,
          message: t('news_and_events:success_alert_title'),
        }),
      );
      handleCloseModal();
      updateArticleDetailsAfterSendRegForm();
    });
  };

  return (
    <>
      <ModalBase
        handleClose={handleCloseModal}
        open={isOpen}
        sx={{ width: '100%', maxWidth: '446px' }}
        modalHeader={
          <HeaderDefault
            onClose={handleCloseModal}
            text={t('news_and_events:reg_event_title')}
          />
        }
      >
        <CreateEventModalWrapper>
          <FormWrapper onSubmit={handleSubmit}>
            <InputsWrapper>
              <TextInput
                label={t('news_and_events:formFields.name_label')}
                placeholder={t('news_and_events:formFields.name_placeholder')}
                value={data.first_name}
                required
                onChange={value => handleUpdateEventInfo('first_name', value)}
                onBlur={() => {}}
                error={errors?.first_name?.init}
                errorText={errors?.first_name?.text}
              />
              <TextInput
                label={t('news_and_events:formFields.surname_label')}
                placeholder={t(
                  'news_and_events:formFields.surname_placeholder',
                )}
                value={data.last_name}
                required
                onChange={value => handleUpdateEventInfo('last_name', value)}
                onBlur={() => {}}
                error={errors?.last_name?.init}
                errorText={errors?.last_name?.text}
              />
              <TextInput
                label="Email"
                placeholder={t('news_and_events:formFields.email_placeholder')}
                value={data.email}
                required
                onChange={value => handleUpdateEventInfo('email', value)}
                onBlur={() => {}}
                error={errors?.email?.init}
                errorText={errors?.email?.text}
              />
              <PhoneInput
                label={t('news_and_events:formFields.phone_label')}
                placeholder="+38"
                value={data.phone}
                required
                onChange={value => handleUpdateEventInfo('phone', value)}
                onBlur={() => {}}
                setPhoneModal={() => {}}
                error={errors?.phone?.init}
                errorText={errors?.phone?.text}
              />
              <TextInput
                label={t('news_and_events:formFields.company_name_label')}
                placeholder={t(
                  'news_and_events:formFields.company_name_placeholder',
                )}
                value={data.company_name}
                required
                onChange={value => handleUpdateEventInfo('company_name', value)}
                onBlur={() => {}}
                error={errors?.company_name?.init}
                errorText={errors?.company_name?.text}
              />
              <TextInput
                label={t('news_and_events:formFields.position_label')}
                placeholder={t(
                  'news_and_events:formFields.position_placeholder',
                )}
                value={data.position}
                required
                onChange={value => handleUpdateEventInfo('position', value)}
                onBlur={() => {}}
                error={errors?.position?.init}
                errorText={errors?.position?.text}
              />
            </InputsWrapper>
            <ButtonWrapper>
              <ButtonBase
                type="submit"
                onClick={handleSubmit}
                sx={{ marginTop: '20px' }}
              >
                {t('news_and_events:buttons.sign_up')}
              </ButtonBase>
            </ButtonWrapper>
          </FormWrapper>
        </CreateEventModalWrapper>
      </ModalBase>
    </>
  );
};

const CreateEventModalWrapper = styled.div``;

const InputsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 12px;
`;

const FormWrapper = styled.form``;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

SignUpToEventModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleCloseModal: PropTypes.func.isRequired,
  articleId: PropTypes.number,
  updateArticleDetailsAfterSendRegForm: PropTypes.func.isRequired,
};
