import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import styled from 'styled-components';

import ProductLabel from '@root/components/ProductLabel';
import { theme } from '@root/styles';
import { CheckboxBase } from '@root/ui';

export const Tags = ({ isEdit, tagsList, onSave, windowWith }) => {
  const { t } = useTranslation(['product_details']);

  const COLORS = {
    top: theme.color.red,
    hit: theme.color.green,
    new: theme.color.main,
    is_sold_with_points: theme.color.background,
    sale: theme.color.orange,
  };
  return (
    <>
      {tagsList && tagsList.length && (
        <TagsWrapper>
          {isEdit ? (
            tagsList.map(tag => (
              <CheckboxWrapper>
                <CheckboxBase
                  onChange={() => onSave(tag.field, !tag.isActive)}
                  checked={tag.isActive}
                  width={16}
                >
                  <CheckboxTitle>{tag.title.toUpperCase()}</CheckboxTitle>
                </CheckboxBase>
              </CheckboxWrapper>
            ))
          ) : (
            <PrewiewTagsWrp windowWith={windowWith}>
              <ProductLabel
                sx={{ marginLeft: '12px' }}
                itemSx={{
                  marginRight: '8px',
                }}
                customLabels={tagsList
                  .filter(el => el.isActive)
                  .map((el, idx) => ({
                    color: COLORS[el.field],
                    text: el.title,
                    icon: el.field === 'is_sold_with_points',
                    textColor:
                      COLORS[el.field] === theme.color.background &&
                      theme.color.gray_80,
                  }))}
              />
            </PrewiewTagsWrp>
          )}
        </TagsWrapper>
      )}
    </>
  );
};

const PrewiewTagsWrp = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  //top: 40px;
  top: ${({ windowWith }) => (windowWith > 1199 ? `40px` : '0')};
`;

const TagsWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;

const CheckboxTitle = styled.div(() => ({
  display: 'flex',
  alignItems: 'center',
  marginLeft: '8px',
  fontWeight: '700',
  fontSize: '12px',
  color: theme.color.gray_80,
}));

const TagTitle = styled.div(() => ({
  display: 'flex',
  alignItems: 'center',
  marginLeft: '8px',
  fontWeight: '700',
  fontSize: '16px',
  color: theme.color.gray_80,
}));

const CheckboxWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 0 8px;
`;
