const getLoading = state => state.textPages.loading;
const getCurrentTextPageLoading = state => state.textPages.currentTextPageLoading;
const getTextPages = state => state.textPages.pagesList;
const getArticleData = state => state.textPages.currentTextPage;
const getHeaderMenuPages = state => state.textPages.headerMenuPages;

const textPagesSelectors = {
  getLoading,
  getTextPages,
  getArticleData,
  getCurrentTextPageLoading,
  getHeaderMenuPages,
};
export default textPagesSelectors;
