const getCities = state => state.option.cities;
const getActivityAreas = state => state.option.activityAreas;
const getHistoryFilters = state => state.option.historyFilters;
const getFilialList = state => state.option.filialList;
const getLogisticServices = state => state.option.logisticServices;
const getBrands = state => state.option.brands;
const getLangs = state => state.option.langs;

const optionSelectors = {
  getCities,
  getActivityAreas,
  getFilialList,
  getHistoryFilters,
  getLogisticServices,
  getBrands,
  getLangs,
};
export default optionSelectors;
