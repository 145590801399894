import React, { useState } from 'react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import axios from 'axios';
import PropTypes from 'prop-types';

import { ErrorOnMaintenance } from '@root/assets/image';
import { ErrorSprite } from '@root/assets/svg/index';
import { Error } from '@root/components';

export const ErrorChecker1C = ({ children, errorPageStyles, error }) => {
  const { t } = useTranslation(['error']);
  return (
    <>
      {!error ? (
        children
      ) : (
        <Error
          themed={true}
          errorImage={`${ErrorSprite}#maintenance`}
          errorTitle={t('error:on_maintenance_page.title')}
          errorDescription={t('error:on_maintenance_page.description')}
          withLogo={false}
          errorPageStyles={errorPageStyles}
          isWidget={true}
        />
      )}
    </>
  );
};

ErrorChecker1C.propTypes = {
  children: PropTypes.object,
  errorPageStyles: PropTypes.object,
  error: PropTypes.bool,
};
