import {
  Table, TableBody, TableCell,
  TableContainer,
  TableHead,
  TableRow, Typography, useTheme,
} from '@mui/material';
import {exchangeRatesConf} from "@root/conf";

export const ExchangeRateTable = () => {
  const { color } = useTheme();

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow
            sx={{
              display: 'flex',
              padding: '12px 0',
              borderBottom: `1px solid ${color.line}`,
            }}
          >
            {exchangeRatesConf.map(el => {
              return (
                <TableCell
                  key={el.label}
                  sx={{
                    p: 0,
                    ...el.style,
                    borderBottom: 'none',
                  }}
                >
                  <Typography
                    variant="bodyBoldSmall"
                    component="p"
                    color={color.gray_60}
                  >
                    {el.label}
                  </Typography>
                </TableCell>
              );
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {[
            { exchange_rate_short: 'UAH', exchange_rate_long: 'Гривня' },
            { exchange_rate_short: 'USD', exchange_rate_long: 'Долар США' },
            { exchange_rate_short: 'EUR', exchange_rate_long: 'Євро' },
          ].map((row, index) => (
            <TableRow
              sx={{
                display: 'flex',
                alignItems: 'center',
                borderBottom: 'none',
                userSelect: 'none',
                padding: '16px 0',
                th: {
                  borderBottom: 'none',
                },
              }}
            >
              {exchangeRatesConf.map((el, i) => {
                switch (el?.value) {
                  case 'currency':
                    return (
                      <TableCell
                        component="th"
                        scope="row"
                        key={'si' + row.id + i}
                        sx={{
                          p: 0,
                          ...el.style,
                          cursor: 'pointer',
                        }}
                      >
                        <Typography
                          variant="bodyLarge"
                          textTransform="uppercase"
                          color={color.gray_80}
                        >
                          {row.exchange_rate_short}
                        </Typography>

                        <Typography
                          variant="bodyLarge"
                          color={color.gray_80}
                        >
                          {row.exchange_rate_long}
                        </Typography>
                      </TableCell>
                    );
                  case 'exchange_rate':
                    return (
                      <TableCell
                        component="th"
                        scope="row"
                        key={'si' + row.id + i}
                        sx={{ p: 0, ...el.style }}
                      >
                        <Typography
                          variant="bodyLarge"
                          textTransform="uppercase"
                          color={color.gray_80}
                        >
                          14.567
                        </Typography>
                      </TableCell>
                    );
                }
              })}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
