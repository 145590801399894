import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Sprite } from '@root/assets/svg';
import { BaseModalHeader } from '@root/components';
import { getErrorStack } from '@root/helpers';
import { useNavigation } from '@root/hooks';
import { WidthContext } from '@root/providers/WidthProvider';
import {
  BasicSelect,
  ButtonBase,
  IconBtn,
  ModalBase,
  TextArea,
} from '@root/ui';

import logisticOperation from '@redux/logistic/logistic-operation';
import { setOpenNotification } from '@redux/notification-watcher/notification-watcher-slice';
import optionOperation from '@redux/option/option-operation';
import optionSelectors from '@redux/option/option-selectors';
import orderServiceOperation from '@redux/order-service/order-service-operation';

import { Divider, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import { ShipmentForm } from './ShipmentForm';
import { UserForm } from './UserForm';

const initialState = {
  shippingMethod: null,
  place: '1',
  filial_id: null,
  address: '',
  contact_person: '',
  phone: '',
  novaposhta_ttn: '',
  additional_info: '',
  productRefundForm: [],
  productRepairForm: [],
};

export const ShipmentModal = ({ type, open, close, defaultPath }) => {
  const { color } = useTheme();
  const { t } = useTranslation(['validation', 'common'], {
    useSuspense: false,
  });
  const deliveryMethodData = useSelector(optionSelectors.getLogisticServices);
  const dispatch = useDispatch();
  const [data, setData] = useState(initialState);
  const [error, setError] = useState({});
  const [guaranteeStatus, setGuaranteeStatus] = useState('');
  const [repairStatus, setRepairStatus] = useState('');
  const { navigation } = useNavigation();

  const { screenWidth } = useContext(WidthContext);

  const {
    shippingMethod,
    additional_info,
    productRefundForm,
    productRepairForm,
  } = data;

  const shippingMethodSelect = val => {
    setData(() => ({ ...initialState, shippingMethod: val }));
    setError({});
  };
  const filialSelect = val => {
    setData(prevState => ({ ...prevState, filial_id: val }));
  };

  const onChange = (name, type, idx) => value => {
    const dataCopy = JSON.parse(JSON.stringify(data));
    if (!type) {
      setData(prevState => ({ ...prevState, [name]: value }));
    } else {
      type === 'refund'
        ? (dataCopy.productRefundForm[idx][name] = value)
        : (dataCopy.productRepairForm[idx][name] = value);
      setData(dataCopy);
    }
  };

  useEffect(() => {
    dispatch(optionOperation.getLogisticServices());
  }, []);

  const validation = () => {
    let flag = false;
    let currentError = {};
    const {
      shippingMethod,
      place,
      filial_id,
      address,
      contact_person,
      phone,
      novaposhta_ttn,
      productRefundForm,
      productRepairForm,
    } = data;

    if (!shippingMethod || shippingMethod.length === '') {
      currentError = getErrorStack(
        currentError,
        'shippingMethod',
        t('validation:required'),
      );
      flag = true;
    }
    const fieldsValidation = keys => {
      return Object.keys(keys).forEach(key => {
        if (!data[key] || data[key] === '') {
          currentError = getErrorStack(
            currentError,
            key,
            t('validation:required'),
          );
          flag = true;
        }
      });
    };
    const nestedFieldsValidation = data => {
      return data.reduce((acc, currentValue, i) => {
        for (const key in currentValue) {
          if (guaranteeStatus !== 'not_found' && key === 'year_of_purchase')
            break;

          if (!key || currentValue[key] === '') {
            acc[i] = {
              ...acc[i],
              [key]: {
                init: true,
                text: t('validation:required'),
              },
            };
            flag = true;
          }
        }
        return acc;
      }, []);
    };

    switch (shippingMethod) {
      case 'novaposhta':
        fieldsValidation({ place, novaposhta_ttn });
        break;
      case 'courier':
        fieldsValidation({ place, address, contact_person, phone });
        if (phone.length < 12 && phone !== '') {
          currentError = getErrorStack(
            currentError,
            'phone',
            t('validation:input_phone_number'),
          );
          flag = true;
        }
        break;
      case 'filial':
        fieldsValidation({ place, filial_id });
        break;
      default:
        break;
    }
    if (productRefundForm.length > 0) {
      currentError = {
        ...currentError,
        refundErr: nestedFieldsValidation(productRefundForm),
      };
    }
    if (productRepairForm.length > 0) {
      currentError = {
        ...currentError,
        repairErr: nestedFieldsValidation(productRepairForm),
      };
    }
    setError(currentError);
    return flag;
  };

  const callNotification = () => {
    dispatch(
      setOpenNotification({
        open: true,
        width: '700px',
        message: t('common:notifications.shipment_successfully_created'),
        action: (
          <IconBtn
            onClick={() =>
              dispatch(
                setOpenNotification({
                  open: false,
                }),
              )
            }
            disableBorder
            sx={{ marginRight: '10px' }}
            size={16}
            href={`${Sprite}#icon-cross`}
          />
        ),
      }),
    );
  };

  const handleSubmit = e => {
    e.preventDefault();
    if (validation()) return;
    switch (type) {
      case 'order-service':
        dispatch(orderServiceOperation.createNewServiceLogistic(data)).then(
          () => {
            close();
            callNotification();
          },
        );
        break;
      case 'logistic':
        dispatch(logisticOperation.createNewDelivery(data)).then(() => {
          close();
          navigation(defaultPath);
          callNotification();
        });
        break;

      default:
        break;
    }
  };

  const cloneForm = type => {
    if (type === 'comeback') {
      setData(prevState => {
        return {
          ...prevState,
          productRefundForm: [
            ...prevState.productRefundForm,
            {
              model: '',
              quantity: '',
            },
          ],
        };
      });
    } else {
      setData(prevState => {
        return {
          ...prevState,
          productRepairForm: [
            ...prevState.productRepairForm,
            {
              model: '',
              serial_num: '',
              declared_malfunction: '',
              complete_set: '',
              year_of_purchase: '',
            },
          ],
        };
      });
    }
  };

  const removeForm = (idx, type) => {
    const dataCopy = JSON.parse(JSON.stringify(data));
    type === 'refund'
      ? dataCopy.productRefundForm.splice(idx, 1)
      : dataCopy.productRepairForm.splice(idx, 1);
    setData(dataCopy);
  };
  const isDisabledSubmit =
    productRefundForm.length > 0 || productRepairForm.length > 0;


  return (
    <ModalBase
      open={open}
      sx={{
        width: '600px',
        maxWidth: '100%',
      }}
      modalHeader={
        <>
          <BaseModalHeader
            mainTitle={t('common:shipment_modal.title')}
            isSearch={false}
            isLangTabs={false}
            closeModalCallback={close}
            toolbarSx={{
              width: 'auto',
              padding: '0 0 12px 0 !important',
              minHeight: 'initial !important',
              height: '40px',
            }}
          />
          <BasicSelect
            error={error?.shippingMethod?.init}
            helperText={error?.shippingMethod?.text}
            height={36}
            sx={{
              maxWidth: '300px',
              marginBottom: '12px',
            }}
            label={t('common:shipment_modal.shipping_method')}
            name={t('common:shipment_modal.select')}
            data={deliveryMethodData}
            currentId={shippingMethod}
            callBack={shippingMethodSelect}
          />
          <Divider sx={{ marginBottom: '12px' }} />
        </>
      }
      modalBottom={
        <ActionButtons>
          <ButtonBase
            sx={{
              height: '38px',
              width: '86px',
              fontSize: '0.75rem',
              background: 'transparent',
              color: color.gray,
              marginRight: '12px',
              border: `1px solid ${color.line}`,
              '&:hover': {
                background: 'transparent',
                boxShadow: 'none',
              },
            }}
            onClick={close}
          >
            {t('common:buttons.cancel')}
          </ButtonBase>
          <ButtonBase
            sx={{
              height: '38px',
              width: '86px',
              fontSize: '0.75rem',
            }}
            onClick={handleSubmit}
            disabled={!isDisabledSubmit}
          >
            {t('common:buttons.sent')}
          </ButtonBase>
        </ActionButtons>
      }
      scrollPadding="'0 19px 10px 0'"
    >
      <ContentWrap>
        <ShipmentForm
          data={data}
          selectedId={shippingMethod}
          onChange={onChange}
          onChangeSelect={filialSelect}
          error={error}
        />
        {shippingMethod && (
          <>
            <SubWrap>
              <Typography
                variant={'bodyBoldSmall'}
                sx={{ color: color.gray, marginBottom: '8px' }}
                component={'span'}
              >
                {t('common:shipment_modal.comeback')}
              </Typography>
              {data.productRefundForm.length > 0 &&
                data.productRefundForm.map((item, index) => (
                  <UserForm
                    key={index + 1}
                    type={'refund'}
                    onChange={onChange}
                    item={item}
                    index={index}
                    onClick={removeForm}
                    error={error}
                    styles={{
                      display: 'grid',
                      gridTemplateColumns:
                        screenWidth < 769 ? '1fr' : '1fr 1fr',
                    }}
                    guaranteeStatus={guaranteeStatus}
                    setGuaranteeStatus={setGuaranteeStatus}
                  />
                ))}
              <ButtonBase
                sx={{
                  background: 'transparent',
                  color: color.gray_60,
                  border: `1px solid ${color.line}`,
                  '&:hover': {
                    background: 'transparent',
                  },
                  height: '36px',
                }}
                onClick={() => cloneForm('comeback')}
              >
                {t('common:buttons.add')}
              </ButtonBase>
            </SubWrap>
            <SubWrap>
              <Typography
                variant={'bodyBoldSmall'}
                sx={{ color: color.gray, marginBottom: '8px' }}
                component={'span'}
              >
                {t('common:shipment_modal.repair')}
              </Typography>
              {data.productRepairForm.length > 0 &&
                data.productRepairForm.map((item, index) => (
                  <UserForm
                    key={index + 2}
                    // type={'refund'}
                    type={'repair'}
                    onChange={onChange}
                    item={item}
                    index={index}
                    onClick={removeForm}
                    error={error}
                    styles={{
                      display: 'grid',
                      gridTemplateColumns:
                        screenWidth < 769 ? '1fr' : '1fr 1fr',
                    }}
                    guaranteeStatus={repairStatus}
                    setGuaranteeStatus={setRepairStatus}
                  />
                ))}
              <ButtonBase
                sx={{
                  background: 'transparent',
                  color: color.gray_60,
                  border: `1px solid ${color.line}`,
                  '&:hover': {
                    background: 'transparent',
                  },
                  height: '36px',
                }}
                onClick={() => cloneForm('repair')}
              >
                {t('common:buttons.add')}
              </ButtonBase>
            </SubWrap>

            <TextArea
              sx={{ height: '96px' }}
              placeholder={t('common:description')}
              type="text"
              max={5000}
              value={additional_info}
              onChange={onChange('additional_info')}
              label={t('common:shipment_modal.additional_info')}
              styleWrapper={{ marginTop: '20px' }}
            />
          </>
        )}
      </ContentWrap>
    </ModalBase>
  );
};

const ContentWrap = styled.div`
  max-height: 700px;
  margin-right: 5px;
`;

const SubWrap = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  ${prop => prop.styles}
`;

const ActionButtons = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 10px;
`;

ShipmentModal.propTypes = {
  type: PropTypes.oneOf(['order-service', 'logistic']),
  open: PropTypes.bool.isRequired,
  close: PropTypes.bool.isRequired,
  defaultPath: PropTypes.string,
};
