import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import styled from 'styled-components';

import { Sprite } from '@root/assets/svg/index';

import { useTheme } from '@mui/material';

import { Typography } from '../../../node_modules/@mui/material/index';

const TutorialButtonFixed = () => {
  const { color } = useTheme();
  const { t } = useTranslation(['common']);
  const [show, setShow] = useState(false);
  const link =
    'https://www.youtube.com/playlist?list=PLfwjorYZKQ-004pcBs8x7Co4BuGEOlQ_t';

  return (
    <>
      <FixedWrap>
        <ButtonDiv
          color={color}
          onClick={() => {
            setShow(!show);
          }}
        >
          {show ? (
            <ButtonSvg width={30}>
              <use href={`${Sprite}#icon-close`} />
            </ButtonSvg>
          ) : (
            <ButtonSvg>
              <use href={`${Sprite}#icon-info`} />
            </ButtonSvg>
          )}
        </ButtonDiv>
        <WindowModal show={show}>
          {/* <Typography variant="bodyBoldSmall">Text text text text</Typography> */}
          <Item color={color} href={link} target="_blank">
            <div className="imgWrap">
              <ItemSvg>
                <use href={`${Sprite}#icon-info`} />
              </ItemSvg>
            </div>
            <Typography variant="bodyBoldSmall">
              {t('tutorial.paylist_modal_dialog')}
              <Typography variant="bodyBoldSmall" sx={{textDecoration: 'underline'}}>
                {t('tutorial.paylist_modal_dialog_playlist')}
              </Typography>
            </Typography>
          </Item>
        </WindowModal>
      </FixedWrap>
    </>
  );
};

export default TutorialButtonFixed;

const FixedWrap = styled.div`
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 10;
  width: 30px;
  height: 30px;
`;
const ButtonDiv = styled.div`
  position: absolute;
  bottom: 30px;
  right: 30px;
  z-index: 10;
  width: 60px;
  height: 60px;
  background-color: ${({ color }) => color.main};
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  @media(max-width: 768px) {
    bottom: 5px;
    right: 5px;
    width: 50px;
    height: 50px;
  }
`;
const ButtonSvg = styled.svg`
  width: ${({ width }) => (width ? `${width}px` : '40px')};
  stroke: #fff;
`;

const WindowModal = styled.div`
  min-width: 360px;
  position: absolute;
  bottom: 100px;
  right: 30px;
  background-color: #fff;
  box-shadow: rgb(0, 0, 0, 0.07) 0px 0px 3px;
  padding: 10px;
  border-radius: 5px;
  transform: ${({ show }) => (show ? 'translate(0,0)' : 'translate(0,210px)')};
  transition: 0.3s ease;
`;

const Item = styled.a`
  display: flex;
  align-items: center;
  /* margin-top: 10px; */

  .imgWrap {
    width: 50px;
    height: 50px;
    flex: 0 0 50px;
    margin-right: 10px;
    border-radius: 50%;
    background-color: ${({ color }) => color.main};
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const ItemSvg = styled.svg`
  width: 40px;
  stroke: #fff;
`;
