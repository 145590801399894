import { useState } from 'react';

import { useTheme } from '@mui/material';

import { Menu, Typography } from '../../../node_modules/@mui/material/index';

const CopyBtn = ({ text, children, sx, copyTxt }) => {
  const { color } = useTheme();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = event => {
    copyToClipboard();
    setAnchorEl(event.currentTarget);
    setTimeout(() => {
      setAnchorEl(null);
    }, 1500);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const copyToClipboard = () => {
    const textarea = document.createElement('textarea');
    textarea.value = copyTxt || '';
    document.body.appendChild(textarea);
    textarea.select();
    document.execCommand('copy');
    document.body.removeChild(textarea);
  };

  return (
    <div style={{display: "flex", alignItems: "center"}}>
      <div onClick={handleClick}>{children}</div>
      <Menu
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
      >
        <Typography
          variant="bodyMedium"
          color={color.gray}
          sx={{ padding: '0 10px' }}
        >
          {text}
        </Typography>
      </Menu>
    </div>
  );
};

export default CopyBtn;
