import { useNavigate, useParams } from 'react-router-dom';

export const useNavigation = () => {
  const { lang } = useParams();
  const navigate = useNavigate();

  const navigation = url => {
    const currentLang = lang ? `/${lang}` : '';
    if (url === -1) {
      navigate(url);
    } else {
      navigate(currentLang + url);
    }
  };

  return { navigation };
};
