import { useState } from 'react';
import { useDispatch } from 'react-redux';

import axios from 'axios';

import { Error403, Error404, Error500 } from '@root/assets/image/index';
import OnMaintenance from '@root/page/Errors/OnMaintenance/index';
import authOperation from '@root/redux/auth/auth-operation';
import { unsetToken } from '@root/redux/auth/auth-slice';

const token = {
  set(token) {
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  },
  unset() {
    axios.defaults.headers.common['Authorization'] = ``;
  },
};

export const Guard = ({ children }) => {
  const [errorView, setErrorView] = useState(null);
  const dispatch = useDispatch();

  axios.interceptors.response.use(
    response => {
      return response;
    },
    error => {
      switch (error.response.status) {
        case 401:
          token.unset();
          dispatch(unsetToken());
          window.location.reload();
          break;
        default:
          setErrorView(error.response.status);
          break;
      }

      return Promise.reject(error);
    },
  );

  const getView = errorCode => {
    switch (true) {
      case [404, 405, 410].includes(errorCode):
        return <Error404 />;

      case [402, 403, 429].includes(errorCode):
        return <Error403 />;

      case errorCode === 503:
        setInterval(() => {
          dispatch(
            authOperation.getServerStatus({
              onResolve: () => {
                window.location.reload();
              },
              onReject: status => {},
            }),
          );
        }, 5000);
        return <OnMaintenance />;

      case errorCode >= 300 && errorCode < 500:
        return children;

      case errorCode >= 500:
        return <Error500 />;

      default:
        return children;
    }
  };

  return <>{getView(errorView)}</>;
};
