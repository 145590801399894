import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import styled from 'styled-components';

import { Sprite } from '@root/assets/svg';
import { useLang } from '@root/hooks';
import { MenuBody, MenuButton, MenuItemCustoms } from '@root/ui';

import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';

const LANG = {
  uk: 'УКР',
  ru: 'РУС',
  en: 'EN',
  // cs: 'CS',
};

export const Locales = () => {
  const { color } = useTheme();
  const { i18n, t } = useTranslation(['header'], { useSuspense: false });
  const { switchLang, localLang } = useLang();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  document.documentElement.lang = localLang;

  useEffect(() => {
    i18n.changeLanguage(localLang);
  }, []);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChangeLang = langChose => {
    switchLang(langChose);
  };

  return (
    <div data-test-id="LocalesSwitcher">
      <MenuButton open={open} handleClick={handleClick}>
        <Svg color={color} width="18" height="18">
          <use href={`${Sprite}#icon-world`}></use>
        </Svg>
        <Typography
          variant={'bodyBoldSmall'}
          sx={{ letterSpacing: '0.02em', lineHeight: 1, padding: '2px 0' }}
        >
          {LANG[localLang]}
        </Typography>
      </MenuButton>

      <MenuBody
        anchorEl={anchorEl}
        open={open}
        handleClose={handleClose}
        sx={{ width: '96px' }}
      >
        <MenuItemCustoms
          isActive={localLang === 'uk'}
          handelClick={() => {
            handleClose();
            handleChangeLang('uk');
          }}
        >
          <Typography
            className={localLang === 'uk' && 'active'}
            variant={'bodySmall'}
            color={`${color.gray} !important`}
          >
            {t('header:locales_widget.uk')}
          </Typography>
        </MenuItemCustoms>
        <MenuItemCustoms
          isActive={localLang === 'en'}
          handelClick={() => {
            handleClose();
            handleChangeLang('en');
          }}
        >
          <Typography
            className={localLang === 'en' && 'active'}
            variant={'bodySmall'}
            color={`${color.gray} !important`}
          >
            {t('header:locales_widget.en')}
          </Typography>
        </MenuItemCustoms>
        <MenuItemCustoms
          isActive={localLang === 'ru'}
          handelClick={() => {
            handleClose();
            handleChangeLang('ru');
          }}
        >
          <Typography
            className={localLang === 'ru' && 'active'}
            variant={'bodySmall'}
            color={`${color.gray} !important`}
          >
            {t('header:locales_widget.ru')}
          </Typography>
        </MenuItemCustoms>
      </MenuBody>
    </div>
  );
};

const Svg = styled.svg`
  margin-right: 4px;
`;
