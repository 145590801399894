import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { serialize } from 'object-to-formdata';
import styled from 'styled-components';

import { ImgAdd, Sprite } from '@root/assets/svg/index';
import { HeaderDefault } from '@root/components/Modal/ModalHeader/Default/index';
import { getErrorStack } from '@root/helpers/validation';
import bannersOperation from '@root/redux/banners/banners-operation';
import bannersSelectors from '@root/redux/banners/banners-selectors';
import { ButtonBase } from '@root/ui/Button/ButtonBase/index';
import { FileLoader } from '@root/ui/Input/LogoFileLoader/index';
import { ProgressLoader } from '@root/ui/Loader/ProgressLoader/index';
import { ModalBase } from '@root/ui/Modal/index';

import { useTheme } from '@mui/material';

import { Typography } from '../../../../node_modules/@mui/material/index';
import BannerImagesItem from '../BannerImagesItem/index';

const BannerImgsSelect = ({
  banners,
  selectedItemId,
  onfileUpload,
  onSelect,
  onDelete,
  onDragDrop,
}) => {
  const { color } = useTheme();
  const { t } = useTranslation(['settings']);
  const [open, setOpen] = useState(false);
  const [file, setFile] = useState(null);
  const uploadLoading = useSelector(bannersSelectors.getUploadLoading);
  const [error, setError] = useState({});
  const fileRef = useRef();
  const dispatch = useDispatch();

  const handleSetImage = e => {
    setFile(e);
  };

  const validation = () => {
    let flag = false;
    let currentError = {};

    if (file) {
      if (file?.size > 6291456) {
        currentError = getErrorStack(
          currentError,
          'file',
          t('validation:file_size'),
        );
        flag = true;
      }
    } else {
      currentError = getErrorStack(
        currentError,
        'file',
        t('validation:required'),
      );
    }
    setError(currentError);
    return flag;
  };

  const sendImage = () => {
    if (validation()) return;
    dispatch(
      bannersOperation.addBanner({
        formData: serialize({
          image: file,
          x: 10,
          y: 10,
          anchor: 'top-left',
        }),
        onResolve: () => {
          setFile(null);
          setError({});
          onfileUpload();
        },
      }),
    );
  };

  return (
    <>
      <ImgSelector>
        <CustomScrollWrap>
          {banners.map((banner, index) => (
            <BannerImagesItem
              key={banner.id}
              banner={banner}
              color={color}
              selectedItemId={selectedItemId}
              index={index}
              dropHandler={onDragDrop}
              onSelect={onSelect}
              onDelete={onDelete}
            />
          ))}
        </CustomScrollWrap>

        <AddImg
          color={color}
          onClick={() => {
            setOpen(true);
          }}
        >
          <img src={ImgAdd} alt="" />
          <Typography
            variant="bodyBoldSmall"
            color={color.gray_60}
            sx={{ marginBottom: '4px' }}
          >
            {t('banner.add')}
          </Typography>
        </AddImg>
      </ImgSelector>

      <ModalBase
        open={open}
        handleClose={() => {
          setOpen(false);
        }}
        modalHeader={
          <HeaderDefault
            onClose={() => {
              setOpen(false);
            }}
            text={t('banner.upload_image')}
          />
        }
        sx={{ position: 'relative' }}
      >
        <FileLoader
          fileRef={fileRef}
          file={file}
          setFile={handleSetImage}
          title={t('banner.upload_image')}
          handleSetImage={handleSetImage}
          initError={error?.file?.init}
        ></FileLoader>
        <BtnWrap>
          <ButtonBase onClick={sendImage} disabled={!file}>
            {t('banner.upload')}
          </ButtonBase>
        </BtnWrap>
        {uploadLoading && <ProgressLoader />}
      </ModalBase>
    </>
  );
};

export default BannerImgsSelect;

const ImgSelector = styled.div`
  display: flex;
  flex-direction: column;
  padding: 22px 0 22px 22px;
`;

const AddImg = styled.div`
  border-radius: 4px;
  box-shadow: ${({ color }) => color.line} 0px 0px 0px 1px;
  width: 100px;
  height: 94px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-top: 20px;
`;

const BtnWrap = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
  width: 350px;
`;

const CustomScrollWrap = styled.div`
  max-height: calc(100vh - 300px);
  overflow: auto;
  padding: 3px 0 0 3px;
`;
