export const NavAdmin = t => [
  {
    path: 'businesses',
    name: t('admin:nav_admin_conf.businesses'),
    role: 'all',
  },
  {
    path: 'counterparts',
    name: t('admin:nav_admin_conf.counterparts'),
    role: 'all',
  },
  {
    path: 'people',
    name: t('admin:nav_admin_conf.people'),
    role: 'all',
  },
  {
    path: 'agreements',
    name: t('admin:nav_admin_conf.agreements'),
    role: 'all'
  },
];
