import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const getKnowledgeData = createAsyncThunk(
    'knowledge/getKnowledgeData',
    async ({ brandId, q, onResolve, onReject }, { rejectWithValue }) => {
        try {
            const { data: {
                data,
                links,
                meta
            } } = await axios.get(`/knowledge/${brandId}`, {
                params: {
                    q
                }
            });
            if (onResolve) onResolve();
            return {
                data,
                links,
                meta
            };
        } catch (error) {
            if (onReject) onReject();
            return rejectWithValue(error);
        }
    },
);
const getKnowledgeArticle = createAsyncThunk(
    'knowledge/getKnowledgeArticle',
    async ({ brandId, slug, onResolve, onReject }, { rejectWithValue }) => {
        try {
            const { data: {
                data,
            } } = await axios.get(`/knowledge/${brandId}/${slug}`);
            if (onResolve) onResolve();
            return data;
        } catch (error) {
            if (onReject) onReject();
            return rejectWithValue(error);
        }
    },
);
const editKnowledgeArticle = createAsyncThunk(
    'knowledge/editKnowledgeArticle',
    async ({ brandId, id, title, content, authorId, onResolve, onReject }, { rejectWithValue }) => {
        try {
            await axios.post(`/admin/knowledge/${brandId}/${id}`, {
                title,
                content,
                author_id: authorId,
            });
            if (onResolve) onResolve();
            return {};
        } catch (error) {
            if (onReject) onReject();
            return rejectWithValue(error);
        }
    },
);
const createKnowledgePost = createAsyncThunk(
    'knowledge/createKnowledgePost',
    async ({ brandId, title, content, authorId, onResolve, onReject }, { rejectWithValue }) => {
        try {
            await axios.post(`/admin/knowledge/${brandId}`, {
                title,
                content,
                author_id: authorId,
            });
            if (onResolve) onResolve();
            return {};
        } catch (error) {
            if (onReject) onReject();
            return rejectWithValue(error);
        }
    },
);

const getKnowledgeAuthors = createAsyncThunk(
    'knowledge/getKnowledgeAuthors',
    async ({ brandId, onResolve, onReject }, { rejectWithValue }) => {
        try {
            const { data: { data } } = await axios.get(`/admin/knowledge/${brandId}/author`);
            if (onResolve) onResolve();
            return data;
        } catch (error) {
            if (onReject) onReject();
            return rejectWithValue(error);
        }
    },
);

const addKnowledgeAuthor = createAsyncThunk(
    'knowledge/addKnowledgeAuthor',
    async ({ brandId, name, file, onResolve, onReject }, { rejectWithValue }) => {
        try {
            const formData = new FormData();
            formData.append("name", name);
            formData.append("avatar", file);
            const { data: { data } } = await axios.post(`/admin/knowledge/${brandId}/author`, formData,
                {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                });
            if (onResolve) onResolve(data);
            return {};
        } catch (error) {
            if (onReject) onReject();
            return rejectWithValue(error);
        }
    },
);

export default {
    getKnowledgeData,
    getKnowledgeArticle,
    editKnowledgeArticle,
    createKnowledgePost,
    getKnowledgeAuthors,
    addKnowledgeAuthor,
};
