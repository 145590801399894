import { createSlice } from '@reduxjs/toolkit';

import {
  updateComparisonsListBySaved,
  updateProductComparison,
} from '@root/helpers';

import cartOperation from '../cart/cart-operation';
import favoriteOperation from '../favorite/favorite-operation';
import operation from './comparisons-operation';

const initialState = {
  loading: false,
  categoriesListLoading: false,
  productsLoader: false,
  createPdfLoading: false,
  pdfListLoading: false,
  comparisonTotalCounter: 0,
  comparisonCategoriesList: null,
  comparisonsProductsDetails: {},
  comparisonItemPdfDetails: {},
  comparisonPdfLinksList: [],
};

const comparisonsSlice = createSlice({
  name: 'comparisons',
  initialState,
  reducers: {
    clearComparisonsData(state, { payload }) {
      state.comparisonCategoriesList = initialState.comparisonCategoriesList;
      state.comparisonsProductsDetails =
        initialState.comparisonsProductsDetails;
      state.comparisonItemPdfDetails = initialState.comparisonItemPdfDetails;
      state.comparisonPdfLinksList = initialState.comparisonPdfLinksList;
    },
  },
  extraReducers: {
    // ================================Global-Store-Listeners=============================//
    [favoriteOperation.postProductToFavorite.fulfilled]: (
      state,
      { payload },
    ) => {
      const { id } = payload;
      state.comparisonsProductsDetails.products = updateComparisonsListBySaved(
        state.comparisonsProductsDetails?.products,
        { savedId: id },
      );
    },
    [favoriteOperation.deleteProductFromSaved.fulfilled]: (
      state,
      { payload },
    ) => {
      const { id } = payload;
      state.comparisonsProductsDetails.products = updateComparisonsListBySaved(
        state.comparisonsProductsDetails?.products,
        { savedId: id },
      );
    },
    [cartOperation.addProductToCart.fulfilled]: (state, { payload }) => {
      const { data } = payload;
      state.comparisonsProductsDetails.products = updateProductComparison(
        state.comparisonsProductsDetails?.products,
        data.data,
      );
    },
    [cartOperation.putOneToCart.fulfilled]: (state, { payload }) => {
      const { data } = payload;
      state.comparisonsProductsDetails.products = updateProductComparison(
        state.comparisonsProductsDetails?.products,
        data.data,
      );
    },
    [cartOperation.deleteOneFromCart.fulfilled]: (state, { payload }) => {
      const { data } = payload;
      state.comparisonsProductsDetails.products = updateProductComparison(
        state.comparisonsProductsDetails?.products,
        data.data,
      );
    },
    //====getComparisonsCount====//
    [operation.getComparisonsCount.pending]: (state, action) => {
      state.loading = true;
    },
    [operation.getComparisonsCount.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.comparisonTotalCounter = payload.all_count;
    },
    [operation.getComparisonsCount.rejected]: (state, { payload }) => {
      state.loading = false;
    },
    //====addToComparisons====//
    [operation.addToComparisons.pending]: (state, action) => {
      state.loading = true;
    },
    [operation.addToComparisons.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.comparisonTotalCounter = payload.all_count;
    },
    [operation.addToComparisons.rejected]: (state, { payload }) => {
      state.loading = false;
    },
    //====removeFromComparisons====//
    [operation.removeFromComparisons.pending]: (state, action) => {
      state.loading = true;
    },
    [operation.removeFromComparisons.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.comparisonTotalCounter = payload.all_count;
    },
    [operation.removeFromComparisons.rejected]: (state, { payload }) => {
      state.loading = false;
    },
    //====getComparisonsCategories====//
    [operation.getComparisonsCategories.pending]: (state, action) => {
      state.categoriesListLoading = true;
    },
    [operation.getComparisonsCategories.fulfilled]: (state, { payload }) => {
      state.categoriesListLoading = false;
      state.comparisonCategoriesList = payload;
    },
    [operation.getComparisonsCategories.rejected]: (state, { payload }) => {
      state.categoriesListLoading = false;
    }, //====getComparisonsProductsDetails====//
    [operation.getComparisonsProductsDetails.pending]: (state, action) => {
      state.productsLoader = true;
    },
    [operation.getComparisonsProductsDetails.fulfilled]: (
      state,
      { payload },
    ) => {
      state.productsLoader = false;
      state.comparisonsProductsDetails = payload;
    },
    [operation.getComparisonsProductsDetails.rejected]: (
      state,
      { payload },
    ) => {
      state.productsLoader = false;
    },
    //====removeFromComparisonsPage====//
    [operation.removeFromComparisonsPage.pending]: (state, action) => {
      state.categoriesListLoading = true;
    },
    [operation.removeFromComparisonsPage.fulfilled]: (state, { payload }) => {
      state.categoriesListLoading = false;
      state.comparisonTotalCounter = payload.all_count;
    },
    [operation.removeFromComparisonsPage.rejected]: (state, { payload }) => {
      state.categoriesListLoading = false;
    },
    //====removeCategoryCompare====//
    [operation.removeCategoryCompare.pending]: (state, action) => {
      state.categoriesListLoading = true;
    },
    [operation.removeCategoryCompare.fulfilled]: (state, { payload }) => {
      state.categoriesListLoading = false;
      state.comparisonTotalCounter = payload.all_count;
    },
    [operation.removeCategoryCompare.rejected]: (state, { payload }) => {
      state.categoriesListLoading = false;
    },
    //====createComparisonPdf====//
    [operation.createComparisonPdf.pending]: (state, action) => {
      state.createPdfLoading = true;
    },
    [operation.createComparisonPdf.fulfilled]: (state, { payload }) => {
      state.createPdfLoading = false;
      state.comparisonItemPdfDetails = payload[0];
    },
    [operation.createComparisonPdf.rejected]: (state, { payload }) => {
      state.createPdfLoading = false;
    },
    //====getComparisonPdfLinksList====//
    [operation.getComparisonPdfLinksList.pending]: (state, action) => {
      state.pdfListLoading = true;
    },
    [operation.getComparisonPdfLinksList.fulfilled]: (state, { payload }) => {
      state.pdfListLoading = false;
      state.comparisonPdfLinksList = payload;
    },
    [operation.getComparisonPdfLinksList.rejected]: (state, { payload }) => {
      state.pdfListLoading = false;
    },
    //====removePdfListItem====//
    [operation.removePdfListItem.pending]: (state, action) => {},
    [operation.removePdfListItem.fulfilled]: (state, { payload }) => {
      state.comparisonPdfLinksList = payload;
    },
    [operation.removePdfListItem.rejected]: (state, { payload }) => {},
  },
});

export const { clearComparisonsData } = comparisonsSlice.actions;
export default comparisonsSlice.reducer;
