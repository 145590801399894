import { useEffect } from 'react';

import styled from 'styled-components';

export const Scaler = ({ children }) => {
  const initialScreenWidth = 1440;
  const hasWindow = typeof window !== 'undefined';

  const getWindowDimensions = () => {
    const screenWidth = hasWindow ? window.innerWidth : null;
    const screenHeight = hasWindow ? window.innerHeight : null;
    return {
      screenWidth,
      screenHeight,
    };
  };

  const getScaleRatio = () => {
    const { screenWidth } = getWindowDimensions();

    return screenWidth / initialScreenWidth;
  };

  const handleScale = () => {
    const home = document.getElementsByClassName('home');
    const homeArray = [...home];
    homeArray[0].style.transform = `scale(${getScaleRatio()})`;
  };

  useEffect(() => {
    handleScale();
  }, []);

  useEffect(() => {
    window.addEventListener('resize', handleScale);
    return () => window.removeEventListener('resize', handleScale);
  }, [hasWindow]);

  return (
    <ScalerWrapper>{children({ scaleRatio: getScaleRatio() })}</ScalerWrapper>
  );
};

const ScalerWrapper = styled.div``;
