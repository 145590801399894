import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Sprite } from '@root/assets/svg';
import { IconBtn, OutlineButtonBase, TextInput } from '@root/ui';

import { Typography } from '@mui/material';

export const InputBtnConfirm = ({ handleConfirm, placeholder }) => {
  const [openInput, setOpenInput] = useState(false);
  const [value, setValue] = useState('');
  const { t } = useTranslation(['product_details'], {
    useSuspense: false,
  });

  const onChange = () => value => {
    setValue(value);
  };

  return (
    <>
      {openInput ? (
        <TextInput
          id="input-with-icon-adornment"
          sx={{
            width: '100%',
            height: '36px',
          }}
          styleWrapper={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            width: '100%',
          }}
          placeholder={placeholder}
          max={100}
          endInputIcons={
            <>
              <IconBtn
                sx={{ marginLeft: '10px' }}
                href={`${Sprite}#icon-abort`}
                onClick={e => {
                  e.stopPropagation();
                  setOpenInput(false);
                }}
                size={16}
              />
              <IconBtn
                sx={{ marginLeft: '15px' }}
                href={`${Sprite}#icon-confirm`}
                onClick={e => {
                  e.stopPropagation();
                  handleConfirm(value);
                  setOpenInput(false);
                }}
                size={16}
              />
            </>
          }
          onChange={onChange('key')}
          value={value}
          onClick={e => e.stopPropagation()}
          onKeyDown={e => e.stopPropagation()}
        />
      ) : (
        <OutlineButtonBase
          type="button"
          sx={{
            width: '100%',
            border: 'none !important',
            justifyContent: 'flex-start',
          }}
          onClick={e => {
            e.stopPropagation();
            setOpenInput(true);
          }}
          data-test-id="addPhone"
        >
          <Svg width="16" height="16">
            <use href={`${Sprite}#icon-plus`}></use>
          </Svg>
          <Typography variant={'bodyBoldSmall'} align={'start'}>
            {t('product_details:add_manufacturer')}
          </Typography>
        </OutlineButtonBase>
      )}
    </>
  );
};

const Svg = styled.svg`
  margin-right: 4px;
`;

InputBtnConfirm.propTypes = {
  handleConfirm: PropTypes.func,
  placeholder: PropTypes.string,
};
