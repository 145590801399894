import { useTranslation } from 'react-i18next';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Clock } from '@root/assets/image';

import { Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

export const NoHistory = ({ title }) => {
  const { color } = useTheme();
  const { t } = useTranslation(['order_service'], { useSuspense: false });
  return (
    <NoHistoryWrapper>
      <Image src={Clock} />
      <Typography
        variant="bodySmall"
        component="div"
        color={color.gray_80}
        marginTop="40px"
        sx={{textAlign: 'center'}}
      >
        {title || t('order_service:not_found_documents_in_time_range')}
      </Typography>
    </NoHistoryWrapper>
  );
};

NoHistory.propTypes = {
  title: PropTypes.string,
};

const NoHistoryWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 50px;
`;
const Image = styled.img``;
