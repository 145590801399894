import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Sprite } from '@root/assets/svg';
import { Empty, HeaderDefault, SearchInput } from '@root/components';
import { correctPhone } from '@root/helpers';
import { ModalBase, OutlineButtonBase, ToggleBtn } from '@root/ui';

import orderOperation from '@redux/order/order-operation';

import { Typography, useTheme } from '@mui/material';

import { CartItem } from '../../CartItem';

export const ModalAllItem = ({
  open,
  toggleOpenModal,
  data,
  sectionType,
  setOpenCreateModal,
  handelAction,
}) => {
  const [searchValue, setSearchValue] = useState('');
  const dispatch = useDispatch();

  const { t } = useTranslation(['placing_order'], { useSuspense: false });

  const handelFilter = item => {
    const toLowerCaseSearch = searchValue.toLowerCase();
    switch (sectionType) {
      case 'delivery':
        return (
          item.info.name?.toLowerCase().includes(toLowerCaseSearch) ||
          item.info.middle_name?.toLowerCase().includes(toLowerCaseSearch) ||
          item.info.surname?.toLowerCase().includes(toLowerCaseSearch) ||
          item.info.phone?.toLowerCase().includes(toLowerCaseSearch) ||
          item.title?.toLowerCase().includes(toLowerCaseSearch) ||
          item.title?.includes(toLowerCaseSearch) ||
          correctPhone(item.info.phone)
            ?.toLowerCase()
            .includes(toLowerCaseSearch)
        );

      default:
        return (
          item.title?.toLowerCase().includes(toLowerCaseSearch) ||
          item.title?.includes(toLowerCaseSearch)
        );
    }
  };

  const { isPinnedArr, otherList, isLimitPined } = useMemo(() => {
    let isPinnedArr = [];
    let otherList = [];

    data.forEach(el => {
      if (el.is_pinned) {
        isPinnedArr.push(el);
      } else {
        otherList.push(el);
      }
    });
    const isLimitPined = isPinnedArr.length === 3;

    isPinnedArr = [...isPinnedArr].filter(item => handelFilter(item));

    otherList = [...otherList].filter(item => handelFilter(item));

    return { isPinnedArr, otherList, isLimitPined };
  }, [data, searchValue]);

  const onChangeSearchInput = name => value => {
    setSearchValue(value);
  };

  const handleClearSearch = () => {
    setSearchValue('');
  };

  const isDelivery = sectionType === 'delivery';
  return (
    <ModalBase
      open={open}
      handleClose={toggleOpenModal}
      sx={{
        maxWidth: '600px',
        width: '100%',
      }}
      modalHeader={
        <>
          <HeaderDefault
            text={
              isDelivery
                ? t(
                    'placing_order:modals.my_saved_shipping_addresses_modal.my_saved_shipping_addresses',
                  )
                : t(
                    'placing_order:modals.my_saved_payment_templates_modal.my_saved_payment_templates',
                  )
            }
            onClose={toggleOpenModal}
          />
          <FlexWrapper>
            <SearchInput
              focus={false}
              onBlur={() => {}}
              onChange={onChangeSearchInput}
              searchValue={searchValue}
              handleClearSearch={handleClearSearch}
              placeholder={
                isDelivery
                  ? t(
                      'placing_order:modals.my_saved_shipping_addresses_modal.search_by_name_phone_number_or_delivery_type_placeholder',
                    )
                  : t(
                      'placing_order:modals.my_saved_payment_templates_modal.search_by_payment_type',
                    )
              }
              sx={{
                '& input': {
                  fontSize: '0.875rem',
                },
              }}
              sxLabel={{
                marginRight: 0,
              }}
            />
          </FlexWrapper>
        </>
      }
      modalBottom={
        <OutlineButtonBase
          type="button"
          sx={{ mt: '12px', width: '100%' }}
          onClick={() => setOpenCreateModal(true)}
        >
          <Svg width="16" height="16">
            <use href={`${Sprite}#icon-plus`}></use>
          </Svg>
          <Typography variant={'bodySmall'} align={'center'}>
            {isDelivery
              ? t(
                  'placing_order:modals.my_saved_shipping_addresses_modal.add_option_to_receive_order_btn',
                )
              : t(
                  'placing_order:modals.my_saved_payment_templates_modal.add_payment_option_for_order',
                )}
          </Typography>
        </OutlineButtonBase>
      }
    >
      <Wrapper>
        {isPinnedArr.length === 0 && otherList.length === 0 ? (
          <Empty />
        ) : (
          <WrapperList>
            {isPinnedArr.map((el, idx) => (
              <CartItem
                key={'del' + el.id + idx}
                type={sectionType}
                data={el}
                edit={() => handelAction({ el, type: 'edit' })}
                clone={() => handelAction({ el, type: 'clone' })}
                removeItem={({ entity, id }) => {
                  dispatch(
                    orderOperation.deleteTemplate({
                      entity,
                      id,
                    }),
                  );
                }}
                pin={() => {
                  dispatch(
                    orderOperation.changePinnedTemplate({
                      entity: sectionType,
                      id: el.id,
                      pin: el.is_pinned ? 0 : 1,
                    }),
                  );
                }}
                hideDellBtn={data.length === 1}
              />
            ))}
            {isLimitPined && isPinnedArr.length > 0 && (
              <Typography
                variant={'bodyExSmall'}
                align={'center'}
                sx={{ py: '10px' }}
                component={'p'}
              >
                {t('placing_order:pin_description')}
              </Typography>
            )}
            {otherList.map((el, idx) => (
              <CartItem
                key={'del' + el.id + idx}
                type={sectionType}
                data={el}
                edit={() => handelAction({ el, type: 'edit' })}
                clone={() => handelAction({ el, type: 'clone' })}
                removeItem={({ entity, id }) => {
                  dispatch(
                    orderOperation.deleteTemplate({
                      entity,
                      id,
                    }),
                  );
                }}
                pin={() => {
                  dispatch(
                    orderOperation.changePinnedTemplate({
                      entity: sectionType,
                      id: el.id,
                      pin: el.is_pinned ? 0 : 1,
                    }),
                  );
                }}
                hidePin={isLimitPined}
                hideDellBtn={data.length === 1}
              />
            ))}
          </WrapperList>
        )}
      </Wrapper>
    </ModalBase>
  );
};

const Wrapper = styled.div`
  min-height: 601px;
`;

const WrapperList = styled.ul`
  padding-right: 5px;

  li + li {
    margin-top: 10px;
  }
`;

const Svg = styled.svg`
  margin-right: 4px;
`;

const FlexWrapper = styled.div`
  margin-top: 12px;
  display: flex;
  margin-bottom: 10px;
`;

ModalAllItem.propTypes = {
  open: PropTypes.bool.isRequired,
  toggleOpenModal: PropTypes.func.isRequired,
  data: PropTypes.arrayOf(
    PropTypes.exact({
      entity: PropTypes.string,
      id: PropTypes.number,
      info: PropTypes.exact({
        address: PropTypes.string,
        delivery_info: PropTypes.exact({
          city_ref: PropTypes.string,
          city_title: PropTypes.string,
          date: PropTypes.string,
          department_ref: PropTypes.string,
          department_title: PropTypes.string,
          elevator: PropTypes.bool,
          flat_num: PropTypes.string,
          floor_num: PropTypes.string,
          npPhone: PropTypes.string,
          post_office_ref: PropTypes.string,
          post_office_title: PropTypes.string,
          street_number: PropTypes.string,
          street_title: PropTypes.string,
          time: PropTypes.string,
          type: PropTypes.string,
        }),
        is_drop: PropTypes.bool,
        middle_name: PropTypes.string,
        name: PropTypes.string,
        phone: PropTypes.string,
        surname: PropTypes.string,
      }),
      is_pinned: PropTypes.number,
      is_selected: PropTypes.bool,
      key: PropTypes.string,
      title: PropTypes.string,
    }),
  ),
  sectionType: PropTypes.string,
  setOpenCreateModal: PropTypes.func.isRequired,
  handelAction: PropTypes.func.isRequired,
};
