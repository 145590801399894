import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { serialize } from 'object-to-formdata';
import styled from 'styled-components';

import { BuildingsIcon } from '@root/assets/svg';
import {
  CompanyForm,
  CreateOrEditCompany,
  Empty,
  ProfileList,
} from '@root/components';
import { handleDispatchFetch } from '@root/helpers';
import { useCommercialOffer, useNavigation } from '@root/hooks';
import { WidthContext } from '@root/providers/WidthProvider';
import { ProgressLoader } from '@root/ui';

import commercialOfferOperation from '@redux/commercial-offer/commercial-offer-operation';
import commercialOfferSelectors from '@redux/commercial-offer/commercial-offer-selectors';
import {
  clearModalState,
  setOpenModal,
} from '@redux/modal-watcher/modal-watcher-slice';
import { setOpenNotification } from '@redux/notification-watcher/notification-watcher-slice';
import userSelectors from '@redux/user/user-selectors';

export const SubsidiaryCompanies = () => {
  const { data, setData, error, setError, onChange, validation } =
    useCommercialOffer();
  const [tabDetails, setTabDetails] = useState({
    active: 0,
    companyId: null,
  });
  const [open, setOpen] = useState(false);
  const permission = useSelector(userSelectors.getPermissions);
  const companyListLoading = useSelector(
    commercialOfferSelectors.companyListLoading,
  );
  const companyList = useSelector(commercialOfferSelectors.getCompanyList);
  const getCompanyFormData = useSelector(
    commercialOfferSelectors.getCompanyTemplates,
  );

  const dispatch = useDispatch();
  const { navigation } = useNavigation();
  const { screenWidth } = useContext(WidthContext);

  const { t } = useTranslation('profile');

  useEffect(() => {
    if (!permission.make_commercial_offer_access) {
      navigation('/error-403');
      return;
    }

    dispatch(commercialOfferOperation.getCompanyList());
  }, []);

  useEffect(() => {
    const { companyId } = tabDetails;
    if (companyList.length === 0) return;
    if (!companyId || companyList[0].selected) {
      setTabDetails({
        active: 0,
        companyId: companyList[0].id,
      });
      showSelectedCompany(companyList[0].id);
    }
  }, [companyList]);

  const handleTabClick = (index, companyId) => {
    setTabDetails({
      active: index,
      companyId,
    });
    showSelectedCompany(companyId);
    setError({});
  };

  const showSelectedCompany = companyId => {
    setData(prevState => ({
      ...prevState,
      ...getCompanyFormData.find(el => el.id === companyId),
    }));
  };

  const openModal = () => setOpen(true);
  const closeModal = () => setOpen(false);

  const callConfirmModal = () => {
    dispatch(
      setOpenModal({
        open: true,
        key: 'confirm',
        isHiddenHeader: true,
        inactiveBackdrop: true,
        dataForConfirm: {
          id: tabDetails.companyId,
        },
        callback: ({ id }) => {
          dispatch(commercialOfferOperation.deleteCompany({ id })).then(() => {
            dispatch(clearModalState());
          });
        },
      }),
    );
  };

  const callNotification = () => {
    dispatch(
      setOpenNotification({
        open: true,
        message: t('notifications.update_company_data'),
      }),
    );
  };

  const submitForm = e => {
    e.preventDefault();

    if (validation()) return;

    const logo = typeof data.logo === 'string' ? null : data.logo;

    handleDispatchFetch(({ onResolve, onReject }) =>
      dispatch(
        commercialOfferOperation.updateCompany({
          formData: serialize(
            {
              ...data,
              logo: logo,
            },
            {
              indices: true,
              nullsAsUndefineds: true,
              booleansAsIntegers: true,
            },
          ),
          id: tabDetails.companyId,
          onResolve,
          onReject,
        }),
      ).then(() => {
        callNotification();
      }),
    );
  };

  return (
    <ContentWrap>
      {companyListLoading ? (
        <ProgressLoader />
      ) : companyList.length > 0 ? (
        <>
          <ProfileList
            handleTabClick={handleTabClick}
            activeTab={tabDetails?.active}
            list={companyList}
            openModal={openModal}
            titleList={t('subsidiary_companies.company_title')}
            titleBtn={t('buttons.add_company')}
          />
          <FormWrap>
            <CompanyForm
              data={data}
              setData={setData}
              onChange={onChange}
              submitForm={submitForm}
              error={error}
              showActionBtn={true}
              actionBtnCallback={callConfirmModal}
              labelRowDirection={screenWidth >= 769}
              labelRowGap={screenWidth >= 769 ? '20px' : '0px'}
              inputHeight={'48px'}
              labelTextVariant={'bodyMedium'}
              errorPositionLeft={'0'}
            />
          </FormWrap>
        </>
      ) : (
        <Empty
          type={'EmptyBox'}
          boxStyles={{
            justifyContent: 'center',
            width: '100%',
            height: '50vh',
          }}
          title={t('subsidiary_companies.empty_title')}
          titleSx={{
            marginBottom: '40px',
          }}
          buttonTittle={t('buttons.add_company')}
          variants={{
            title: 'bodySmall',
          }}
          subTitle={''}
          callbackBtn={openModal}
        >
          <Img src={BuildingsIcon} alt={'Buildings'} />
        </Empty>
      )}
      {open && (
        <CreateOrEditCompany
          open={open}
          handleClose={closeModal}
          companyDetails={{ type: 'new', id: null }}
        />
      )}
    </ContentWrap>
  );
};

const ContentWrap = styled.div`
  display: flex;
  gap: 17px;
  height: 100%;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const FormWrap = styled.div`
  flex: 0 1 610px;

  @media (max-width: 768px) {
    flex: 1 1 auto;
  }
`;

const Img = styled.img`
  margin-bottom: 40px;
  width: 169px;
  height: 139px;
`;
