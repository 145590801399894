import { initReactI18next } from 'react-i18next';

import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import HttpApi from 'i18next-http-backend';

import { langConf } from '@root/conf';

const generateUniqueString = length => {
    const characters =
        'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';

    for (let i = 0; i < length; i++) {
        const randomIndex = Math.floor(Math.random() * characters.length);
        result += characters.charAt(randomIndex);
    }

    return result;
};

i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .use(LanguageDetector)
    .use(HttpApi)
    .init({
        supportedLngs: langConf,
        fallbackLng: 'en',
        ns: [
            'common, header, login, registration, validation, admin, cart, catalog, error,order_history, order, saved, search, password_reset, watchlist, settings',
        ],
        defaultNS: 'common',
        debug: false,
        detection: {
            order: ['localStorage'],
            caches: ['localStorage'],
        },
        interpolation: {
            escapeValue: false,
        },
        backend: {
            loadPath: `/assets/locales/{{lng}}/{{ns}}.json?token=${generateUniqueString(
                5,
            )}`,
        },
    });

export default i18n;
