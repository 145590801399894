import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import styled from 'styled-components';

import { Sprite } from '@root/assets/svg';
import { HeaderDefault } from '@root/components';
import { IconBtn, LabelStatus, ModalBase } from '@root/ui';

import orderServiceSelectors from '@redux/order-service/order-service-selectors';

import { Typography, useTheme } from '@mui/material';

export const ShrinkServiceOrdersWidget = ({ serviceOrderDetailsModule }) => {
  const serviceOrders = useSelector(orderServiceSelectors.getServiceOrders);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedServiceOrder, setSelectedServiceOrder] = useState(null);

  const { color } = useTheme();
  const { t } = useTranslation(['home'], { useSuspense: false });

  const handleOnServiceOrderClick = serviceOrder => {
    handleToggleModal(true);
    setSelectedServiceOrder(serviceOrder);
  };

  const handleToggleModal = isOpen => {
    setIsModalOpen(isOpen);
  };

  return (
    <ShrinkServiceOrdersWidgetWrapper>
      {isModalOpen && (
        <ModalBase
          handleClose={() => handleToggleModal(false)}
          open={isModalOpen}
          sx={{ paddingTop: '10px' }}
        >
          <HeaderDefault
            onClose={() => handleToggleModal(false)}
            text={t('home:modals.service_order_details_modal.title')}
            sx={{ marginBottom: '12px' }}
          />
          {serviceOrderDetailsModule({
            selectedServiceOrder,
            width: '100%',
            height: '100%',
          })}
        </ModalBase>
      )}
      {serviceOrders &&
        serviceOrders.map(serviceOrder => (
          <ShrinkServiceOrdersItem borderColor={color.line}>
            <ItemHeader>
              <Typography
                color={color.gray}
                variant="bodyBoldSmall"
                component="p"
              >
                {t('home:order')}{' '}
                <Typography
                  color={color.gray}
                  variant="bodyBoldSmall"
                  sx={{
                    cursor: 'pointer',
                    '&:hover': {
                      textDecoration: 'underline',
                    },
                  }}
                  onClick={() => handleOnServiceOrderClick(serviceOrder)}
                >
                  #{serviceOrder.number}
                </Typography>
              </Typography>

              <LabelStatus>
                <Typography color={color.gray_80} variant="bodyBoldExSmall">
                  {serviceOrder.status}
                </Typography>
              </LabelStatus>
            </ItemHeader>

            <Typography
              component="p"
              color={color.gray}
              variant="bodyExSmall"
              marginBottom="11px"
            >
              {t('home:device')}: {serviceOrder.model}.{' '}
              {t('home:serial_number')}: {serviceOrder.serial}
            </Typography>

            {serviceOrder.type === 'Гарантийный' ? (
              <Typography
                component="p"
                variant="bodyExSmall"
                marginBottom="11px"
                color={color.green}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  columnGap: '3px',
                  svg: {
                    fill: color.green,
                    stroke: color.green,
                  },
                }}
              >
                <IconBtn href={`${Sprite}#icon-confirm`} />
                {t('home:guaranteed')}
              </Typography>
            ) : (
              <Typography
                component="p"
                variant="bodyExSmall"
                marginBottom="11px"
                color={color.red}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  columnGap: '3px',
                  svg: {
                    fill: color.red,
                    stroke: color.red,
                  },
                }}
              >
                <IconBtn href={`${Sprite}#icon-cross`} />
                {t('home:not_guaranteed')}
              </Typography>
            )}

            <Typography
              component="p"
              color={color.gray_60}
              variant="bodyExSmall"
            >
              {serviceOrder.date}
            </Typography>
          </ShrinkServiceOrdersItem>
        ))}
    </ShrinkServiceOrdersWidgetWrapper>
  );
};

const ShrinkServiceOrdersWidgetWrapper = styled.div``;
const ShrinkServiceOrdersItem = styled.div`
  padding: 16px 20px;

  &:not(:first-child) {
    border-top: 1px solid ${({ borderColor }) => borderColor};
  }
`;

const ItemHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 30px;
  margin-bottom: 11px;
`;
