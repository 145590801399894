import { Sprite } from '@root/assets/svg';
import { useNavigation } from '@root/hooks';
import { ButtonWithoutBorder } from '@root/ui';

import { useTheme } from '@mui/material';

export const Scales = () => {
  const { color } = useTheme();
  const { navigation } = useNavigation();

  const click = () => navigation('/comparisons');
  return (
    <ButtonWithoutBorder
      onClick={click}
      sx={{
        color: color.gray_60,
        background: 'none',
        '&:hover': {
          color: color.main,
          background: 'none',
        },
        minWidth: 'unset',
        marginRight: '18px',
      }}
    >
      <svg width={24} height={24} fill={'none'}>
        <use href={`${Sprite}#icon-scales`}></use>
      </svg>
    </ButtonWithoutBorder>
  );
};
