const getKnowledgeList = state => state.knowledge.list;
const getKnowledgeListLoading = state => state.knowledge.loading;
const getKnowledgeMeta = state => state.knowledge.meta;
const getKnowledgeLinks = state => state.knowledge.links;
const getArticleLoading = state => state.knowledge.articleLoading;
const getArticleData = state => state.knowledge.articleData;
const getAuthors = state => state.knowledge.authorList;

const knowledgeSelectors = {
    getKnowledgeList,
    getKnowledgeListLoading,
    getKnowledgeMeta,
    getKnowledgeLinks,
    getArticleData,
    getArticleLoading,
    getAuthors,
};
export default knowledgeSelectors;
