import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import styled from 'styled-components';

import activityAreasOperation from '@root/redux/activity-areas/activity-areas-operation';
import { ButtonBase } from '@root/ui/Button/ButtonBase/index';
import { TextInput } from '@root/ui/Input/TextInput/index';

import { useTheme } from '@mui/material';

const ActivityAreaTableTop = () => {
  const { color } = useTheme();
  const [value, setValue] = useState('');
  const dispatch = useDispatch();
  const { t } = useTranslation('common', { useSuspense: false });

  const onChange = name => value => {
    setValue(value);
  };

  const onAdd = () => {
    if (!value || !value.trim()) return;

    dispatch(activityAreasOperation.addNewActivityArea({ name: value }));
    setValue('');
  };

  return (
    <Wrap color={color}>
      <TextInput
        value={value}
        onChange={onChange('value')}
        placeholder={t('placeholders.field_of_activity')}
      />
      <ButtonBase sx={{ marginLeft: '10px' }} onClick={onAdd}>
        {t('buttons.add')}
      </ButtonBase>
    </Wrap>
  );
};

export default ActivityAreaTableTop;

const Wrap = styled.div`
  padding: 10px 20px;
  display: flex;
  border-bottom: 1px solid ${({ color }) => color.line};

  & label {
    flex-grow: 1;
  }
`;
