import React from 'react';

import { Notifications } from '@root/components';

export const NotificationDashboardWidjet = ({ conf, activeTab }) => {
  return (
    activeTab === 'all' && (
      <Notifications
        containerHeight={'307px'}
        tabsNavigation={false}
        baseLoader={true}
      />
    )
  );
};
