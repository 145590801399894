import React, { useEffect } from 'react';

import PropTypes from 'prop-types';

import { Alert, AlertTitle, Typography, useTheme } from '@mui/material';

export const AlertBase = ({
  alertVariant = 'outlined',
  alertType = 'success',
  closeAfterTimeout = false,
  setShow,
  alertTitle,
  alertIcon,
  alertSx,
  alertTitleSx,
}) => {
  const { color } = useTheme();

  useEffect(() => {
    if (closeAfterTimeout) {
      const timer = setTimeout(() => {
        setShow(false);
      }, 1000);
      return () => {
        clearTimeout(timer);
      };
    }
  }, []);

  return (
    <Alert
      sx={{
        padding: 0,
        alignItems: 'center',
        border: 'none',
        '.MuiAlert-icon': {
          marginRight: 0,
          padding: 0,
          height: '16px',
          svg: {
            width: '16px',
            height: '16px',
          },
        },
        '.MuiAlert-message': {
          padding: 0,
        },
        ...alertSx,
      }}
      variant={alertVariant}
      severity={alertType}
      icon={alertIcon}
    >
      {alertTitle && (
        <Typography
          sx={{
            ...alertTitleSx,
            marginLeft: '5px',
          }}
          variant="bodyBoldSmall"
          color={'inherit !important'}
        >
          {alertTitle}
        </Typography>
      )}
    </Alert>
  );
};

AlertBase.propTypes = {
  alertVariant: PropTypes.oneOf(['outlined', 'filled']),
  alertType: PropTypes.oneOf(['success', 'error', 'warning', 'info']),
  closeAfterTimeout: PropTypes.bool,
  setShow: PropTypes.func,
  alertTitle: PropTypes.string,
  alertIcon: PropTypes.node,
  alertSx: PropTypes.object,
  alertTitleSx: PropTypes.string,
};
