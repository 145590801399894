import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import { HeaderDefault, InfiniteScroll, SearchInput } from '@root/components';
import { useDebounce } from '@root/hooks';
import { ModalBase, ProgressLoader } from '@root/ui';

import savedOrdersOperation from '@redux/saved-orders/saved-orders-operation';
import savedOrdersSelectors from '@redux/saved-orders/saved-orders-selectors';

import { NoSavedOrders } from './NoSavedOrders';
import { SavedOrder } from './SavedOrder';

export const SavedOrdersModal = ({ open, handleClose, isCreateSavedOrder }) => {
  const dispatch = useDispatch();
  const savedOrders = useSelector(savedOrdersSelectors.getSavedOrders);
  const savedOrdersMeta = useSelector(savedOrdersSelectors.getSavedOrdersMeta);
  const isSavedOrdersLoading = useSelector(
    savedOrdersSelectors.getIsSavedOrdersLoading,
  );
  const [searchValue, setSearchValue] = useState('');
  const debounceSearchValue = useDebounce(searchValue, 400);

  const { t } = useTranslation(['cart'], { useSuspense: false });

  useEffect(() => {
    if (!savedOrders) {
      handleGetSavedOrders(1);
    }
  }, []);

  useEffect(() => {
    if (savedOrders) {
      handleGetSavedOrders(1);
    }
  }, [debounceSearchValue]);

  const handleGetSavedOrders = page => {
    dispatch(
      savedOrdersOperation.getSavedOrders({
        page,
        search: searchValue,
      }),
    );
  };

  const handleFetchSavedOrdersOnScroll = inView => {
    checkIsInfiniteScroll(inView) &&
      handleGetSavedOrders(savedOrdersMeta.current_page + 1);
  };

  const checkIsInfiniteScroll = inView =>
    inView && savedOrdersMeta?.current_page !== savedOrdersMeta?.last_page;

  const onChangeSearchInput = name => value => {
    setSearchValue(value);
  };

  const handleClearSearch = () => {
    setSearchValue('');
  };

  return (
    <ModalBase
      open={open}
      handleClose={handleClose}
      modalHeader={
        <>
          <HeaderDefault
            text={t('cart:saved_order')}
            onClose={handleClose}
            sx={{ marginBottom: '10px' }}
          />
          <SearchInput
            focus={false}
            onBlur={() => {}}
            onChange={onChangeSearchInput}
            searchValue={searchValue}
            handleClearSearch={handleClearSearch}
            placeholder={t('cart:searchTitle')}
            sx={{
              '& input': {
                fontSize: '0.875rem',
              },
              mb: '12px',
            }}
          />
        </>
      }
      scrollPadding="0px 5px 0px 0px"
    >
      <SavedOrdersWrapper>
        {!savedOrders ? (
          <ProgressLoader />
        ) : savedOrders.length ? (
          <>
            {isSavedOrdersLoading && <ProgressLoader />}
            <SavedOrdersBody>
              <InfiniteScroll
                data={savedOrders}
                onScroll={({ inView }) =>
                  inView && handleFetchSavedOrdersOnScroll(inView)
                }
              >
                {({ isInView, item, index }) => (
                  <SavedOrder
                    key={item.id}
                    data={item}
                    handleClose={handleClose}
                    defaultExpanded={
                      isCreateSavedOrder && savedOrders[0].id === item.id
                    }
                  />
                )}
              </InfiniteScroll>
            </SavedOrdersBody>
          </>
        ) : (
          <NoSavedOrders goToCart={handleClose} />
        )}
      </SavedOrdersWrapper>
    </ModalBase>
  );
};

const SavedOrdersWrapper = styled.div`
  width: 618px;
  max-width: 100%;
  min-height: 480px;

  @media(max-width: 768px) {
    min-height: unset;
  }
`;

const SavedOrdersBody = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 480px;
`;

SavedOrdersModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  isCreateSavedOrder: PropTypes.bool,
};
