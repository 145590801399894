import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import homeOperation from '@redux/home/home-operation';
import homeSelectors from '@redux/home/home-selectors';

export const useSaleData = ({ key, count, activeTab }) => {
  const dataHomeTopSale = useSelector(homeSelectors.getHomeTopSale);
  const dataBuysMostOften = useSelector(homeSelectors.getHomeBuysMostOften);
  const getBuysMostOftenLoading = useSelector(
    homeSelectors.getBuysMostOftenLoading,
  );
  const getHomeTopSalleLoading = useSelector(
    homeSelectors.getHomeTopSalleLoading,
  );
  const error = useSelector(homeSelectors.getErrorOneС);

  const { item, data, loading, errorOneC } = useMemo(() => {
    let loading;
    let data = [];
    let item = {};
    let errorOneC = false;

    switch (key) {
      case 'top_sale':
        data = dataHomeTopSale;
        loading = getHomeTopSalleLoading;
        item = data[count];

        break;
      case 'buys_most_often':
        data = dataBuysMostOften;
        loading = getBuysMostOftenLoading;
        errorOneC = error.homeBuysMostOftenError;
        if (activeTab) {
          item = data[activeTab][count];
        }
        break;
      default:
        break;
    }

    return { data, loading, item, errorOneC };
  }, [
    count,
    dataBuysMostOften,
    dataHomeTopSale,
    getBuysMostOftenLoading,
    getHomeTopSalleLoading,
    key,
    activeTab,
    error,
  ]);

  const dispatch = useDispatch();

  useEffect(() => {
    switch (key) {
      case 'top_sale':
        dispatch(homeOperation.getHomeTopSale());
        break;

      case 'buys_most_often':
        dispatch(homeOperation.getHomeBuysMostOften());
        break;

      default:
        break;
    }
  }, []);

  return { data, item, loading, errorOneC };
};
