import {
  NavLink,
  useParams,
} from '../../../../node_modules/react-router-dom/dist/index';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import styled from 'styled-components';

import { handleDispatchFetch } from '@root/helpers/handleDispatchFetch';
import { getErrorStack, validEmail } from '@root/helpers/validation';
import authOperation from '@root/redux/auth/auth-operation';
import { ButtonBase } from '@root/ui/Button/ButtonBase/index';
import { MessageError } from '@root/ui/Error/Message/index';
import { TextInput } from '@root/ui/Input/TextInput/index';

import { useTheme } from '@mui/material/styles';

import { Typography } from '../../../../node_modules/@mui/material/index';
import moment from '../../../../node_modules/moment/moment';

const PasswordResetForm = () => {
  const { lang } = useParams();
  const { color } = useTheme();
  const { t } = useTranslation(['password_reset', 'validation']);
  const [data, setData] = useState({
    email: '',
  });
  const [textError, setTextError] = useState('');
  const currentLang = lang ? `/${lang}` : '';
  const [error, setError] = useState({});
  const [success, setSuccess] = useState(false);
  const [secondSuccess, setSecondSuccess] = useState(false);
  const [isCountdown, setCountdown] = useState(false);
  const [countdownTime, setCountdownTime] = useState({
    minutes: '00',
    seconds: '00',
  });

  const dispatch = useDispatch();

  const onChange = name => value => {
    setData(prevState => ({ ...prevState, [name]: value }));
  };

  const validation = () => {
    let flag = false;
    let currentError = {};

    const { email } = data;

    Object.keys({
      email,
    }).forEach(key => {
      if (!data[key] || data[key] === '') {
        currentError = getErrorStack(
          currentError,
          key,
          t('validation:required'),
        );
        flag = true;
      }
    });

    if (validEmail(email) && email !== '') {
      currentError = getErrorStack(
        currentError,
        'email',
        t('validation:incorrect_email'),
      );
      flag = true;
    }
    setError(currentError);
    return flag;
  };

  const handelSubmit = async e => {
    if (e?.preventDefault) e?.preventDefault();
    if (validation()) return;

    handleDispatchFetch(
      ({ onResolve, onReject }) =>
        dispatch(
          authOperation.passwordReset({
            data,
            onResolve,
            onReject,
          }),
        ),
      e => {
        setSuccess(true);
      },
      ({ message }) => {
        setError({
          email: { init: true, text: '' },
        });
        setTextError(message);
      },
    );
  };

  const startCountdown = () => {
    handelSubmit();
    setSecondSuccess(true);
    setCountdown(true);
    const endTime = moment().add(1, 'minute');

    const id = setInterval(() => {
      const now = moment();
      const duration = moment.duration(endTime.diff(now));
      if (duration._milliseconds === 0 || duration._milliseconds < 0) {
        clearInterval(id);
        setCountdown(false);
        return;
      }
      setCountdownTime({
        minutes: duration.minutes(),
        seconds: duration.seconds(),
      });
    }, 1000);
  };

  const { email } = data;
  return (
    <>
      <Form autoComplete="on" onSubmit={handelSubmit}>
        <Typography variant={'h1'}>{t('title')}</Typography>
        {!success && (
          <>
            <Typography
              sx={{ mt: '28px' }}
              variant={'bodyLarge'}
              component={'p'}
            >
              {t('slogan')}
            </Typography>
            <Row>
              <TextInput
                error={error?.email?.init}
                errorText={error?.email?.text}
                sx={{ mt: '28px', width: '100%', height: '48px' }}
                value={email}
                onChange={onChange('email')}
                placeholder={'Email*'}
                type="text"
                withOutSpace={true}
                data-test-id="Email"
                autoComplete={'email'}
                inputProps={{
                  maxLength: 255,
                }}
              />
            </Row>

            {textError && (
              <MessageError
                errorText={textError}
                sx={{
                  mt: '16px',
                  mr: 'auto',
                  position: 'relative',
                  top: '16px',
                  fontSize: '0.75rem',
                  whiteSpace: 'nowrap',
                }}
              />
            )}

            <ButtonBase
              type="submit"
              size={'large'}
              sx={{ mt: '28px', width: '100% ' }}
            >
              <Typography variant={'bodyBoldLarge'}>
                {' '}
                {t('sendBtnText')}
              </Typography>
            </ButtonBase>

            <BotLinksWrap>
              <NavLink to={currentLang + '/login'}>
                <Typography
                  variant={'bodyBoldMedium'}
                  sx={{ color: color.main, ml: '4px' }}
                  component={'p'}
                >
                  {t('recallPass')}
                </Typography>
              </NavLink>
              <NavLink to={currentLang + '/password-reset'}>
                <Typography
                  variant={'bodyBoldMedium'}
                  sx={{ color: color.main, ml: '4px' }}
                  component={'p'}
                >
                  {t('anotherLogin')}
                </Typography>
              </NavLink>
            </BotLinksWrap>
          </>
        )}
        {success && (
          <>
            {!secondSuccess && (
              <Typography
                sx={{ mt: '28px' }}
                variant={'bodyLarge'}
                component={'p'}
              >
                {t('success_slogan')}
              </Typography>
            )}
            {secondSuccess && (
              <>
                <Typography
                  sx={{ mt: '28px' }}
                  variant={'bodyLarge'}
                  component={'p'}
                >
                  {t('second_success_slogan1')}
                </Typography>
                <Typography
                  sx={{ mt: '28px' }}
                  variant={'bodyLarge'}
                  component={'p'}
                >
                  {t('second_success_slogan2')}
                </Typography>
              </>
            )}
            <Typography
              variant={'bodyBoldMedium'}
              sx={{
                color: color.main,
                ml: '4px',
                mt: '28px',
                alignSelf: 'flex-start',
              }}
              component={'p'}
            >
              {email}
            </Typography>
            <BotLinksWrap>
              {!isCountdown ? (
                <StartCountdown
                  onClick={() => {
                    startCountdown();
                  }}
                >
                  <Typography
                    variant={'bodyBoldMedium'}
                    sx={{ color: color.main, ml: '4px' }}
                    component={'p'}
                  >
                    {t('mail_not_found')}
                  </Typography>
                </StartCountdown>
              ) : (
                <Typography
                  variant={'bodyBoldMedium'}
                  sx={{ color: color.main, ml: '4px' }}
                  component={'p'}
                >
                  {countdownTime.minutes}:{countdownTime.seconds}
                </Typography>
              )}
            </BotLinksWrap>
          </>
        )}
      </Form>
    </>
  );
};

export default PasswordResetForm;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 103px;
  flex-grow: 1;
`;
const Row = styled.div`
  width: 100%;
`;

const BotLinksWrap = styled.div`
  flex-grow: 1;
  display: flex;
  justify-content: space-around;
  align-items: flex-end;
  a {
    margin: 0 10px;
  }
`;

const StartCountdown = styled.button`
  background-color: none;
  border: none;
  cursor: pointer;
`;
