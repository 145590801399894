import { useEffect, useRef } from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';

export const ModalLiqPay = ({ onlineForm }) => {
  const refLiqPay = useRef();

  useEffect(() => {
    goToLiqPay();
  }, [refLiqPay]);

  function goToLiqPay() {
    refLiqPay.current.children[0].submit();
  }

  return (
    <HideForm
      id="payPal"
      ref={refLiqPay}
      dangerouslySetInnerHTML={{
        __html: onlineForm ? onlineForm : '',
      }}
    ></HideForm>
  );
};

const HideForm = styled.div`
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  padding: 0;
  white-space: nowrap;
  clip-path: inset(100%);
  clip: rect(0 0 0 0);
  overflow: hidden;
`;

ModalLiqPay.propTypes = {
  onlineForm: PropTypes.node,
};
