import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import {
  CharacteristicsInfo,
  HeaderDefault,
  ListSimilarProducts,
  ListWarehouses,
  ProductSlider,
  ZoomProductSlider,
} from '@root/components';
import { CardDetailsConf } from '@root/conf';
import { useNavigation } from '@root/hooks';
import { IconBtn, ModalBase, NavigationButton } from '@root/ui';
import { EllipsisText } from '@root/ui/EllipsisText/index';

import cartOperation from '@redux/cart/cart-operation';
import cartSelectors from '@redux/cart/cart-selectors';

import { CircularProgress, Divider, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import { getProductById } from './api/getProductById';

export const CardDetails = ({
  id,
  handelOpenSavedModal,
  addOrRemoveFromComparisons,
  aboutOnly = false,
}) => {
  const [data, setData] = useState([]);
  const [navSection, setNavSection] = useState('about');
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const { navigation } = useNavigation();
  const cartData = useSelector(cartSelectors.getCartData);
  const [relatedScoop, setRelatedScoop] = useState(false);
  const [analoguesScoop, setAnaloguesScoop] = useState(false);
  const [openCard, setOpenCard] = useState(false);
  const [choseIdCard, setChoseIdCard] = useState(null);
  const { color } = useTheme();

  const { t } = useTranslation(['catalog', 'common'], { useSuspense: false });

  useEffect(() => {
    getProduct(id);
  }, [dispatch, id, cartData]);

  const getProduct = async id => {
    try {
      const { data } = await getProductById(id);
      setData(data.data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    setOpenCard(false);
    setChoseIdCard(null);
  }, [navSection]);

  const toggleOpenModal = () => {
    setOpen(!open);
  };

  const handelDetailCard = id => {
    if (choseIdCard !== id) {
      setOpenCard(true);
      setChoseIdCard(id);
    } else {
      setOpenCard(!openCard);
      setChoseIdCard(openCard ? null : id);
    }
  };

  const handleChoseItem = (param, quantity) => {
    dispatch(
      cartOperation.addProductToCart({
        id: param.id,
        quantity: quantity ? quantity : 1,
      }),
    );
  };

  const getMarckUp = () => {
    switch (navSection) {
      case 'about':
        return (
          <WrapperFlex>
            <SliderWrapper>
              {data?.images.length && (
                <ProductSlider
                  images={data?.images}
                  handelModal={toggleOpenModal}
                />
              )}
            </SliderWrapper>

            <DescriptionWrapper>
              <Typography variant={'bodyBoldSmall'} component={'h3'}>
                {t('common:product_description')}
              </Typography>
              {data?.description && (
                <Typography
                  sx={{ mt: '8px' }}
                  variant={'bodySmall'}
                  component={'p'}
                >
                  {/* <EllipsisText text={data?.description} length={'500'} /> */}
                  <EllipsisText text={data?.description} length={'500'} />
                </Typography>
              )}
              <Typography
                sx={{
                  mt: '4px',
                  color: color.main,
                  textDecoration: 'underline',
                  cursor: 'pointer',
                }}
                variant={'bodySmall'}
                component={'p'}
                onClick={() => navigation(data?.url)}
              >
                {t('common:buttons.read_all')}
              </Typography>
              {data?.brand && (
                <Typography
                  sx={{ mt: '10px' }}
                  variant={'bodyBoldSmall'}
                  component={'h3'}
                >
                  {t('common:brand')}:{' '}
                  <Typography variant={'bodySmall'} component={'span'}>
                    {data?.brand}
                  </Typography>
                </Typography>
              )}

              {data?.property_categories.length > 0 && (
                <>
                  <CharacteristicsWrapper>
                    <Typography
                      variant={'bodyBoldSmall'}
                      sx={{}}
                      component={'p'}
                    >
                      {t('common:characteristics')}
                    </Typography>

                    {/* TODO: CharacteristicsInfo FIX */}
                    {/* <CharacteristicsInfo
                      data={data?.property_categories[0]}
                      hiddenTittle={true}
                      width={'400px'}
                      limit={aboutOnly ? 8 : 10}
                    /> */}
                  </CharacteristicsWrapper>

                  <Typography
                    sx={{
                      mt: '8px',
                      color: color.main,
                      textDecoration: 'underline',
                      cursor: 'pointer',
                    }}
                    variant={'bodySmall'}
                    component={'p'}
                    onClick={() => navigation(data?.url)}
                  >
                    {t('common:buttons.view_all_characteristics')}
                  </Typography>
                </>
              )}
            </DescriptionWrapper>
          </WrapperFlex>
        );

      case 'related_products':
        return (
          <ListSimilarProducts
            data={data?.related_products}
            handelOpenSavedModal={handelOpenSavedModal}
            addOrRemoveFromComparisons={addOrRemoveFromComparisons}
            isOpen={relatedScoop}
            productsCategory={t('common:related')}
            openCard={openCard}
            choseIdCard={choseIdCard}
            handelDetailCard={handelDetailCard}
            Scoop={relatedScoop}
            handleChoseItem={handleChoseItem}
            setScoop={setRelatedScoop}
          />
        );
      case 'analogues':
        return (
          <ListSimilarProducts
            data={data?.analogue_products}
            isOpen={analoguesScoop}
            handelOpenSavedModal={handelOpenSavedModal}
            addOrRemoveFromComparisons={addOrRemoveFromComparisons}
            productsCategory={t('common:analogues')}
            openCard={openCard}
            choseIdCard={choseIdCard}
            handelDetailCard={handelDetailCard}
            Scoop={analoguesScoop}
            handleChoseItem={handleChoseItem}
            setScoop={setAnaloguesScoop}
          />
        );
      case 'warehouses':
        return (
          <ListWarehousesWrap>
            <ListWarehouses data={data} />
          </ListWarehousesWrap>
        );
      case 'discounted_products':
        return <></>;
      case 'buy_together':
        return <></>;
      default:
        break;
    }
  };

  const getMarkupNavigation = item => {
    return (
      <NavigationButton
        isActive={navSection === item.slug}
        onClick={() => setNavSection(item.slug)}
        key={item.slug}
      >
        {t(`catalog:product_details.tabs_name.${item.slug}`)}
      </NavigationButton>
    );
  };

  return (
    <>
      {Object.keys(data).length > 0 ? (
        <Wrapper>
          {CardDetailsConf(t).map(item => {
            if (aboutOnly && item.slug !== 'about') {
              return <></>;
            }
            switch (item.slug) {
              case 'related_products':
                if (data.related_products.length === 0) {
                  return <></>;
                } else {
                  return getMarkupNavigation(item);
                }
              case 'analogues':
                if (data.analogue_products.length === 0) {
                  return <></>;
                } else {
                  return getMarkupNavigation(item);
                }
              case 'warehouses':
                if (data.warehouses.length === 0) {
                  return <></>;
                } else {
                  return getMarkupNavigation(item);
                }
              default:
                return getMarkupNavigation(item);
            }
          })}
          <Divider />
          {getMarckUp()}
          <ModalBase
            open={open}
            handleClose={toggleOpenModal}
            modalHeader={
              <HeaderDefault
                text={data?.name}
                onClose={toggleOpenModal}
                sx={{
                  width: '1100px',
                }}
              />
            }
          >
            <ZoomProductSlider images={data?.images} />
          </ModalBase>
        </Wrapper>
      ) : (
        <LoaderWrapper>
          <Container>
            <CircularProgress />
          </Container>
        </LoaderWrapper>
      )}
    </>
  );
};

const Wrapper = styled.div`
  width: 100%;
`;

const WrapperFlex = styled.div`
  display: flex;
  padding: 24px 20px;
`;

const DescriptionWrapper = styled.div`
  margin-left: 20px;
`;

const CharacteristicsWrapper = styled.div`
  margin-top: 10px;
  width: 100%;
  max-height: 300px;
`;

const SliderWrapper = styled.div`
  min-width: 160px;
  max-height: 190px;
`;

const LoaderWrapper = styled.div`
  width: 100%;
  margin: 50px auto;
`;

const Container = styled.div`
  width: fit-content;
  margin: 0 auto;
`;

const ListWarehousesWrap = styled.div`
  padding: 24px 20px;
`;

CardDetails.propTypes = {
  id: PropTypes.number.isRequired,
  aboutOnly: PropTypes.bool,
  handelOpenSavedModal: PropTypes.func.isRequired,
  addOrRemoveFromComparisons: PropTypes.func.isRequired,
};
