import React from 'react';
import { useTranslation } from 'react-i18next';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import { RadioGroup, Typography, useTheme } from '@mui/material';

import { RadioItem } from '../../RadioItem';
import { NovaPoshta } from './NovaPoshta';
import { ViatecDelivery } from './Viatec';

export const DeliveryClientInfo = ({
  handleCardRadio,
  onChange,
  deliveryType,
  conf,
  data,
  setData,
  error,
  confBackEnd,
}) => {
  const { color } = useTheme();

  const { t } = useTranslation(['placing_order'], { useSuspense: false });

  const isDeliveryType =
    deliveryType === 'Nova_Poshta_branch' || deliveryType === 'Address';
  const { items } = confBackEnd;

  return (
    <Wrap>
      {conf.map(
        el =>
          el.value === deliveryType && (
            <DeliveryTypeWrap>
              <Typography
                variant={'bodyBoldMedium'}
                sx={{ color: color.gray_80 }}
                component={'h2'}
              >
                {t('placing_order:fields.select_operator')}
              </Typography>
              <RadioGroup
                row
                aria-labelledby="demo-radio-buttons-group-label"
                name="radio-buttons-group"
                value={data.delivery_info.type}
                onChange={e => handleCardRadio(e.target.value)}
              >
                {items.map(nestedItem => {
                  const chooseEl = el.data.find(
                    el => el.key === nestedItem.key,
                  );
                  return <RadioItem conf={chooseEl} data={nestedItem} />;
                })}
              </RadioGroup>
            </DeliveryTypeWrap>
          ),
      )}
      {isDeliveryType && (
        <>
          <NovaPoshta
            error={error}
            data={data}
            setData={setData}
            onChange={onChange}
            deliveryType={deliveryType}
          />
          {data.delivery_info.type === 'viatec_courier' &&
            deliveryType === 'Address' && (
              <ViatecDelivery
                error={error}
                data={data}
                setData={setData}
                onChange={onChange}
                deliveryType={deliveryType}
              />
            )}
        </>
      )}
    </Wrap>
  );
};

const Wrap = styled.div``;

const DeliveryTypeWrap = styled.div``;

DeliveryClientInfo.propTypes = {
  handleCardRadio: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  deliveryType: PropTypes.string,
  conf: PropTypes.arrayOf(
    PropTypes.exact({
      data: PropTypes.arrayOf(
        PropTypes.exact({
          isCustomRadioBtn: PropTypes.bool,
          key: PropTypes.string,
          labelStyle: PropTypes.exact({
            marginLeft: PropTypes.string,
            position: PropTypes.string,
          }),
          radioStyle: PropTypes.exact({
            left: PropTypes.string,
            padding: PropTypes.number,
            position: PropTypes.string,
            top: PropTypes.string,
          }),
          src: PropTypes.string,
          subTitle: PropTypes.string,
          title: PropTypes.string,
        }),
      ),
      id: PropTypes.number,
      value: PropTypes.string,
    }),
  ),
  data: PropTypes.exact({
    delivery_filial: PropTypes.string,
    delivery_info: PropTypes.exact({
      city_ref: PropTypes.string,
      city_title: PropTypes.string,
      date: PropTypes.string,
      department_ref: PropTypes.string,
      department_title: PropTypes.string,
      elevator: PropTypes.bool,
      flat_num: PropTypes.string,
      floor_num: PropTypes.string,
      npPhone: PropTypes.string,
      post_office_ref: PropTypes.string,
      post_office_title: PropTypes.string,
      street_number: PropTypes.string,
      street_title: PropTypes.string,
      time: PropTypes.string,
      type: PropTypes.string,
    }),
    delivery_type: PropTypes.string,
    discount: PropTypes.string,
    dont_call: PropTypes.bool,
    id: PropTypes.number,
    its_drop: PropTypes.bool,
    middle_name: PropTypes.string,
    name: PropTypes.string,
    payment_type: PropTypes.string,
    phone: PropTypes.string,
    surname: PropTypes.string,
  }),
  setData: PropTypes.func.isRequired,
  error: PropTypes.object,
  confBackEnd: PropTypes.arrayOf(
    PropTypes.exact({
      id: PropTypes.number,
      items: PropTypes.arrayOf(
        PropTypes.exact({
          icon: PropTypes.string,
          id: PropTypes.number,
          isDefault: PropTypes.bool,
          key: PropTypes.string,
          subTitle: PropTypes.string,
          title: PropTypes.string,
        }),
      ),
      key: PropTypes.string,
      label: PropTypes.string,
      subTitle: PropTypes.string,
      title: PropTypes.string,
    }),
  ),
};
