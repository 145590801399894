import PropTypes from 'prop-types';

import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';

const ButtonWithBottomLine = styled(Button)(
  ({ theme, size, variant, isActive }) => `
&.nav-btn{
    color: ${theme.color.gray};
    text-align: center;
    font-weight: 700;
    font-size: 0.75rem;
    font-family: Roboto;
    padding: 8px 28px;
    border-bottom: ${isActive ? `3px solid ${theme.color.main}` : 'none'};
    border-radius: 0;
    text-transform: none;

    &:disabled{
      color: ${theme.color.gray_60};
      background: ${theme.color.gray_20};
    }

    @media(max-width: 1199px) {
      padding: 8px 12px;
    }
}`,
);

export const NavigationButton = ({
  children,
  onClick,
  sx,
  isActive,
  disabled = false,
  onMouseDown,
}) => {
  return (
    <ButtonWithBottomLine
      classes={{ root: 'nav-btn' }}
      isActive={isActive}
      onClick={onClick}
      sx={{ ...sx }}
      disabled={disabled}
      onMouseDown={onMouseDown}
    >
      {children}
    </ButtonWithBottomLine>
  );
};

NavigationButton.propTypes = {
  children: PropTypes.node,
  onClick: PropTypes.func.isRequired,
  sx: PropTypes.object,
  isActive: PropTypes.bool,
  disabled: PropTypes.bool,
};
