import { useState } from 'react';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import { useTranslation } from 'react-i18next';

import axios from 'axios';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Sprite } from '@root/assets/svg';
import { theme } from '@root/styles';
import { ButtonWithoutBorder } from '@root/ui';
import { TextInput } from '@root/ui/Input/TextInput/index';

import { Typography, useTheme } from '@mui/material';
import {useSelector} from "react-redux";

export const CharacteristicsInfo = ({
  data = [],
  blockIndex,
  width = '100%',
  hiddenTittle,
  limit,
  isEdit,
  editBlockValue,
  deleteBlock,
  onTogglePin,
  editPropIndex,
  onEditBtnClick,
  dragHandleProps,
  onBlockDelete,
  onBlockNameChange,
}) => {
  const { t } = useTranslation(['common']);
  //TODO a temporary solution, there is no ready-made category editor yet
  const arrInLimit = limit ? data.properties.slice(0, limit) : data.properties;
  const { color } = useTheme();

  const [opts, setOpts] = useState([]);
  const [lastQuery, setLastQuery] = useState('');
  const [timerId, setTimerId] = useState(null);

  const windowWith = useSelector(state => state?.windowWithSlice?.windowWith)

  const fetchOpts = async (prop, query) => {
    if (!query || query === lastQuery) return;
    try {
      setLastQuery(query);
      const {
        data: { data },
      } = await axios.get(`/properties/values/${prop.id}?search=${query}`);
      if (data?.length) setOpts(data);
    } catch (e) {
      throw new Error(e);
    }
  };

  const onChange = (value, idx, prop) => {
    editBlockValue(blockIndex, idx, value);
    window.clearTimeout(timerId);
    setTimerId(
      window.setTimeout(() => {
        if (value.length > 3) {
          fetchOpts(prop, value);
        }
      }, 1000),
    );
  };

  const isEditing = propId => {
    return (
      +editPropIndex?.blockIndex === +blockIndex &&
      +editPropIndex?.propId === +propId
    );
  };
  const isNameEditing = () => {
    return (
      +editPropIndex?.blockIndex === +blockIndex &&
      editPropIndex?.propId === 'blockName'
    );
  };

  const onNameEditClick = () => {
    if (
      +editPropIndex?.blockIndex === +blockIndex &&
      editPropIndex?.propId === 'blockName'
    )
      onEditBtnClick(blockIndex, null);
    else onEditBtnClick(blockIndex, 'blockName');
  };

  return (
    <CharacteristicsBlock>
      {!hiddenTittle && (
        <BlockTitleWrp>
          <Typography
            variant={'bodyBoldLarge'}
            sx={{
              marginRight: windowWith > 1199 ?'8px' : '0',
              ml: windowWith > 1199 ?'28px' : '0',
              color: theme.color.gray,
            }}
          >
            {blockIndex !== 0 && (
              <div
                {...dragHandleProps}
                style={{
                  display: 'inline-block',
                  marginRight: '4px',
                  marginTop: '2px',
                  transform: 'translate(0,2px)',
                }}
              >
                {isEdit && (
                  <svg width={16} height={16} fill={'#8094AE'}>
                    <use href={`${Sprite}#item-marker`}></use>
                  </svg>
                )}
              </div>
            )}
            {isNameEditing() ? (
              <TextInput
                value={data?.name || ''}
                onChange={val => {
                  onBlockNameChange(val, blockIndex);
                }}
                styleWrapper={{ display: 'inline-block' }}
              />
            ) : (
              data?.name
            )}
          </Typography>
          {isEdit && (
            <>
              <svg
                onClick={onNameEditClick}
                width={14}
                height={14}
                fill={'#8094AE'}
                style={{ marginRight: '5px', cursor: 'pointer' }}
              >
                <use href={`${Sprite}#edit-pen`}></use>
              </svg>
              {blockIndex !== 0 && (
                <svg
                  width={18}
                  height={18}
                  fill={'#8094AE'}
                  style={{ cursor: 'pointer' }}
                  onClick={onBlockDelete}
                >
                  <use href={`${Sprite}#icon-delete`}></use>
                </svg>
              )}
            </>
          )}
        </BlockTitleWrp>
      )}
      <Droppable droppableId={`prop-${blockIndex}`} type={`prop-${blockIndex}`}>
        {provided => (
          <div
            {...provided.droppableProps}
            ref={provided.innerRef}
            style={{ width: '100%' }}
          >
            {arrInLimit.map((el, idx) => (
              <Draggable
                draggableId={`prop${blockIndex}-${el.id}`}
                key={`prop${el.id}`}
                index={idx}
              >
                {provided => (
                  <Item
                    {...provided.draggableProps}
                    ref={provided.innerRef}
                    width={width}
                    color={theme.color}
                    isEdit={isEditing(el.id)}
                  >
                    <NameWrp>
                      <div {...provided.dragHandleProps}>
                        {isEdit && (
                          <svg width={16} height={16} fill={'#8094AE'}>
                            <use href={`${Sprite}#item-marker`}></use>
                          </svg>
                        )}
                      </div>

                      <Typography
                        variant={'bodySmall'}
                        sx={{
                          color: theme.color.gray,
                          fontSize: '14px',
                          marginLeft: windowWith > 1199 ? '8px' : '0',
                        }}
                      >
                        {el.name}
                      </Typography>
                    </NameWrp>
                    <ValWrp>
                      {isEditing(el.id) ? (
                        <TextInput
                          value={el?.value || ''}
                          onChange={val => {
                            onChange(val, idx, el);
                          }}
                          sx={{
                            maxWidth: '200px',
                            padding: '0 0 0 2px',
                            height: '18px',
                            '*': {
                              border: 'none',
                            },
                          }}
                          styleWrapper={{ height: '18px' }}
                          tips={opts}
                          placeholder={t('common:table.table_heads.value')}
                        />
                      ) : (
                        <Typography
                          variant={'bodySmall'}
                          sx={{
                            color: theme.color.gray,
                            fontSize: '14px',
                          }}
                        >
                          {el?.value || ''}
                        </Typography>
                      )}
                    </ValWrp>
                    {isEdit && (
                      <EditPanel>
                        <ButtonWithoutBorder
                          onClick={() => {
                            onEditBtnClick(blockIndex, el.id);
                          }}
                          sx={{
                            color: theme.color.gray_60,
                            display: 'flex',
                            width: '16px',
                            height: '16px',
                            minWidth: '16px',
                            alignItems: 'center',
                            justifyContent: 'flex-start',
                            marginLeft: '8px',
                            padding: '0',
                            background: 'none',
                            '&:hover': {
                              background: 'none',
                            },
                          }}
                        >
                          <svg
                            width={13}
                            height={13}
                            stroke={
                              isEditing(el.id) ? theme.color.main : '#8094AE'
                            }
                          >
                            <use href={`${Sprite}#icon-edit`}></use>
                          </svg>
                        </ButtonWithoutBorder>
                        <ButtonWithoutBorder
                          onClick={() => deleteBlock(blockIndex, idx)}
                          sx={{
                            color: theme.color.gray_60,
                            display: 'flex',
                            width: '16px',
                            height: '16px',
                            minWidth: '16px',
                            alignItems: 'center',
                            marginLeft: '8px',
                            justifyContent: 'flex-start',
                            padding: '0',
                            background: 'none',
                            '&:hover': {
                              background: 'none',
                            },
                          }}
                        >
                          <svg width={12.5} height={13.75} fill={'#8094AE'}>
                            <use href={`${Sprite}#trash-box`}></use>
                          </svg>
                        </ButtonWithoutBorder>
                        <ButtonWithoutBorder
                          onClick={() => {
                            onTogglePin(blockIndex, idx);
                          }}
                          sx={{
                            color: theme.color.gray_60,
                            display: 'flex',
                            width: '16px',
                            height: '16px',
                            minWidth: '16px',
                            marginLeft: '8px',
                            alignItems: 'center',
                            justifyContent: 'flex-start',
                            padding: '0',
                            background: 'none',
                            '&:hover': {
                              background: 'none',
                            },
                          }}
                        >
                          <svg
                            width={13.5}
                            height={13.5}
                            stroke={el.is_pinned ? color.main : 'none'}
                          >
                            <use href={`${Sprite}#pin-icon`}></use>
                          </svg>
                        </ButtonWithoutBorder>
                      </EditPanel>
                    )}
                  </Item>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </CharacteristicsBlock>
  );
};

const ValWrp = styled.div`
  flex: 1;
`;

const Input = styled.input(() => ({
  width: '100%',
  color: theme.color.gray_80,
  fontWeight: '400',
  fontSize: '14px',
  marginLeft: '8px',
}));

const EditPanel = styled.div(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
}));

const Item = styled.div`
  background-color: #fff;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px 0;
  border-bottom: 1px solid ${props => props.color.line};
  width: ${({ width }) => width};
  margin-left: 28px;
  /* height: 36px; */
  * {
    color: ${({ isEdit, color }) =>
      isEdit ? `${color.main} !important` : 'unset'};
  }

  @media(max-width: 1199px) {
    margin-left: 0;
  }
`;

const CharacteristicsBlock = styled.div`
  background-color: #fff;
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  flex-wrap: wrap;
  max-height: inherit;
  margin-left: -28px;

  @media(max-width: 1199px) {
    margin-left: 0;
  }
`;

const NameWrp = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
`;

const BlockTitleWrp = styled.div`
  display: flex;
  align-items: center;
  margin-top: 24px;
  margin-bottom: 16px;
`;

CharacteristicsInfo.propTypes = {
  data: PropTypes.exact({
    id: PropTypes.number,
    name: PropTypes.string,
    properties: PropTypes.array,
  }),
  width: PropTypes.string,
  hiddenTittle: PropTypes.bool,
  limit: PropTypes.number,
};
