import specGuideOperations from "./spec-guide-operations";
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  loading: false,

  categorys: [],
  subCategorys: [],

  currentSubCategory: null,

  specItemsLoading: false,
  specItems: [],
  isDirty: false,
  autocompleteItems: [],

  error: null
};

const specGuide = createSlice({
  name: 'spec-guide',
  initialState,
  reducers: {
    clearSubCategorys(state, { payload }) {
      state.subCategorys = [];
    },
    setCurrentSubCategory(state, { payload }) {
      state.currentSubCategory = payload
    },
    setSpecItems(state, { payload }) {
      state.specItems = payload;
      state.isDirty = true;
    }
  },
  extraReducers: {
    //=====getCategorys=====//
    [specGuideOperations.getCategorys.pending]: (state, action) => {
      state.loading = true;
    },
    [specGuideOperations.getCategorys.fulfilled]: (state, { payload }) => {
      state.categorys = payload;
      state.loading = false;
    },
    [specGuideOperations.getCategorys.rejected]: (state, { payload }) => {
      state.error = payload;
      state.loading = false;
    },
    //=====getSubCategorys=====//
    [specGuideOperations.getSubCategorys.pending]: (state, action) => {
      state.loading = true;
    },
    [specGuideOperations.getSubCategorys.fulfilled]: (state, { payload }) => {
      state.subCategorys = payload;
      state.loading = false;
    },
    [specGuideOperations.getSubCategorys.rejected]: (state, { payload }) => {
      state.error = payload;
      state.loading = false;
    },
    //=====getSpescByCategoryId=====//
    [specGuideOperations.getSpescByCategoryId.pending]: (state, action) => {
      state.specItemsLoading = true;
    },
    [specGuideOperations.getSpescByCategoryId.fulfilled]: (state, { payload }) => {
      state.specItems = payload.map(block => {
        return {
          ...block,
          dndId: Math.random(),
          properties: block.properties
            .map(prop => {
              return { ...prop, dndId: Math.random() }
            })
        }
      });
      state.isDirty = false;
      state.specItemsLoading = false;
    },
    [specGuideOperations.getSpescByCategoryId.rejected]: (state, { payload }) => {
      state.error = payload;
      state.specItemsLoading = false;
    },
    //=====getAutoComplete=====//
    [specGuideOperations.storeSpecs.pending]: (state, action) => {
      state.specItemsLoading = true;
    },
    [specGuideOperations.storeSpecs.fulfilled]: (state, { payload }) => {
      state.specItems = payload;
      state.isDirty = false;
      state.specItemsLoading = false;
    },
    [specGuideOperations.storeSpecs.rejected]: (state, { payload }) => {
      state.error = payload;
      state.specItemsLoading = false;
    },
  },
});
export const { clearSubCategorys, setCurrentSubCategory, setSpecItems } = specGuide.actions;
export default specGuide.reducer;