
export const findCategoryObjectById = (category, itemCategoryId) => {
    let result = {}
    // find parent category object
    let parentObject = category.find(obj => {
        // find child object with id
        let child = Object.values(obj.children).find(child => child.id === itemCategoryId);
        // if child object found, return parent and child objects
        if (child) {

            result = { parentObject: obj, childObject: child }
            return result;
        }
        // if child object not found return null
        return null;
    });

    // if child in parent object not found
    if (!parentObject) {
        let parentObjectWithoutChildren = category.find(obj => obj.id === itemCategoryId)
        result = {parentObjectWithoutChildren: parentObjectWithoutChildren}
        return result
    }

    return result
    // return parentObject;
}
