import React, { useContext, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  AutoSizer,
  CellMeasurer,
  CellMeasurerCache,
  List,
} from 'react-virtualized';

import styled from 'styled-components';

import {
  Balance,
  Documents,
  Empty,
  Header,
  LabelDocument,
  VirtualizedTable,
} from '@root/components';
import { AccountingDocumentsConf } from '@root/conf';
import { formatDate } from '@root/helpers';
import { WidthContext } from '@root/providers/WidthProvider';
import { ProgressLoader } from '@root/ui';

import accountingDocumentsSelectors from '@redux/accounting-documents/accounting-documents-selectors';
import {
  setSearchValue,
  sortAccountingDocumentsByDate,
} from '@redux/accounting-documents/accounting-documents-slice';

import { TableBody, TableCell, TableRow, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

export const AccountingDocumentsHistory = ({
  t,
  handleFetchAccountingDocuments,
  selectedDocument,
  handleSelectDocument,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [sortingOn, setSortingOn] = useState(false);
  const [sortDuration, setSortDuration] = useState(null);
  const [sortingType, setSortingType] = useState(null);

  const isAccountingDocumentsLoading = useSelector(
    accountingDocumentsSelectors.getIsAccountingDocumentsLoading,
  );

  const filteredAccountingDocuments = useSelector(
    accountingDocumentsSelectors.getFilteredAccountingDocuments,
  );

  const searchTerm = useSelector(accountingDocumentsSelectors.getSearchValue);

  const { color } = useTheme();

  const { screenWidth } = useContext(WidthContext);

  const dispatch = useDispatch();

  const windowWith = useSelector(state => state?.windowWithSlice?.windowWith);

  const cache = useRef(
    new CellMeasurerCache({
      fixedWidth: false,
      defaultHeight: 71,
    }),
  );

  const tableHeight = `calc(100vh - 227px)`;

  const handleToggleSearchExpended = () => {
    setIsExpanded(prevIsExpanded => !prevIsExpanded);
  };

  const onSearchTermUpdate = searchValue => {
    dispatch(setSearchValue(searchValue));
  };

  const tableBodyHeight = `calc(100vh - 297px)`;

  const handleGetDataBySort = () => {
    dispatch(sortAccountingDocumentsByDate());
  };

  const handleSorting = type => {
    switch (type) {
      case 'date': {
        handleGetDataBySort(type, sortDuration);
        setSortDuration(sortDuration === type ? null : type);
        break;
      }
      default: {
        break;
      }
    }
    setSortingType(type);
    setSortingOn(!sortingOn);
  };

  return (
    <AccountingDocumentsHistoryWrapper
      colorBorder={color.line}
      colorBg={color.white}
    >
      <Header
        isExpanded={isExpanded}
        handleFetchDeliveryHistory={handleFetchAccountingDocuments}
        handleToggleSearchExpended={handleToggleSearchExpended}
        isTopBorder={false}
        onSearchTermUpdate={onSearchTermUpdate}
        isBottomBorder={true}
      />
      <TableWrapper>
        {isAccountingDocumentsLoading && <ProgressLoader />}
        {filteredAccountingDocuments.length > 0 && (
          <VirtualizedTable
            tableConf={AccountingDocumentsConf}
            checkIsInclude={false}
            isTopBorder={false}
            headRowStyles={{
              padding: windowWith > 1300 ? '10px 16px' : '8px',
            }}
            sortDuration={sortDuration}
            sortingType={sortingType}
            handleSorting={handleSorting}
          >
            <TableBody
              sx={{
                height: tableBodyHeight,
                display: 'block',
              }}
            >
              <ScrollWrapper
                colors={{
                  gray: color.gray_40,
                  white: color.white,
                  line: color.line,
                }}
              >
                <AutoSizer>
                  {({ width, height }) => (
                    <List
                      width={width}
                      height={height}
                      rowHeight={cache.current.rowHeight}
                      defferedMeasurementCache={cache.current}
                      rowCount={filteredAccountingDocuments.length}
                      rowRenderer={({ key, index, style, parent }) => {
                        const data = filteredAccountingDocuments[index];

                        return (
                          <CellMeasurer
                            key={key}
                            cache={cache.current}
                            parent={parent}
                            columnIndex={0}
                            rowIndex={index}
                          >
                            <TableRow
                              sx={{
                                minHeight: '71px',
                                borderBottom: `1px solid ${color.line}`,
                                '&:last-child, &:last-child td': {
                                  borderBottom: 0,
                                },
                                userSelect: 'auto',
                                boxShadow: 'none',
                                th: {
                                  borderBottom: 'none',
                                },
                                display: 'flex',
                                alignItems: 'center',
                                padding: windowWith > 1300 ? '8px 16px' : '8px',
                                position: 'relative',
                              }}
                              style={style}
                            >
                              {data.status === 'Закрыт' && (
                                <Placeholder colorBg={color.gray_20} />
                              )}
                              {AccountingDocumentsConf(t, screenWidth).map(
                                delivery => {
                                  switch (delivery.slug) {
                                    case 'document':
                                      return (
                                        <TableCell
                                          sx={{
                                            borderBottom: 'none',
                                            ...delivery.style,
                                            p: 0,
                                          }}
                                          key={delivery.slug}
                                        >
                                          <LabelDocument
                                            number={data.number}
                                            seller={data.seller}
                                            buyer={data.buyer}
                                          />

                                          {screenWidth < 1701 && (
                                            <Typography
                                              variant="bodySmall"
                                              color={color.gray_80}
                                            >
                                              {formatDate(data.date)}
                                            </Typography>
                                          )}

                                          {screenWidth < 1401 && (
                                            <Typography
                                              variant="bodySmall"
                                              color={color.gray_80}
                                            >
                                              {data.status}
                                            </Typography>
                                          )}

                                          {screenWidth < 577 && (
                                            <Documents
                                              verticalAlign="start"
                                              t={t}
                                              docInvoiceImplUrl={
                                                data.doc_invoice_impl_url
                                              }
                                              docInvoiceUrl={
                                                data.doc_invoice_url
                                              }
                                              handleSelectDocument={(
                                                type,
                                                url,
                                              ) =>
                                                handleSelectDocument({
                                                  type,
                                                  url,
                                                  number: data.number,
                                                  date: data.date,
                                                })
                                              }
                                              selectedDocument={
                                                selectedDocument
                                              }
                                            />
                                          )}
                                        </TableCell>
                                      );

                                    case 'date':
                                      return (
                                        delivery.isDisplay && (
                                          <TableCell
                                            sx={{
                                              borderBottom: 'none',
                                              ...delivery.style,
                                              p: 0,
                                            }}
                                            key={delivery.slug}
                                          >
                                            <Typography
                                              variant="bodySmall"
                                              color={color.gray_80}
                                            >
                                              {formatDate(data.date)}
                                            </Typography>
                                          </TableCell>
                                        )
                                      );

                                    case 'status':
                                      return (
                                        delivery.isDisplay && (
                                          <TableCell
                                            sx={{
                                              borderBottom: 'none',
                                              ...delivery.style,
                                              p: 0,
                                            }}
                                            key={delivery.slug}
                                          >
                                            <Typography
                                              variant="bodySmall"
                                              color={color.gray_80}
                                            >
                                              {data.status}
                                            </Typography>
                                          </TableCell>
                                        )
                                      );

                                    case 'balance':
                                      return (
                                        delivery.isDisplay && (
                                          <TableCell
                                            sx={{
                                              borderBottom: 'none',
                                              ...delivery.style,
                                              p: 0,
                                            }}
                                            key={delivery.slug}
                                          >
                                            <Balance
                                              summAll={data.summ_all}
                                              summPaid={data.summ_paid}
                                              shipped={data.shipped}
                                            />
                                          </TableCell>
                                        )
                                      );

                                    case 'bill':
                                      return (
                                        delivery.isDisplay && (
                                          <TableCell
                                            sx={{
                                              borderBottom: 'none',
                                              ...delivery.style,
                                              p: 0,
                                            }}
                                            key={delivery.slug}
                                          >
                                            <Documents
                                              t={t}
                                              docInvoiceImplUrl={
                                                data.doc_invoice_impl_url
                                              }
                                              docInvoiceUrl={
                                                data.doc_invoice_url
                                              }
                                              handleSelectDocument={(
                                                type,
                                                url,
                                              ) =>
                                                handleSelectDocument({
                                                  type,
                                                  url,
                                                  number: data.number,
                                                  date: data.date,
                                                })
                                              }
                                              selectedDocument={
                                                selectedDocument
                                              }
                                            />
                                          </TableCell>
                                        )
                                      );
                                  }
                                },
                              )}
                            </TableRow>
                          </CellMeasurer>
                        );
                      }}
                    />
                  )}
                </AutoSizer>
              </ScrollWrapper>
            </TableBody>
          </VirtualizedTable>
        )}

        {searchTerm === '' && filteredAccountingDocuments.length === 0 && (
          <Empty type={'NoHistory'} />
        )}

        {searchTerm !== '' && filteredAccountingDocuments.length === 0 && (
          <Empty type={'EmptySearchLogo'} />
        )}
      </TableWrapper>
    </AccountingDocumentsHistoryWrapper>
  );
};

const AccountingDocumentsHistoryWrapper = styled.div`
  border-radius: 4px;
  border: ${({ colorBorder }) => `1px solid ${colorBorder}`};
  background: ${({ colorBg }) => colorBg};
  flex: 0 1 60%;

  @media (max-width: 1024px) {
    width: 100%;
    flex: 1 0 100%;
  }
`;

const ScrollWrapper = styled.div`
  overflow: hidden;
  height: inherit;
  max-height: inherit;

  div {
    ::-webkit-scrollbar {
      width: 5px;
    }

    ::-webkit-scrollbar-thumb {
      background: ${props => props.colors.gray};
      border-radius: 4px;
    }

    ::-webkit-scrollbar-track {
      background: ${props => props.colors.white};
      border-left: 1px solid ${props => props.colors.line};
    }
  }
`;

const TableWrapper = styled.div`
  position: relative;
`;

const Placeholder = styled.div`
  background: ${({ colorBg }) => colorBg};
  opacity: 0.4;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 800;
`;
