import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const getBanners = createAsyncThunk(
    'banners/getBanners',
    async ({ onResolve, onReject }, { rejectWithValue }) => {
        try {
            const { data: { data } } = await axios.get('advanced-banner');
            if (onResolve) onResolve()
            return data;
        } catch (error) {
            if (onReject) onReject()
            return rejectWithValue(error);
        }
    },
);
const addBanner = createAsyncThunk(
    'banners/addBanner',
    async ({ formData, onResolve, onReject }, { rejectWithValue }) => {
        try {
            const { data: { data } } = await axios.post('admin/advanced-banner', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data;'
                }
            });
            if (onResolve) onResolve()
            return data;
        } catch (error) {
            if (onReject) onReject()
            return rejectWithValue(error);
        }
    },
);
const deleteBanner = createAsyncThunk(
    'banners/deleteBanner',
    async ({ id, onResolve, onReject }, { rejectWithValue }) => {
        try {
            const { data: { data } } = await axios.delete(`admin/advanced-banner/${id}`);
            if (onResolve) onResolve()
            return data;
        } catch (error) {
            if (onReject) onReject()
            return rejectWithValue(error);
        }
    },
);
const updateBanner = createAsyncThunk(
    'banners/updateBanner',
    async ({ formData, id, onResolve, onReject }, { rejectWithValue }) => {
        try {
            const { data: { data } } = await axios.post(`admin/advanced-banner/${id}`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data;'
                }
            });
            if (onResolve) onResolve()
            return data;
        } catch (error) {
            if (onReject) onReject(error.response.data.errors)
            return rejectWithValue(error);
        }
    },
);
const updateBannerSort = createAsyncThunk(
    'banners/updateBannerSort',
    async ({ sorted, onResolve, onReject }, { rejectWithValue }) => {
        try {
            const { data: { data } } = await axios.post(`admin/advanced-banner/sort`, { sorted });
            if (onResolve) onResolve()
            return data;
        } catch (error) {
            if (onReject) onReject(error.response.data.errors)
            return rejectWithValue(error);
        }
    },
);
const handleClickBanner = createAsyncThunk(
    'banners/handleClickBanner',
    async ({ id, onResolve, onReject }, { rejectWithValue }) => {
        try {
            const { data: { data } } = await axios.post(`advanced-banner/${id}/click`);
            if (onResolve) onResolve()
            return data;
        } catch (error) {
            if (onReject) onReject(error.response.data.errors)
            return rejectWithValue(error);
        }
    },
);

export default {
    getBanners,
    addBanner,
    deleteBanner,
    updateBanner,
    updateBannerSort,
    handleClickBanner,
};
