import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import styled from 'styled-components';

import { Sprite } from '@root/assets/svg';

import userSelectors from '@redux/user/user-selectors';

import { Typography, useTheme } from '@mui/material';

import { Popover } from '../../../node_modules/@mui/material/index';

export const BonusWidjet = () => {
  const userBonusDetails = useSelector(userSelectors.getUserProfile).bonuses;
  const { color } = useTheme();
  const { t } = useTranslation('header', { useSuspense: false });
  const permissions = useSelector(userSelectors.getPermissions);

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const hex2rgba = (hex, a) =>
    `rgba(${hex
      .substr(1)
      .match(/../g)
      .map(x => +`0x${x}`)},${a})`;

  return (
    <>
      {permissions.bonuses_access && (
        <MainWrap>
          <LabelWrap
            color={color}
            onClick={handleClick}
            isOpen={anchorEl}
            hoverColor={hex2rgba(color.main, 0.08)}
          >
            <LeftSide>
              <Svg color={color}>
                <use href={`${Sprite}#icon-banknote`}></use>
              </Svg>
              <Typography
                color={`${color.gray} !important`}
                variant={'bodyBoldSmall'}
                component={'span'}
              >
                {t('bonus_widjet.balance')}
              </Typography>
            </LeftSide>
            <RightSide>
              <Typography
                color={`${color.gray} !important`}
                variant={'bodySmall'}
                component={'span'}
              >
                {userBonusDetails.bonus || 0}
              </Typography>
              <Typography
                color={`${color.gray} !important`}
                variant={'bodySmall'}
                component={'span'}
              >
                <Chewron color={color.gray} isOpen={anchorEl}>
                  <use href={`${Sprite}#icon-arrow_card`} />
                </Chewron>
              </Typography>
            </RightSide>
          </LabelWrap>
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            style={{ marginTop: '4px' }}
          >
            <BallancePopover color={color}>
              <Typography
                variant="bodyExSmall"
                color={color.gray}
                sx={{ mt: `8px`, display: 'block' }}
              >
                {t('bonus_widjet.balance')}
              </Typography>
              <MainBonusLine>
                <Typography
                  variant="h1"
                  color={userBonusDetails.bonus > 0 ? color.gray : color.red}
                >
                  {userBonusDetails.bonus}
                  <Typography
                    variant="bodyExSmall"
                    color={userBonusDetails.bonus > 0 ? color.gray : color.red}
                    sx={{ m: `0 10px 0 3px` }}
                  >
                    USD
                  </Typography>
                </Typography>
                {userBonusDetails.bonus < 0 && (
                  <Typography
                    variant="bodyBoldExSmall"
                    color="#fff"
                    sx={{
                      padding: `2px 4px`,
                      borderRadius: `2px`,
                      backgroundColor: color.red,
                      alignSelf: `center`,
                    }}
                  >
                    {t('bonus_widjet.debt')}
                  </Typography>
                )}
              </MainBonusLine>
              <Delimeter color={color.line} />
              <BonusLine>
                <Typography variant="bodyExSmall" color={color.gray}>
                  {t('bonus_widjet.marceting_bonus')}
                </Typography>
                <Typography variant="bodyExSmall" color={color.gray}>
                  {userBonusDetails.bonus_program || 0}
                </Typography>
              </BonusLine>
              <Delimeter color={color.line} />
              <BonusLine>
                <Typography variant="bodyExSmall" color={color.gray}>
                  {t('bonus_widjet.points')}
                </Typography>
                <Typography variant="bodyExSmall" color={color.gray}>
                  {userBonusDetails?.commercial_bonus?.bonus || '0'}
                </Typography>
              </BonusLine>
            </BallancePopover>
          </Popover>
        </MainWrap>
      )}
    </>
  );
};

const MainWrap = styled.div``;

const LabelWrap = styled.div`
  display: flex;
  align-items: center;
  //TODO create object for static colors, if situation with background repeat
  background: ${({ isOpen, color, hoverColor }) =>
    isOpen ? hoverColor : '#f5f6fa'};
  padding: 8px 12px;
  border-radius: 4px;
  width: 100%;
  gap: 12px;
  border: 1px solid ${({ color }) => color.line};
  transition: 0.3s ease;
  cursor: pointer;

  &:hover {
    background: ${({ hoverColor }) => hoverColor};
  }

  @media(max-width: 768px) {
    gap: 6px;
    padding: 8px 4px;
  }
`;

const LeftSide = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  flex: 50%;
`;
const RightSide = styled.div`
  flex: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
`;

const Chewron = styled.svg`
  width: 16px;
  height: 16px;
  fill: transparent;
  vertical-align: middle;
  position: relative;
  stroke: ${({ color }) => color};
  transform: ${({ isOpen }) => (isOpen ? `rotate(180deg)` : 'unset')};
  transition: 0.1s ease;
`;

const Svg = styled.svg`
  width: 16px;
  height: 16px;
  fill: transparent;
  vertical-align: middle;
  position: relative;
`;

const BallancePopover = styled.div`
  border-radius: 4px;
  border: 1px solid ${({ color }) => color.line};
  padding: 10px;
  min-width: 180px;
`;

const Delimeter = styled.div`
  border-top: 1px solid ${({ color }) => color};
`;

const BonusLine = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 8px 0;
`;

const MainBonusLine = styled.div`
  display: flex;
  justify-content: space-between;
`;
