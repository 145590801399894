import { createSlice } from '@reduxjs/toolkit';

import employeesOperation from '@redux/employees/employees-operation';
import permissionOperation from '@redux/permission/permission-operation';

const initialState = {
  employees: [],
  employeesMeta: {},
  employeeRoles: [],
  isEmployeesLoading: false,
};

const employeesSlice = createSlice({
  name: 'employees',
  initialState,
  reducers: {},
  extraReducers: {
    [employeesOperation.getEmployees.pending]: (state, { payload }) => {
      state.isEmployeesLoading = true;
    },
    [employeesOperation.getEmployees.fulfilled]: (state, { payload }) => {
      const { data, meta } = payload;
      state.employees = data;
      state.employeesMeta = meta;
      state.isEmployeesLoading = false;
    },
    [employeesOperation.getEmployees.rejected]: (state, { payload }) => {
      state.isEmployeesLoading = false;
    },

    [employeesOperation.inviteEmployee.fulfilled]: (state, { payload }) => {
      const { data, meta } = payload;
      state.employees = data;
      state.employeesMeta = meta;
    },

    [employeesOperation.deleteEmployeeById.fulfilled]: (state, { payload }) => {
      const { data, meta } = payload;
      state.employees = data;
      state.employeesMeta = meta;
    },

    [employeesOperation.updateEmployeeById.fulfilled]: (state, { payload }) => {
      const { response, id } = payload;
      state.employees = [...state.employees].map(employee =>
        employee.id === id ? { ...employee, ...response } : employee,
      );
    },

    [permissionOperation.getPermission.fulfilled]: (state, { payload }) => {
      const { data } = payload;
      state.employeeRoles = data;
    },
  },
});

export default employeesSlice.reducer;
