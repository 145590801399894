import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import styled from 'styled-components';

import { SearchInput } from '@root/components/Input/SearchInput/index';

import { useTheme } from '@mui/material';

const KnowledgeListSearch = ({ onSearch }) => {
  const { t } = useTranslation(['knowledge']);
  const [value, setValue] = useState('');
  const { color } = useTheme();

  const handleChange = name => value => {
    setValue(value.trim());
  };

  const onKeyDown = event => {
    if (event.code === 'Enter' || event.code === 'NumpadEnter') {
      onSearch(value);
    }
  };

  return (
    <SearchWrap color={color} onKeyDown={onKeyDown}>
      <SearchInput
        searchValue={value}
        onChange={handleChange}
        handleClearSearch={() => {
          setValue('');
          onSearch();
        }}
        placeholder={t('search_post')}
      />
    </SearchWrap>
  );
};

export default KnowledgeListSearch;

const SearchWrap = styled.div`
  padding: 20px;
  border-bottom: 1px solid ${({ color }) => color.line};

  @media(max-width: 1024px) {
    padding: 15px;
  }
`;
