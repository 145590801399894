import React, { useContext, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  AutoSizer,
  CellMeasurer,
  CellMeasurerCache,
  List,
} from 'react-virtualized';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Empty, Header, VirtualizedTable } from '@root/components';
import { OrderServiceConf } from '@root/conf/OrderServiceConf';
import { WidthContext } from '@root/providers/WidthProvider';
import { LabelStatus, ProgressLoader } from '@root/ui';

import orderServiceSelectors from '@redux/order-service/order-service-selectors';
import { setSearchValue } from '@redux/order-service/order-service-slice';

import { TableBody, TableCell, TableRow, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

export const OrderServiceHistory = ({
  handleFetchServiceOrders,
  handleSelectServiceOrder,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [activeRow, setActiveRow] = useState();

  const { t } = useTranslation(['order-service'], { useSuspense: false });

  const { screenWidth } = useContext(WidthContext);

  const { color } = useTheme();
  const dispatch = useDispatch();

  const filteredServiceOrders = useSelector(
    orderServiceSelectors.getFilteredServiceOrders,
  );
  const isServiceOrdersLoading = useSelector(
    orderServiceSelectors.getIsServiceOrdersLoading,
  );
  const searchTerm = useSelector(orderServiceSelectors.getSearchValue);

  const cache = useRef(
    new CellMeasurerCache({
      fixedWidth: true,
      defaultHeight: 50,
    }),
  );

  const handleSetActiveRow = orderService => {
    const { number } = orderService;
    setActiveRow(number);
    handleSelectServiceOrder(orderService);
  };

  const handleToggleIsExpanded = () => {
    setIsExpanded(prevIsExpanded => !prevIsExpanded);
  };

  return (
    <OrderServiceHistoryWrapper color={color.line}>
      <Header
        isTopBorder={false}
        isExpanded={isExpanded}
        handleFetchDeliveryHistory={handleFetchServiceOrders}
        handleToggleSearchExpended={handleToggleIsExpanded}
        onSearchTermUpdate={searchTerm => dispatch(setSearchValue(searchTerm))}
      />

      <div
        style={{
          position: 'relative',
          height: 'calc(100vh - 238px)',
        }}
      >
        {isServiceOrdersLoading && <ProgressLoader />}
        {filteredServiceOrders.length > 0 && (
          <VirtualizedTable
            checkIsInclude={false}
            isSearchExpanded={isExpanded}
            tableConf={OrderServiceConf}
          >
            <TableBody
              sx={{
                display: 'block',
                height: 'calc(100vh - 272px)',
              }}
            >
              <ScrollWrapper
                colors={{
                  gray: color.gray_40,
                  white: color.white,
                  line: color.line,
                }}
              >
                <AutoSizer>
                  {({ width, height }) => (
                    <List
                      width={width}
                      height={height}
                      rowHeight={cache.current.rowHeight}
                      defferedMeasurementCache={cache.current}
                      rowCount={filteredServiceOrders.length}
                      rowRenderer={({ key, index, style, parent }) => {
                        const data = filteredServiceOrders[index];

                        return (
                          <CellMeasurer
                            key={key}
                            cache={cache.current}
                            parent={parent}
                            columnIndex={0}
                            rowIndex={index}
                          >
                            <TableRow
                              sx={{
                                cursor: 'pointer',
                                borderBottom: `1px solid ${color.line}`,
                                userSelect: 'auto',
                                boxShadow: 'none',
                                td: {
                                  borderBottom: 'none',
                                },
                                display: 'flex',
                                alignItems: 'center',
                                padding: '7px 16px',
                                '&:last-child': {
                                  borderBottom: 'none',
                                },
                                background:
                                  activeRow === data.number
                                    ? color.main_light
                                    : color.white,
                                borderLeft:
                                  activeRow === data.number
                                    ? `3px solid ${color.main}`
                                    : 'none',

                                '&:hover': {
                                  background:
                                    activeRow !== data.number && color.gray_20,
                                },
                              }}
                              onClick={() => handleSetActiveRow(data)}
                              style={style}
                            >
                              {OrderServiceConf(t, screenWidth).map(
                                orderService => {
                                  switch (orderService.slug) {
                                    case 'date':
                                      return (
                                        orderService.isDisplay && (
                                          <TableCell
                                            sx={{
                                              padding: '0',
                                              ...orderService.style,
                                            }}
                                          >
                                            <MobileWrapper>
                                              {screenWidth < 1025 && (
                                                <Typography
                                                  variant="bodyBoldSmall"
                                                  color={color.gray}
                                                >
                                                  #{data.number}
                                                </Typography>
                                              )}

                                              <OrderInfoMobileWrapper>
                                                {screenWidth < 769 && (
                                                  <Typography
                                                    variant="bodySmall"
                                                    color={color.gray}
                                                  >
                                                    <Typography
                                                      variant="bodyBoldSmall"
                                                      component="span"
                                                      color={color.gray_60}
                                                    >
                                                      {t(
                                                        'order_service:conf.device',
                                                      )}
                                                      :
                                                    </Typography>
                                                    &nbsp; {data.model}
                                                  </Typography>
                                                )}

                                                {screenWidth < 769 && (
                                                  <Typography
                                                    variant="bodySmall"
                                                    color={color.gray}
                                                  >
                                                    <Typography
                                                      variant="bodyBoldSmall"
                                                      component="span"
                                                      color={color.gray_60}
                                                    >
                                                      {t(
                                                        'order_service:conf.serial_number',
                                                      )}
                                                      :
                                                    </Typography>
                                                    &nbsp; {data.serial}
                                                  </Typography>
                                                )}

                                                {screenWidth < 769 && (
                                                  <Typography
                                                    variant="bodyBoldExSmall"
                                                    color={color.gray}
                                                    sx={{
                                                      display: 'flex',
                                                      alignItems: 'center',
                                                    }}
                                                  >
                                                    <Typography
                                                      variant="bodyBoldSmall"
                                                      component="span"
                                                      color={color.gray_60}
                                                    >
                                                      {t(
                                                        'order_service:conf.warranty_payments',
                                                      )}{' '}
                                                      :
                                                    </Typography>
                                                    &nbsp; {data.type}
                                                  </Typography>
                                                )}
                                              </OrderInfoMobileWrapper>

                                              <Typography
                                                variant="bodySmall"
                                                color={color.gray_80}
                                              >
                                                {data.date}
                                              </Typography>
                                            </MobileWrapper>
                                          </TableCell>
                                        )
                                      );
                                    case 'order_number':
                                      return (
                                        orderService.isDisplay && (
                                          <TableCell
                                            sx={{
                                              padding: '0',
                                              ...orderService.style,
                                            }}
                                          >
                                            <Typography
                                              variant="bodyBoldSmall"
                                              color={color.gray}
                                            >
                                              {data.number}
                                            </Typography>
                                          </TableCell>
                                        )
                                      );
                                    case 'device':
                                      return (
                                        orderService.isDisplay && (
                                          <TableCell
                                            sx={{
                                              padding: '0',
                                              ...orderService.style,
                                            }}
                                          >
                                            <Typography
                                              variant="bodySmall"
                                              color={color.gray}
                                            >
                                              {data.model}
                                            </Typography>
                                          </TableCell>
                                        )
                                      );
                                    case 'serial_number':
                                      return (
                                        orderService.isDisplay && (
                                          <TableCell
                                            sx={{
                                              padding: '0',
                                              ...orderService.style,
                                            }}
                                          >
                                            <Typography
                                              variant="bodySmall"
                                              color={color.gray}
                                            >
                                              {data.serial}
                                            </Typography>
                                          </TableCell>
                                        )
                                      );
                                    case 'warranty_payments':
                                      return (
                                        orderService.isDisplay && (
                                          <TableCell
                                            sx={{
                                              padding: '0',
                                              ...orderService.style,
                                            }}
                                          >
                                            <Typography
                                              variant="bodyBoldExSmall"
                                              color={color.gray}
                                              sx={{
                                                display: 'flex',
                                                alignItems: 'center',
                                              }}
                                            >
                                              {data.type}
                                            </Typography>
                                          </TableCell>
                                        )
                                      );
                                    case 'status':
                                      return (
                                        orderService.isDisplay && (
                                          <TableCell
                                            sx={{
                                              padding: '0',
                                              ...orderService.style,
                                            }}
                                          >
                                            <LabelStatus>
                                              <Typography
                                                variant="bodyBoldExSmall"
                                                color={`${color.gray_80} !important`}
                                              >
                                                {data.status}
                                              </Typography>
                                            </LabelStatus>
                                          </TableCell>
                                        )
                                      );
                                  }
                                },
                              )}
                            </TableRow>
                          </CellMeasurer>
                        );
                      }}
                    />
                  )}
                </AutoSizer>
              </ScrollWrapper>
            </TableBody>
          </VirtualizedTable>
        )}

        {searchTerm === '' && filteredServiceOrders.length === 0 && (
          <Empty type={'NoHistory'} />
        )}

        {searchTerm !== '' && filteredServiceOrders.length === 0 && (
          <Empty type={'EmptySearchLogo'} />
        )}
      </div>
    </OrderServiceHistoryWrapper>
  );
};

OrderServiceHistory.propTypes = {
  handleFetchServiceOrders: PropTypes.func,
  handleSelectServiceOrder: PropTypes.func,
};

const OrderServiceHistoryWrapper = styled.div`
  background: #ffffff;
  border-radius: 4px;
  width: 60%;
  border: 1px solid ${props => props.color};

  @media (max-width: 1400px) {
    width: 100%;
  }
`;

const MobileWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const OrderInfoMobileWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;

  @media (min-width: 769px) {
    display: none;
  }
`;

const ScrollWrapper = styled.div`
  overflow: hidden;
  height: inherit;
  max-height: inherit;

  div {
    ::-webkit-scrollbar {
      width: 5px;
    }

    ::-webkit-scrollbar-thumb {
      background: ${props => props.colors.gray};
      border-radius: 4px;
    }

    ::-webkit-scrollbar-track {
      background: ${props => props.colors.white};
      border-left: 1px solid ${props => props.colors.line};
    }
  }
`;
