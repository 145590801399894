export const RegistrationStapes = t => [
  { label: t('registration:stepper.registration') },
  { label: t('registration:stepper.contact_info') },
  { label: t('registration:stepper.legal_information') },
  { label: t('registration:stepper.about_company') },
  { label: t('registration:stepper.staff') },
  { label: t('registration:stepper.sent_full_form') },
];

//TODO
//icon pan like ex.

export const StatusStapes = t => [
  { label: 'Скасовано', icon: 'icon-pan' },
  { label: 'Розміщено', icon: 'icon-pan' },
  { label: 'Oчікує оплати', icon: 'icon-pan' },
  { label: 'Сплачений', icon: 'icon-pan' },
  { label: 'В доставці', icon: 'icon-pan' },
  { label: 'Виконано', icon: 'icon-pan' },
];
