import { useEffect } from 'react';

export const MouseFlow = ({ children }) => {
  useEffect(() => {
    if (process.env.REACT_APP_ENV === 'prod') {
      var mouseflowPath = window.location.hostname + window.location.pathname;
      var mouseflowCrossDomainSupport = true;
      window._mfq = window._mfq || [];
      (function () {
        var mf = document.createElement('script');
        mf.type = 'text/javascript';
        mf.defer = true;
        mf.src =
          'https://cdn.mouseflow.com/projects/70ae674b-9fd0-4379-a66f-9cce223ae051.js';
        document.getElementsByTagName('head')[0].appendChild(mf);
      })();

      var scriptElement = document.createElement('script');
      scriptElement.innerHTML =
        "(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0], j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src= 'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f); })(window,document,'script','dataLayer','GTM-5GZ996W');";
      document.head.appendChild(scriptElement);

      var noscriptElement = document.createElement('noscript');
      noscriptElement.innerHTML =
        '<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-5GZ996W" height="0" width="0" style="display:none;visibility:hidden"></iframe>';
      document.body.appendChild(noscriptElement);

      //   09.02.2024
      var gtmScript = document.createElement('script');
      gtmScript.async = true;
      gtmScript.src =
        'https://www.googletagmanager.com/gtag/js?id=G-4BFH6WR5BC';
      var gaConfigScript = document.createElement('script');
      gaConfigScript.innerHTML = `
  window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}
  gtag('js', new Date());
  gtag('config', 'G-4BFH6WR5BC');
`;

      // Добавляем оба скрипта в конец тега <head>
      document.head.appendChild(gtmScript);
      document.head.appendChild(gaConfigScript);
    }

    return () => {
      if (process.env.REACT_APP_ENV === 'prod') {
        const head = document.getElementsByTagName('head')[0];
        const el = head.querySelector('#MouseFlow');
        if (el) {
          head.removeChild(el);
        }
      }
    };
  }, []);

  return <>{children}</>;
};
