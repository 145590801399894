import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import { PricesTooltip } from '@root/components';

import userSelectors from '@redux/user/user-selectors';

import { Typography, useTheme } from '@mui/material';

export const Cost = ({ isHiddenTooltip, isHiddenPrice, onlyUAH, data, mobileCatalogStyles }) => {
  const { personal_price, price, prices } = data;
  const currency = useSelector(userSelectors.getChoosedCurrency);
  const choosedCurrency = useSelector(
    userSelectors.getChoosedCurrencyWidget,
  ).field_name;
  const { t } = useTranslation(['common'], { useSuspense: false });
  const { color } = useTheme();

  const priceColor = useMemo(
    () =>
      personal_price[choosedCurrency] < price[choosedCurrency]
        ? color.red
        : color.gray,
    [color, personal_price, price, choosedCurrency],
  );

  const displayMainCurrency = () => {
    switch (true) {
      case onlyUAH === true:
        return t('common:currency');
      case data?.is_marketing === true:
        return t('common:currencyPoints');
      default:
        return currency;
    }
  };

  const DisplayMainValue = () => {
    switch (true) {
      case data?.is_marketing === true:
        return data?.price_marketing;
      default:
        return personal_price[choosedCurrency];
    }
  };

  return (
    <Box>
      {personal_price[choosedCurrency] > 0 ||
      price[choosedCurrency] > 0 ||
      data?.is_marketing ? (
        <PricesTooltip prices={prices} isHiddenTooltip={isHiddenTooltip}>
          <TooltipInner>
            {(personal_price[choosedCurrency] > 0 || data?.is_marketing) && (
              <Typography
                sx={{
                  color: priceColor,
                  display: 'block',
                  // alignItems: 'baseline',
                  textAlign: 'left',
                  mb: '3px',
                  fontSize: mobileCatalogStyles ? '0.85rem' : '1rem'
                }}
                variant={'bodyBoldLarge'}
                align={'center'}
                component={'p'}
              >
                {DisplayMainValue()}
                <Typography
                  sx={{
                    color: priceColor,
                    // ml: '4px',
                    // display: 'block',
                  }}
                  variant={'bodyBoldSmall'}
                  align={'left'}
                >
                  {displayMainCurrency()}
                </Typography>
              </Typography>
            )}
            {choosedCurrency === 'price' &&
              price[choosedCurrency] > 0 &&
              personal_price['price_no_cash'] > 0 && (
                <Typography
                  sx={{ color: color.gray, display: 'flex', mb: `4px` }}
                  variant={'bodyExSmall'}
                  align={'center'}
                  component={'p'}
                >
                  {/* <pre>{JSON.stringify(price[choosedCurrency], null, 2)}</pre> */}
                  {personal_price['price_no_cash']} {currency}{' '}
                  {t('common:currencyBg')}
                </Typography>
              )}
            {!isHiddenPrice && price[choosedCurrency] > 0 && (
              <Typography
                sx={
                  {
                    color: color.gray_60,
                    display: 'flex',
                    // flexDirection: mobileCatalogStyles ? 'column' : 'row' ,
                  }}
                variant={'bodyExSmall'}
                align={'center'}
                component={'p'}
              >
                {price[choosedCurrency]}
                <Typography
                  sx={{ color: color.gray_60, ml: '2px', display: 'block' }}
                  variant={'bodyExSmall'}
                  align={'center'}
                >
                  {onlyUAH ? t('common:currency') : currency}
                </Typography>
              </Typography>
            )}
          </TooltipInner>
        </PricesTooltip>
      ) : (
        <Typography
          sx={{
            color: color.gray,
            display: 'flex',
            alignItems: 'baseline',
            width: '90%',
            textAlign: 'left',
          }}
          variant={'bodyBoldMedium'}
          align={'center'}
          component={'p'}
        >
          {t('common:check_price_for_details')}
        </Typography>
      )}
    </Box>
  );
};

const Box = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  @media(max-width: 768px) {
    padding-left: 5px;
  }
`;

const TooltipInner = styled.div``;

Cost.propTypes = {
  isHiddenTooltip: PropTypes.bool,
  onlyUAH: PropTypes.bool,
  data: PropTypes.exact({
    brand: PropTypes.string,
    can_buy: PropTypes.exact({
      price: PropTypes.bool,
      price_uah_cash: PropTypes.bool,
      price_uah_no_pdv: PropTypes.bool,
      price_uah_pdv: PropTypes.bool,
    }),
    cart: PropTypes.shape({
      cart_product_id: PropTypes.number,
      id: PropTypes.number,
      personal_amount: PropTypes.exact({
        price: PropTypes.string,
        price_uah_cash: PropTypes.string,
        price_uah_no_pdv: PropTypes.string,
        price_uah_pdv: PropTypes.string,
      }),
      quantity: PropTypes.number,
    }),
    description: PropTypes.string,
    has_analogs: PropTypes.bool,
    id: PropTypes.number,
    image: PropTypes.string,
    images_quantity: PropTypes.number,
    in_compare: PropTypes.bool,
    is_in_cart: PropTypes.bool,
    is_saved: PropTypes.bool,
    model: PropTypes.string,
    name: PropTypes.string,
    personal_price: PropTypes.exact({
      price: PropTypes.string,
      price_uah_cash: PropTypes.string,
      price_uah_no_pdv: PropTypes.string,
      price_uah_pdv: PropTypes.string,
    }),
    price: PropTypes.exact({
      price: PropTypes.string,
      price_uah_cash: PropTypes.string,
      price_uah_no_pdv: PropTypes.string,
      price_uah_pdv: PropTypes.string,
    }),
    prices: PropTypes.exact({
      price: PropTypes.exact({
        product: PropTypes.string,
        personal: PropTypes.string,
      }),
      price_uah_cash: PropTypes.exact({
        product: PropTypes.string,
        personal: PropTypes.string,
      }),
      price_uah_no_pdv: PropTypes.exact({
        product: PropTypes.string,
        personal: PropTypes.string,
      }),
      price_uah_pdv: PropTypes.exact({
        product: PropTypes.string,
        personal: PropTypes.string,
      }),
    }),
    sku: PropTypes.string,
    slug: PropTypes.string,
    stock: PropTypes.number,
    stock_max: PropTypes.number,
    stock_percent: PropTypes.number,
    url: PropTypes.string,
    warehouses: PropTypes.arrayOf(
      PropTypes.exact({
        city_name: PropTypes.string,
        warehouses: PropTypes.exact({
          name: PropTypes.string,
          stock: PropTypes.number,
          stock_max: PropTypes.number,
          stock_percent: PropTypes.number,
        }),
      }),
    ),
  }),
};
