import { useState } from 'react';

import styled from 'styled-components';

import { Sprite } from '@root/assets/svg/index';

const BannerImagesItem = ({
  color,
  index,
  selectedItemId,
  dropHandler,
  banner,
  onSelect,
  onDelete,
}) => {
  const [isOnHover, setOnHover] = useState(false);

  return (
    <MainWrap
      isOnHover={isOnHover}
      color={color}
      draggable={true}
      onDragStart={e => {
        e.dataTransfer.setData('text/plain', index);
      }}
      onDragLeave={e => {
        setOnHover(false);
      }}
      onDragOver={e => {
        e.preventDefault();
        setOnHover(true);
      }}
      onDragEnd={e => {}}
      onDrop={e => {
        setOnHover(false);
        dropHandler(e, index, e.dataTransfer.getData('text/plain'));
      }}
    >
      <ImgItem
        isOnHover={isOnHover}
        color={color}
        active={index === selectedItemId}
      >
        <img
          src={banner?.image}
          alt="banner"
          onClick={() => {
            onSelect(index);
          }}
        />
        {banner.html && (
          <TextSvgWrap>
            <SvgTextExsist color={color}>
              <use href={`${Sprite}#icon-text`}></use>
            </SvgTextExsist>
          </TextSvgWrap>
        )}
        <Controls>
          <Svg color={color}>
            <use href={`${Sprite}#icon-replace`}></use>
          </Svg>
          <Svg
            color={color}
            onClick={() => {
              onDelete(index);
            }}
          >
            <use href={`${Sprite}#icon-delete`}></use>
          </Svg>
        </Controls>
      </ImgItem>
    </MainWrap>
  );
};

export default BannerImagesItem;

const MainWrap = styled.div`
  /* background-color: red; */
`;

const ImgItem = styled.div`
  display: flex;
  margin-bottom: 8px;
  object-fit: cover;
  position: relative;

  background-color: ${({ isOnHover, color }) =>
    isOnHover ? color.gray_40 : 'unset'};

  /* transform: translate(0, ${({ isOnHover }) =>
    isOnHover ? '20px' : '0'}); */

  & img {
    cursor: pointer;
    width: 100px;
    height: 94px;
    border-radius: 4px;
    object-fit: cover;
    box-shadow: ${({ active, color }) =>
      active
        ? `${color.main} 0px 0px 0px 3px`
        : `${color.line} 0px 0px 0px 1px`};
  }
`;
const Controls = styled.div`
  width: 24px;
  margin-left: 8px;
  padding-top: 1px;
`;
const Svg = styled.svg`
  width: 16px;
  height: 16px;
  margin-bottom: 5px;
  cursor: pointer;
  opacity: 0.8;
  stroke: ${({ color }) => color.gray_60};
  &:hover {
    opacity: 1;
    stroke: ${({ color }) => color.gray_80};
  }
`;

const TextSvgWrap = styled.div`
  background-color: rgba(182, 182, 182, 0.5);
  padding: 3px;
  position: absolute;
  bottom: 3px;
  left: 3px;
  border-radius: 2px;
`;

const SvgTextExsist = styled.svg`
  width: 16px;
  height: 16px;
  cursor: pointer;
  opacity: 0.8;
  /* stroke: red !important; */
  stroke: ${({ color }) => color.white};
`;
