import { useEffect, useMemo } from 'react';
// import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Swiper, SwiperSlide } from 'swiper/react';

import styled from 'styled-components';
import { Autoplay, Pagination } from 'swiper';
import 'swiper/css';
import 'swiper/css/pagination';

import bannersOperation from '@root/redux/banners/banners-operation';
import bannersSelectors from '@root/redux/banners/banners-selectors';

import { useTheme } from '@mui/material';

import { SliderItem } from './SliderItem';

export const Slider = () => {
  const { color } = useTheme();
  //   const { t } = useTranslation(['login'], { useSuspense: false });
  const banners = useSelector(bannersSelectors.getBanners);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(bannersOperation.getBanners({}));
  }, []);
  const sortedBanners = useMemo(() => {
    const bannersCopy = [...banners];
    return bannersCopy?.sort((a, b) => a.weight - b.weight);
  }, [banners]);

  return (
    <SwiperWrapper>
      <Swiper
        pagination={true}
        style={{
          '--swiper-pagination-color': color.white,
        }}
        modules={[Autoplay, Pagination]}
        className="mySwiper"
        autoplay={{
          delay: 5000,
          disableOnInteraction: false,
        }}
        speed={600}
      >
        {sortedBanners.map(({ image, html, anchor, x, y, link, id }, i) => (
          <SwiperSlide>
            <SliderItem
              key={i}
              id={id}
              image={image}
              html={html}
              anchor={anchor}
              x={x}
              y={y}
              link={link}
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </SwiperWrapper>
  );
};

const SwiperWrapper = styled.div`
  .swiper-pagination-bullet {
    width: 16px;
    height: 16px;
    background: rgba(255, 255, 255, 0.4);
    border-radius: 50%;
    opacity: 1;
    margin: 0;
  }

  .swiper-pagination-bullet + .swiper-pagination-bullet {
    margin-left: 16px;
  }

  .swiper-pagination-bullet-active {
    background: white;
  }

  .swiper-pagination {
    margin-bottom: 50px;
  }
`;
