import {
    AppleIco,
    FacebookIco,
    GoogleIco,
    MicrosoftIco,
} from '@root/assets/svg/index';

export const SocialsLinksConf = [
    { ico: FacebookIco, text: 'Facebook', id: 'facebook' },
    { ico: GoogleIco, text: 'Google', id: 'google' },
    // { ico: AppleIco, text: 'Apple ID', id: 'apple' },
    // { ico: MicrosoftIco, text: 'Microsoft', id: 'microsoft' },
]