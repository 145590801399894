import React from 'react';
import { useTranslation } from 'react-i18next';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Sprite } from '@root/assets/svg';
import { ButtonBase, OutlineButtonBase, TextInput } from '@root/ui';

import CloseIcon from '@mui/icons-material/Close';
import {
  AppBar,
  IconButton,
  Toolbar,
  Typography,
  useTheme,
} from '@mui/material';

export const BaseModalHeader = ({
  mainTitle,
  saveOrUpdate,
  closeModalCallback,
  handleCleanSearch,
  handleEnterSearch,
  handleOpenModal,
  value,
  onChange,
  isActionButtons,
  isCreateModal,
  isSearch = true,
  isLangTabs = true,
  toolbarSx,
  defPadding,
  baseWrapperSx,
}) => {
  const { color } = useTheme();
  const { t } = useTranslation(['common'], { useSuspense: false });
  return (
    <BaseHeaderWrap baseStyles={baseWrapperSx}>
      <AppBar
        sx={{
          position: 'relative',
          background: 'transparent',
          boxShadow: 'initial',
        }}
      >
        <Toolbar
          sx={{
            ...toolbarSx,
          }}
        >
          <Typography
            sx={{
              flex: 1,
              color: color.gray,
              fontWeight: 700,
            }}
            variant="p"
            component="p"
          >
            {mainTitle}
          </Typography>
          {isActionButtons ? (
            <>
              <OutlineButtonBase
                sx={{
                  height: '28px',
                  width: '76px',
                  fontSize: '0.75rem',
                  background: 'transparent',
                  color: color.gray_80,
                  marginRight: '5px',
                  border: 'none !important',
                  '&:hover': {
                    background: 'transparent',
                    boxShadow: 'none',
                  },
                  '&:active': {
                    background: 'none',
                    boxShadow: 'none',
                  },
                }}
                onClick={closeModalCallback}
              >
                {t('common:buttons.cancel')}
              </OutlineButtonBase>
              <ButtonBase
                sx={{
                  height: '28px',
                  width: '76px',
                  fontSize: '0.75rem',
                }}
                onClick={saveOrUpdate}
              >
                {t('common:buttons.save')}
              </ButtonBase>
            </>
          ) : (
            <IconButton
              edge="start"
              color={color.gray}
              aria-label="close"
              onClick={closeModalCallback}
            >
              <CloseIcon />
            </IconButton>
          )}
        </Toolbar>
      </AppBar>
      {isLangTabs && (
        <TabsWrap padding={defPadding}>
          <ButtonBase
            type={'button'}
            onClick={() => console.log('click')}
            sx={{
              maxWidth: '92px',
              width: '100%',
              fontSize: '0.75rem',
              fontWeight: '700',
              '&:hover': {
                background: color.main,
                boxShadow: 'none',
              },
              '&:active': {
                background: 'none',
                boxShadow: 'none',
              },
            }}
          >
            Українська
          </ButtonBase>
          <ButtonBase
            type={'button'}
            onClick={() => console.log('click')}
            sx={{
              maxWidth: '72px',
              width: '100%',
              background: 'transparent',
              color: color.gray_80,
              fontSize: '0.75rem',
              fontWeight: '400',
              '&:hover': {
                background: 'transparent',
                boxShadow: 'none',
              },
              '&:active': {
                background: 'none',
                boxShadow: 'none',
              },
            }}
          >
            English
          </ButtonBase>
          <ButtonBase
            type={'button'}
            onClick={() => console.log('click')}
            sx={{
              maxWidth: '72px',
              width: '100%',
              background: 'transparent',
              color: color.gray_80,
              fontSize: '0.75rem',
              fontWeight: '400',
              '&:hover': {
                background: 'transparent',
                boxShadow: 'none',
              },
              '&:active': {
                background: 'none',
                boxShadow: 'none',
              },
            }}
          >
            Русский
          </ButtonBase>
        </TabsWrap>
      )}
      {isSearch && (
        <SearchWrap padding={defPadding}>
          <TextInput
            sx={{
              width: '100%',
              height: '36px',
              input: { pl: '10px' },
            }}
            placeholder={t('common:search')}
            startAdornment={
              <Svg stroke={color.gray_60} width={16} height={16}>
                <use href={`${Sprite}#icon-loop`}></use>
              </Svg>
            }
            endAdornment={
              value && (
                <Svg
                  stroke={color.gray_60}
                  width={16}
                  height={16}
                  onClick={handleCleanSearch}
                >
                  <use href={`${Sprite}#icon-delete`}></use>
                </Svg>
              )
            }
            onKeyDown={e => handleEnterSearch(e)}
            onChange={val => onChange(val)}
            value={value}
          />
          {isCreateModal && (
            <OutlineButtonBase
              type={'button'}
              sx={{
                maxWidth: '200px',
                width: '100%',
                background: 'transparent',
                height: '36px',
                '&:hover': {
                  background: 'transparent',
                },
                color: color.gray,
                border: `1px solid ${color.line}`,
                fontSize: '0.75rem',
              }}
              onClick={handleOpenModal}
            >
              <Svg stroke={color.gray_60}>
                <use href={`${Sprite}#icon-plus`}></use>
              </Svg>
              {t(
                'catalog:modals.settings_filter_modal.buttons.create_new_filter',
              )}
            </OutlineButtonBase>
          )}
        </SearchWrap>
      )}
    </BaseHeaderWrap>
  );
};

const BaseHeaderWrap = styled.div`
  ${prop => prop.baseStyles};
`;

const TabsWrap = styled.div`
  display: flex;
  align-items: center;
  padding: ${prop => (prop.padding ? prop.padding : '20px')};
  padding-bottom: 16px;
`;

const SearchWrap = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  padding: ${prop => (prop.padding ? prop.padding : '20px')};

  label {
    width: 100%;
  }
`;

const Svg = styled.svg`
  stroke: ${props => props.stroke};
  width: 16px;
  height: 16px;
  margin-right: 4px;

  cursor: pointer;
`;

BaseModalHeader.propTypes = {
  mainTitle: PropTypes.string,
  saveOrUpdate: PropTypes.func.isRequired,
  closeModalCallback: PropTypes.func.isRequired,
  handleCleanSearch: PropTypes.func.isRequired,
  handleEnterSearch: PropTypes.func.isRequired,
  handleOpenModal: PropTypes.func.isRequired,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  isActionButtons: PropTypes.bool,
  isCreateModal: PropTypes.bool,
  isSearch: PropTypes.bool,
  isLangTabs: PropTypes.bool,
  toolbarSx: PropTypes.object,
  defPadding: PropTypes.string,
  baseWrapperSx: PropTypes.object,
};
