import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { nanoid } from 'nanoid';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { HeaderDefault, InviteEmployees } from '@root/components';
import { getErrorStack, handleDispatchFetch, validEmail } from '@root/helpers';
import { ButtonBase, ModalBase } from '@root/ui';

import employeesOperation from '@redux/employees/employees-operation';

const defaultEmployeeInfo = { email: null, role: null, id: nanoid() };

export const InviteEmployeesModal = ({ handleClose, open }) => {
  const [data, setData] = useState(() => ({
    employees: [defaultEmployeeInfo],
  }));
  const [error, setError] = useState([]);

  const { t } = useTranslation(
    ['registration', 'validation', 'common', 'profile'],
    {
      useSuspense: false,
    },
  );

  const dispatch = useDispatch();

  const handelAddEmployees = () => {
    const dataCopy = JSON.parse(JSON.stringify(data));
    dataCopy.employees = [
      ...dataCopy.employees,
      { email: null, role: null, id: nanoid() },
    ];
    setData(dataCopy);
  };

  const onChange = (name, i, subName) => value => {
    const dataCopy = JSON.parse(JSON.stringify(data));
    dataCopy[name][i][subName] = value ? value : null;
    setData(dataCopy);
  };

  const validation = () => {
    let flag = false;

    const { employees } = data;

    const newErrorsArray = employees.reduce((acc, employee, i) => {
      const { email } = employee;

      Object.keys(employee).forEach(key => {
        if (employee[key] === null) {
          acc[i] = getErrorStack(acc[i], key, t('validation:required'));
          flag = true;
        }
      });

      if (email !== null && validEmail(email)) {
        acc[i] = getErrorStack(
          acc[i],
          'email',
          t('validation:incorrect_email'),
        );
        flag = true;
      }

      return acc;
    }, []);

    setError(newErrorsArray);

    return flag;
  };

  const handleErrorsAfterFetch = ({ errors, message }) => {
    const users = Object.keys(errors).map(el => +el.split('.')[1]);
    const newErrorsArray = [];
    users.forEach(
      el =>
        (newErrorsArray[el] = getErrorStack(
          el,
          'email',
          'Вказані email вже зареєстровані',
        )),
    );

    setError(newErrorsArray);
  };

  const deleteEmployees = id => {
    const dataCopy = JSON.parse(JSON.stringify(data));

    dataCopy.employees = [...dataCopy.employees].filter(el => el?.id !== id);
    if (dataCopy.employees.length === 0) {
      dataCopy.employees = [{ email: null, role: null, id: nanoid() }];
    }

    setData(dataCopy);
  };

  const handelSubmit = e => {
    e.preventDefault();
    if (validation()) return;

    handleDispatchFetch(
      ({ onResolve, onReject }) =>
        dispatch(
          employeesOperation.inviteEmployee({
            users: data.employees,
            onResolve,
            onReject,
          }),
        ),
      () => handleClose(),
      payload => handleErrorsAfterFetch(payload),
    );
  };

  return (
    <ModalBase
      open={open}
      handleClose={handleClose}
      sx={{
        width: '100%',
        maxWidth: '466px',
      }}
      modalHeader={
        <HeaderDefault
          onClose={handleClose}
          text={t('profile:conf.list_of_employees.add_employee_btn')}
        />
      }
      modalBottom={
        <ButtonWrapper>
          <ButtonBase onClick={handelSubmit}>
            {t('common:buttons.send_invite')}
          </ButtonBase>
        </ButtonWrapper>
      }
    >
      <InviteEmployeesModalWrapper>
        <InviteEmployees
          employees={data.employees}
          handelAddEmployees={handelAddEmployees}
          onChange={onChange}
          error={error}
          deleteEmployees={deleteEmployees}
        />
      </InviteEmployeesModalWrapper>
    </ModalBase>
  );
};

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
`;

const InviteEmployeesModalWrapper = styled.div`
  padding-right: 5px;
`;

InviteEmployeesModal.propTypes = {
  handleClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};
