import * as React from 'react';
import { useTranslation } from 'react-i18next';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import { RequiredStar, Sprite } from '@root/assets/svg';
import { IconBtn, TextInput } from '@root/ui';

import { Typography, useTheme } from '@mui/material';

export const CreateFilter = ({
  name,
  handleDeleteFilter,
  handleFilterName,
  error,
  index,
}) => {
  const { t } = useTranslation(['catalog'], { useSuspense: false });
  const { color } = useTheme();
  return (
    <CreateOrUpdateFiltersWrap>
      <LabelWrapper style={{ marginLeft: '35px' }}>
        <RequiredStarImg src={RequiredStar} alt="Required Star" />
        <Typography
          variant={'bodySmall'}
          sx={{
            color: color.gray,
            margin: '0 0 5px 2px',
          }}
          component={'p'}
        >
          {t('catalog:modals.add_new_filter_modal.fields.filter_value')}
        </Typography>
      </LabelWrapper>
      <FilterWrapper>
        <IconBtn href={`${Sprite}#icon-drag-and-drop`} size={16} />
        <TextInput
          error={
            error?.valuesArrErrors &&
            error?.valuesArrErrors[index]?.filterError?.init
          }
          errorText={
            error?.valuesArrErrors &&
            error?.valuesArrErrors[index]?.filterError?.text
          }
          id="input-with-icon-adornment"
          sx={{
            width: '752px',
            height: '36px',
            input: { fontSize: '0.875rem' },
          }}
          max={100}
          styleWrapper={{
            display: 'flex',
            flexDirection: 'column',
            marginLeft: '18px',
          }}
          placeholder={t(
            'catalog:modals.add_new_filter_modal.fields.type_filter_value',
          )}
          onChange={handleFilterName()}
          value={name}
        />
        <IconBtn
          sx={{ marginLeft: '16px' }}
          href={`${Sprite}#icon-delete`}
          onClick={handleDeleteFilter}
          size={16}
        />
      </FilterWrapper>
    </CreateOrUpdateFiltersWrap>
  );
};

const RequiredStarImg = styled.img`
  margin-top: 3px;
`;

const LabelWrapper = styled.div`
  display: flex;
  align-items: flex-start;
`;

const FilterWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const CreateOrUpdateFiltersWrap = styled.div`
  padding: 10px 20px;
`;

CreateFilter.propTypes = {
  name: PropTypes.string,
  handleDeleteFilter: PropTypes.func.isRequired,
  handleFilterName: PropTypes.func.isRequired,
  error: PropTypes.object,
  index: PropTypes.number,
};
