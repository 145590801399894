import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import styled from 'styled-components';

import { Sprite } from '@root/assets/svg/index';
import { RichTextEditor } from '@root/components/RichTextEditor/index';
import { ButtonWithoutBorder } from '@root/ui/Button/ButtonWithoutBorder/index';
import { EllipsisText } from '@root/ui/EllipsisText/index';
import { TextArea } from '@root/ui/Input/TextArea/index';

import { Box, Typography, useTheme } from '@mui/material';

const DescriptionOperations = ({
  isCreate,
  isEdit,
  description,
  introText,
  onChangeDescription,
  onChangeIntroText,
}) => {
  const { color } = useTheme();
  const { t } = useTranslation();
  const [localEdit, setLocalEdit] = useState(false);

  useEffect(() => {
    if (isCreate) setLocalEdit(true);
    else {
      if (!isEdit) setLocalEdit(false);
    }
  }, [isEdit, isCreate]);

  return (
    <>
      {isEdit && !localEdit && (
        <ButtonWithoutBorder
          onClick={() => {
            setLocalEdit(true);
          }}
          sx={{
            color: color.gray_60,
            display: 'flex',
            width: '158px',
            height: '44px',
            alignItems: 'center',
            justifyContent: 'flex-start',
            padding: '0',
            background: 'none',
            '&:hover': {
              background: 'none',
            },
          }}
        >
          <svg width={16} height={16} fill={color.gray_60}>
            <use href={`${Sprite}#icon-edit`}></use>
          </svg>

          <Typography
            sx={{
              marginLeft: '4px',
              fontSize: '12px',
              fontWeight: '700',
              color: `${color.gray_80} !important`,
            }}
            variant="bodyBoldSmall"
          >
            {t('product_details:edit-description')}
          </Typography>
        </ButtonWithoutBorder>
      )}

      {isEdit && localEdit && (
        <Box>
          <Typography
            variant="bodySmall"
            color={color.gray}
            sx={{
              mb: '10px',
              span: {
                color: color.red,
              },
            }}
            component="div"
          >
            <span>*</span> {t('product_details:edit-description')}
          </Typography>
        </Box>
      )}

      <Description>
        {localEdit ? (
          <>
            <RichTextEditor
              value={introText}
              placeholder={t('product_details:edit-intro_text')}
              onChange={(value, emitter) => {
                if (emitter === 'user') onChangeIntroText(value);
              }}
              height="unset"
              qlEditorSx={{
                border: `1px solid ${color.line}`,
                borderBottomLeftRadius: '4px',
                borderBottomRightRadius: '4px',
                padding: '8px 16px',
                color: color.gray_80,
              }}
              qlToolsSx={{
                borderBottom: 'none',
                borderBottomLeftRadius: '0',
                borderBottomRightRadius: '0',
                width: 'unset',
              }}
              qlPlaceholderSx={{
                left: '16px !important',
              }}
            />
            <Divider color={localEdit ? '#fff' : color.line} />
            <RichTextEditor
              value={description}
              placeholder={t('product_details:edit-description')}
              onChange={(value, emitter) => {
                if (emitter === 'user') onChangeDescription(value);
              }}
              height="unset"
              qlEditorSx={{
                border: `1px solid ${color.line}`,
                borderBottomLeftRadius: '4px',
                borderBottomRightRadius: '4px',
                padding: '8px 16px',
                color: color.gray_80,
              }}
              qlToolsSx={{
                borderBottom: 'none',
                borderBottomLeftRadius: '0',
                borderBottomRightRadius: '0',
                width: 'unset',
              }}
              qlPlaceholderSx={{
                left: '16px !important',
              }}
            />
          </>
        ) : (
          <>
            {isEdit && (
              <Typography
                variant="bodyMedium"
                color={color.gray_80}
                component="div"
                sx={{
                  borderBottom: `1px solid ${color.line}`,
                  paddingBottom: `10px`,
                  marginBottom: `10px`,
                }}
                dangerouslySetInnerHTML={{
                  __html: introText || '...',
                }}
              ></Typography>
            )}
            <EllipsisText
              text={description}
              length={500}
              color={color.gray_80}
              variant="bodyMedium"
            />
          </>
        )}
      </Description>
    </>
  );
};

export default DescriptionOperations;

const Description = styled.div`
  width: 100%;
`;

const Divider = styled.div`
  border-top: 1px solid ${({ color }) => color};
  margin: 0px 0 20px;
`;
