import {useEffect, useRef, useState} from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { nanoid } from 'nanoid';
import styled from 'styled-components';

import { Sprite } from '@root/assets/svg';
import { Logo } from '@root/components';
import {
  ButtonBase,
  ButtonWithoutBorder,
  CustomScroll,
  IconBtn,
  TextArea,
} from '@root/ui';

import userSelectors from '@redux/user/user-selectors';

import { Drawer, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

const id = nanoid();

export const AISupport = () => {
  const name = useSelector(userSelectors.getUserName);
  const [open, setOpen] = useState(false);
  const [typerOpen, setTyperOpen] = useState(false);
  const [message, setMessage] = useState('');
  const { t } = useTranslation(['common'], { useSuspense: false });
  // scroll ref
  const chatContainerRef = useRef(null);

  const authToken = useSelector(state => state.auth.token)
  const windowWith = useSelector(state => state?.windowWithSlice?.windowWith)

  const [conversation, setConversation] = useState([
    {
      role: 'assistant',
      content: t('common:ai_support.greeting'),
    },
  ]);

  const { color } = useTheme();

  const onChangeText = name => value => {
    setMessage(value);
  };

  const handleSubmit = e => {
    e.preventDefault();

    const userMessage = message.trim();
    const allConversation = [
      ...conversation,
      {
        role: 'user',
        content: userMessage,
      },
    ];

    setConversation(allConversation);
    setMessage('');

    setTyperOpen(true);

    fetch(`${process.env.REACT_APP_AI_BOT_API_URL}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        "Authorization": `Bearer ${authToken}`,
      },
      body: JSON.stringify({ conversation: allConversation }),
    })
      .then(response => response.json())
      .then(({ content }) => {

        console.log('content in AISupport', content)

        if (content) {
          setConversation([
            ...allConversation,
            {
              role: 'assistant',
              content,
            },
          ]);
        } else {
          setConversation([
            ...allConversation,
            {
              role: 'assistant',
              content: t('common:ai_support.errorMessage'),
            },
          ]);
        }
        setTyperOpen(false);
      })
      .catch(error => {
        setConversation([
          ...allConversation,
          {
            role: 'assistant',
            content: t('common:ai_support.errorMessage'),
          },
          setTyperOpen(false),
        ]);
      });
  };

// SCROLL TO BOTTOM WHEN CHAT UPDATES
    const scrollToBottom = () => {
        if (chatContainerRef.current) {
            chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
        }
    };

    useEffect(() => {
        scrollToBottom();
    }, [conversation]);
// END SCROLL TO BOTTOM WHEN CHAT UPDATES


  return (
    <>
      <ButtonWithoutBorder
        onClick={() => setOpen(true)}
        sx={{
          color: color.gray_60,
          background: 'none',
          '&:hover': {
            color: color.main,
            background: 'none',
          },
          minWidth: 'unset',
          marginRight: '18px',
        }}
      >
        <svg width={24} height={24} fill={'none'}>
          <use href={`${Sprite}#icon-support`}></use>
        </svg>
      </ButtonWithoutBorder>

      <Drawer
        sx={{
          width: 0,
          flexShrink: 0,
          height: 0,

          '& .MuiDrawer-paper': {
            position: 'inherit',
            width: windowWith > 550 ?  '500px' : '100vw',
            boxSizing: 'border-box',
            border: `1px solid ${color.line}`,
          },
          ...(open && {
            width: windowWith > 550 ?  '500px' : '100vw',
            height: windowWith > 1024 ? '100%' : '100dvh',
            position: 'fixed',
            top: 0,
            right: '0',
            zIndex: 9000,
          }),
        }}
        variant="persistent"
        anchor="right"
        open={open}
      >
        <Wrapper>
          <Header color={color}>
            <IconBtn
              sx={{ position: 'absolute', top: 0, right: 0 }}
              size="24px"
              href={`${Sprite}#icon-cross`}
              onClick={() => setOpen(false)}
            />

            <Logo sx={{ height: '34px' }} />
            <Typography
                variant={'h1'}
                component={'p'}
                sx={
                    {
                        mt: '5px',
                        fontSize: windowWith > 768 ? '1.75rem' : '1.5rem'
                    }
                }
            >
              {t('common:ai_support.title')}
            </Typography>
          </Header>
          <Answer color={color}>
            {' '}
            <CustomScroll vertical={true} ref={chatContainerRef}>
              <List>
                {conversation && conversation.map(el => {
                  // console.log('el', el)
                  // console.log('el.content', el.content)
                  return (
                      <Item color={color}>
                        <Typography variant={'bodyBoldSmall'} component={'p'}>
                          {el?.role === 'assistant' ? 'Viatec Assistant' : name}:{' '}
                          {/*<Typography variant={'bodySmall'}>*/}
                          {/*  {el.content}*/}
                          {/*</Typography>*/}
                            <Typography
                                variant={'bodySmall'}
                                dangerouslySetInnerHTML={{ __html: el.content }}
                            />

                        </Typography>
                      </Item>
                  )
                })}
              </List>
            </CustomScroll>
          </Answer>

          <WrapperArea
            onSubmit={handleSubmit}
            onKeyDown={e => {
              if (e.key === 'Enter' && !e.shiftKey) {
                handleSubmit(e);
              }
            }}
          >
            <Typer>
              {typerOpen && (
                <>
                  <Typography variant={'bodySmall'}>
                    {t('common:ai_support.typer')}
                  </Typography>
                  <span>
                    <span class="dot">.</span>
                    <span class="dot">.</span>
                    <span class="dot">.</span>
                  </span>
                </>
              )}
            </Typer>

            <TextArea
              sx={{
                padding: '5px',
                border: `1px solid ${color.line}`,
                width: '100%',
              }}
              value={message}
              onChange={onChangeText('description')}
              placeholder={t('common:ai_support.placeholder')}
              type="text"
            />

            <ButtonBase
              type="submit"
              size={'large'}
              sx={{ width: '100%', mt: '5px' }}
              disabled={!message.length > 0}
            >
              {t('common:buttons.sent')}
            </ButtonBase>
          </WrapperArea>
        </Wrapper>
      </Drawer>
    </>
  );
};

const Wrapper = styled.div``;
const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 30px 0px;

  background: ${({ color }) => color.line};
`;

const Answer = styled.div`
  width: 100%;
  background: ${({ color }) => color.gray_20};
  padding: 5px;
  height: calc(100dvh - 365px);
  overflow: hidden;
  margin-bottom: 10px;

  p + p {
    margin-top: 5px;
  }
`;

const WrapperArea = styled.form`
  padding: 5px;
`;


const List = styled.ul`
  padding-right: 5px;

  ul {
    display: flex;
    flex-direction: column;


    li {
      margin-top: 5px;
      a {
        color: #007aff;
        text-decoration: underline;
      }
    }
  }
  img {
    height: 86px;
  }
  li {
    margin-top: 5px;
    a {
      color: #007aff;
      text-decoration: underline;
    }
  }
  li {
    ol {
      padding-left: 16px;
      list-style: auto;
    }
  }
`;

const Item = styled.li`
  display: flex;
  display: block;
  padding: 5px;
  margin-top: 2px;
  margin-bottom: 0px;
  background: ${({ color }) => color.line};
  white-space: pre-line;
  border-radius: 4px;
`;

const Typer = styled.div`
  height: 20px;
  font-size: 12px;

  .dot {
    display: inline-block;
    animation: typing 1s infinite;
    font-weight: bold;
  }

  .dot:nth-child(1) {
    animation-delay: 0.2s;
  }

  .dot:nth-child(2) {
    animation-delay: 0.4s;
  }

  .dot:nth-child(3) {
    animation-delay: 0.6s;
  }

  @keyframes typing {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
`;
