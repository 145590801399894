import { useState } from 'react';
import { useDispatch } from 'react-redux';

import PropTypes from 'prop-types';

import { Sprite } from '@root/assets/svg';
import { MenuBody, MenuButton, MenuItemCustoms } from '@root/ui';

import orderOperation from '@redux/order/order-operation';

import { Typography, useTheme } from '@mui/material';
import { styled } from '@mui/material/styles';

const Svg = styled('svg')(({ theme, colorChosen }) => ({
  fill: colorChosen,
  stroke: colorChosen,
  margin: '0 4px 0 0',
  flexShrink: 0,
}));

const SvgPan = styled('svg')(({ theme }) => ({
  fill: theme.color?.grey_80,
  stroke: theme.color?.grey_80,
  margin: '0  0 0 4px',
  flexShrink: 0,

  ':hover': {
    fill: theme.color?.main,
    stroke: theme.color?.main,
  },
}));

export const StatusLabel = ({ active, statuses, id, onlyAdmin = true }) => {
  const { color } = useTheme();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const dispatch = useDispatch();

  const getColorChosen = codeStatus => {
    switch (codeStatus) {
      case 'reject':
        return color.red;
      case 'done':
      case 'apply':
        return color.green;
      case 'new':
        return color.gray_60;
      default:
        return color.main;
    }
  };

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      {' '}
      {!onlyAdmin ? (
        <>
          {' '}
          <MenuButton open={open} handleClick={handleClick}>
            <Typography
              variant={'bodyBoldSmall'}
              component={'p'}
              sx={{
                color: getColorChosen(active?.code),
                display: 'flex',
                mb: '4px',
              }}
              align={'center'}
            >
              <Svg
                width="16"
                height="16"
                colorChosen={getColorChosen(active?.code)}
              >
                <use href={`${Sprite}#${active?.icon}`}></use>
              </Svg>{' '}
              {active?.label}
              <SvgPan width="16" height="16" colorChosen={color?.grey_80}>
                <use href={`${Sprite}#icon-pan`}></use>
              </SvgPan>
            </Typography>
          </MenuButton>
          <MenuBody
            anchorEl={anchorEl}
            open={open}
            handleClose={handleClose}
            sx={{ width: 'fit-content' }}
            horizontalPosition={'left'}
          >
            {statuses.map(el => (
              <MenuItemCustoms
                handelClick={() => {
                  handleClose();
                  dispatch(
                    orderOperation.orderAdminUpdateStatus({
                      id: id,
                      status: el.id,
                    }),
                  );
                }}
                styled={{
                  '&:active p, &:active svg': {
                    color: `${color.white}!important`,
                    stroke: `${color.white}!important`,
                    fill: `${color.white}!important`,
                  },
                }}
              >
                <Typography
                  variant={'bodyBoldSmall'}
                  component={'p'}
                  sx={{
                    color: getColorChosen(el.code),
                    display: 'flex',
                    mb: '4px',
                  }}
                  align={'center'}
                >
                  <Svg
                    width="16"
                    height="16"
                    colorChosen={getColorChosen(el.code)}
                  >
                    <use href={`${Sprite}#${el.icon}`}></use>
                  </Svg>{' '}
                  {el.label}
                </Typography>
              </MenuItemCustoms>
            ))}
          </MenuBody>
        </>
      ) : (
        <Typography
          variant={'bodyBoldSmall'}
          component={'p'}
          sx={{
            color: getColorChosen(active?.code),
            display: 'flex',
            mb: '4px',
          }}
          align={'center'}
        >
          <Svg
            width="16"
            height="16"
            colorChosen={getColorChosen(active?.code)}
          >
            <use href={`${Sprite}#${active?.icon}`}></use>
          </Svg>{' '}
          {active?.label}
        </Typography>
      )}
    </>
  );
};

StatusLabel.propTypes = {
  active: PropTypes.exact({
    code: PropTypes.string,
    icon: PropTypes.string,
    id: PropTypes.number,
    label: PropTypes.string,
    sort: PropTypes.number,
    status: PropTypes.string,
  }),
  statuses: PropTypes.arrayOf(
    PropTypes.exact({
      code: PropTypes.string,
      icon: PropTypes.string,
      id: PropTypes.number,
      label: PropTypes.string,
      sort: PropTypes.number,
      status: PropTypes.string,
    }),
  ),
  id: PropTypes.number,
  onlyAdmin: PropTypes.bool,
};
