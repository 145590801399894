import {useEffect, useMemo, useRef, useState} from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { NavLink, useLocation, useParams } from 'react-router-dom';

import styled from 'styled-components';

import { Sprite } from '@root/assets/svg';
import { Logo } from '@root/components';
import { getNavMenu } from '@root/conf';
import { BasicTooltip } from '@root/ui';

import authSelectors from '@redux/auth/auth-selectors';
import userSelectors from '@redux/user/user-selectors';

import { useTheme } from '@mui/material/styles';

export const SideBar = ({isSidebarVisible, setIsSidebarVisible}) => {
  const getUserProfile = useSelector(userSelectors.getUserProfile);
  const permission = useSelector(userSelectors.getPermissions);
  const { pathname } = useLocation();
  const { t } = useTranslation(['common']);
  const { lang } = useParams();
  const { logo_small } = useSelector(userSelectors.getUserCompanyConf);
  const currentPath = lang ? pathname.split(`/${lang}`).join('') : pathname;
  const parentFolder = currentPath.split('/')[1];
  const currentLang = lang ? `/${lang}` : '';
  const { color } = useTheme();
  const role = useSelector(authSelectors.getRole);
  const windowWith = useSelector(state => state?.windowWithSlice?.windowWith)


  return useMemo(() => {
    return (
      <NavBox color={color} isSidebarVisible={isSidebarVisible}>
        <ul>
          <Item visible={true}>
            <WrapperLogo color={color}>
              <Logo isSmall src={logo_small} sx={{ height: '29px' }} />
            </WrapperLogo>
          </Item>
          {getNavMenu(t)
            .filter(({ permission_key }) => {
              if (
                permission[permission_key] ||
                permission_key === 'exception' ||
                (permission_key === 'adminpanelTEMP' && role === 'admin')
              )
                return true;
            })
            .map(({ path, icon, name, role, visible }) => {
              const navPath = path.split('/')[1];
              return (
                <Item key={path} visible={!!(visible !== false)} onClick={() => setIsSidebarVisible(false)}>
                  <BasicTooltip title={name} placement="right">
                    <Wrapper
                      color={color}
                      isActive={
                        parentFolder === 'saved'
                          ? `/${navPath}` === `/catalog`
                          : `/${navPath}` === `/${parentFolder}`
                      } //spike because saved page is deferent component accordion to catalog page
                    >
                      <NavLink to={currentLang + path}>
                        <Svg color={color} width="24" height="24">
                          <use href={`${Sprite}#${icon}`}></use>
                        </Svg>
                        {
                            windowWith < 1199 &&
                            <MobileNavButtonLabel>
                                {name}
                            </MobileNavButtonLabel>
                        }
                      </NavLink>
                    </Wrapper>
                  </BasicTooltip>
                </Item>
              );
            })}
        </ul>
      </NavBox>
    );
  }, [color, parentFolder, isSidebarVisible, windowWith]);
};

const NavBox = styled.nav`
  width: 56px;
  min-height: 450px;
  height: 100%;
  transition: 0.2s;

  & ul {
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
  }
  & ul li:last-child {
    flex-grow: 1;
    display: flex;
    align-items: flex-end;
  }

  @media(max-width: 1199px) {
    width: ${props => (props.isSidebarVisible ? '56px' : '0')};
    min-width: ${props => (props.isSidebarVisible ? '56px' : '0')};
    overflow: ${props => (props.isSidebarVisible ? 'visible' : 'hidden')};
    position: relative;

    & ul {
      position: absolute;
      top: 0;
      left: 0;
      transition: 0.219s;
      transform: ${props => (props.isSidebarVisible ? 'translateX(0%)' : 'translateX(-100%)')};
    }
  }
  background: linear-gradient(
    180deg,
    ${({ color }) => color.sidebar_gradient_start} 0%,
    ${({ color }) => color.sidebar_gradient_end} 100%
  );
  /* background: linear-gradient(
    180deg,
    #38b0a7 0%,
    #36a0b0 27.32%,
    #368cb6 52.61%,
    #3677ad 76.87%,
    #3268b2 100%
  ); */
`;

const Item = styled.li`
  display: ${props => (props.visible ? 'block' : 'none')};
`;

const WrapperLogo = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 18px 7px 20px 7px;
  border-bottom: 1px solid ${prop => prop.color.line_light};
`;

const Wrapper = styled.div`
  padding: 16px;
  stroke: ${prop => prop.color.white};
  background: ${prop => prop.isActive && prop.color.bg_opacity};

  &:hover {
    background: ${prop => prop.color.bg_opacity};
  }

  @media(max-width: 1199px) {
    padding: 12px 4px;
    text-align: center;
    width: 56px;

    & a {
      width: 100%;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      overflow: hidden;
      flex-direction: column;
    }
  }
`;

const MobileNavButtonLabel = styled.span`
  font-size: 8px;
  color: #fff;
  display: inline-flex;
  justify-content: center;
  margin-top: 3px;
  word-wrap: anywhere;
  width: 100%;
`;

const Svg = styled.svg``;
