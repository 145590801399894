import * as React from 'react';

import PropTypes from 'prop-types';

import { Empty } from '@root/components';
import IconCheck from '@root/components/IconCheck/index';
import { AvatarUI } from '@root/ui';

import { Typography, useTheme } from '@mui/material';
import TableCell from '@mui/material/TableCell';

export const PeopleAvatar = ({ style, row, hasGuid }) => {
  const { color } = useTheme();
  return (
    <TableCell
      component="th"
      scope="row"
      sx={{
        p: 0,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        ...style,
      }}
    >
      <AvatarUI width={28} height={28} src={row?.avatar}>
        <Empty
          type={'EmptyLogo'}
          isCompany={false}
          name={row?.name}
          noAvatarSx={{
            fontSize: '.9rem',
            lineHeight: '1.4',
          }}
        />
      </AvatarUI>
      <Typography
        sx={{ color: color.gray, marginLeft: '16px', wordBreak: 'break-all' }}
        variant={'bodyBoldMedium'}
        component={'p'}
      >
        {row.name} <IconCheck display={hasGuid} height={16} />
      </Typography>
    </TableCell>
  );
};

PeopleAvatar.propTypes = {
  style: PropTypes.object,
  row: PropTypes.exact({
    avatar: PropTypes.string,
    birthday: PropTypes.string,
    city: PropTypes.string,
    city_id: PropTypes.number,
    company: PropTypes.exact({
      activity_areas: PropTypes.arrayOf(
        PropTypes.exact({ id: PropTypes.number, name: PropTypes.string }),
      ),
      additional_info: PropTypes.string,
      company_type: PropTypes.string,
      company_type_id: PropTypes.number,
      contractor_code: PropTypes.number,
      employees: PropTypes.array,
      employees_quantity: PropTypes.number,
      identification_code: PropTypes.number,
      logo: PropTypes.string,
      name: PropTypes.string,
      official_name: PropTypes.string,
      site: PropTypes.string,
    }),
    created_at: PropTypes.string,
    deleted_at: PropTypes.string,
    deleted_by: PropTypes.string,
    dont_show_logins: PropTypes.number,
    email: PropTypes.string,
    email_verified_at: PropTypes.string,
    ga_active: PropTypes.number,
    ga_secret: PropTypes.string,
    guid: PropTypes.string,
    id: PropTypes.number,
    media: PropTypes.array,
    name: PropTypes.string,
    password_updated_at: PropTypes.string,
    permissions: PropTypes.array,
    phones: PropTypes.arrayOf(
      PropTypes.exact({
        id: PropTypes.number,
        phone: PropTypes.string,
        confirm: PropTypes.bool,
        user_id: PropTypes.number,
        created_at: PropTypes.string,
        updated_at: PropTypes.string,
      }),
    ),
    roles: PropTypes.arrayOf(
      PropTypes.exact({
        created_at: PropTypes.string,
        guard_name: PropTypes.string,
        id: PropTypes.number,
        name: PropTypes.string,
        pivot: PropTypes.exact({
          model_id: PropTypes.number,
          model_type: PropTypes.string,
          role_id: PropTypes.number,
        }),
        updated_at: PropTypes.string,
      }),
    ),
    status: PropTypes.string,
    updated_at: PropTypes.string,
    use_price: PropTypes.string,
  }),
};
