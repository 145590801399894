import { createSlice } from '@reduxjs/toolkit';

import activityOperations from './activity-areas-operation'

const initialState = {
    loading: false,
    list: [],
};

const activityAreasSlice = createSlice({
    name: 'activityAreas',
    initialState,
    reducers: {},
    extraReducers: {
        //====getActivityData====//
        [activityOperations.getActivityData.pending]: (state, action) => {
            state.loading = true
        },
        [activityOperations.getActivityData.fulfilled]: (state, { payload }) => {
            state.list = payload.data
            state.loading = false
        },
        [activityOperations.getActivityData.rejected]: (state, action) => {
            state.loading = false
        },
        //====addNewActivityArea====//
        [activityOperations.addNewActivityArea.pending]: (state, action) => {
            state.loading = true
        },
        [activityOperations.addNewActivityArea.fulfilled]: (state, { payload }) => {
            state.list = [...state.list, payload]
            state.loading = false
        },
        [activityOperations.addNewActivityArea.rejected]: (state, action) => {
            state.loading = false
        },
        //====deleteActivityArea====//
        [activityOperations.deleteActivityArea.pending]: (state, action) => {
            state.loading = true
        },
        [activityOperations.deleteActivityArea.fulfilled]: (state, { payload }) => {

            state.list = state.list.filter(item => item.id !== payload)
            state.loading = false
        },
        [activityOperations.deleteActivityArea.rejected]: (state, action) => {
            state.loading = false
        },
        //====updateActivityArea====//
        [activityOperations.updateActivityArea.pending]: (state, action) => {
            state.loading = true
        },
        [activityOperations.updateActivityArea.fulfilled]: (state, { payload }) => {
            const updatedList = state.list.map(item => {
                if (item.id === payload.id) return payload
                return item
            })
            state.list = updatedList
            state.loading = false
        },
        [activityOperations.updateActivityArea.rejected]: (state, action) => {
            state.loading = false
        },
    },
});

export default activityAreasSlice.reducer;
