import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import { HeaderDefault, PasswordLights } from '@root/components';
import { PasswordConf } from '@root/conf';
import {
  getErrorStack,
  handleDispatchFetch,
  validPassword,
} from '@root/helpers';
import { ButtonBase, ModalBase, PasswordInput } from '@root/ui';

import userOperation from '@redux/user/user-operation';

import { Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

export const ChangePasswordModal = ({
  open,
  handleCloseModal,
  handleOpenNotification,
}) => {
  const { color } = useTheme();
  const { t } = useTranslation(['validation', 'registration', 'profile'], {
    useSuspense: false,
  });
  const [passwordInfo, setPasswordInfo] = useState({
    old_password: '',
    password: '',
    confirm_password: '',
  });
  const [error, setError] = useState({});
  const [isVisibleValid, setIsVisibleValid] = useState(false);
  const [steps, setSteps] = useState(PasswordConf(t));
  const [submitPressed, setSubmitPressed] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    const { password } = passwordInfo;
    if (!password || password === '') return;
    const stepsCopy = [...steps];

    if (!isVisibleValid) setIsVisibleValid(true);
    if (validPassword(password, { minUppercase: 1 })) {
      stepsCopy[0].status = true;
    } else {
      stepsCopy[0].status = false;
    }
    if (validPassword(password, { minLowercase: 1 })) {
      stepsCopy[1].status = true;
    } else {
      stepsCopy[1].status = false;
    }

    if (validPassword(password, { minNumbers: 1 })) {
      stepsCopy[2].status = true;
    } else {
      stepsCopy[2].status = false;
    }

    if (password.length >= 8) {
      stepsCopy[3].status = true;
    } else {
      stepsCopy[3].status = false;
    }

    setSteps(stepsCopy);
  }, [passwordInfo.password]);

  useEffect(() => {
    if (submitPressed) {
      validation();
    }
  }, [passwordInfo, submitPressed]);

  const onChange = (key, value) => {
    setPasswordInfo(prevPasswordInfo => ({
      ...prevPasswordInfo,
      [key]: value,
    }));
  };

  const validation = () => {
    let flag = false;
    let currentError = {};

    const { old_password, password, confirm_password } = passwordInfo;

    Object.keys({
      old_password,
      password,
      confirm_password,
    }).forEach(key => {
      if (!passwordInfo[key] || passwordInfo[key] === '') {
        currentError = getErrorStack(
          currentError,
          key,
          t('validation:required'),
        );
        flag = true;
      }
    });

    if (
      !validPassword(password, {
        minLowercase: 1,
        minUppercase: 1,
        minNumbers: 1,
        minLength: 8,
      }) &&
      password !== ''
    ) {
      currentError = getErrorStack(
        currentError,
        'password',
        t('validation:password_not_secure'),
      );
      flag = true;
    }

    if (confirm_password !== password && confirm_password !== '') {
      currentError = getErrorStack(
        currentError,
        'confirm_password',
        t('validation:password_not_match'),
      );
      flag = true;
    }

    setError(currentError);
    return flag;
  };

  const onSubmit = e => {
    e.preventDefault();
    setSubmitPressed(true);
    if (validation()) return;

    const { old_password, password, confirm_password } = passwordInfo;
    handleDispatchFetch(
      ({ onResolve, onReject }) =>
        dispatch(
          userOperation.changePassword({
            onResolve,
            onReject,
            password: old_password,
            password_new: password,
            password_confirm: confirm_password,
          }),
        ),
      () => {
        handleOpenNotification();
        handleCloseModal();
      },
      message => {
        setError({
          old_password: { init: true, text: message },
        });
      },
    );
  };

  return (
    <>
      <ModalBase
        open={open}
        handleClose={handleCloseModal}
        sx={{ width: '100%', maxWidth: '542px' }}
        modalHeader={
          <HeaderDefault
            text={t('profile:security_page.modals.change_password_modal.title')}
            onClose={handleCloseModal}
          />
        }
        modalBottom={
          <ButtonWrapper>
            <ButtonBase type="submit" form="change-password-form">
              {t('common:buttons.change_password')}
            </ButtonBase>
          </ButtonWrapper>
        }
      >
        <ChangePasswordModalWrapper>
          <Typography
            color={color.gray_80}
            variant="bodyMedium"
            component="p"
            marginTop="7px"
          >
            {t(
              'profile:security_page.modals.change_password_modal.description',
            )}
          </Typography>
          <Form onSubmit={onSubmit} id="change-password-form">
            <ChangePasswordModalBody>
              <InputsWrapper>
                <PasswordInput
                  value={passwordInfo.old_password}
                  error={error?.old_password?.init}
                  errorText={error?.old_password?.text}
                  sx={{ maxWidth: '263px', marginTop: '20px', height: '48px' }}
                  placeholder={t('profile:fields.current_password')}
                  onChange={value => {
                    onChange('old_password', value);
                  }}
                />
                <PasswordInput
                  error={error?.password?.init}
                  errorText={error?.password?.text}
                  value={passwordInfo.password}
                  sx={{ maxWidth: '263px', marginTop: '20px', height: '48px' }}
                  onChange={value => {
                    onChange('password', value);
                  }}
                  placeholder={t('registration:field.password', { req: '*' })}
                />
                <PasswordInput
                  error={error?.confirm_password?.init}
                  errorText={error?.confirm_password?.text}
                  value={passwordInfo.confirm_password}
                  sx={{ maxWidth: '263px', marginTop: '20px', height: '48px' }}
                  onChange={value => {
                    onChange('confirm_password', value);
                  }}
                  placeholder={t('registration:field.duplicate_password', {
                    req: '*',
                  })}
                />
              </InputsWrapper>

              <PasswordVerificationWrapper>
                {isVisibleValid &&
                  steps.map(({ status, text }) => (
                    <PasswordLights status={status} text={text} key={text} />
                  ))}
              </PasswordVerificationWrapper>
            </ChangePasswordModalBody>
          </Form>
        </ChangePasswordModalWrapper>
      </ModalBase>
    </>
  );
};

const ChangePasswordModalWrapper = styled.div``;

const ChangePasswordModalBody = styled.div`
  display: flex;
  column-gap: 15px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 21px;
`;

const Form = styled.form``;

const InputsWrapper = styled.div`
  max-width: 263px;
  width: 100%;
`;

const PasswordVerificationWrapper = styled.div`
  margin-top: 88px;
`;

ChangePasswordModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleCloseModal: PropTypes.func.isRequired,
  handleOpenNotification: PropTypes.func.isRequired,
};
