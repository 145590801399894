export const BusinessConf = t => [
  {
    label: '',
    value: 'checkBox',
    type: 'checkBox',
    style: active =>
      active ? { ml: '32px', width: '16px' } : { ml: '32px', width: '16px' },
  },
  {
    label: t('admin:business_table_conf.logo'),
    value: 'companyLogo',
    type: 'img',
    style: active =>
      active ? { ml: '16px', width: '100px' } : { ml: '16px', width: '100px' },
    hide: true,
  },
  {
    label: t('admin:business_table_conf.company_name', { req: '*' }),
    value: 'name',
    type: 'name',
    style: active =>
      active ? { ml: '16px', width: '290px' } : { ml: '16px', width: '290px' },
  },
  {
    label: t('admin:business_table_conf.number_of_employees'),
    value: 'numberOfEmployees',
    type: 'number',
    style: active =>
      active ? { ml: '35px', width: '100px' } : { ml: '35px', width: '100px' },
    hide: true,
  }, // change
  {
    label: t('admin:business_table_conf.site'),
    value: 'site',
    type: 'site',
    style: active =>
      active ? { ml: '14px', width: '208px' } : { ml: '39px', width: '208px' },
  }, // change
  {
    label: t('admin:business_table_conf.areas_of_activity'),
    value: 'areasOfActivity',
    type: 'areasOfActivity',
    style: active =>
      active ? { ml: '14px', width: '126px' } : { ml: '48px', width: '126px' },
  }, // change
  {
    label: t('admin:business_table_conf.status'),
    value: 'status',
    type: 'status',
    style: active =>
      active ? { ml: '25px', width: '137px' } : { ml: '25px', width: '137px' },
    hide: true,
  },
  {
    label: '',
    value: 'edit',
    type: 'action',
    style: active => (active ? { ml: 'auto' } : { ml: 'auto' }),
    accordionData: ['Переглянути', 'Редагувати', 'Архівувати'],
  },
];
