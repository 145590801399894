import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import moment from 'moment';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { ContactField } from '@root/components';
import { useNavigation } from '@root/hooks';
import { ButtonBase } from '@root/ui';

import authOperation from '@redux/auth/auth-operation';

import { Typography, useTheme } from '@mui/material';
import withWidth from "@mui/material/Hidden/withWidth";

export const SendApplication = ({ initData, pathParrent, windowWith }) => {
  const [data, setData] = useState({});
  const dispatch = useDispatch();
  const { navigation } = useNavigation();
  const { t } = useTranslation(['registration', 'validation', 'common'], {
    useSuspense: false,
  });
  const { color } = useTheme();

  useEffect(() => {
    setData(initData);
  }, [initData]);

  const handelSubmit = e => {
    e.preventDefault();
    dispatch(
      authOperation.signUpStepSixth({
        navigate: () => navigation('/successfully-sign-up'),
      }),
    );
  };

  const { email, name, phones, birthday, city, company, avatar } = data;

  const checkFieldValue = (fieldValue, type) => {
    let resultValue = fieldValue ? fieldValue : '-';

    if (type === 'date') {
      resultValue = fieldValue ? moment(fieldValue).format('DD.MM.YYYY') : '-';
    }

    return resultValue;
  };

  return (
    <Wrapper>
      {Object.keys(data).length > 0 && (
        <Form onSubmit={handelSubmit}>
          <Typography variant={'h1'} align={'center'}>
            {t('registration:step_six.tittle')}
          </Typography>
          <Typography
            sx={{ mt: '28px' }}
            variant={'bodyLarge'}
            align={'center'}
            component={'p'}
          >
            {t('registration:step_six.slogan')}
          </Typography>
          <WrapperStep>
            <Typography
              variant={'bodyBoldSmall'}
              align={'center'}
              sx={{
                width: '100%',
                background: color.line_light,
                borderRadius: '4px',
                py: '8px',
                color: color.gray_80,
              }}
            >
              {t('registration:step_six.profile')}
            </Typography>

            <ContactField
              title={'Email'}
              value={email}
              sx={{ color: color.gray_80 }}
            />
          </WrapperStep>

          <WrapperStep>
            <Typography
              variant={'bodyBoldSmall'}
              align={'center'}
              sx={{
                width: '100%',
                background: color.line_light,
                borderRadius: '4px',
                py: '8px',
                color: color.gray_80,
              }}
            >
              {t('registration:step_six.contact_info')}
            </Typography>
            <ContactField
              type={'img'}
              value={avatar}
              subValue={name}
              goToStep={() => navigation(`${pathParrent}/1?edit=true`)}
              sx={{ color: color.gray_80 }}
            />
            <ContactField
              title={t('registration:field.name', { req: '' })}
              value={name}
              goToStep={() => navigation(`${pathParrent}/1?edit=true`)}
              sx={{ color: color.gray_80 }}
            />
            <ContactField
              lastStep={true}
              type={'phone'}
              title={t('registration:field.phone')}
              value={phones}
              goToStep={() => navigation(`${pathParrent}/1?edit=true`)}
              sx={{ color: color.gray_80 }}
            />
            <ContactField
              title={t('registration:field.birthday')}
              value={checkFieldValue(birthday, 'date')}
              goToStep={() => navigation(`${pathParrent}/1?edit=true`)}
              sx={{ color: color.gray_80 }}
            />
            <ContactField
              title={t('registration:field.city')}
              value={checkFieldValue(city)}
              goToStep={() => navigation(`${pathParrent}/1?edit=true`)}
              sx={{ color: color.gray_80 }}
            />
          </WrapperStep>

          <WrapperStep>
            <Typography
              variant={'bodyBoldSmall'}
              align={'center'}
              sx={{
                width: '100%',
                background: color.line_light,
                borderRadius: '4px',
                py: '8px',
                color: color.gray_80,
              }}
            >
              {t('registration:step_six.company_info')}
            </Typography>
            {}
            <ContactField
              type={'companyLogo'}
              value={company.logo}
              goToStep={() => navigation(`${pathParrent}/2?edit=true`)}
              sx={{ color: color.gray_80 }}
            />
            <ContactField
              title={t('registration:field.legal_name_of_the_company', {
                req: '',
              })}
              value={company.official_name}
              goToStep={() => navigation(`${pathParrent}/2?edit=true`)}
              sx={{ color: color.gray_80 }}
            />
            {company.contractor_code && (
              <ContactField
                title={t('registration:field.company_registration_number', {
                  req: '',
                })}
                value={company.contractor_code}
                goToStep={() => navigation(`${pathParrent}/2?edit=true`)}
                sx={{ color: color.gray_80 }}
              />
            )}

            {company.identification_code && (
              <ContactField
                title={t('registration:field.ipn_company')}
                value={company.identification_code}
                goToStep={() => navigation(`${pathParrent}/2?edit=true`)}
                sx={{ color: color.gray_80 }}
              />
            )}

            <ContactField
              title={t('registration:field.number_of_employees')}
              value={checkFieldValue(company.employees_quantity)}
              goToStep={() => navigation(`${pathParrent}/3?edit=true`)}
              sx={{ color: color.gray_80 }}
            />

            <ContactField
              title={t('registration:field.site_url')}
              value={checkFieldValue(company.site)}
              goToStep={() => navigation(`${pathParrent}/3?edit=true`)}
              sx={{ color: color.gray_80 }}
            />

            <ContactField
              //   type={'area'}
              title={t('registration:field.additional_info')}
              value={checkFieldValue(company.additional_info)}
              goToStep={() => navigation(`${pathParrent}/3?edit=true`)}
              sx={{ color: color.gray_80 }}
            />
          </WrapperStep>

          <WrapperStep>
            <Typography
              variant={'bodyBoldSmall'}
              align={'center'}
              sx={{
                width: '100%',
                background: color.line_light,
                borderRadius: '4px',
                py: '8px',
                color: color.gray_80,
              }}
            >
              {t('registration:step_six.info')}
            </Typography>
            {}
            <ContactField
              title={t('registration:field.type_of_activity')}
              value={company.activity_areas.map(item => item.name).join(', ')}
              goToStep={() => navigation(`${pathParrent}/3?edit=true`)}
              sx={{ color: color.gray_80 }}
            />
          </WrapperStep>

          <ButtonBase
            type="submit"
            size={'large'}
            sx={{
              mt: '28px',
              position: 'sticky',
              bottom: '20px',
              width: windowWith > 991 ? '488px' : '100%',
            }}
          >
            <Typography variant={'bodyBoldLarge'}>
              {t('common:buttons.sent')}
            </Typography>
          </ButtonBase>
        </Form>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding-bottom: 50px;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 488px;

  @media(max-width: 991px) {
    width: 100%;
    max-width: 500px;

    p.MuiTypography-root.MuiTypography-bodyLarge {
      margin-right: 5px;
    }
  }
`;

const WrapperStep = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 28px;
`;

SendApplication.propTypes = {
  initData: PropTypes.exact({
    avatar: PropTypes.string,
    birthday: PropTypes.string,
    city: PropTypes.string,
    city_id: PropTypes.number,
    company: PropTypes.exact({
      activity_areas: PropTypes.arrayOf(
        PropTypes.exact({ id: PropTypes.number, name: PropTypes.string }),
      ),
      additional_info: PropTypes.string,
      company_type: PropTypes.string,
      company_type_id: PropTypes.number,
      contractor_code: PropTypes.number,
      employees: PropTypes.array,
      employees_quantity: PropTypes.number,
      identification_code: PropTypes.number,
      logo: PropTypes.string,
      name: PropTypes.string,
      official_name: PropTypes.string,
      site: PropTypes.string,
    }),
    currency_rates: PropTypes.array,
    email: PropTypes.string,
    ga_is_active: PropTypes.bool,
    id: PropTypes.number,
    name: PropTypes.string,
    np_api_key: PropTypes.string,
    password_updated_at: PropTypes.string,
    phones: PropTypes.arrayOf(
      PropTypes.exact({ confirm: PropTypes.bool, phone: PropTypes.string }),
    ),
    prices: PropTypes.array,
    role: PropTypes.arrayOf(PropTypes.string),
    show_logins: PropTypes.bool,
    status: PropTypes.string,
  }),
  pathParrent: PropTypes.string.isRequired,
};
