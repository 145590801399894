import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import styled from 'styled-components';

import { ProfileList } from '@root/components';
import { useNavigation } from '@root/hooks';
import { ProgressLoader } from '@root/ui';

import permissionOperation from '@redux/permission/permission-operation';
import permissionSelectors from '@redux/permission/permission-selectors';
import userSelectors from '@redux/user/user-selectors';

import { AddNewRoleModal } from './AddNewRoleModal';
import { PermissionDetails } from './PermissionDetails';

export const Roles = () => {
  const [tabDetails, setTabDetails] = useState({
    active: 0,
    roleId: null,
  });
  const [open, setOpen] = useState(false);
  const loading = useSelector(permissionSelectors.getLoading);
  const permissionList = useSelector(permissionSelectors.getPermission);
  const permissionById = useSelector(permissionSelectors.getPermissionConf);
  const permission = useSelector(userSelectors.getPermissions);
  const { t } = useTranslation('profile');

  const dispatch = useDispatch();
  const { navigation } = useNavigation();

  useEffect(() => {
    if (!permission.profile_permissions_access) {
      navigation('/error-403');
      return;
    }

    dispatch(permissionOperation.getPermission({}));
  }, []);

  useEffect(() => {
    if (permissionList.length > 0 && !permissionById) {
      dispatch(
        permissionOperation.getPermissionById({ id: permissionList[0].id }),
      );
      setTabDetails({ active: 0, roleId: permissionList[0].id });
    }
  }, [permissionList]);

  const handleTabClick = (index, roleId) => {
    setTabDetails({
      active: index,
      roleId,
    });
    dispatch(permissionOperation.getPermissionById({ id: roleId }));
  };

  return (
    <ContentWrap>
      {loading ? (
        <ProgressLoader />
      ) : (
        <>
          <WrapperList>
            <ProfileList
              handleTabClick={handleTabClick}
              activeTab={tabDetails?.active}
              list={permissionList}
              openModal={() => setOpen(true)}
              titleList={t('profile:permission.title')}
              titleBtn={t('profile:permission.add_btn')}
              isShowBtn={permission.profile_permissions_create_access}
            />
          </WrapperList>
          <PermissionDetails handleTabClick={handleTabClick} />
        </>
      )}

      {open && (
        <AddNewRoleModal
          open={open}
          handleClose={() => setOpen(false)}
          handleRedirectToNewRole={handleTabClick}
        />
      )}
    </ContentWrap>
  );
};

const ContentWrap = styled.div`
  display: flex;
  gap: 17px;
  height: calc(100vh - 326px);

  @media (max-width: 1024px) {
    height: auto;
  }

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const WrapperList = styled.div`
  flex: 0 0 184px;
`;
