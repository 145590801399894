import React, { useEffect, useMemo, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import styled from 'styled-components';

import { Sprite } from '@root/assets/svg';
import { CharacteristicsInfo } from '@root/components/CharacteristicsInfo/index';
import productOperation from '@root/redux/product/product-operation';
import productSelectors from '@root/redux/product/product-selectors';
import {
  addPropsToEdit,
  clearBlockArray,
  deleteProductPropsBlock,
  deleteProp,
  editProductBlockName,
  editPropValue,
  setProductPropsToEdit,
  togglePin,
} from '@root/redux/product/product-slice';
import { theme } from '@root/styles';
import { BasicSelect, ButtonWithoutBorder } from '@root/ui';

import { Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

export const Characteristics = ({ isEdit, characteristics, itemId }) => {
  const { t } = useTranslation(['product_details', 'common', 'catalog'], {
    useSuspense: false,
  });
  const { color } = useTheme();
  const dispatch = useDispatch();
  const [editPropIndex, setEditPropIndex] = useState({});
  const getProductProps = useSelector(productSelectors.getProductProps);
  const getPropsCategorys = useSelector(productSelectors.getPropsCategorys);
  const getPropsBlocks = useSelector(productSelectors.getPropsBocls);
  const [selctedCategory, setSelectedCategory] = useState(null);
  const [selectedBlock, setSelectedBlock] = useState(null);
  const [isReadyToAdd, setIsReadyToAdd] = useState(false);
  const [isAddCharacteristic, setIsAddCharacteristic] = useState(false);

  const itemCharacteristicsMemo = useMemo(() => {
    return isEdit ? getProductProps : characteristics;
  }, [isEdit, getProductProps, characteristics]);

  useEffect(() => {
    if (itemId) {
      dispatch(productOperation.getProductPropsToEdit({ id: itemId }));
    }
  }, [itemId]);

  const fetchBlocks = async id => {
    dispatch(productOperation.getBlocksByCategoryToEdit({ id }));
  };

  useEffect(() => {
    if (isEdit) {
      dispatch(productOperation.getCategorysToEdit({}));
    }
    setEditPropIndex({});
  }, [isEdit]);

  const editBlockValue = (blockIdx, propIdx, value) => {
    dispatch(editPropValue({ blockIdx, propIdx, value }));
  };

  const onTogglePin = (blockIdx, propIdx) => {
    dispatch(togglePin({ blockIdx, propIdx }));
  };

  const deleteBlock = (blockIdx, propIdx) => {
    dispatch(deleteProp({ blockIdx, propIdx }));
  };

  const addCharacteristic = () => {
    const blockObj = getPropsBlocks.find(el => el.id === selectedBlock);
    dispatch(addPropsToEdit(blockObj));
    setSelectedCategory(null);
    setSelectedBlock(null);
    setIsAddCharacteristic(false);
    setIsReadyToAdd(false);
  };

  const onChangeSelect = async (key, value) => {
    switch (key) {
      case 'categoryId': {
        setSelectedCategory(value);
        await fetchBlocks(value);
        break;
      }
      case 'blockId': {
        setSelectedBlock(value);
        setIsReadyToAdd(true);
        break;
      }
      default:
        break;
    }
  };

  const onBlockDelete = blockIdx => {
    dispatch(deleteProductPropsBlock({ blockIdx }));
  };

  const onBlockNameChange = (value, blockIdx) => {
    dispatch(editProductBlockName({ value, blockIdx }));
  };

  const onDragEnd = result => {
    const { destination, source, draggableId, type } = result;
    if (!destination) {
      return;
    }
    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }

    if (type === 'block') {
      const indexSource = source.index;
      const indexDestination = destination.index;
      let newBlocks = [...itemCharacteristicsMemo];
      const element = newBlocks.splice(indexSource, 1)[0];
      newBlocks.splice(indexDestination, 0, element);
      dispatch(setProductPropsToEdit(newBlocks));
    }

    if (type.includes('prop')) {
      const blockId = type.split('-')[1];
      const newBlocks = [...itemCharacteristicsMemo];
      let newProps = [...newBlocks[blockId].properties];

      const indexSource = source.index;
      const indexDestination = destination.index;

      const element = newProps.splice(indexSource, 1)[0];
      newProps.splice(indexDestination, 0, element);

      newBlocks[blockId] = { ...newBlocks[blockId], properties: newProps };
      dispatch(setProductPropsToEdit(newBlocks));
    }
  };

  return (
    <>
      {(characteristics.length > 0 || isEdit) && (
        <CharacteristicsWrapper id="property_categories">
          <CharacteristicsHeader>
            <Typography
              variant={'h2'}
              sx={{
                color: theme.color.gray,
              }}
            >
              {t('common:characteristics')}
            </Typography>
            {isEdit && (
              <ButtonWithoutBorder
                onClick={() => {
                  setSelectedCategory(null);
                  setSelectedBlock(null);
                  setIsAddCharacteristic(!isAddCharacteristic);
                  setIsReadyToAdd(false);
                  dispatch(clearBlockArray());
                }}
                sx={{
                  color: theme.color.gray_60,
                  alignItems: 'center',
                  justifyContent: 'flex-start',
                  marginLeft: '12px',
                  padding: '0',
                  background: 'none',
                  '&:hover': {
                    background: 'none',
                  },
                }}
              >
                <svg width={12.5} height={12.5} fill={'#8094AE'}>
                  <use href={`${Sprite}#plus-icon`}></use>
                </svg>
                <Typography
                  sx={{
                    color: theme.color.gray,
                    fontWeight: '700',
                    fontSize: '12px',
                    marginLeft: '4px',
                  }}
                >
                  {t('catalog:buttons.add_characteristic')}
                </Typography>
              </ButtonWithoutBorder>
            )}
          </CharacteristicsHeader>
          {isEdit && isAddCharacteristic && (
            <FieldsWrapper>
              {getPropsCategorys && getPropsCategorys.length && (
                <SelectField color={color}>
                  <BasicSelect
                    sx={{
                      color: theme.color.gray,
                      fontWeight: '400',
                      fontSize: '14px',
                      height: '36px',
                      width: '100%',
                    }}
                    name={t('catalog:category_placeholder')}
                    // currentId={selctedCategory}
                    callBack={value => onChangeSelect('categoryId', value)}
                    data={getPropsCategorys}
                  />
                </SelectField>
              )}

              {getPropsBlocks && !!getPropsBlocks.length && (
                <SelectField color={color}>
                  <BasicSelect
                    sx={{
                      color: theme.color.gray,
                      fontWeight: '400',
                      fontSize: '14px',
                      height: '36px',
                      width: '100%',
                    }}
                    name={t('catalog:characteristic_placeholder')}
                    // currentId={selectedBlock}
                    callBack={value => onChangeSelect('blockId', value)}
                    data={getPropsBlocks}
                  />
                </SelectField>
              )}
            </FieldsWrapper>
          )}
          {isEdit && isAddCharacteristic && isReadyToAdd && (
            <ButtonWithoutBorder
              onClick={addCharacteristic}
              sx={{
                color: theme.color.gray_60,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-start',
                marginLeft: '12px',
                padding: '0',
                background: 'none',
                '&:hover': {
                  background: 'none',
                },
              }}
            >
              <svg width={12.5} height={12.5} fill={'#8094AE'}>
                <use href={`${Sprite}#plus-icon`}></use>
              </svg>
              <Typography
                sx={{
                  color: theme.color.gray,
                  fontWeight: '700',
                  fontSize: '12px',
                  marginLeft: '4px',
                }}
              >
                {t('catalog:buttons.add_new_characteristic')}
              </Typography>
            </ButtonWithoutBorder>
          )}
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId={`blocks`} type={`block`}>
              {provided => (
                <div
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  style={{ width: '100%' }}
                >
                  {itemCharacteristicsMemo.map((block, idx) => (
                    <>
                      {idx === 0 ? (
                        <CharacteristicsInfo
                          dragHandleProps={provided.dragHandleProps}
                          data={block}
                          blockIndex={idx}
                          isEdit={isEdit}
                          editBlockValue={editBlockValue}
                          deleteBlock={deleteBlock}
                          categoryId={block.id}
                          onTogglePin={onTogglePin}
                          editPropIndex={editPropIndex}
                          onEditBtnClick={(blockIndex, idx) => {
                            setEditPropIndex({ blockIndex, propId: idx });
                          }}
                          onBlockNameChange={onBlockNameChange}
                        />
                      ) : (
                        <Draggable
                          draggableId={`block${idx}`}
                          key={`block${idx}`}
                          index={idx}
                          isDragDisabled={idx === 0}
                        >
                          {provided => (
                            <div
                              {...provided.draggableProps}
                              ref={provided.innerRef}
                            >
                              <CharacteristicsInfo
                                dragHandleProps={provided.dragHandleProps}
                                data={block}
                                blockIndex={idx}
                                isEdit={isEdit}
                                editBlockValue={editBlockValue}
                                deleteBlock={deleteBlock}
                                categoryId={block.id}
                                onTogglePin={onTogglePin}
                                editPropIndex={editPropIndex}
                                onEditBtnClick={(blockIndex, idx) => {
                                  setEditPropIndex({ blockIndex, propId: idx });
                                }}
                                onBlockDelete={() => {
                                  onBlockDelete(idx);
                                }}
                                onBlockNameChange={onBlockNameChange}
                              />
                            </div>
                          )}
                        </Draggable>
                      )}
                    </>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </CharacteristicsWrapper>
      )}
    </>
  );
};

const FieldsWrapper = styled.div`
  width: 100%;
  padding: 16px 0;
`;

const SelectField = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${({ color }) => color.line};
  padding: 8px 0;
`;
const CharacteristicsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 34px 103px;

  @media(max-width: 1199px) {
    padding: 15px 0;
  }
`;

const CharacteristicsHeader = styled.div`
  display: flex;
  align-items: center;
`;
