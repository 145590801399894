import { useTranslation } from 'react-i18next';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import { HeaderDefault } from '@root/components';
import { ButtonBase, ModalBase, TextInput } from '@root/ui';

import { useTheme } from '@mui/material/styles';

export const CheckSecretKeyModal = ({
  open,
  handleCloseModal,
  data,
  setData,
  onChange,
  onSubmit,
  error,
}) => {
  const { color } = useTheme();
  const { t } = useTranslation(['profile', 'common'], { useSuspense: false });

  return (
    <ModalBase
      open={open}
      handleClose={handleCloseModal}
      sx={{ width: '100%', maxWidth: '542px' }}
      modalHeader={
        <HeaderDefault
          text={t('profile:security_page.modals.check_secret_key_modal.title')}
          onClose={handleCloseModal}
        />
      }
      modalBottom={
        <ButtonWrapper>
          <ButtonBase onClick={onSubmit}>
            {t('common:buttons.confirm')}
          </ButtonBase>
        </ButtonWrapper>
      }
    >
      <CheckSecretKeyModalWrapper>
        <TextInput onChange={onChange('secret')} value={data.secret} />
      </CheckSecretKeyModalWrapper>
    </ModalBase>
  );
};

const CheckSecretKeyModalWrapper = styled.div``;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
`;

CheckSecretKeyModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleCloseModal: PropTypes.func.isRequired,
  data: PropTypes.exact({
    email: PropTypes.string,
    password: PropTypes.string,
    secret: PropTypes.string,
    rememberMe: PropTypes.bool,
  }),
  setData: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  error: PropTypes.object,
};
