import PropTypes from 'prop-types';

import { Checked } from '@root/assets/svg';

import Checkbox from '@mui/material/Checkbox';
import { styled } from '@mui/material/styles';

const BpIcon = styled('span')(({ theme, width, error }) => ({
  borderRadius: '2px',
  width: `${width}px`,
  height: `${width}px`,
  border: error
    ? `1px solid ${theme.color.red}`
    : `1px solid ${theme.color.line}`,
  'span:active &': {
    border: `1px solid ${theme.color.main}`,
  },
  'span:hover &': {
    backgroundColor: theme.color.gray_20,
  },
  padding: '0',
}));

const Container = styled('label')(({ theme, width, error }) => ({
  display: 'flex',
  cursor: 'pointer',
  //   width: width,
  //   height: width,
}));

const CheckIcon = styled('img')(({ theme, disabled }) => ({
  borderRadius: '2px',
  color: theme.color.main,
  backgroundColor: disabled ? theme.color.gray_40 : theme.color.main,
}));

export const CheckboxBase = ({
  checked,
  disable,
  onChange,
  width,
  error,
  children,
  widthLabel = 'auto',
  ...prop
}) => {
  const handelChange = e => onChange(!checked);
  return (
    <Container width={width}>
      <Checkbox
        checked={disable ? true : checked ? checked : false}
        checkedIcon={
          <CheckIcon
            src={Checked}
            alt="Checked Icon"
            disabled={disable}
            width={width}
          />
        }
        disabled={disable}
        onChange={e => handelChange(e)}
        sx={{
          width: `${width}px`,
          height: `${width}px`,
          padding: '0',
          borderRadius: '2px',
          alignItems: 'center',
          backgroundColor: '#fff'
        }}
        disableRipple
        icon={<BpIcon width={width} error={error} />}
        {...prop}
      />
      {children}
    </Container>
  );
};

CheckboxBase.propTypes = {
  checked: PropTypes.bool.isRequired,
  disable: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  width: PropTypes.number,
  error: PropTypes.string,
  children: PropTypes.node,
  widthLabel: PropTypes.string,
};
