import { useEffect, useState } from 'react';
import DatePicker, {
  CalendarContainer,
  registerLocale,
} from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useLocation } from 'react-router-dom';

import { addDays } from 'date-fns';
import uk from 'date-fns/locale/uk';
import moment from 'moment';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { useLang } from '@root/hooks';

import { useTheme } from '@mui/material';

import { OutlinedInputCustom } from '../../style/OutlinedInput';
import { customDatePickerHeader } from '../ui';

registerLocale('uk', uk);

export const DatePickerCustom = ({
  onChange,
  value,
  placeholder,
  minDate = false,
  maxDate = true,
  minValue = '',
  maxValue = '',
  dataTestId,
  sx,
  error,
  errorText,
  ...prop
}) => {
  const [monthsList, setMonthsList] = useState([]);
  const [date, setDate] = useState(value ? new Date(value) : '');
  const { checkLanguageFromURL } = useLang();
  const { pathname } = useLocation();
  const { color } = useTheme();
  const today = new Date();

  useEffect(() => {
    moment.locale(checkLanguageFromURL(pathname));
    setMonthsList(moment.months());
  }, []);

  const MyContainer = ({ className, children }) => {
    return (
      <div
        style={{
          color: color.white,
          borderRadius: '4px',
          height: '100%',
        }}
      >
        <CalendarContainer className={className}>
          <div style={{ position: 'relative' }}>{children}</div>
        </CalendarContainer>
      </div>
    );
  };

  const selectDateHandler = d => {
    if (!d) {
      setDate('');
      onChange('');
      return;
    }
    setDate(d);
    onChange(moment(d).format('YYYY-MM-DD'));
  };

  useEffect(() => {
    if (!value || value === '') {
      setDate('');
    } else if (value) {
      setDate(new Date(value));
    }
  }, [value]);
  return (
    <Label>
      <DatePicker
        locale={checkLanguageFromURL(pathname)}
        dateFormat="dd/MM/yyyy"
        selected={date}
        onChange={selectDateHandler}
        minDate={minDate ? addDays(today, 3) : minValue}
        maxDate={maxDate ? today : maxValue}
        customInput={
          <OutlinedInputCustom
            sx={{
              width: '100% !important',
              cursor: 'pointer',
              ...sx,
              '& input': {
                cursor: 'pointer',
                color: color.gray,
              },
            }}
            data-test-id={dataTestId}
            error={error}
            errorText={errorText}
          />
        }
        placeholderText={placeholder}
        calendarContainer={MyContainer}
        renderCustomHeader={({
          date,
          changeYear,
          changeMonth,
          decreaseMonth,
          increaseMonth,
          prevMonthButtonDisabled,
          nextMonthButtonDisabled,
        }) =>
          customDatePickerHeader(
            date,
            changeYear,
            changeMonth,
            decreaseMonth,
            increaseMonth,
            prevMonthButtonDisabled,
            nextMonthButtonDisabled,
            monthsList,
            minDate,
          )
        }
        {...prop}
      />
    </Label>
  );
};

const Label = styled.label`
  width: 100%;
  .react-datepicker-wrapper {
    width: 100%;
  }
`;

DatePickerCustom.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  minDate: PropTypes.bool,
  maxDate: PropTypes.bool,
  minValue: PropTypes.string,
  maxValue: PropTypes.string,
  height: PropTypes.string,
};
