import React from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Select_Icon } from '@root/assets/svg';
import {
  inputStyles,
  listStyles,
} from '@root/styles/ui/Autocomplete/autocompleteStyles';
import { MessageError } from '@root/ui';

import { Autocomplete, TextField, useTheme } from '@mui/material';

export const BasicAutocompleteSelect = ({
  error,
  helperText,
  type,
  placeholder,
  options,
  sx,
  size,
  listBoxStyles,
  getOptionLabel,
  isOptionEqualToValue,
  autocompleteInputChange,
  onAutocompleteChange,
  onAutocompleteOpen,
  noOptionsText,
  pagination = false,
  paginationCallback,
  value,
  multiple = false,
  groupBy,
  renderGroup,
  renderOption,
  renderTags,
  filterOptions,
}) => {
  const { color } = useTheme();
  return (
    <>
      <Autocomplete
        disablePortal
        size={size}
        popupIcon={<Img src={Select_Icon} />}
        groupBy={groupBy}
        classes={{
          paper: listStyles,
        }}
        sx={{
          ...sx,
          border: `1px solid ${color.line}`,
          borderRadius: '4px',
          borderColor: error ? color.red : '',
          '& fieldset': {
            border: 'none',
          },
        }}
        ListboxProps={{
          style: { ...listBoxStyles },
          onScroll: event => {
            if (pagination) {
              const listBoxNode = event.currentTarget;
              if (
                listBoxNode.scrollTop + listBoxNode.clientHeight ===
                listBoxNode.scrollHeight
              ) {
                paginationCallback(type);
              }
            }
          },
        }}
        noOptionsText={noOptionsText}
        options={options}
        renderInput={params => (
          <TextField
            sx={{
              ...inputStyles,
            }}
            variant={'outlined'}
            {...params}
            placeholder={placeholder}
          />
        )}
        value={value}
        isOptionEqualToValue={isOptionEqualToValue}
        getOptionLabel={getOptionLabel}
        onOpen={() => onAutocompleteOpen(type)}
        filterOptions={filterOptions}
        onInputChange={(event, newInputValue, reason) =>
          autocompleteInputChange(event, newInputValue, reason, type)
        }
        onChange={(event, newValue) =>
          onAutocompleteChange(event, newValue, type)
        }
        multiple={multiple}
        renderOption={renderOption}
        renderGroup={renderGroup}
        renderTags={renderTags}
      />
      {helperText && <MessageError errorText={helperText} sx={{ ml: 0 }} />}
    </>
  );
};

const Img = styled.img`
  width: auto;
  height: auto;
`;

BasicAutocompleteSelect.propTypes = {
  error: PropTypes.bool,
  helperText: PropTypes.string,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  options: PropTypes.array.isRequired,
  sx: PropTypes.object,
  size: PropTypes.string,
  listBoxStyles: PropTypes.object,
  getOptionLabel: PropTypes.string,
  isOptionEqualToValue: PropTypes.bool,
  autocompleteInputChange: PropTypes.func.isRequired,
  onAutocompleteChange: PropTypes.func.isRequired,
  onAutocompleteOpen: PropTypes.func.isRequired,
  noOptionsText: PropTypes.string,
  pagination: PropTypes.bool,
  paginationCallback: PropTypes.func.isRequired,
  value: PropTypes.object,
};
