import { useMemo } from 'react';
import { useDispatch } from 'react-redux';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Logo_ono } from '@root/assets/image/index';
import bannersOperation from '@root/redux/banners/banners-operation';

import { useTheme } from '@mui/material';

export const SliderItem = ({ image, html, anchor, x, y, link, id }) => {
  const { color } = useTheme();
  const dispatch = useDispatch();

  const styles = useMemo(() => {
    switch (anchor) {
      case 'top-left':
        return {
          top: `${y}px`,
          left: `${x}px`,
        };
      case 'top-right':
        return {
          top: `${y}px`,
          right: `${x}px`,
        };
      case 'bottom-left':
        return {
          bottom: `${y}px`,
          left: `${x}px`,
        };
      case 'bottom-right':
        return {
          bottom: `${y}px`,
          right: `${x}px`,
        };

      default:
        return {};
    }
  }, [anchor, x, y]);

  const handleClick = link => {
    if (link) {
      dispatch(bannersOperation.handleClickBanner({ id }));
    }
  };

  return (
    <a
      href={link}
      target="_blank"
      rel="noreferrer"
      onClick={() => {
        handleClick(link);
      }}
    >
      <ImageBg img={image || Logo_ono}>
        <Wrapper style={{ ...styles }} color={color}>
          <Text
            color={color.white}
            dangerouslySetInnerHTML={{ __html: html }}
          ></Text>
        </Wrapper>
      </ImageBg>
    </a>
  );
};

const ImageBg = styled.div`
  display: flex;
  background-image: url(${prop => prop.img});
  background-position: center;
  background-origin: border-box;
  background-repeat: no-repeat;
  background-size: cover;

  width: 100%;
  height: 100dvh;

  flex-direction: column;
  justify-content: flex-end;
`;

const Wrapper = styled.div`
  position: absolute;
`;

const Text = styled.p`
  font-weight: 400;
  font-size: 1.5rem;
  line-height: 1.5;

  color: inherit;
  background-image: ${prop => prop.img};
  background-size: cover;
`;

SliderItem.propTypes = {
  img: PropTypes.string,
  title: PropTypes.string,
  text: PropTypes.string,
  slogan: PropTypes.string,
};
