import { InView } from '../../../../node_modules/react-intersection-observer/index';
import {
  Link,
  useParams,
} from '../../../../node_modules/react-router-dom/dist/index';
import EllipsisText from 'react-ellipsis-text';
import { useTranslation } from 'react-i18next';
import { Swiper, SwiperSlide } from 'swiper/react';

import styled from 'styled-components';

import { CostDetails } from '@root/components/CostDetails/index';
import ProductLabel from '@root/components/ProductLabel/index';
import { Action } from '@root/components/Table/Action/index';

import { Typography, useTheme } from '@mui/material';

import { Paper } from '../../../../node_modules/@mui/material/index';

const DiscountedGoods = ({ item, setNavSection, handleChoseItem }) => {
  const { color } = useTheme();
  const { t } = useTranslation(['product_details', 'common'], {
    useSuspense: false,
  });
  const { lang } = useParams();
  const langstr = lang ? `/${lang}` : '';

  const handleBuy = el => {
    handleChoseItem(el);
  };

  return (
    <>
      {item?.markdown_products.length > 0 && (
        <>
          <InView
            onChange={(inView, entry) =>
              inView && setNavSection('markdown_products')
            }
          />
          <ComponentWrap id="markdown_products">
            <Typography
              variant={'h2'}
              sx={{
                color: color.gray,
              }}
            >
              {t('product_details:view_discounted_products')}
            </Typography>
            <ItemsWrap>
              <Swiper
                speed={700}
                slidesPerView={'auto'}
                style={{
                  width: '100%',
                }}
                spaceBetween={18}
                onInit={() => {}}
              >
                {item.markdown_products.map(el => (
                  <SwiperSlide style={{ width: 'auto' }}>
                    <Paper
                      sx={{
                        boxShadow: '6px 6px 10px 0px rgba(54, 74, 99, 0.06)',
                        p: '10px',
                      }}
                    >
                      <ProductLabel
                        customLabels={[
                          { text: t('discounted_product'), color: color.orange },
                        ]}
                      />

                      <Link to={`${langstr}/catalog/product/${el.slug}`}>
                        <Typography
                          variant="bodyBoldMedium"
                          component="p"
                          color={color.gray}
                          sx={{ mt: '5px' }}
                        >
                          <EllipsisText
                            length={30}
                            text={el.name}
                          ></EllipsisText>
                        </Typography>
                      </Link>
                      <PriceAndBuy>
                        <CostDetails item={el} />
                        <Action
                          isSaved={el?.is_saved}
                          inCompare={el?.in_compare}
                          availableNumber={el?.stock}
                          productPrice={el?.price}
                          addedToCart={el?.is_in_cart}
                          data={el}
                          isWatchlist={el?.in_watchlist}
                          onClick={e => {
                            handleBuy(el);
                          }}
                          showControllers={false}
                        />
                      </PriceAndBuy>
                    </Paper>
                  </SwiperSlide>
                ))}
              </Swiper>
            </ItemsWrap>
          </ComponentWrap>
        </>
      )}
    </>
  );
};

export default DiscountedGoods;

const ComponentWrap = styled.div`
  padding: 25px 103px;
  background-color: #f5f6fa;
  margin-left: -20px;
  margin-right: -20px;
  width: 100%;

  @media(max-width: 1199px) {
    margin: 0;
    padding: 25px 50px;
  }
  @media(max-width: 600px) {
    margin: 0;
    padding: 25px 25px;
  }
`;

const ItemsWrap = styled.div`
  width: 100%;
  margin-top: 16px;
`;

const PriceAndBuy = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 17px 0;
`;
