import React from 'react';
import Box from '@mui/material/Box';
import ProductLabel from '@root/components/ProductLabel';
import { Typography, useTheme } from '@mui/material';
import { Cost } from '@root/components';
import { Sprite } from '@root/assets/svg';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
import styled from 'styled-components';

const DiscountParentProductLink = ({ data }) => {
  const { color } = useTheme();
  const { t } = useTranslation('product_details');
  const { lang } = useParams();
  const langStr = lang ? `/${lang}` : '';

  return (
    <>
      {data && <>
        <LinkStyled to={langStr + `/catalog/product/${data.slug}`}>
          <Box sx={{
            padding: '10px 24px 4px',
            width: '100%',
            boxShadow: `6px 6px 10px 0px rgba(54, 74, 99, 0.06)`,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}>
            <Box>
              <ProductLabel customLabels={[{ text: t('parent_product'), color: color.green }]} />
              <Typography variant="bodyBoldMedium" color={color.gray}
                          sx={{
                            mt: '4px', maxWidth: '200px', display: `-webkit-box`,
                          }}>
                {data.name}
              </Typography>
            </Box>

            <Cost data={data} isHiddenTooltip={true} isHiddenPrice={true} />

            <svg width={16} height={16} stroke={color.gray_60}>
              <use href={`${Sprite}#icon-arrow-up-right`} />
            </svg>
          </Box>
        </LinkStyled>

      </>}
    </>

  );
};

export default DiscountParentProductLink;

const LinkStyled = styled(Link)`
    width: 100%;
`;