const getProduct = state => state.product.product;
const getMeta = state => state.product.meta;
const getCategoryName = state => state.product.categoryName;
const getProductById = state => state.product.productById;
const getFullPrice = state => state.product.fullPrice;
const getElementCounter = state => state.product.elementsCounter;
const getLoading = state => state.product.loading;
const get1cLoading = state => state.product.sync1cLoaing;
const getLoadingItemPage = state => state.product.loadingItemPage;
const getProductByEditId = state => state.product.productByEditIdData;
const getLoadingEditPage = state => state.product.loadingEditPage;

const getCharacteristicsList = state => state.characteristicsList;

const getSettingsFilterList = state => state.product.productFilterSettings.list;
const getSettingsFilterListArray = state =>
  state.product.productFilterSettings.listArray;
const getSettingsFilterActive = state =>
  state.product.productFilterSettings.active;
const getSettingsFilterCurrent = state =>
  state.product.productFilterSettings.currentList;
const getSettingsFilterDirty = state =>
  state.product.productFilterSettings.dirty;
const getNewFilterSettings = state => {
  return state.product.productFilterSettings.currentList.reduce(
    (accumulator, item, index) => {
      const filter_value_id = item.propsArrayValue?.value;
      const filter_id = item.filterValue?.value;
      accumulator[`active[${index}][filter_value_id]`] = filter_value_id;
      accumulator[`active[${index}][filter_id]`] = filter_id;
      return accumulator;
    },
    {},
  );
};

const getTags = state => state.tags;

const getProductProps = state => state.product.productPropsToEdit;
const getProductPropsDirty = state => state.product.productPropsDirty;

const getPropsCategorys = state => state.product.productProps.categorys;
const getPropsBocls = state => state.product.productProps.blocks;

const productSelectors = {
  getProduct,
  getElementCounter,
  getMeta,
  getCategoryName,
  getProductById,
  getFullPrice,
  getLoading,
  get1cLoading,
  getLoadingItemPage,
  getProductByEditId,
  getLoadingEditPage,
  getCharacteristicsList,

  getSettingsFilterList,
  getSettingsFilterListArray,
  getSettingsFilterActive,
  getSettingsFilterCurrent,
  getSettingsFilterDirty,
  getNewFilterSettings,
  getTags,

  getProductProps,
  getProductPropsDirty,
  getPropsCategorys,
  getPropsBocls,
};

export default productSelectors;
