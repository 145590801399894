import axios from 'axios';

const $http = axios.create();
delete $http.defaults.headers.common['Authorization'];

const defaultNpApiKey = process.env.REACT_APP_NP_API_KEY;
export const novaPoshtaReqCities = (
  dropNpApiKey,
  modelName = 'Address',
  calledMethod = 'getCities',
  page = 1,
  search = '',
  ref = '',
) => {
  return $http.post('https://api.novaposhta.ua/v2.0/json/', {
    // apiKey: !dropNpApiKey ? defaultNpApiKey : dropNpApiKey,
    apiKey: defaultNpApiKey,
    modelName: modelName,
    calledMethod: calledMethod,
    methodProperties: {
      Page: page,
      Limit: 20,
      FindByString: search,
      Ref: ref.length ? ref : '',
    },
  });
};
export const novaPoshtaDepartmentOrPostOffice = (
  dropNpApiKey,
  ref,
  search = '',
  page = 1,
  isOffice,
) => {
  const typeWarehouse = !isOffice
    ? '841339c7-591a-42e2-8233-7a0a00f0ed6f'
    : 'f9316480-5f2d-425d-bc2c-ac7cd29decf0';

  return $http.post('https://api.novaposhta.ua/v2.0/json/', {
    // apiKey: !dropNpApiKey ? defaultNpApiKey : dropNpApiKey,
    apiKey: defaultNpApiKey,
    modelName: 'Address',
    calledMethod: 'getWarehouses',
    methodProperties: {
      Page: page,
      Limit: 20,
      FindByString: search,
      CityRef: ref,
      TypeOfWarehouseRef: typeWarehouse,
    },
  });
};

export const getNovaPoshtaDepartmentOrOfficeByRef = (
  dropNpApiKey,
  city_ref,
  department_or_office_ref,
  isOffice,
) => {
  const typeWarehouse = !isOffice
    ? '841339c7-591a-42e2-8233-7a0a00f0ed6f'
    : 'f9316480-5f2d-425d-bc2c-ac7cd29decf0';

  return $http.post('https://api.novaposhta.ua/v2.0/json/', {
    // apiKey: !dropNpApiKey ? defaultNpApiKey : dropNpApiKey,
    apiKey: defaultNpApiKey,
    modelName: 'Address',
    calledMethod: 'getWarehouses',
    methodProperties: {
      Limit: 20,
      CityRef: city_ref,
      Ref: department_or_office_ref,
      TypeOfWarehouseRef: typeWarehouse,
    },
  });
};

export const getStatus = async (dropNpApiKey, DocumentNumber, Phone) => {
  return await $http.post('https://api.novaposhta.ua/v2.0/json/', {
    // apiKey: !dropNpApiKey ? defaultNpApiKey : dropNpApiKey,
    apiKey: defaultNpApiKey,
    modelName: 'TrackingDocument',
    calledMethod: 'getStatusDocuments',
    methodProperties: {
      Documents: [{ DocumentNumber, Phone }],
      system: 'DevCentre',
    },
  });
};

export async function getStepData(userNpApiKey, ttn_number, phone_number, t) {
  try {
    const { data } = await getStatus(
      userNpApiKey ? userNpApiKey : defaultNpApiKey,
      ttn_number,
      phone_number,
    );

    const {
      WarehouseSender,
      DateCreated,
      WarehouseRecipient,
      StatusCode,
      RecipientDateTime,
      TrackingUpdateDate,
      SenderAddress,
      WarehouseRecipientAddress,
    } = data.data[0];

    let activeStep = null;

    switch (StatusCode) {
      case '1':
        activeStep = 0;
        break;
      case '12':
        activeStep = 0;
        break;
      case '5':
        activeStep = 1;
        break;
      case '7':
        activeStep = 2;
        break;
      case '9':
        activeStep = 3;
        break;
      case '103':
        activeStep = 3;
        break;
      case '102':
        activeStep = 3;
        break;
      default:
        break;
    }

    return {
      activeStep,
      steps: [
        {
          label: `${t('logistic:stepper.parcel_received_by_np')} ${
            WarehouseSender || SenderAddress
          }`,
          description: DateCreated,
        },
        {
          label: `${t('logistic:stepper.package_left')} ${
            WarehouseSender || SenderAddress
          }`,
          description: StatusCode === '5' ? TrackingUpdateDate : '',
        },
        {
          label: `${t('logistic:stepper.arrived_to')} ${
            WarehouseRecipient || WarehouseRecipientAddress
          }`,
          description: StatusCode === 7 ? TrackingUpdateDate : '',
        },
        {
          label:
            StatusCode === '102' || StatusCode === '103'
              ? t('logistic:stepper.refusal_recipient')
              : t('logistic:stepper.parcel_received_by_buyer'),
          description:
            StatusCode === '102' || StatusCode === '103' || StatusCode === '9'
              ? RecipientDateTime
              : '',
        },
      ],
    };
  } catch (error) {
    return {
      active: 0,
      steps: [],
    };
  }
}
