import {
  useNavigate,
  useParams,
} from '../../../../node_modules/react-router-dom/dist/index';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import styled from 'styled-components';

import { handleDispatchFetch } from '@root/helpers/handleDispatchFetch';
import {
  getErrorStack,
  validEmail,
  validPassword,
} from '@root/helpers/validation';
import authOperation from '@root/redux/auth/auth-operation';
import authSelectors from '@root/redux/auth/auth-selectors';
import { storePasswordResetData } from '@root/redux/auth/auth-slice';
import { ButtonBase } from '@root/ui/Button/ButtonBase/index';
import { MessageError } from '@root/ui/Error/Message/index';
import { PasswordInput } from '@root/ui/Input/Password/index';

import { useTheme } from '@mui/material/styles';

import { Typography } from '../../../../node_modules/@mui/material/index';

const PasswordResetConfirmForm = () => {
  const navigate = useNavigate();
  const { lang } = useParams();
  const { t } = useTranslation(['password_reset', 'validation', 'login'], {
    useSuspense: false,
  });
  const [data, setData] = useState({
    password: '',
    passwordConfirm: '',
  });
  const [textError, setTextError] = useState('');
  const currentLang = lang ? `/${lang}` : '';
  const [error, setError] = useState({});
  const dispatch = useDispatch();
  const passwordResetData = useSelector(authSelectors.getPasswordResetData);

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const token = searchParams.get('token');
    let email = searchParams.get('hash');
    if (email) email = atob(email);
    if (token && email && !validEmail(email)) {
      dispatch(
        storePasswordResetData({
          email,
          token,
        }),
      );
      navigate(currentLang + '/reset-password', { replace: true });
    } else {
      alert('No token or email');
      navigate(currentLang + '/login');
    }
  }, []);

  const onChange = name => value => {
    setData(prevState => ({ ...prevState, [name]: value }));
  };

  const validation = () => {
    let flag = false;
    let currentError = {};

    const { password, passwordConfirm } = data;

    Object.keys({
      password,
      passwordConfirm,
    }).forEach(key => {
      if (!data[key] || data[key] === '') {
        currentError = getErrorStack(
          currentError,
          key,
          t('validation:required'),
        );
        flag = true;
      }
    });

    if (
      !validPassword(password, {
        minLowercase: 1,
        minUppercase: 1,
        minNumbers: 1,
        minLength: 8,
      }) &&
      password !== ''
    ) {
      currentError = getErrorStack(
        currentError,
        'password',
        t('validation:password_not_secure'),
      );
      flag = true;
    }
    if (passwordConfirm !== password) {
      currentError = getErrorStack(
        currentError,
        'passwordConfirm',
        t('validation:password_not_match'),
      );
      flag = true;
    }
    setError(currentError);
    return flag;
  };

  const handelSubmit = async e => {
    if (e?.preventDefault) e?.preventDefault();
    if (validation()) return;

    const formData = new FormData();
    formData.append('email', passwordResetData.email);
    formData.append('token', passwordResetData.token);
    formData.append('password', password);
    formData.append('password_confirmation', passwordConfirm);

    handleDispatchFetch(
      ({ onResolve, onReject }) =>
        dispatch(
          authOperation.passwordResetComfirm({
            data: formData,
            onResolve,
            onReject,
          }),
        ),
      e => {
        dispatch(
          authOperation.signIn({
            data: {
              email: passwordResetData.email,
              password: password,
              rememberMe: false,
              secret: '',
            },
          }),
        );
      },
      ({ message }) => {
        setError({
          email: { init: true, text: '' },
        });
        setTextError(message);
      },
    );
  };

  const { password, passwordConfirm } = data;
  return (
    <>
      <Form autoComplete="on" onSubmit={handelSubmit}>
        <Typography variant={'h1'}>{t('title')}</Typography>

        <Typography sx={{ mt: '28px' }} variant={'bodyLarge'} component={'p'}>
          {t('create_new_pass_slogan')}
        </Typography>
        <Row>
          <PasswordInput
            error={error?.password?.init}
            errorText={error?.password?.text}
            sx={{ mt: '28px', width: '100%', height: '48px' }}
            value={password}
            onChange={onChange('password')}
            placeholder={t('login:field.password', { req: '*' })}
            data-test-id="Password"
            autoComplete={'new-password'}
            inputProps={{
              maxLength: 255,
            }}
          />
          <PasswordInput
            error={error?.passwordConfirm?.init}
            errorText={error?.passwordConfirm?.text}
            sx={{ mt: '28px', width: '100%', height: '48px' }}
            value={passwordConfirm}
            onChange={onChange('passwordConfirm')}
            placeholder={t('login:field.password', { req: '*' })}
            data-test-id="passwordConfirm"
            autoComplete={'new-password'}
            inputProps={{
              maxLength: 255,
            }}
          />
        </Row>

        {textError && (
          <MessageError
            errorText={textError}
            sx={{
              mt: '16px',
              mr: 'auto',
              position: 'relative',
              top: '16px',
              fontSize: '0.75rem',
              whiteSpace: 'nowrap',
            }}
          />
        )}

        <ButtonBase
          type="submit"
          size={'large'}
          sx={{ mt: '28px', width: '100% ' }}
        >
          <Typography variant={'bodyBoldLarge'}>
            {' '}
            {t('login:loginBtn')}
          </Typography>
        </ButtonBase>
      </Form>
    </>
  );
};

export default PasswordResetConfirmForm;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 103px;
`;
const Row = styled.div`
  width: 100%;
`;

const BotLinksWrap = styled.div`
  flex-grow: 1;
  display: flex;
  justify-content: space-around;
  align-items: flex-end;
  a {
    margin: 0 10px;
  }
`;

const StartCountdown = styled.button`
  background-color: none;
  border: none;
  cursor: pointer;
`;
