import { useState } from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Sprite } from '@root/assets/svg';
import { ToggleBtn } from '@root/ui';

import { useTheme } from '@mui/material/styles';

export const ZoomProductSlider = ({ images = [] }) => {
  const [count, setCount] = useState(0);
  const { color } = useTheme();
  return (
    <WrapperSlider>
      <ControllerWrapper>
        {images.map(({ thumbnail }, i) => (
          <ImgController
            src={thumbnail}
            alt="Switch Icon"
            isActiveIcon={count === i}
            color={color}
            onClick={() => setCount(i)}
          />
        ))}
      </ControllerWrapper>
      <Content>
        <ToggleBtn
          size={50}
          onClick={() => count !== 0 && setCount(count - 1)}
          href={`${Sprite}#icon-arrow-left`}
        />
        <ImgCover src={images[count]?.big} alt="Product Photo" />
        <ToggleBtn
          size={50}
          onClick={() => count < images.length - 1 && setCount(count + 1)}
          href={`${Sprite}#icon-arrow-right`}
        />
      </Content>
    </WrapperSlider>
  );
};

const WrapperSlider = styled.div`
  display: flex;
`;

const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;

  margin-left: 78px;
  margin-bottom: 108px;

  width: 100%;
`;

const ImgCover = styled.img`
  display: block;
  width: 481px;
  margin: 0 87px;
`;

const ImgController = styled.img`
  display: block;
  width: 100px;
  padding: 10px;
  border: 1px solid
    ${({ isActiveIcon, color }) => (isActiveIcon ? color.main : color.line)};
  border-radius: 4px;
`;

const ControllerWrapper = styled.div`
  display: flex;
  flex-direction: column;

  img + img {
    margin-top: 16px;
  }
`;

ZoomProductSlider.propTypes = {
  images: PropTypes.array,
};
