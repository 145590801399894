import { useTranslation } from 'react-i18next';

import styled from 'styled-components';

import { ProgressLoader } from '@root/ui/Loader/ProgressLoader/index';

import { useTheme } from '@mui/material';
import TextPageHtmlWrap from '../TextPages/TextPageHtmlWrap/index';

export const UserAgreement = ({ content }) => {
  return (
    <UserAgreementWrapper>
      {content ? (
        <TextContainer>
          <QlEditor className="">
            <TextPageHtmlWrap content={content} />
          </QlEditor>
        </TextContainer>
      ) : (
        <ProgressLoader />
      )}
    </UserAgreementWrapper>
  );
};

const UserAgreementWrapper = styled.div`
  min-height: 200px;
  min-width: 200px;
  position: relative;
`;

const TextContainer = styled.div`
  max-width: 873px;
  margin: 30px auto;
`;

const QlEditor = styled.div`
  padding-left: 0;
  padding-right: 0;
`;
