export const SearchConf = [
  { type: 'name', name: 'Назва' },
  { type: 'model', name: 'Модель' },
  { type: 'description', name: 'Опис' },
  { type: 'sku', name: 'Артикул' },
  { type: 'property', name: 'Характеристики' },
];

export const customStyles = [
  {
    style: { maxWidth: '499px', width: '100%' },
  },
  {
    style: { maxWidth: '110px', width: '100%' },
  },
  {
    style: { maxWidth: '100px', width: '100%' },
  },
  {
    style: { maxWidth: '110px', width: '100%', display: 'flex' },
  },
];
