import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import { BasicTooltip } from '@root/ui';

import { Typography, useTheme } from '@mui/material';

export const Notification = ({
  title = 'Хочете отримати додаткову знижку?',
  text = 'Залишайте відгук про нашу компанію та отримуйте додаткову знижку на наступну покупку! Деталі у вашого персонального менеджера.',
  onNotificationClick,
  isViewed = false,
  created,
  url,
  handleClosePopUp,
}) => {
  const { color } = useTheme();
  const { t } = useTranslation(['common']);
  return (
    <NotificationWrapper
      borderColor={color.line}
      isViewed={isViewed}
      onClick={() => !isViewed && onNotificationClick()}
    >
      <DotWrapper>
        <BasicTooltip
          title={t('common:read')}
          placement="top"
          sx={{
            position: 'absolute',
            top: '16px',
            right: '20px',
          }}
        >
          <Dot isViewed={isViewed} />
        </BasicTooltip>
      </DotWrapper>

      <NavLink
        style={{
          display: 'inline-block',
          marginRight: '10px',
        }}
        to={url}
        onClick={handleClosePopUp}
      >
        <Typography
          variant="bodyBoldSmall"
          color={color.gray}
          component="h3"
          sx={{
            textDecoration: 'underline',
            '&:hover': {
              color: color.main,
            },
          }}
        >
          {title}
        </Typography>
      </NavLink>

      <Typography
        variant="bodyExSmall"
        color={color.gray}
        component="p"
        marginTop="9px"
      >
        {text}
      </Typography>
      <Typography
        variant="bodyExSmall"
        color={color.gray_60}
        component="p"
        marginTop="8px"
      >
        {created}
      </Typography>
    </NotificationWrapper>
  );
};

const NotificationWrapper = styled.div`
  position: relative;
  cursor: pointer;
  padding: 16px 20px;
  border-bottom: 1px solid ${({ borderColor }) => borderColor};
  background: ${({ isViewed }) =>
    !isViewed &&
    `linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.92) 0%,
      rgba(255, 255, 255, 0.92) 100%
    ),
    #1996AA`};

  &:last-child {
    border-bottom: none;
  }
`;

const DotWrapper = styled.div`
  position: absolute;
  top: 16px;
  right: 20px;
`;

const Dot = styled.div`
  ${({ isViewed }) =>
    !isViewed &&
    `&:before {
    content: '';
    display: block;
    width: 8px;
    height: 8px;
    background-color: #1996AA;
    border-radius: 50%;
  }`}
`;

Notification.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
  onNotificationClick: PropTypes.func,
  isViewed: PropTypes.bool,
  created: PropTypes.string,
  url: PropTypes.string,
  handleClosePopUp: PropTypes.func,
};
