import { useTranslation } from 'react-i18next';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import { ColorPicker } from '@root/components';
import { OutlineButtonBase } from '@root/ui';

import { Typography, useTheme } from '@mui/material';

export const ItemColorCard = ({
  data,
  currentColor,
  handleChangeColor,
  handleReset,
}) => {
  const { color } = useTheme();
  const { title, details } = data;
  const { t } = useTranslation(['common']);
  return (
    <Card color={color}>
      <ContendInfo>
        <Typography
          variant={'bodyBoldMedium'}
          component={'h4'}
          color={color.gray}
        >
          {title}
        </Typography>
        <Typography
          variant={'bodySmall'}
          component={'p'}
          color={color.gray_80}
          sx={{ mt: '4px' }}
        >
          {details}
        </Typography>
      </ContendInfo>
      <ColorPickerWrapper>
        <OutlineButtonBase
          onClick={handleReset}
          sx={{
            padding: '5px 16px',
            marginRight: '10px',
            border: 'none !important',
          }}
        >
          {t('common:buttons.reset')}
        </OutlineButtonBase>
        <ColorPicker colorNum={currentColor} onChange={handleChangeColor} />
      </ColorPickerWrapper>
    </Card>
  );
};

const Card = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 32px;
  border: 1px solid ${({ color }) => color.line};
  border-radius: 4px;

  @media (max-width: 768px) {
    padding: 10px 16px;
    flex-direction: column;
    justify-content: start;
    align-items: start;
    gap: 10px;
  }
`;

const ContendInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  max-width: 491px;
`;

const ColorPickerWrapper = styled.div`
  display: flex;

  @media (max-width: 768px) {
    align-self: end;
  }
`;

ItemColorCard.propTypes = {
  data: PropTypes.exact({
    title: PropTypes.string.isRequired,
    details: PropTypes.string.isRequired,
    key: PropTypes.string.isRequired,
  }),
  currentColor: PropTypes.string.isRequired,
  handleChangeColor: PropTypes.func.isRequired,
  handleReset: PropTypes.func.isRequired,
};
