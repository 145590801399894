import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { getErrorStack, validEmail } from '@root/helpers';

export const useOrder = ({ servicesValidation, available_methods }) => {
  const [data, setData] = useState({
    name: '',
    middle_name: '',
    surname: '',
    phone: '',
    delivery_type: 'Self_pickup',
    delivery_filial: '',
    delivery_info: {
      type: 'SELF_PICKUP',
      city_title: '',
      city_ref: '',
      department_title: '',
      department_ref: '',
      post_office_title: '',
      post_office_ref: '',
      street_title: '',
      street_number: '',
      flat_num: '',
      floor_num: '',
      elevator: false,
      npPhone: '',
      date: '',
      time: '',
    },
    its_drop: false,
    payment_type: 'Online',
    payment_info: {
      account_option: 'with_vat',
      send_invoice: false,
      payer_type: 'legal_entity',
      email: '',
      name_organization: '',
      name_fop_organization: '',
      contractor_code: '',
      identification_code: '',
      full_name: '',
    },
    id: '',
    dont_call: false,
    discount: '',
  });
  const [error, setError] = useState({});
  const { t } = useTranslation(['validation']);

  const onChange = (name, key) => value => {
    if (key === 'np_courier') {
      setData(prevState => {
        return {
          ...prevState,
          delivery_info: {
            ...prevState.delivery_info,
            [name]: value,
          },
        };
      });
    } else if (name === 'its_drop') {
      setData(prevState => ({
        ...prevState,
        [name]: value,
        delivery_info: {
          ...prevState.delivery_info,
          city_title: '',
          city_ref: '',
          department_title: '',
          department_ref: '',
          post_office_title: '',
          post_office_ref: '',
          street_title: '',
          street_number: '',
          flat_num: '',
          floor_num: '',
          elevator: false,
          npPhone: '',
          date: '',
          time: '',
        },
      }));
    } else if (key === 'payment_info') {
      setData({
        ...data,
        payment_info: { ...data.payment_info, [name]: value },
      });
    } else {
      setData(prevState => ({ ...prevState, [name]: value }));
    }
  };

  const getDefaultChildrenType = value => {
    const items = available_methods?.delivery_types.find(
      el => el.key === value,
    ).items;
    return items.find(el => el.isDefault);
  };

  const onChangeRadio = (name, key) => event => {
    if (name === 'delivery_type') {
      const type = getDefaultChildrenType(event.target.value);
      setData(prevState => ({
        ...prevState,
        [name]: event.target.value,
        delivery_filial: '',
        delivery_info: {
          ...prevState.delivery_info,
          type: type?.key,
        },
      }));
    } else if (key === 'payment_info') {
      setData({
        ...data,
        payment_info: {
          ...data.payment_info,
          [name]: event.target.value,
        },
      });
    } else {
      setData(prevState => ({
        ...prevState,
        [name]: event.target.value,
      }));
    }
  };

  const handleSelectFilial = value => {
    setData(prevState => ({ ...prevState, delivery_filial: value }));
  };

  const handleCardRadio = val => {
    setData(prevState => {
      return {
        ...prevState,
        delivery_info: {
          ...prevState.delivery_info,
          type: val,
          city_title: val === 'viatec_courier' ? 'Київ' : '',
          city_ref: '',
          department_ref: '',
          department_title: '',
          post_office_ref: '',
          post_office_title: '',
          street_title: '',
          street_number: '',
          flat_num: '',
          floor_num: '',
          elevator: false,
          npPhone: '',
          date: '',
          time:
            val === 'np_courier' || val === 'viatec_courier'
              ? '10:00-20:00'
              : '',
        },
      };
    });
  };

  const validation = () => {
    let flag = false;
    let currentError = {};
    const { name, middle_name, surname, phone } = data;

    Object.keys({
      name,
      middle_name,
      surname,
      phone,
    }).forEach(key => {
      if (!data[key] || data[key] === '') {
        currentError = getErrorStack(
          currentError,
          key,
          t('validation:required'),
        );
        flag = true;
      }
    });

    Object.keys({ name, middle_name, surname }).forEach(key => {
      if (data[key].length !== 0 && data[key].length < 2) {
        currentError = getErrorStack(
          currentError,
          key,
          `${t('validation:minLengthLetters')}2`,
        );
        flag = true;
      }
    });

    if (phone?.length < 12 && phone !== '') {
      currentError = getErrorStack(
        currentError,
        'phone',
        t('validation:input_phone_number'),
      );
      flag = true;
    }

    setError(currentError);

    flag = servicesValidation(data, setError, currentError, t, flag);
    return flag;
  };

  const validationPayment = () => {
    let flag = false;
    let currentError = {};
    const { payment_type } = data;

    const {
      send_invoice,
      payer_type,
      email,
      name_organization,
      name_fop_organization,
      contractor_code,
      identification_code,
      full_name,
    } = data.payment_info;

    if (payment_type !== 'Bill') return flag;

    switch (payer_type) {
      case 'legal_entity':
        if (!name_organization || name_organization === '') {
          currentError = getErrorStack(
            currentError,
            'name_organization',
            t('validation:required'),
          );
          flag = true;
        }

        if (!contractor_code || contractor_code === '') {
          currentError = getErrorStack(
            currentError,
            'contractor_code',
            t('validation:required'),
          );
          flag = true;
        } else if (contractor_code.length < 8) {
          currentError = getErrorStack(
            currentError,
            'contractor_code',
            t(`${t('validation:minLengthLetters')} 8`),
          );
          flag = true;
        }
        break;

      case 'individual_entrepreneur':
        if (!name_fop_organization || name_fop_organization === '') {
          currentError = getErrorStack(
            currentError,
            'name_fop_organization',
            t('validation:required'),
          );
          flag = true;
        }

        if (!identification_code || identification_code === '') {
          currentError = getErrorStack(
            currentError,
            'identification_code',
            t('validation:required'),
          );
          flag = true;
        } else if (identification_code.length < 10) {
          currentError = getErrorStack(
            currentError,
            'identification_code',
            t(`${t('validation:minLengthLetters')} 10`),
          );
          flag = true;
        }
        break;
      default:
        if (!full_name || full_name === '') {
          currentError = getErrorStack(
            currentError,
            'full_name',
            t('validation:required'),
          );
          flag = true;
        }
        break;
    }

    if (send_invoice && (!email || email === '')) {
      currentError = getErrorStack(
        currentError,
        'email',
        t('validation:required'),
      );
      flag = true;
    } else if (validEmail(email) && email !== '') {
      currentError = getErrorStack(
        currentError,
        'email',
        t('validation:incorrect_email'),
      );
      flag = true;
    }
    setError(currentError);
    return flag;
  };

  return {
    onChange,
    error,
    onChangeRadio,
    handleSelectFilial,
    handleCardRadio,
    validation,
    data,
    setData,
    validationPayment,
  };
};
