import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import styled from 'styled-components';

import { Sprite } from '@root/assets/svg';
import { Notifications } from '@root/components';
import { useNavigation } from '@root/hooks';
import { ButtonWithoutBorder, IconBtn, MenuBody, MenuButton } from '@root/ui';

import notificationsSelectors from '@redux/notifications/notifications-selectors';

import { Typography, useTheme } from '@mui/material';

export const NotificationsWidget = () => {
  const { t } = useTranslation('common');
  const [anchorEl, setAnchorEl] = useState(null);
  const [fontSizeNotificationsCount, setFontSizeNotificationsCount] =
    useState(8);
  const [notViewedNotificationsCount, setNotViewedNotificationsCount] =
    useState(2);

  useEffect(() => {
    setFontSizeNotificationsCount(notViewedNotificationsCount > 9 ? 6 : 8);
  }, [notViewedNotificationsCount]);

  const hasNotViewedNotifications = useSelector(
    notificationsSelectors.getHasNotViewedNotifications,
  );

  const { navigation } = useNavigation();
  const { color } = useTheme();

  const handleMoveToNotificationsPage = () => {
    handleClosePopUp();
    navigation('/notifications');
  };

  const handleOpenPopUp = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClosePopUp = () => {
    setAnchorEl(null);
  };
  return (
    <NotificationsWidgetWrapper>
      <MenuButton
        open={!!anchorEl}
        handleClick={handleOpenPopUp}
        sx={{
          transformOrigin: 'center',
          stroke: hasNotViewedNotifications ? color.main : '',
          position: 'relative',
          color: color.gray_60,
          '&:hover': {
            color: color.main,
            background: 'none',
          },
        }}
      >
        <svg width={24} height={24} fill={'none'}>
          <use href={`${Sprite}#notification-icon`}></use>
        </svg>
        {hasNotViewedNotifications && notViewedNotificationsCount && (
          <div
            style={{
              width: '10px',
              height: '10px',
              background: color.main,
              borderRadius: '50%',
              position: 'absolute',
              marginRight: '20px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              fontSize: fontSizeNotificationsCount + 'px',
              fontWeight: '700',
              color: color.white,
              top: 0,
              right: 0,
            }}
          >
            {notViewedNotificationsCount}
          </div>
        )}
      </MenuButton>

      <MenuBody
        anchorEl={anchorEl}
        open={!!anchorEl}
        handleClose={handleClosePopUp}
        sx={{ maxWidth: '430px', width: '100%', padding: '6px 0 0 0' }}
        anchorOriginPosition={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOriginPosition={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Notifications
          containerHeight="calc(100vh - 443px)"
          handleClosePopUp={handleClosePopUp}
          baseLoader={true}
        />
        <NotificationsFooter borderColor={color.line}>
          <ButtonWithoutBorder onClick={handleMoveToNotificationsPage}>
            <Typography variant="bodyBoldSmall" color={color.gray}>
              {t('notifications.view_all')}
            </Typography>
          </ButtonWithoutBorder>

          {/* <IconBtn onClick={() => {}} href={`${Sprite}#icon-settings`} /> */}
        </NotificationsFooter>
      </MenuBody>
    </NotificationsWidgetWrapper>
  );
};

const NotificationsFooter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px 10px 12px;
  border-top: 1px solid ${({ borderColor }) => borderColor};
`;

const NotificationsWidgetWrapper = styled.div``;
