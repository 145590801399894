import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Sprite } from '@root/assets/svg';
import { CustomScroll, OutlineButtonBase } from '@root/ui';

import { Typography, useTheme } from '@mui/material';

export const ProfileList = ({
  handleTabClick,
  activeTab,
  list,
  openModal,
  titleList,
  titleBtn,
  isShowBtn = true,
}) => {
  const { color } = useTheme();

  return (
    <CompanyList color={color}>
      <Typography
        sx={{
          paddingLeft: '10px',
          marginBottom: '10px',
        }}
        color={color.gray}
        component="p"
        variant="bodyBoldMedium"
      >
        {titleList}
      </Typography>
      <div
        style={{
          maxHeight: '400px',
          height: '100%',
          width: '100%',
        }}
      >
        <CustomScroll vertical={true}>
          <ButtonsWrap>
            {list.map((listItem, idx) => (
              <Button
                key={listItem.name + idx}
                isActive={activeTab === idx}
                color={color}
                onClick={() => handleTabClick(idx, listItem.id)}
              >
                <Typography variant="bodySmall" sx={{ fontWeight: 'inherit' }}>
                  {listItem.name}
                </Typography>
              </Button>
            ))}
          </ButtonsWrap>
        </CustomScroll>
      </div>
      {isShowBtn && (
        <OutlineButtonBase
          sx={{
            width: 'auto',
            marginLeft: '10px',
            height: '32px',
            paddingLeft: '12px',
            paddingRight: '12px',
          }}
          onClick={openModal}
        >
          <Svg color={color}>
            <use href={`${Sprite}#icon-plus`}></use>
          </Svg>
          <Typography variant="bodyBoldSmall" color={color.gray_60}>
            {titleBtn}
          </Typography>
        </OutlineButtonBase>
      )}
    </CompanyList>
  );
};

const CompanyList = styled.div`
  background: ${({ color }) => color.gray_20};
  padding: 16px 0 16px;
  border-radius: 4px;
  flex: 0 1 192px;
  align-self: flex-start;

  @media (max-width: 768px) {
    align-self: stretch;
    flex: 1 1 auto;
  }
`;

const Svg = styled.svg`
  width: 16px;
  height: 16px;
  margin-right: 5px;
  stroke: ${({ color }) => color.gray_60};
`;

const ButtonsWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 10px;
  gap: 10px;
  padding-right: 20px;
`;

const Button = styled.button`
  cursor: pointer;
  padding-left: 10px;
  border-radius: 1px;
  text-align: left;
  border-left: 2px solid
    ${({ isActive, color }) => (!isActive ? 'transparent' : color.main)};
  color: ${({ isActive, color }) => (!isActive ? color.gray : color.main)};
  font-weight: ${({ isActive }) => (!isActive ? 400 : 700)};
`;

ProfileList.propTypes = {
  titleList: PropTypes.string.isRequired,
  titleBtn: PropTypes.string.isRequired,
  openModal: PropTypes.func.isRequired,
  handleTabClick: PropTypes.func.isRequired,
  tabDetails: PropTypes.object,
  list: PropTypes.array.isRequired,
  isShowBtn: PropTypes.bool,
  activeTab: PropTypes.number,
};
