import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';

const ButtonWithoutBorderCustom = styled(Button)(
  ({ theme, size, variant }) => ({
    fontWeight: '700',
    fontSize: '0.75rem',
    background: theme.color.white,
    height: '28px',
    display: 'flex',
    alignItems: 'center',
    color: theme.color.gray_80,
    cursor: 'pointer',
    textTransform: 'none',
    boxShadow: 'none !important',
  }),
);

export const ButtonWithoutBorder = ({ children, sx, ...restProps }) => {
  return (
    <ButtonWithoutBorderCustom sx={sx} disableRipple={true} {...restProps}>
      {children}
    </ButtonWithoutBorderCustom>
  );
};
