import PropTypes from 'prop-types';

import { BasicTooltip } from '@root/ui';

import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';

const ColorButton = styled(Button)(({ theme, size, variant, isActive, borderColor, hoverBackground }) => ({
  color: theme.color.gray_60,
  backgroundColor: theme.color.white,
  padding: theme.padding.outlineButton,
  textTransform: 'none',
  border: `1px solid ${borderColor || theme.color.line} !important`,
  boxShadow: 'none !important',
  transition: '0.25s',

  '& svg': {
    transition: '0.25s',
    fill: isActive ? theme.color.white : theme.color.gray_60,
    stroke: isActive ? theme.color.white : theme.color.gray_60,
  },

  '&:hover svg': {
    stroke: isActive ? theme.color.white : theme.color.gray_80,
    fill: isActive ? theme.color.white : theme.color.gray_80,
  },

  '&:hover': {
    background: hoverBackground || theme.color.gray_20,
    color: theme.color.gray_80,
  },

  '&:active': {
    background: theme.color.gray_20,
    color: theme.color.main,
  },

  '&:active svg': {
    stroke: !isActive && theme.color.main,
    fill: !isActive && theme.color.main,
  },
}));

export const OutlineButtonBase = ({
  children,
  isActive,
  type,
  onClick,
  size,
  sx,
  disabled,
  tooltipText = '',
  borderColor,
  hoverBackground,
  ...prop
}) => {
  return (
    <BasicTooltip title={tooltipText} placement="top">
      <ColorButton
        variant="outlined"
        sx={{ ...sx }}
        type={type}
        isActive={isActive}
        onClick={onClick}
        size={size}
        disableRipple={true}
        disabled={disabled}
        borderColor={borderColor}
        hoverBackground={hoverBackground}
        {...prop}
      >
        {' '}
        {children}
      </ColorButton>
    </BasicTooltip>
  );
};

OutlineButtonBase.propTypes = {
  children: PropTypes.node,
  isActive: PropTypes.bool,
  type: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  size: PropTypes.number,
  sx: PropTypes.object,
  disabled: PropTypes.bool,
};
