export const AgreementsConf = t => [
    {
        label: '',
        value: 'checkBox',
        type: 'checkBox',
        style: active =>
            active ? { ml: '32px', width: '16px' } : { ml: '32px', width: '16px' },
    },
    {
        label: t('admin:agreements_table_conf.name', { req: '*' }),
        value: 'agreementname',
        type: 'agreementname',
        style: active =>
            active ? { ml: '16px', width: '500px' } : { ml: '16px', width: '500px' },
    },
    {
        label: t('admin:agreements_table_conf.status'),
        value: 'agrstatus',
        type: 'agrstatus',
        style: active =>
            active ? { ml: '25px', width: '137px' } : { ml: '25px', width: '137px' },
        hide: true,
    },

    {
        label: '',
        value: 'agrdownload',
        type: 'agrdownload',
        style: active => (active ? { ml: 'auto', width: '137px' } : { ml: 'auto', width: '137px' }),
    },

    {
        label: '',
        value: 'edit',
        type: 'action',
        style: active => (active ? { ml: 'auto' } : { ml: 'auto' }),
        accordionData: ['Видалити'],
    },
]