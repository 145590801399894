import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Hide_Password, Show_Password } from '@root/assets/svg';
import { BasicTooltip } from '@root/ui';

import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';

import { TextInput } from '../TextInput';

export const PasswordInput = ({
  onChange,
  value = '',
  tooltipText = '',
  ...prop
}) => {
  const [showPassword, setShowPassword] = useState(false);

  const { t } = useTranslation(['common'], { useSuspense: false });

  const handleClickShowPassword = () => setShowPassword(show => !show);

  const handleMouseDownPassword = event => {
    event.preventDefault();
  };

  return (
    <>
      <TextInput
        type={showPassword ? 'text' : 'password'}
        onChange={onChange}
        autocomplete="off"
        value={value}
        withOutSpace={true}
        endAdornment={
          <InputAdornment position="end">
            <BasicTooltip
              title={
                showPassword
                  ? t('common:tooltip.hide')
                  : t('common:tooltip.show')
              }
              placement="top"
            >
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
                edge="end"
              >
                {showPassword ? (
                  <Img src={Hide_Password} alt="Hide Password" />
                ) : (
                  <Img src={Show_Password} alt="Show Password" />
                )}
              </IconButton>
            </BasicTooltip>
          </InputAdornment>
        }
        {...prop}
      />
    </>
  );
};

const Img = styled.img`
  width: 24px;
  height: 24px;
`;

PasswordInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
};
