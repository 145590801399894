import * as React from 'react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Sprite } from '@root/assets/svg';
import { handleDispatchFetch } from '@root/helpers';
import { useNavigation } from '@root/hooks';
import {
  ButtonBase,
  CheckboxBase,
  IconBtn,
  OutlineButtonBase,
  TextArea,
  TextInput,
} from '@root/ui';

import favoriteOperation from '@redux/favorite/favorite-operation';
import favoriteSelectors from '@redux/favorite/favorite-selectors';

import { Divider, Typography, useTheme } from '@mui/material';

export const SavedGoods = ({
  productId,
  callbackAfterAddToFavoriteList,
  favoritesChosedArr,
  setFavoritesChosedArr,
}) => {
  const { color } = useTheme();
  const [createNewSaved, setCreateNewSaved] = useState(false);
  const [savedData, setSavedData] = useState({
    name: '',
    description: '',
  });
  const [saveActive, setSaveActive] = useState(false);
  const favoritesList = useSelector(favoriteSelectors.getListTypesFavorite);

  const dispatch = useDispatch();

  const { t } = useTranslation(['common', 'catalog'], { useSuspense: false });
  const { navigation } = useNavigation();

  const onChangeText = name => value => {
    setSavedData(prevState => ({ ...prevState, [name]: value }));
  };

  const handleSetSavedList = () => {
    if (
      (favoritesChosedArr.length !== 0 || saveActive) &&
      ((!savedData.description && !savedData.name) || !savedData.name)
    ) {
      return handleAddProductToSaved();
    }

    handleDispatchFetch(
      ({ onResolve, onReject }) =>
        dispatch(
          favoriteOperation.postNewFavorite({ savedData, onResolve, onReject }),
        ),
      data => {
        dispatch(
          favoriteOperation.postProductToFavorite({
            favorites: [...favoritesChosedArr.map(el => el.id), data.id],
            product_id: productId,
            saved: saveActive,
          }),
        );
        callbackAfterAddToFavoriteList(data);
      },
      error => {},
    );
  };

  const handleAddProductToSaved = () => {
    dispatch(
      favoriteOperation.postProductToFavorite({
        favorites: favoritesChosedArr.map(el => el.id),
        product_id: productId,
        saved: saveActive,
      }),
    );

    callbackAfterAddToFavoriteList();
  };

  const handleChoseFavoriteType = listItem => {
    const findCheckedEl = favoritesChosedArr.find(el => el.id === listItem?.id);
    findCheckedEl
      ? setFavoritesChosedArr(
          favoritesChosedArr.filter(item => item.id !== listItem?.id),
        )
      : setFavoritesChosedArr(previousValue => [...previousValue, listItem]);
  };

  const handleChoseToSave = () => {
    setSaveActive(!saveActive);
  };

  useEffect(() => {
    dispatch(favoriteOperation.getFavoritesList());
    setFavoritesChosedArr([]);
    setCreateNewSaved(false);
    setSaveActive(false);
  }, []);

  const { name, description } = savedData;
  return (
    <ContentWrap>
      <SavedSection>
        <svg
          onClick={handleChoseToSave}
          width={22}
          height={20}
          fill={saveActive ? color.main : 'none'}
          stroke={color.gray_60}
        >
          <use href={`${Sprite}#icon-heart`}></use>
        </svg>
        <Typography variant={'bodySmall'} color={color.gray_80}>
          {t('catalog:modals.save_product_modal.save_product')}
        </Typography>
      </SavedSection>
      <Typography
        variant={'bodyBoldSmall'}
        color={color.gray}
        sx={{ margin: '12px 0 4px 0' }}
      >
        {t('catalog:modals.save_product_modal.add_to_list')}
      </Typography>
      {favoritesList.map(item => (
        <CreatedSavedList color={color.gray_20} key={item.id}>
          <CheckboxBase
            checked={favoritesChosedArr.find(el => el?.id === item?.id)}
            width={16}
            onChange={() => handleChoseFavoriteType(item)}
          >
            <Typography variant={'bodyBoldSmall'} color={color.gray_60}>
              {item.name}
            </Typography>
          </CheckboxBase>
        </CreatedSavedList>
      ))}
      <Divider style={{ width: '100%', marginTop: '12px' }} />
      {createNewSaved ? (
        <NewSavedListWrapper>
          <Typography
            sx={{ margin: '12px 0 11px 0' }}
            variant={'bodyBoldSmall'}
            color={color.gray}
          >
            {t('common:buttons.new_list')}
          </Typography>
          <TextInput
            sx={{
              width: '100%',
              height: '36px',
            }}
            placeholder={t('catalog:modals.save_product_modal.fields.name')}
            max={50}
            onChange={onChangeText('name')}
            value={name}
          />
          <TextArea
            sx={{ marginTop: '8px', border: `1px solid ${color.line}` }}
            value={description}
            onChange={onChangeText('description')}
            placeholder={t(
              'catalog:modals.save_product_modal.fields.description',
            )}
            type="text"
            max={200}
          />
        </NewSavedListWrapper>
      ) : (
        <AddNewListWrapper onClick={() => setCreateNewSaved(true)}>
          <IconBtn disableBorder size={16} href={`${Sprite}#icon-plus`} />
          <Typography variant={'bodyBoldSmall'} color={color.gray_60}>
            {t('common:buttons.new_list')}
          </Typography>
        </AddNewListWrapper>
      )}
      <ButtonsWrapper>
        {createNewSaved ? (
          <>
            <OutlineButtonBase onClick={() => setCreateNewSaved(false)}>
              {t('common:buttons.cancel')}
            </OutlineButtonBase>
            <ButtonBase
              disabled={
                favoritesChosedArr.length === 0 &&
                !saveActive &&
                !savedData.name
              }
              onClick={handleSetSavedList}
            >
              {t('common:buttons.create')}
            </ButtonBase>
          </>
        ) : (
          <ButtonBase
            disabled={!saveActive && !favoritesChosedArr.length}
            onClick={handleAddProductToSaved}
          >
            {t('common:buttons.ready')}
          </ButtonBase>
        )}
      </ButtonsWrapper>
    </ContentWrap>
  );
};

const NewSavedListWrapper = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 8px;
  flex-direction: column;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  width: inherit;
  max-width: 100%;
  flex-direction: row;
  justify-content: flex-end;

  button + button {
    margin-left: 8px;
  }
`;

const AddNewListWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 20px;
  padding: 6px 10px;

  button + span {
    margin-left: 8px;
  }
`;

const CreatedSavedList = styled.div`
  display: flex;
  cursor: pointer;
  flex-direction: row;
  align-items: center;
  padding: 6px 10px;
  width: 100%;

  span + span {
    margin-left: 8px;
  }

  &:hover {
    background: ${props => props.color};
  }
`;

const SavedSection = styled.div`
  padding: 6px 10px;
  display: flex;
  align-items: center;
  flex-direction: row;

  svg + span {
    margin-left: 11px;
  }
`;

const ContentWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  width: 442px;
  max-width: 100%;
`;

SavedGoods.propTypes = {
  productId: PropTypes.number,
  callbackAfterAddToFavoriteList: PropTypes.func.isRequired,
};
