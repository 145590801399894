import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Sprite } from '@root/assets/svg';
import { IconBtn } from '@root/ui';

import { OutlinedInput, useTheme } from '@mui/material';

export const CounterInput = ({
  data,
  handleDecrement,
  handleAddProduct,
  handleClickOutside,
  inputRef,
  handleEnter,
  inputWidth,
  errorBorder,
  productQuantity,
  handleSetNumberAddToCart,
  isDisabledIncrementBtn,
}) => {
  const { color } = useTheme();
  return (
    <CounterWrapper
      colors={{ white: color.white, line: color.line }}
      border={errorBorder}
      onClick={e => e.preventDefault()}
    >
      <IconBtn
        onClick={handleDecrement}
        disableBorder
        size={8}
        href={`${Sprite}#icon-minus`}
        data-test-id="ButtonDecrementFromCart"
        id={data?.id}
      />
      <form
        onSubmit={e => e.preventDefault()}
        onBlur={handleClickOutside}
        onFocus={e => e.preventDefault()}
      >
        <OutlinedInput
          id="phone-number-input"
          type="number"
          inputRef={inputRef}
          onKeyDown={handleEnter}
          sx={{
            height: 32,
            '&.MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
              border: 'none',
            },
            width: inputWidth,
          }}
          inputProps={{
            notched: false,

            style: {
              minWidth: 28,
              padding: 0,
              textAlign: 'center',
            },
          }}
          value={productQuantity}
          onChange={e => handleSetNumberAddToCart(e)}
          data-test-id="InputProductManager"
        />
      </form>
      <IconBtn
        onClick={handleAddProduct}
        disableBorder
        size={10}
        disabled={isDisabledIncrementBtn}
        href={`${Sprite}#icon-plus`}
        data-test-id="ButtonIncrementFromCart"
      />
    </CounterWrapper>
  );
};

const CounterWrapper = styled.div`
  display: flex;
  height: 32px;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: 8px;
  margin-bottom: 6px;
  background: ${props => props.colors.white};
  border: ${props =>
    props.border ? '1px solid red' : `1px solid ${props.colors.line}`};
  border-radius: 4px;
`;

CounterInput.propTypes = {
  data: PropTypes.exact({
    brand: PropTypes.string,
    can_buy: PropTypes.exact({
      price: PropTypes.bool,
      price_uah_cash: PropTypes.bool,
      price_uah_no_pdv: PropTypes.bool,
      price_uah_pdv: PropTypes.bool,
    }),
    cart: PropTypes.shape({
      cart_product_id: PropTypes.number,
      id: PropTypes.number,
      personal_amount: PropTypes.exact({
        price: PropTypes.string,
        price_uah_cash: PropTypes.string,
        price_uah_no_pdv: PropTypes.string,
        price_uah_pdv: PropTypes.string,
      }),
      quantity: PropTypes.number,
    }),
    cart_product_id: PropTypes.number,
    description: PropTypes.string,
    has_analogs: PropTypes.bool,
    id: PropTypes.number,
    image: PropTypes.string,
    images_quantity: PropTypes.number,
    in_compare: PropTypes.bool,
    is_in_cart: PropTypes.bool,
    is_saved: PropTypes.bool,
    model: PropTypes.string,
    name: PropTypes.string,
    personal_price: PropTypes.exact({
      price: PropTypes.string,
      price_uah_cash: PropTypes.string,
      price_uah_no_pdv: PropTypes.string,
      price_uah_pdv: PropTypes.string,
    }),
    price: PropTypes.exact({
      price: PropTypes.string,
      price_uah_cash: PropTypes.string,
      price_uah_no_pdv: PropTypes.string,
      price_uah_pdv: PropTypes.string,
    }),
    prices: PropTypes.exact({
      price: PropTypes.exact({
        product: PropTypes.string,
        personal: PropTypes.string,
      }),
      price_uah_cash: PropTypes.exact({
        product: PropTypes.string,
        personal: PropTypes.string,
      }),
      price_uah_no_pdv: PropTypes.exact({
        product: PropTypes.string,
        personal: PropTypes.string,
      }),
      price_uah_pdv: PropTypes.exact({
        product: PropTypes.string,
        personal: PropTypes.string,
      }),
    }),
    quantity: PropTypes.number,
    sku: PropTypes.string,
    slug: PropTypes.string,
    stock: PropTypes.number,
    stock_max: PropTypes.number,
    stock_percent: PropTypes.number,
    url: PropTypes.string,
    warehouses: PropTypes.arrayOf(
      PropTypes.exact({
        city_name: PropTypes.string,
        warehouses: PropTypes.exact({
          name: PropTypes.string,
          stock: PropTypes.number,
          stock_max: PropTypes.number,
          stock_percent: PropTypes.number,
        }),
      }),
    ),
  }),
  handleDecrement: PropTypes.func,
  handleAddProduct: PropTypes.func,
  handleClickOutside: PropTypes.func,
  inputRef: PropTypes.node,
  handleEnter: PropTypes.func,
  inputWidth: PropTypes.number,
  errorBorder: PropTypes.bool,
  productQuantity: PropTypes.number,
  handleSetNumberAddToCart: PropTypes.func,
  isDisabledIncrementBtn: PropTypes.bool,
};
