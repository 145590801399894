import { forwardRef } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';

import styled from 'styled-components';

import { theme } from '@root/styles';

import logisticSelectors from '@redux/logistic/logistic-selectors';

import { useTheme } from '@mui/material/styles';

import { DeliveryFromClient } from './DeliveryFromClient';
import { NoDeliveryDetails } from './NoDeliveryDetails';
import { SendingToClient } from './SendingToClient';

export const DeliveryDetails = forwardRef((_, ref) => {
  const { logisticsTab } = useParams();
  const { color } = useTheme();

  const selectedDelivery = useSelector(logisticSelectors.getSelectedDelivery);

  if (!selectedDelivery) {
    return (
      <DeliveryDetailsWrapper color={color.line} ref={ref}>
        <NoDeliveryDetails />
      </DeliveryDetailsWrapper>
    );
  }

  if (selectedDelivery && logisticsTab === 'delivery-from-client') {
    return (
      <DeliveryDetailsWrapper color={color.line} ref={ref}>
        <DeliveryFromClient />
      </DeliveryDetailsWrapper>
    );
  }

  if (selectedDelivery && logisticsTab === 'sending-to-client') {
    return (
      <DeliveryDetailsWrapper color={color.line} ref={ref}>
        <SendingToClient />
      </DeliveryDetailsWrapper>
    );
  }
});

DeliveryDetails.propTypes = {};

const DeliveryDetailsWrapper = styled.div`
  border: ${({ color }) => `1px solid ${color}`};
  border-radius: 4px;
  background: ${theme.color.white};
  flex: 0 0 calc(58% - 24px);
  height: calc(100vh - 172.5px);
  padding ${({ padding }) => padding};

  @media (max-width: 1400px) {
    flex: 0 0 50%;
  }

  @media (max-width: 1400px) {
    flex: 0 0 40%;
  }

  @media (max-width: 1024px) {
    flex: 0 0 100%;
  }
`;
