import {
  NavLink,
  useParams,
} from '../../../../node_modules/react-router-dom/dist/index';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import styled from 'styled-components';

import { Sprite } from '@root/assets/svg/index';
import { InfiniteScroll } from '@root/components/InfiniteScroll/index';
import KnowledgeListSearch from '@root/components/Knowledge/KnowledgeListSearch/index';
import KnowledgeTitleRename from '@root/components/Knowledge/KnowledgeTitleRename/index';
import authSelectors from '@root/redux/auth/auth-selectors';
import knowledgeSelectors from '@root/redux/knowledge/knowledge-selectors';
import { ButtonBase } from '@root/ui/Button/ButtonBase/index';
import { ProgressLoader } from '@root/ui/Loader/ProgressLoader/index';

import { Typography, useTheme } from '@mui/material';

import { Card } from '../../../../node_modules/@mui/material/index';

const KnowledgeList = ({ list, onSearch, scrollToBottom}) => {
  const { t } = useTranslation(['knowledge']);
  const { brandId, slug } = useParams();
  const { color } = useTheme();
  const role = useSelector(authSelectors.getRole);
  const listLoading = useSelector(knowledgeSelectors.getKnowledgeListLoading);

  const handleScrollToBottom = () => {
    scrollToBottom()
  }


  return (
    <Card
      sx={{ height: `calc(100%)`, display: 'flex', flexDirection: 'column' }}
    >
      <KnowledgeListSearch onSearch={onSearch} />
      <KnowledgeTitleRename />
      {role === 'admin' && slug !== 'new-post' && (
        <AddPostWrap color={color}>
          <NavLink to={`/knowledge/${brandId}/new-post`}>
            <ButtonBase sx={{ p: '11px' }}>{t('create_post')}</ButtonBase>
          </NavLink>
        </AddPostWrap>
      )}

      <ItemsWrap>
        {listLoading ? (
          <ProgressLoader />
        ) : (
          <InfiniteScroll data={list} onScroll={e => {}}>
            {({ isInView, item, index }) => {
              return (
                <ItemWrap
                  to={`/knowledge/${brandId}/${item.id}`}
                  active={item.id == slug}
                  color={color}
                  onClick={() => handleScrollToBottom()}
                >
                  <Typography
                    variant="bodyMedium"
                    color={color.gray}
                    component="p"
                  >
                    {item.title}
                  </Typography>
                </ItemWrap>
              );
            }}
          </InfiniteScroll>
        )}
      </ItemsWrap>
    </Card>
  );
};

export default KnowledgeList;

const ItemsWrap = styled.div`
  max-height: calc(100%);
  /* height: 100%; */
  /* overflow-y: auto; */

  flex: 1 1 auto;
  overflow-y: auto;
  height: 0px;
  position: relative;

  @media(max-width: 1024px) {
    height: calc(100vh - 251px);
    max-height: unset;
  }
`;

const ItemWrap = styled(NavLink)`
  padding: 16px;
  border-bottom: 1px solid ${({ color }) => color.line};
  display: flex;
  position: relative;
  cursor: pointer;
  background-color: ${({ active, color }) =>
    active ? color.background : 'unset'};
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 3px;
    height: 100%;
    background-color: ${({ color, active }) => (active ? color.main : 'unset')};
  }
`;

const AddPostWrap = styled.div`
  padding: 16px;
  border-bottom: 1px solid ${({ color }) => color.line};
  display: flex;
  & button {
    flex-grow: 1;
  }
  a {
    flex-grow: 1;
    display: flex;
  }
`;
