import * as React from 'react';
import { useEffect, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import { RequiredStar, Sprite } from '@root/assets/svg';
import { InfoIcon } from '@root/assets/svg';
import { BaseModalHeader } from '@root/components';
import { categoryList } from '@root/conf/FilterConf';
import { getErrorStack } from '@root/helpers';
import { CreateFilter } from '@root/modules';
import {
  BasicSelect,
  ModalBase,
  OutlineButtonBase,
  RadioCustom,
  TextInput,
} from '@root/ui';

import categoryOperation from '@redux/category/category-operation';
import categorySelectors from '@redux/category/category-selectors';

import { FormControlLabel, RadioGroup, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

export const CreateFilterModal = ({ open, handleClose, changetItem }) => {
  const [data, setData] = useState({
    id: null,
    category_id: null,
    name: '',
    is_multiple: false,
    sort: null,
    values: [{ id: null, name: '' }],
    deleted_values: [],
  });
  const [error, setError] = useState({});
  const { color } = useTheme();
  const { t } = useTranslation(['catalog', 'common', 'validation'], {
    useSuspense: false,
  });
  const { category_id, name, sort, is_multiple, values } = data;

  const dispatch = useDispatch();
  const category = useSelector(categorySelectors.getCategoryListToEdit);

  useEffect(() => {
    if (changetItem) {
      setData({
        id: changetItem.id,
        category_id: changetItem.category_id,
        name: changetItem.name,
        is_multiple: changetItem.is_multiple,
        sort: changetItem.sort,
        values: changetItem.values,
        deleted_values: [],
      });
    }
  }, []);

  const handleCategoryChose = value => {
    setData(prevState => ({ ...prevState, category_id: value }));
  };
  const handleSetMultiple = value =>
    setData(prevState => ({ ...prevState, is_multiple: value === 'true' }));
  const onChange = name => value => {
    setData(prevState => ({ ...prevState, [name]: value }));
  };
  const addFilter = () => {
    setData(prevState => ({
      ...prevState,
      values: [
        ...prevState.values,
        {
          id: null,
          name: '',
        },
      ],
    }));
  };
  const removeFilter = (idx, array) => {
    const copyList = [...array];

    if (copyList[idx].id) {
      setData(prevState => ({
        ...prevState,
        deleted_values: [...prevState.deleted_values, copyList[idx].id],
      }));
    }
    setData(prevState => {
      return {
        ...prevState,
        values: copyList.filter((item, itemIdx) => itemIdx !== idx),
      };
    });
  };
  const handleFilterName = index => value => {
    setData(prevState => {
      return {
        ...prevState,
        values: prevState.values.map((item, itemIdx) =>
          itemIdx === index ? { ...item, name: value } : item,
        ),
      };
    });
  };
  const validation = () => {
    let flag = false;
    let currentError = {};

    const { category_id, name, sort, values } = data;

    Object.keys({
      category_id,
      name,
      sort,
    }).forEach(key => {
      if (!data[key] || data[key] === '') {
        currentError = getErrorStack(
          currentError,
          key,
          t('validation:required'),
        );
        flag = true;
      }
    });
    let valuesArrErrors = [];
    valuesArrErrors = values.reduce((acc, { name }, i) => {
      if (!name || name === '') {
        acc[i] = {
          ...acc[i],
          filterError: {
            init: true,
            text: t('validation:required'),
          },
        };
        flag = true;
      }
      return acc;
    }, []);

    currentError = {
      ...currentError,
      valuesArrErrors: valuesArrErrors,
    };

    setError(currentError);
    return flag;
  };

  const handleCreateNewFilter = () => {
    if (validation()) return;
    dispatch(categoryOperation.postNewFilter(data));
    handleClose();
    setData({
      id: null,
      category_id: null,
      name: '',
      is_multiple: false,
      sort: null,
      values: [{ id: null, name: '' }],
      deleted_values: [],
    });
  };
  const handleCleanDataBeforeClose = () => {
    handleClose();
    setData({
      id: null,
      category_id: null,
      name: '',
      is_multiple: false,
      sort: null,
      values: [{ id: null, name: '' }],
      deleted_values: [],
    });
  };
  const handleDragEnd = result => {
    const { source, destination } = result;

    if (!destination) return;

    const reorderRes = JSON.parse(JSON.stringify(values));
    const [removed] = reorderRes.splice(source.index, 1);
    reorderRes.splice(destination.index, 0, removed);
    setData(prevState => ({ ...prevState, values: reorderRes }));
  };

  const renderSelectedValue = selectedId => {
    return (
      <>
        {typeof selectedId !== 'string' ? (
          categoryList(category).map(parent =>
            parent.id === selectedId ? (
              <Typography
                variant={'bodyBoldMedium'}
                component={'span'}
                sx={{
                  color: `${color.gray} !important`,
                }}
              >
                {parent.name}
              </Typography>
            ) : (
              parent.children.map(
                child =>
                  child.id === selectedId && (
                    <SelectedValueBox key={parent.id + child.id}>
                      <Typography
                        variant={'bodyBoldMedium'}
                        component={'span'}
                        sx={{
                          color: `${color.gray} !important`,
                        }}
                      >
                        {child?.name}
                      </Typography>
                      <Typography
                        variant={'bodyMedium'}
                        component={'span'}
                        sx={{ color: `${color.gray_80} !important` }}
                      >
                        в категорії
                      </Typography>
                      <Typography
                        variant={'bodyBoldMedium'}
                        component={'span'}
                        sx={{ color: `${color.gray_80} !important` }}
                      >
                        {parent?.name}
                      </Typography>
                    </SelectedValueBox>
                  ),
              )
            ),
          )
        ) : (
          <Typography
            variant={'bodyMedium'}
            component={'span'}
            sx={{ color: color.gray_60 }}
          >
            {t('common:buttons.add')}
          </Typography>
        )}
      </>
    );
  };

  return (
    <ModalBase
      open={open}
      onClose={handleClose}
      modalSx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
      sx={{
        top: 'initial',
        left: 'initial',
        transform: 'initial',
      }}
      modalHeader={
        <BaseModalHeader
          mainTitle={t('catalog:modals.add_new_filter_modal.add_new_filter')}
          isActionButtons={true}
          isSearch={false}
          toolbarSx={{
            width: 'auto',
            padding: '0 0 12px 0 !important',
            minHeight: 'initial !important',
          }}
          baseWrapperSx={{
            width: '100%',
          }}
          defPadding={'0px'}
          saveOrUpdate={handleCreateNewFilter}
          closeModalCallback={handleCleanDataBeforeClose}
        />
      }
    >
      <ModalWrapper>
        <BasicSelect
          error={error?.category_id?.init}
          helperText={error?.category_id?.text}
          sx={{
            p: 0,
            marginBottom: '20px',
            width: '816px',
          }}
          labelSx={{
            fontWeight: 400,
          }}
          label={t(
            'catalog:modals.add_new_filter_modal.fields.filter_for_product_category',
          )}
          group={true}
          currentId={category_id}
          height={'36px !important'}
          data={categoryList(category)}
          name={t('common:buttons.add')}
          callBack={handleCategoryChose}
          renderValue={value => renderSelectedValue(value)}
          required={true}
        />
        <TextInput
          error={error?.name?.init}
          errorText={error?.name?.text}
          id="input-with-icon-adornment"
          sx={{
            width: '816px',
            height: '36px',
            input: {
              fontSize: '0.875rem',
            },
          }}
          styleWrapper={{
            display: 'flex',
            flexDirection: 'column',
          }}
          label={t('catalog:modals.add_new_filter_modal.fields.block_name')}
          labelSx={{
            fontWeight: 400,
          }}
          placeholder={t('common:type_name')}
          max={100}
          onChange={onChange('name')}
          value={name}
          required={true}
        />
        <LabelWrapper>
          <RequiredStarImg src={RequiredStar} alt="Required Star" />
          <Typography
            variant={'bodySmall'}
            sx={{ color: color.gray, ml: '2px', fontWeight: 400 }}
            component={'h2'}
          >
            {t('catalog:modals.add_new_filter_modal.checkbox.type')}
          </Typography>
        </LabelWrapper>
        <Typography
          marginTop={'4px'}
          variant={'bodySmall'}
          sx={{
            display: 'flex',
            alignItems: 'center',
            marginTop: '5px',
          }}
          color={color.gray_60}
          component={'p'}
        >
          <Img src={InfoIcon} />
          {t('catalog:modals.add_new_filter_modal.warning')}
        </Typography>
        <RadioGroup
          onChange={(_, value) => handleSetMultiple(value)}
          value={is_multiple}
          sx={{
            marginTop: '5px',
          }}
        >
          <FormControlLabel
            control={<RadioCustom />}
            sx={{
              marginLeft: '-9px',
            }}
            label={
              <Typography
                variant={'bodyBoldMedium'}
                component={'span'}
                sx={{
                  fontWeight: 400,
                  color: color.gray_80,
                }}
              >
                {t(
                  'catalog:modals.add_new_filter_modal.checkbox.filter_type_single',
                )}
              </Typography>
            }
            value={false}
          />
          <FormControlLabel
            control={<RadioCustom />}
            sx={{
              marginLeft: '-8px',
            }}
            label={
              <Typography
                variant={'bodyBoldMedium'}
                component={'span'}
                sx={{
                  fontWeight: 400,
                  color: color.gray_80,
                }}
              >
                {t(
                  'catalog:modals.add_new_filter_modal.checkbox.filter_type_multiple',
                )}
              </Typography>
            }
            value={true}
          />
        </RadioGroup>
        <TextInput
          error={error?.sort?.init}
          errorText={error?.sort?.text}
          type={'number'}
          id="input-with-icon-adornment"
          sx={{
            width: '816px',
            height: '36px',
            input: {
              fontSize: '0.875rem',
            },
          }}
          styleWrapper={{
            margin: '20px 0',
            display: 'flex',
            flexDirection: 'column',
          }}
          label={t('catalog:modals.add_new_filter_modal.fields.sort_order')}
          labelSx={{
            fontWeight: 400,
          }}
          placeholder={t(
            'catalog:modals.add_new_filter_modal.fields.type_sort_order',
          )}
          max={100}
          onChange={onChange('sort')}
          value={sort}
          required={true}
        />
        <Typography
          variant={'bodyBoldLarge'}
          color={color.gray}
          sx={{ mb: '10px' }}
        >
          {t('catalog:modals.add_new_filter_modal.fields.filter_value')}
        </Typography>
        <ListWrapper
          colors={{ white: color.white, gray: color.gray_40, line: color.line }}
        >
          <DragDropContext onDragEnd={handleDragEnd}>
            <div>
              <Droppable droppableId="droppable">
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                    style={{
                      paddingBottom: snapshot.isDraggingOver ? '76px' : '0',
                    }}
                  >
                    {values.map((item, index, array) => (
                      <Draggable
                        key={'f-' + index}
                        draggableId={'f' + index}
                        index={index}
                      >
                        {(provided, dragSnapshot) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={{
                              ...provided.draggableProps.style,
                              border: dragSnapshot.isDragging
                                ? `1px solid ${color.main}`
                                : '1px solid transparent',
                              background: dragSnapshot.isDragging
                                ? 'aliceblue'
                                : color.white,
                            }}
                          >
                            <CreateFilter
                              error={error}
                              index={index}
                              item={item}
                              key={index}
                              name={item.name}
                              handleDeleteFilter={() =>
                                removeFilter(index, array)
                              }
                              handleFilterName={() => handleFilterName(index)}
                            />
                          </div>
                        )}
                      </Draggable>
                    ))}
                  </div>
                )}
              </Droppable>
            </div>
          </DragDropContext>
        </ListWrapper>
        <OutlineButtonBase
          onClick={addFilter}
          sx={{
            width: '752px',
            height: '36px',
            margin: '20px 0 0 33px',
          }}
        >
          <Svg stroke={color.gray_60}>
            <use href={`${Sprite}#icon-plus`}></use>
          </Svg>
          {t('catalog:modals.add_new_filter_modal.buttons.add_filter_value')}
        </OutlineButtonBase>
      </ModalWrapper>
    </ModalBase>
  );
};

const Img = styled.img`
  width: auto;
  height: auto;
  margin-right: 6px;
  position: relative;
  top: -2px;
`;

const SelectedValueBox = styled.div`
  display: inline-flex;
  gap: 5px;
`;

const ListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  margin: 0 -20px;
`;

const Svg = styled.svg`
  stroke: ${props => props.stroke};
  width: 16px;
  height: 16px;
  margin-right: 4px;
`;

const LabelWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  margin-top: 20px;
`;

const RequiredStarImg = styled.img`
  margin-top: 3px;
`;

const ModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  width: 830px;
  max-height: 900px;
`;

CreateFilterModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  changetItem: PropTypes.exact({
    category_id: PropTypes.number,
    category_name: PropTypes.string,
    id: PropTypes.number,
    is_multiple: PropTypes.bool,
    name: PropTypes.string,
    slug: PropTypes.string,
    sort: PropTypes.number,
    values: PropTypes.arrayOf(
      PropTypes.exact({
        id: PropTypes.number,
        name: PropTypes.string,
        slug: PropTypes.string,
        sort: PropTypes.number,
      }),
    ),
  }),
};
