import {Suspense, useEffect, useRef, useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {Outlet, useParams} from 'react-router-dom';

import Echo from 'laravel-echo';
import io from 'socket.io-client';
import styled from 'styled-components';

import BonusWarningModal2 from '@root/components/BonusWarningModal2/index';
import BonusWarningModal from '@root/components/BonusWarningModal/index';
import { Container } from '@root/middleware';
import { WidthChecker } from '@root/middleware/WidthChecker';
import { Header } from '@root/modules';
import ReviewForm from '@root/modules/Forms/ReviewForm/index';
import TutorialButtonFixed from '@root/modules/TutorialButtonFixed/index';
import { Loader } from '@root/ui';
import { SideBar } from '@root/widgets';

import notificationsOperation from '@redux/notifications/notifications-operation';
import userSelectors from '@redux/user/user-selectors';

import Box from '@mui/material/Box';

export const TemplateMain = () => {
  const getUserProfile = useSelector(userSelectors.getUserProfile);
  const dispatch = useDispatch();
  const [isSidebarVisible, setIsSidebarVisible] = useState(false);
  //  close sidebar vars
  const currentPage= useParams();
  const blurContentRef = useRef(null);

  useEffect(() => {
    const echo = new Echo({
      broadcaster: 'socket.io',
      transports: ['websocket', 'polling'],
      host: process.env.REACT_APP_ECHO_SERVER_HOST,
      client: io,
      disableStats: true,
      encrypted: true,
      forceTLS: true,
      enabledTransports: ['ws', 'wss'],
    });

    const channel = echo.channel(
      `viatec_database_notifications-${getUserProfile.id}`,
    );

    dispatch(
      notificationsOperation.getNotifications({
        page: 1,
        per_page: 10,
        type: 'base',
      }),
    );

    channel.listen('.has-new', e => {
      dispatch(
        notificationsOperation.getNotifications({
          page: 1,
          per_page: 10,
          type: 'base',
        }),
      );
    });

    return () => {
      echo.leaveChannel(`viatec_database_notifications-${getUserProfile.id}`);
    };
  }, []);

  //  close sidebar if click on content side
  const handleClickOutsideNavBox = (event) => {

    if (blurContentRef.current &&
        blurContentRef.current.contains(event.target) &&
        isSidebarVisible === true) {

      setIsSidebarVisible(false)
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutsideNavBox);

    return () => {
      document.removeEventListener('click', handleClickOutsideNavBox);
    };
  }, [isSidebarVisible, currentPage]);
  //  end close sidebar if click content side

  //close sidebar and remove blur if user click on another page in header
  useEffect(() => {
    if (isSidebarVisible === true) {
      setIsSidebarVisible(false)
    }
  }, [currentPage]);


  return (
    <WidthChecker>
      <Container>
        <Box sx={{ display: 'flex', height: '100dvh' }}>
          <SideBar
              isSidebarVisible={isSidebarVisible}
              setIsSidebarVisible={setIsSidebarVisible}
          />

          <Page>
            <Header
              isSidebarVisible={isSidebarVisible}
              setIsSidebarVisible={() =>
                setIsSidebarVisible(prevState => !prevState)
              }
            />
            <BlurContent isSidebarVisible={isSidebarVisible} ref={blurContentRef}>
              <Suspense fallback={<Loader />}>
                <Outlet />
              </Suspense>
            </BlurContent>

          </Page>
        </Box>

        <TutorialButtonFixed />
        <ReviewForm />
        <BonusWarningModal />
        <BonusWarningModal2 />
      </Container>
    </WidthChecker>
  );
};

const Page = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

const BlurContent  = styled.div`
  filter: ${props => (props.isSidebarVisible ? 'blur(5px)' : '')};

  width: 100%;
  height: 100%;
  overflow: hidden;

`;
