import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import { ContactField, Empty } from '@root/components';
import { handleDispatchFetch } from '@root/helpers';
import { getErrorStack } from '@root/helpers/validation';
import activityAreasOperation from '@root/redux/activity-areas/activity-areas-operation';
import activityAreasSelectors from '@root/redux/activity-areas/activity-areas-selectors';

import adminOperation from '@redux/admin/admin-operation';
import adminSelectors from '@redux/admin/admin-selectors';

import { Typography } from '@mui/material';

export const AgencyForm = () => {
  const activityAreas = useSelector(activityAreasSelectors.getActivityList);
  const data = useSelector(adminSelectors.getBusinessDetails);
  const { activity_areas, site, employees_quantity, name, logo, status, id } =
    data;

  const { t } = useTranslation(['admin'], { useSuspense: false });
  const [errors, setErrors] = useState({});

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(activityAreasOperation.getActivityData({}));
  }, []);

  const handleResetErrors = name => {
    if (name) {
      const copyErrors = JSON.parse(JSON.stringify(errors));
      delete copyErrors[name];
      setErrors(copyErrors);
    } else {
      setErrors({});
    }
  };

  const handleErrors = errors => {
    let currentError = {};
    console.log(errors);
    for (const [key, value] of Object.entries(errors)) {
      currentError = getErrorStack(
        currentError,
        key.replace(/\.\d+/, ''),
        value?.[0],
      );
    }
    setErrors(currentError);
  };

  const handleUpdateBusinessInfo = ({ id, payload, onEditSuccess }) => {
    handleResetErrors();
    handleDispatchFetch(
      ({ onResolve, onReject }) => {
        dispatch(
          adminOperation.updateAdminBusiness({
            id,
            payload,
            onResolve,
            onReject,
          }),
        );
      },
      () => onEditSuccess(),
      errors => {
        handleErrors(errors.response.data.errors);
      },
    );
  };

  return (
    <Form>
      {logo ? (
        <CompanyLogo src={logo} alt="Logo Company" />
      ) : (
        <Empty
          type={'EmptyLogo'}
          isCompany
          tag={'div'}
          wrapperStyles={{
            maxWidth: '230px',
            height: '60px',
            width: '100%',
          }}
          contentStyles={{
            width: '100%',
            height: '100%',
          }}
        />
      )}
      <Typography
        variant={'h2'}
        sx={{ textTransform: 'uppercase', mt: '24px' }}
      >
        {name}
      </Typography>
      <Wrapper>
        <ContactField
          type="switch"
          padding={'28px 0'}
          title={t('admin:business_table_conf.status')}
          editValue={status === 'active'}
          errors={errors.status}
          value={
            status === 'active'
              ? t('admin:active_status')
              : t('admin:inactive_status')
          }
          variant={'bodyMedium'}
          onEdit={(payload, onEditSuccess) =>
            handleUpdateBusinessInfo({
              id,
              payload: { status: payload ? 'active' : 'inactive' },
              onEditSuccess,
            })
          }
          id={id}
        />

        <ContactField
          padding={'28px 0'}
          title={t('admin:business_table_conf.number_of_employees')}
          variant={'bodyMedium'}
          value={employees_quantity}
          errors={errors.employees_quantity}
          onEdit={(payload, onEditSuccess) => {
            handleUpdateBusinessInfo({
              id,
              payload: { employees_quantity: payload },
              onEditSuccess,
            });
          }}
          id={id}
        />

        <ContactField
          padding={'28px 0'}
          title={t('admin:business_table_conf.site')}
          variant={'bodyMedium'}
          value={site}
          errors={errors.site}
          onEdit={(payload, onEditSuccess) => {
            handleUpdateBusinessInfo({
              id,
              payload: { site: payload },
              onEditSuccess,
            });
          }}
          id={id}
        />

        <ContactField
          type={'select'}
          padding={'28px 0'}
          title={t('admin:business_table_conf.areas_of_activity')}
          variant={'bodyMedium'}
          value={activity_areas}
          data={activityAreas}
          multiple={true}
          errors={errors.activity_areas}
          onEdit={(payload, onEditSuccess) => {
            handleUpdateBusinessInfo({
              id,
              payload: { activity_areas: payload },
              onEditSuccess,
            });
          }}
          id={id}
        />
      </Wrapper>
    </Form>
  );
};

const CompanyLogo = styled.img`
  height: 60px;
  object-fit: contain;
  align-self: flex-start;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 425px;
  padding-bottom: 50px;
`;

const Wrapper = styled.div`
  margin-top: 28px;
`;

AgencyForm.propTypes = {
  data: PropTypes.exact({
    activity_areas: PropTypes.arrayOf(
      PropTypes.exact({ id: PropTypes.number, name: PropTypes.string }),
    ),
    additional_info: PropTypes.string,
    company_type: PropTypes.string,
    company_type_id: PropTypes.number,
    contractor_code: PropTypes.number,
    employees_quantity: PropTypes.number,
    id: PropTypes.number,
    identification_code: PropTypes.number,
    logo: PropTypes.string,
    name: PropTypes.string,
    official_name: PropTypes.string,
    site: PropTypes.string,
    status: PropTypes.string,
  }),
};
