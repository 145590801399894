export const MutualSettlementsConf = (t, screenWidth) => [
  {
    label:
      screenWidth < 769
        ? t('common:notifications.all')
        : t('mutual_settlements:conf.date'),
    slug: 'date',
    isDisplay: true,
    style: {
      minWidth: screenWidth < 769 ? '50%' : '120px',
      maxWidth: '100%',
    },
  },
  {
    isDisplay: screenWidth > 768,
    label: '#',
    slug: 'number',
    style: { ml: '20px', width: '120px', maxWidth: '150px' },
  },
  {
    isDisplay: screenWidth > 768,
    label: t('mutual_settlements:conf.document_type'),
    slug: 'document_type',
    style: {
      ml: '20px',
      width: '240px',
      maxWidth: '350px',
    },
  },
  {
    isDisplay: screenWidth > 768,
    label: t('mutual_settlements:conf.expenses'),
    slug: 'expenses',
    style: { ml: '10px', width: '120px' },
  },
  {
    isDisplay: screenWidth > 768,
    label: t('mutual_settlements:conf.income'),
    slug: 'income',
    style: { ml: '10px', width: '120px' },
  },
];

export const MutualSettlementsDetailsConf = t => [
  {
    label: '#',
    slug: 'number',
    style: {
      borderBottom: 'none',
      maxWidth: '30px',
      width: '100%',
      padding: 0,
    },
  },
  {
    label: t('mutual_settlements:details_conf.name'),
    slug: 'name',
    style: {
      width: '100%',
      ml: '20px',
      mr: 'auto',
      borderBottom: 'none',
      maxWidth: '350px',
      padding: 0,
    },
  },
  {
    label: t('mutual_settlements:details_conf.quantity'),
    slug: 'quantity',
    style: {
      width: '100%',
      ml: '20px',
      borderBottom: 'none',
      maxWidth: '60px',
      padding: 0,
      textAlign: 'center',
    },
  },
  {
    label: t('mutual_settlements:details_conf.unit_of_measurement'),
    slug: 'unit_of_measurement',
    style: {
      width: '100%',
      ml: '20px',
      borderBottom: 'none',
      maxWidth: '60px',
      padding: 0,
      textAlign: 'center',
    },
  },
  {
    label: t('mutual_settlements:details_conf.price'),
    slug: 'price',
    style: {
      width: '100%',
      ml: '20px',
      maxWidth: '100px',
      padding: 0,
      borderBottom: 'none',
      textAlign: 'center',
    },
  },
  {
    label: t('mutual_settlements:details_conf.amount'),
    slug: 'amount',
    style: {
      width: '100%',
      ml: '20px',
      maxWidth: '100px',
      padding: 0,
      borderBottom: 'none',
      textAlign: 'center',
    },
  },
];
