import { useTranslation } from 'react-i18next';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Sprite } from '@root/assets/svg';
import { CardDetails, ProductCard } from '@root/components';

import { useTheme } from '@mui/material';
import Typography from '@mui/material/Typography';

export const ListSimilarProducts = ({
  data,
  isOpen,
  openCard,
  choseIdCard,
  handelDetailCard,
  productsCategory,
  Scoop,
  setScoop,
  handleChoseItem,
  handelOpenSavedModal,
  addOrRemoveFromComparisons,
  windowWith,
  isHideArrowCard,
  mobileCatalogStyles,
  showControllers
}) => {
  const { color } = useTheme();
  const { t } = useTranslation('common');
  const dataSliceRelated = [...data].slice(0, 3);
  const renderDataRelated = isOpen ? data : dataSliceRelated;
  const getMarkupMoreBtn = prop => {
    const { text, handler, isShow, isHidden } = prop;
    if (isHidden) return <></>;
    return (
      <MoreBtn color={color.background}>
        {isShow && (
          <Svg stroke={color.main} width={16} height={16} rotateSvg={'180deg'}>
            <use href={`${Sprite}#icon-arrow_card`}></use>
          </Svg>
        )}
        <Typography
          sx={{
            mt: '4px',
            color: color.main,
            cursor: 'pointer',
            textAlign: 'center',
          }}
          variant={'bodyBoldSmall'}
          component={'p'}
          onClick={handler}
        >
          {text}
        </Typography>
        {!isShow && (
          <Svg stroke={color.main} width={16} height={16} rotateSvg={0}>
            <use href={`${Sprite}#icon-arrow_card`}></use>
          </Svg>
        )}
      </MoreBtn>
    );
  };

  return (
    <>
      {renderDataRelated.map(el => (
        <>
          <ProductCard
            handelOpenSavedModal={handelOpenSavedModal}
            addOrRemoveFromComparisons={addOrRemoveFromComparisons}
            data={el}
            isOpenDetail={openCard && choseIdCard === el.id}
            handelDetailCard={() => handelDetailCard(el.id)}
            handleChoseItem={() => handleChoseItem(el, el?.relation_quantity)}
            windowWith={windowWith}
            isHideArrowCard={isHideArrowCard}
            mobileCatalogStyles={mobileCatalogStyles}
            showControllers={showControllers}
          />
          {openCard && choseIdCard === el.id && (
            <CardDetails
              id={choseIdCard}
              handelOpenSavedModal={handelOpenSavedModal}
              addOrRemoveFromComparisons={addOrRemoveFromComparisons}
            />
          )}
        </>
      ))}
      {getMarkupMoreBtn({
        text:
          renderDataRelated.length > 3
            ? `${t('buttons.hide')} ${productsCategory}`
            : `${t('view_all')}  ${productsCategory}`,
        handler: () => setScoop(!Scoop),
        isShow: renderDataRelated.length > 3,
        isHidden: data.length <= 3,
      })}
    </>
  );
};

const MoreBtn = styled.div`
  padding: 11px;
  background: ${props => props.color};
`;

const Svg = styled.svg`
  stroke: ${props => props.stroke};
  margin: 0 auto;
  transform: ${({ rotateSvg }) => `rotate(${rotateSvg})`};
`;

ListSimilarProducts.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.exact({
      brand: PropTypes.string,
      can_buy: PropTypes.exact({
        price: PropTypes.bool,
        price_uah_cash: PropTypes.bool,
        price_uah_no_pdv: PropTypes.bool,
        price_uah_pdv: PropTypes.bool,
      }),
      cart: PropTypes.shape({
        cart_product_id: PropTypes.number,
        id: PropTypes.number,
        personal_amount: PropTypes.exact({
          price: PropTypes.string,
          price_uah_cash: PropTypes.string,
          price_uah_no_pdv: PropTypes.string,
          price_uah_pdv: PropTypes.string,
        }),
        quantity: PropTypes.number,
      }),
      description: PropTypes.string,
      has_analogs: PropTypes.bool,
      id: PropTypes.number,
      image: PropTypes.string,
      images_quantity: PropTypes.number,
      in_compare: PropTypes.bool,
      is_in_cart: PropTypes.bool,
      is_saved: PropTypes.bool,
      model: PropTypes.string,
      name: PropTypes.string,
      personal_price: PropTypes.exact({
        price: PropTypes.string,
        price_uah_cash: PropTypes.string,
        price_uah_no_pdv: PropTypes.string,
        price_uah_pdv: PropTypes.string,
      }),
      price: PropTypes.exact({
        price: PropTypes.string,
        price_uah_cash: PropTypes.string,
        price_uah_no_pdv: PropTypes.string,
        price_uah_pdv: PropTypes.string,
      }),
      prices: PropTypes.exact({
        price: PropTypes.exact({
          product: PropTypes.string,
          personal: PropTypes.string,
        }),
        price_uah_cash: PropTypes.exact({
          product: PropTypes.string,
          personal: PropTypes.string,
        }),
        price_uah_no_pdv: PropTypes.exact({
          product: PropTypes.string,
          personal: PropTypes.string,
        }),
        price_uah_pdv: PropTypes.exact({
          product: PropTypes.string,
          personal: PropTypes.string,
        }),
      }),
      sku: PropTypes.string,
      slug: PropTypes.string,
      stock: PropTypes.number,
      stock_max: PropTypes.number,
      stock_percent: PropTypes.number,
      url: PropTypes.string,
      warehouses: PropTypes.arrayOf(
        PropTypes.exact({
          city_name: PropTypes.string,
          warehouses: PropTypes.exact({
            name: PropTypes.string,
            stock: PropTypes.number,
            stock_max: PropTypes.number,
            stock_percent: PropTypes.number,
          }),
        }),
      ),
    }),
  ),
  isOpen: PropTypes.bool.isRequired,
  openCard: PropTypes.bool.isRequired,
  choseIdCard: PropTypes.oneOfType([PropTypes.object, PropTypes.number]),
  handelDetailCard: PropTypes.func.isRequired,
  productsCategory: PropTypes.string,
  Scoop: PropTypes.bool.isRequired,
  setScoop: PropTypes.func.isRequired,
  handleChoseItem: PropTypes.func.isRequired,
  handelOpenSavedModal: PropTypes.func.isRequired,
  addOrRemoveFromComparisons: PropTypes.func.isRequired,
};
