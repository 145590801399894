import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import styled from 'styled-components';

import { DeliveryFromClientDetailsConf } from '@root/conf';
import { WidthContext } from '@root/providers/WidthProvider';

import logisticSelectors from '@redux/logistic/logistic-selectors';

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';

export const DeliveryFromClient = () => {
  const { t } = useTranslation(['common', 'logistic'], { useSuspense: false });
  const { color } = useTheme();

  const { repair_array, return_array } = useSelector(
    logisticSelectors.getSelectedDelivery,
  );

  const { screenWidth } = useContext(WidthContext);

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow
            sx={{
              display: 'flex',
            }}
          >
            {DeliveryFromClientDetailsConf(t, screenWidth).map(
              detail =>
                detail.isDisplay && (
                  <TableCell sx={{ ...detail.style }}>
                    <Typography
                      variant="bodyBoldSmall"
                      component="p"
                      color={color.gray_60}
                    >
                      {detail.label}
                    </Typography>
                  </TableCell>
                ),
            )}
          </TableRow>
        </TableHead>
        <TableBody
          sx={{
            display: 'block',
          }}
        >
          {repair_array.map(data => (
            <TableRow
              key={data.id}
              sx={{
                display: 'flex',
                wordBreak: 'break-all',
              }}
            >
              {DeliveryFromClientDetailsConf(t).map(detail => {
                switch (detail.slug) {
                  case 'model':
                    return (
                      <TableCell key={detail.slug} sx={{ ...detail.style }}>
                        {screenWidth < 768 && (
                          <Typography color={color.gray_80} variant="bodySmall">
                            {data.model}
                          </Typography>
                        )}

                        {screenWidth < 769 && (
                          <MobileGrid>
                            <Typography
                              color={color.gray_60}
                              variant="bodyBoldMedium"
                            >
                              {t('common:table.table_heads.model')}:
                            </Typography>

                            <Typography
                              color={color.gray_80}
                              variant="bodyMedium"
                            >
                              {data.model || '-'}
                            </Typography>

                            <Typography
                              color={color.gray_60}
                              variant="bodyBoldMedium"
                            >
                              {t('common:table.table_heads.serial_number')}:
                            </Typography>

                            <Typography
                              color={color.gray_80}
                              variant="bodyMedium"
                            >
                              {data.serial}
                            </Typography>

                            <Typography
                              color={color.gray_60}
                              variant="bodyBoldMedium"
                            >
                              {t(
                                'common:table.table_heads.reported_malfunction',
                              )}
                              :
                            </Typography>

                            <Typography
                              color={color.gray_80}
                              variant="bodyMedium"
                            >
                              {data.malfunction}
                            </Typography>

                            <Typography
                              color={color.gray_60}
                              variant="bodyBoldMedium"
                            >
                              {t('common:table.table_heads.equipment')}:
                            </Typography>

                            <Typography
                              color={color.gray_80}
                              variant="bodyMedium"
                              textTransform="capitalize"
                            >
                              {data.complect}
                            </Typography>
                          </MobileGrid>
                        )}
                      </TableCell>
                    );
                  case 'serial_number':
                    return (
                      detail.isDisplay && (
                        <TableCell key={detail.slug} sx={{ ...detail.style }}>
                          <Typography color={color.gray_80} variant="bodySmall">
                            {data.serial}
                          </Typography>
                        </TableCell>
                      )
                    );
                  case 'reported_malfunction':
                    return (
                      detail.isDisplay && (
                        <TableCell key={detail.slug} sx={{ ...detail.style }}>
                          <Typography color={color.gray_80} variant="bodySmall">
                            {data.malfunction}
                          </Typography>
                        </TableCell>
                      )
                    );
                  case 'equipment':
                    return (
                      detail.isDisplay && (
                        <TableCell key={detail.slug} sx={{ ...detail.style }}>
                          <Typography
                            color={color.gray_80}
                            variant="bodySmall"
                            textTransform="capitalize"
                          >
                            {data.complect}
                          </Typography>
                        </TableCell>
                      )
                    );
                }
              })}
            </TableRow>
          ))}
          {return_array.map(data => (
            <TableRow
              key={data.id}
              sx={{
                display: 'flex',
                wordBreak: 'break-all',
              }}
            >
              {DeliveryFromClientDetailsConf(t).map(detail => {
                switch (detail.slug) {
                  case 'model':
                    return (
                      <TableCell key={detail.slug} sx={{ ...detail.style }}>
                        <Typography color={color.gray_80} variant="bodySmall">
                          {data.value || data.product_guid}
                        </Typography>
                      </TableCell>
                    );
                  case 'serial_number':
                    return (
                      <TableCell
                        key={detail.slug}
                        sx={{ ...detail.style }}
                      ></TableCell>
                    );
                  case 'reported_malfunction':
                    return (
                      <TableCell key={detail.slug} sx={{ ...detail.style }}>
                        <Typography color={color.gray_80} variant="bodySmall">
                          {t('logistic:return')}
                        </Typography>
                      </TableCell>
                    );
                  case 'equipment':
                    return (
                      <TableCell
                        key={detail.slug}
                        sx={{ ...detail.style }}
                      ></TableCell>
                    );
                }
              })}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const MobileGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: repeat(4, 1fr);
  gap: 10px 50px;

  @media (max-width: 576px) {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(8, 1fr);
    gap: 10px;
  }
`;

DeliveryFromClient.propTypes = {};
