import { createSlice } from '@reduxjs/toolkit';

import operation from './banners-operation';

const initialState = {
    loading: true,
    uploadLoading: false,
    banners: []
};

const bannersSlice = createSlice({
    name: 'banners',
    initialState,
    reducers: {

    },
    extraReducers: {
        //====getBanners====//
        [operation.getBanners.pending]: (state, action) => {
            state.loading = true;
        },
        [operation.getBanners.fulfilled]: (state, { payload }) => {
            state.banners = payload;
            state.loading = false;
        },
        [operation.getBanners.rejected]: (state, { payload }) => {
            state.loading = false;
        },
        //====addBanner====//
        [operation.addBanner.pending]: (state, action) => {
            state.loading = true;
            state.uploadLoading = true;
        },
        [operation.addBanner.fulfilled]: (state, { payload }) => {
            state.banners = payload;
            state.loading = false;
            state.uploadLoading = false;
        },
        [operation.addBanner.rejected]: (state, { payload }) => {
            state.loading = false;
            state.uploadLoading = false;
        },
        //====deleteBanner====//
        [operation.deleteBanner.pending]: (state, action) => {
            state.loading = true;
        },
        [operation.deleteBanner.fulfilled]: (state, { payload }) => {
            state.banners = payload;
            state.loading = false;
        },
        [operation.deleteBanner.rejected]: (state, { payload }) => {
            state.loading = false;
        },
        //====updateBanner====//
        [operation.updateBanner.pending]: (state, action) => {
            state.loading = true;
        },
        [operation.updateBanner.fulfilled]: (state, { payload }) => {
            state.banners = payload;
            state.loading = false;
        },
        [operation.updateBanner.rejected]: (state, { payload }) => {
            state.loading = false;
        },
        //====updateBannerSort====//
        [operation.updateBannerSort.pending]: (state, action) => {
            state.loading = true;
        },
        [operation.updateBannerSort.fulfilled]: (state, { payload }) => {
            state.banners = payload;
            state.loading = false;
        },
        [operation.updateBannerSort.rejected]: (state, { payload }) => {
            state.loading = false;
        },
        //====handleClickBanner====//
        [operation.handleClickBanner.pending]: (state, action) => {
            state.loading = true;
        },
        [operation.handleClickBanner.fulfilled]: (state, { payload }) => {
            state.loading = false;
        },
        [operation.handleClickBanner.rejected]: (state, { payload }) => {
            state.loading = false;
        },

    },
});

// export const {
//     redurer
// } = orderSlice.actions;

export default bannersSlice.reducer;
