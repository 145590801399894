import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import moment from 'moment/moment';
import { serialize } from 'object-to-formdata';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { HeaderDefault, RichEditor } from '@root/components';
import { getErrorStack, handleDispatchFetch, validURL } from '@root/helpers';
import {
  ButtonBase,
  DatePickerCustom,
  FileLoader,
  ModalBase,
  OutlineButtonBase,
  TextInput,
} from '@root/ui';

import newsAndEventsOperation from '@redux/news-and-events/news-and-events-operation';

const initialEventInfoData = {
  image: null,
  preview_image: null,
  date: moment(),
  time: '',
  title: '',
  content: '',
  link: '',
  location: '',
};

export const CreateEventModal = ({
  isOpen,
  handleCloseModal,
  editEventInfo,
  navigationAfterUpdateArticle,
}) => {
  const [eventInfo, setEventInfo] = useState(
    () => editEventInfo || initialEventInfoData,
  );
  const [errors, setErrors] = useState();
  const dispatch = useDispatch();

  const bigImageRef = useRef();
  const smallImageRef = useRef();

  const { t } = useTranslation(['validation', 'news_and_events'], {
    useSuspense: false,
  });

  useEffect(() => {
    if (!editEventInfo) return;

    setEventInfo({
      ...editEventInfo,
    });
  }, [editEventInfo]);

  const validation = () => {
    let currentError = {};
    let flag = false;

    const { title, link, content } = eventInfo;

    Object.keys({ title, link, content }).forEach(key => {
      if (!eventInfo[key] || !eventInfo[key].length) {
        currentError = getErrorStack(
          currentError,
          key,
          t('validation:required'),
        );

        flag = true;
      }
    });

    if (!validURL(link) && link !== '') {
      currentError = getErrorStack(
        currentError,
        'link',
        t('validation:enter_valid_url'),
      );
      flag = true;
    }

    setErrors(currentError);

    return flag;
  };

  const handleSubmit = e => {
    e.preventDefault();
    if (validation()) return;

    const image = typeof eventInfo.image === 'string' ? null : eventInfo.image;
    const preview_image =
      typeof eventInfo.preview_image === 'string'
        ? null
        : eventInfo.preview_image;

    if (!editEventInfo) {
      handleDispatchFetch(
        ({ onResolve, onReject }) =>
          dispatch(
            newsAndEventsOperation.createEvent({
              formData: serialize(
                {
                  ...eventInfo,
                  date: moment(eventInfo.date).format('YYYY-MM-DD'),
                  image,
                  preview_image,
                },
                {
                  indices: true,
                  nullsAsUndefineds: true,
                  booleansAsIntegers: true,
                },
              ),
              onResolve,
              onReject,
            }),
          ),
        () => {
          handleCloseModal();
        },
      );
    } else {
      handleDispatchFetch(
        ({ onResolve, onReject }) =>
          dispatch(
            newsAndEventsOperation.updateEvent({
              formData: serialize(
                {
                  ...eventInfo,
                  date: moment(eventInfo.date).format('YYYY-MM-DD'),
                  image,
                  preview_image,
                },
                {
                  indices: true,
                  nullsAsUndefineds: true,
                  booleansAsIntegers: true,
                },
              ),
              id: eventInfo.id,
              onResolve,
              onReject,
            }),
          ),
        res => {
          handleCloseModal();
          navigationAfterUpdateArticle(res.slug);
        },
      );
    }
  };

  const handleUpdateEventInfo = (name, value) => {
    setEventInfo(prevEventInfo => ({ ...prevEventInfo, [name]: value }));
  };

  const handleUpdateEventImages = (name, img) => {
    if (!img) {
      setEventInfo(prevNewsInfo => {
        if (name === 'image') {
          return {
            ...prevNewsInfo,
            delete_image: true,
            [name]: null,
          };
        } else {
          return {
            ...prevNewsInfo,
            delete_preview_image: true,
            [name]: null,
          };
        }
      });
    } else {
      setEventInfo(prevNewsInfo => {
        const { delete_image, delete_preview_image, ...newNewsInfo } =
          prevNewsInfo;
        if (name === 'image') {
          return { ...newNewsInfo, [name]: img };
        } else {
          return { ...newNewsInfo, [name]: img };
        }
      });
    }
  };

  return (
    <ModalBase
      handleClose={handleCloseModal}
      open={isOpen}
      sx={{ width: '100%', maxWidth: '630px' }}
      modalHeader={
        <HeaderDefault
          onClose={handleCloseModal}
          text={t('news_and_events:event_modal_title')}
        />
      }
    >
      <CreateEventModalWrapper>
        <FormWrapper onSubmit={handleSubmit}>
          <InputsWrapper>
            <FileLoader
              title={t('news_and_events:formFields.main_image_title')}
              optionalRecommendationSize={t(
                'news_and_events:image_optimal_size',
                {
                  size: '893x349',
                },
              )}
              styles={{ margin: '0px' }}
              file={eventInfo?.image}
              setFile={value => handleUpdateEventImages('image', value)}
              fileRef={bigImageRef}
              handleSetImage={value => handleUpdateEventImages('image', value)}
              isLogo
              imageStyles={{
                height: '150px',
              }}
            />

            <FileLoader
              title={t('news_and_events:formFields.secondary_image_title')}
              optionalRecommendationSize={t(
                'news_and_events:image_optimal_size',
                {
                  size: '76x58',
                },
              )}
              styles={{ margin: '0px' }}
              file={eventInfo?.preview_image}
              setFile={value => handleUpdateEventImages('preview_image', value)}
              fileRef={smallImageRef}
              handleSetImage={value =>
                handleUpdateEventImages('preview_image', value)
              }
              isLogo
              imageStyles={{
                height: '150px',
              }}
            />
            <DatePickerCustom
              label={t('news_and_events:formFields.date_event_label')}
              onChange={value => handleUpdateEventInfo('date', value)}
              value={eventInfo?.date}
              minValue={moment().toDate()}
              maxDate={false}
              placeholder={t('news_and_events:formFields.date_placeholder')}
            />

            <TextInput
              label={t('news_and_events:formFields.time_event_label')}
              placeholder={t(
                'news_and_events:formFields.time_event_placeholder',
              )}
              type="time"
              value={eventInfo?.time}
              onChange={value => handleUpdateEventInfo('time', value)}
              onBlur={() => {}}
              sx={{
                'input[type="time"]::-webkit-calendar-picker-indicator': {
                  background: 'none',
                  pointerEvents: 'none',
                },
              }}
            />

            <TextInput
              label={t('news_and_events:formFields.location_label')}
              placeholder={t('news_and_events:formFields.location_placeholder')}
              value={eventInfo?.location}
              onChange={value => handleUpdateEventInfo('location', value)}
              onBlur={() => {}}
            />

            <TextInput
              label={t('news_and_events:formFields.title_label')}
              placeholder={t('news_and_events:formFields.title_placeholder')}
              value={eventInfo?.title}
              error={errors?.title?.init}
              errorText={errors?.title?.text}
              required
              onChange={value => handleUpdateEventInfo('title', value)}
              onBlur={() => {}}
            />

            <RichEditorWrapper>
              <RichEditor
                label={t('news_and_events:formFields.editor_label')}
                placeholder={t('news_and_events:formFields.editor_placeholder')}
                error={errors?.content}
                value={eventInfo.content}
                onChange={value => handleUpdateEventInfo('content', value)}
              />
            </RichEditorWrapper>

            <TextInput
              label={t('news_and_events:formFields.url_label')}
              placeholder={t('news_and_events:formFields.url_placeholder')}
              value={eventInfo?.link}
              error={errors?.link?.init}
              errorText={errors?.link?.text}
              required
              onChange={value => handleUpdateEventInfo('link', value)}
              onBlur={() => {}}
            />
          </InputsWrapper>

          <ActionsWrapper>
            <OutlineButtonBase onClick={handleCloseModal}>
              {t('news_and_events:buttons.cancel')}
            </OutlineButtonBase>
            <ButtonBase type="type">
              {t(`news_and_events:buttons.${!editEventInfo ? 'add' : 'edit'}`)}
            </ButtonBase>
          </ActionsWrapper>
        </FormWrapper>
      </CreateEventModalWrapper>
    </ModalBase>
  );
};

const CreateEventModalWrapper = styled.div`
  padding-right: 5px;
`;

const FormWrapper = styled.form``;

const InputsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 12px;
`;

const ActionsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
  column-gap: 12px;
`;

const RichEditorWrapper = styled.div`
  width: 100%;
`;

CreateEventModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleCloseModal: PropTypes.func.isRequired,
  editNewsInfo: PropTypes.exact({
    image: PropTypes.any,
    preview_image: PropTypes.any,
    date: PropTypes.any,
    time: PropTypes.any,
    title: PropTypes.string,
    content: PropTypes.string,
    link: PropTypes.string,
  }),
  navigationAfterUpdateArticle: PropTypes.func.isRequired,
};
